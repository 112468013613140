module.exports = (preview) => `
{
  pageCollection(preview: ${preview},limit: 200) {
    items {
      sys {
        id
      }
      seoHead{
        breadcrumbsTitle
        title
        url
      }
    }
  }
}`;
