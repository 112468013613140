module.exports = `...on IdBindingMainPageSection4
{
  title
  leftLogoText
  middleText
  leftImage
  {
    imageDesktop
    {
      cloudinaryId
      description
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      description
      asset{url}
    }
  }
  rightImage
  {
    imageDesktop
    {
      cloudinaryId
      description
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      description
      asset{url}
    }
  }
  
}
`;
