import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Style from './Retailerpromotion.scss';
import Typography from '../Typography/Typography';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const RetailerPromotion = (props) => {
    const banner = props.sampleData.bannerCollection.items[0].url;
    const headerImage = props.sampleData.promotionHeaderCollection.items[0].url;
    return (
        <div className="landing retailerPromtion">
            <div className="landing-header">
                <div className="c-promoimg1">
                    <img src={buildContentfullImageUrl(banner)} className="image1" style={{ width: '100%' }} alt="ウィスパー　こんなにうすいのにモレを防ぐの" />
                </div>

                {props.sampleData.promotionImageCollection.items.map((imageData) => (

                    <div className="promoimg2">
                        <img src={buildContentfullImageUrl(imageData.url)} className="" style={{ width: '90%' }} alt={imageData.description} />
                    </div>
                ))}

                <Typography
                    className="conditionText"
                    component="h2"
                    dangerouslySetInnerHTML={{
                        __html: props.sampleData.text,
                    }}
                />
                <div className="promoimg3">
                    {props.sampleData.imageCollection.items.map((imageData) => (
                        <img src={buildContentfullImageUrl(imageData.url)} className="" style={{ width: '100%' }} alt={imageData.description} />
                    ))}
                </div>
                <div className="promoimg4">
                    {props.sampleData.productImageCollection.items.map((imageData) => (
                        <img src={buildContentfullImageUrl(imageData.url)} className="" style={{ width: '100%' }} alt={imageData.description} />
                    ))}
                </div>
                <div className="promoimg5">
                    {props.sampleData.promotionImageTwoCollection.items.map((imageData) => (
                        <img src={buildContentfullImageUrl(imageData.url)} className="" style={{ width: '90%' }} alt={imageData.description} />
                    ))}
                </div>
                <Typography
                    className="conditionText"
                    component="h2"
                    dangerouslySetInnerHTML={{
                        __html: props.sampleData.text1,
                    }}
                />
                <div className="promoimg6">
                    <img src={buildContentfullImageUrl(headerImage)} className="image1" style={{ width: '100%' }} alt="alt" />
                </div>
                <div>
                    {props.sampleData.productCardCollection.items.map((cardData) => (
                        <div className="promoimg7">
                            <a href={cardData.link}>
                                <img src={buildContentfullImageUrl(cardData.cardImage.url)} className="" style={{ width: '100%' }} alt={cardData.cardImage.description} />
                            </a>
                        </div>
                    ))}
                </div>
                <div>
                    {props.sampleData.bannerContentCollection.items.map((bannerData) => (
                        <div className="promoimg8">
                            <Typography
                                className="conditionText1"
                                component="h2"
                                dangerouslySetInnerHTML={{
                                    __html: bannerData.sectionContentCollection.items[0].body,
                                }}
                            />
                            <a href={bannerData.sectionContentCollection.items[1].text}>
                                <img src={buildContentfullImageUrl(bannerData.sectionContentCollection.items[1].image.url)} className="" style={{ width: '80%' }} alt={bannerData.sectionContentCollection.items[1].image.description} />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            <style jsx global>
                {Style}
            </style>
        </div>
    );
};
RetailerPromotion.propTypes = {
    sampleData: PropTypes.object,
};
const mapStateToProps = (state) => ({ allPages: state.allPages });
export default connect(mapStateToProps)(RetailerPromotion);
