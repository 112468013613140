/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isCurrentPage from '../Hooks/useCurrentPage';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';

import { getCategoryArticles, getArticleLists } from './ArticleList.helper';

import Style from './ArticleList.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import Pagination from '../Pagination/Pagination';
import { getArticleCategories } from '../../adapters/contentful.adapter';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

const ArticleList = (props) => {
    const { title, categories } = props;
    const { pageInfo } = props;
    
    const setSCurrentSlidePosition = () => {
        if (typeof window !== 'undefined') {
            const currentValue =  window.sessionStorage.getItem('currentSlide');
            // eslint-disable-next-line radix
            return parseInt(currentValue);
        }
    };
    const [initial, setInitial] = useState(setSCurrentSlidePosition() || 0);
    const myRef = useRef();

    useEffect(async () => () => {
        window.sessionStorage.removeItem('currentSlide');
    }, []);

    const renderArticles = (articleListData) => {
        let resolution = useScreenSize();

        if (!resolution) {
            resolution = { isMobile: true };
        }
       
        // const filteredArticles = categoryList.filter((item) => item.name === selectedCategory);
        return (
            <div className="article-container">
                {articleListData.map((article, j) => {
                    if(article?.page?.seoHead?.url){
                    return (
                    <div>
                        {initial <= j && j < initial + 6 && ( 
                            <div className="row article-card-stretch" key={j}>
                                <div className="col-md-6 col-sm-12 article-card-mb" key={j}>
                                    <a
                                        className={classNameForGA(article?.page?.seoHead?.url || '')}
                                        {...dataActionForGA(article?.page?.seoHead?.url || '')}
                                        href={article && article?.page?.seoHead?.url || ''}
                                    >
                                        {article.subheading1 === 'customcontent' ? (
                                            <div className="article-card banner-promo">
                                                <div className="mobile-image-cropper">
                                                    <Image image={resolution.isMobile ? article.imageSp : article.imageDt} />
                                                </div>
                                            </div>
                                        )
                                            : (
                                                <div className="article-card">
                                                    <div className="mobile-image-cropper">
                                                        <Image className="card-image" image={resolution.isMobile ? article.imageSp : article.imageDt} />
                                                    </div>
                                                    <div className="title-container">
                                                        <h3 className="first-title">{article.title}</h3>
                                                        {
                                                            article && article.title1
                                                                ? <h3 className="second-title">{article.title1}&nbsp;</h3>
                                                                : ''

                                                        }
                                                    </div>
                                                </div>
                                            )}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }else return null
            })
                }
                <style jsx>{Style}</style>
            </div>
        );
    };

    const categoriesToShow = getCategoryArticles(categories, pageInfo);
    let articles = getArticleLists(categoriesToShow);
   
    articles = articles.filter(val=>{
        return (typeof val?.page?.seoHead?.url !=='undefined')
    })
    const scrollSmooth = () => {
        myRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const handleCurrentSlide = (slide) => {
        window.sessionStorage.setItem('currentSlide', slide);
    };
     
    const nextPage = (e) => {
        e.preventDefault();
        scrollSmooth(e, 'top');
        if (initial + 7 <= articles.length) {
            setInitial(initial + 6);
        }
        console.log(initial, 'handle next');
        handleCurrentSlide(initial + 6);
    };
    const previousPage = (e) => {
        e.preventDefault();
        scrollSmooth(e, 'top');
        if (initial >= 6) {
            setInitial(initial - 6);
        }
        console.log(initial, 'handle previous');
        handleCurrentSlide(initial - 6);
    };

    const handlePagination = (e, index) => {
        if (e) { e.preventDefault(); } 
        scrollSmooth(e, 'top');                
        const totPages = Math.ceil(articles.length / 6);       

        if (initial + 7 <= articles.length) {
            console.log(initial, index, 'handle pagination');
            if (index === totPages) {
                const temp = index - 1;
                setInitial(temp * 6);
                handleCurrentSlide(temp + 6);
            } else {
                setInitial(index * 6);
                handleCurrentSlide(index + 6);
            }
        } else {
            let totalPagesCurr = 0;
            console.log(initial, index, 'handle elseeee');
            if (index !== 0) {
                totalPagesCurr = index - 1;
            }         
            setInitial(totalPagesCurr * 6);
            handleCurrentSlide(totalPagesCurr + 6);
        }   
    };
  
    const handleChange = () => {
        window.sessionStorage.setItem('currentSlide', 0);
    };

    const handleBlur = () => {
        window.sessionStorage.setItem('currentSlide', 0);
    };

    const totalPages = Math.ceil(articles.length / 6);
    return (
        <div className="article-list container" ref={myRef} id="headerDiv">
            <Breadcrumbs pageInfo={pageInfo} className="articleList-brdCrumb" />
            <h1 className="header-title">尿もれお役立ちコラム</h1>
            <p className="title">{title}</p>
            <div className="dropdown">
                <button className="dropbtn" type="button">{pageInfo.url === '/article-top/' ? 'すべてのトピック' : pageInfo.breadcrumbsTitle}</button>
                <div className="dropdown-arrow" />
                <div className="dropdown-content">
                    {categories.items.map((category) => {
                        const categoryUrl = category && category.page.seoHead.url;
                        const currentPage = classnames({ active: isCurrentPage(categoryUrl) });
                        return category && category.name && (
                            <a
                                className={`event_menu_click ${currentPage}`}
                                href={categoryUrl}
                                data-action-detail={category && category.name} 
                                title={category && category.name}
                                dangerouslySetInnerHTML={{ __html: category && category.name }}
                                onClick={handleChange}
                                onBlur={handleBlur}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 articles">
                    {pageInfo.breadcrumbsTitle && <h2>{pageInfo.breadcrumbsTitle}</h2>}
                    {articles && articles.length > 0 &&  renderArticles(articles, categoriesToShow)}
                    <div className="paginate">
                        { categoriesToShow && categoriesToShow.length && (
                            <Pagination
                                data={articles}
                                num_pages={totalPages}
                                current_page={Math.round(initial / 6) + 1}
                                next={(e) => nextPage(e)}
                                previous={(e) => previousPage(e)}
                                nextButtonClass="next"
                                previousButtonClass="previous"
                                handlePagination={handlePagination}
                            />
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

ArticleList.getInitialProps = async () => {
    const articleCategories = await getArticleCategories();

    return {
        articleCategories,
    };
};

ArticleList.propTypes = {
    articlesText: PropTypes.string,
    categories: PropTypes.object,
    menuTitleText: PropTypes.string,
    articlePromoBanners: PropTypes.object,
    retailersDropdown: PropTypes.object,
    promoBanner: PropTypes.object,
    pageInfo: PropTypes.object,
    title: PropTypes.string,
};

export default ArticleList;
