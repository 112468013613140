import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.related-articles {
    margin-bottom: 60px;

    @media (max-width: $breakpoint-max) {
        margin-top: -100px;
    }

    h3 {
        color: $purple-dark;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 50px;
        margin-top: 50px;

        @media (max-width: $breakpoint-max) {
            font-size: 26px;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    .related-article {
        text-align: center;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        h4 {
            color: $purple-dark;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            margin: 0;

            @media (max-width: $breakpoint-max) {
                margin-top: 10px;
            }

            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                font-size: 2vw;
                line-height: 3vw;
            }
        }

        img {
            max-width: 100%;
        }

        img + h4 {
            margin-bottom: 0;
            margin-top: 20px;
        }

        h4 + h4 {
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
}
`;

export default styles;