import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.youtubeVideo {
    // background: #f5f5f5;
    // width: 640px;
    // margin: -40px auto 20px;
    padding: 0 20px;
    @media only screen and (max-width: $breakpoint-max) {
      width: 100%;
      margin: 0 auto 20px;
      iframe {
        // width: 100% !important;
        // height: 300px !important;
      }
    }
    iframe video{
      object-fit:cover !important;
    }

}
.homeYoutube{
  width: 100%;
  max-width: 1180px;
  height: 665px;
  margin-bottom: 24px;
  @media (max-width: $breakpoint-max-md-ipad) {
    height: 220px;
  }
}`;

export default styles;