module.exports = (preview) => `
{
  retailerPromotionCollection(limit: 2,preview: ${preview}) {
    items {
      __typename
      name
      slug
      bannerCollection (limit: 2,preview: ${preview}){
        items{
        ... on Asset {
          fileName
          width
          height
          description
          url
        }
        }
      }
      promotionImageCollection (limit:3,preview: ${preview}){
        items{
        ... on Asset {
          fileName
          width
          height
          description
          url
        }
        }
      }
      text
      imageCollection(preview: ${preview}) {
        items{
        ... on Asset {
          url
          description
        }
        }
      }
      productImageCollection(preview: ${preview}) {
        items{
        ... on Asset {
          url
          description
        }
        }
      }
      promotionImageTwoCollection (limit:3,preview: ${preview}){
        items{
        ... on Asset {
          fileName
          width
          height
          description
          url
        }
        }
      }
      text1
      promotionHeaderCollection(limit:1,preview: ${preview}){
        items{
        ... on Asset {
          fileName
          width
          height
          description
          url
        }
        }
      }
      productCardCollection(limit: 4,preview: ${preview}) {
        items{
        ... on CardContainer {
          name
          link
          styles
          cardImage {
            ... on Asset {
              url
              description
            }
          }
        }
        }
      }
      bannerContentCollection(preview: ${preview}) {
        items{
        ... on SectionContainer {
          name
          sectionContentCollection(limit:2,preview: ${preview}) {
            items{
            ... on ContentTypeRichText {
              name
              body
              styles
            }
            ... on ImageAndText {
              name
              image {
                url
                description
              }
              text
            }
            }
          }
          styles
        }
      }
    }
    }
  }
}
`;
