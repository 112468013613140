import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { dataActionForGA } from '../../helpers/dataForGA';
import BuyNowButton from '../BuyNowButton/BuyNowButton';
import { getProductDetailsByGTIN } from '../../adapters/contentful.adapter';
import resultstyle from './resultstyle.scss';
import StaticHeader from '../StaticHeader/StaticHeader';
import PromoBanner from '../PromoBanner/PromoBanner';
import SmallPromoBannersContainer from '../SmallPromoBannersContainer/SmallPromoBannersContainer';
import { getImageSet } from '../../helpers/uiHelper';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ResultPage = (props) => {
    const { savedResponse, products, resolution } = props;
    const [prodetails, setProductDetails] = useState([]);
    const [showSpinner, setSpinenr] = useState(true);
    const banner = props.resultPageCollection.items[0];
    const imageSet = props.resultPageCollection.items[1];
    const promoBanner = props.resultPageCollection.items[2];
    const smallPromoBanners = props.resultPageCollection.items[3];
    useEffect(() => {
        const newProd = [];
        let count = 0;
        async function fetchData() {
            await savedResponse.map(async (val) => {
                if (val.EANCode) {
                    await getProductDetailsByGTIN(JSON.stringify(val.EANCode))
                        .then((prods) => {
                           
                            prods.EANCode = val.EANCode;
                            let productImage = prods?.imageDt
                            let productName = prods?.productCategory 
                            let productShortName = prods?.title ?? null
                            
                            if(productShortName?.includes('/')){
                                productShortName = productShortName?.split("/")[0]
                            }
                            let prodUpdate = {
                                productName : productName,
                                productShortName: productShortName ?? null,
                                productImage:{
                                    title: productImage?.title ,
                                    url:productImage?.asset?.url
                                },
                                EANCode:val.EANCode
                            }
                            newProd.push(prodUpdate);
                            
                            count++;
                            if (count === savedResponse.length) {
                                setProductDetails(newProd);
                                setSpinenr(false);
                            }
                            const result = document.getElementsByClassName('result-box')[0];
                            if (count === 1) {
                                return result && result.classList.add('full-wide');
                            }
                            return result.classList.remove('full-wide');
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    setProductDetails(newProd);
                    setSpinenr(false);
                }
            });
        }
        fetchData();
        window.scroll(0, 0);
    }, []);

    useEffect(() => window.PriceSpider && window.PriceSpider.rebind());

    return (
        <>
            <div className="result-banner"><StaticHeader {...banner} /> </div>
            <div className="product-box result">
                {showSpinner
                    ? (
                        <div className="loader-part">
                            <Loader
                                type="TailSpin"
                                color="#8347ad"
                                className="loader"
                                height={96}
                                width={96}
                                visible={true}
                            />
                        </div>
                    )
                    : (
                        <div className="result-box">
                            {Object.values(products).map((val) => prodetails.map((product) => {
                                console.log(product, 'product');
                                return (
                                    product.EANCode === val.ProductGTINs && (
                                        <div className="result-box-container" key={product.productName}>
                                            <div className="result-card">
                                                <div className="prod-title">
                                                    <p className="prod-name">
                                                        {product.productName}
                                                    </p>
                                                    {product.productShortName && (
                                                        <p className="prod-variant"> {product.productShortName} </p>
                                                    )}
                                                </div>
                                                <img
                                                    src={buildContentfullImageUrl(product.productImage.url)}
                                                    alt={product.productName}
                                                />
                                                <div className="bin-button" {...dataActionForGA(val.ProductGTINs)}>
                                                    <BuyNowButton sku={val.ProductGTINs} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            }))}
                        </div>
                    )}
                <div className="backToContainer">
                    <span
                        className="backTo"
                        onClick={() => {
                            window.document.location.reload();
                        }}
                    >
                        {'\u2190'} 診断TOPへ戻る
                    </span>
                </div>
            </div>
            {getImageSet(imageSet, resolution)}
            <PromoBanner {...promoBanner} />
            <SmallPromoBannersContainer {...smallPromoBanners} />
            <style jsx>{resultstyle}</style>
        </>

    );
};
ResultPage.propTypes = {
    products: PropTypes.object,
    savedResponse: PropTypes.object,
    ResultPage: PropTypes.object,
    promoBanner: PropTypes.object,
    resolution: PropTypes.any,
    resultPageCollection: PropTypes.any,
};
export default ResultPage;
