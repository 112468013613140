/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useScreenSize from "../Hooks/useScreenSize";
import PromoBannerWithCta from "../PromoBannerWithCta/PromoBannerWithCta";
import SmallPromoBannersContainer from "../SmallPromoBannersContainer/SmallPromoBannersContainer";
import PromoBannerInterview from "../PromoBannerInterview/PromoBannerInterview";
import RetailersDropdown from "../RetailersDropdown/RetailersDropdown";

import Style from "./ProductListContainer.scss";

import {
  buildCategories,
  getSectionTitles,
  getFilteredProducts,
  getFilters,
  getSelectedCategory,
  assignRatingsToCategories,
  extractRnrIds,
} from "./ProductListContainer.helper";
import BazaarVoice from "../../adapters/bazaarVoice.adapter";
import ERetailers from "../ERetailers/ERetailers";
import ProductCard from "../ProductCard/ProductCard";
import { useRouter } from "next/router";
import PriceSpiderMetaTags from "../PriceSpiderMetaTags";

const ProductListContainer = ({
  heroBannerCollection: { items: heroBanners },
  customClass,
  pageInfo,
  pageContentCollection: { items: pageContents },
  ...rest
}) => {
  const resolution = useScreenSize();
  const router = useRouter();
  const { asPath } = router;
  const hash = asPath?.split("#")[1];

  const categoryTabs =
    pageContents?.filter((itm) => itm.styles === "plpTabContainer")?.[0] ?? {};
  const eRetailers =
    pageContents?.filter((itm) => itm.__typename === "ERetailers")?.[0] ?? {};
  const allProducts = pageContents?.filter(
    (itm) => itm.__typename === "ProductContainer"
  );
  const [productList, setProductList] = useState([...allProducts]);
  const [selectedBanner, setSelectedBanner] = useState(heroBanners[0]);
  const [hashCategory, setHashCategory] = useState("");
  const [mountPS, setMoountPS] = useState(false);

  const handleTabClick = (category) => async(_ev) => {
    setMoountPS(false)
    if (!category && !hash) return;
    await router.push({ pathname: router.query.slug, hash: category });
    setTimeout(()=>setMoountPS(true),500) //Pricespider triggering issue resolved
  };
  useEffect(() => {
    if (!hash) {
      setSelectedBanner(heroBanners[0]);
      setProductList([...allProducts]);
    } else {
      const filteredBanner = heroBanners
        ?.slice()
        ?.filter((banner) => banner.bannerId?.trim() === hash)?.[0] ?? {
        ...heroBanners[0],
      };
      setSelectedBanner(filteredBanner);
      const filteredProduct = allProducts
        ?.slice()
        ?.filter((products) => products.containerId?.trim() === hash) ?? [
        ...allProducts,
      ];
      setProductList(filteredProduct);
    }
    setHashCategory(hash);
  }, [hash]);

  return (
    <div>
      <div className={selectedBanner.style}>
        {selectedBanner.hasOwnProperty("desktopImage") && (
          <picture>
            <source
              media="(min-width: 1024px)"
              srcset={selectedBanner.desktopImage.url}
            />
            <source
              media="(min-width: 350px)"
              srcset={selectedBanner.smartPhoneImage.url}
            />
            <img src={selectedBanner.smartPhoneImage.url} alt={selectedBanner.altText}/>
          </picture>
        )}
      </div>
      <div className="breadcum-plp">
        <a className="breadcrumb-anchor" href="/">Home</a> {" > "}
        <span className="breadcrumb-span">{pageInfo.breadcrumbsTitle}</span>
      </div>
      <div className={categoryTabs.styles}>
        <h1>{categoryTabs.header}</h1>
        <div className="tab-container">
          {categoryTabs.sectionContentCollection.items?.map((tab, ind) => {
            return (
              <button
                onClick={handleTabClick(tab.url)}
                key={`tab-${ind+1}`}
                className={`${tab.customClass} ${
                  tab.url?.trim() === hashCategory ? "active" : ""
                }`}
              >
                {tab.textValue}
              </button>
            );
          })}
        </div>
      </div>

      <div className="product-category-container">
      {mountPS&&<PriceSpiderMetaTags />}
        {productList.map((catList, ind) => {
          return (
            <div key={`category-${ind + 1}`} className={catList.styles}>
              <h1>{catList.title}</h1>
              <div className="product-list-container">
                {catList.contentTypeCollection?.items?.map((product, index) => {
                  return (
                    <ProductCard product={product} resolution={resolution} key={`${catList.title}-product-${index+1}`} />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <ERetailers {...eRetailers} />
      <style jsx>{Style}</style>
    </div>
  );
};

ProductListContainer.propTypes = {
  productCategories: PropTypes.object,
  promoBannerWithCta: PropTypes.object,
  promoBannerInterview: PropTypes.object,
  retailersDropdown: PropTypes.object,
  smallPromoBannersContainer: PropTypes.object,
};

const mapStateToProps = (state) => ({
  productCategories: state.productCategories,
});

export default connect(mapStateToProps)(ProductListContainer);
