const preview = process.env.PREVIEW;
module.exports = `
... on BannersContainer {
nameEn
style
banners: bannersCollection(limit: 10,preview: ${preview}) {
  items {
    ... on PromoBanner {
          nameEn
          heading
          imageDt {
            title
            description
            cloudinaryId
            asset{url}
          }
          imageSp {
            title
            description
            cloudinaryId
            asset{url}
          }
          link {
            __typename
            ...on Page {
              seoHead {
                url
              }
            }
          }
          externalLink
        }
      }
  }
}
`;
