const preview = process.env.PREVIEW;
module.exports =  `
... on SectionContainer {
    name
    header
    styles
    jumpLinkId
    sectionContentCollection(limit: 4, preview: ${preview}){
        items{
           sys {
            id
          }
          ... on PromoBanner{
            __typename
            nameEn
            customClass
            heading
            imageDt {
                title
                description
                cloudinaryId
                asset{url}
            }
            imageSp {
                title
                description
                cloudinaryId
                asset{url}
            }
          }
          ... on Video {
            __typename
            title
            video {
                url
            }
            videoLink
            autoplay
            styles
        }
        ... on Link {
            __typename
            name
            textValue
            url
            type
            target
            customClass
        }
        ... on SectionContainer {
            __typename
            name
            styles 
            sectionContentCollection(limit: 4, preview: ${preview}){
           items {
            sys{
                id
            }
            ... on CloudinaryImage {
                __typename
                title
                cloudinaryId
                asset{url}
               }
            
            ... on SimpleCard{
                __typename
                name
                header
                altText
                isCardLink
                customClass
                cardMedia {
                    url
                }
                cardDescription
                cardActionCollection(preview: ${preview}, limit: 4) {
                    items {
                    ... on Link {
                        name
                        textValue
                        url
                        type
                        target
                        customClass
                    }
                 }
                }

            }
           }
        }
        }
        }
        }
}
`;
