import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';
import { renderSection, getRichText } from '../../helpers/uiHelper';
import Style from './ProductContainer.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
const renderSectionBody = (item, resolution) => {
    let image;
    switch (item.__typename) {
    case 'ImageSet':
        image = resolution?.isMobile ? item.imageSmartphone : item.imageDesktop;
        return (
            <LazyLoad offset={20}>
                <div className="imageset">
                    <Image image={image} />
                </div>
            </LazyLoad>
        );
    case 'ContentTypeRichText':
        return getRichText(item);
    case 'SectionContainer':
        return <LazyLoad offset={20}> {renderSection(item, resolution)}</LazyLoad>;
    default:
        break;
    }
};

const ProductContainer = ({ title, ...props }) => {
    const resolution = useScreenSize();
    return (
        <div className="productContainer">
            <Breadcrumbs pageInfo={props.pageInfo} className="article-brdCrumb" />
            {title && <h2 className="title">{title}</h2>}
            {props.contentTypeCollection.items && props.contentTypeCollection.items.map((item) => renderSectionBody(item, resolution))}
            <style jsx>{Style}</style>
        </div>
    );
};
ProductContainer.propTypes = {
    title: PropTypes.string,
    props: PropTypes.object,
    contentTypeCollection: PropTypes.object,
};

export default ProductContainer;
