import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import LazyLoad from 'react-lazyload';
import CategoryColumn from './CategoryColumn';
import BackToTop from '../UI/BackToTop/BackToTop';
import useScreenSize from '../Hooks/useScreenSize';
import { storageKeysRemove, GetEmailVerified } from '../../helpers/StorageEvents';
import RetailersDropdown from '../RetailersDropdown/RetailersDropdown';
import { isProductPages } from '../../helpers/uiHelper';
import Style from './FooterProducts.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import { buyProducts, headerAndFooter, regUrl } from '../../helpers/constants';

const FooterProducts = ({ retailers, products }) => {
    const categories = products.items[0].categoriesCollection.items;
    const { registrationButton } = products.items[0];
    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';
    const router = useRouter();
    let resolution = useScreenSize();
    if (!resolution) {
        resolution = { isMobile: true };
    }
    const handleLogout = () => {
        window.dataLayer.push({
            event: 'customEvent', 
            GAeventCategory: 'event_crm_action',
            GAeventAction: 'event_profile_logout',
            GAeventLabel: 'Whisper_logout_complete ',
            GAeventValue: 0,
            GAeventNonInteraction: false,
        });
        window.sessionStorage.removeItem('isUserLoggedIn');
        window.sessionStorage.removeItem(`${window.location.hostname}crmDetails`);
        window.localStorage.removeItem(`${window.location.hostname}crmDetails`);
        storageKeysRemove(['LohacoRelease', 'authCode', 'completeIDBinding',
            'UnbindTC', 'IDBindingVia', 'RegIDBindingVia', 'LohacoUnbind',
            'retailerId', 'loaderStatus', 'nextStatus', 'userData',
            'videoFeedbackVia', 'consumerID', 'orderId']);
        window.location.href = '/';
    };

    return (
        <div className="product-footer full-width">
            {!isHomePage || isProductPages(router.asPath) && <BackToTop text="トップに戻る" />}
            <div className="Buy-whisper-products">
                <div className="Buy-products-container">
                    <div className="Buy-products-promo">
                        <h3>ウィスパー商品を購入する</h3>
                        <LazyLoad offset={20}>
                            <div className="Buy-products-img">
                                {
                                    resolution.isMobile
                                        ? <img src={buildContentfullImageUrl(buyProducts)} alt="img1" />
                                        : <img src={buildContentfullImageUrl(buyProducts)} alt="ウィスパー商品ラインナップ" />
                                }
                                <RetailersDropdown
                                    dropdownTitle={retailers.items[0].dropdownTitle}
                                    retailersCollection={retailers.items[0].retailersCollection}
                                    isForHeader={false}
                                />
                            </div>
                        </LazyLoad>
                    </div>
                </div>
            </div>
            {resolution.isMobile
                ? (
                    <div className="brandLogoWrapper">
                        <a href="/">
                            <img
                                src={buildContentfullImageUrl(headerAndFooter)}
                                className="logo"
                                width="100"
                                alt="Whisper logo"
                                title="Whisper logo"
                            />
                        </a>
                    </div>
                )
                :''
            }

            <div className="container mobile-footer-hide footer-container">
                <div className="footer-product-section">
                    <div className="spacer-fat" />
                    <h3>{products.items[0].title}</h3>
                    <div className="row footer-product-align">
                        <div className="basicline">
                            <h3 className="heading">ベーシックライン</h3>
                            <div className="basicline-wrapper">
                                {categories.map((items) =>
                                    items.category === 'ベーシックライン'
                                    && <CategoryColumn items={items} key={items.name} />)}
                            </div>
                        </div>
                        <div className="premiumline">
                            <h3 className="heading">プレミアムライン</h3>
                            <div className="premiumline-wrapper">
                                {categories.map((items) =>
                                    items.category === 'プレミアムライン'
                                    && <CategoryColumn items={items} key={items.name} />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

FooterProducts.propTypes = {
    products: PropTypes.object,
    retailers: PropTypes.any,
};

export default FooterProducts;
