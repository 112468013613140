import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.review-campaign-lp{
    width: 100%;
    float: left;
    position: relative;
    margin:0 auto;
    .banner-image-section{
        width:100%;
        float: left;
        .banner-image{
            img{
                width:100%;
            }
        }
    }
}
.review-campaign-lp .content-container {
    padding: 50px 20px;
}

.header-container{
    width: 100%;
    position: relative;
    margin-bottom: 50px;

    .title{
        color: #8347AD;
        text-align: center;
        font-size: 16px;
        line-height: 40px;
        position: relative;
        margin: 30px 0 50px 0;
        font-family: "Noto Sans JP";
        font-weight: bold;
        float: left;
        width: 100%;
        @media only screen and (min-width: 768px) {
            margin-top: 0;
            margin-bottom: 40px;
        }
        @media only screen and (min-width: 1366px) {
            font: normal normal bold 20px/40px Noto Sans JP;
        }
        &::after{
            position: absolute;
            content: "";
            height: 4px;
            bottom: -16px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 60px;
            background: #8347ad;
        }
    }

    .sub-title{
        color: #8347AD;
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        position: relative;
        font-family: "Noto Sans JP";
        font-weight: bold;
        width: 100%;
        float: left;
        margin: 0 0 50px 0;
        @media only screen and (min-width: 768px) {
            text-align: center;
        }
        @media only screen and (min-width: 1366px) {
            font: normal normal bold 16px/30px Noto Sans JP;
        }        
    }
}
.product-list-container {
    padding: 20px 20px;
    letter-spacing: 0px;
    font-family: "Noto Sans JP";
    @media only screen and (min-width: 1366px) {
        max-width: 1280px;
        margin: 0 auto;
    }
    .listMainConatainer{
        float: left;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 20px;
        .cardWrapper {
            width: 50%;
            float: left;
            text-align: center;
            margin-bottom: 30px;
            @media (min-width: 768px) {
                width: 33%;
                margin-bottom: 60px;
            }
            @media (min-width: 1366px) {
                width: 25%;
            }
            img{
                width: 140px;
                height: auto;
                text-align: center;
                margin: 0 auto;
                @media (min-width: 1024px) {
                    width: 270px;
                }     
                @media (min-width: 1366px) {
                    width: 186px;
                }      
            }
            .button-cmp {
                border: 1px solid #8347AD;
                width: 93%;
                margin: 0 auto;
                margin-top: 20px;
                padding: 7px 0 11px;
                font-family: "Noto Sans JP";
                @media (min-width: 768px) {
                    width: 230px;
                }
                @media (min-width: 1024px) {
                    width: 86%;
                    margin-top: 55px;
                    padding: 16px;
                }
                @media (min-width: 1366px) {
                    padding: 7px 0 8px;
                }
               
                &:hover{
                    background: #8347AD;
                    a{
                        color: #ffffff;
                    }
                }
                a {
                    color: #8347AD;
                    font: normal normal bold 14px/21px Noto Sans JP;
                    letter-spacing: 0px;
                    @media (min-width: 768px) {
                        font-size: 18px;
                    }
                    @media (min-width: 1366px) {
                        font: normal normal bold 16px/30px Noto Sans JP;
                    }                    
                }
            }
        }
    }
}
.maincmp-btn {
    background: #8347AD;
    width: 72%;
    margin: 0 auto;
    text-align: center;
    padding: 14px;
    clear: both;
    border-radius: 10px;
    @media (min-width: 768px) {
        width: 280px;
    }
    a {
        color: #fff;
        font-weight: bold;
        position: relative;
        &::after{
            content: "";
            background: url('../../../static/images/Icon-arrow-right.png');
            position: absolute;
            right: -28px;
            width: 20px;
            top: 6px;
            height: 20px;
            background-repeat: no-repeat;
        }
        span{
            text-decoration: underline;
            /* &::before{
                content: "";
                position: absolute;
                right: -32px;
                width: 20px;
                height: 2px;
                background: #fff;
                transform: rotate(35deg);
                top: 5px;
            }
            &::after{
                content: "";
                position: absolute;
                right: -32px;
                width: 20px;
                height: 2px;
                background: #fff;
                transform: rotate(35deg);
                top: 5px;
            } */
        }
    }
}
.Review-Campaing-Loginsection{
    width: 88%;
    margin-top: 80px;
    position: relative;
    padding: 0 30px;
    border: 4px solid #8347ad14;
    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 80px;
    @media only screen and (min-width: 1366px) {
        max-width: 700px;
        margin: 0 auto;
        width: 48%;
        border: 1px solid #8347AD;
        margin-top: 160px;        
    }
    .Review-Campaing-PI{
        margin: 0 auto;
        text-align: center;

        .pi{
            width:48%;
            text-align: center;
            background-color: white;
            border-radius: 5px;
            @media (min-width: 768px) {
                width: 61%;
                margin: 0 auto;
            }
            @media (min-width: 1024px) {
                width: 100%;
                margin: 0 auto;
            }
            .title {
                text-align: center;
                font-size: 18px;
                font-family: "Noto Sans JP";
                color: #8347ad;
                margin: 40px 0 40px;
                position: relative;
                font-weight: bold;

                &::after{
                    position: absolute;
                    content: "";
                    height: 4px;
                    bottom: -16px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 60px;
                    background: #8347ad;
                }
            }
            .txt{
                color:$purple;
                width:100%;
                font-family: "Noto Sans JP";
                font-weight: normal;
                font-size: 18px;
                padding: 30px 0;
                font-weight: bold;
                text-align: left;
                @media (min-width: 768px) {
                    text-align: center;
                }
            }
            .sub-text-one{
                padding-top: 0;
                padding-bottom: 0;
                @media (min-width: 1024px) {
                    text-align: center;
                }
            }
            .cta{
                background: url('https://images.ctfassets.net/8g0fievzqg8k/7AtzKPdp930GPhg0XtOYty/835dbf7c2bc2f67a9910cf26bc4cb297/PC_button_register.jpg') no-repeat;
                background-size: 100%;
                width: 100%;
                height: 60px;
                padding: 0;
            }      
    
            .registration {
                background: url('https://images.ctfassets.net/8g0fievzqg8k/7AtzKPdp930GPhg0XtOYty/835dbf7c2bc2f67a9910cf26bc4cb297/PC_button_register.jpg') no-repeat 5px 0px;
                background-size: 100%;
                width: 100%;
                height: 60px;
                padding: 0;
                @media (min-width: 768px) {
                    height: 82px;
                }
                @media (min-width: 1024px) {
                    background-size: 300px;
                    background-position: center;
                    margin-top: 24px;
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
            .Review-Campaing-PI{
                .pi{
                    width: 100%;
                    display: block;
                }
            }
         }
}

.review-campaign-contentsection {
    width:100%;
    position: relative;
    margin: 0 auto;
    font-family: "Noto Sans JP";
    font-weight: normal;
    background-color: $purple-ultra-light;
    font-family: "Noto Sans JP";
    padding-top: 80px;
    padding-bottom: 80px;
    @media only screen and (min-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media only screen and (min-width: 1366px) {
        margin-top: 160px;
    }
    .review-campaign-content{
        width:100%;
        margin: 0 auto 80px;        
        @media only screen and (min-width: 1366px) {
            max-width: 1280px;
            margin: 0 auto;
        }
        .review-campaign-header{
            color:$purple;
            font-size: 21px;
            @media only screen and (min-width: 1366px) {
                font: normal normal bold 18px/30px Noto Sans JP;
            }
        }
        .review-campaign-text{
            font-size: 18px;
            line-height: 34px;
            margin: 15px 0;
            width: 100%;
            span{
                text-decoration: underline;
            }
            a {
                color: black;
                text-decoration: underline;
            }
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        .review-campaign-content{
            width:100%;
            padding:0 20px;
            .review-campaign-header{
                color:$purple-dark;
                font-size: 24px;
            }
            .review-campaign-text{
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .review-campaign-content{
            width:100%;
            // padding:0 20px;
            margin: 0 auto ;

            .review-campaign-header{
                color:#8347AD;;
                font-size: 17px;
                font-family: "Noto Sans JP";
                font-weight: bold;
                letter-spacing: 0px;
                margin-bottom: 0;
            }
            .review-campaign-text{
                font-size: 16px;
                line-height: 24px;
                margin-top: 8px;
                margin-bottom: 24px;
            }
        }
    }
    @media only screen and (max-width: 767px){
        width:100%;
    }
}

.product-content-section{
    width: 60%;
    text-align: center;
    img{
        width: 100%;
        :nth-child(3){
            width: 15%;
        }
    }
    p{
        font-weight: 500;
    }
    h2{
        color:#8347AD;
        font-size: 24px;
    }
    @media only screen and (max-width: 767px){
        p{
            text-align: left;
        }
        width:90%;
        img{
            width:90%;
            :nth-child(3){
                width: 50%;
            }
        }
        h2{
            font-size: 17px;
        }
    }
}
.product-content-section > *{
    margin-bottom: 45px;
}
.product-content-section > *:first-child {
    margin-top: 45px;
    
}
.product-content-section2{
     width: 80%;
     .onlypc{
        color:#8347AD;
     }
     h2{
        color:#8347AD;;
        font-size: 24px;
        margin-top: 55px;
        margin-bottom: 20px;
    }
    p{
        font-weight: 500;
    }
    margin-bottom: 100px;
    @media only screen and (max-width: 767px){
        width: 90%;
        margin-bottom: unset;
        h2{
            font-size: 17px;
            margin-top: unset;
        }
        .onlypc{
            display: none;
        }
    }
}
.header-section{
    text-align: center;
    margin: 40px auto 0px auto;
    display: flex;
    flex-direction: row;
    width: 80%;
    .leftdiv,.middlediv,.rightdiv{
        width: 33.33%;
    }
    .leftdiv,.rightdiv{
        position: relative;
        span{
            display: none;
        }
        ::before{
            content : "";
            position: absolute;
            left    : 0;
            bottom  : 50%;
            height  : 1px;
            width   : 100%;  /* or 100px */
            border-bottom:2px solid black;
            @media only screen and (max-width: 767px){
                border-bottom:1.5px solid black;
                font-weight: 600;
            }
        }
    }
    h1{
        font-size: 2vw;
        margin: 0px;
        font-weight: 300;

    }
    @media only screen and (max-width: 767px){
        .leftdiv,.rightdiv{
            width: 15%;
        }
        .middlediv{
            width: 70%;
        }
        h1{
            font-size: 4.5vw;
        }

    }
}




`;

export default styles;