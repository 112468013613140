import React from 'react';
import Style from './ReviewCampaignLP.scss';

const ReviewCampaignLPContent = () => (
    <div className="review-campaign-contentsection">
        <div className="review-campaign-content">
            <p className="review-campaign-header">【応募期間】</p>
            <p className="review-campaign-text">
                2022年3月18日(金) ~2022年４月18日(金)23:59<br />
            </p>
            <p className="review-campaign-header">【概要】</p>
            <p className="review-campaign-text">
                期間中にマイレピ上で対象製品にレビューを投稿してくださった方の中から50名様に5000円のアマゾンギフト券をプレゼント。
            </p>
            <p className="review-campaign-header">【応募方法】</p>
            <p className="review-campaign-text">
                ウィスパー会員に登録した上で、マイレピ上でレビューを投稿してください。マイレピ内にはウィスパーの会員情報でログインいただけます。<br /> マイレピ上でログインいただき、レビューを期間内に投稿いただければ応募完了です。
            </p>
            <p className="review-campaign-header">【当選発表】</p>
            <p className="review-campaign-text">
                応募者の中から抽選の上、当選者を決定いたします。<br />
                当選者の発表は、商品の発送をもってかえさせていただきます。
            </p>
            <p className="review-campaign-header">【ご注意】</p>
            <p className="review-campaign-text">
                ● ご応募にはマイレピ会員/ウィスパー会員の登録（無料）が必要です。<bt />
                ● マイレピ上に掲載されているウィスパー製品が対象になります。<br />
                ● 掲載には掲載承認まで日数がかかる場合がございますが、掲載承認が遅くなった場合でも投稿がキャンペーン期間内であれば対象となります。<br />
                ● 会員登録頂いている住所へ当選の発送をさせていただきますので、正しい住所をご入力してください。不備があった場合、商品のお届けができないため当選権利失効となります。<br />
                ● レビュー投稿画面には確認画面はございませんので、投稿時にご確認お願いいたします。<br />
                ● ご応募は国内住んでいる方のみに限ります。<br />
                ● 口コミ・レビューの利用規約または本サイトの利用規約に反するレビュー投稿は対象外となります。<br />
                ● 必要事項に不備や虚偽がある場合は無効となります。またご応募内容の訂正は承れません。<br />
                ● ご応募はこちらのページからのみとなります。メールやはがき、お電話、FAXでのご応募は受け付けておりません。<br />
                ● ご応募の際、キャンペーン事務局に送られた質問やご意見等については返答いたしかねます。<br />
                ● ご応募の際にかかる通信料は応募者負担とさせていただきます。<br />
                ● P&Gジャパンの社員とその家族、及び関係者の応募はできません。<br />
                ● 応募者に予告することなく、本キャンペーンの内容の一部または全部を変更し、もしくは本サービスの提供を中止することがあります。P&Gジャパンは、これによって応募者に生じた損害について一切の責任を負いません。<br />
            </p>
            <p className="review-campaign-header">■本キャンペーンに関するお問い合わせ先</p>
            <p className="review-campaign-text">マイレピキャンペーン事務局</p>
            <p className="review-campaign-text"><span>メールアドレス：</span><a href="mailto:campaign_k@myrepi.zendesk.com">campaign_k@myrepi.zendesk.com</a></p>
            <p className="review-campaign-text">お問い合わせの内容によっては回答に日数を要することもございます。<br /> 応募受付の状況、抽選結果やプレゼントの発送に関する個別のお問い合わせにはお答えできません。</p>
        </div>
        <style jsx>{Style}</style>
    </div>
);

export default ReviewCampaignLPContent;
