import React from 'react';
import Style from './DigitalCampaignLP.scss';
import DigitalCampaignLPBanner from './DigitalCampaignLPbanner';
import DigitalCampaignbarcode from './DigitalCampaignbarcode';
import DigitalCampaignLP3Content from './DigitalCampaignLP3Content';

const DigitalCampaignLP3 = () => (
    <>
        <div className="digital-campaign-lp">
            <p className="digital-title"> <span>【ウィスパー公式サイト】 </span></p>
            <DigitalCampaignLPBanner />
            <DigitalCampaignbarcode />
            <DigitalCampaignLP3Content />
            <style jsx>{Style}</style>
        </div>
    </>
);

export default DigitalCampaignLP3;
