import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.footer {
    background-color: white;

    @media (min-width: $breakpoint-min) {
        margin-top: 0;
        padding-bottom: 40px;
        float: left;
        width: 100%;
    }

    .footer-list {
        border-top: 1px solid $purple-darker;
        list-style: none;
        margin: 0;
        padding: 0;
        float: left;
        width: 100%;
        margin-top:40px;
        
        @media (max-width: $breakpoint-max) {
            border-top: 0;
            margin: 0 0 40px;
        }

        li {
            color: $text-black;
            display: inline-block;
            font-family: "Noto Sans JP", sans-serif;
            font-size: 12px;
            font-weight: 500;
            height: 40px;
            line-height: 1.3;
            padding: 0 47px 0 0;

            &.copy {
                padding-top: 13px;
            }

            @media (min-width: $breakpoint-min) {
                padding: 13px 47px 0 0;

                &:last-child {
                    float: right;
                    padding-right: 0;
                }
            }

            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                padding: 13px 46px 0 0;
            }

            @media (max-width: $breakpoint-max) {
                border-bottom: 1px solid #d5d5d5;
                display: block;
                padding: 12px 0 0;
                text-align: center;

                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 80px;
                    padding-top: 30px;
                }
            }

            a {
                color: inherit;

                &:hover {
                    text-decoration: none !important;
                }
            }

            .ad-choices {
                &::before {
                    content: url("https://images.ctfassets.net/8g0fievzqg8k/6DFVOLr1MWemuphaAvtSCN/efd21a1426df599f67be53ea5c173ec1/adchoices-icon.png");
                    left: 0;
                    margin-right: 5px;
                    position: relative;
                    top: 4px;
                }
            }
        }
    }
}

@media (max-width: $breakpoint-max) {
    .container {
        .full-width-mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
`;

export default styles;