const preview = process.env.PREVIEW;
module.exports = `
... on ArticleList {
  articlesText
  menuTitleText
  title 
  articlePromoBanners {
    bannersCollection (limit: 1,preview: ${preview}) {
      items {
        __typename
        ... on SmallPromoBannerCta {        
          nameEn
          imageDt {
            title
            description
            cloudinaryId
            asset{url}}
          imageSp {
            title
            description
            cloudinaryId
            asset{url}}
          bigText
          smallText
          midText
          button {
            ... on ButtonExternal {
              externalUrl
              text
              target}
            ... on ButtonInternal {
              text
              target
              url {seoHead {url}}}}}       
        ... on SmallPromoBannerNoTextCta {
          nameEn
          imageDt {
            title
            description
            cloudinaryId
            asset{url}}
          imageSp {title
            description
            cloudinaryId
            asset{url}}
          button {
            ... on ButtonExternal {
              externalUrl
              text
              target}
            ... on ButtonInternal {
              text
              target
              url {
                seoHead {url}}}}}}}}
  retailersDropdown {
    dropdownTitle
    retailersCollection(limit: 2,preview: ${preview}) {
      items {
        nameEn
        imageDt {
          cloudinaryId
          description
          asset{url}}
        imageSp {
          cloudinaryId
          description
          asset{url}}
        link {label
          url
          newWindow }}}}
  promoBanner {
    nameEn
    imageDt {
      title
      description
      cloudinaryId
      asset{url}
    }
    imageSp {
      title
      description
      cloudinaryId
      asset{url}
    }
    link {
      seoHead {
        url}}
    externalLink}
}
`;
