import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './Pagination.scss';

export default function PaginationActions(props) {
    const { num_pages, current_page, next, previous, handlePagination } = props;
    const [actualPages, setPages] = useState([]);

    const handleClick = (e) => {
        previous(e);
    };

    const pages = [];
    let EndPages = [];
    let startPages = [];
    const middlePages = [];
    const secondPageSet = [];
    const secondLastPageSet = [];

    const handlePageGAEvent = (e) => {
        next(e);
        window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_ratings_action',
            'GAeventAction': 'event_movie_review_more',
            'GAeventLabel': 'whisper_review_seemore',
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    };

    for (let index = 1; index <= num_pages; index++) {
        pages.push(
            <>
                <p 
                    onClick={(e) => handlePagination(e, index - 1)}
                    key={index}
                    className={classNames(index === current_page ? 'active' : '')}
                >
                    {index}
                </p>
                <style jsx> {style} </style>
            </>,
        );
    }

    useEffect(() => {
        if (num_pages <= 5) { setPages(pages); } else if (num_pages > 5) {
            if (current_page === 1) {
                startPages = (
                    <>
                        <p onClick={(e) => handlePagination(e, 0)} className="active">1</p>
                        <p onClick={(e) => handlePagination(e, 1)}>2</p>
                        <p onClick={(e) => handlePagination(e, 2)}>3</p>
                        <p className="dots wpCustomList">...</p>
                        <p className="wpCustomList" onClick={(e) => handlePagination(e, num_pages)}>{num_pages}</p>
                        <style jsx> {style} </style>
                    </>
                );
                setPages(startPages);
            } else if (current_page === num_pages) {
                EndPages = (
                    <>
                        <p onClick={(e) => handlePagination(e, 0)}>1</p>
                        <p className="dots wpCustomList">...</p>
                        <p onClick={(e) => handlePagination(e, num_pages - 2)}>{num_pages - 2}</p>
                        <p onClick={(e) => handlePagination(e, num_pages - 1)}>{num_pages - 1}</p>
                        <p className="active" onClick={() => handlePagination(num_pages)}>{num_pages}</p>
                        <style jsx> {style} </style>
                    </>
                );
                setPages(EndPages);
            } else if (current_page === 2) {
                for (let index = 1; index <= 5; index++) {
                    secondPageSet.push(
                        <>
                            <p onClick={(e) => handlePagination(e, index - 1)} className={classNames(index === current_page ? 'active' : '')}>{index}</p>
                            <style jsx> {style} </style>
                        </>,
                    );
                }
                setPages(secondPageSet);
            } else if (current_page === num_pages - 1) {
                for (let index = num_pages - 4; index <= num_pages; index++) {
                    secondLastPageSet.push(
                        <>
                            <p onClick={(e) => handlePagination(e, index - 1)} className={classNames(index === current_page ? 'active' : '')}>{index}</p>
                            <style jsx> {style} </style>
                        </>,
                    );
                }
                setPages(secondLastPageSet);
            } else {
                for (let index = current_page - 2; index <= current_page + 2 && index <= num_pages; index++) {
                    middlePages.push(
                        <>
                            <p onClick={(e) => handlePagination(e, index - 1)} className={classNames(index === current_page ? 'active' : '')}>{index}</p>
                            <style jsx> {style} </style>
                        </>,
                    );
                }
                setPages(middlePages);
            }
        }
    }, [current_page, num_pages]);
    return (
        <div className="paginationStyles">
            <button
                type="button"
                disabled={current_page <= 1} 
                onClick={(e) => {
                    handleClick(e, current_page - 1);
                }}
            >
                <span>{'<'}</span>
            </button>
            {actualPages}
            <button
                className={current_page >= num_pages ? classNames('disabled') : ''} 
                disabled={current_page >= num_pages}
                type="button"
                onClick={(e) => handlePageGAEvent(e, current_page + 1)}
            >
                <span>{'>'}</span>
            </button>
            <style jsx> {style} </style>
        </div>
    );
}

PaginationActions.propTypes = {
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    num_pages: PropTypes.number,
    current_page: PropTypes.number,
    handlePagination: PropTypes.func,
};
