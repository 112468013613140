import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { storageKeysRemove } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';
import style from './EmailThanksForRegistration.scss';

const EmailThanksForSuccess = (props) => {
    const { heading, spHeading, subheading, spSubheading, leftText, rightText, leftBtnText, rightBtnText } = props.contentProps;
    const resolution = useScreenSize();

    const handleClearSession = () => {
        const crmDetails = `${window.location.hostname}crmDetails`;
        storageKeysRemove([crmDetails, 'doiVerifiyStatus', 'isUserLoggedIn', 'resendCount', 'userData']);
    };

    const handleGAEvents = async (event, eventCate, eventAction, eventLabel) => {
        await window.dataLayer.push({
            'event': event,
            'GAeventCategory': eventCate,
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
        return true;
    };

    const handleCampiganFlow = () => {
        const campiganStatus = handleGAEvents('customEvent', 'event_campaign_action',
            'event_campaign_start', 'Whisper_campaign_start');
        if (campiganStatus) {
            handleClearSession();
            Router.push('/campaign/whisper-request-sampling2020');
        }
    };
    // const handleIdbindFlow = () => {
    //     const idbindStatus = handleGAEvents('customEvent', 'event_lohaco_idbinding_action',
    // 'event_lohaco_idbinding_start', 'Whisper_lohaco_idbinding_start');
    //     if (idbindStatus) {
    //         handleClearSession();
    //         Router.push('/id-bind');
    //     }
    // };

    const handleEditProfile = () => {
        const profileStatus = handleGAEvents('customEvent', 'event_crm_action', 'event_login_update', 'Whisper_login_from_doicomplete');
        if (profileStatus) {
            handleClearSession();
            Router.push('/login');
        }
    };

    if (!resolution) {
        return null;
    }

    return (
        <div className="email-thanks-reg-container">
            <div className="email-thanks-reg">
                <h1
                    className="heading"
                    dangerouslySetInnerHTML={{ __html: resolution.isMobile ? spHeading : heading }}
                />
                <h2
                    className="sub-heading"
                    dangerouslySetInnerHTML={{ __html: resolution.isMobile ? spSubheading : subheading }}
                />
                <div className="section">
                    <div className="section-left">
                        <h3>{leftText}</h3>
                        <div className="cta-container left">
                            <button type="button" className="left-cta" onClick={handleEditProfile}>{leftBtnText}</button>
                        </div>
                    </div>
                    {/* <div className="section-right">
                        <h3>{rightText}</h3>
                        <div className="cta-container right">
                            <button className="right-cta" onClick={handleIdbindFlow}> {"ID連携をする"} </button>
                        </div>
                    </div> */}
                    <div className="section-right">
                        <h3>{rightText}</h3>
                        <div className="cta-container right">
                            <button type="button" className="right-cta" onClick={handleCampiganFlow}>{rightBtnText}</button>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{style}</style>
        </div>
    );
};

EmailThanksForSuccess.propTypes = {
    contentProps: PropTypes.object,
};

export default EmailThanksForSuccess;
