/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useIsInViewport from 'use-is-in-viewport';
import LazyLoad from 'react-lazy-load';
import PromoBannerWithCta from '../PromoBannerWithCta/PromoBannerWithCta';
import CampaignContentItem from '../CampaignContentItem/CampaignContentItem';
import useScreenSize from '../Hooks/useScreenSize';
import Product from '../ProductList/Products/Products';
import Image from '../CloudinaryImage/CloudinaryImage';
import Typography from '../Typography/Typography';
import { getContent } from '../../helpers/uiHelper';
import Style from './CardPromo.scss';

const getImageAndText = (item, resolution) => {
    const image = resolution.isMobile ? item.imageSection.imageSmartphone : item.imageSection.imageDesktop;
    const url = item.button?.url.seoHead.url;

    return resolution.isMobile
        ? (
            <div className="imageSection-wrapper-sp">

                <div className="imageSection-img">
                    <LazyLoad>
                        <Image image={image} loading="lazy" />
                    </LazyLoad>
                </div>
                <Typography
                    className="imageSection-text"
                    component="h2"
                    dangerouslySetInnerHTML={{
                        __html: item.text,
                    }}
                />
                <div className="imageSection-cta">
                    <a href={url} className="cta event_button_click" data-action-detail={url}>{item.button.text}</a>
                </div>

                <style jsx>{Style}</style>
            </div>
        )
        : (
            <div className="imageSection-wrapper-dt">

                <div className="imageSection-wrapper-dt-left">
                    <Typography
                        className="imageSection-text"
                        component="div"
                        dangerouslySetInnerHTML={{
                            __html: item.text,
                        }}
                    />
                    <div className="imageSection-cta">
                        <a href={url} className="cta event_button_click" data-action-detail={url}>{item.button.text}</a>
                    </div>
                </div>
                <div className="imageSection-img">
                    <LazyLoad>
                        <Image image={image} />
                    </LazyLoad>
                </div>

                <style jsx>{Style}</style>
            </div>
        );
};

const renderSectionBody = (item) => {
    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    switch (item.__typename) {
    case 'PromoBannerWithCta':
        return <PromoBannerWithCta {...item} />;
    case 'CampaignContentItem':
        return <CampaignContentItem {...item} />;
    case 'Product':
        return <Product product={item} />;
    case 'ContentLt':
        return getContent(item, resolution);
    case 'ImageAndText':
        return getImageAndText(item, resolution);
    default:
    }
};
const [isInViewport, targetRef] = useIsInViewport();

const CardPromo = ({ title, ...props }) =>
    (
        <div className="cardPromo">
            <h2 ref={targetRef} className={isInViewport ? 'hm-animate title' : 'title hm-animate'}>{title}</h2>
            {props.contentTypeCollection.items && props.contentTypeCollection.items.map((item) => renderSectionBody(item))}
            <style jsx>{Style}</style>
        </div>
    );
CardPromo.propTypes = {
    title: PropTypes.string,
    props: PropTypes.object,
    contentTypeCollection: PropTypes.object,
};

export default CardPromo;
