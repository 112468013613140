import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import Style from '../LotteryCampaign/OpenCampaignlp.scss';
import OpenCampaignLPContent from '../LotteryCampaign/OpenCampaignLPcontent';
import OpenCampaignlogin from './OpenCampainLogin';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const OpenCampaignLP = () => {
    const resolution = useScreenSize();
    if (!resolution) return null;
    const img = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/7bSnhXfShas0luZ55LCkkZ/ceffa22ffc42d89ecf3069799f843ca1/lottery_campaign_SP.jpg' : 'https://images.ctfassets.net/8g0fievzqg8k/34MlNlGmWKpQNdngqLOskc/70e39870aef764dab6b0c29edfa23182/lottery_campaign_PC.jpg';

    return (
        <>
            <div className="lottery-campaign-lp">
                <h1 className="lottery-campaign-banner">
                    <img src={buildContentfullImageUrl(img)} alt="サンドラッグキャンペーン" height="auto" width="auto" />
                    <span>サンドラッグキャンペーン</span>
                </h1>
                <p className="lotery-banner-text">ウィスパー会員に登録（無料）いただいている方の中から、抽選で1000名様に容量が選べる「ウィスパートライアルパック」をプレゼント！<br />
                    ウィスパーなら、サッと吸収、スッと消臭。ちょっとしたUI（尿もれ）が気になる方や本格的に尿ケアをしたい方、ぜひウィスパー会員に登録して、キャンペーンにご応募ください。
                </p>
                <OpenCampaignlogin />
                <style jsx>{Style}</style>
                <OpenCampaignLPContent />
            </div>
        </>
    );
};

export default OpenCampaignLP;
