import React from 'react';
import PropTypes from 'prop-types';
import Style from './SearchInput.scss';
import SearchIcon from '../../UI/SearchInput/SearchIcon';

const SearchInput = (props) => {
    const { placeholder, onSearch, onSearchTermChange, className } = props;

    const onSubmit = (e) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    };

    return (
        <div className={className}>
            <input type="text" placeholder={placeholder} onKeyDown={onSubmit} onChange={onSearchTermChange} />
            <div className="search-icon"><SearchIcon onClick={onSearch} /></div>
            <style jsx>{Style}</style>
        </div>
    );
};

SearchInput.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func,
    onSearchTermChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default SearchInput;
