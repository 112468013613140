/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { find } from 'lodash';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';

const findUrlById = (id, allPages) => {
    const foundPage = find(allPages, (page) => page.sys.id === id);
    return foundPage.seoHead.url;
};

const prepareRichTextContent = (json, allPages) => {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <i>{text}</i>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.HR]: () => <div className="spacing" />,
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const url = findUrlById(node.data.target.sys.id, allPages);
                return <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url}>{children}</a>;
            },
        },
    };
    return documentToReactComponents(json, options);
};

export { prepareRichTextContent };
