/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../CloudinaryImage/CloudinaryImage';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './RelatedArticles.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';

const RelatedArticles = ({ title, articlesCollection }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    const articles = articlesCollection.items;

    return (
        <div className="container">
            <div className="related-articles">
                <div className="spacer-thin" />
                <h3>{title}</h3>
                <div className="row">
                    {articles.map((article, a) => {
                        const  url  = article?.page?.seoHead?.url;
                        const image = resolution.isMobile ? article.imageSp : article.imageDt;
                        return (
                            <div className="col-md-4 related-article" key={a}>
                                <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url} key={a}>
                                    <Image image={image} />
                                    <h4 className={classNameForGA(url)} {...dataActionForGA(url)}>{article.title}</h4>
                                    <h4>{article.title1}</h4>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

RelatedArticles.propTypes = {
    articlesCollection: PropTypes.object,
    title: PropTypes.string,
};

export default RelatedArticles;
