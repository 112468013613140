/* eslint-disable react/prop-types */
import React from 'react';
import TextArea from './TextArea';

const OptionsRenderer = ({ isMultiSelect, label, onSelect, isSelected, showTextArea, onChangeText, textValue }) => (
    <>
        <label className={isMultiSelect ? 'survey_option_checkbox' : 'survey_option_text'}>
            <input
                type={isMultiSelect ? 'checkbox' : 'radio'}
                id={label}
                checked={isSelected}
                value={label}
                onClick={onSelect}
            />
            <label>{label}</label>
        </label>
        {showTextArea && <TextArea onChangeText={onChangeText} shouldDisable={!isSelected} textValue={textValue} />}
    </>
);

export default OptionsRenderer;
