import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */
//    color: #5F3C85;
@import './styles/global-variables';

.thanks-for-lotterycp{
    margin-top: 50px;
    .thanks-for-lotterycp-text {
        font-size: 30px;
        color: #fff;       
        background: url(https://images.ctfassets.net/8g0fievzqg8k/1mxPCSGQFMeYaAdEC9fdaG/53f142bad11014357041bdaf23675bf4/question-box.png) no-repeat 50%;
        padding: 25px;
        height: 94px;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .thanks-for-lotterycp-subtexts{
        color: #5F3C85;
        font-size: 24px;
        font-weight: 500;
        margin:20px 0;
        
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .thanks-for-lotterycp{
        text-align: left;
        .thanks-for-lotterycp-text{
            font-size: 18px;
            height: auto;
            padding: 20px;
            text-align: center;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .thanks-for-lotterycp{
        text-align: left;
    }
}
`;

export default styles;