import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Styles from './Accordion.scss';

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{title}</div>
                <div>{isActive ? <span className="minus">-</span> : <span className="plus">+</span>}</div>
            </div>
            {isActive && <div className="accordion-content">{content}</div>}
            <style jsx>{Styles}</style>
        </div>
    );
};

Accordion.propTypes = {
    title: PropTypes.any,
    content: PropTypes.any,

};

export default Accordion;
