// eslint-disable-next-line no-unused-expressions
if (typeof window === 'undefined') require('dotenv').config();
const axios = require('axios');
const get = require('lodash/get');
const queries = require('../queries');

const baseApiUrl = 'https://graphql.contentful.com/content/v1/spaces/{spaceId}/environments/{envId}';
const environment = process.env.CF_ENVIRONMENT || 'master';
const finalApiUrl = baseApiUrl.replace('{spaceId}', process.env.CF_SPACE_ID).replace('{envId}', environment);
const preview = process.env.PREVIEW;

const buildOptions = (query) => ({
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.CF_DELIVERY_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query }),
});

const surveyParams = {
    campaignId: 87,
    locale: 'ja-JP',
};

const getPageContentTypes = (pageId) => {
    const options = buildOptions(queries.pageContentTypes(pageId, preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data.data.page.contentCollection.items)
        .catch(() => console.log('Error fetching page content types'));
};

const getPage = (pageId, componentsQuery, limit) => {
    const options = buildOptions(queries.singlePage(pageId, componentsQuery, preview, limit));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data.data.page)
        .catch((err) => console.warn(err.response.data, 'Error fetching page'));
};

const getAllPages = () => {
    const options = buildOptions(queries.allPages(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res?.data?.data?.pageCollection?.items)
        .catch((error) => {
            console.log('Error fetching slugs from contentful', error);
            console.warn('Error fetching slugs');
        });
};
const getHeader = () => {
    const options = buildOptions(queries.headerNavigation(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => get(res.data, 'data'))
        .catch(() => console.warn('Error fetching header'));
};

const getFooter = () => {
    const options = buildOptions(queries.footer(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => get(res.data, 'data'))
        .catch(() => console.warn('Error fetching footer navigation'));
};

const getProductCategories = () => {
    const options = buildOptions(queries.productCategories(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => get(res.data, 'data'))
        .catch(() => console.warn('Error fetching product categories'));
};

const getAlgoliaProducts = () => {
    const options = buildOptions(queries.algoliaProducts(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => get(res.data, 'data'))
        .catch((e) => console.warn('Error fetching product categories', e));
};

const getAllRetailerCampaignData = () => {
    const options = buildOptions(queries.retailerCampaignPage(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data.data.retailerPromotionCollection.items[0])
        .catch((e) => console.warn(e));
};

const getSurveyQuestions = () =>{return {}}
   

const getAllSampleCampaignData = () => {
    const options = buildOptions(queries.samplingCampaignPage(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data)
        .catch(() => console.warn('Error fetching slugs'));
};
const getResultPage = () => {
    const options = buildOptions(queries.ResultPage(preview));

    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data.data.resultPageCollection.items[0])
        .catch(() => console.warn('Error fetching slugs'));
};

const getFiftyFivePage = () => {
    const options = buildOptions(queries.fiftyfive(preview));
    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => res.data.data.fiftyfivePromotionCollection.items[0])
        .catch(() => console.warn('Error fetching slugs'));
};

const getArticleCategories = () => {
    const options = buildOptions(queries.articleCategories(preview));
    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => get(res.data, 'data.articleListCollection.items[0]'))
        .catch(() => console.warn('Error fetching article categories'));
};

const getApiFormatter = (answers, products) => {
    const response = {};
    response.campaignId = 42;
    response.locale = 'ja-JP';
    response.surveyId = Number(process.env.SAMPLING_CAMPAIGN_DIAGNOSIS_SURVEY_ID);
    response.userRequest = answers;
    response.recommenderServiceResponse = products;
    return response;
};

const getProductDetailsByGTIN = (GTIN) => new Promise((resolve, reject) => {
    const options = buildOptions(queries.productCollection(GTIN,preview));
    return axios
        .post(finalApiUrl, options.body, { headers: options.headers })
        .then((res) => {
            let resultData = productMapperDetails(res)
            resolve (resultData)
       
        })
        .catch((err) => {
            reject(err);
        });
})
const productMapperDetails =(res)=>{
    let resultVal = {}
    if(res?.data?.data?.productCardCollection?.items && res?.data?.data?.productCardCollection?.items.length>0){
        resultVal = res?.data?.data?.productCardCollection?.items[0]
    }else if(res?.data?.data?.productCollection?.items && res?.data?.data?.productCollection?.items.length>0){
        let prods = (res?.data?.data?.productCollection?.items && res?.data?.data?.productCollection?.items.length>0) ? res?.data?.data?.productCollection?.items[0]:{}
        let productName = prods?.name 
        let productImage = prods?.imagesCollection?.items[0]?.image                   
        if(productName?.includes('cc')){
                productName = productName?.split(" ")[0]
        }
        resultVal = {
            productCategory : productName,
            title: (prods?.absorbency?.name) ? prods?.absorbency?.name : prods?.size?.name,
            imageDt:{
                title:productImage?.title,
                asset:{
                    url:productImage?.asset?.url
                }
            }
        }
    }
    return resultVal
}

module.exports = {
    getPage,
    getAllPages,
    getHeader,
    getFooter,
    getProductCategories,
    getAlgoliaProducts,
    getArticleCategories,
    getPageContentTypes,
    getSurveyQuestions,
    getApiFormatter,
    getAllRetailerCampaignData,
    getResultPage,
    getFiftyFivePage,
    getAllSampleCampaignData,
    getProductDetailsByGTIN
};
