import React from 'react';
import Style from '../ReviewCampaign/ReviewCampaignLP.scss';
import TsuruhaCampaignBanner from './TsuruhaCampaignBanner';

const ReviewCampaign = () => (
    <div className="review-campaign-lp">
        <TsuruhaCampaignBanner />
        <style jsx>{Style}</style>
    </div>
);

export default ReviewCampaign;
