import css from 'styled-jsx/css';

const styles = css`.custom-content{
    @media (min-width: 1024px) {
        display: block;
        float: left;

    }
}`;

export default styles;