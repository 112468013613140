const preview = process.env.PREVIEW;
module.exports = `
... on ProductCategoryLineup {
  categoriesLineup: productCategoryLineupItemsCollection(limit: 4,preview: ${preview}) {
    items {
      name
      nameEn
      description
      imageDt {
        cloudinaryId
        title
        description
        asset{url}
      }
      imageSp {
        cloudinaryId
        title
        description
        asset{url}
      }
      button {
        title
        text
        url {
          seoHead {
            url
          }
        }
      }
    }
    }
}
`;
