module.exports = `
... on PromoBanner {
  nameEn
  heading
  imageDt {
    title
    description
    cloudinaryId
    asset{url}
  }
  imageSp {
    title
    description
    cloudinaryId
    asset{url}
  }
  link {
    __typename
    ...on Page {
      seoHead {
        url
      }
    }
  }
  jumpLinkId
  externalLink
  customClass
}
`;
