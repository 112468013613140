const preview = process.env.PREVIEW;
module.exports = `
... on ProductDiagnosis {
  nameEn
  styles
  banner {
    imageDt {
      description
      cloudinaryId
      asset{url}
    }
    imageSp {
      description
      cloudinaryId
      asset{url}
    }
  }
  content {
    content
    contentMobile
    styles
  }
  promoBanner {
    nameEn
    heading
    imageDt {
      title
      description
      cloudinaryId
      asset{url}
    }
    imageSp {
      title
      description
      cloudinaryId
      asset{url}
    }
    link {
      __typename
      ... on Page {
        seoHead {
          url
        }
      }
    }
    externalLink
  }
  smallPromoBanners {
    banners: bannersCollection(limit: 5,preview: ${preview}) {
      items {
        nameEn
        smallText
        bigText
        midText
        imageDt {
          title
          description
          cloudinaryId
          asset{url}
        }
        imageSp {
          title
          description
          cloudinaryId
          asset{url}
        }
        button {
          ... on ButtonExternal {
            externalUrl
            text
            target
          }
          ... on ButtonInternal {
            text
            target
            url {
              seoHead {
                url
              }}}}}}}
  resultPageCollection(limit:4,preview: ${preview}) {
    items {
      __typename
      ... on StaticHeader {
        nameEn
        imageDt {
          cloudinaryId
          asset{url}
        }
        imageSp {
          cloudinaryId
          asset{url}
        }
      }
      ... on ImageSet {
        imageDesktop {
          description
          cloudinaryId
          asset{url}
        }
        imageSmartphone {
          description
          cloudinaryId
          asset{url}
        }
      }
      ... on PromoBanner {
        nameEn
        heading
        imageDt {
          title
          description
          cloudinaryId
          asset{url}
        }
        imageSp {
          title
          description
          cloudinaryId
          asset{url}
        }
        link {
          __typename
          ... on Page {
            seoHead {
              url
            }
          }
        }
        externalLink
      }
      ... on SmallPromoBannersContainer {
        banners: bannersCollection(limit: 2,preview: ${preview}) {
          items {
            nameEn
            smallText
            bigText
            midText
            imageDt {
              title
              description
              cloudinaryId
              asset{url}
            }
            imageSp {
              title
              description
              cloudinaryId
              asset{url}
            }
            button {
              ... on ButtonExternal {
                externalUrl
                text
                target
              }
              ... on ButtonInternal {
                text
                target
                url {
                  seoHead {
                    url
}}}}}}}}}}
`;
