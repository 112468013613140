import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.small-promo-banners-container {
    margin-bottom: 50px;
    padding-left: 0;
    padding-right: 0;

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: $breakpoint-max) {
        overflow: hidden;
        padding: 0;
    }
}

.small-promo-banner-cta {
    background-repeat: no-repeat;
    background-size: 100%;
    height:185px !important;
    // box-shadow: $box-shadow-25;
    @media only screen and (max-width: $breakpoint-max) {
        position: relative;
        height:auto !important;
    }
    @media (max-width: 320px) {
        height:150px !important;
    }
    &.banner-mobile {
        padding-bottom: 180px;
        width: 100%;
    }

    &.banner-desktop {
        height: 185px;
        width: 100%;
    }

    .text-container {
        align-items: center;
        color: $purple-dark;
        display: flex;
        flex-direction: column;
        float: left;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-weight: 500;
        //font-family: Hiragino6, sans-serif;
        height: 100%;
        justify-content: space-around;
        padding: 25px;
        text-align: center;
        @media only screen and (max-width: $breakpoint-max) {
            padding: 30px 25px 14px 18px !important;
            height: auto !important;
        }
        p {
            margin: 0;
        }

        .banner-text-small {
            font-size: 16px;
            font-weight: 500;
            //font-weight: bold;
            margin-bottom: -5px;
            text-shadow: $text-glow;
        }

        .banner-text-big {
            font-size: 28px;
            font-weight: 500;
            //font-weight: bold;
            margin-bottom: 13px;
            text-shadow: $text-glow;
        }

        .banner-text-mid {
            font-size: 23px;
            font-weight: bold;
            margin: -20px 0 11px;
            text-shadow: $text-glow;
        }

        @media (max-width: $breakpoint-max) {
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0 25px 14px 18px;

            .banner-text-small {
                font-size: 14px;
            }

            .banner-text-big {
                font-size: 24px;
            }

            .banner-text-mid {
                font-size: 20px;
            }
        }
    }

    .cta {
        font-family: inherit;
        font-size: 18px;
        font-weight: 500;
        //font-weight: bold;
        padding: 3px 0;
        width: 150px;

        &::after {
            border-bottom: 7px solid transparent;
            border-left: 12px solid white;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            content: '';
            font-size: 0;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width: $breakpoint-max) {
            font-family: 'M PLUS Rounded 1c', sans-serif;
            //font-family: Hiragino4, sans-serif;
            font-size: 16px;
        }
    }

    @media screen and (min-width: 0\\0) {
        .cta {
            height: 31px;
            line-height: 30px;
        }
    }

    @media (max-width: $breakpoint-max) {
        margin-bottom: 11px;
    }

    .no-text {
        float: right;
        margin-right: 20px;
        margin-top: 123px;
        text-align: center;

        @media (max-width: $breakpoint-max) {
            bottom: 25px;
            float: left;
            margin-left: 18px;
            margin-top: 0;
            position: absolute;
        }
    }
}

.col-md-6 {
    &:first-of-type {
        padding-left: 15px;
        padding-right: 20px;

        @media (max-width: $breakpoint-max) {
            padding-right: 14px;
        }
    }

    &:last-of-type {
        padding-left: 20px;
        padding-right: 15px;

        @media (max-width: $breakpoint-max) {
            padding-left: 15px;
        }
    }
}

`;

export default styles;