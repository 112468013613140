module.exports = ` ...on IdBindLohacoImageText{
    idBindLohacoTableImage{
        imageDesktop{
          cloudinaryId
          description
          asset{url}
        }
        imageSmartphone{
          cloudinaryId
          description
          asset{url}
        }
      }
  idBindLohacoTextHeader
  idBindLohacoText1
  idBindLohacoText2
  idBindLohacoText3
  idBindLohacoText4
  idBindLohacoText5
  idBindLohacoText6 
 
}
  `;
