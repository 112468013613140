/* eslint-disable max-len */
import Axios from 'axios';
import { questionsFetching, questionsSuccessFetching, questionsErrorFetching } from '../reducers/SurveyQuestionsSlice';

const getSurveyQuestions = () => async (dispatch) => {
    try {


        dispatch(questionsSuccessFetching({}));

    } catch (gcsFetchError) {
        console.log('The error received from the GCS API', gcsFetchError);
        dispatch(questionsErrorFetching({ gcsFetchError }));
        // const { message } = formatApiErrors(endScenarioError);
        // dispatch(endScenarioErrorFetching(message));
        // dispatch(setPopUp({ page, type: 'ERROR' }));
    }
};

export default getSurveyQuestions;
