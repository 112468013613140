const excludeUnwantedPages = (categories) =>
    categories.items.map((category, index) =>
    // if (category.page.seoHead.hideInSitemap) {
    //     return null;
    // }

        ({
            ...category,
            articles: {
                items: category && category.articles.items.filter((article) => {
                    const  hideInSitemap  = article?.page?.seoHead?.hideInSitemap;

                    // first item in array contains all articles therefore we are
                    // filtering the first category articles so it doesn't show duplicates
                    return index === 0 ? false : !hideInSitemap;
                }),
            },
        })).filter(Boolean);

export { excludeUnwantedPages };
