module.exports = `... on BannerWithPara {
    nameEn
    bannerImage {imageDesktop {description
      cloudinaryId
      asset{url}}
      imageSmartphone {cloudinaryId
        description
        asset{url}}}title
         extraLine
        paraContent
        styles}`;
