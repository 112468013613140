/* eslint-disable no-undef */
export const addDynatraceActions = () => {
    console.log('PriceSpider Activated');
    let action = null;
    if(typeof dtrum !=='undefined'){
    action = dtrum?.enterAction('PriceSpider Activated', 'click');
    dtrum?.leaveAction(action);
    action = null;
    }
};

export const checkPriceSpiderAvailability = () => {
    const psWidget = (document.getElementsByClassName('ps-wtb') ? document.getElementsByClassName('ps-wtb') : null);
    if ((psWidget !== null) && (psWidget !== undefined)) {
        addDynatraceActions();
    } else {
        (console.log('PriceSpider Inactive'));
    }
};
