import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';

.interview {
    border-bottom: 2px solid $purple-dark;
    color: #595757;
    font-family: 'M PLUS Rounded 1c';
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 60px;
    padding-bottom: 38px;
    width: $desktop-elements-width;

    @media (max-width: $breakpoint-max) {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        justify-content: center;
        padding: 0 20px;
        width: 100%;
    }

    > h1 {
        color: $purple-dark;
        font-family: 'Noto Serif JP', serif;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        margin: 0;
        padding: 0;
        width: 80%;
        word-break: keep-all;

        @media (max-width: $breakpoint-max) {
            font-size: 20px;
            line-height: unset;
        }
    }

    > h2 {
        color: $purple-dark;
        font-family: 'Noto Serif JP', serif;
        font-size: 30px;
        font-weight: 600;
        margin: 0 0 0 -10px;
        padding: 0 0 20px;

        @media (max-width: $breakpoint-max) {
            font-size: 15px;
            margin-top: 30px;
        }
    }

    hr {
        background-color: $purple-dark;
        border: 0;
        color: $purple-dark;
        height: 2px;
        margin: 40px 0;

        @media (max-width: $breakpoint-max) {
            margin: 0;
        }
    }

    .content {
        font-family: 'M PLUS Rounded 1c';
        font-weight: 500;

        .image {
            @media (max-width: $breakpoint-max) {
                margin: 0 !important;
            }

            img {
                width: 300px;
                @media (max-width: $breakpoint-max) {
                    width: 100%;
                }
            }
        }

        h2 {
            color: $purple-dark;
            font-family: 'Noto Serif JP', serif;
            font-size: 35px;
            font-weight: 500;
            padding-left: 55px;
            position: relative;
            width: 80%;
            word-break: keep-all;

            @media (max-width: $breakpoint-max) {
                font-size: 20px;
                padding-left: 0;
            }

            &::before {
                background: url('/static/images/flowers-header-decoration.png') left top no-repeat;
                background-size: contain;
                content: '';
                height: 45px;
                left: 0;
                position: absolute;
                top: 5px;
                width: 50px;

                @media (max-width: $breakpoint-max) {
                    display: none;
                }
            }
        }
    }
}

// Overide large margin because margin is set for homepage
div :global(.small-promo-banners-container) {
    margin-bottom: 40px !important;
}
`;

export default styles;