import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.videofeedback-container{
    font-family: "M PLUS Rounded 1c",sans-serif;
    font-weight: 500;
    width: 940px;
    margin:0 auto;
    color:$text-article;
    .VF-Headersection{
        width:100%;
        float:left;
        text-align: center;
        position: relative;
        h2{
        color:#592c80;
        font-size: 37px;
        line-height: 58px;
         }
    p{
        font-size: 20px;
        line-height:40px;        
        
    }
}

.VF-login-info{
    width:100%;
    float:left;
    text-align: center;
    margin: 20px 0;
    h2{
    color:$purple-dark;
    font-size: 30px;
    line-height:48px;
    }
    p{
        font-size: 18px;
        line-height:38px;  
    } 
    .VF-login-info-cta{
        display: flex;
        justify-content: center;
        @media only screen and (max-width: $breakpoint-max){
            flex-wrap: wrap;
        }
        button{
            background: none;
            border: none;
            color: #fff; 
            width: 280px;
            height: 45px;
            border-radius: 15px;    
            font-weight: 500;       
        }
        .login{
            background: #8347AD;
            margin-right: 10px;
            @media only screen and (max-width: $breakpoint-max){
                float: none;
                margin: 0;
            }
        }
        .register{
            background: url(/static/images/moviereg.png) no-repeat;
            background-size: cover;
            width: 280px;
            height: 45px;
            margin-left: 10px;
            @media only screen and (max-width: $breakpoint-max){
                float: none;
                margin: 0;
            }
        }
    } 

    .VF-Message-Board{
        background:#8347AD;
        float:left;
        width: 100%;
        position: relative;
        margin:20px auto;
        color:#fff;
        padding: 20px 0 10px 0;
        .VF-Message-Board-header{
            color:#fff;
            font-size: 26px;
            float:left;
            text-align: left;
            margin-left: 20px;
        }
        .message{
            background:#fff;
            border: none;
            width: 190px;
            height: 45px;
            border-radius: 15px;    
            font-weight: 500;
            color:$purple-dark; 
           
        }
    }
}
@media only screen and (max-width: $breakpoint-max){
    width:100%;
    margin:50px auto;
    padding:0 20px;
    position: relative;
    .VF-Headersection{
        h2{
        font-size: 17px;
        line-height: 27px;
         }
    p{
        font-size: 12px;
        line-height:20px;        
        
    }
}
.VF-login-info{
    h2{
    color:$purple-dark;
    font-size: 15px;
    line-height:25px;
    }
    p{
        font-size: 12px;
        line-height:20px;  
    } 
    .VF-login-info-cta{
 
        button{
            background: none;
            border: none;
            color: #fff; 
            width: 280px;
            height: 45px;
            border-radius: 15px;    
            font-weight: 500; 
            font-size: 17px; 
                
        }
        .login{
            margin:10px auto;         
        }
        .register{
            margin:10px auto;
        }
    } 

    .VF-Message-Board{
          padding: 10px 0;
        .VF-Message-Board-header{
            font-size: 17px;            
            text-align: center;
            margin:0 auto;
            width: 100%;
        }
        .message{
            width: 150px;
            height: 40px;
            margin:20px 0;
            font-size: 17px;
           
        }
    }
}

}
}`;

export default styles;