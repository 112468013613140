const preview = process.env.PREVIEW;
module.exports = `
... on ProductListContainer {
    name
    heading
    heroBannerCollection(limit:10,preview:${preview}) {
      items {
        bannerId
        altText
        desktopImage {
          url
        }
        smartPhoneImage {
          url
        }
        style
      }
    }
    pageContentCollection(limit:10,preview:${preview}) {
      items {
        __typename
        ... on SectionContainer {
          name
          header
          styles
          sectionContentCollection(limit: 10, preview: ${preview}) {
            items {
              __typename
              ... on Link {
                name
                textValue
                url
                type
                target
                customClass
              }
            }
          }
        }
        ... on ERetailers {
          name
          header
          headerIcon {
            url
          }
          customClass
          retailersCollection(limit: 5, preview: ${preview}) {
            items {
              name
              link
              target
              image {
                url
              }
              customClass
            }
          }
        }
        ... on ProductContainer{
          name
          title
          containerId
          styles
          contentTypeCollection(limit:15,preview:${preview}){
            items{
              ... on ProductCard{
                nameEn
                title
                imageDt{
                  asset{url}
                  description
                }
                imageSp{
                  asset{url}
                  description
                }
                productCategory
                scent
                packSizes
                gtin
                productVariant{
                  url
                  description
                }
                urlId
              }
            }
          }
        }
      }
    }
    customClass
}
`;
