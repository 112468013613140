import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import MobileHeaderNavigation from './MobileNavigation/MobileHeaderNavigation';
import DesktopHeaderNavigation from './DesktopNavigation/DesktopHeaderNavigation';

const Navigation = ({ navigationItems, retailers, loginColumn }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        // This is to ensure there is H1 tag on the serverside while there's no browser object
        // to determine which navigation should be rendered
        return <MobileHeaderNavigation menuItems={navigationItems} retailers={retailers} loginColumn={loginColumn} />;
    }

    if (resolution.isMobile) {
        return <MobileHeaderNavigation menuItems={navigationItems} retailers={retailers} loginColumn={loginColumn} />;
    }

    return <DesktopHeaderNavigation menuItems={navigationItems} retailers={retailers} loginColumn={loginColumn} />;
};

Navigation.propTypes = {
    navigationItems: PropTypes.array,
    retailers: PropTypes.object,
    loginColumn: PropTypes.object,
};

export default Navigation;
