import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import { useRouter } from 'next/router';
import useIsInViewport from 'use-is-in-viewport';
import SectionContainer from '../components/SectionContainer/SectionContainer';
import { getImageUrl } from '../adapters/cloudinary.adapter';
import Typography from '../components/Typography';
import Image from './../components/CloudinaryImage/CloudinaryImage';
import Video from '../components/Video/Video';
import ContentNorma from '../components/ContentLt/ContentNorma';
import CategoryList from '../components/CategoryList/CategoryList';
import ProductCard from '../components/ProductCard/ProductCard';
import SamplingSectionContainer from '../components/Sampling/SamplingSectionContainer';
import { buildContentfullImageUrl } from '../components/ReusableComponents/imageHelper';

export const getSmallPromoBanner = (productList) => (
    <div className="productlist_banner">
        <img src={buildContentfullImageUrl(getImageUrl(productList.imageDt.asset.url, {}))} className="productlist_img" alt="alt" />
        <div className="text_container">
            <span className="banner-text-small">{productList.smallText}</span>
            <span className="bigtext">{productList.bigText}</span>
            <span className="banner-text-mid">{productList.midText}</span>
            <button
                type="button"
                className="btn-round ctaone event_button_click promobtn"
                data-action-detail={productList.button.url ? productList.button.url.seoHead.url : productList.button.externalUrl}
            >
                <a href={productList.button.url
                    ? productList.button.url.seoHead.url : productList.button.externalUrl}
                >
                    {productList.button.text}
                </a>
            </button>
        </div>
    </div>
);

export const isProductPages = (url) => {
    const renewalPages = ['all-product', 'philosophy'];
    return renewalPages.indexOf(url.split('/')[1]) !== -1;
};
export const isAkashiPages = (url) => {
    const renewalPages = ['value'];
    return renewalPages.indexOf(url.split('/')[1]) !== -1;
};
export const isProductDiagnosisPages = (url) => {
    const renewalPages = ['productdiagnosis'];
    return renewalPages.indexOf(url.split('/')[1]) !== -1;
};

export const isCampaignPage = (url) => {
    const campaignPage = ['whisper-lottery2021', 'lotterycp-confirmation', 'lotterycp-thankyou'];
    return campaignPage.indexOf(url.split('/')[2]) !== -1;
};
export const isArticleVideoPage = (url) => {
    const renewalPages = ['incontinence-among-women',
        'urinary-incontinence-and-management-of-life',
        'urinary-incontinence-faq',
        'bladder-leakage-in-women',
        'stress-incontinence',
        'urge-incontinence',
        'frequent-urination',
        'overactive-bladder',
        'sample-template',
        'incontinence-training-for-women',
        'incontinence-and-pregnancy',
        'changes-in-the-bladder-associated-with-menopause',
        'urinary-incontinence-lifestyle',
    ];
    return renewalPages.indexOf(url.split('/')[2]) !== -1;
};
export const isArticlePages = (url) => {
    const articlePages = [
        'drnomura-1',
        'drnomura-2',
        'drnomura-3',
        'drnomura-4',
        'drnomura-5',
        'drnomura-6',
        'changes-in-the-bladder-associated-with-menopause',
        'ignorance-of-urinary-leakage',
        'type',
        'about-urine-leakage',
        'causes-measures',
        'daily-life',
        'positive',
        'urinalysis-physician',
        'urinary-leakage-product',
        'talk-to-your-doctor',
        'cystocele',
        'stress-incontinence',
        'urge-incontinence',
        'pollakiuria',
        'overactive-bladder',
        'treatment-of-overactive-bladder',
        'strengthening-of-pelvic-floor-muscles',
        'pelvic-floor-muscle-training',
        'pregnancy-and-childbirth',
        'menopause',
        'medical-subsidy',
        'elderly-gymnastics-class',
        'drsong-menopause-1',
        'drsong-menopause-2',
        'hirayama-1-care',
        'hirayama-2-choose',
        'hirayama-3-heart',
        'hay-fever-and-urinary-incontinence',
        'nursing-care-1',
        'nursing-care-2'];
    return articlePages.indexOf(url.split('/')[3]) !== -1;
};
export const isAbsorbencyPages = (url) => {
    const absorbencyPageSlugs = ['orimonoandkyusuicare', 'syouryouyou', 'cyuuryouyou', 'ooitoki', 'adult-diaper'];
    return absorbencyPageSlugs.indexOf(url.split('/')[2]) !== -1;
};

export const isLineUpPages = (url) => {
    const lineUpPageSlugs = ['1mai2yaku', 'ususara-kyusui', 'ususara-anshin', 'ususara-airy', 'ususara-airy-plus', 'ususara-silky'];
    return lineUpPageSlugs.indexOf(url.split('/')[2]) !== -1;
};

export const getRichText = (data) => {
    const className = data.styles ? data.styles : 'richText';
    return (
        <Typography
            className={classNames(
                className,
            )}
            component="div"
            dangerouslySetInnerHTML={
                {
                    __html: data.body,
                }
            }
        />
    );
};

export const getInlineRichText = (data) => {
    const className = data.styles ? data.styles : 'richText';
    return (
        <span
            className={classNames(
                className,
            )}
        >{data.body}
        </span>
    );
};


export const getImageSet = (data, resolution) => {
    const router = useRouter();
    const [isInViewport, targetRef] = useIsInViewport();

    const image = (data.imageSmartphone && data.imageDesktop) && (resolution?.isMobile ? data.imageSmartphone : data.imageDesktop);

    const className = data.style ? data.style : '';

    if (data.smartPhoneImage && data.desktopImage) {
        if (!resolution) {
            return null;
        }
        return (

            <div className={classNames(
                className,
            )}
            >
                {resolution.isMobile
                    ? data.smartPhoneImage && data.smartPhoneImage.url && (
                        <img
                            src={buildContentfullImageUrl(data.smartPhoneImage.url)}
                            className={classNames(
                                `${className}sp`,
                            )}
                            alt="alt"
                        />
                    )
                    : data.desktopImage && data.desktopImage.url && (
                        <img
                            src={buildContentfullImageUrl(data.desktopImage.url)}
                            className={classNames(
                                `${className}dp`,
                            )}
                            alt="alt"
                        />
                    )}
            </div>

        );
    }

    return router.asPath === '/philosophy/' || router.asPath === '/philosophy'

        ? (
            <div ref={targetRef} className={isInViewport ? 'hm-animate' : 'hm-hide'}>
                <Image image={image} />
            </div>
        )
        : <Image image={image} />;
};

export const getVideo = (data) => (
    <Video
        src={data}
        className={classNames(data.styles)}
    />
);

export const getSimpleImage = (data) => {
    const className = data.styles ? data.styles : '';
    const { url, description } = data.image;
    return (
        <div className={classNames(
            className,
        )}
        >
            <img
                src={buildContentfullImageUrl(url)}
                alt={description}
            />
        </div>
    );
};

export const getContent = (item, resolution) => {
    const className = item.styles ? item.styles : 'content-lt homepage-lt';
    return (
        <div className={className}>
            <ReactMarkdown
                source={resolution?.isMobile ? item.contentMobile : item.content}
                escapeHtml={false}
                disallowedTypes={[]}
                unwrapDisallowed
            />
        </div>
    );
};

export const getAdvancedRichText = (data, resolution) => {
    if (!resolution) {
        return null;
    }
    return <ContentNorma items={resolution?.isMobile ? data.mobileContentCollection : data.desktopContentCollection} />;
};

export const getProductCard = (data) => {
    const className = data && data.style ? data.style : '';
    const link = data && data.productUrl ? data.productUrl : '';

    return (
        <div className={classNames(
            `${className}productview`,
        )}
        >
            {data.productUrl === null
                ? (
                    <div className="productview-img">
                        <img
                            src={buildContentfullImageUrl(data.productImage.url)}
                            className={classNames(
                                `${className}productviewsub`,
                            )}
                            alt="alt"
                        />
                    </div>
                )
                : (
                    <Link href={link}>
                        <div className="productview-img">
                            <img
                                src={buildContentfullImageUrl(data.productImage.url)}
                                className={classNames(
                                    `${className}productviewsub`,
                                )}
                                alt="alt"
                            />
                        </div>
                    </Link>
                )}
            <div className="product-text">
                <h4 className="productName">{data.productName}</h4>
                <h4 className="productNumber">{data.productWeight}</h4>
            </div>
            <div>
                {data.productUrl === null
                    ? <span className="cta event_button_click">製品詳細</span>
                    : <a className="cta event_button_click" href={link}>製品詳細</a>}
            </div>
        </div>
    );
};
export const renderSection = (section, resolution) => {
    const className = section.styles ? section.styles : '';
    let sectionBackground = {};
    if (section?.backgroundImage?.url) {
        sectionBackground = {
            backgroundImage: `url(${buildContentfullImageUrl(section.backgroundImage
                && section.backgroundImage.url)})`,
        };
    }
    return (
        <SectionContainer
            styles={className}
            background={sectionBackground.backgroundImage ? sectionBackground : null}
        >
            {section.styles === 'productbanner'
                ? <SamplingSectionContainer section={section} resolution={resolution} />
                : section?.sectionContentCollection?.items.map((content) => {
                    switch (content.__typename) {
                    case 'SectionContainer':
                        return renderSection(content, resolution);
                    case 'ImageSet':
                        return getImageSet(content, resolution);
                    case 'ContentTypeRichText':
                        return getRichText(content);
                    case 'SmallPromoBannerCta':
                        return getSmallPromoBanner(content);
                    case 'SwiftTypeTagsContainer':
                        return getAdvancedRichText(content, resolution);
                    case 'SamplingProductCard':
                        return getProductCard(content);
                    case 'Video':
                        return getVideo(content);
                    case 'ProductCard':
                        return <ProductCard product={content} resolution={resolution} />;
                    case 'ContentLt':
                        return getContent(content, resolution);
                    case 'CategoryList':
                        return <CategoryList content={content} resolution={resolution} />;
                    default:
                        return null;
                    }
                })}
        </SectionContainer>
    );
};
