const { ApplicationInsights } = require('@microsoft/applicationinsights-web');

const INSTRUMENTATIONKEY = process.env.AZURE_INSTRUMENTATION_KEY
  || 'f3423740-bc5a-4770-88dd-f8c0c1355c4c';
const brand = 'Whisper';

class TelemetryService {
    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: INSTRUMENTATIONKEY,
                disableFetchTracking: false,
                loggingLevelConsole: 2,
                isCookieUseDisabled: true,
                isBrowserLinkTrackingEnabled: true,
            },
        });
        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer((item) => {
            item.tags.push({ 'ai.cloud.app': brand });
        });
        this.appInsights.trackPageView({});
    }
}

const t1 = new TelemetryService();

const logSuccessEvent = (eventName, startTime, endTime, extraProps) => {
    const duration = Math.abs(endTime - startTime);

    t1.appInsights.trackEvent({
        name: eventName,
        properties: { brand, duration: ((duration % 60000) / 1000).toFixed(0), ...extraProps },
    });
};

const logException = (eventName, startTime, endTime, message, status, stack = '', extraProps) => {
    const duration = Math.abs(endTime - startTime);

    t1.appInsights.trackEvent({
        name: eventName,
        properties: { brand, message, duration: ((duration % 60000) / 1000).toFixed(0), stack, status, ...extraProps },
    });
};

module.exports = { logSuccessEvent, logException };
