import React from 'react';
import PropTypes from 'prop-types';

import FilterBox from './FilterBox';
import useScreenSize from '../../Hooks/useScreenSize';

const Filters = (props) => {
    const { sectionTitles, filters, onFilterChange, activeFilter, toggleFilterbox, openFilterBox } = props;
    const filterNames = Object.keys(filters);
    const resolution = useScreenSize();

    return (
        <>
            {filterNames.map((filter, i) => (
                <FilterBox
                    key={i}
                    activeFilter={activeFilter && activeFilter.value}
                    filters={filters[filter]}
                    name={filter}
                    onFilterClick={onFilterChange}
                    onTitleClick={toggleFilterbox}
                    title={sectionTitles[filter]}
                    isOpen={(resolution && !resolution.isMobile) || openFilterBox === filter}
                />

            ))}
        </>
    );
};

Filters.propTypes = {
    activeFilter: PropTypes.object,
    filters: PropTypes.object,
    onFilterChange: PropTypes.func,
    openFilterBox: PropTypes.func,
    sectionTitles: PropTypes.object,
    toggleFilterbox: PropTypes.func,
};

export default Filters;
