module.exports = (preview) => `
{
  retailers: retailersDropdownCollection(limit: 1,preview:${preview}) {
    items {
      dropdownTitle
      retailersCollection(limit: 5,preview:${preview}) {
        items {
          imageDt {
            cloudinaryId
            description
            asset{
              url
            }
          }
          imageSp {
            cloudinaryId
            description
            asset{
              url
            }
          }
          link {
            url
            title
          }
        }
      }
    }
  }
navigation: footerCollection(where: {name: "Footer"}, limit: 1,preview:${preview}) {
  items {
    navigation {
      navigationColumn: navigationColumnCollection(limit: 10,preview:${preview}) {
        items {
          ... on NavigationItem {
            displayTitle
            nameEn
            link {
              __typename
              ... on ExternalLink {
                url
                newWindow
              }
              ... on Page {
                seoHead {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
products: footerProductsCollection(where: {nameEn: "Footer Products"}, limit: 1,preview:${preview}) {
  items {
    title
    registrationButton{
      label
      url
      title
    }
    categoriesCollection(limit: 10,preview:${preview}) {
      items {
        __typename
        ... on Category {
          name
          category
          page {
            seoHead {
              url
            }
          }
          image {
            title
            cloudinaryId
            description
            asset{
              url
            }
          }
          products: productsCollection(limit: 12,preview:${preview}) {
            items {
              ... on Product {
                __typename
                page {
                  seoHead {
                    url
                  }
                }
                absorbency {
                  name
                }
              }
            }
          }
        }
        ... on CategoryList {
          name
          category
          page {
            seoHead {
              url
            }
          }
          image {
            title
            cloudinaryId
            description
            asset{
              url
            }
          }
          products: productsCollection(limit: 12,preview:${preview}) {
            items {
              ... on ProductCard{
                __typename
                title
              }
            }
          }
        }
      }
    }
  }
  }
}
`;
