const preview = process.env.PREVIEW;
module.exports = `
headerCarouselCollection(limit: 10, ,preview:${preview}) {
  items {
    slidesCollection(limit: 10, ,preview:${preview}) {
      items {
        header
        imageDt {
          cloudinaryId
          asset {
            url
          }
        }
        imageSp {
          cloudinaryId
          asset {
            url
          }
        }
      }
      link {
        __typename
        ...on ExternalLink {
            url
          }
        ...on Page {
          seoHead {
            url
          }
        }
      }
    }
  }
}
`;
