import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.main-container {
    width:100%;
    font-family: "Noto Sans JP";

     .container{
        padding: 0 0 40px 0;
        
            .extraline {
                text-align: center;
                font-size: 20px;
                padding-bottom: 30px;
                font-family: "Noto Sans JP";
                font-weight: 400;
                color: #333333;
                @media only screen and (max-width: 767px){
                font-size: 16px;
                text-align: left;
                line-height: 24px;
                padding-bottom: 9px;

                }
            }
            .profile-title{
            width: 100%;
            // float: left;
            position: relative;
            margin-bottom:50px;
            .title {
                color: #5A3177;
                text-align: center;
                font-size: 20px;
                line-height: 24px;
                position: relative;
                margin: 30px 0 50px 0;
                font-family: "SourceSansPro-Regular";
                // font-family: "Noto Sans JP";
                &::after {
                    position: absolute;
                    content: "";
                    height: 4px;
                    bottom: -16px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 60px;
                    background: #5A3177;
                }   

                @media only screen and (max-width: 767px){
                font-size: 14px;
                margin: 75px 0 70px 0;

                    
                }                
            
        }

    }
        .profile-content-section{
            width:100%;
            position: relative;
            .progile-image-section{
                float:left;
                width:25%;
                max-width: 232px;
                img {
                    width: 100%;
                }
                @media only screen and (max-width: 767px){
                    width: 70%;
                }
            }
            .Profile-copy-section{
                width:74%;
                float:right;
                .head1{
                    color:#5A3177;
                    font-size: 14px;
                    margin-bottom: 20px;
                    font-family: "Noto Sans JP";
                    font-weight: normal;

                }
                .head2{
                    color:#5A3177;
                    font-size: 20px;
                    margin-bottom: 20px;
                    font-family: "Noto Sans JP";
                    font-weight: normal;


                }
                p{
                    width:100%;
                    float: left;
                    font-size: 16px;
                    font-family: "Noto Sans JP";
                    font-weight: 400;

                    color: #333333;
                }

                @media only screen and (max-width: 767px){
                    width: 100%;
                    padding-bottom: 19px;
                    .head1{
                        padding-top: 20px;
                    }
                    
                }
            }

            @media only screen and (max-width: 767px){
                padding: 0 20px;
                text-align: left;
            }
        }
    }

    
}

.Profile-content {
    padding:30px 0 20px;
    @media only screen and (max-width: 767px){
    padding:0
    }
}

 .second-section-mij {
     padding:80px 0 0;
     
        .container{
            padding: 0px 0;
            
            .profile-content-section {
                padding:20px 0;
            .Profile-copy-section{
                float: left;               
            .title {
                // font-family: "SourceSansPro-Regular";
                font-family: "Noto Sans JP";
                font-weight: normal;
                padding : 0 0 20px;
                color: #5A3177;
                font-size: 26px;
                line-height: 40px;

            }          

        }

            .progile-image-section {
                float: right;
            }

        
               
            }

            @media only screen and (max-width: 767px){
                .head2  {
                    width: 100%;
                }
            .profile-content-section{
                padding: 20px;
                text-align: left;
                .Profile-copy-section{
                    width: 100%;
                    float: none;
                    .title { 
                padding : 0 0px;
                font-size: 20px;
                line-height: 35px;


                    }
                }

                .progile-image-section {
                    float: left;
                padding : 10px 0px 5px;

                }
               

               
            }
        }
        }

        @media only screen and (max-width: 767px){
            padding:20px 0 0;
            .container {
                padding-bottom: 0px;
            }
        }
      
    }

.mij-second-profile {
    padding:40px 0 0;
        .container{
            padding: 20px 0;
            .profile-content-section {
                padding:40px 0 60px;
            .Profile-copy-section{
            .title {
                // font-family: "SourceSansPro-Regular";
                font-family: "Noto Sans JP";
                font-weight: normal;
                padding : 0 0 10px;
                color: #5A3177;
                font-size: 26px;
            }
        }
    }
    
    @media only screen and (max-width: 767px){
        padding: 0;
        .profile-content-section{
            padding: 0 20px 55px;
            .Profile-copy-section{
                padding-bottom: 5px;

                p{
                    line-height: 24px;
                }

                // margin-bottom: 75px;
            .title {
                // font-family: "SourceSansPro-Regular";
                font-size: 20px;
                line-height: 35px;
                padding-bottom: 0;
            }
        }
        .progile-image-section {
            margin-bottom: 25px;
        }
        }
    }
}
}
   
.factory-manager-profile {
    padding:10px 0 0;
    .container{
        padding: 20px 0;
        .profile-content-section {
            background-color: #e3d9e8  ;
        .Profile-copy-section{
            width:50%;
            padding: 20px 20px 20px 30px;
            float: left;
            
            .head2,
            .head1{
                margin-bottom: 5px;
                font-weight: normal;
                font-family: "Noto Sans JP";

            }
            .head1{
                margin-top: 20px;
            }
            p.content-para {
                font-size: 14px;
                line-height: 28px;
            }
            .title {
            // font-family: "SourceSansPro-Regular";
            font-family: "Noto Sans JP";
            font-weight: normal;
            padding : 0 0 20px;
            color: #8347ad;
            // font-size: 32px;
            }
    }

    .progile-image-section {
        width:49%;
        max-width: 485px;
        float: right;
        }
        }

        @media only screen and (max-width: 767px){
           
        .profile-content-section{
            padding: 0;
            text-align: left;
            float: none;
            .Profile-copy-section{
                width: 100%;
                padding: 19px 18px 30px;

                .head1{
                    font-size: 9px;
                    margin-top: 0;
                    padding-bottom: 4px;

                 }
                 .head2  {
                     width: 100%;
                 }
                 p.content-para {
                    font-size: 16px;
                    padding-top: 9px;
                    margin-bottom: 7px;
                 }

            }

            .progile-image-section {
                width: 100%;

                float: left;
            }
           

           
        }
    }
    @media only screen and (max-width: 767px){
        padding: 0  20px
    }

    }

    @media only screen and (max-width: 767px){
        padding:0px
    }


}   

.factory-manager-second-profile {
    .Profile-copy-section {
        .content-para {
            font-size: 16px;
            line-height: 24px;
        }

    }
}

.factory-manager-second-profile {
    @media only screen and (max-width: 767px){
    .Profile-copy-section{
        margin-top: 25px;
    }
}
}

.Akashi-promo-banner-section{
    padding:15px 0 0 0;
    .Akashi-promo-content-section{
        width:100%;
        float:left;
        position: relative;
        margin:20px 0 0 0;
        .promo-head{
            color:#8347AD;
            font-family: "Noto Sans JP";
            font-weight: normal;
            font-size: 18px;
            line-height: 30px;
        }
    }
}
   


    
// @media only screen and (max-width: 767px){
//     .main-container {

//     .Profile-content {
//         .profile-content-section{
//             width:100%;
//             position: relative;
//             .progile-image-section{
//                 display: block ;
//                 width:60%;
//                 img {
//                     width: 100%;
//                 }
//             }
//             .Profile-copy-section{
//                 width:100%;
//                 float:right;

//                 .head1{
//                     color:$purple-dark;
//                     font-size: 18px;
//                 }
//                 .head2{
//                     color:$purple-dark;
//                     font-size: 24px;
//                 }
//                 p{
//                     width:100%;
//                     float: left;
//                     font-size: 18px;
//                     color:#000;
//                 }
//             }
//         }
   
//     }       
// }
// }`;

export default styles;