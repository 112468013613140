import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.server-side {
    visibility: hidden;
}

.desktop-navigation {
    font-family: "M PLUS Rounded 1c", sans-serif;

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        position: relative;

        .brandLogoWrapper {
            position: absolute;
            top: 36px;

            .logo {
                position: relative;
                float: left;
                width: 100px;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;

                @media (max-width: $breakpoint-ipad-max) {
                    width: 60px;
                }
            }
        }


        .brandLogoWrapper-h1 {
            top: 36px;

            h1 {
                padding: 0;
                margin: 0;
                line-height: 0 !important;

                a {
                    display: inline-block;
                    text-indent: -9999px;
                }
            }

        }

        .search-wrapper {
            display: flex;
            flex-direction: column;
            position: relative;
            bottom: 0;
        }
    }

    a {
        color: inherit;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            box-sizing: border-box;

            @media (max-width: $breakpoint-max) {
                word-break: unset;
            }
        }

        &.nav-root {
            -ms-flex-align: start;
            align-items: center;
            display: flex;
            justify-content: space-between;

            &>li {
                display: inline-block;
                font-size: 21px;
                line-height: 1.2;
                padding: 30px 10px;
                position: relative;
                text-align: center;
                vertical-align: bottom;

                &:hover {
                    ul {
                        &.nav-sub {
                            display: block;
                        }
                    }
                }

                &:last-child {
                    padding-left: 18px;
                }
            }

            .product-menu-container {
                @include display-flex-center;
                flex-direction: column;
                background-color: $light-purple-bg;

                & .product-menu {
                    @include display-flex-center;
                    flex-direction: row;

                    .card {
                        width: 180px;
                        border: none;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .card-body {
                        text-align: center;
                        background-color: $light-purple-bg;
                        max-height: 100px;

                        p {
                            margin-top: 18px;
                            font-size: 14px;
                        }
                    }

                    .card-media {
                        max-width: 200px;
                        width: auto;
                        padding: 10px;
                    }
                }
            }
        }

        &.nav-sub-sub {
            display: block;
        }

        &.nav-sub {
            background-color: white;
            border-collapse: collapse;
            box-shadow: 6px 6px 10px rgba(black, 0.4);
            display: none;
            left: 50%;
            margin-top: 12px;
            min-width: 100%;
            position: absolute;
            transform: translate(-50%, 0);
            z-index: 999;
            border: 1px solid #dac5e6;

            ul {
                color: $purple;
                font-size: 18px;
                padding: 10px;
            }

            li {
                background-color: #fff !important;
                border: 1px solid $purple-dark;
                border-top: 0;
                color: $purple-dark;
                display: list-item;
                font-size: 21px;
                padding: 5px 11px 6px;
                text-align: left;

                &.product-menu {
                    background-color: transparent !important;
                }

                &.view-all-product::after {
                    color: #fff;
                    background-color: $purple-darker;
                    font-size: 10px;
                    margin-left: 15px;
                    padding: 2px 5px;
                    border-radius: 49%;
                    content: " >";
                    display: none;
                }

                &:hover {
                    a {
                        display: block;

                        &::after {
                            color: #fff;
                        }
                    }
                }

                a {
                    position: relative;

                    &::after {
                        color: #fff;
                        background-color: $purple-darker;
                        font-size: 10px;
                        margin-left: 15px;
                        padding: 2px 5px;
                        border-radius: 49%;
                        content: " >";
                        float: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.Homepage-navigation {
    z-index: 8999;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff !important;
    height: 100px;
    padding: 0;

    .container {
        max-width: 1180px;
        margin: auto;
        padding: 0;
        height: 100%;

        .header-wrapper {
            height: 100%;
            justify-content: left;
            align-items: center;
            padding-top: 10px;

            .updated-header {
                .pop-btn {
                    background-color: $purple-darker;
                    border: 1px solid $purple-darker;
                    border-radius: 30px;
                    padding: 11px 60px;
                    color: #fff;
                    font-family: 'Noto Sans JP';
                    font-weight: bold;

                    .desktop-popup-btn-arrow {
                        position: relative;
                        right: -40px;
                        font-weight: 500;

                        @media (max-width: $breakpoint-ipad-max) {
                            right: -6px;
                        }
                    }

                    @media (max-width: $breakpoint-ipad-max) {
                        padding: 5px 15px;
                        font-size: 10px;
                        top: 40px;
                        right: 60px;
                    }
                }

                .header-popup-btn {
                    background-color: #fff;
                    border: 1px solid $purple-darker;
                    border-radius: 20px;
                    width: 124px;
                    margin: 0 auto 50px;
                    color: $purple-darker;
                    font-size: 13px;
                    padding: 5px;
                    font-family: "Noto Sans JP";
                    font-weight: 500;

                    .modal-popup-close-btn {
                        display: inline-block;
                        float: left;
                        padding-left: 8px;
                    }
                }
            }

            .brandLogoWrapper {
                position: relative;
                top: 0;
                left: 5%;

                @media (max-width: $breakpoint-ipad-max) {
                    top: 0;
                }

                a {
                    img {
                        max-width: 100%;
                        height: auto;
                        top: 0;
                    }
                }
            }

            .Desktop-navigation-container {
                position: relative;
                display: flex;
                min-width: 65%;
                left: 5%;
                padding: 0 0 0 5%;
                justify-content: space-between;
                align-items: center;

                .nav-root {
                    width: auto;
                    position: relative;

                    li {
                        font-family: "Noto Sans JP";
                        font-size: 16px;
                        color: $text-black;
                        padding: 0 10px;
                        font-weight: 500;
                        line-height: 30px;

                        a {
                            color: $text-black;
                            text-decoration: none;
                        }

                        .nav-sub {
                            padding: 20px 20px 50px;
                            min-width: 300px;
                            left: 180px;

                            .heading {
                                font-family: "Noto Sans JP";
                                color: $purple-dark;
                                font-weight: bold;
                                font-size: 16px;
                                padding: 0 0 15px 0;
                                text-align: center;
                            }

                            &>li {
                                padding: 16px 0;
                                margin: 10px 0 0;
                                color: $text-black;
                                text-align: center;
                                width: 100%;
                                border: none;
                                font-size: 10px;
                                line-height: 20px;

                                &>a {
                                    color: $purple-dark;
                                    text-align: center;

                                    &::after {
                                        color: #fff;
                                        background-color: $purple-darker;
                                        font-size: 10px;
                                        margin-left: 15px;
                                        padding: 2px 5px;
                                        border-radius: 49%;
                                        content: " >";
                                    }
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            .nav-sub {
                                width: 250px;

                                &li {
                                    text-align: left;
                                    float: left;

                                    &a {
                                        color: $purple-dark;
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            .nav-sub {
                                width: 700px;
                            }
                        }

                        &:nth-of-type(3) {
                            .nav-sub {
                                li {
                                    float: left;
                                    text-align: left;

                                    a {
                                        text-align: left;
                                        float: left;
                                        text-decoration: none;
                                        color: $purple-dark;
                                    }
                                }
                            }
                        }
                    }
                }

                .search-wrapper {
                    .optionsWrapper {
                        display: flex;

                        .log-menu {
                            padding: 10px;
                            margin: 0 0 0.34rem 0.34rem;
                            font-size: 14px;
                            font-weight: 500;
                            color: $purple;
                            font-family: "Noto Sans JP";

                            .nav-root {
                                display: none;
                                left: 50px;
                                right: auto;
                                width: 185px;
                                margin-top: 12px;
                                min-width: 25%;
                                position: absolute;
                                -webkit-transform: translate(-50%, 0);
                                -ms-transform: translate(-50%, 0);
                                transform: translate(-50%, 0);
                                z-index: 999;
                                background-color: white;
                                border-collapse: collapse;
                                box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
                                padding: 20px;
                                border: 1px solid $purple-light;

                                .nav-sub-sub {

                                    .event_profile_login::after,
                                    .event_profile_register::after {
                                        display: none;
                                    }

                                    li {
                                        padding: 5px;
                                        margin: 5px 0;

                                        a {
                                            &:hover {
                                                text-decoration: none;
                                            }

                                            &::after {
                                                color: #8347ad;
                                                content: " >";
                                                font-size: 16px;
                                                float: none;
                                                font-weight: 500;
                                            }
                                        }

                                        &:hover {
                                            background: #fff;
                                        }
                                    }
                                }

                                &:before {
                                    content: "";
                                    width: 23px;
                                    height: 17px;
                                    position: absolute;
                                    top: -15px;
                                    left: 80px;
                                    right: 0;
                                    margin: 0;
                                    background: url(https://images.ctfassets.net/8g0fievzqg8k/3FwxJ2zAdIngs96PRpDd3L/9ce81f36aa84572bc701702655c086ee/menuArrow.png) no-repeat;
                                    background-size: 100% 100%;
                                }
                            }

                            a {
                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            img {
                                width: 100px;

                                @media (max-width: 1024px) {
                                    width: 60px;
                                }
                            }
                        }

                        .header-login {
                            background-repeat: no-repeat;
                            background-size: 8px;
                            background-position: center left;
                            margin-left: 10px;

                            .nav-root {
                                right: -18px !important;

                                .nav-sub-sub {
                                    li {
                                        padding: 5px !important;
                                        margin: 0 !important;

                                        a {
                                            &::after {
                                                padding-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .nav-root {
                                    display: block;
                                    background: #fff;
                                }
                            }
                        }

                        .header-register {
                            background-image: url("/static/images/register.png");
                            background-repeat: no-repeat;
                            background-size: 15px;
                            background-position: center left;
                            margin-left: 10px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
}

.sticky {
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
}

.navSticky {
    background: transparent;
}

.search-span {
    span {
        display: none;
    }
}`;

export default styles;