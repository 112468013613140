import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Style from './IdBindingMainPageSection2.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const IdBindngMainPageSection2 = (props) => {
    const { headingText, description, rightText3, leftLogoImg1, leftLogoImg2, leftLogoImg3, leftLogoImg4, termAndCondition, disclaimer } = props;

    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    const leftlogo1 = resolution.isMobile
        ? getImageUrl(leftLogoImg1.imageSmartphone.asset.url, {})
        : getImageUrl(leftLogoImg1.imageDesktop.asset.url, {});

    const leftlogo2 = resolution.isMobile
        ? getImageUrl(leftLogoImg2.imageSmartphone.asset.url, {})
        : getImageUrl(leftLogoImg2.imageDesktop.asset.url, {});

    const leftlogo3 = resolution.isMobile
        ? getImageUrl(leftLogoImg3.imageSmartphone.asset.url, {})
        : getImageUrl(leftLogoImg3.imageDesktop.asset.url, {});

    const leftlogo4 = resolution.isMobile
        ? getImageUrl(leftLogoImg4.imageSmartphone.asset.url, {})
        : getImageUrl(leftLogoImg4.imageDesktop.asset.url, {});

    return (
        <>
            <div className="id-binding-main-section2">
                <div className="section2-text">
                    <div className="section2-heading">
                        <h1>{headingText}</h1>
                    </div>
                    <div className="section2-content">
                        <div className=" section2-logo-text section2-logo-texts-1">
                            <img src={buildContentfullImageUrl(leftlogo1)} alt={description} />
                            <div className="logo-text logo-text-1">ご購入されるお店でのお得な<br /><span>限定キャンペーン</span> へのご招待</div>
                        </div>
                        <div className="section2-logo-text section2-right-text">
                            <img src={buildContentfullImageUrl(leftlogo2)} alt={description} />
                            <div className="logo-text logo-text-2">ウィスパーの <span>新キャンペーン</span><span className="bigtext">情報</span>をイチ早くお届け！</div>
                        </div>
                        <div className="section2-logo-text section2-logo-texts-3">
                            <img src={buildContentfullImageUrl(leftlogo3)} alt={description} />
                            <div className="logo-text logo-text-3" dangerouslySetInnerHTML={{ __html: rightText3 }} />
                        </div>
                        <div className="section2-logo-text section2-logo-texts-4">
                            <img src={buildContentfullImageUrl(leftlogo4)} alt={description} />
                            {resolution && resolution.isMobile
                                ? <div className="logo-text logo-text-4">購入特典による <br /><span>クーポン<br />プレゼント!</span></div>
                                : <div className="logo-text logo-text-4">購入特典による <br /><span>クーポンプレゼント!</span></div>}
                        </div>
                    </div>

                </div>
                <div className="section2-richtext" dangerouslySetInnerHTML={{ __html: disclaimer }} />

                <div className="termandconditon"><p>{termAndCondition}</p></div>

            </div>

            <style jsx>{Style}</style>
        </>
    );
};

IdBindngMainPageSection2.propTypes = {
    headingText: PropTypes.string,
    description: PropTypes.string,
    rightText3: PropTypes.string,
    leftLogoImg1: PropTypes.object,
    leftLogoImg2: PropTypes.object,
    leftLogoImg3: PropTypes.object,
    leftLogoImg4: PropTypes.object,
    termAndCondition: PropTypes.string,
    disclaimer: PropTypes.string,
};

export default IdBindngMainPageSection2;
