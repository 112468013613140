import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.survey_questions_container {
    .form_subhead {
        margin: 16px 0 15px 0;
        font-size: 30px;
        text-align: center;
        line-height: 32px;
        color: #592c82;
        font-weight: 500;
        @media only screen and (max-width: 767px) {
            margin: 9px 0 29px 10px;
            font-size: 20px;
            text-align: left;
            line-height: 28px;
            margin-bottom: 0px;
            text-align: center;
        }
    }
    .survey_question_text {
        font-size: 24px;
        color: #fff;
        background: url(https://images.ctfassets.net/8g0fievzqg8k/1mxPCSGQFMeYaAdEC9fdaG/53f142bad11014357041bdaf23675bf4/question-box.png) no-repeat 50%;
        padding: 20px 40px;
        height: 110px;
        width: 95%;
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $breakpoint-max) {
            font-size: 14px;
            width: 100%;
            height: auto;
        }
    }
    .error {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        margin-left: 32%;
        @media only screen and (max-width: 767px) {
            width: 150px;
            font-size: 7px;
        }
    }
    .survey_Count {
        width: 100%;
        @media (max-width: $breakpoint-max) {
            max-width: 100%;
        }
    }
    .options {
        margin-top: 20px;
        max-width: 80%;
        margin: 0px auto;
        .survey_option_text {
            text-align: left;
            float: left;
            width: 100%;
            padding: 12px 50px;
            background: #fff;
            font-weight: 700;
            font-size: 19px;
            position: relative;
            margin-bottom: 1rem;
            top: 30px;
            cursor: pointer;
            @media (max-width: $breakpoint-max) {
                max-width: 100%;
                padding: 12px 20px;
                font-size: 14px;
            }
        }
        @media (max-width: $breakpoint-max) {
            max-width: 100%;
        }
    }
    .survey_option_text {
        [type="radio"]:checked,
        [type="radio"]:not(:checked) {
            position: absolute;
            left: 45px;
            top: 13px;
            height: 18px;
            width: 18px;
            opacity: 0;
            z-index: 999;
            @media (max-width: $breakpoint-max) {
                left: 15px;
                top: 10px;
            }
        }
        [type="radio"]:checked+label,
        [type="radio"]:not(:checked)+label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
        }
        [type="radio"]:checked+label:before,
        [type="radio"]:not(:checked)+label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #8347ad;
            border-radius: 100%;
            background: #fff;
        }
        [type="radio"]:checked+label:after,
        [type="radio"]:not(:checked)+label:after {
            content: "";
            width: 10px;
            height: 10px;
            background: #2196f3;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 100%;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }
        [type="radio"]:not(:checked)+label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        [type="radio"]:checked+label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
    .survey_option_textarea {
        width: 100%;
        height: 50px;
        border: 1px solid #8347ad;
        margin-top: 5px;
        padding: 5px;
    }
    .survey_option_checkbox {
        text-align: left;
        float: left;
        width: 100%;
        padding: 12px 50px;
        background: #fff;
        font-weight: 700;
        font-size: 19px;
        position: relative;
        margin-bottom: 1rem;
        top: 30px;
        [type="checkbox"]:checked,
        [type="checkbox"]:not(:checked) {
            position: absolute;
            left: 45px;
            top: 13px;
            height: 18px;
            width: 18px;
            opacity: 0;
            z-index: 999;
        }
        [type="checkbox"]:checked+label,
        [type="checkbox"]:not(:checked)+label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            line-height: 20px;
            display: inline-block;
        }
        [type="checkbox"]:checked+label:before,
        [type="checkbox"]:not(:checked)+label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #8347ad;
            background: #fff;
            border-radius: 1px;
        }
        // input:checked~.checkmark {
        //     background-color: #2196f3;
        // }
        [type="checkbox"]:checked+label:after,
        [type="checkbox"]:not(:checked)+label:after {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            top: 1px;
            left: 1px;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            border: 5px solid #2196f3;
        }
        [type="checkbox"]:not(:checked)+label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        [type="checkbox"]:checked+label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
        @media only screen and (max-width: 767px) {
           top:0;
        }
        
    }
    .frm-cta-section {
        margin: 54px 0 100px 0;
        @media only screen and (max-width: 767px) {
            margin: 33px 0 50px 0;
        }
    }
 
    .btn-check {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        margin-left: 5%;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10%;
        }
    }
    .frm-cta-section button {
        width: auto;
        padding: 0 30px;
        font-size: 20px;
    }
    .navigation_button.return {
        display: inline-block;
        margin: 0px auto;
        width: 160px;
        cursor: pointer;
        position: absolute;
        bottom: 3%;
        left: 0;
        right: 0;
        button {
            border: none;
            background: transparent;
            cursor: pointer;
        }
        ::before {
            border-bottom: 5px solid transparent;
            border-right: 10px solid #592C82;
            border-left-color: inherit;
            border-top: 5px solid transparent;
            content: '';
            height: 0;
            position: absolute;
            width: 0;
            margin: 10px;
        }
       
    }
    .error {
        color: red;
        font-size: 14px;
        font-weight: 100;
        @media only screen and (max-width: 767px) {
            width: 220px;
            font-size: 10px;
            font-weight: 100;
            margin-left: -110px;
            left: 50%;
            bottom:-6px;
        }
    }
    .navigation_button.next {
        background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
        width: 393px;
        height: 60px;
        color: #fff;
        margin: 20px auto;
        font-size: 30px;
        padding: 5px;
        border: none;
        margin-top: 50px;
        cursor: pointer;
        a {
            text-decoration: none;
            color: white;
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            width: 80%;
            border-radius: 8px;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            width: 393px;
        }
    }
    .frm-cta-section div {
        @media only screen and (max-width: 767px) {
            text-align: center;
        }
    }
}

.options p .survey_option_text:last-child {
    margin-bottom: 200px;
   
    @media only screen and (max-width: 767px) {
        margin-bottom: 200px;
    }
  }
   
.options p label:last-child.survey_option_checkbox {
    margin-bottom: 200px; 
    
}
.survey_option_lastTextArea{
    margin-top: -25px;
}

.options p.survey_option_checkbox .survey_option_textarea:last-child {
    margin-bottom: 200px; 
}

.survey_questions_container .navigation_button.next {
    position: absolute;
    bottom: 6%;
    left: 0;
    right: 0;
    @media (max-width: $breakpoint-max) {
        bottom: 6%;
    }
}

.survey_questions_container .navigation_button.next:after {
    padding: 130px;
    position: absolute;
    right: -400px;
    background-size: contain;
    bottom: -135px;
}

.Flowercontainer {
    position: absolute;
    width: 360px;
    height: 280px;
    background-position: right bottom;
    left: auto;
    content: "";
    background: url(https://images.ctfassets.net/8g0fievzqg8k/3NATsWGOXVCSv6XtMoSr25/7ec9b429b64f473bc2897e1f72daf438/btm-flower.png) no-repeat;
    bottom: -130px;
    right: -23%;
    @media (max-width: $breakpoint-max) {
        height: 25%;
        width: 100px;
        bottom: -50px;
        right: 0;
        left: auto;
        position: absolute;
        background-position: bottom;
        background-size: contain;
        z-index: 0;
    }
}`;

export default styles;