import { includes } from 'lodash';
import { useState, useEffect } from 'react';

const useCurrentPage = (pageSlug) => {
    const [isCurrentPage, setCurrentPage] = useState(false);

    useEffect(() => {
        if (process.browser) {
            const currentPageUrl = window.location.pathname;
            const doesPageMatch = includes([currentPageUrl, `${currentPageUrl}/`, currentPageUrl.slice(0, -1)], pageSlug);

            setCurrentPage(doesPageMatch);
        }
    }, []);

    return isCurrentPage;
};

export default useCurrentPage;
