import React from "react";
import PropTypes from "prop-types";
import cssStyle from "./ERetailers.scss";
import PromoBanner from "../PromoBanner/PromoBanner";

const ERetailers = ({
  retailersCollection,
  customClass,
  header,
  headerIcon,
}) => {
  
  return (
    <div className={`eretailer-container ${customClass}`}>
      <h1>
        {header}{" "}
        <span>
          <img src={headerIcon.url} alt='shopping cart icon' />
        </span>
      </h1>
      <div className="retailbtn-container">
        {retailersCollection?.items?.map((retailer) => {
          return (
            <a
              href={retailer.link}
              aria-label={retailer.name}
              target={retailer.target}
              className="image-btn"
            >
              <img src={retailer.image.url} alt={retailer.altText??retailer.name} />
            </a>
          );
        })}
      </div>
      <style jsx>{cssStyle}</style>
    </div>
  );
};

ERetailers.propTypes = {
  retailersCollection: PropTypes.object,
  customClass: PropTypes.string,
  header: PropTypes.string,
  headerIcon: PropTypes.object,
};

export default ERetailers;
