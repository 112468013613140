import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './IdBindingMainPageSection4.scss';

import { getImageUrl } from '../../adapters/cloudinary.adapter';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const IdBindngMainPageSection4 = (props) => {
    const { leftImage, leftLogoText, middleText, rightImage } = props;

    let leftImg;
    let rightImg;
    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    if (leftImage) {
        leftImg = resolution.isMobile
            ? getImageUrl(leftImage.imageSmartphone.asset.url, {})
            : getImageUrl(leftImage.imageDesktop.asset.url, {});
    }

    if (rightImage) {
        rightImg = resolution.isMobile
            ? getImageUrl(rightImage.imageSmartphone.asset.url, {})
            : getImageUrl(rightImage.imageDesktop.asset.url, {});
    }
    return (
        <div className="id-binding-main-section4">
            <div className="main-page-section4">
                {leftImg ? (
                    <div className="left-logo-section">
                        <div className="left-Image">
                            <img src={buildContentfullImageUrl(leftImg)} loading="lazy" alt={leftImage.imageDesktop.description} />
                        </div>
                        <div className="left-txt">
                            <p>{middleText}</p>
                        </div>
                    </div>
                ) : (
                    <div className="section4-text">
                        <p>{leftLogoText}
                        </p>
                    </div>
                )}
                {middleText ? (
                    <div className="middle-text">
                        <p>
                            {middleText}
                        </p>
                    </div>
                ) : ''}
                {rightImg ? (
                    <div className="right-image">
                        <img src={buildContentfullImageUrl(rightImg)} loading="lazy" alt={rightImage.imageDesktop.description} />
                    </div>
                ) : ''}
            </div>

            <style jsx>{Style}</style>
        </div>
    );
};

IdBindngMainPageSection4.propTypes = {
    leftImage: PropTypes.object,
    rightImage: PropTypes.object,
    leftLogoText: PropTypes.string,
    middleText: PropTypes.string,
};

export default IdBindngMainPageSection4;
