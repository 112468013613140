import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.question-box {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin-top: 30px;
    .list {
        width: 48%;
        position: relative;  
        border: 2px solid #898c8e;
        padding: 70px 0;     
        margin:0 20px 20px 0;
        display:flex;
        align-items:center;
        justify-content: center;
        &:nth-child(even){
            margin-right: 0 !important;
        }
        label {
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            font-family: 'Noto Sans JP';
            font-size:21px; 
            color:#898c8e;          
            &:hover {
                cursor: pointer;
            }
            input {
                display: none;
            }
            input[type="radio"]:checked + .text {
                color: #8347ad;
            }
            
            // [type="radio"]:checked ~ span,
            [type="radio"]:checked ~ span.checkmark {
                width: 40px;
                height: 40px;
                background:url(/static/images/tick.png);
                background-size: 40px 40px;
                &:after {
                    display: block;
                }
            }
            .checkmark {
                position: absolute;
                top: 0;
                right: 0;
                height: 40px;
                width: 40px;
            }
            
        }
       
      }   
      .list-selected{
        border: 2px solid #8347ad;
      }
    

    button {
        border: none;
        box-shadow: none;
        font-weight: 700;
        font-family: "M PLUS Rounded 1c", sans-serif;
    }
    button.next {
        // background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png)
        //     no-repeat;
        width: 393px;
        height: 60px;
        color: #fff;
        margin: 20px auto;
        font-size: 24px;
        background: #8347ad;
        border-radius: 20px;
    }
    button.previous {
        display: block;
        background: none;
        margin: 0 auto;
        font-size: 20px;
        color: #8347ad;
        background: url(https://images.ctfassets.net/8g0fievzqg8k/2INhWydQYypCgpRdkFPjI6/5de868c1847a8bd5d21443f27fd00808/prev-arr.png)
            no-repeat 10% 60%;
        padding: 0 23px;
        background-size: 8px;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        .list {
            width: 100%;
            position: relative;
            border: 2px solid #898c8e;
            padding: 50px 0;
            margin:10px 0;
            &:nth-child(even){
                margin: 10px auto !important;
            }
            label {
                width: 100%;
                text-align: center;
                margin-bottom: 0;
                font-family: 'Noto Sans JP';
                font-size:21px; 
                color:#898c8e;          
                &:hover {
                    cursor: pointer;
                }
                input {
                    display: none;
                }
                input[type="radio"]:checked + .text {
                    color: #8347ad;
                }
                // [type="radio"]:checked ~ span,
                [type="radio"]:checked ~ span.checkmark {
                    width: 40px;
                    height: 40px;
                    background:url(/static/images/tick.png);
                    background-size: 40px 40px;
                    &:after {
                        display: block;
                    }
                }
                .checkmark {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 40px;
                    width: 40px;
                }
                
            }
           
          } 
          .list-selected{
            border: 2px solid #8347ad;
          }
        button.next {
            background: url(https://images.ctfassets.net/8g0fievzqg8k/7pPpjUTGz7oDquuOoSeKmi/a7b6825bbf7c7e2c113cf20147239d7d/next-btn-sp.png)
                no-repeat;
            width: 200px;
            height: 35px;
        }
    }
}
@media only screen and (max-width: 767px) {
    .product-diagnosis + .full-width-mobile {
        margin-top: 0px;
    }
}

`;

export default styles;