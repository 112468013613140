/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useScreenSize from '../Hooks/useScreenSize';
import SlickDefaultStyle from '../../../styles/slick.scss';
import Style from './HeaderCarousel.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import Image from '../CloudinaryImage/CloudinaryImage';

const CustomArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={`${className} event_online_tool`}
            data-action-detail={className}
            onClick={onClick}
        />
    );
};

const HeaderCarousel = (props) => {
    const resolution = useScreenSize();
    const { items } = props.slidesCollection;
    // const [visibleStatus, setVisibleStatus] = useState(true);

    if (!resolution) {
        return null;
    }

    const settings = {
        dots: !resolution.isMobile,
        infinite: true,
        lazyLoad: 'ondemand',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow />,
    };

    const getLinkUrl = (link) => {
        switch (link.__typename) {
        case 'ExternalLink':
            return link.url;
        case 'Page':
            return link.seoHead.url;
        default:
            return '';
        }
    };

    const renderItem = (item, i) => {
        if (item.link) {
            return (
                <div key={i}>
                    <a className={classNameForGA(getLinkUrl(item.link))} {...dataActionForGA(getLinkUrl(item.link))} href={getLinkUrl(item.link)}>
                        <Image image={resolution.isMobile ? item.imageSp : item.imageDt} />
                    </a>
                </div>
            );
        }
        return (
            <div key={i}>
                <Image image={resolution.isMobile ? item.imageSp : item.imageDt} />
            </div>
        );
    };

    // const handleOnClose = () => {
    //     window.sessionStorage.setItem('popupStatus', true);
    //     setVisibleStatus(false);
    // };
    // const handleClickLink = async () => {
    //     await window.sessionStorage.setItem('popupStatus', true);
    //     if (window.sessionStorage.getItem('popupStatus')) {
    //         window.location = `${window.location.origin}/campaign/whisper-request-sampling2020/`;
    //     }
    // };
    // const localStgPopupStatus = window.sessionStorage.getItem('popupStatus');
    // const locationStatus = window.location.pathname === '/';

    return (
        <div className="header-carousel">
            <Slider {...settings}>
                {items.map(renderItem)}
            </Slider>
            <div className="bottom-cover" />
            {/* {resolution.isMobile && !localStgPopupStatus && visibleStatus && locationStatus && (
                <Dialog open={true}>
                    <div className="">
                        <DialogTitle className="Loader-overlay headerHide" component="div">
                            <div className="popupContainer relative">
                                <div className="text-right">
                                    <span onClick={handleOnClose} className="popCloseIcon"><img
                                        src="https://images.ctfassets.net/8g0fievzqg8k/5cfUTxaWbJ7aMz5oaeq32q/7227dc70e167e20b7e57f902794fefbe/
                                             home_popup_close_02.png"
                                        alt="close-icon"
                                    />
                                    </span>
                                </div>
                                <div onClick={handleClickLink}>
                                    <div id="homePagePopup" className="homePagePopup" onClick={handleOnClose} />
                                </div>
                            </div>
                        </DialogTitle>
                    </div>
                </Dialog>
            )} */}
            <style jsx>{SlickDefaultStyle}</style>
            <style jsx>{Style}</style>
        </div>
    );
};

HeaderCarousel.propTypes = {
    slidesCollection: PropTypes.object,
};

CustomArrow.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default HeaderCarousel;
