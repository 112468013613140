module.exports = ` ...on IdBindingBanner
{
  title
  image
  {
    imageDesktop
    {
      cloudinaryId
      description
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      description
      asset{url}
    }
  }
  imageText
}`;
