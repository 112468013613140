import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';

.reviews {
    align-items: center;
    color: $text-black;
    display: flex;
    flex-wrap: wrap;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 14px;
    justify-content: flex-start;
    width:106%;



    .stars {
        color: $purple;
        font-size: 18px;
        margin-right: 10px;

        .star {
            display: inline-block;
            position: relative;
            width: 18px;

            .empty {
                left: 0;
                position: absolute;
                top: -22px;
            }

            .full {
                color: $purple-dark;
                display: none;
                left: 0;
                overflow: hidden;
                position: absolute;
                top: -22px;
            }

            &.on {
                .full {
                    display: block;
                }
            }
        }
        @media (max-width: $breakpoint-max){
            margin:10px 21px 10px 0px;
        }
    }

    .numbers {
        margin-right: 15px;
        @media (max-width: $breakpoint-max){
            margin:0 15px 10px 0;
        }

        &.hidden {
            visibility: hidden;
            // @media (max-width: $breakpoint-max){
            //     display:none;
            // }

        }
    }

    a {
        color: $text-black;
        text-decoration: underline;
        @media (max-width: $breakpoint-max){
            margin: 0 0 20px 0;
        }
    }
}

.search-results {
    .result-list {
        .info {
            .reviews {
                display: block;
            }
        }
    }
}

.product-list {
    .category-list {
        .info {
            .reviews {
                display: block;
            }
        }
    }
}

.col-product-data {
    .reviews {
        a {
            @media (max-width: $breakpoint-max) {
                text-decoration: underline;
            }
        }

        .stars {
            margin: 2px 15px 2px 0;
        }

        .numbers {
            margin-top: -1px;
        }
    }
}
`;

export default styles;