import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.content-wrapper {
    width: 100%;
    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding: 0 15px;
        margin: 0px auto;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding: 0 15px;
        margin: 0px auto;
    }
    .landing {
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-weight: 500;
        width: $desktop-elements-width;
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            width: 100%;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            max-width: 100%;
        }
        @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
            padding: 0 15px;
            width: 100%;
        }
        .landing-header {
            display: grid;
            position: relative;
            width: 100%;
            h1 {
                top: 0;
                color: #e11383;
                font-size: 48px;
                font-weight: 500;
                line-height: 58px;
                /* margin-bottom: 0; */
                word-break: keep-all;
                text-align: center;
                position: absolute;
                /* bottom: 0; */
                margin-top: 630px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    font-size: 30px;
                    width: 40%;
                    line-height: 40px;
                    word-break: keep-all;
                    text-align: center;
                    position: relative;
                    top: -130px;
                    margin: 0px auto;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    font-size: 20px;
                    line-height: 40px;
                    text-align: center;
                    width: 100%;
                    margin: 0px auto;
                    position: relative;
                    top: -100px;
                    margin-top: 16px;
                }
                @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                    font-size: 30px;
                    width: auto;
                    line-height: 40px;
                    word-break: keep-all;
                    text-align: center;
                    position: relative;
                    top: -130px;
                    margin: 0px auto;
                }
            }
            img {
                max-width: 100%;
                height: auto;
            }
            h2 {
                color: $text-article;
                font-size: 24px;
                font-weight: 500;
                line-height: 40px;
                margin: 0;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    font-size: 20px;
                    line-height: 28px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                @media (max-width: $breakpoint-antediluvian-sp-max) {
                    font-size: 19px;
                }
            }
            h3 {
                color: #592c82;
                font-size: 36px;
                font-weight: 500;
                line-height: 58px;
                margin-bottom: 0;
                word-break: keep-all;
                text-align: center;
                margin: 0px;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    font-size: 30px;
                    line-height: 40px;
                    // padding-left: 15px;
                    padding-right: 15px;
                    text-align: left;
                    margin-top: 35px;
                }
                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                @media (max-width: $breakpoint-antediluvian-sp-max) {
                    font-size: 20px;
                }
            }
            h4 {
                color: #592c82;
                font-size: 24px;
                margin-bottom: 0;
                @media only screen and (max-width: 1024px) {
                    font-size: 20px;
                    margin-top: 15px;
                }
            }
            h5 {
                color: #592c82;
                font-size: 18px;
                width: width;
                margin: 0 auto;
                line-height: 40px;
                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    font-size: 20px;
                }
            }
            h6 {
                color: #ffffff;
                font-size: 48px;
                font-weight: 500;
                line-height: 58px;
                margin-bottom: 0;
                word-break: keep-all;
                text-align: center;
                position: absolute;
                top: 380px;
                width: 100%;
                @media (max-width: $breakpoint-max) {
                    width: 100%;
                    font-size: 37px;
                    font-weight: 500;
                    line-height: 58px;
                    margin-bottom: 0;
                    text-align: center;
                    color: #ffffff;
                    position: relative;
                    top: -160px;
                    margin: 0px auto;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    font-size: 16px;
                    top: -100px;
                }
            }
            .subsection {
                width: 80%;
                margin: 0 auto;
                position: relative;
                margin-top: 60px;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    width:100%;
                    margin-top: -100px;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    width: 100%;
                    margin-top: -80px;
                }
                @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                    width: 100%;
                    margin-top: -100px; 
                }
                .des {
                    font-size: px;
                    width: 700px;
                    margin: 0 auto;
                    margin-top: 40px;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        margin-top: 8px;
                        width: 100%;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        margin-top: 20px;
                        width: 100%;
                    }
                    @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                        width: 100%; 
                        margin-top: 10px;
                    }
                }
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                display: block;
            }
            @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                display: block;
            }
        }
        .landing-textone {
            width: 90%;
            color: #592c82;
            font-size: 24px;
            width: width;
            margin: 0 auto;
            a {
                text-decoration: underline;
                color: inherit;
            }
            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                font-size: 20px;
            }
            @media (max-width: $breakpoint-max) {
                font-size: 16px;
            }
        }
        .landing-text {
            width: 90%;
            color: #592c82;
            font-size: 18px;
            margin: 0 auto;
            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                width: 100%;
                font-size: 16px;
                // margin-top: 30px;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                width: 100%;
                font-size: 16px;
                margin-top: 30px;
            }
            p {
                line-height: 42px;
                font-size: 23px;
                margin: 0px;
                text-align: center;
                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    font-size: 20px;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    text-align: left;
                }
            }
            p+p {
                margin: 47px 0 0;
            }
            b,
            i {
                color: $purple;
                font-style: normal;
                font-weight: 500;
            }
            a {
                color: #592c82;
                text-decoration: underline;
            }
            h2 {
                &+ul {
                    @media (max-width: $breakpoint-max) {
                        // margin-bottom: 66px;
                    }
                }
            }
            h3 {
                &:nth-of-type(1),
                &:nth-of-type(2) {
                    @media (max-width: $breakpoint-max) {
                        // margin-top: 66px;
                    }
                }
            }
            h2 {
                background-color: $purple;
                color: white;
                display: inline-block;
                font-size: inherit;
                font-weight: 500;
                margin: 0;
                padding: 1px 8px 2px;
                @media (max-width: $breakpoint-max) {
                    width: 100%;
                }
                &+ul {
                    margin-top: 0;
                }
            }
            h6 {
                font-size: inherit;
                line-height: 42px;
                margin: 0;
                &:nth-of-type(1),
                &:nth-child(6) {
                    @media (max-width: $breakpoint-max) {
                        margin-top: 66px;
                    }
                }
                &:nth-of-type(3) {
                    @media (max-width: $breakpoint-max) {
                        margin-bottom: 66px;
                    }
                }
            }
            ul {
                margin: 0;
                padding-left: 16px;
                li {
                    list-style-type: none;
                    position: relative;
                    p {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                    &::before {
                        background-color: $purple;
                        border-radius: 100%;
                        content: "";
                        display: block;
                        float: left;
                        height: 6px;
                        margin-left: -16px;
                        margin-top: 19px;
                        width: 6px;
                        @media (max-width: $breakpoint-max) {
                            margin-top: 15px;
                        }
                    }
                }
            }
            &>p {
                &:last-child {
                    margin-bottom: 47px;
                }
            }
        }
        .view-btn {
            background-color: #592c82;
            border: 0;
            border-radius: 25px;
            color: white;
            cursor: pointer;
            display: block;
            font-family: inherit;
            font-size: 24px;
            line-height: 1;
            margin: 2rem 5rem;
            outline: 0;
            padding: 11px 60px;
            position: relative;
            width: 590px;
            margin: 0 auto;
            top: 10%;
            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                width: 100%;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                margin: 0 auto;
                margin-top: 80px;
            }
            @media (max-width: 768px) {
                width: 100%;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                margin: 0 auto;
                margin-top: 10px;
            }
        }
        .landing-view-btn {
            background: #e11383;
            border-radius: 8px;
            display: inline-block;
            font-family: "Helvetica", sans-serif;
            padding: 10px 12px 10px 42px;
            color: white;
            border: 0;
            width: 300px;
            margin: 0 auto;
            font-size: 20px;
            position: relative;
            cursor: pointer;
            a {
                color: #fff;
                text-decoration: none;
            }
            ::after {
                border-bottom: 7px solid transparent;
                border-left: 12px solid white;
                border-right: 7px solid transparent;
                border-top: 7px solid transparent;
                content: '';
                font-size: 0;
                position: absolute;
                right: 4px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
        .sub-productimg {
            margin: 0 auto;
        }
        .main-section {
            width: 100%;
            background-color: #41326b;
            .main-banner {
                width: 95%;
                background-color: #fae5ee;
                margin: 0px auto;
                padding: 30px;
                border-radius: 25px;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    margin-top: 0px;
                    padding: 15px;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    margin-top: 0px;
                    padding: 15px;
                }
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                margin-top: -10px;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                margin-top: -10px;
            }
            @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                margin-top: -10px;
            }
        }
        .product-banner-text {
            width: 100%;
            margin: 0px auto;
            p {
                font-size: 36px;
                color: #fff;
                position: absolute;
                margin-top: 100px;
                text-align: justify;
                margin-left: 170px;
                width: 100%;
            }
            span {
                font-size: 20px;
                color: #fff;
                position: absolute;
                margin-top: 150px;
                text-align: justify;
                margin-left: 190px;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                p {
                    width: 100%;
                    font-size: 12px;
                    text-align: center;
                    margin-top: 35px;
                    margin-left: 0;
                }
                span {
                    width: 100%;
                    font-size: 8px;
                    text-align: center;
                    margin-top: 50px;
                    margin-left: 0px;
                }
            }
            @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                p {
                    width: 100%;
                    font-size: 20px;
                    text-align: center;
                    margin-top: 70px;
                    margin-left: 0px;
                }
                span {
                    width: 100%;
                    font-size: 18px;
                    text-align: center;
                    margin-top: 97px;
                    margin-left: 0px;
                }  
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                p {
                    width: 100%;
                    font-size: 20px;
                    text-align: left;
                    margin-top: 80px;
                }
                span {
                    width: 100%;
                    font-size: 18px;
                    text-align: left;
                    margin-top: 110px;
                    left: -10%;
                }
            }
            @media only screen and (max-width: 320px) {
                p {
                    margin-top: 27px;
                }
                span {
                    margin-top: 42px;
                }
            }
        }
        .btn-banner {
            position: relative;
            width: 100%;
            span {
                font-size: 32px;
                color: #000000;
                float: left;
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    font-size: 20px;
                }
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    font-size: 10px;
                }
                @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                    font-size: 20px; 
                }
            }
        }
        @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
            .btn-banner:last-child a+a {
                margin-top: 46% !important;
            }
        }
        .cta {
            background-color: #592c82;
            border: 0;
            box-sizing: border-box;
            color: white;
            display: block;
            position: absolute;
            word-break: keep-all;
            right: 2%;
            width: 100px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                // display: none;
                // width: 50px;
                margin-left: 50%;
                right: auto;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                background-color: #592c82;
                border: 0;
                box-sizing: border-box;
                color: white;
                display: block;
                position: absolute;
                word-break: keep-all;
                right: 0.5%;
                font-size: 20px;
                width: 90px;
                font-size: 14px;
                text-align: center;
                top: 20px;
            }
            @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
                width:auto !important;
                right:auto;
                margin-left: 55%;
                padding: 4px 20px !important;
                font-size: 16px !important;
            }
        }
        .ctaone {
            background-color: #592c82;
            border: 0;
            box-sizing: border-box;
            color: white;
            display: block;
            position: absolute;
            word-break: keep-all;
            right: 2%;
            width: 100px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {}
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                background-color: #592c82;
                border: 0;
                box-sizing: border-box;
                color: white;
                display: block;
                position: absolute;
                word-break: keep-all;
                right: 0.5%;
                font-size: 20px;
                width: 90px;
                font-size: 14px;
                text-align: center;
                top: 20px;
            }
        }
        .btnOne {
            top: 90px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                top: 70px;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                margin-top: 18%;
                top: 0;
            }
        }
        .btnTwo {
            top: 210px;
            // @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            //     margin-top: 40%;
            //     top:0;
            // }
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                margin-top: 40%;
                top: 0;
            }
        }
        .btnThree {
            top: 310px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
                margin-top: 60%;
                top: 0;
            }
        }
        .btn-round {
            border-radius: 18px;
        }
        .small-promo-banner-cta.cta {
            font-family: inherit;
            font-size: 18px;
            font-weight: 500;
            padding: 3px 0;
            width: 150px;
        }
        .mg-bt {
            @media only screen and (max-width: 767px) {
                font-size: 8px;
                width: auto;
                padding: 2px 19px 2px 10px;
            }
        }
        .img-btn {
            display: block;
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
        .img-mbtn {
            display: none;
            @media only screen and (max-width: 767px) {
                display: block;
            }
        }
        .cta::after {
            border-bottom: 5px solid transparent;
            border-left: 10px solid white;
            border-right: 5px solid transparent;
            border-top: 5px solid transparent;
            content: '';
            font-size: 0;
            position: absolute;
            right: 0;
            top: 45%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            @media only screen and (max-width: 767px) {
                border-bottom: 4px solid transparent;
                border-left: 8px solid white;
                border-right: 4px solid transparent;
                border-top: 4px solid transparent;
            }
        }
        .sub-productimg {
            margin: 0 auto;
            display: block;
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
        .sub-productimgone {
            display: none;
            @media only screen and (max-width: 767px) {
                display: block;
            }
        }
        .main-banner {
            background-color: #592c82;
            text-align: center;
        }
        .btn-round {
            border-radius: 18px;
        }
        .small-promo-banner-cta.cta {
            font-family: inherit;
            font-size: 18px;
            font-weight: 500;
            padding: 3px 0;
            width: 150px;
        }
        .cta::after {
            border-bottom: 5px solid transparent;
            border-left: 10px solid white;
            border-right: 5px solid transparent;
            border-top: 5px solid transparent;
            content: '';
            font-size: 0;
            position: absolute;
            right: 0;
            top: 45%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            @media only screen and (max-width: 767px) {
                border-bottom: 4px solid transparent;
                border-left: 8px solid white;
                border-right: 4px solid transparent;
                border-top: 4px solid transparent;
            }
        }
        .Promo-section {
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            margin-top: 5%;
            .productlist_banner {
                position: relative;
                padding-right: 20px;
            }
            .text_container {
                position: absolute;
                color: #592C82;
                display: flex;
                flex-direction: column;
                padding: 10px;
                float: left;
                bottom: 20%;
                padding: 20px;
                margin-top: 30px;
                text-align: center;
                justify-content: space-around;
                p {
                    font-size: 16px;
                    color: #592C82;
                    font-weight: 500;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .bigtext {
                    font-size: 28px;
                    font-weight: 500;
                    margin-bottom: 13px;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .promobtn {
                    position: relative;
                    margin: 0px auto;
                    cursor: pointer;
                    @media only screen and (max-width: 767px) {
                        margin: 3px;
                    }
                }
                a {
                    text-decoration: none;
                    color: white;
                }
                @media (max-width: $breakpoint-max) {
                    position: absolute;
                    color: #592C82;
                    display: flex;
                    flex-direction: column;
                    // padding: 10px;
                    float: left;
                    top: 0px;
                    // bottom: 20%;
                    // padding: 20px;
                    // margin-top: 30px;
                    text-align: left;
                    justify-content: space-around;
                    p {
                        font-size: 16px;
                        color: #592C82;
                        font-weight: 500;
                        text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                    }
                    .bigtext {
                        font-size: 18px;
                        font-weight: 500;
                        margin-bottom: 13px;
                        text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                    }
                }
            }
            @media (max-width: $breakpoint-max) {
                flex-direction: column;
                margin-bottom: 100px;
            }
        }
        .footerfix {
            display: block;
            width: 100%;
            position: fixed;
            left: 0px;
            bottom: 2px;
            z-index: 9999;
            text-align: center;
            padding: 0 auto;
            background-color: #d2c19a;
            opacity: .9;
            padding: 15px;
        }
    }
    .retailerPromtion {
        width:600px;
        max-width: 600px;
        margin: auto;
        @media only screen and (max-width: 767px) {
            width:100%;
            max-width: 600px;
        }
        .landing-header {
            box-shadow: 0px 10px 10px #9b9b9b;
            @media only screen and (max-width: 767px) {
                margin-top: 10%;
                margin-bottom: 30%;
            }
            .promoimg2 {
                text-align: center;
                img {
                    margin: 0 5% 30px;
                    @media only screen and (max-width: 767px) {
                        margin: 10px 5% 10px;
                    }
                }
                img:first-of-type {
                    margin: 14px 5% 20px 5%;
                }            
            }
            .conditionText {
                font-size: 12px;
                line-height: 18px;
                width: 90%;
                margin: -2% 5% 0 5%;
                .conditionText-disc{
                    font-size: 12px;
                    line-height:18px;
                }
            }
            .promoimg3 {
                z-index: 1;
                position: relative;
            }
            .promoimg4 {
                margin-top: -90px;
                position: relative;
                @media only screen and (max-width: 767px) {
                    margin-top: -15%;
                }
            }
            .promoimg5 {
                text-align: center;
                img {
                    margin: 0 5% 30px;
                    @media only screen and (max-width: 767px) {
                        margin: 10px 5% 10px;
                    }
                }
                img:first-of-type {
                    margin: 37px 5% 20px 5%;
                }            
            }
            .promoimg6 {
                z-index: 1;
                position: relative;
            }
            .promoimg7:first-of-type {
                margin-top: -90px;
                position: relative;
                @media only screen and (max-width: 767px) {
                    margin-top: -15%;
                }
            }
            .promoimg8 {
                text-align: center;
                .conditionText1 {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 44px 10% 10px;
                    text-align: left;
                    @media only screen and (max-width: 767px) {
                        padding:0;
                        font-size: 12px;
                        line-height: 20px;
                        margin: 5% 10% 0px;
                        
                    }
                    p:last-of-type {
                        text-align: center;
                        color:#9400D3;
                        font-size:21px;
                        float:left;
                        width: 100%;
                        padding: 31px 0 12px 0;

                    }
                    @media only screen and (max-width: 767px) {
                        p:last-of-type {
                            text-align: center;
                            color:#9400D3;
                            font-size:10px;
                            float:left;
                            width: 100%;
                            padding: 14px 0 12px 0;
    
                        }
                    }
                    @media (min-width: 767px) and (max-width: 1026px) {
                        p:last-of-type {
                            text-align: center;
                            color:#9400D3;
                            font-size:18px;
                            float:left;
                            width: 100%;
                            padding: 27px 0 12px 0;
    
                        }
                    }
                }
                img {
                    margin: 0 5%;
                }
            }
            .promoimg8:last-of-type{
                .conditionText1{
                    font-size: 20px;
                    line-height: 30px;
                    margin: 33px 10% 10px;
                    text-align: left;
                    @media only screen and (max-width: 767px) {
                        padding:0;
                        font-size: 12px;
                        line-height: 20px;
                        margin: 5% 10% 0px;
                    }
                }
            }
            .promoimg8:first-of-type img {
                padding-bottom: 40px;
                border-bottom: 2px dashed #8347ad;
                @media only screen and (max-width: 767px) {
                    padding-bottom: 25px;
                }
            }
            .promoimg8:last-of-type img {
                margin-bottom: 10%;
            }
        }
    }
}
.retailerPromtion {
    .landing-header {
        .c-promoimg1{
            position: relative;
            padding-top: calc(655 / 600 * 100%);

            img{
                position: absolute;
                top: 0;
            }
        }
        .c-promoimg1+.promoimg2{
            position: relative;
            padding-top: calc(437 / 500 * 100%);

            img{
                position: absolute;
                top: 0;
                left: 0;
            }
        }
          
    }
}
`;

export default styles;