import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.temporary-thanks-reg {
    text-align: center;
    margin: 16px auto 20px;
    width:940px;
    font-family: 'M PLUS Rounded 1c',sans-serif;
    .successMsgInfo {
        color: $purple;
    }
    .errorMsgInfo {
        color: red;
    }
    .heading {
        font-size: 38px;
        color: $purple;
        br{
            display:none;
        }
    }
    .sub-heading {
        font-size: 24px;
        margin-top: 4px;
        color: $text-article;
        br{
            display:none;
        }
    }
    .mail-section {
        border: 1px solid #DCDCDC;
        margin: 0 auto;
        width: 100%;
        margin-top: 40px;
        text-align: left;
        padding: 30px;
        float:left;
        position: relative;
        h3 {
            color: $text-article;
            font-size: 24px;
            .shortdesc-purple {
                color: $purple;
            }
        }        
        .mail-section-desc {
            margin-top: 35px;
            h3 {
                font-size: 24px;
                .shortdesc-purple {
                    color: $purple;
                }                
            }
        }        
    }
    .mail-info-desc {
        position: relative;
        margin-top: 50px;
        border: 4px solid $purple;
        background-color: #FFF5FA;
        width: 100%;
        display: block;
        padding-left: 30px;
        padding-top: 25px;
        padding-bottom: 4px;
        float:left;
        .right-content {
            text-align: left;
            font-size: 20px;
            color: $text-article;
            float:left;
            .content-list {
                .content-list-item {
                    padding-bottom: 10px;
                    .resend-cta{
                        background:none;
                        border:none;
                        color:#007bff;
                        text-decoration: underline;
                        font-weight: 500;
                        &:hover{
                            color:#0056b3;
                        }
                    }
                    .disabled{
                        text-decoration: none;
                        font-weight: normal;
                        color: $text-article;
                        cursor:text;
                        &:hover{
                            color: $text-article;
                        }
                    }
                }
            }
        }
        .left-content {           
            color: $purple; 
            margin-top: 15px;
            color: #8347ad;       
            float:left;
            margin-right:5px;
            .left-content-img{
                height: auto;
                width: 60px;
                margin: 0 auto;
                img{
                    width:100%;
                }
            }
            p {
                margin-top: 0;
            }
        }        
    }
    @media (max-width: $breakpoint-max){
        width:100%;
        margin: 50px auto 100px;
        padding:0 15px;
        .heading {
            font-size: 24px;
            line-height: 28px;
           text-align: left;
           margin:0 0 10px 0;
           br{
            display:block;
        }
        }
        .sub-heading {
            font-size: 16px;
            line-height: 22px;
            text-align:left;
            br{
                display:block;
            }
            
        }
        .mail-section {
            margin-top: 20px;
            padding: 15px;
            h3 {
                overflow-wrap: break-word;
                width: 100%;
                float: left;
                font-size:16px;
                line-height: 22px;
                .shortdesc-purple {
                        color: $purple;
                    }
            }        
            .mail-section-desc {
                margin-top: 35px;
                h3 {
                    font-size: 16px;
                    line-height:22px;
                    .shortdesc-purple {
                        color: $purple;
                    }                
                }
            }        
        }
        .mail-info-desc {
            position: relative;
            margin-top: 25px;
            width: 100%;
            display:block;
            padding: 15px;
            float:left;
            .right-content {       
                font-size: 14px;    
                .content-list {
                    .content-list-item {
                        padding-bottom: 20px;
                    }
                }
            }
            .left-content {
                // display: flex;
                // align-items: center;
                // justify-content: center; 
                float:left;
                margin:10px 40px 20px 40px;  
                width:100%;
                display: block;             
               .left-content-img{
                    height: auto;
                    width: 80px;                 
                    margin: 0 auto;
                    margin-left: 0;
                    margin-right: 25px;
                    float:left;
                }
                p{
                    float:left;
                }
                @media (max-width: 320px){
                    margin:10px 10px 20px 20px; 
                }
            }
        }






    }
    // @media (max-width:  $breakpoint-ipad-max){
    //     width:940px;
    //     text-align: center;
    //     margin: 16px auto 20px;
    //     width:940px;
    //     font-family: 'M PLUS Rounded 1c',sans-serif;
    // }
   
}
`;

export default styles;