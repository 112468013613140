import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isProductPages, isProductDiagnosisPages, isArticlePages, isAkashiPages, isCampaignPage } from '../../helpers/uiHelper';
import Style from './Breadcrumbs.scss';

const Breadcrumbs = ({ allPages, pageInfo, tsuruhaPage, className }) => {
    if (pageInfo.url === '/') {
        return null;
    }

    const deWhisper = (text) => (_.trim(text, 'ウィスパー'));

    const categories = _.reduce(allPages, (acc, page) => {
        if (page.seoHead != null) {
            const { url, breadcrumbsTitle, title } = page.seoHead;
            const path = _.trim(url, '/').split('/');
            if (path.length === 2) {
                acc[path[1]] = breadcrumbsTitle || deWhisper(title);
            }
            return acc;
        }
        return {};
    }, {});

    const topPages = _.reduce(allPages, (acc, page) => {
        if (page.seoHead != null) {
            const { url, breadcrumbsTitle, title } = page.seoHead;
            const path = _.trim(url, '/').split('/');
            if (path[0] === 'article-top') {
                acc[path[0]] = 'お役立ちコラム';
            } else if (path.length === 2) {
                acc[path[0]] = breadcrumbsTitle || deWhisper(title);
            }
            return acc;
        }
        return {};
    }, {});

    const getTopTitle = (pathPart) => (topPages[pathPart]);

    const divider = () => (<span key={Math.random()}> &gt; </span>);
    const pathParts = _.trim(pageInfo.url, '/').split('/');
    let trail = [<a href="/" key={0}>Home</a>];
    const pathText = pathParts.length === 2  && getTopTitle(pathParts[0]);
    switch (pathParts.length) {
    case 1:
        trail = [
            ...trail,
            divider(),
            pageInfo.breadcrumbsTitle || deWhisper(pageInfo.title),
        ];
        break;
    case 2:
        trail = [
            ...trail,
            divider(),
            pathText && <a href={`/${pathParts[0]}/`} key={1}>{pathText}</a>,
            pathText && divider(),
            pageInfo.breadcrumbsTitle || deWhisper(pageInfo.title),
        ];
        break;
    case 3:
        trail = [
            ...trail,
            divider(),
            <a href={`/${pathParts[0]}/`} key={1} aria-label='demo'>{topPages && getTopTitle(pathParts[0])}</a>,
            divider(),
            <a href={`/${pathParts[0]}/${pathParts[1]}/`} key={2} aria-label='demo'>{categories && categories[pathParts[1]]}</a>,
            divider(),
            pageInfo.breadcrumbsTitle || deWhisper(pageInfo.title),
        ];
        
        break;
    default:
        return;
    }
  
    return (
        <div className={isProductPages(pageInfo.url)
            ? 'breadcrumbs-container productPage'
            : isProductDiagnosisPages(pageInfo.url)
                ? 'breadcrumbs-container productDiagnosisPage'
                : isAkashiPages(pageInfo.url)
                    ? 'breadcrumbs-container AkashiPage'
                    : isCampaignPage(pageInfo.url)
                        ? 'breadcrumbs-container CampaignPage'
                        : isArticlePages(pageInfo.url)
                            ? `breadcrumbs-container ArticlePage ${className}`
                            : `breadcrumbs-container ${className}`}
        >
     
            <div className={`container ${tsuruhaPage ? 'tsuruhaPageContainer' : ''}`}>
                {trail}
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

Breadcrumbs.propTypes = {
    allPages: PropTypes.array,
    pageInfo: PropTypes.object,
    tsuruhaPage: PropTypes.bool,
    className: PropTypes.string,
};

const mapStateToProps = (state) => ({ allPages: state.allPages });

export default connect(mapStateToProps)(Breadcrumbs);
