import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './CashbackKV.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const CashbackKV = () => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }
    const kv = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/488CevykWDxtRHn1YZq5FQ/56b0d059a307cfbe3a27850395ebfce2/sp_top_tit01.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2kXCDUus81sgYPZwGMp3vI/9412877fd3d05e148978e03b1dcd11d6/pc_top_tit01.png';
    return (
        <div className="Cashback-KVsection">
            <div className="Cashback-KVsection-content">
                <div className="KV-text">
                    <h3 className="KVsection-head">
                        ※返金金額には対象商品ごとに上限がございます。<br />
                        ※大容量パック、まとめ売り商品は対象外です。
                    </h3>
                </div>
                <div className="KV-image">
                    <img src={buildContentfullImageUrl(kv)} alt={kv} />
                </div>
                <div className="KV-text">
                    <h3 className="KVsection-subhead">
                        ご応募いただく前に必ずお読みください。<br />
                        ご応募いただく場合、本応募要項等にご同意いただいたものとみなします。
                    </h3>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CashbackKV;
