const preview = process.env.PREVIEW;
module.exports = `
... on ProductFilterSection{
    title
    heading
    category1
    category2
    productsCollection(limit:6,preview: ${preview}){
      items {
        __typename
        ... on ProductCategoryLineupItem {
          name
          nameSp
          description
          imageDt {
            cloudinaryId
            title
            description
            asset{url}
          }
          imageSp {
            cloudinaryId
            title
            description
            asset{url}
          }
          link{
            seoHead{
              url
            }
          }
          productType
          filterCategory
        }
      }
    }
  }
`;
