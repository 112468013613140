import React, { useState } from 'react';
import { useSelector, shallowEqual, connect } from 'react-redux';
import PropTypes from 'prop-types';
import Router from 'next/router';
import axios from 'axios';
import Loader from 'react-loader-spinner';
// import { getPreviewPage } from '../src/adapters/contentful.adapter';
import Style from './Preview.scss';
import { Dialog, DialogTitle } from '../Dialog';

const Preview = (props) => {
    // Commenting the below code to fix the Sampling Campaign prod issue occurred after the Customer Coupon Campaign Go-Live.
    // if (!props.userCredentials.profileData.email) {
    //     Router.push('/campaign/whisper-request-sampling2020');
    // }
    const [loaderFlag, setloaderFlag] = useState(false);

    const formatPostBody = ({ answers, questions }) => {
        const newBody = [];
        questions.forEach((question, index) => {
            if (Array.isArray(answers[index])) {
                answers[index].forEach(({ optionId, helptext, textValue }) => {
                    newBody.push({ questionId: question.questionId, optionId: parseInt(optionId, 10), answer: helptext === 'Other' ? textValue : '' });
                });
            } else {
                newBody.push({ questionId: question.questionId, answer: answers[index] });
            }
        });
        console.log('FINAL FORMATED RESPONSE', newBody);
        return newBody;
    };

    const handleSubmit = (questionsData, profileData) => {
        setloaderFlag(true);
        let consumerResponse = window.sessionStorage.getItem(`${window.location.hostname}crmDetails`);
        consumerResponse = JSON.parse(consumerResponse);

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_crm_action',
                GAeventAction: 'event_campaign_complete',
                GAeventLabel: 'Whisper_campaign_complete',
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_survey_action',
                GAeventAction: 'event_survey_complete',
                GAeventLabel: 'Whisper_survey_complete',
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }

        Router.push('/thanksForParticipation');
        setloaderFlag(false);
        
    };

    const handlePreviewPage = (previewPageData) => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_crm_action',
                GAeventAction: 'event_profile_update',
                GAeventLabel: 'Whisper_profile_update_from_preview_page',
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }
        Router.push(
            {
                pathname: '/editprofile',
                query: {
                    question14: previewPageData.question14,
                    editProfileData: previewPageData.editProfileData,
                    surveyPageData: previewPageData.surveyPageData,
                },
            },
            '/editprofile',
        );
    };

    const handleSurveyQuestions = (previewPageData) => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_survey_action',
                GAeventAction: 'event_survey_update',
                GAeventLabel: 'Whisper_question_update_from_preview_page',
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }
        Router.push(
            {
                pathname: '/surveyQuestions',
                query: {
                    question14: previewPageData.question14,
                    editProfileData: previewPageData.editProfileData,
                    surveyPageData: previewPageData.surveyPageData,
                },
            },
            '/surveyQuestions',
        );
    };

    const { answers } = useSelector((state) => state.survey, shallowEqual);
    const { result: questions } = useSelector((state) => state.surveyQuestions, shallowEqual);
    const { profileData, userData } = props.userCredentials;

    return (
        <div className="preview-page-container">
            <div className="preview_text">入力内容をご確認ください。</div>
            <h5 className="preview-profile-text"> お届け先情報</h5>
            <div>
                {(Object.keys(userData).length > 0 || Object.keys(profileData).length > 0) && (
                    <>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">氏名</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {/* {JSON.parse(props.previewPageData)} */}
                                    {profileData && profileData.lastName}
                                    {' '}
                                    {profileData && profileData.firstName}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">メールアドレス</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {profileData && profileData.email}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">郵便番号</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {profileData && profileData.postalCode}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">ご住所</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userData && userData.prefecture}<br />
                                    {profileData && profileData.addressCity}<br />
                                    {profileData && profileData.fullAddress}<br />
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">電話番号</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {profileData && profileData.phoneNumber}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <div className="frm-cta-section container">
                    <div align="center" className="btn-check">
                        <div
                            className="survey_complete"
                            data-event-detail="Whisper_profile_update"
                            onClick={() => handlePreviewPage(props.previewPageData)}
                        >
                            プロフィールを変更する
                        </div>
                    </div>
                </div>

                <div className="survey-question-container">
                    <h5 className="preview-profile-text">アンケート</h5>
                    <div className="preview-profile-survey">
                        {/* {intitalSurveyData && intitalSurveyData.reverse().map(({ surveyQuestionnumber, quest, ans }) => (
                            <>
                                <div className="preview-question-text">
                                    <span className="preview-question-count">Q:{surveyQuestionnumber} </span>
                                    {quest}
                                </div>
                                <div className="preview-answer-text">{ans}</div>
                            </>
                        ))} */}
                        {questions.length > 0
                            && questions.map((question, index) => (
                                <>
                                    {answers[index] && answers[index].length > 0 && (
                                        <>
                                            <div className="preview-question-text">
                                                <span className="preview-question-count">Q:{index + 1} </span>
                                                {question.questionName}
                                            </div>
                                            {Array.isArray(answers[index])
                                                && answers[index].map(({ optiontext, textValue }) => (
                                                    <div className="preview-answer-text">{optiontext === 'その他' ? textValue : optiontext}</div>
                                                ))}
                                            {typeof answers[index] === 'string' && (
                                                <div className="preview-answer-text">{answers[index]}</div>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                    </div>
                    <div className="frm-cta-section container">
                        <div align="center" className="btn-check">
                            <div
                                className="event_Profile_complete"
                                data-event-detail="Whisper_question_update"
                                onClick={() => handleSurveyQuestions(props.previewPageData)}
                            >
                                アンケートを変更する
                            </div>
                        </div>
                        <div align="center" className="btn-check">
                            <div
                                className="survey_complete"
                                onClick={() => handleSubmit({ answers, questions }, profileData)}
                            >
                                次へ
                            </div>
                        </div>
                        <div className="Flowercontainer" />
                    </div>
                </div>
            </div>
            <style jsx global>
                {Style}
            </style>
            {loaderFlag ? (
                <Dialog open={true}>
                    <div className="">
                        <DialogTitle className="Loader-overlay" component="div">
                            <Loader
                                type="TailSpin"
                                color="#8347ad"
                                className="loader"
                                height={100}
                                width={100}
                                visible={true}
                            />
                        </DialogTitle>
                    </div>
                </Dialog>
            ) : null}
        </div>
    );
};

Preview.propTypes = {
    previewPageData: PropTypes.object,
    userData: PropTypes.object,
    userCredentials: PropTypes.object,
};

const mapStateToProps = (state) => ({ allPages: state.allPages, userCredentials: state.userCredentials });

export default connect(mapStateToProps)(Preview);
