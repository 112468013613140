const preview = process.env.PREVIEW;
module.exports = `
...on RetailersDropdown {
  nameEn
  dropdownTitle
  retailersCollection(limit: 5,preview: ${preview}) {
    items {
      imageDt {
        title
        cloudinaryId
        description
        asset{url}
      }
      imageSp {
        title
        cloudinaryId
        description
        asset{url}
      }
      link {
        label
        url
        newWindow
      }
    }
  }
}
`;
