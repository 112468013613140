import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.clearfix {
    overflow: auto;
  }
.Coupon-Campaing-Loginsection{
    width: 100%;
    margin: 20px auto 118px;
    position: relative;    
    .Coupon-Campaing-PI{
        // width: 80%;
        margin: 0 auto;
        text-align: center;
    .pi{
        width:48%;
        text-align: center;
        display: inline-block;
        .txt{
            color:$purple;
            width:100%;
            font-family: "M PLUS Rounded 1c", sans-serif;
            font-weight: normal;
            font-size: 28px;
        }
        .cta{
            background: url('https://images.ctfassets.net/8g0fievzqg8k/68beghVzLHrTImf1Fm85J9/d0871c336f6a0b8552384aa29366a30f/_____________152_2x.png') no-repeat 5px 0px;
            background-size: 100% 100%;
            margin:0 auto;
            text-align:center;
            padding:4px 20px;
            // background:$purple;
            width: 100%;
            height:74px;
            font-family: "M PLUS Rounded 1c", sans-serif;
            font-weight: 500;
            font-size: 28px;
            letter-spacing: 5px;
        }      

        .registration {
            background: url('https://images.ctfassets.net/8g0fievzqg8k/4TjYTvGIc3D3bsbUPyYs14/957f71744975920a073f896428eac167/_____________151_2x.png') no-repeat 5px 0px;
            background-size: 100% 100%;
        }
        
        .log-image-wrap {
            width:100%;
            margin : 40px 0 10px;

            img {
                width: 100%;
            }

        }
        
    }
    .pi-left {
        float: left;
    }
    .pi-right {
        float: right;
    }
    .align-center {
        margin: 0px 25%;
    }

    // .Coupon-Campaing-Login{
    //     // margin-right:20px;
    // }
    
}
@media only screen and (max-width: $breakpoint-max){
    margin: 0px auto 50px;
    .Coupon-Campaing-PI{
        width:100%;
        // padding: 0 20px;
    .pi{
        width:100%; 
        margin:20px 0; 
        .txt{
            font-size: 24px;
        }
        .cta{
            height: 60px;
            font-size: 20px;
            letter-spacing: 1px;
        }    
    }
    
}

}
@media only screen and (min-width: 769px) and (max-width: 1024px)and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2){
    .cta{
        font-size: 22px !important;
    } 
}
    
}


@media only screen and (max-width: 767px) {
.Coupon-Campaing-Loginsection{

    .Coupon-Campaing-PI{

        .pi{
            width: 100%;
            display: block;
        }
        
    }

 }
}`;

export default styles;