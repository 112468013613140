import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
// }

// body {
//     // font-family: Verdana, Geneva, Tahoma, sans-serif;
//     // font-size: 20px;
//     // background: rgb(238, 174, 202);
//     // background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(199, 233, 148, 1) 100%);
// }

h1 {
    text-align: center;
    margin: 2rem 0 4rem 0;
}

.accordion {
    max-width: 600px;
    margin: 2rem auto;
}

.accordion-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    justify-content: space-between;
    cursor: pointer;
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: $purple-dark;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
}

.accordion-item {
    float: left;
    width: 100%;
    border-bottom: 1px solid #000;
}
.accordion-title,
.accordion-content {
    padding: 1rem;
}
.plus {
    border: 1px solid $purple-dark;
    padding: 0;
    border-radius: 80%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.minus {
    background: $purple-dark;
    color: #fff;
    border: 1px solid $purple-dark;
    border-radius: 80%;
    padding: 0;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accordion-content {
    color: #000;
    font-weight: 500;
}

@media screen and (max-width: 700px) {
    body {
        font-size: 18px;
    }

    .accordion {
        width: 90%;
    }
}
`;

export default styles;