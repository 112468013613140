import css from 'styled-jsx/css';

const styles = css`.indicators {
    img {
        padding: 20px 10px;
    }
}
.pageCount {
    width: 100%;
    float: left;
    margin-top: 40px;
    .highlight {
        opacity: 1;
    }
    span {
        opacity: 0.5;
        float: left;
        background: url(https://images.ctfassets.net/8g0fievzqg8k/kMNyfl7yrn6Add0KFjaVS/5247d67ca882ff4f7170d52e5cc61207/right-arrow.png)
            no-repeat 100%;
        width: 156px;
        padding-right: 1%;
        text-align: center;
        &:last-child {
            background: none;
            padding: 0;
        }
        @media only screen and (max-width: 991px) and (min-width: 768px) {
            width: 120px;
        }
        img {
            width: auto;
            height: 135px;
            padding: 5px;
            @media only screen and (max-width: 991px) and (min-width: 768px) {
                height: 105px;
            }
        }
        .sp {
            display: none;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 0 15px;
        margin-top: 20px;
        span {
            width: 16%;
            background: url(https://images.ctfassets.net/8g0fievzqg8k/7sya5AAso8qGQVCz0ZEVhu/9020e8334467e43da25139ee9a483480/step_Two_Points.png)
                no-repeat 88%;
            .highlight {
                div {
                    color: #ed1e84;
                }
            }
            img {
                display: none;
            }
            .sp {
                display: block;
                img {
                    display: block;
                    height: auto;
                }
            }
            &:nth-child(5) {
                background: url(https://images.ctfassets.net/8g0fievzqg8k/5hoE3IyqmGQcni5lfowu10/a89a3872ab7c9f6156b90a5c96d10122/step_Right_Arrow.png)
                    no-repeat 91%;
            }
        }
    }
}
`;

export default styles;