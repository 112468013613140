import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useIsInViewport from 'use-is-in-viewport';
// import aosstyle from 'aos/src/sass/aos.scss';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './CampaignContentItem.scss';

const CampaignContentItem = ({ title, description, styles, cta }) => {
    const resolution = useScreenSize();
    const [isInViewport, targetRef] = useIsInViewport();

    if (!resolution) {
        return null;
    }

    return (
        <div ref={targetRef} className={isInViewport ? 'news-banner hm-animate' : 'news-banner hm-hide'}>

            <div className="banner-section dt">
                <div className={classNames(styles)}>{title}</div>
                <div className="description">{description}</div>
                {cta.externalUrl && cta.externalUrl === '/'
                    ? <span>{cta.title}</span>
                    : <a className="event_button_click" data-action-detail={cta.externalUrl} href={cta.externalUrl}>{cta.title}</a>}
            </div>
            <style jsx>{Style}</style>

        </div>
    );
};

CampaignContentItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    styles: PropTypes.string,
    cta: PropTypes.object,
};

export default CampaignContentItem;
