import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";

body {
    background-image: url(/static/images/Background_Productline_purple.jpg),
                      linear-gradient(176deg,white 704px,#eae3f0 1953px);
    background-position: center 570px,0 170px;
    background-repeat: no-repeat, no-repeat;

    @media (max-width: $breakpoint-max) {
        background: white;
    }
}
`;

export default styles;