import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';

import Style from './ProductCategoryLineup.scss';

import { getImageUrl } from '../../adapters/cloudinary.adapter';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ProductCategoryLineup = ({ categoriesLineup }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    return (
        <div className="product-category-lineup">
            {categoriesLineup.items && categoriesLineup.items.map((item, i) => {
                const cardBgImg = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(item.imageSp?.asset?.url, {}) : getImageUrl(item.imageDt?.asset?.url, {}));
                const backgroundStyle = {
                    backgroundImage: !resolution.isMobile ? `url(${cardBgImg})` : 'none',
                };
                const description = item.description.split(' ');

                return (
                    <React.Fragment key={i}>
                        <div className="card" style={backgroundStyle} key={`card${i}`}>
                            <div>
                                <h4 className="category-name" href={item.button.url.seoHead.url}>{item.name}</h4>
                            </div>
                            <p>{description[0]}<br />{description[1]}</p>
                            {resolution.isMobile
                                && <img src={buildContentfullImageUrl(cardBgImg)} alt="test" />}
                            <a className="cta event_button_click" data-action-detail={item.button.url.seoHead.url} href={item.button.url.seoHead.url}>
                                {item.button.text}
                            </a>
                        </div>
                    </React.Fragment>
                );
            })}

            <style jsx>{Style}</style>
        </div>
    );
};

ProductCategoryLineup.propTypes = {
    categoriesLineup: PropTypes.object,
};

export default ProductCategoryLineup;
