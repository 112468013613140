import css from 'styled-jsx/css';

const styles = css`img {
    width: 100%;
    height: 100%;
}

.card-image{
    min-height: 181px;
}
`;

export default styles;