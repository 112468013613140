import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
.Cashback-Campaign-Container{
    width: 100%;
    float: left;
    position: relative;
    margin:0 auto;
    .banner-image-section{
        width:100%;
        float: left;
        .banner-image{
            img{
                width:100%;
            }
        }

    }
}

@media (max-width: 550px){
    .Cashback-Campaign-Container{
        .banner-image {
            padding-top: calc(874 / 550 * 100%);
            position: relative;
        }
    }
    .Cashback-Campaign-Container{
        .banner-image {
            img{
                width: 100%;
                position: absolute;
                top: 0;
            }
        }
    }
}`;

export default styles;