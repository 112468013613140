import React from 'react';
// import PropTypes from 'prop-types';
import Style from './couponlpcontent.scss';

const CouponContent = () => (
    <div className="Coupon-Campaing-Contentsection">
        <div className="Coupon-Campaing-Content">
            <h3 className=" Coupon-Campaing-header">【クーポン取得に関しての注意事項】</h3>
            <p className=" Coupon-Campaing-text">
                ●利用条件<br />
                クーポン有効期間中に、対象商品と本クーポン画面をお会計時にレジでご提示ください。<br /><br />

                ●対象店舗<br />
                ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店<br />
                ※一部取り扱いのない店舗がございます。<br /><br />

                ●クーポン対象商品<br />
                ・ウィスパーWガード・うすさら吸水　各種<br />
                4902430893947<br />
                4987176030795<br />
                4902430874311<br />
                4902430881531<br />
                4902430874335<br />
                4902430873796<br />
                4902430882460<br />
                4902430881609<br />
                ・ウィスパーうすさらエアリー &nbsp;&nbsp;各種 <br />
                4902430596176<br />
                4902430596251<br />
                4902430597357<br />
                4902430597395<br />
                4902430597456<br />
                4902430598972<br />
                4902430598323<br />
                ・ウィスパーうすさらエアリー＋ &nbsp;&nbsp;各種<br />
                4902430598507<br />
                4902430598927<br />
                4902430599139<br />
                4902430599177<br />
                ・ウィスパーうすさら安心 &nbsp;&nbsp;各種<br />
                4902430874328<br />
                4902430879941<br />
                4902430882453<br />
                4902430881562<br />
                4902430881616<br />
                4902430881579<br />
                4902430640800<br />
                4902430641135<br />
                ・ウィスパーWガード・うすさら吸水&nbsp;&nbsp;大容量パック　各種<br />
                4902430893954<br />
                4987176030818<br />
                4902430881517<br />
                4902430881715<br />
                4902430917100<br />
                4902430917148<br />
                4902430917179<br />
                ・ウィスパーうすさら安心 &nbsp;&nbsp;大容量パック　各種<br />
                4902430874342<br />
                4902430874359<br />
                4902430916981<br />
                4902430344678<br />
                4902430917049<br />
                4902430344821<br /><br />

                ●ご利用上の注意<br />
                ・ 他のクーポン（ガッチャモールクーポン、Tポイントクーポン、割引・値引きクーポン）との併用はできません。<br />
                ・ 期間中お1人様1回限りのご利用となります。<br />
                ・ 1回のお会計につき1パックのみ、対象となります。<br />
                ・ ポイントは、ご購入日から3日以内に付与されます。<br />
                ・ お会計時に有効期限切れ等、Tカード番号が無効となっている場合は、ポイント付与が出来ませんのでご注意ください。
            </p>
        </div>
        <style jsx>{Style}</style>
    </div>
);
export default CouponContent;
