import React from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { IdbindRetailers } from '../../redux/actions/IdbindRetailers';
import Style from './IdBindingRetailers.scss';
import getRetailer from '../../adapters/api.adapter';
import { storageGet, storageSet, storageKeysRemove, GetEmailVerified } from '../../helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

class IdBindingRetailers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userloggedInstatus: '',
            completeIDBinding: '',
            IdBindError: false,
        };
        this.handleIDBindingAPI = this.handleIDBindingAPI.bind(this);
    }

    componentDidMount() {
        storageKeysRemove(['LohacoRelease', 'authCode', 'completeIDBinding', 'UnbindTC', 'IDBindingVia', 'RegIDBindingVia', 'LohacoUnbind', 'retailerId', 'loaderStatus', 'nextStatus', 'videoFeedbackVia']);
        this.setState({
            userloggedInstatus: (storageGet('isUserLoggedIn') && GetEmailVerified()),
        });
        this.handleRetailerList();
    }

    handleRetailerList = async () => {
        this.props.IdbindRetailers();
        if (storageGet('isUserLoggedIn') && GetEmailVerified()) {
            this.handleIDBindingAPI();
        }
    }

    handleIDBindingAPI = async () => {
        await getRetailer.getBinding().then(async (idbindResponse) => {
            await this.handleSetState(idbindResponse);
        }).catch(() => {
            this.setState({ IdBindError: true });
        });
    }

    handleSetState = async (idbindResponse) => {
        if (idbindResponse && idbindResponse.data.length === 0) {
            storageKeysRemove(['authCode', 'LohacoUnbind']);
        } else if (idbindResponse && idbindResponse.status === 200) {
            storageKeysRemove(['authCode', 'LohacoUnbind']);
            storageSet('bindingID', idbindResponse.data[0].bindingId);
            this.setState({
                completeIDBinding: true,
            });
        }
    }

    handleLogin = async () => {
        await this.handleGAEvents('event_profile_lohaco_login', 'whisper_login_start_idbinding');
        storageSet('IDBindingVia', true);
        Router.push('/login');
    }

    handleUnbind = async () => {
        await this.handleGAEvents('event_lohaco_coupon_unbinding', 'whisper_lohaco_unbinding_start');
        storageSet('LohacoRelease', true);
        Router.push('/id-bind-lohaco-release');
    }

    handleGAEvents = async (eventAction, eventLabel) => {
        await window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_coupon_action',
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    }

    pageRoute = async (retailerId) => {
        await this.handleGAEvents('event_coupon_lohaco_idbinding', 'whisper_lohaco_idbinding');
        storageSet('retailerId', retailerId);
        Router.push('/id-bind-lohaco');
    }

    handleGotoTC = () => {
        storageSet('UnbindTC', true);
        Router.push('/id-bind-lohaco');
    }

    render() {
        const { userloggedInstatus, completeIDBinding, IdBindError } = this.state;
        const { isFetching, isError, result } = this.props.idBindRetailersData;

        return (
            <>
                <div className="id-binding-main-retailers-content">
                    <div className="id-binding-userlog">
                        {!userloggedInstatus && <p className="id-binding-userlog-status">ID連携状況は、<span onClick={this.handleLogin}>ログイン</span>後、以下よりご確認いただけます。</p>}

                        {userloggedInstatus && completeIDBinding
                            && (
                                <>
                                    <h3>ウィスパーｘLOHACOのID連携の詳しい情報は<span onClick={this.handleGotoTC}>こちら</span>よりご確認ください</h3>
                                </>
                            )}
                    </div>
                    <div className="id-binding-retailer-logo">
                        {isError && <h1 className="id-binding-error">予期せぬエラーが発生しました。しばらく経ってからお試しください。</h1>}
                        {isFetching ? (
                            <div>
                                <Loader
                                    type="TailSpin"
                                    color="#8347ad"
                                    className="loader"
                                    height={40}
                                    width={40}
                                    visible={true}
                                />
                            </div>
                        ) : !isError && result && result.data.map((item, index) => (
                            <div key={index}>
                                <>
                                    <img src={buildContentfullImageUrl(item.logoCdn)} alt={item.retailerName} />
                                    <p>{item.logoTitle}</p>
                                </>
                            </div>
                        ))}
                    </div>
                    <div>
                        {IdBindError ? <h1 className="id-binding-error">予期せぬエラーが発生しました。しばらく経ってからお試しください。</h1>
                            : (
                                <>
                                    {!isError && !isFetching && result && result.data.map((item, index) => (
                                        <div key={index}>
                                            <>
                                                {!userloggedInstatus && !completeIDBinding && (
                                                    <div className="id-binding-status">
                                                        <button className="submit_button" type="button" onClick={() => this.pageRoute(item.retailerId)}>ID連携する</button>
                                                    </div>
                                                )}
                                                {userloggedInstatus && !completeIDBinding && (
                                                    <div className="id-binding-status">
                                                        <button className="submit_button" type="button" onClick={() => this.pageRoute(item.retailerId)}>ID連携する</button>
                                                    </div>
                                                )}
                                                {userloggedInstatus && completeIDBinding && (
                                                    <div className="id-binding-status binded-user">
                                                        <h3 className="binded-user-info">連携済み</h3>
                                                        <button className="unbind-submit_button" type="button" onClick={this.handleUnbind}>解除</button>
                                                    </div>
                                                )}
                                            </>
                                        </div>
                                    ))}
                                </>
                            )}
                    </div>
                    <style jsx> {Style} </style>
                </div>
                <hr />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    IdbindRetailers: () => dispatch(IdbindRetailers()),
});

const mapStateToProps = (state) => ({
    idBindRetailersData: state.IdbindRetailers,
});

IdBindingRetailers.propTypes = {
    IdbindRetailers: PropTypes.func,
    isFetching: PropTypes.any,
    idBindRetailersData: PropTypes.any,
    isError: PropTypes.any,
    result: PropTypes.any,
    data: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdBindingRetailers);
