const areAllCategoriesSelected = (categories) => {
    const categoryUrls = categories.map((category) => category.url);

    if (typeof window === 'undefined') {
        return false;
    }

    return categoryUrls.filter((url) => new RegExp(`${window.location.pathname.slice(0, -1)}/?$`).test(url)).length < 1;
};

export { areAllCategoriesSelected };
