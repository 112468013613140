import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';

.content-md {
    color: $purple-dark;
    font-family: 'Noto Serif JP', serif;
    font-size: 25px;
    margin-top: 40px;
    text-align: center;
    width: $desktop-elements-width;

    @media (max-width: $breakpoint-max) {
        font-size: 12px;
        width: 100%;
    }

    p {
        margin: 10px 0;
        padding: 0;
    }

    h2 {
        border-bottom: 1px solid $purple-dark;
        display: inline-block;
        font-size: 48px;
        font-weight: 500;
        margin: 40px 0 80px;
        padding: 0;
        padding-bottom: 40px;

        @media (max-width: $breakpoint-max) {
            font-size: 22px;
            margin-bottom: 30px;
            margin-top: 10px;
            padding-bottom: 10px;
        }

        &:nth-of-type(2) {
            border: 0;
            margin: 40px 0 0;
            padding: 0;
        }
    }

    h3 {
        font-size: 36px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 20px;
        padding: 0;

        @media (max-width: $breakpoint-max) {
            font-size: 15px;
            margin-bottom: 10px;
        }
    }

    h6 {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding: 0;

        @media (max-width: $breakpoint-max) {
            font-size: 9px;
        }
    }

    .spacing {
        opacity: 0;
        padding: 20px;

        @media (max-width: $breakpoint-max) {
            padding: 15px;
        }
    }
}
`;

export default styles;