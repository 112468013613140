import { useState, useEffect, useRef } from 'react'
import { deviceWidth } from '../../../helpers/constants'

function getScreenSize() {
    const [state, _setState] = useState({
        isMobile: true,
        isTablet: false,
        isDesktop: false
    })
    const screenStateRef = useRef(state)
    const setScreenState = (data) => {
        screenStateRef.current = data
        _setState(data)
    }

    useEffect(() => {
        function checkScreenSize() {
            const currentState = screenStateRef.current
            if (window.innerWidth <= deviceWidth.smallScreenEnd) {
                !currentState.isMobile &&
                    setScreenState({
                        isMobile: true,
                        isTablet: false,
                        isDesktop: false
                    })
            } else if (window.innerWidth <= deviceWidth.tabWidthEnd) {
                !currentState.isTablet &&
                    setScreenState({
                        isMobile: false,
                        isTablet: true,
                        isDesktop: false
                    })
            } else {
                !currentState.isDesktop &&
                    setScreenState({
                        isMobile: false,
                        isTablet: false,
                        isDesktop: true
                    })
            }
        }
        checkScreenSize()
        window.addEventListener('resize', checkScreenSize)
        return () => window.removeEventListener('resize', checkScreenSize)
    }, [])

    return state
}

export default getScreenSize