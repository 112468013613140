import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Articles from './SitemapArticles';
import Products from './SitemapProducts';
import OtherLinks from './SitemapOtherLinks';

import Style from './Sitemap.scss';

const Sitemap = ({ links, productCategories, articleCategories }) => (
    <div className="full-width sitemap">
        <div className="container">
            <h1>サイトマップ</h1>
            <div className="row white-pb white-bg">
                <div className="col-md-6">
                    <Products categories={productCategories} />
                    <OtherLinks links={links} />
                </div>
                <div className="col-md-6">
                    <Articles categories={articleCategories} />
                </div>
            </div>
        </div>
        <style jsx>{Style}</style>
    </div>
);

const mapStateToProps = (state) => ({
    productCategories: state.productCategories.categories,
    articleCategories: state.articleCategories.categories,
});

Sitemap.propTypes = {
    articleCategories: PropTypes.object,
    links: PropTypes.object,
    productCategories: PropTypes.object,
};

export default connect(mapStateToProps)(Sitemap);
