/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { storageGet } from '../../helpers/StorageEvents';
import EmailThanksForSuccess from './EmailThanksForSuccess';
import EmailThanksForFail from './EmailThanksForFail';
import style from './EmailThanksForRegistration.scss';

const EmailThanksForRegistration = (props) => {
    const doiStatus = storageGet('doiVerifiyStatus');
    const parseIntStatus = parseInt(doiStatus, 10);
    const [verifyStatus, setVerifyStatus] = useState(parseIntStatus);

    useEffect(() => {
        window?.dataLayer?.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_crm_action',
            'GAeventAction': 'event_profile_register_complete',
            'GAeventLabel': 'Whisper_register_complete',
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    }, []);

    return (
        <div className="email-thanks-reg-container">
            {verifyStatus
                ? <EmailThanksForSuccess contentProps={props} />
                : <EmailThanksForFail />}
            <style jsx>{style}</style>
        </div>
    );
};

export default EmailThanksForRegistration;
