import React from 'react';

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const prepareRichTextContent = (json) => {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <i>{text}</i>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        },
    };

    return documentToReactComponents(json, options);
};

export { prepareRichTextContent };
