import css from 'styled-jsx/css';

const styles = css`// @import "./styles/global-variables";

.whisper-jp-thankreg-form {
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: #8347ad;
  max-width: 940px;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin: 0 10px 0 10px;
  }
  .form_head {
    font-size: 40px;
    text-align: left;
  }
  .form_subhead {
    margin: 16px 0 75px 0;
    font-size: 24px;
    text-align: left;
    line-height: 32px;
    margin-bottom: 0px;
    @media only screen and (max-width: 767px) {
      margin: 9px 0 29px 10px;
      font-size: 20px;
      text-align: left;
      line-height: 28px;
      margin-bottom: 0px;
    }
  }
  .frm-thanksForReg .container {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    @media only screen and (max-width: 767px) {
      width: 100%;
      padding-right: 5px;
      padding-left: 5px;
      margin: auto;
    }
  }
  .temporaryRegistrationlink {
    color: inherit;
    text-decoration: underline;
  }

  .frm-cta-section {
    margin: 54px 0 100px 0;
    @media only screen and (max-width: 767px) {
      margin: 33px 0 50px 0;
    }
  }
  .btn-check {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-left: 5%;
    @media only screen and (max-width: 767px) {
      margin-bottom: 10%;
    }
  }
  .frm-cta-section button {
    width: auto;
    padding: 0 20px;
    min-width: 250px;
    font-size: 20px;
  }
  .back_button {
    height: 38px;
    width: 30%;
    border-radius: 18px;
    border: none;
    color: white;
    cursor: pointer;
    background-image: linear-gradient(to right, #382f62, #5f3c85, #382f62);
  }
  .frm-cta-section div {
    @media only screen and (max-width: 767px) {
      margin-bottom: 100px;
      text-align: center;
    }
  }
}
`;

export default styles;