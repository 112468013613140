import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Style from './IdBindingBanner.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const IdBindingBanner = (props) => {
    const { image, imageText } = props;
    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    const img = resolution.isMobile ? getImageUrl(image.imageSmartphone.asset.url, {}) : getImageUrl(image.imageDesktop.asset.url, {});

    return (
        <div className="banner-image-section">
            <div className="banner-image">
                <img src={buildContentfullImageUrl(img)} alt={image.imageDesktop.description} />
            </div>
            <div className="image-text" dangerouslySetInnerHTML={{ __html: imageText }} />
            <style jsx>{Style}</style>
        </div>
    );
};

IdBindingBanner.propTypes = {
    image: PropTypes.object,
    imageText: PropTypes.string,
};

export default IdBindingBanner;
