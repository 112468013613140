import React from 'react';
// import PropTypes from 'prop-types';
import Style from './couponlppromo.scss';

const CouponPromo = () => (
    <div className="Coupon-Campaing-Promosection">
        <div className="Coupon-Campaing-Promo">
            <h3 className=" Coupon-Campaing-Promo-header">クーポン取得方法</h3>
            <p className="Coupon-Campaing-Promo-subtitle">簡単３ステップでクーポンをご利用いただけます。</p>
            <div className="Coupon-Campaing-DoublePromo">
                <div className="Coupon-Campaing-left promo">
                    <h2 className="Coupon-Campaing-left-header header">すでに会員の方</h2>
                    <div className="Coupon-Campaing-left-section section">
                        <h3 className="Coupon-Campaing-left-point pt1 pt">ステップ１</h3>
                        <p className="Coupon-Campaing-left-text txt1 txt">サインインボタンをクリック</p>
                        <h3 className="Coupon-Campaing-left-point pt2 pt">ステップ2</h3>
                        <p className="Coupon-Campaing-left-text txt2 txt">アドレス、パスワードを入力</p>
                        <h3 className="Coupon-Campaing-left-point pt3 pt">ステップ3</h3>
                        <p className="Coupon-Campaing-left-text txt3 txt">クーポン取得ボタンをクリック</p>
                    </div>

                </div>
                <div className="Coupon-Campaing-right promo">
                    <h2 className="Coupon-Campaing-right-header header">会員登録がまだの方</h2>
                    <div className="Coupon-Campaing-right-section section">
                        <h3 className="Coupon-Campaing-right-point pt1 pt">ステップ１</h3>
                        <p className="Coupon-Campaing-right-text txt1 txt">新規会員登録ボタンをクリック</p>
                        <h3 className="Coupon-Campaing-right-point pt2 pt">ステップ2</h3>
                        <p className="Coupon-Campaing-right-text txt2 txt">フォームに沿って登録</p>
                        <h3 className="Coupon-Campaing-right-point pt3 pt">ステップ3</h3>
                        <p className="Coupon-Campaing-right-text txt3 txt">クーポン取得ボタンをクリック</p>
                    </div>
                </div>
            </div>
        </div>
        <style jsx>{Style}</style>
    </div>
);
export default CouponPromo;
