/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ExpertsVoice from '../ExpertsVoice/ExpertsVoice';
import ArticleStep from '../ArticleStep/ArticleStep';
import ContentLt from '../ContentLt/ContentLt';
import ContentNorma from '../ContentLt/ContentNorma';
import { renderSection } from '../../helpers/uiHelper';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './CustomContent.scss';

const CustomContent = ({ items }) => {
    let resolution = useScreenSize();
    if (!resolution) {
        resolution = { isMobile: true };
    }
    const nomuraPage = !!(typeof window !== 'undefined' && (window.location.pathname === '/article-top/drnomura-1/' || window.location.pathname === '/article-top/drnomura-1' || window.location.pathname === '/article-top/drnomura-2/' || window.location.pathname === '/article-top/drnomura-2' || window.location.pathname === '/article-top/drnomura-5' || window.location.pathname === '/article-top/drnomura-5/ ' || window.location.pathname === '/article-top/drnomura-6/' || window.location.pathname === '/article-top/drnomura-6'));
    return (
        <div className="custom-content">
            {nomuraPage ? (
                <>
                    <ContentNorma items={items} />
                </>
            ) : (
                <>
                    {items.map((item, index) => {
                        switch (item.type) {
                        case 'ExpertsVoice': return <ExpertsVoice {...item} key={index} />;
                        case 'ArticleStep': return <ArticleStep {...item} key={index} />;
                        case 'ContentLt': return <ContentLt {...item} currentIndex={index} key={index} />;
                        case 'SectionContainer': return renderSection(item, resolution);
                        default: return null;
                        }
                    })}
                </>
            )}
            <style jsx>{Style}</style>
        </div>
    );
};

CustomContent.propTypes = {
    items: PropTypes.array,
};

export default CustomContent;
