import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

@font-face {
    font-family: Hiragino6;
    font-weight: bold;
    src: url("../fonts/HiraginoStdNW6.otf");
}

@font-face {
    font-family: Hiragino8;
    font-weight: 800;
    src: url("../fonts/HiraginoStdNW8.otf");
}

@font-face {
    font-family: Hiragino4;
    font-weight: normal;
    src: url("../fonts/HiraginoStdNW4.otf");
}

.surveyError {
    color: #913cca;
    font-weight: bold;
    font-size: 18px;
    margin: 2px auto 12px;
}
.productDiagnosisSection {
    width: 100%;
    .grey-text {
        margin-top: 80px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .start-button {
        position: relative;
        background-color:#8347ad;
        border: 0;
        padding: 5px 0;
        border-radius: 20px;
        color: white;
        cursor: pointer;
        font-family: "SourceSansPro-Regular";
        font-size: 20px;
        text-align: center;
        width: 320px;
        margin: 0 auto;
        letter-spacing: 4px;
        &::after {
            border-bottom: 7px solid transparent;
            border-left: 12px solid white;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            content: "";
            font-size: 0;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
        }
       
    }
    @media only screen and (max-width: 767px){
        .grey-text {
           position:absolute;
           top:0;
        }
        .start-button{
           margin-top: -8%;
           left:0;
           right:0;
        }

    }
    
}
.prod-main-container {
    float: left;
    width: 100%;
    padding-bottom: 130px;
    border-bottom: none;
    margin-bottom: 0;
    margin-top: 100px;
    height: 100%;
    @media only screen and (max-width: 767px){
        padding-bottom:40px !important;
    }
   
    
    .image {
        img {
            width: 100%;
        }
    }
    .questions-row {
        width: 100%;
        position: relative;
        text-align: center;
    }
    .questions-row-image {
        position: absolute;
        top: 30px;
        right: -100px;
        img{
            width:73.5%;
        }
        @media only screen and (max-width: 767px){
            right:0;
            top:-203px;
            z-index: -1;
            img{
                width:100%;
            }
        }
    }
    .product-box {
        margin: 100px 0 10px;
        position: relative;
        @media only screen and (max-width: 767px){
            padding:0 15px 40px;
            margin:30px 0 10px;
        }
        .questions {
            // background: url(https://images.ctfassets.net/8g0fievzqg8k/5bZYAJFge9vI4PEV51kohf/207c0d319a53e600251abe31ea0e748d/whisper_20fall_check_pc-16.png)
            //     no-repeat;
            background-size: cover;
            padding: 10px 40px;
            height: 140px;
            position: relative;
            background: rgb(131,17,179);
            background: linear-gradient(90deg, rgba(131,17,179,1) 0%, rgba(117,3,143,1) 41%, rgba(193,163,214,1) 100%);
          @media only screen and (max-width: 991px) and (min-width: 768px) {
                // background-size: contain;
             }
            h2.heading {
                text-align: center;
                color: #fff;
                font-size: 18px;
                font-family: M PLUS rounded\\1c, sans-serif;
                &::after {
                    position: absolute;
                    content: "";
                    height: 2px;
                    bottom: 100px;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    width: 50px;
                    background: #fff;
                }
                @media only screen and (max-width: 767px) {
                    &::after {                       
                        bottom: 0px;                        
                        top:40px;
                    } 
                }
            }
            .ques {
                margin-top: 25px;
                h2 {
                    color: #fff;
                    font-size: 24px;
                    line-height: 28px;
                    margin: 0;
                    font-family: "M PLUS Rounded 1c", sans-serif;
                    text-align: center;
                }
            }
            @media only screen and (max-width: 767px) {
                // background: url(https://images.ctfassets.net/8g0fievzqg8k/1aRjuVlHn2VHTuk9lrSrHp/8b2dad43695616796af50b23646c7ac2/ques-bx.png)
                //     no-repeat 50%;
                background: rgb(131,17,179);
                background: linear-gradient(90deg, rgba(131,17,179,1) 0%, rgba(117,3,143,1) 41%, rgba(193,163,214,1) 100%);                    
                width: 100%;
                padding: 9px 0;
                height:100%;

                .ques {
                    h2 {
                        font-size: 12px;
                        line-height: 23px;
                        margin: 0;
                    }
                }
            }
        }

        button {
            border: none;
            box-shadow: none;
            font-weight: 700;
            font-family: "M PLUS Rounded 1c", sans-serif;
            &:not(:disabled) {
                cursor: pointer;
            }
        }
        button.next {
            // background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png)
            //     no-repeat;
            background:#8347ad;
            width: 393px;
            height: 60px;
            color: #fff;
            margin: 80px auto 20px;
            font-size: 22px;
            border-radius: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Noto Sans JP';
            position: relative;
            &::after {
                border-bottom: 7px solid transparent;
                border-left: 12px solid white;
                border-right: 7px solid transparent;
                border-top: 7px solid transparent;
                content: "";
                font-size: 0;
                position: absolute;
                right: 4px;
                top: 50%;
                transform: translateY(-50%);
            }
            @media only screen and (max-width: 767px) {
                // background: url(https://images.ctfassets.net/8g0fievzqg8k/7pPpjUTGz7oDquuOoSeKmi/a7b6825bbf7c7e2c113cf20147239d7d/next-btn-sp.png)
                //     no-repeat;
                background: $purple;
                width: 200px;
                height: 35px;
                margin: 30px auto 20px;
            }
        }
        button.previous {
            display: block;
            background: none;
            margin: 0 auto;
            font-size: 22px;
            color:$text-article;
            // background: url(https://images.ctfassets.net/8g0fievzqg8k/2INhWydQYypCgpRdkFPjI6/5de868c1847a8bd5d21443f27fd00808/prev-arr.png)
            //     no-repeat 10% 60%;
            padding: 0 23px;
            background-size: 8px;            
            font-family: 'Noto Sans JP';
            // &:before{
            //     content:"<--";
            //     height:10px;
            //     width:20px;
            // }
        }
    }

    .loader-part {
        float: left;
        width: 100%;
        text-align: center;
        margin: 40px 0 0 0;
        .loder {
            text-align: center;
            border-radius: 50%;
        }
    }
}
@media only screen and (min-width: 768px) {
    .prod-main-container + .footer {
        margin-top: 0;
        padding-bottom: 0;
    }
}

`;

export default styles;