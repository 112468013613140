import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useScreenSize from '../Hooks/useScreenSize';

import Style from './ContentMd.scss';

import { prepareRichTextContent } from './ContentMd.helper';

const ContentMd = (props) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    const content = resolution.isMobile
        ? prepareRichTextContent(props.contentMobile.json, props.allPages)
        : prepareRichTextContent(props.content.json, props.allPages);

    return (
        <div className="content-md">
            {content}
            <style jsx>{Style}</style>
        </div>
    );
};

ContentMd.propTypes = {
    allPages: PropTypes.any,
    content: PropTypes.object,
    contentMobile: PropTypes.object,
};

const mapStateToProps = (state) => ({ allPages: state.allPages });

export default connect(mapStateToProps)(ContentMd);
