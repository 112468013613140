import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.recommended-bg {
    background-image: url('/static/images/bg_recommended_products.png');
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: $breakpoint-max) {
        background-color: #fff;
    }

    .buy-now-wrapper {
        display: flex;
        justify-content: center;
        margin-top: -100px;
    }

    .recommended-products {
        font-family: 'M PLUS Rounded 1c', sans-serif;
        margin-bottom: 100px;
        margin-top: 150px;

        @media (max-width: $breakpoint-max) {
            margin-bottom: 100px;
            margin-top: 45px;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 5px;

            @media (max-width: $breakpoint-max) {
                padding-top: 30px;
            }
        }

        h4 {
            font-size: 12px;
            margin-bottom: 40px;
            margin-top: 0;
        }

        h3,
        h4 {
            color: $purple-dark;
            text-align: center;
        }

        .recommended-product {
            margin: 0 -25px 60px;
            text-align: center;

            @media (max-width: $breakpoint-max) {
                margin: 0 0 35px;
            }

            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                margin: 0 0 60px;
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }

            h5 {
                color: $purple-dark;
                font-size: 18px;
                margin: 0 auto;
                max-width: 70%;

                @media (max-width: $breakpoint-max) {
                    max-width: none;
                }
                @media (max-width: $breakpoint-antediluvian-sp-max) {
                    font-size: 16px;
                }

            }

            img {
                margin-bottom: 15px;
                max-width: 100%;

                @media (max-width: $breakpoint-max) {
                    margin-bottom: 25px;
                }
            }
        }
    }
}
`;

export default styles;