import _ from 'lodash';

const isPinkCategory = (name) => (
    _.includes(name, 'Futayaku')
    || _.includes(name, 'Kyusui')
    || _.includes(name, 'うすさら吸水')
    || _.includes(name, '1枚2役Wガード')
);

export default isPinkCategory;
