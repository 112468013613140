import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.Coupon-Campaing-Promosection{
    width:100%;
    float:left;
    position: relative;
    background:#efefef;
    padding:50px 0;
    margin: 0 auto 70px;
    .Coupon-Campaing-Promo{
        width:940px;
        margin:0 auto; 
        .Coupon-Campaing-Promo-header{
            width:100%;
            float:left;
            margin:0 auto;
            text-align: center;
            color:$purple-dark;
            &::after{
                float: left;
                content:"-----------";
                margin:0 auto;
                width: 100%;
                margin:0 auto; 
                letter-spacing: -5px;               
            }           
        } 
        .Coupon-Campaing-Promo-subtitle{
            width:100%;
            float:left;
            margin:0 auto;
            text-align: center;
            color:$purple; 
        } 
        .Coupon-Campaing-DoublePromo{
            width: 100%;
            float:left;
            position: relative;
            margin:20px auto;
            .promo {
                border:2px solid $purple-dark;
                float:left;
                margin:0 auto;
                width:48%;
                .header{
                    width:100%;
                    background: $purple-dark;
                    color: #fff;
                    text-align:center;
                    margin: 0 auto;
                    font-size: 28px;
                    line-height: 34px;
                    height: 74px;
                    vertical-align: middle;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .section{
                    width:100%;
                    float:left;
                    position: relative;
                    margin:0 auto;
                    text-align:center;
                    background:#fff;
                    padding: 30px 0 20px;
                    .pt{
                        color:#fff;
                        background-image: url(/static/images/id-bind-text-bg.png);
                        background-repeat: no-repeat;
                        background-position: center;
                        height: 77px;
                        width: 200px;
                        background-size: contain;
                        position: relative;
                        margin:0 auto;
                        vertical-align: -webkit-baseline-middle;
                        font-size: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .pt3{
                        background-image: url(/static/images/id-bind-text-bg-1.png);
                        background-repeat: no-repeat;
                        background-position: center;        
                   
                    }
                    .txt{
                        color:$purple-dark;
                        font-weight: 500;
                        font-size: 24px;
                    }

                }
            }
            .Coupon-Campaing-left{
                margin-right:24px;
            }
        }     
    }
    @media only screen and (max-width: $breakpoint-max){
        .Coupon-Campaing-Promo{
            width:100%;
            margin:0 auto;
            padding:0 20px; 
            .Coupon-Campaing-Promo-header{
                        
            } 
            .Coupon-Campaing-Promo-subtitle{
              
            } 
            .Coupon-Campaing-DoublePromo{
                margin:20px auto;
                .promo {
                    width:100%;
                    .header{                      
                        font-size: 28px;
                        line-height: 34px;
                        height: 50px;                      
                    }
                    .section{
                        padding: 30px 0 20px;
                        .pt{                       
                            height: 80px;
                            width: 100%;                           
                        }
                        .pt3{                                 
                       
                        }
                        .txt{                     
                            font-size: 20px;
                        }   
                    }
                }
                .Coupon-Campaing-left{
                    margin-right:0;
                    margin-bottom:30px;
                }
            }     
        }

    }
}`;

export default styles;