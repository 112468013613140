import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Router from 'next/router';
import Style from './SurveyQuestion.scss';
import getSurveyQuestions from '../../redux/actions';
import OptionsRenderer from './OptionsRenderer';
import {
    selectAnswer,
    navigateToNextQuestion,
    navigateToPreviousQuestion,
    updateTextField,
} from '../../redux/reducers/SurveySlice';
import Navigation from './Navigation';
import TextArea from './TextArea';

// const QuestionStepper = (stepNumber = 1) => (
//     <p className="form_subhead">
//         {stepNumber === 1 ? <span>製品選択 ({stepNumber + 1} / 14)</span> : <span>アンケート ({stepNumber + 1} / 14)</span>}
//     </p>
// );
const QuestionStepper = (stepNumber = 1) => {
    useEffect(() => { }, [stepNumber]);

    return (
        <p className="form_subhead">
            {stepNumber === 1 ? <span>製品選択 ({stepNumber + 1} / 14)</span> : <span>アンケート ({stepNumber + 1} / 14)</span>}
        </p>
    );
};

const SurveyQuestion = () => {
    const dispatch = useDispatch();
    const { result } = useSelector((state) => state.surveyQuestions, shallowEqual);
    const { currentQuestion, answers, error } = useSelector((state) => state.survey, shallowEqual);
    const { questionName, isMultiSelect, options } = result[currentQuestion] || [];
    // const router = useRouter();

    const selectedAnswers = answers[currentQuestion] || [];

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch(getSurveyQuestions());
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const handleSelect = (option) => {
        dispatch(selectAnswer({ option, question: currentQuestion, isMultiSelect }));
    };

    const GaEvents = () => {
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_survey_action',
                GAeventAction: 'event_survey_next_questions',
                GAeventLabel: `quesNo#${currentQuestion + 1}`,
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }
    };
    const handleNextScreen = () => {
        GaEvents();
        if (currentQuestion === 13) {
            Router.push({
                pathname: '/previewPage',
                asPath: '/previewPage',
            });
        } else {
            dispatch(navigateToNextQuestion());
        }
    };

    const handlePreviousScreen = () => {
        dispatch(navigateToPreviousQuestion());
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'customEvent',
                GAeventCategory: 'event_survey_action',
                GAeventAction: 'event_survey_back_questions',
                GAeventLabel: `quesNo#${currentQuestion - 1}`,
                GAeventValue: 0,
                GAeventNonInteraction: false,
            });
        }
    };
    const handlePrevPage = () => {
        Router.push({
            pathname: '/editprofile',
        });
    };
    const textChange = (value) => {
        dispatch(updateTextField({ value, currentQuestion }));
    };

    return (
        <div className="survey_questions_container">
            {QuestionStepper(currentQuestion)}
            <div className="survey_question_text">{questionName}</div>
            <div className="options">
                <p>
                    {options
                        && options.map((option, index) => {
                            const isSelected = selectedAnswers.filter((answer) => answer.optionId === option.optionId).length > 0;
                            const textOption = selectedAnswers.filter((answer) => answer.helptext === 'Other');
                            const textValue = (textOption && textOption.length > 0 && textOption[0].textValue) || '';
                            return (
                                <OptionsRenderer
                                    onSelect={() => handleSelect(option)}
                                    key={`${index + 1}_Option`}
                                    label={option.optiontext}
                                    showTextArea={option.helptext === 'Other'}
                                    isMultiSelect={isMultiSelect}
                                    isSelected={isSelected}
                                    onChangeText={textChange}
                                    textValue={textValue}
                                />
                            );
                        })}
                    {
                        currentQuestion === 13 && <TextArea lastQuestion textValue={selectedAnswers || ''} onChangeText={(value) => textChange(value)} />
                    }
                </p>
            </div>
            <div className="frm-cta-section container">
                <div align="center" className="btn-check">
                    <Navigation screen={currentQuestion} onBack={handlePreviousScreen} onNext={handleNextScreen} onPrevPage={handlePrevPage} />
                </div>
            </div>
            {error && <p className="error">質問に答えてから「次へ」を押してください。</p>}
            <style jsx global>
                {Style}
            </style>
        </div>
    );
};

// SurveyQuestion.propTypes = {
//     surveyData: PropTypes.object,
//     router: PropTypes.object,
//     comingFromPreview: PropTypes.bool,
// };

export default SurveyQuestion;
