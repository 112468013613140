module.exports = (preview) => `
{
  articleListCollection(limit: 1,preview:${preview}) {
    items {
      categories: articleCategoriesCollection(limit: 15,preview:${preview}) {
        items {
          page {
            seoHead {
              url
              hideInSitemap
            }
          }
          nameEn
          name
          articles: articlesCollection(limit: 10,preview:${preview}) {
            items {
              nameEn
              title
              title1
              subheading
              imageDt {
                cloudinaryId
                description
                asset{url}
              }
              imageSp {
                cloudinaryId
                description
                asset{url}
              }
              text {
                json
              }
              page {
                seoHead {
                  url
                  title
                  hideInSitemap
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
