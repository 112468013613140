import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.Pagination{
    width: 100%;
    float: left;
    margin: 20px auto;
    text-align: center;
    button{
        border:$purple-dark;
        color:$purple-dark;
        margin: 0 5px;
        height: 30px;
        width: 30px;
        font-weight: 500;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        background:none;
        border:1px solid $purple-dark
    }
    .pageNumber{
        background:$purple-dark;
        border:$purple-dark;
        color: #fff;
        margin: 0 5px;
        height: 30px;
        width: 30px;
        font-weight: 500;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        border:1px solid $purple-dark
        }
    .disabled{
        cursor: not-allowed;
        opacity: 0.3; 
        background:$purple-dark;  
        color: #fff;
        border:1px solid $purple-dark
    }
    .inactive{
        opacity: 0.3;       
    }
    .active{
        opacity:1;
    }
}`;

export default styles;