import React from 'react';
import PropTypes from 'prop-types';

import Style from './Sitemap.scss';

const SitemapProducts = ({ categories }) => {
    const productCategories = [...(categories?.items??[])];
    return (
        <div className="products">
            <ul>
                <li>
                    <h3><a href="/all-product/" title="全ての商品">全ての商品</a></h3>
                </li>
                {productCategories.map((category, i) => {
                    const categoryUrl = category.page.seoHead.url;
                    return (
                        <li key={i}>
                            <h3><a href={categoryUrl} title={category.name}>{category.name}</a></h3>
                            <ul>
                                {category.products.items.map((product, j) => (
                                    <li key={j}>
                                        <a href={categoryUrl} title={product.title}>{product.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    );
                })}
            </ul>
            <style jsx>{Style}</style>
        </div>
    );
};

SitemapProducts.propTypes = {
    categories: PropTypes.object,
};

export default SitemapProducts;
