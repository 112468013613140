import React from "react";
import Style from "./Link.scss";

export const LinkButton = ({
  customClass,
  target,
  textValue,
  type,
  url,
  ...rest
}) => {
  let jsxMarkup = (
    <button className={customClass ?? ""} {...rest}>
      {textValue}
    </button>
  );
  if (type === "Anchor")
    jsxMarkup = (
      <a
        href={url}
        data-action-details={url}
        className={customClass ?? ""}
        {...rest}
      >
        {textValue ?? ""}
      </a>
    );

  return (
    <>
      {jsxMarkup}
      <style jsx>{Style}</style>
    </>
  );
};

export default LinkButton;
