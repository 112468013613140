import React, { useEffect, useState } from 'react';
import Style from './RipplemockMagic.scss';
import SlickDefaultStyle from '../../../styles/slick.scss';
import Accordion from './Accordion';
import RippleCarousel from './RippleCarousel';
import RippleMockPopUp from '../../../pages/rippleMockPopUp';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const RippleMockTleMagic = () => {
    const accordionData = [
        {
            title: '01 普通の下着のような履き心地ですか？',
            content: 'はい、下着デザイナーとウィスパーの研究開発担当者が協力し、尿漏れにきちんと対応でき尚且つ普通の下着のような見た目と履き心地の製品を作りました。',
        },
        {
            title: '02 繰り返し使えますか?',
            content: 'はい、この下着は普通の下着のように洗濯可能で繰り返し使えます。洗濯し繰り返して100回まで使用可能ようにデザインされています。',
        },
        {
            title: '03 （尿漏れがあった場合）濡れた感じがしますか?',
            content: 'この下着は水分を肌から遠ざける機能がありますので一日中お肌さらさら。',
        },
        {
            title: '04 （尿漏れがあった場合）ニオイはどうですか?',
            content: '抗臭作用が施されているのでニオイを防ぐ効果があります。',
        },
        {
            title: '05 この製品はどうやって吸水していますか?',
            content: '下着のマチの部分を3層にしました。一番上の層は水分をすばやくお肌から遠ざけ、真ん中の層で水分を吸収し、一番下の層で洋服を汚さないよう液漏れを防ぐ構造になっています。',
        },
        {
            title: '06 洗濯はどうすればいいですか?',
            content: '洗濯機の「おしゃれ着洗い」や「デリケート」コース、あるいは手洗いで、洗剤を使用し洗ってください。柔軟剤や漂白剤は下着の効果を下げてしまうので使わないでください。吊るして干すか、低温の乾燥機にかけてください。他の普通の下着同様、初めて着用する前に一度洗ってください。',
        },
        {
            title: '07 どれぐらい持ちますか?',
            content: 'この吸収力のある下着は普通の下着と同じ素材でできているので、正しいお手入れをすれば普通の下着と同じぐらい長く使えます。 なお、洗濯し繰り返して100回まで使用可能ようにデザインされています。',
        },
        {
            title: '08 この下着はどのような素材で出来ていますか?',
            content: '主な生地は柔らかくて薄いナイロンとスパンデックスの混合です。吸収性のマチ(吸水する部分)は高性能のポリエステル、ポリプロピレンとナイロンの混合で出来ています。',
        },
        {
            title: '09 この下着は洋服に透けませんか?',
            content: 'この下着はなめらかで締め付けすぎないように作られているので服に響きにくくできています。ご自分に合うサイズをサイズ表で確かめてみてください。',
        },
        {
            title: '10 この製品についてもっと知りたいですが?',
            content: 'メールアドレスで認証いただいた状態で、ご希望のサイズや色をお選びいただいた後、「この製品が欲しい」ボタンを押していただくと、より詳細な製品情報などが入り次第ご連絡を差し上げます。',
        },
    ];
    const color = [
        {
            id: '1',
            on: 'https://images.ctfassets.net/8g0fievzqg8k/gMzskKXhDZEDrzTxFEjBy/eff54830d47d068e41f67b341f8c0ac7/btm_lbl_on.png',
            off: 'https://images.ctfassets.net/8g0fievzqg8k/2Jzg5izIl7f63tjRgj1f7S/4dfc7b5069643544cc19fcf594833d2f/btm_lbl_off.png',
        },
        {
            id: '2',
            on: 'https://images.ctfassets.net/8g0fievzqg8k/5nRBqJu5MdNLhBFWvVMfeu/bd958419aa45a3857e2e1e685b394b5e/btm_lpl_on.png',
            off: 'https://images.ctfassets.net/8g0fievzqg8k/1fo4RLV8XuVdkbVYNqV2zz/260075ccd9699b4c6404c012478c0dc6/btm_lpl_off.png',
        },
        {
            id: '3',
            on: 'https://images.ctfassets.net/8g0fievzqg8k/59KKWCdHBA7OSaO7XWgY55/19e7aebafd83c20de85f0b8dfcc2c0da/btm_lps_on.png',
            off: 'https://images.ctfassets.net/8g0fievzqg8k/5vTKcgeuVZ30SBQUIoQPHN/fe6c9b38aacaf2bea55d9acca2bb7749/btm_lps_off.png',
        },
        {
            id: '4',
            on: 'https://images.ctfassets.net/8g0fievzqg8k/54RjOyDGfr8lH32h6TgcR4/7ed9c88df1a2a0886d97114f3dd57999/btm_lbs_on.png',
            off: 'https://images.ctfassets.net/8g0fievzqg8k/70tEllo5fnOUibTaQmYowI/fc032d28c8ae490daf759548f6254d6c/btm_lbs_off.png',
        },
    ];

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    const carousel = [
        {
            id: '1',
            imgurl: 'https://images.ctfassets.net/8g0fievzqg8k/5aX1nhKMWwdlAazUfblzEo/48a14248c0912456a5d5e42ee56153b9/pink_lacey_img01.png',
        },
        {
            id: '2',
            imgurl: 'https://images.ctfassets.net/8g0fievzqg8k/2Tgyka1Anm4i6OZbYUwq2m/4dfe6ebfde57343baaa37a870ba7d555/pink_lacey_img02.png',
        },
        {
            id: '3',
            imgurl: 'https://images.ctfassets.net/8g0fievzqg8k/4HyBhXG4RI0XjKrumZ2ncD/06fce03eeae0f6b4a74d9912f2d5cb05/pink_lacey_img03.png',
        },
        {
            id: '4',
            imgurl: 'https://images.ctfassets.net/8g0fievzqg8k/6lw3CDYIADJotX3DNEcKeJ/750335582515ff305ebdcd6a4c59730e/pink_lacey_img04.png',
        },
        {
            id: '5',
            imgurl: 'https://images.ctfassets.net/8g0fievzqg8k/4WBSLIpVrDyoU2nbpMW7wc/aa1ec14ea2cf1aaba01964be766ef9cd/BlackGusset_Pour.gif',
        },
    ];
    const [toggle, setToggle] = useState(false);
    const [productColor, setProductColor] = useState(null);
    const [productSize, setProductSize] = useState(null);
    const [productPrice, setProductPrice] = useState(null);
    const [enableButton, setEnableButton] = useState(null);
    const [openNotify, setOpenNotify] = useState(false);
    const [notifypop, setNotifypop] = useState(false);
    const [sizePop, setSizepop] = useState(false);

    const magicheader = 'https://images.ctfassets.net/8g0fievzqg8k/6um2WOA2FNU9Ss7C3hPJuK/e5a88b07643541dfe06652006cc7a05b/headmain_img_magic.png';
    const footer = 'https://images.ctfassets.net/8g0fievzqg8k/P6ayIn6CGDaam98RsxD8V/b259173c3d3cfa19836a2e6a451c01fd/footer_img.png';
    const magicproduct = 'https://images.ctfassets.net/8g0fievzqg8k/34gerjyuudZWFaLv6YDyL7/71e52204dcc98ab4c82e3f70d198f86a/product_features_magic.png';
    const buttonon = 'https://images.ctfassets.net/8g0fievzqg8k/4DZlluSMpNLluvHFEalypH/c791290e2378fb739d53d0103bdf7dbb/btm_submit_on.png';
    const buttonoff = 'https://images.ctfassets.net/8g0fievzqg8k/1MpNmMRlfynvuGDCwarjiy/c950801494b70f03c5759f0b293d252a/btm_submit_inactive.png';
    // const coloron = 'https://images.ctfassets.net/8g0fievzqg8k/gMzskKXhDZEDrzTxFEjBy/eff54830d47d068e41f67b341f8c0ac7/btm_lbl_on.png';
    // const coloroff = 'https://images.ctfassets.net/8g0fievzqg8k/2Jzg5izIl7f63tjRgj1f7S/4dfc7b5069643544cc19fcf594833d2f/btm_lbl_off.png';

    // const imgurl = 'https://images.ctfassets.net/8g0fievzqg8k/1fZZtelP1yQeqd0TadNu8D/722bff2bcac87943d3a763f639085542/all_common_slide05.png';

    const toggleAccordian = () => {
        if (toggle === false) {
            setToggle(true);
        } else {
            setToggle(false);
        }
    };

    const selectColor = (id) => {
        if (productColor === id) {
            setProductColor(null);
        } else {
            setProductColor(id);
        }
    };
    const selectSize = (id) => {
        if (productSize === id) {
            setProductSize(null);
        } else {
            setProductSize(id);
        }
    };

    const selectPrice = (id) => {
        if (productPrice === id) {
            setProductPrice(null);
        } else {
            setProductPrice(id);
        }
    };

    const notifySubmit = (val) => {
        if (val === 'sizepop') {
            setSizepop(true);
            setOpenNotify(true);
        } else if (enableButton === true) {
            if (val === 'notify1') {
                setGATagsAndEvents('event_product_registered', 'event_ripple_p1_notifyme01', 'event_sales_action');
                setNotifypop(true);
                setOpenNotify(true);
            } else {
                setGATagsAndEvents('event_product_registered', 'event_ripple_p1_notifyme02', 'event_sales_action');
                setNotifypop(true);
                setOpenNotify(true);
            }
        }
    };
    const closeNotifyPop = () => {
        setOpenNotify(false);
        setNotifypop(false);
        setSizepop(false);
    };

    useEffect(() => {
        setGATagsAndEvents('event_view_product_detail_page', 'event_ripple_pageview_p1', 'event_informational_action');
    }, []);

    useEffect(() => {
        if (productColor !== null && productPrice !== null && productSize !== null) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    }, [productPrice, productSize, productColor]);

    return (
        <div className="ripplemockmagic-container">
            <div className="header-section">
                <img src={buildContentfullImageUrl(magicheader)} alt="magic" width="100%" height="100%" />
            </div>
            <RippleCarousel carousel={carousel} />

            <div className="color-selection">
                <div className="color-header">
                    <p>色を選ぶ：</p>
                </div>
                {color.map((prod, key) => (
                    <div className="color-wrap" key={key} onClick={() => { selectColor(prod.id); }}>
                        <img src={buildContentfullImageUrl(productColor === prod.id ? prod.on : prod.off)} alt="magic" width="auto" height="auto" />
                    </div>
                ))}
            </div>

            <div className="size-section">
                <div className="size-header">
                    <p>サイズを選ぶ：</p>
                    <p className="sizepop-button" onClick={() => { notifySubmit('sizepop'); }}>サイズ表を見る</p>
                </div>
                <div className="">
                    <ul>
                        <li className={productSize === 'XS' ? 'selected' : ''} onClick={() => { selectSize('XS'); }}><span>XS</span></li>
                        <li className={productSize === 'S' ? 'selected' : ''} onClick={() => { selectSize('S'); }}><span>S</span></li>
                        <li className={productSize === 'M' ? 'selected' : ''} onClick={() => { selectSize('M'); }}><span>M</span></li>
                        <li className={productSize === 'L' ? 'selected' : ''} onClick={() => { selectSize('L'); }}><span>L</span></li>
                        <li className={productSize === 'XL' ? 'selected' : ''} onClick={() => { selectSize('XL'); }}><span>XL</span></li>
                        <li className={productSize === 'XXL' ? 'selected' : ''} onClick={() => { selectSize('XXL'); }}><span>XXL</span></li>
                    </ul>
                </div>
            </div>
            <div className="size-section price-section ">
                <div className="size-header">
                    <p>数量を選ぶ：</p>
                </div>
                <div className="">
                    <ul>
                        <li className={productPrice === '3k' ? 'selected' : ''} onClick={() => { selectPrice('3k'); }}><span>1枚  ¥3,480</span></li>
                        <li className={productPrice === '5k' ? 'selected' : ''} onClick={() => { selectPrice('5k'); }}><span>2枚  ¥5,980</span></li>
                        <li className={productPrice === '9k' ? 'selected' : ''} onClick={() => { selectPrice('9k'); }}><span>4枚  ¥9,980</span></li>
                    </ul>
                </div>
            </div>
            <div className="notify-section">
                <img
                    src={buildContentfullImageUrl(enableButton ? buttonon : buttonoff)}
                    onClick={() => {
                        notifySubmit('notify1');
                    }}
                    alt="magic"
                    width="100%"
                    height="100%"
                />
            </div>
            <div className="product-section">
                <img src={buildContentfullImageUrl(magicproduct)} alt="magic" width="100%" height="100%" />
            </div>
            <div className="content-section">
                <h2 className="ripplemock-title">製品について</h2>
                <p className="ripplemock-text">● 60ccまでのライナーやパッドの代わりに<br />
                    ● 洗濯して繰り返し使用可能<br />
                    ● 素材<br />
                　 本体：ナイロン76％、ポリウレタン24％<br />
                　 裏地：ポリエステル63％、ポリプロピレン37％<br />
                　 ガセット：ポリエステル88％、ナイロン12％<br />
                　 防水膜：ポリエステル100％<br />
                　 ※乾燥機使用可・アイロン不可
                </p>
            </div>
            <div className="accordian-section">
                <div className="accordian-mainbody">
                    <div className="accordian-title" onClick={toggleAccordian}>
                        <div className="content-toggle">
                            <p> よくいただく質問 </p>
                            <p className="purple">質問を見る
                                {/* <span> V </span> */}
                            </p>
                        </div>
                    </div>
                    { toggle && (
                        <div className="accordian-childwrap">
                            {accordionData.map(({ title, content }) => (
                                <Accordion title={title} content={content} />
                            ))}

                        </div>
                    )}
                </div>
            </div>
            <div className="notify-section">
                <img
                    src={buildContentfullImageUrl(enableButton ? buttonon : buttonoff)}
                    onClick={() => {
                        notifySubmit('notify2');
                    }}
                    alt="magic"
                    width="100%"
                    height="100%"
                />
            </div>

            <div className="footer-section">
                <img src={buildContentfullImageUrl(footer)} alt="magic" width="100%" height="100%" />
            </div>
            <style jsx>{SlickDefaultStyle}</style>
            <style jsx>{Style}</style>
            <RippleMockPopUp openNotify={openNotify} notifypop={notifypop} sizePop={sizePop} closeNotifyPop={closeNotifyPop} />
        </div>
    );
};
export default RippleMockTleMagic;
