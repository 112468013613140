import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.related-interviews {
    margin-bottom: 50px;
    width: $desktop-elements-width;

    @media (max-width: $breakpoint-max) {
        margin-bottom: 100px;
        width: 100%;
    }

    > h2 {
        color: $purple-dark;
        font-family: 'Noto Serif JP', serif;
        font-size: 25px;
        font-weight: 600;
        margin: 0 0 20px;
        padding: 0;

        @media (max-width: $breakpoint-max) {
            display: none;
        }
    }
}

:global(.retailers-dropdown) {
    margin-bottom: 0 !important;
}

:global(.related-interviews .interviews) {
    display: flex;
    font-family: 'Noto Serif JP', serif;
    justify-content: space-between;

    @media (max-width: $breakpoint-max) {
        flex-direction: column;
    }

    .interview-card {
        color: $purple-dark;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        width: 300px;

        a {
            color: $purple-dark;

            &:hover {
                text-decoration: none;
            }
        }

        @media (max-width: $breakpoint-max) {
            width: 100%;
        }

        &:nth-of-type(2) {
            @media (max-width: $breakpoint-max) {
                margin: 0;
            }
        }
    }

    h4 {
        font-size: 15px;
        margin: 10px 0 10px -8px;
        padding: 0;
    }

    h3 {
        font-size: 21px;
        margin: 0;
        padding: 0;
    }
}
`;

export default styles;