import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.sitemap {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 100px, rgba(215, 201, 224, 1) 1000px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 100px;

    h1 {
        color: $purple;
        margin: 1rem 2rem;

        @media (max-width: $breakpoint-max) {
            margin: 2rem 0 0;
        }
    }

    @media (max-width: $breakpoint-max) {
        padding-bottom: 0;
        width: 100%;
    }

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding-bottom: 0;
    }

    ul {
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-weight: 500;
        list-style-type: none;

        @media (max-width: $breakpoint-max) {
            padding-left: 0;
        }

        @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
            padding-left: 0;
        }

        li {
            h3,
            h2 {
                font-size: 24px;

                @media (max-width: $breakpoint-max) {
                    font-size: 20px;
                    margin-bottom: 18px;
                }

                a {
                    color: $purple;
                    font-size: 24px;
                    font-weight: 500;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            ul {
                padding-left: 0;

                li {
                    a {
                        color: $purple;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 34px;
                        text-decoration: underline;

                        @media (max-width: $breakpoint-max) {
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    .other-links {
        margin-top: 50px;

        a {
            color: $purple;
            font-weight: bold;
        }
    }

    .white-bg {
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 100px, rgba(255, 255, 255, 1) 1000px);
    }

    .white-pb {
        padding-bottom: 70px;

        @media (max-width: $breakpoint-max) {
            padding-bottom: 100px;
        }
    }
}
`;

export default styles;