import React, { useState, useEffect } from 'react';
import Router from 'next/router';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './DigitalCampaignLPlogin.scss';
import { storageSet, storageGet } from '../../../src/helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const DigitalCampaignLPlogin = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [regLoginText, setRegLoginText] = useState('true');
    const [retailer, setRetailer] = useState('');

    const registrationGATagsMapping = {
        'sundrug': 'whisper_register_coupon_swingpop_sundrug', // sundrug
        'mkcf': 'whisper_register_coupon_all',
        'tsuruha': 'whisper_register_coupon_all',
        'all': 'whisper_register_coupon_all',
    };

    const loginGATagsMapping = {
        'sundrug': 'whisper_login_coupon_swingpop_sundrug', // sundrug
        'mkcf': 'whisper_login_coupon_all',
        'all': 'whisper_login_coupon_all',
        'tsuruha': 'whisper_login_coupon_all',
    };

    useEffect(() => {
        const { location } = window;
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        setRetailer(mydigiretailer);
    }, []);

    useEffect(() => {
        const registered = storageGet('userFromDigitalCampaign');
        setLoggedIn(storageGet('isUserLoggedIn'));

        console.log(registered);
        if (registered) {
            setRegLoginText('false');
        } else {
            setRegLoginText('true');
        }
    }, [regLoginText]);

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });
    const Login = async () => {
        setGATagsAndEvents('event_profile_coupon_login', loginGATagsMapping[retailer], 'event_crm_action');

        storageSet('digitalCampaignflow', 'true');
        Router.push('/login');
    };

    const Register = async () => {
        setGATagsAndEvents('event_profile_coupon_register', registrationGATagsMapping[retailer], 'event_crm_action');
        storageSet('digitalCampaignflow', 'true');
        Router.push('/registration');
    };

    const Continue = async () => {
        storageSet('digitalCampaignflow', 'true');
        console.log(retailer);
        if (retailer === 'sundrug') {
            Router.push('/campaign/coupon-sundrug-lp2/');
        } else if (retailer === 'all') {
            Router.push('/campaign/coupon-all-lp2/');
        } else if (retailer === 'mkcf') {
            Router.push('/campaign/coupon-mkcf-lp2/');
        } else if (retailer === 'tsuruha') {
            Router.push('/campaign/coupon-tsuruha-lp2/');
        }
    };

    const resolution = useScreenSize();
    if (!resolution) return null;

    const RegImg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/4sKgmxC5W1coQLTfBXOfsf/abc98f60d175ecb8f8458cf7aa16e690/registration_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2bOa3TcGEApJqb3B7SmKIH/3ab4677b92c0e7fd3ce31d1e7d1ebd87/registration_pc.png';
    const loginImg = 'https://images.ctfassets.net/8g0fievzqg8k/7Mtj9kYZGL5gtgRL1JLRPb/ef573b97c9d1ff08d8d0c14bc18a51f6/signin_pc.png';

    return (
        <div>
            <div className="digital-Campaing-Loginsection">
                <div className="digital-Campaing-PI clearfix">

                    {regLoginText === 'true' && loggedIn !== 'true'
                        ? (
                            <div className="login-img pi pi-left">
                                <div className="log-image-wrap">
                                    <img src={buildContentfullImageUrl(RegImg)} alt="会員登録がまだの方 登録は簡単！約30秒" />
                                </div>
                                <div className="Coupon-Campaing-Register ">
                                    <button className="Coupon-Campaing-Register-cta cta registration" type="button" onClick={Register} />
                                </div>
                            </div>
                        ) : ''}

                    {loggedIn !== 'true'
               && (regLoginText === 'true'
                   ? (
                       <div className="login-img pi pi-right">
                           {!resolution.isMobile && (
                               <div className="log-image-wrap">
                                   <img src={buildContentfullImageUrl(loginImg)} alt="すでに会員の方 ウィスパークラブにサインインするだけ！" />
                               </div>
                           )}
                           <div className="Coupon-Campaing-Login">
                               {resolution.isMobile && <p className="login txt">すでに会員の方</p>}
                               <button className="Coupon-Campaing-Login-cta cta" type="button" onClick={Login} />
                           </div>
                       </div>
                   )
                   : (
                       <div className="login-img pi pi-right">
                           {!resolution.isMobile && (
                               <div className="log-image-wrap">
                                   <img src={buildContentfullImageUrl(loginImg)} alt="すでに会員の方 ウィスパークラブにサインインするだけ！" />
                               </div>
                           )}
                           <div className="Coupon-Campaing-Login">
                               {resolution.isMobile && <p className="login txt">すでに会員の方</p>}
                               <button className="Coupon-Campaing-Login-cta cta" type="button" onClick={Login} />
                           </div>
                       </div>
                   ))}

                    { loggedIn === 'true'
                         && (
                             <div className="login-img pi center-align">

                                 <div className="Coupon-Campaing-Login">
                                     {resolution.isMobile && <p className="login txt">すでに会員の方</p>}
                                     <button className="Coupon-Campaing-Login-cta cta continue" type="button" onClick={Continue} />
                                 </div>
                             </div>
                         )}

                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default DigitalCampaignLPlogin;
