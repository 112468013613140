import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import Layout from '../../components/Layout';
import { storageSet } from '../../helpers/StorageEvents';
import { Dialog, DialogTitle } from '../Dialog';
import style from '../../../styles/userProfile/ThanksForRegistration.scss';

class OptIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderStatus: true,
        };
    }

    componentDidMount() {
        const gaTagsMapping = {
            // '/ftq2k': 'whisper_register_complete_coupon_fb_welcia',
            // '/bSqE8': 'whisper_register_complete_coupon_whisperedm_welcia',
            // '/we2pm': 'whisper_register_complete_coupon_from_menu',
            '/s5trg~fn7rk': 'whisper_register_complete_coupon_fb_sundrug',
            '/s5trg~y8gda': 'whisper_register_complete_coupon_yh_sundrug',
            '/s5trg~e1y6m': 'whisper_register_complete_coupon_whisperedm_sundrug',
            '/w3lca~fn7rk': 'whisper_register_complete_coupon_fb_welcia',
            '/w3lca~y8gda': 'whisper_register_complete_coupon_yh_welcia',
            '/w3lca~e1y6m': 'whisper_register_complete_coupon_whisperedm_welcia',
            '/w3lca~s3e1m': 'whisper_register_complete_coupon_sem_welcia',
        };
        const { search, pathname } = window.location;
        let adBannerType = '';
        let banner = '';
        let path = '';
        const params = new URLSearchParams(search);
        console.log('params', params);
        const code = params.get('verification_code');
        const dashSplit = pathname.split('-');
        const doiSrc = dashSplit[0];

        const tilde = doiSrc.split('~');
        const tildeLen = tilde.length;
        if (tildeLen === 2) {
            [adBannerType, banner] = tilde;
            path = doiSrc;
        } else {
            [adBannerType] = tilde;
            [path] = tilde;
        }

        if (code) {
            const postData = {
                verification_code: code,
            };

            axios.post(`${process.env.JANRAIN}useverificationcodeApi`, postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            }).then((consumerResponse) => {
                if (consumerResponse.data.status === 'SUCCESS') {
                    switch (path) {
                    case '/campaign':
                        console.log('campaign optin');
                        storageSet('userFromCampaignFlag', 'true');
                        this.handleGAEvents('customEvent', 'event_campaign_action', 'event_campaign_start', 'Whisper_campaign_start');
                        this.handleGAEvents('customEvent', 'event_crm_action', 'event_login_update', 'Whisper_login_from_doicomplete');
                        Router.push({
                            pathname: '/editprofile',
                        });
                        break;
                    case '/idbinding':
                        this.handleGAEvents('customEvent', 'event_l ohaco_idbinding_action', 'event_lohaco_idbinding_start', 'Whisper_lohaco_idbinding_start');
                        this.handleGAEvents('customEvent', 'event_crm_action', 'event_login_update', 'Whisper_login_from_doicomplete');
                        Router.push({
                            pathname: '/id-bind-lohaco',
                        });
                        break;
                    case '/pelvicvideocomments':
                        Router.push({
                            pathname: '/pelvic-training-exercise-video',
                        });
                        break;
                    case '/incontvideocomments':
                        Router.push({
                            pathname: '/urinary-incontinence-message-post',
                        });
                        break;
                        // case '/pEQ2t~cUvy9f':
                        // case '/pEQ2t~tNzcR9':
                        // case '/pEQ2t~ldx4bG':
                        // case '/fcI2Q':
                        // case '/gPtdN':
                        // case '/lspP6~cUvy9f':
                        // case '/lspP6~tNzcR9':
                    case '/wSKE2':
                    case '/bSqE8':
                    case '/we2pm':
                    case '/wl1ba':
                    case '/ftq2k':
                    case '/s5trg~fn7rk':
                    case '/s5trg~y8gda':
                    case '/s5trg~e1y6m':
                        console.log('path: ', path);
                        if (['/wSKE2', '/wl1ba'].indexOf(path) === -1) {
                            console.log('case block, inside if statement, event label is:', gaTagsMapping[path]);
                            //                 (event, eventCate, eventAction, eventLabel)
                            // this.handleGAEvents('customEvent', 'event_crm_action', 'event_profile_coupon_register_complete', gaTagsMapping[path]);
                            window.dataLayer.push({
                                'event': 'customEvent',
                                'GAeventCategory': 'event_crm_action',
                                'GAeventAction': 'event_profile_coupon_register_complete',
                                'GAeventLabel': gaTagsMapping[path],
                                'GAeventValue': 0,
                                'GAeventNonInteraction': false,
                            });
                        }
                        storageSet('adBannerType', adBannerType);
                        storageSet('banner', banner);
                        storageSet('retailerUrl', pathname);
                        storageSet('doiVerifiyStatus', 1);
                        Router.push({
                            pathname: '/sundrug_lp1',
                        });
                        break;

                    case '/w3lca~fn7rk':
                    case '/w3lca~y8gda':
                    case '/w3lca~e1y6m':
                    case '/w3lca~s3e1m':
                        console.log('path: ', path);
                        if (['/wSKE2', '/wl1ba'].indexOf(path) === -1) {
                            console.log('case block, inside if statement, event label is:', gaTagsMapping[path]);
                            //                 (event, eventCate, eventAction, eventLabel)
                            // this.handleGAEvents('customEvent', 'event_crm_action', 'event_profile_coupon_register_complete', gaTagsMapping[path]);
                            window.dataLayer.push({
                                'event': 'customEvent',
                                'GAeventCategory': 'event_crm_action',
                                'GAeventAction': 'event_profile_coupon_register_complete',
                                'GAeventLabel': gaTagsMapping[path],
                                'GAeventValue': 0,
                                'GAeventNonInteraction': false,
                            });
                        }
                        storageSet('adBannerType', adBannerType);
                        storageSet('banner', banner);
                        storageSet('retailerUrl', pathname);
                        Router.push({
                            pathname: '/welcia_lp1',
                        });
                        break;
                    case '/lottery':
                        storageSet('userFromLotteryFlag', 'true');
                        storageSet('userFromRegistration', 'true');
                        window.dataLayer.push({
                            'event': 'customEvent',
                            'GAeventCategory': 'event_crm_action',
                            'GAeventAction': 'event_profile_register_complete',
                            'GAeventLabel': 'Qualified',
                            'GAeventValue': 0,
                            'GAeventNonInteraction': false,
                        });
                        Router.push({
                            pathname: '/campaign/whisper-lottery2021/',
                        });
                        break;
                    case '/digital~sugi':
                        storageSet('userFromDigitalCampaign', 'true');
                        Router.push({
                            pathname: '/campaign/coupon-sugi-lp1/',
                        });
                        break;

                    case '/digital~sundrug':
                        storageSet('userFromDigitalCampaign', 'true');
                        Router.push({
                            pathname: '/campaign/coupon-sundrug-lp1/',
                        });
                        break;

                    case '/digital~all':
                        storageSet('userFromDigitalCampaign', 'true');
                        Router.push({
                            pathname: '/campaign/coupon-all-lp1/',
                        });
                        break;

                    case '/digital~mkcf':
                        storageSet('userFromDigitalCampaign', 'true');
                        Router.push({
                            pathname: '/campaign/coupon-mkcf-lp1/',
                        });
                        break;

                    case '/digital~tsuruha':
                        storageSet('userFromDigitalCampaign', 'true');
                        Router.push({
                            pathname: '/campaign/coupon-tsuruha-lp1/',
                        });
                        break;
                    case '/review':
                        storageSet('userFromRegistration', 'true');
                        window.dataLayer.push({
                            'event': 'customEvent',
                            'GAeventCategory': 'event_crm_action',
                            'GAeventAction': 'event_profile_randr_register',
                            'GAeventLabel': 'whisper_register_randrcpn',
                            'GAeventValue': 0,
                            'GAeventNonInteraction': false,
                        });
                        Router.push({
                            pathname: '/campaign/reviewcampaign/',
                        });
                        break;
                    default:
                        storageSet('doiVerifiyStatus', 0);
                        Router.push({
                            pathname: '/thanksforregistration',
                        });
                        break;
                    }
                }
            }).catch(() => {
                storageSet('doiVerifiyStatus', 0);
                Router.push({
                    pathname: '/thanksforregistration',
                });
            });
        } else {
            storageSet('doiVerifiyStatus', 0);
            Router.push({
                pathname: '/thanksforregistration',
            });
        }
    }

    componentWillUnmount() {
        this.setState({ loaderStatus: false });
    }

    handleGAEvents = async (event, eventCate, eventAction, eventLabel) => {
        console.log('OptIn handleGAEvents: ', eventLabel);
        if (window !== undefined) {
            window.dataLayer.push({
                'event': event,
                'GAeventCategory': eventCate,
                'GAeventAction': eventAction,
                'GAeventLabel': eventLabel,
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        }
        return true;
    }

    render() {
        const {
            header,
            footer,
            pageInfo,
        } = this.props;

        const { loaderStatus } = this.state;

        return (
            <div>
                <Layout
                    header={
                        header
                    }
                    footer={
                        footer
                    }
                    pageInfo={
                        pageInfo
                    }
                >
                    <div className="content-wrapper">
                        {loaderStatus && (
                            <Dialog open={true}>
                                <div className="">
                                    <DialogTitle className="Loader-overlay" component="div">
                                        <Loader
                                            type="TailSpin"
                                            color="#8347ad"
                                            className="loader"
                                            height={100}
                                            width={100}
                                            visible={true}
                                        />
                                    </DialogTitle>
                                </div>
                            </Dialog>
                        )}
                        <style jsx> {
                            style
                        }
                        </style>
                    </div>
                </Layout>
            </div>
        );
    }
}

OptIn.propTypes = {
    footer: PropTypes.object,
    header: PropTypes.object,
    pageInfo: PropTypes.object,
};

export default OptIn;
