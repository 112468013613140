import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function PaginationActions(props) {
    const { activePage, data, next, previous,
        previousButtonClass, nextButtonClass, pageNumberClass,
        breakClass, defaultPage, StartButtonClass } = props;
    const totalPages = Math.ceil(data.length / 6);

    const handlePageEvent = (e) => {
        e.preventDefault();
        defaultPage(e);
    };

    const handlePageGAEvent = (e) => {
        next(e);
        window.dataLayer.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_ratings_action',
            'GAeventAction': 'event_movie_review_more',
            'GAeventLabel': 'whisper_review_seemore',
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    };

    return (
        <div className="paginationStyles">
            <div>
                <button
                    type="button"
                    className={classNames(previousButtonClass, activePage === 1 && 'disabled')}
                    onClick={(e) => {
                        previous(e);
                    }}
                    disabled={activePage === 1}
                >
                    {'<'}
                </button>
                {activePage > 1 && totalPages > 4 && <p className={breakClass}>...</p>}
                { totalPages > 4 ? Array(...Array(4)).map((content, i) => (
                    activePage + 3 < totalPages ? (

                        <p
                            className={activePage + i === activePage ? `active ${pageNumberClass}` : `inactive ${pageNumberClass}`}
                        >
                            {activePage + i}
                        </p>
                    ) : (
                        <p
                            className={totalPages - (3 - i) === activePage ? `active ${pageNumberClass}` : `inactive ${pageNumberClass}`}
                        >
                            {totalPages - (3 - i)}
                        </p>

                    )
                )) : Array(...Array(totalPages)).map((content, i) => ((

                    <p
                        className={i + 1 === activePage ? `active ${pageNumberClass}` : `inactive ${pageNumberClass}`}
                    >
                        {i + 1}
                    </p>

                )
                ))}
                {activePage + 3 < totalPages && <p className={breakClass}>...</p>}
                <button
                    className={classNames(nextButtonClass, activePage === (totalPages) && 'disabled')}
                    type="button"
                    onClick={(e) => handlePageGAEvent(e)}
                    disabled={activePage === (totalPages)}
                >
                    {'>'}
                </button>
            </div>
            <button
                type="button"
                className={StartButtonClass}
                onClick={(e) => handlePageEvent(e)}
            >最初のページに戻る
            </button>
        </div>
    );
}

PaginationActions.propTypes = {
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    activePage: PropTypes.number.isRequired,
    previousButtonClass: PropTypes.string,
    nextButtonClass: PropTypes.string,
    pageNumberClass: PropTypes.string,
    breakClass: PropTypes.string,
    data: PropTypes.array.isRequired,
    defaultPage: PropTypes.func,
    StartButtonClass: PropTypes.string,

};
