import { get } from 'lodash';

const buildFooterLinks = (footerNavigation) => {
    const navigation = get(footerNavigation, 'items[0].navigation.navigationColumn.items', []);

    return navigation.map((item) => {
        const url = item.link.__typename === 'ExternalLink' ? item.link.url : item.link.seoHead.url;
        const newWin = item.link.__typename === 'ExternalLink' ? item.link.newWindow : false;

        return {
            title: item.displayTitle,
            url,
            newWin,
        };
    });
};

export { buildFooterLinks };
