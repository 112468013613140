import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.back-to-top {
    display: flex;
    justify-content: center;
    position: relative;
    button {
        background-color: $purple-dark;
        border: 0;
        border-radius: 25px;
        color: white;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        opacity: 0;
        padding: 8px 40px;
        position: absolute;
        top: -4rem;
        transition: opacity 300ms;
        &.visible {
            opacity: 1;
            z-index: 1;
        }
        &:hover {
            text-decoration: underline;
        }
        &::after {
            border-bottom: 7px solid transparent;
            border-left: 12px solid white;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            content: "";
            font-size: 0;
            position: absolute;
            right: 4px;
            top: 12px;
        }
    }
    @media (max-width: $breakpoint-max){
        float: left;
        width: 100%;
        position: relative;
        z-index: 0;
    }
}

.landing {
    display: flex;
    justify-content: center;
    position: relative;
    .landingbutton {
        background-color: #e11383;
        border-radius: 8px;
        width: 250px;
        height: 50px;
        color: white;
        display: inline-block;
        font-family: "Helvetica", sans-serif;
        text-align: center;
        // &::after {
        //     border-bottom: 7px solid transparent;
        //     border-left: 12px solid white;
        //     border-right: 7px solid transparent;
        //     border-top: 7px solid transparent;
        //     content: '';
        //     font-size: 0;
        //     position: absolute;
        //     right: 2px;
        //     top: 40px;
        // }
    }
}`;

export default styles;