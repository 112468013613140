module.exports = `... on IdBindingMainPageSection2{
  headingText            
  leftLogoImg1{
    imageDesktop{
      cloudinaryId
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      asset{url}
    }
  }
  leftLogoImg2{
    imageDesktop{
      cloudinaryId
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      asset{url}
    }
  }
  leftLogoImg3{
    imageDesktop{
      cloudinaryId
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      asset{url}
    }
    
  }
  leftLogoImg4{
    imageDesktop{
      cloudinaryId
      asset{url}
    }
    imageSmartphone
    {
      cloudinaryId
      asset{url}
    }
    
  }
  rightText1
  rightText2
  rightText3
  rightText4
  termAndCondition
  disclaimer
  }
`;
