import { useState, useEffect } from 'react';

const DESKTOP_BREAKPOINT = 768;

const useIsMobile = () => {
    const [width, setWidth] = useState(null);
    const [scrollY, setScrollY] = useState(0);
    const handleResize = () => setWidth(window.innerWidth);
    const handleScroll = () => setScrollY(window.scrollY);
    useEffect(() => {
        if (process.browser) {
            setWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('resize', handleResize);
                window.removeEventListener('scroll', handleResize);
            };
        }
    }, []);

    if (!width) {
        return null;
    }

    return {
        width,
        scrollY,
        isMobile: width <= DESKTOP_BREAKPOINT,
        isDesktop: width >= DESKTOP_BREAKPOINT,
    };
};

export default useIsMobile;
