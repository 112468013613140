import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useScreenSize from '../../Hooks/useScreenSize';
import Style from './BackToTop.scss';

const BackToTop = ({ text }) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const resolution = useScreenSize();
    const className = classnames({
        visible: resolution && resolution.scrollY > 300,
    });

    return (
        <div>
            <div className="back-to-top">
                <button className={className} type="button" onClick={scrollToTop}>
                    {text}
                </button>
                <style jsx>{Style}</style>
                {/* <div>
                    <button>
                        <h1 className="landingbutton">応募はこちら</h1>
                    </button>
                </div> */}
            </div>
        </div>
    );
};

BackToTop.propTypes = {
    text: PropTypes.string,
};

export default BackToTop;
