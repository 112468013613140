import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.digital-campaign-contentsection{
    width:940px;
    position: relative;
    margin: 0 auto;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: normal;
    .digital-campaign-content{
        width:100%;
        margin: 0 auto 80px;
        .digital-campaign-title{
            color:$purple;
            font-size: 28px;
            margin:0 0 50px 0;
        }
        .digital-campaign-header{
            color:$purple-dark;
            font-size: 28px;
        }
        .digital-campaign-text{
            font-size: 18px;
            line-height: 34px;
            margin: 15px 0;
            width: 100%;
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        .digital-campaign-content{
            width:100%;
            padding:0 20px;
            float: left;
            .digital-campaign-title{
                font-size: 24px;
                margin:0 0 20px 0;
            }
            .digital-campaign-header{
                color:$purple-dark;
                font-size: 24px;
            }
            .digital-campaign-text{
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .digital-campaign-content{
            width:100%;
            // padding:0 20px;
            margin: 0 auto ;
            float: left;
            .digital-campaign-header{
                color:$purple-dark;
                font-size: 17px;
            }
            .digital-campaign-text{
                font-size: 16px;     
                line-height: 30px;
            }
        }
    }
    @media only screen and (max-width: 767px){
        width:100%;
    }
}`;

export default styles;