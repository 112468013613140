import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Style from './CloudinaryImage.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const Image = (props) => {
    const { image, className, loading, asset } = props;
    const imageUrl = asset ? asset.url : image?.asset?.url;
    const imgAlt = (image?.description) ? (image?.description) : '';

    return (
        <div>
            {(imageUrl)
                && (
                    <picture className="c-picture">
                        <source media="(max-width: 360px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 360 }))} />
                        <source media="(min-width: 361px) and (max-width: 480px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 480 }))} />
                        <source media="(min-width: 481px) and (max-width: 550px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 550 }))} />
                        <source media="(min-width: 551px) and (max-width: 661px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 600 }))} />
                        <source media="(min-width: 661px) and (max-width: 766px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 767 }))} />
                        <source media="(min-width: 767px) and (max-width: 980px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 980 }))} />
                        <source media="(min-width: 980px) and (max-width: 1024px)" srcSet={buildContentfullImageUrl(getImageUrl(imageUrl, { 'width': 1024 }))} />
                        <img src={buildContentfullImageUrl(getImageUrl(imageUrl, '1920'))} className={className} alt={imgAlt} loading={loading} />
                    </picture>
                )}
            <style jsx>{Style}</style>
        </div>
    );
};

export default Image;

Image.propTypes = {
    className: PropTypes.string,
    image: PropTypes.object.isRequired,
    loading: PropTypes.any,
    asset: PropTypes.object,
};
