const { buildProducts, getSectionTitles, countFilters } = require('./ProductList.helper');

test('Builds product correctly', () => {
    const product = {
        'nameEn': 'Ichimai Futayaku Double Guard 15cc/19cm',
        'name': '1枚2役Wガード 15cc／19cm',
        'absorbency': {
            'nameEn': '15cc',
            'name': '15cc',
        },
        'lenght': {
            'nameEn': '19cm',
            'name': '19cm',
        },
        'style': null,
        'size': null,
        'page': {
            'seoHead': {
                'title': 'ウィスパー1枚2役Wガード15ccの商品紹介ページ',
                'description': 'くしゃみなどでちょこっと尿もれを感じたらウィスパー1枚2役Wガード15ccを試してみてください。おりものだけでなく尿ケアもするWガードがサッと吸水します。気になる匂いもさっと吸収。',
                'url': '/all-product/1mai2yaku/1mai2yaku-15cc/',
            },
        },
        'images': {
            'items': [
                {
                    'nameEn': 'Ichimai Futayaku Double Guard 15cc/19cm',
                    'image': {
                        'url': 'https://images.ctfassets.net/8g0fievzqg8k/2LvHMoNktEpVcXAwbSDDkD/e795a99439eda4652476b8b0f5a24e07/15cc.jpg',
                        'description': null,
                    },
                    'imageThumbnail': {
                        'url': 'https://images.ctfassets.net/8g0fievzqg8k/4wGLxmbMpXNlm9FPEDG1kG/83a149f58dcccdfd87049aaf10ee96b4/Ichimai_Futayaku_Double_Guard_15cc_19cm.png',
                        'description': null,
                    },
                },
            ],
        },
        'description': 'おりものも、水分も。',
        'checkTheReviewLink': {
            'label': 'test',
            'url': '/',
            'newWindow': true,
            'title': null,
        },
        'ratingsReviewsId': '0000',
        'gtin': '4902430874311',
    };

    const expectedOutput = {
        'absorbency': '15cc',
        'gtin': '4902430874311',
        'image': {
            'description': null,
            'url': 'https://images.ctfassets.net/8g0fievzqg8k/4wGLxmbMpXNlm9FPEDG1kG/83a149f58dcccdfd87049aaf10ee96b4/Ichimai_Futayaku_Double_Guard_15cc_19cm.png',
        },
        'length': '19cm',
        'name': '1枚2役Wガード 15cc／19cm',
        'nameEn': 'Ichimai Futayaku Double Guard 15cc/19cm',
        'rnrId': '0000',
        'size': null,
        'style': null,
        'url': '/all-product/1mai2yaku/1mai2yaku-15cc/',
    };

    expect(buildProducts(product)).toEqual(expectedOutput);
});

test('Build section titles correctly', () => {
    const data = {
        'allProducts': '全ての商品＞',
        'lookForAProduct': '商品を探す',
        'lookForTheWa': '吸水量で探す',
        'searchByLength': '長さで探す',
        'pantsType': 'パンツタイプ',

    };

    const expectedOutput = {
        'allProducts': data.allProducts,
        'categories': data.lookForAProduct,
        'absorbency': data.lookForTheWa,
        'length': data.searchByLength,
        'size': data.pantsType,
    };

    expect(getSectionTitles(data)).toEqual(expectedOutput);
});

test('Counting product filters correctly', () => {
    const filters = [
        {
            'absorbency': '3cc',
            'length': '15cm',
            'size': null,
        },
        {
            'absorbency': '15cc',
            'length': '19cm',
            'size': null,
        },
        {
            'absorbency': '80cc',
            'length': '27cm',
            'size': null,
        },
        {
            'absorbency': null,
            'length': null,
            'size': 'S～M',
        },
        {
            'absorbency': null,
            'length': null,
            'size': 'L',
        },
    ];

    const expectedOutput = { '15cc': 1, '3cc': 1, '80cc': 1 };

    expect(countFilters(filters, 'absorbency')).toEqual(expectedOutput);
});
