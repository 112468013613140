import React from 'react';
import PropTypes from 'prop-types';
import style from './CollapsibleList.scss';

const CollapsibleList = (props) => {
    const { title, children, isOpen, setIsOpen } = props;
    return (
        <>
            <span className={isOpen ? 'title active-in' : 'title active-out'} onClick={setIsOpen}>{title}</span>
            <li className={`collapse-container ${isOpen ? 'collapse-open' : 'collapse-close'}`}>{children}</li>
            <style jsx>{style}</style>
        </>
    );
};

CollapsibleList.propTypes = {
    title: PropTypes.any,
    children: PropTypes.any,
    isOpen: PropTypes.any,
    setIsOpen: PropTypes.any,
};
export default CollapsibleList;
