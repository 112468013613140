import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.Coupon-Retailer{
    width:100%;
    float:left;
    margin:0 auto;
    position: relative;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: normal;
    .Coupon-Retailer-Banner{
        width:940px;
        margin:40px auto 90px;
        position: relative;
        .Banner-header{
            width:100%;
            float: left;
            margin: 0 auto 30px;            
            text-align: center;
            color:$purple-dark;
        }
            .Retailer-Banner{
                .center-image {
                    margin: 0px 35% !important;
                }
                width:100%;
                float: left; 
                margin: 20px auto 70px; 
                position: relative;
                .Banner-image{
                    cursor: pointer;
                    width: 270px;
                    float: left;
                    border: 1px solid #ccc;
                    padding: 20px;
                    margin-right: 20px;
                    height: 270px;
                    padding-top: 8%;
                    margin: 0 20px 0 0;
                    position:relative;
                    
                    img{
                        cursor: pointer;
                        width:100%;
                    }
                    p{
                        width:100%;
                        float: left;
                        text-align:left;    
                        background-image: url(/static/images/Reatiler-arrow.png);
                        background-repeat: no-repeat;
                        background-position: 86% center;
                        background-size: 40px 10px;
                        margin: 10px 0; 
                        font-size: 20px; 
                        font-weight: 500;
                        position: absolute;
                        bottom: 25px; 
                        color: $purple;                  
                    }
            }  
            .life{
                padding-top:2% !important;
                img{
                    width: 50%;
                    margin: 0 30%;
                    text-align: center;
                }
            }            
            .bar-code{
                padding-top:2% !important;
                p {
                    margin-left: 20%;
                    font-size: 35px;
                    font-family: "Noto Sans CJK JP_Medium";
                    color:$purple-dark;
                }
                img{
                 
                    width: 700px;
                    margin: 0px 10%;
                    text-align: center;
                }
            }
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        .Coupon-Retailer-Banner{
            width:100%;
            float:left;
            margin: 0 auto;
            padding: 0 20px;
            .Banner-header{
                margin: 0 auto 20px;            
                font-size: 19px;
            }
            .Retailer-Banner{  
                .center-image {
                    margin: 0 10% !important;
                }           
                .Banner-image{                   
                    padding-top: 18%;
                    margin: 20px;         
                }  
                .life{  
                    padding-top:8% !important;                  
                    img{                      
                        margin: 0 30% 0 26%;                       
                    }
                } 
                .kokokara{
                    padding-top:27% !important;
                }   
                .bar-code{
                    padding-top:2% !important;
                    p {
                        margin-left: 0;
                        font-size: 30px;
                        text-align:center;
                    }
                    img{                     
                        width: 100%;
                        margin: 0px;
                    }
                }        
            }
        }        
    }
}
`;

export default styles;