import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import Style from './DigitalCampaignLP.scss';
import DigitalCampaignLPContent from './DigitalCampaignLPContent';
import DigitalCampaignLPBanner from './DigitalCampaignLPbanner';
import DigitalCampaignbarcode from './DigitalCampaignbarcode';
import DigitalCampaignAllRetailer from './DigitalCampaignLPAllRetailer';
import { storageGet } from '../../../src/helpers/StorageEvents';

const DigitalCampaignLP2 = () => {
    const [multiReatailer, setmultiRetailer] = useState('false');

    useEffect(() => {
        const { location } = window;
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        const retailerlogo = mydigiretailer;
        const userLoggedIn = storageGet('isUserLoggedIn');
        if (userLoggedIn !== 'true') {
            if (retailerlogo === 'all') {
                Router.push('/campaign/coupon-all-lp1/');
            } else if (retailerlogo === 'mkcf') {
                Router.push('/campaign/coupon-mkcf-lp1/');
            } else if (retailerlogo === 'tsuruha') {
                Router.push('/campaign/coupon-tsuruha-lp1/');
            } else if (retailerlogo === 'sundrug') {
                Router.push('/campaign/coupon-sundrug-lp1/');
            }
        }
        console.log('retailerlogo', retailerlogo);
        if (retailerlogo === 'all' || retailerlogo === 'mkcf' || retailerlogo === 'tsuruha') {
            setmultiRetailer('true');
        }
    }, []);

    return (
        <>
            <div className="digital-campaign-lp">
                <p className="digital-title"> <span>【ウィスパー公式サイト】 </span></p>
                <DigitalCampaignLPBanner />
                { multiReatailer === 'false' ? <DigitalCampaignbarcode /> : <DigitalCampaignAllRetailer /> }
                <DigitalCampaignLPContent />
                <style jsx>{Style}</style>
            </div>
        </>
    );
};

export default DigitalCampaignLP2;
