import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.article-recommended-products {
    margin-bottom: 100px;

    h3 {
        color: $purple-dark;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 50px;
        margin-top: 50px;

        @media (max-width: $breakpoint-max) {
            font-size: 26px;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }

    .article-recommended-product {

        @media (max-width: $breakpoint-max) {
            margin-bottom: 15px;
            text-align: center;
        }

        &.shadow {

            img {
                border-radius: 6px;
                box-shadow: $box-shadow-25;
            }
        }

        img {
            max-width: 100%;
        }
    }
}
`;

export default styles;