import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';

.header-carousel {
    margin-top: -63px;
    position: relative;
    width: 100%;

    @media (max-width: $breakpoint-max) {
        margin-top: -80px;
    }

    img {
        width: 100%;
        @media (max-width: $breakpoint-max){
            position: relative;
            top:-20px;
        }
    }
    .popupContainer.relative {
        position: relative;
    }

    .popupContainer {
        margin: auto;        
        
        @media only screen and (min-width:320px) and (max-width:767px) {
            width:265px;
            height: 265px;
        }
    }    

    .homePagePopup {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/1y2tkIY17jbafS11FdM5sb/8b798d93f2e48d4b6a4a6782b6d828fd/home-banner-image.png");
        background-position: center;
        background-repeat: no-repeat, no-repeat;
        margin: auto;   
        width:265px;
        height: 265px;
    }
    .text-right {
        text-align: right;
    }
    .popCloseIcon {
        position: absolute;
        right: 15px;
        color: #592c82;
        top: 15px;
        width: 20px;
        height: 20px;
        border-radius: 9999px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
    .slick-prev,
    .slick-next {
        border: 1px solid $purple;
        border-radius: 50%;
        color: $purple;
        cursor: pointer;
        height: 50px;
        padding: 10px 20px;
        position: absolute;
        top: 35%;
        width: 50px;
        z-index: 1;

        &::before {
            border-bottom: 1px solid $purple-dark;
            border-left: 1px solid $purple-dark;
            content: '';
            font-size: 0;
            height: 12px;
            left: 55%;
            position: absolute;
            top: 45%;
            transform: translate(-50%, -50%);
            width: 12px;

            @media (max-width: $breakpoint-max) {
                border-width: 2px;
            }
        }

        @media (max-width: $breakpoint-max) {
            border-width: 2px;
            padding: 5px 10px;
            top: 40%;
        }
    }

    .slick-next {
        right: 11%;
        transform: rotate(225deg);

        @media (max-width: $breakpoint-max) {
            border-width: 2px;
            right: 1%;
            transform: rotate(225deg) scale(.5);
        }
    }

    .slick-prev {
        left: 11%;
        transform: rotate(45deg);

        @media (max-width: $breakpoint-max) {
            left: 1%;
            transform: rotate(45deg) scale(.5);

        }
    }

    .slick-dots {
        margin-top: -30px;
        padding-left: 0;
        position: relative;
        text-align: center;
        z-index: 100;

        li {
            display: inline;
            list-style: none;
            margin: 8px;

            button {
                background-color: $purple;
                border: 0;
                border-radius: 9px;
                color: transparent;
                height: 10px;
                opacity: .5;
                padding: 6px;
                width: 10px;
            }

            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }
    }

    .bottom-cover {
        bottom: 0;
        height: 19%;
        overflow: hidden;
        position: absolute;
        width: 100%;

        &::before {
            background-image: linear-gradient(90deg, rgb(166, 132, 53) 0%, rgb(218, 197, 150) 50%, rgb(166, 132, 53) 100%);
            border-radius: 50%;
            content: '';
            height: 200%;
            left: -10%;
            position: absolute;
            top: 10%;
            width: 120%;
        }

        &::after {
            background-color: white;
            border-radius: 50%;
            content: '';
            height: 200%;
            left: -10%;
            position: absolute;
            top: 0%;
            transform: rotate(1deg);
            transform-origin: left top;
            width: 117%;

            @media (max-width: $breakpoint-max) {
                top: 9%;
            }
        }

        @media (max-width: $breakpoint-max) {
            height: 13%;
        }
    }
}
`;

export default styles;