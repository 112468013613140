const preview = process.env.PREVIEW;
module.exports = `
... on SmallPromoBannersContainer {
banners: bannersCollection(limit: 10,preview: ${preview}) {
  items {
    nameEn
    smallText
    bigText
    midText
    imageDt {
      title
      description
      cloudinaryId
      asset{url}
    }
    imageSp {
      title
      description
      cloudinaryId
      asset{url}
    }
    button {
      ... on ButtonExternal {
        externalUrl
        text
        target
      }
      ... on ButtonInternal {
        text
        target
        url {
          seoHead {
            url
}}}}}}}
`;
