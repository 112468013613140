import React from 'react';
// import PropTypes from 'prop-types';

import { uniqueId } from 'lodash';
import Style from './FooterProducts.scss';
import Image from '../CloudinaryImage/CloudinaryImage';

const CategoryColumn = (items) => {
    const { name, page, products, image } = items.items;
    return (
        <div className="product-footer-nth">
            <div className="product-header">
                <div className="image-sizer v">
                    <Image image={image} className="footer-product-image" loading="lazy" />
                </div>
                <h4><a href={page.seoHead.url}>{name}</a></h4>
            </div>
            <ul className="product-footer-list clear">
                {products.items.map((product) => {
                    const title = product.title?.split(' ')[0];
                    return (
                        <li key={uniqueId()}>
                            {product.__typename === 'Product'
                                ? (
                                    <a href={product.page.seoHead.url}>
                                        {product.absorbency?.name || product.size?.name}
                                    </a>
                                )
                                : (
                                    <a href={page.seoHead.url}>
                                        {title}
                                    </a>
                                )}
                        </li>
                    );
                })}
            </ul>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CategoryColumn;
