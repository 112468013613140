/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import showdown from 'showdown';

const markdownConverter = new showdown.Converter();
function Typography(props) {
    const {
        className: classNameProp,
        component: componentProp,
        dangerouslySetInnerHTML,
        ...other
    } = props;
    const className = classNames(classNameProp);
    const Component = componentProp || 'p';
    if (dangerouslySetInnerHTML) {
        const getHtml = (typeof dangerouslySetInnerHTML.__html === 'string'
            && dangerouslySetInnerHTML.__html.trim() === 'VER OPINIONES')
            || (typeof dangerouslySetInnerHTML.__html === 'string'
                && dangerouslySetInnerHTML.__html.trim() === 'ESCRIBIR OPINIÓN')
            ? dangerouslySetInnerHTML.__html
            : markdownConverter.makeHtml(dangerouslySetInnerHTML.__html);
        return (
            <Component
                className={className}
                dangerouslySetInnerHTML={{
                    __html: getHtml,
                }}
                {...other}
            />
        );
    }
    return <Component className={className} {...other} />;
}
Typography.propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    dangerouslySetInnerHTML: PropTypes.any,
};
Typography.defaultProps = {
    className: '',
    component: 'p',
};
export default Typography;
