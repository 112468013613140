import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from './RippleCarousel.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const RippleCarousel = (props) => {
    const { carousel } = props;
    const [imageSet, setImageSet] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [carousalStop, setCarousalStop] = useState(false);

    let func;

    const changeSlide = () => {
        if (currentIndex < imageSet.length) {
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(1);
        }
    };

    const stopCarousel = () => {
        setCarousalStop(true);
        clearTimeout(func);
    };

    const startCarousel = () => {
        setCarousalStop(false);
    };

    useEffect(() => {
        if (carousel.length !== 0) {
            setImageSet(carousel);
        }
    }, [props]);

    useEffect(() => {
        if (carousalStop === false) {
            func = setTimeout(changeSlide, 2000);
        } else {
            window.clearTimeout(func);
            clearTimeout(func);
        }
    }, [imageSet, currentIndex, carousalStop]);

    return (
        <div className="carousel-container">
            <div className="carousel-inner" onMouseEnter={stopCarousel} onMouseLeave={startCarousel}>
                {imageSet.length !== 0 && imageSet.map((pic, i) => (
                    <div key={i} className={classNames(currentIndex === pic.id ? 'show' : 'hide', 'img-wrap')}>
                        <img src={buildContentfullImageUrl(pic.imgurl)} alt="magic" />
                    </div>
                ))}

                <div className="carousel-dots">
                    {imageSet.map((dot, i) =>
                        <div key={i} className={classNames(currentIndex === dot.id ? 'active' : '', 'dot')}> </div>)}

                </div>
            </div>
            <style jsx>{Styles}</style>

        </div>
    );
};

RippleCarousel.propTypes = {
    carousel: PropTypes.any,

};
export default RippleCarousel;
