import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';
.footer {
    padding-top: 0px;
    .back-to-top {
        display: none;
    }
}

.whisper-jp-survey-form {
    font-family: "M PLUS Rounded 1c", sans-serif;
    color: #8347ad;
    max-width: 940px;
    margin: auto;
    @media only screen and (max-width: 767px) {
        padding: 0 15px;
        width: 100%;
    }
    .preview-page-container {
        .preview_text {
            font-size: 30px;
            color: #fff;
            background: url(https://images.ctfassets.net/8g0fievzqg8k/1mxPCSGQFMeYaAdEC9fdaG/53f142bad11014357041bdaf23675bf4/question-box.png) no-repeat 50%;
            padding: 25px;
            height: 94px;
            width: 95%;
            margin: 0 auto;
            position: relative;
            margin-top: 55px;
            @media (max-width: $breakpoint-max) {
                font-size: 14px;
                width: 100%;
                height: auto;
                padding: 20px;
            }
        }
        .preview-profile-text {
            font-size: 36px;
            margin: 50px 0px;
            color: #592C82;
            @media only screen and (max-width: 767px) {
                font-size: 30px;
                margin: 15px 0px;
            }
        }
        h5 {
            font-size: 36px;
            margin: 50px 0px;
            text-align: center;
        }
        .btn-check {
            flex-basis: 0;
            flex-grow: 1;
            width: 50%;
            margin: 0px auto;
            @media only screen and (max-width: 768px) {
                width: 55%;
            }
            @media only screen and (max-width: 767px) {
                margin-bottom: 10%;
                width:70%;
            }
            @media only screen and (max-width: 375px) {
                margin-bottom: 10%;
                width:75%;
            }
            @media only screen and (max-width:320px) {
                margin-bottom: 10%;
                width:100%;
            }
        }
        .event_Profile_complete{
            background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
            color: #fff;
            background-size: 100% 100%;
            margin:25px auto 20px;
            font-size: 30px;
            padding: 5px;
            border-radius: 8px;
            cursor: pointer;
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin: 0px auto;
                font-size: 17px;
                text-align: center;
                height: auto;
            }
            // :after {
            //     content: "";
            //     background: url(https://images.ctfassets.net/8g0fievzqg8k/3NATsWGOXVCSv6XtMoSr25/7ec9b429b64f473bc2897e1f72daf438/btm-flower.png) no-repeat;
            //     padding: 130px;
            //     position: absolute;
            //     right: -140px;
            //     background-size: contain;
            //     bottom: -120px;
            // }
        }
        .survey_complete {
            background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
            color: #fff;
            background-size: 100% 100%;
            margin:25px auto 20px;
            font-size: 30px;
            padding: 5px;
            border-radius: 8px;
            cursor: pointer;
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin: 0px auto;
                font-size: 17px;
                text-align: center;
                height: auto;
            }
            // :after {
            //     content: "";
            //     background: url(https://images.ctfassets.net/8g0fievzqg8k/3NATsWGOXVCSv6XtMoSr25/7ec9b429b64f473bc2897e1f72daf438/btm-flower.png) no-repeat;
            //     padding: 130px;
            //     position: absolute;
            //     right: -140px;
            //     background-size: contain;
            //     bottom: -120px;
            // }
        }
        .frm-cta-section {           
            margin-top: 60px;
            @media only screen and (max-width: 767px) {
                padding: 0;
            }
        }
        .frm-cta-section div {
            @media only screen and (max-width: 767px) {
                text-align: center;
            }
        }
        .preview-profile-container {
            width: 100%;
            display: flex;
            color: #592C82;
            // height: 45px;
            .preview-profile-question {
                width: 40%;
                text-align: right;
                padding: 5px 15px;
                .preview-profile-question-text {
                    font-size: 24px;
                    font-weight: 500;
                    @media only screen and (max-width: 767px) {
                        font-size: 15px;
                    }
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    text-align: left;
                    padding-bottom: 0px;
                    padding-left: 0;
                }
            }
            .preview-profile-answer {
                width: 60%;
                background: #fff;
                text-align: left;
                padding: 0px 15px;
                margin-left: 5px;
                .preview-profile-answer-text {
                    font-size: 24px;
                    font-weight: 500;
                    @media only screen and (max-width: 767px) {
                        font-size: 15px;
                        padding: 5px 5px 5px 0;
                    }
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    font-size: 15px;
                    padding-left: 0;
                    margin-left: 0;
                }
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                display: block;
                margin-bottom: 20px;
            }
        }
        .survey-question-container {
            // padding: 30px;
            .preview-profile-survey {
                color: #592C82;
                text-align: left;
                padding: 0px 30px;
                @media only screen and (max-width: 767px) {
                    padding: 0;
                }
                .preview-question-text {
                    font-size: 24px;
                    padding: 10px 0px;
                    margin-top: 20px;
                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        margin-top: 0;
                    }
                    .preview-question-count {
                        font-size: 24px;
                        font-weight: 500;
                        @media only screen and (max-width: 767px) {
                            font-size: 18px;
                        }
                    }
                }
                .preview-answer-text {
                    font-size: 24px;
                    padding: 5px;
                    background: #fff;
                    margin-bottom: 24px;
                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}`;

export default styles;