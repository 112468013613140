module.exports = `
... on EmailThanksForRegistration {
    heading
    spHeading
    subheading
    spSubheading
    leftText
    rightText
    leftBtnText
    rightBtnText           
  }
`;
