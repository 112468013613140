import axios from 'axios';
import { logSuccessEvent, logException } from '../helpers/telemetry';

export default {
    getRating(productId) {
        const startTime = new Date().getTime();
        return axios
            .get(
                `${process.env.BV_API_URL
                }/reviews.json?apiversion=${
                    Number(process.env.BV_API_VERSION)
                }&passkey=${
                    process.env.BV_PASS_KEY
                }&Filter=ProductId:${
                    productId
                }&include=Products&Stats=Reviews`,
            )
            .then((response) => {
                const endTime = new Date().getTime();
                logSuccessEvent('FetchRatingSuccess', startTime, endTime, { productId });
                return response.data;
            })
            .catch((error) => {
                console.log('Failed to get reviews :', error);
                const endTime = new Date().getTime();
                logException('FetchRatingFailure', startTime, endTime, error?.response?.statusText, error?.response?.status, '', { productId, ...error?.response?.data });
            });
    },

    getMultipleProductRatings(productIds) {
        return axios
            .get(
                `${process.env.BV_API_URL
                }/statistics.json?apiversion=${
                    Number(process.env.BV_API_VERSION)
                }&passkey=${
                    process.env.BV_PASS_KEY
                }&Filter=ProductId:${
                    productIds.join(',')
                }&stats=Reviews`,
            )
            .then((response) => response.data)
            .catch((error) => {
                console.log('Failed to get reviews :', error);
            });
    },
};
