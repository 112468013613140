import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.product-list {
    .products-list-search {
        @media (max-width: $breakpoint-max) {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;
            margin-top: 25px;
            position: relative;
        }

        .filters {
            position: relative;

            @media (max-width: $breakpoint-max) {
                float: left;
                margin-bottom: 2%;
                width: 49%;

                &:nth-child(odd) {
                    margin-right: 2%;
                }

                &:nth-child(even) {
                    .search-list {
                        left: -104%;
                    }
                }
            }
        }

        .search-list {
            background-color: white;
            border-left: 1px solid $purple;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            list-style: none;
            margin-bottom: 37px;
            margin-top: 0;
            padding-left: 0;

            @media (max-width: $breakpoint-max) {
                border-top: 1px solid $purple;
                left: 0;
                margin-top: -1px;
                position: absolute;
                top: 47px;
                width: 204%;
                z-index: 11;
            }

            li {
                background-color: white;
                border-bottom: 1px solid $purple;
                border-right: 1px solid $purple;
                cursor: pointer;
                line-height: 20px;
                padding: 10px 0 10px 19px;

                @media (max-width: $breakpoint-max) {
                    padding: 12px 19px;
                }

                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    padding: 10px 0 10px 10px;
                }

                &:hover {
                    background-color: rgba(193, 163, 214, .25);

                    a {
                        color: $pink;
                        text-decoration: none;
                    }

                    &::after {
                        color: $pink;
                    }
                }

                a {
                    color: $purple;
                    font-size: 21px;
                    line-height: 20px;

                    @media (max-width: $breakpoint-max) {
                        font-size: 26px;
                        line-height: 26px;
                    }
                }

                &::after {
                    color: $purple;
                    content: ' >';  // non-breaking-space in content (Alt+0160)
                    font-size: 24px;
                }

                h1 {
                    display: inline;
                    font-weight: normal;
                    margin: 0;
                    padding: 0;

                    a {
                        color: $pink;

                        &::after {
                            color: $pink;
                        }
                    }

                }

                &.active {
                    background-color: rgba(193, 163, 214, .25);

                    a {
                        color: $pink;
                    }

                    &::after {
                        color: $pink;
                    }
                }
            }
        }
    }

    .filter-title {
        background-color: $purple;
        clear: both;
        color: white;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 24px;
        font-weight: normal;
        line-height: 40px;
        margin-bottom: 0;
        margin-top: 0;
        padding: 13px 32px;
        text-align: center;

        @media (max-width: $breakpoint-max) {
            background-color: white;
            border: 1px solid $purple;
            color: $purple;
            font-size: 16px;
            line-height: 24px;
            padding: 9px 12px;
            text-align: left;

            &::after {
                color: $purple;
                content: ' >';  // non-breaking-space in content (Alt+0160)
                font-size: 22px;
            }
        }

        @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
            font-size: 16px;
        }
    }

    @media screen and (min-width: 0\\0) {
        .filter-title {
            height: 66px;
            line-height: 50px;
        }
    }

    .products-list-subtitle {
        background-color: $purple;
        padding: 13px 32px;

        h3 {
            color: white;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 35px;
            font-weight: normal;
            line-height: 40px;
            margin-bottom: 0;
            margin-top: 0;

            @media (max-width: $breakpoint-max) {
                font-size: 24px;
                line-height: 24px;
            }
        }

        @media (max-width: $breakpoint-max) {
            margin-top: 6px;
        }
    }

    @media screen and (min-width: 0\\0) {
        .products-list-subtitle {
            height: 66px;

            h3 {
                line-height: 50px;
            }
        }
    }

    .category-container {
        clear: both;

        @media (max-width: $breakpoint-max) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .category-list {
        list-style: none;
        margin: 34px -23px 12px 0;
        padding: 0;

        @media (max-width: $breakpoint-max) {
            margin-bottom: 10px;
            margin-right: 0;
            margin-top: 10px;
        }

        .item {
            &.product {
                @include product-card;

                .info {
                    font-size: 25px;
                    padding: 10px;
                    text-align: center;

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    h4 {
                        color: $purple-dark;
                        font-family: 'M PLUS Rounded 1c', sans-serif;
                        font-weight: 500;
                        margin: 10px 0;

                        @media (max-width: 440px) {
                            font-size: 1rem;
                        }
                    }
                }

                .image {
                    img {
                        max-width: 80%;
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-min) {
        .promo-banners {
            margin-bottom: -145px;
            transform: scale(.74);
            transform-origin: top left;
            width: 970px;
        }
    }

    @media (max-width: $breakpoint-max) {
        .product-list-sp {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (max-width: $breakpoint-max) {
    .products-list {
        margin: 0;
    }
}
`;

export default styles;