import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import Style from '../ReviewCampaign/ReviewCampaignLP.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ReviewCampaignLPBanner = () => {
    let resolution = useScreenSize();

    if (!resolution) {
        resolution = { isMobile: true };
    }
    const mobileImg = 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/e5a6f0a1fd85e8bdbd0dffc0abab17a9/tsuruha-group_PC.png';
    const desktopImg = 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/2fc3517b2d0a5fcb3f4fe0c4ec94cadd/tsuruhagroup_PC.png';
    const productsImg = resolution.isMobile ? mobileImg : desktopImg;
    const barCodeImg = 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/5d87d1caf7a61df3abc388bd8c8ce356/QR.png';

    return (
        <>
            <div className="product-content-section">
                <h2>【対象ツルハグループ】</h2>
                <img src={buildContentfullImageUrl(productsImg)} alt="対象ツルハグループ" />
                <img src={buildContentfullImageUrl(barCodeImg)} alt="QRコード" />
                <p>※お⼀⼈様期間中何度でもご利⽤頂けます<br />
                    ※有効期限を過ぎた場合はご利⽤頂けません<br />
                    ※⼀部取り扱いのない店舗がございます
                </p>
            </div>
            <div className="product-content-section2">
                <div className="onlypc">
                    <p>スマートフォン‧タブレットをお持ちの⽅は<br />
                        こちらのページを店頭にて画⾯上でQRコードまたはバーコードをご提⽰ください。
                    </p>
                    <p>または、こちらのページを印刷し店頭にてご提⽰ください。</p>
                </div>
                <h2>【クーポン取得に関しての注意事項】</h2>
                <p>●利⽤⽅法<br />
                    クーポン有効期間中に、対象商品と本クーポン画⾯をお会計時にレジでご提⽰ください。
                </p>
                <p>
                    ●対象店舗<br />
                    ツルハドラッグ、くすりの福太郎、ウェルネス、ウォンツ、B&D、ドラッグイレブン各店 ※ファミリーマート一体型店は除く<br />
                    ※⼀部取り扱いのない店舗がございます。
                </p>
                <p> ●クーポン対象商品<br />
                    ウィスパー１枚２役Wガード‧うすさら吸⽔‧うすさら安⼼‧うすさらエアリーシリーズ 各種
                </p>
                <p>●ご利⽤上の注意<br />
                    ※クーポンご利⽤で該当商品お買上１点につき50pt付与させていただきます。<br />
                    ※対象期間中、お⼀⼈様何度でもご利⽤頂けます。
                </p>
            </div>
            <style jsx>{Style}</style>
        </>
    );
};

export default ReviewCampaignLPBanner;
