import React from 'react';
import Style from './ReviewCampaignLP.scss';
import { productList } from './ReviewCampaignProductList';
import { myRepiAllBrands } from '../../../constants/index';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ReviewCampaignLPProductSection = () => (
    <div className="product-list-container">
        <div className="header-container">
            <h2 className="title">すでにウィスパー会員に登録済みの方</h2>
            <p className="sub-title">こちらから対象のレビューページに飛び、ログインの後、レビューの投稿をお願いいたします。</p>
        </div>
        <div className="listMainConatainer">
            {productList.map((product, index) => (
                <div className="cardWrapper" key={index}>
                    <img src={buildContentfullImageUrl(product.img)} alt={product.altText} />
                    <div className="button-cmp">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://www.myrepi.com/${product.url}`}
                        >製品レビューを書く
                        </a>
                    </div>
                </div>
            ))}
        </div>
        <div className="maincmp-btn">
            <a href={myRepiAllBrands}>その他の製品は<span>こちらから</span></a>
        </div>
        <style jsx>{Style}</style>
    </div>
);
export default ReviewCampaignLPProductSection;
