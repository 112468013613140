import React from "react";
import PropTypes from "prop-types";
import Style from "./BuyNowButton.scss";
import { checkPriceSpiderAvailability } from "./pricespiderHelper";
import { useRef } from "react";

const BuyNowButton = ({ sku }) => {
  const buttonRef = useRef(null);

  const openPSWidget = () => {
    buttonRef?.current?.querySelector(".buy-now-ps-div-ref")?.click();
    checkPriceSpiderAvailability();
  };

  return (
    <div className="buy-now-button event_buy_now" data-action-detail={sku}>
      <button
        ref={buttonRef}
        type="button"
        className="buy-now-cta"
        onClick={openPSWidget}
      >
        <div className="buttoncover" />
        <div
          id="buy-now-ps-div"
          className="ps-widget buy-now-ps-div-ref"
          ps-sku={sku}
        >
          <span className="active-label-text">購入する</span>
          <span className="inactive-label-text">売り手が見つかりません</span>
        </div>
      </button>
      <style jsx>{Style}</style>
    </div>
  );
};
BuyNowButton.propTypes = {
  sku: PropTypes.string,
};

export default BuyNowButton;
