import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.container {
    padding: 0 0 40px 0;
  
}

.Akashi-banner-top {
    width: 100%;

    .banner-wrap {
        width: 100%;
        @media only screen and (max-width: 767px){
            min-height: 135px;
        }
        // margin-bottom: 30px;
    }
    .container {

    padding: 50px 0;
    p{
        margin: 20px 0;
        font-size: 18px;
        line-height: 2em;
        font-family: "Noto Sans JP";
        font-weight: 400;
        color:#333333;
    }
}

@media only screen and (max-width: 767px){
    .container {
        p.content-para {
            font-size: 16px;
            line-height: 24px;
        }

    }
}

}



.section-made-in-japan {
    padding: 45px 0 30px;
    .profile-title {
        font-family: "Noto Sans JP";
        font-weight: normal;
        padding : 30px 0 0;
        color: $purple;

        h2{
            font-size: 26px;
            line-height: 40px;
            font-family: "Noto Sans JP";
            font-weight: normal;
        }
        @media only screen and (max-width: 767px){
            h2{
                font-size: 20px;
            line-height: 35px;

            }
        }
    }
    .container {
        padding: 0px 0;

        p {
            margin: 20px 0 50px;
            font-size: 16px ;
            line-height: 24px;
            font-family: "Noto Sans JP";
            font-weight: 400;
            color: #333333;

        }
    }

    @media only screen and (max-width: 767px){
        .container {
        padding: 0 20px 0px 20px; 
        p {
            margin: 20px 0 0px;

        }
        }  
    }

   
   
}




.section-third-mij-banner {
    .container {
        padding-bottom: 0; 
        .content-para {
            font-size: 16px;
            color: #333333;
        }
    }
}

.mij-second-banner {
    margin-top: 60px;
    @media only screen and (max-width: 767px){
    margin-top: 5px;

    }
}

.Akashi-Plant {
    .factory-manager-content {
        .profile-title {
            padding-top: 30px;
        }
    }
}

.content-para {
    font-family: "Noto Sans JP";
    font-weight: 400;
}

.factory-manager-content {
    .container{
        padding-bottom: 0;
        .profile-title{
            padding: 40px 0 22px;
            // padding-top: 40px;
            font-family: "Noto Sans JP";
            font-weight: normal;
        color: #8347AD;
        h2 {
            font-size: 26px;
            font-family: "Noto Sans JP";
            font-weight: normal;
        }
        }
    }
    .content-para {
        margin-bottom: 8px;
        color: #333333;
    }
}



.ending-section {
    width : 100%;
    background-color: #fff;
    padding:60px 0 60px;
    .extra-line {
        text-align: center;
        // font-family: "SourceSansPro-Regular";
        font-family: "Noto Serif JP";
        font-weight: normal;
        font-size: 24px;
        line-height: 48px;
        padding : 70px 0 ;
        margin : 70px 0 ;
        color: #8347AD;
    }
}   


    
@media only screen and (max-width: 767px){
    .Akashi-banner-top {
    .container {

        padding: 20px 0;
        p{
            padding: 0 20px;
            margin: 20px 0 40px;
            font-size: 20px;
            line-height: 2em;
            font-family: "Noto Sans JP";
            font-weight: 400;
            color:#333333;

        }
    }
}       



.section-third-mij-banner {
    .container {
        padding: 0 20px 10px 20px; 

    }
}

    .factory-manager-content {
        margin-bottom: 50px;
        .container {
            padding: 10px 20px 0px 20px; 
            .profile-title{
                h2 {
                font-size: 20px;
                line-height: 35px;

                }
            }
        }
    }

    .ending-section {
    padding: 20px 0;
    .extra-line {
        padding : 55px 0 35px ;
        margin : 0px 0 ;
        font-family: "Noto Serif JP";
        font-weight: normal;
        font-size: 22px;
        line-height: 40px;


    }
    }
}`;

export default styles;