import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.id-bind-unbind{
    font-family: "M PLUS Rounded 1c",sans-serif;
    font-weight: 500;
    width: 940px;
    margin: 15px auto 30px;
    text-align: center;
    position: relative;
    float: left;

    .id-binding-heading {
        position: relative;
        display: flex;
        width: 100%;
        float: left;
        justify-content: center;
        align-items: center;
        .id-binding-imageLohaco{
            width:70%;
        }
        .id-binding-imageDivider{
            width:auto;
            margin:0 40px;
        }
        .id-binding-imagewhisper{
            width:70%;
            }
        img{
            width: 100%;
        }
    }
    .id-binding-description{
        float: left;
        width: 100%;
        h1{
        font-size: 30px;
        font-family: 'M PLUS Rounded 1c', sans-serif; 
        color:$purple-dark;
        margin: 72px 0 55px 0;
        
        }
        .error-msg-status{
            color:red;
        }
    }

    .id-binding-buttons{
        float: left;
        width: 100%;
        div{
            float: left;
            width: 100%;
            h1{
                font-size: 30px;
                font-family: 'M PLUS Rounded 1c', sans-serif; 
                color:$purple-dark;
                margin: 72px 0 55px 0;
                
                }

        }
        .id-un-bind-error-status{
            float:left;
            width: 100%;
            .unBindError{
            font-size: 30px;
            font-family: 'M PLUS Rounded 1c', sans-serif; 
            color:red;
            margin: 0px 0 55px 0;
            }
    
        }
    }
    
    .id-binding-buttons{
        button{
            background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
            width: 393px;
            height: 60px;
            color: #fff;
            margin: 20px auto;
            font-size: 24px;
            border: none;
            box-shadow: none;
            font-weight: 700;
            font-family: 'M PLUS Rounded 1c',sans-serif;
        }
        button + button {
        background-color: #E3007E !important; 
        width: 393px;
            height: 60px;
            color: #fff;
            margin: 20px auto;
            font-size: 24px;
            border: none;
            box-shadow: none;
            font-weight: 700;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            margin-left:20px;
            background:none;
            border-radius: 5px;

        }
        
    }

    @media (max-width: $breakpoint-max)   {
        width: 100%;
        padding: 0 15px;
        margin-top:40px;
        float:left;
        width:100%;
        position: relative;
        .id-binding-heading{
            display: inline-block;
            width: 100%;
            float: left;
            
            .id-binding-imageLohaco{
                position: relative;
                width: 82%;
                top: 0;
                float:left;
            }
            .id-binding-imagewhisper{
                width: 95%;
                float:left;
            }
            .id-binding-imageDivider{
                float:left;
                width:15%;
                margin:0;
            }
        }
        .id-binding-description{
            h1{
                font-size: 24px;
                margin: 15px 0 25px 0
            }
            .error-msg-status{
                color:red;
            }
        }

        .id-binding-buttons{
            div{                
                h1{
                    font-size: 24px;
                    margin: 15px 0 25px 0                    
                    }   
            }
            .id-un-bind-error-status{
                float:left;
                width: 100%;
                margin:0;
                .unBindError{
                    font-size: 18px;
                    font-family: 'M PLUS Rounded 1c',sans-serif;
                    color: red;
                    margin: 25px 0;
                }    
            }
        }
        .id-binding-buttons{
            button{
                width: 270px;
                background-size: contain;
                height: 42px;
                font-size: 18px; 
                margin: 10px auto;   

            }  
            button + button{
                width: 270px;
                background-size: contain;
                height: 42px;
                font-size: 18px; 
                margin: 10px auto;
                margin-left: 0px;

            }      
        }
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px){
        .id-binding-heading{
            margin-top:40px;
            margin-bottom:40px;                        
            .id-binding-imageLohaco{               
                width: 84%; 
                float:left;              
            }
            .binding-imagewhisper{
                width:80%;
                float:left;
            }
            .id-binding-imageDivider{
                float:left;
                width:14%;
            }
        }
        .id-binding-buttons{
             
            button + button{
               margin-left:20px;
            }      
        }

    }

}`;

export default styles;