import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qnumberstyle from './qnumber.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const Qnumber = (props) => {
    useEffect(() => {
        if (props.screen !== 1) { document.getElementById(props.screen - 1).classList.remove('highlight'); }
        if (props.screen !== 6) document.getElementById(props.screen).classList.add('highlight');
    });

    const indicators = [
        'https://images.ctfassets.net/8g0fievzqg8k/6pV50ysGTFhGdXwyE8eJzW/73246986d22cf33e8ef868192ab8b042/whisper_20fall_check_pc-06.png',
        'https://images.ctfassets.net/8g0fievzqg8k/5qVLYqGme4J6ZUXbFPQwKq/304a5e2d11f591ff9d7c54112990107b/whisper_20fall_check_pc-11.png',
        'https://images.ctfassets.net/8g0fievzqg8k/2JhLpuf585XZxmyasewOl4/6db3a26a3887ecf7805e13c9ca87b52e/whisper_20fall_check_pc-12.png',
        'https://images.ctfassets.net/8g0fievzqg8k/4lCaMmj8B0eqIHJc44KY1T/86a7b9b4e2ea4dbde7ff9d1cf5595fec/whisper_20fall_check_pc-13.png',
        'https://images.ctfassets.net/8g0fievzqg8k/7sbQ8aJE5PPJ8WajF9crPf/384335c075a7bee95001d56e0349bfd9/whisper_20fall_check_pc-14.png',
    ];
    const activeIndicators = [
        'https://images.ctfassets.net/8g0fievzqg8k/6pV50ysGTFhGdXwyE8eJzW/73246986d22cf33e8ef868192ab8b042/whisper_20fall_check_pc-06.png',
        'https://images.ctfassets.net/8g0fievzqg8k/4S6uRcWF74S1sMH8brFjNX/753ef1da5d90bf7216f6bb777bb6be5d/whisper_20fall_check_pc-07.png',
        'https://images.ctfassets.net/8g0fievzqg8k/6JRGmjRW140Kc9pBhGqbhr/7de823b3fea138a2d7cc16af3d2c9098/whisper_20fall_check_pc-08.png',
        'https://images.ctfassets.net/8g0fievzqg8k/6DxqG6JIWeaobm3eHU4899/85c392afa2e584f5c6e83c0322d34870/whisper_20fall_check_pc-09.png',
        'https://images.ctfassets.net/8g0fievzqg8k/64etwzi6Q32szglpE0ryZO/bd9c7c4a32b13e23c4a780636b011930/whisper_20fall_check_pc-10.png',
    ];
    const completedIndicator = 'https://images.ctfassets.net/8g0fievzqg8k/7EhxrsSHmsRbqBN5zCmkcR/e10d9ffa0528813e88a020eea0ccecae/whisper_20fall_check_pc-15.png';

    return (
        <div className="indicators" ref={props.ref}>
            {
                indicators.map((index, i) => (
                    <span id={i + 1} key={i}>
                        {
                            (i >= 0 && i < props.screen - 1)
                                ? <img src={buildContentfullImageUrl(completedIndicator)} alt="img" />
                                : (
                                    <img
                                        src={buildContentfullImageUrl(i === props.screen - 1
                                            ? activeIndicators[i]
                                            : indicators[i])}
                                        alt={`Icon ${i}`}
                                    />
                                )
                        }
                    </span>
                ))
            }
            <style jsx>{qnumberstyle}</style>
        </div>
    );
};
Qnumber.propTypes = {
    screen: PropTypes.func,
    ref: PropTypes.any,
};
export default Qnumber;
