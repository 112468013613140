import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.products-wrapper {
    justify-content: space-around;
    margin: 0 20px 0 0;
    width: 252px;
    a {
        text-decoration: none;
    }
    .image-wrapper {
        position: relative;
        margin-bottom: 22px;
        height: 300px;
        img {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 10px;
            width: 35px;
        }
    }
    .title {
        font-size: 28px;
        margin-bottom: 10px;
        color: $purple;
        font-family: 'Noto Sans JP';
    }
    .description {
        font-size: 16px;
        color: $text-article;
        font-family: 'Noto Sans JP';
    }
    @media all and (max-width: $breakpoint-max) {
        width: 49%;
        float: left;
        margin: 0 3px 0 0;
        min-height: 385px;
        .image-wrapper {
            margin-right: 15px;
            height: auto;
            margin-bottom: 12px;
            img {
                width: 25px;
            }
        }
        .title {
            font-size: 20px;
            margin: 0;
        }
    }
}
`;

export default styles;