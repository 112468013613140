module.exports = `
... on PromoBannerWithCta {
    nameEn
    imageDt {
      title
      description
      cloudinaryId
      asset{url}
    }
    imageSp {
      cloudinaryId
      title
      description
      asset{url}
    }
      button {
        ... on ButtonExternal {
          externalUrl
          text
          target
        }
        ... on ButtonInternal {
          text
          target
          url {
            seoHead {
              url
            }
          }
        }
      }
}
`;
