import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.digital-Campaing-Loginsection{
    width: 940px;
    margin: 20px auto 118px;
    position: relative;
    .digital-Campaing-PI{
        margin: 0 auto;
        text-align: center;
        .pi{
            width:49%;
            float:left;
            margin:0 5px;
            .log-image-wrap{
                img{
                    width:100%;
                }
            }
            .cta{
                background: url('https://images.ctfassets.net/8g0fievzqg8k/68beghVzLHrTImf1Fm85J9/d0871c336f6a0b8552384aa29366a30f/_____________152_2x.png') no-repeat 5px 0px;
                background-size: 100% 100%;
                margin:0 auto;
                text-align:center;
                padding:4px 20px;
                // background:$purple;
                width: 100%;
                height:74px;
                font-family: "M PLUS Rounded 1c", sans-serif;
                font-weight: 500;
                font-size: 28px;
                letter-spacing: 5px;
            }      
    
            .registration {
                background: url('https://images.ctfassets.net/8g0fievzqg8k/4TjYTvGIc3D3bsbUPyYs14/957f71744975920a073f896428eac167/_____________151_2x.png') no-repeat 5px 0px;
                background-size: 100% 100%;
            }

            .continue {
                background: url('https://images.ctfassets.net/8g0fievzqg8k/7HcqY3ottMBxyyiQLWejxK/6406c9e70750a77ab3bbc6e47c4d405b/button_oubo_applynow.png') no-repeat 5px 0px;
                background-size: 100% 100%;
            }
        }
        .pi-right{
            margin-right:0;
        }     
        
        .center-align{
            margin: 0 auto;
            float: none;
        }
    }
    @media only screen and (max-width: 767px){
        width:100%;
        padding:0 20px;
        margin:20px auto 50px;
        .digital-Campaing-PI{
            margin: 0 auto;
            text-align: center;
            .pi{
                width:100%;
                float:left;
                margin:0 auto;     
            }   
            .pi-right{
                margin:50px 0 0 0;
            }
        }      
    }
}`;

export default styles;