import React, { useState, useEffect } from 'react';
import Router from 'next/router';

import Style from './couponlplogin.scss';
import { storageGet } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';

const CouponLogin = () => {
    const [regLoginText, setRegLoginText] = useState('true');
    const [retUrl, setRetUrl] = useState('');

    const registrationGATagsMapping = {
        's5trgfn7rk': 'whisper_register_coupon_fb_sundrug', // sundrug-fb
        's5trgy8gda': 'whisper_register_coupon_yh_sundrug', // sundrug-ydm
        's5trge1y6m': 'whisper_register_coupon_whisperedm_sundrug', // sundrug-edm
        'w3lcafn7rk': 'whisper_register_coupon_fb_welcia', // welcia-fb
        'w3lcay8gda': 'whisper_register_coupon_yh_welcia', // welcia-ydm
        'w3lcae1y6m': 'whisper_register_coupon_whisperedm_welcia', // welcia-edm
        'w3lcas3e1m': 'whisper_register_coupon_sem_welcia', // welcia-sem
        // 'pEQ2tcUvy9f': 'whisper_register_coupon_edm_cocokara', // retailer-cocokara
        // 'pEQ2ttNzcR9': 'whisper_register_coupon_edm_tomods', // retailer-tomods
        // 'pEQ2tldx4bG': 'whisper_register_coupon_edm_life', // retailer-life
        // 'wSKE2cUvy9f': 'whisper_register_coupon_whisperedm', // whispereDM-cocokara
        // 'wSKE2tNzcR9': 'whisper_register_coupon_whisperedm', // whisper-tomods
        // 'wSKE2ldx4bG': 'whisper_register_coupon_whisperedm', // whisper-life
        // 'lspP6cUvy9f': 'whisper_register_coupon_line_cocokara', // linead-cocokara
        // 'lspP6tNzcR9': 'whisper_register_coupon_line_tomods', // linead-tomods
        // 'fcI2Q': 'whisper_register_coupon_facebook', // fb-cocokara
        // 'gPtdN': 'whisper_register_coupon_gdn', // gdn-cocokara
        // 'wl1ba': 'whisper_register_coupon_edm_welcia',
        // 'ftq2k': 'whisper_register_coupon_fb_welcia',
        // 'bSqE8': 'whisper_register_coupon_whisperedm_welcia',
        // 'we2pm': 'whisper_register_coupon_from_menu',
    };

    const loginGATagsMapping = {
        's5trgfn7rk': 'whisper_login_coupon_fb_sundrug', // sundrug-fb
        's5trgy8gda': 'whisper_login_coupon_yh_sundrug', // sundrug-ydm
        's5trge1y6m': 'whisper_login_coupon_whisperedm_sundrug', // sundrug-edm
        'w3lcafn7rk': 'whisper_login_coupon_fb_welcia', // welcia-fb
        'w3lcay8gda': 'whisper_login_coupon_yh_welcia', // welcia-ydm
        'w3lcae1y6m': 'whisper_login_coupon_whisperedm_welcia', // welcia-edm
        'w3lcas3e1m': 'whisper_login_coupon_sem_welcia', // welcia-sem
        // 'pEQ2tcUvy9f': 'whisper_login_coupon_edm_cocokara', // retailer-cocokara
        // 'pEQ2ttNzcR9': 'whisper_login_coupon_edm_tomods', // retailer-tomods
        // 'pEQ2tldx4bG': 'whisper_login_coupon_edm_life', // retailer-life
        // 'wSKE2cUvy9f': 'whisper_login_coupon_whisperedm', // whispereDM-cocokara
        // 'wSKE2tNzcR9': 'whisper_login_coupon_whisperedm', // whisper-tomods
        // 'wSKE2ldx4bG': 'whisper_login_coupon_whisperedm', // whisper-life
        // 'lspP6cUvy9f': 'whisper_login_coupon_line_cocokara', // linead-cocokara
        // 'lspP6tNzcR9': 'whisper_login_coupon_line_tomods', // linead-tomods
        // 'fcI2Q': 'whisper_login_coupon_facebook', // fb-cocokara
        // // 'fcI2Q': 'whisper_login_coupon_facebook', // fb-tomods
        // // 'fcI2Q': 'whisper_login_coupon_facebook', // fb-life
        // 'gPtdN': 'whisper_login_coupon_gdn', // gdn-cocokara
        // // 'gPtdN': 'whisper_login_coupon_gdn', // gdn-tomods
        // // 'gPtdN': 'whisper_login_coupon_gdn', // gdn-life
        // 'wl1ba': 'whisper_login_coupon_edm_welcia',
        // 'ftq2k': 'whisper_login_coupon_fb_welcia',
        // 'bSqE8': 'whisper_login_coupon_whisperedm_welcia',
        // 'we2pm': 'whisper_login_coupon_from_menu',
    };

    const gaTagsMapping = {
        '/s5trg~y8gda': 'whisper_register_complete_coupon_yh_sundrug',
    };

    useEffect(() => {
        const adBannerType = storageGet('adBannerType');
        const retailerUrl = storageGet('retailerUrl');
        const url = retailerUrl.split('-')[0].split('~').join('');
        setRetUrl(url);
        if (adBannerType) {
            setRegLoginText('false');
        } else {
            setRegLoginText('true');
        }
    }, [regLoginText]);

    useEffect(() => {
        const adBannerType = storageGet('adBannerType');
        const doiVerifiyStatus = storageGet('doiVerifiyStatus');
        const retailerUrl = storageGet('retailerUrl');
        const banner = storageGet('banner');
        if (doiVerifiyStatus === '1' && adBannerType === '/s5trg' && banner === 'y8gda' && retailerUrl) {
            window.dataLayer.push({
                'event': 'customEvent',
                'GAeventCategory': 'event_crm_action',
                'GAeventAction': 'event_profile_coupon_register_complete',
                'GAeventLabel': gaTagsMapping['/s5trg~y8gda'],
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        }
    }, []);

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    const Login = async () => {
        setGATagsAndEvents('event_profile_coupon_login', loginGATagsMapping[retUrl], 'event_crm_action');
        Router.push('/login');
    };

    const Register = async () => {
        setGATagsAndEvents('event_profile_coupon_register', registrationGATagsMapping[retUrl], 'event_crm_action');
        Router.push('/registration');
    };

    const resolution = useScreenSize();
    if (!resolution) return null;

    const loginImg1 = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/4sKgmxC5W1coQLTfBXOfsf/abc98f60d175ecb8f8458cf7aa16e690/registration_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2bOa3TcGEApJqb3B7SmKIH/3ab4677b92c0e7fd3ce31d1e7d1ebd87/registration_pc.png';

    return (
        <div className="Coupon-Campaing-Loginsection">
            <div className="Coupon-Campaing-PI clearfix">

                {regLoginText === 'true'
                    ? (
                        <div className="login-img pi pi-left">
                            <div className="log-image-wrap">
                                <img src={loginImg1} alt="会員登録がまだの方 登録は簡単！約30秒" />
                            </div>
                            <div className="Coupon-Campaing-Register ">
                                {/* <p className=" register txt">会員登録がまだの方</p> */}
                                <button className="Coupon-Campaing-Register-cta cta registration" type="button" onClick={Register} />
                            </div>
                        </div>
                    )
                    : ''}
                {regLoginText === 'true'
                    ? (
                        <div className="login-img pi pi-right">
                            {!resolution.isMobile && (
                                <div className="log-image-wrap">
                                    <img src="https://images.ctfassets.net/8g0fievzqg8k/7Mtj9kYZGL5gtgRL1JLRPb/ef573b97c9d1ff08d8d0c14bc18a51f6/signin_pc.png" alt="すでに会員の方 ウィスパークラブにサインインするだけ！" />
                                </div>
                            )}
                            <div className="Coupon-Campaing-Login">
                                {resolution.isMobile && <p className="login txt">すでに会員の方</p>}
                                <button className="Coupon-Campaing-Login-cta cta" type="button" onClick={Login} />
                            </div>
                        </div>

                    ) : (
                        <div className="Coupon-Campaing-Login pi align-center">
                            <p className="login txt">ご登録ありがとうございます。</p>
                            <button className="Coupon-Campaing-Login-cta cta" type="button" onClick={Login} />
                        </div>
                    )}
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CouponLogin;
