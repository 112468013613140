/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';
import Style from './ArticleRecommendedProducts.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';

const ArticleRecommendedProducts = ({ title, categoriesCollection }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    const categories = categoriesCollection.items;

    return (
        <div className="container">
            <div className="article-recommended-products">
                <div className="spacer-thin" />
                <h3>{title}</h3>
                <div className="row">
                    {categories.map((category, c) => {
                        const { url } = category.page.seoHead;
                        const image = resolution.isMobile ? category.imageSpArticleRecommendedProducts : category.imageDtArticleRecommendedProducts;
                        return (
                            <div className="col-md-4 article-recommended-product shadow" key={c}>
                                <a aria-label='label' className={classNameForGA(url)} {...dataActionForGA(url)} href={url} key={c}>
                                    <Image image={image} />
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

ArticleRecommendedProducts.propTypes = {
    categoriesCollection: PropTypes.object,
    title: PropTypes.string,
};

export default ArticleRecommendedProducts;
