import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.search-results {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    max-width: 990px;

    h1 {
        color: $purple;
        font-size: 40px;
        margin-bottom: 20px;
    }

    h2 {
        color: $purple;
        margin-bottom: 50px;
    }

    .tabs {
        list-style: none;
        margin: 14px 0 22px;
        padding: 0;
    }

    .tab {
        border: 1px solid $off-black;
        color: $off-black;
        cursor: pointer;
        display: inline-block;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 20px;
        font-weight: 500;
        height: 40px;
        margin-right: 22px;
        padding: 5px 14px;
        vertical-align: bottom;
        width: 218px;

        &.active {
            background-color: $purple-ultra-light;
            cursor: default;
        }
    }

    @media screen and (min-width: 0\\0) {
        .tab {
            font-weight: bold;
            line-height: 33px;
        }
    }

    .sort-bar {
        background-color: $purple;
        height: 66px;
        margin-bottom: 22px;
        padding: 15px;

        h2 {
            color: white;
            float: left;
            font-size: 30px;
            line-height: 1.2;
            margin: 0;

            @media (max-width: $breakpoint-max) {
                display: none;
            }
        }

        .sort-by-text {
            color: white;
            float: right;
            margin-right: 15px;
            margin-top: 5px;
        }
    }

    @media screen and (min-width: 0\\0) {
        .sort-bar {
            h2 {
                line-height: 43px;
            }
        }
    }

    .select {
        float: right;
        position: relative;

        .select-selected {
            background-color: white;
            border-radius: 19px;
            color: $purple;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 18px;
            height: 38px;
            padding: 5px 20px;
            position: relative;
            width: 220px;

            &::after {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 12px solid $purple;
                content: '';
                height: 0;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
            }
        }

        @media screen and (min-width: 0\\0) {
            .select-selected {
                line-height: 32px;
            }
        }

        .select-list {
            background-color: white;
            box-shadow: 7px 7px 10px rgba(#000, .5);
            color: $text-article;
            left: 20px;
            list-style: none;
            margin: 0;
            padding: 0;
            padding-top: 10px;
            position: absolute;
            top: 38px;
            z-index: 11;


            li {
                cursor: default;
                padding: 5px 36px;

                &:hover {
                    background-color: $purple-ultra-light;
                }

                &.active {
                    background-color: $purple-ultra-light;
                    position: relative;

                    &::before {
                        border-bottom: 2px solid $off-black;
                        border-left: 2px solid $off-black;
                        border-right: 0 solid transparent;
                        border-top: 0 solid transparent;
                        content: '';
                        display: block;
                        height: 5px;
                        left: 12px;
                        position: absolute;
                        top: 11px;
                        transform: rotate(-33deg);
                        width: 20px;
                    }

                    &:hover {
                        background-color: $purple-light;
                    }
                }
            }
        }
    }

    .result-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {

            &.product {
                @include product-card;

                .info {
                    font-size: 25px;
                    padding: 10px;
                    text-align: center;

                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    h4 {
                        color: $purple-dark;
                        font-family: 'M PLUS Rounded 1c', sans-serif;
                        font-weight: 500;
                        margin: 10px 0;

                        @media (max-width: 440px) {
                            font-size: 1rem;
                        }
                    }
                }

                .image {
                    img {
                        max-width: 80%;
                    }
                }
            }

            &.article {
                border-bottom: 1px solid $off-black;
                clear: both;
                padding: 22px 0;

                .image {
                    float: left;
                    height: 148px;
                    margin-right: 20px;
                    overflow: hidden;
                    width: 300px;

                    img {
                        max-width: 100%;
                    }
                }

                .info {
                    h4 {
                        color: $purple-dark;
                        font-family: 'M PLUS Rounded 1c', sans-serif;
                        font-size: 26px;
                        font-weight: 500;
                        margin: 10px 0;
                    }

                    p {
                        color: $text-article;
                        font-family: 'M PLUS Rounded 1c', sans-serif;
                        font-size: 22px;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                }

                @media (max-width: $breakpoint-max) {
                    .image {
                        float: none;
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }

                    .info {
                        h4 {
                            font-size: 18px;
                        }

                        p {
                            display: none;
                        }
                    }
                }
            }

            & > a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @media screen and (min-width: 0\\0) {
        .result-list {
            li {
                &.article {
                    .info {
                        p {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .no-result {
        align-items: center;
        display: flex;
        height: 450px;
    }
}
`;

export default styles;