import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.tab-items {
    display: flex;
    position: relative;
    margin: 0 0 170px 0;
    .image-left {
        width: 48%;
        div {
        }
    }
    .text-right {
        width: 50%;
        position: relative;
        .text-right-wrapper {
            width: 100%;
            float: left;
            position: absolute;
            border: 1px solid #ccc;
            bottom: 20px;
            left: 24px;
            height: auto;
            background: #fff;
            box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
            padding: 30px 40px;

            &::before {
                content: "";
                width: 370px;
                height: 85px;
                background: #e6d8ee;
                position: absolute;
                left: -25px;
                top: -30px;
                z-index: -1;
            }
        }
    }
    @media (max-width: $breakpoint-max) {
        background: #e6d8ee;
        margin: 0 0 100px 0;
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-bottom: 100px;
        &::before {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 15px;
            left: 10px;
            width: 50%;
            top: 80%;
            max-width: 300px;
            background: #777;
            -webkit-box-shadow: 0 15px 10px #777;
            -moz-box-shadow: 0 15px 10px #777;
            box-shadow: 0 15px 10px #777;
            -webkit-transform: rotate(-3deg);
            -moz-transform: rotate(-3deg);
            -o-transform: rotate(-3deg);
            -ms-transform: rotate(-3deg);
            transform: rotate(-3deg);
        }
        &::after {
            z-index: -1;
            position: absolute;
            content: "";
            bottom: 15px;
            left: auto;
            right: 10px;
            width: 50%;
            top: 80%;
            max-width: 300px;
            background: #777;
            -webkit-box-shadow: 0 15px 10px #777;
            -moz-box-shadow: 0 15px 10px #777;
            box-shadow: 0 15px 10px #777;
            -webkit-transform: rotate(3deg);
            -moz-transform: rotate(3deg);
            -o-transform: rotate(3deg);
            -ms-transform: rotate(3deg);
            transform: rotate(3deg);
        }

        .image-left {
            width: 100%;
            padding: 0 20px;
        }
        .text-right {
            width: 100%;
            .text-right-wrapper {
                width: 100%;
                float: left;
                position: static;
                border: none;
                height: auto;
                background: transparent;
                padding: 15px 10px;
                box-shadow: none;

                &::before {
                    content: none;
                }
            }
        }
        &:nth-child(even) {
            background: none;
            margin-bottom:0px;
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
        &:last-child{
            margin-bottom:10px;
        }
    }
    &:last-child{
        margin-bottom:60px;
    }
    &:nth-child(2){
    @media (max-width: $breakpoint-max) {
        margin-bottom: 0px;
    }}
    &:last-child{
        @media (max-width: $breakpoint-max) {
            margin-bottom: 0px;
    }}
}
`;

export default styles;