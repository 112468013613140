const preview = process.env.PREVIEW;
module.exports = `...on LohacoIdBinding
{
  sys
  {
    id
  }
  imageWhisper
          {
            imageDesktop
            {
              cloudinaryId
              asset{url}
            }
            imageSmartphone
            {
              cloudinaryId
              asset{url}
            }
          }
          imageDivider
          {
            imageDesktop
            {
              cloudinaryId
              asset{url}
            }
            imageSmartphone
            {
              cloudinaryId
              asset{url}
            }
          }
          imageLohaco
          {
            imageDesktop
            {
              cloudinaryId
              asset{url}
            }
            imageSmartphone
            {
              cloudinaryId
              asset{url}
            }
          }
  description
  buttonsCollection(limit:2,preview: ${preview})
  {
    items
    {
      ...on ButtonExternal
      {
        externalUrl
        title
        target
        nameEn
      }
    }
  }
}
`;
