const preview = process.env.PREVIEW;
module.exports = `
... on ProductDetails {
  nameEn
  product {
    nameEn
    name
    description
    description1
    absorbency {
      nameEn
      name
    }
    lenght {
      nameEn
      name
    }
    size {
      nameEn
      name
    }
    checkTheReviewLink {
      label
      url
    }
    ratingsReviewsId
    gtin
    imagesCollection(limit: 5,preview: ${preview}) {
      items {
        image {
          title
          cloudinaryId
          description
          asset{url}
        }
        imageThumbnail {
          title
          cloudinaryId
          description
          asset{url}
        }
      }
    }
  }
}
`;
