import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.clearfix {
    overflow: auto;
  }

    

.coupon-barcode {
    width: 100%;
    text-align: center;
        .barcode-wrapper {
            width: 60%;
            margin: 50px auto;
            img {
                width: 100%;
            }
        }
        
        @media only screen and (max-width: 767px) {
            .barcode-wrapper {
                width: 90%;
                margin: 50px auto;
                img {
                    width: 100%;
                }
            }
        }
}

`;

export default styles;