import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';

import { getImageUrl } from '../../adapters/cloudinary.adapter';

import style from './ArticlePromoBanners.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ArticlePromoBanners = ({ banners }) => {
    const resolution = useScreenSize();
    const ratio = 47;

    if (!resolution) {
        return null;
    }

    const renderBanners = () => banners.items.map((banner, i) => {
        const { imageSp, imageDt, button, smallText, bigText, midText } = banner;
        const image = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(imageSp.asset.url, {}) : getImageUrl(imageDt.asset.url, {}));
        const url = button.url ? button.url.seoHead.url : button.externalUrl;
        const target = button.target === '_blank' ? '_blank' : '_self';

        const bannerStyle = resolution.isMobile
            ? {
                backgroundImage: `url(${image})`,
                width: '100%',
                paddingBottom: `${ratio}%`,
            }
            : {
                backgroundImage: `url(${image})`,
                width: '100%',
                height: '180px',
            };

        const textContainerStyle = resolution.isMobile
            ? { height: `${resolution.width * (ratio / 100)}px` }
            : null;

        return (
            <div className="col-md-6 col-sm-12" key={i}>
                <a href={url}>
                    <div className="small-promo-banner-cta" style={bannerStyle}>
                        {banner.__typename !== 'SmallPromoBannerNoTextCta'
                            && (
                                <div className="text-container" style={textContainerStyle}>
                                    {smallText
                                        && <p className="banner-text-small">{smallText}</p>}
                                    {bigText
                                        && <p className="banner-text-big">{bigText}</p>}
                                    {midText && <p className="banner-text-mid">{midText}</p>}
                                    <a
                                        href={url}
                                        className="btn-round cta event_button_click"
                                        data-action-detail={url}
                                        target={target}
                                    >
                                        {button.text}
                                    </a>
                                </div>
                            )}
                        {banner.__typename === 'SmallPromoBannerNoTextCta'
                            && (
                                <a
                                    href={url}
                                    className={`btn-round cta event_button_click ${banner.__typename === 'SmallPromoBannerNoTextCta' ? 'no-text' : ''}`}
                                    data-action-detail={url}
                                    target={target}
                                >
                                    {button.text}
                                </a>
                            )}
                    </div>
                </a>
                <style jsx>{style}</style>
            </div>
        );
    });

    return (
        <div className="small-promo-banners-container container">
            <div className="row">
                {renderBanners()}
            </div>
            <style jsx>{style}</style>
        </div>
    );
};

ArticlePromoBanners.propTypes = {
    banners: PropTypes.object,
};

export default ArticlePromoBanners;
