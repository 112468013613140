import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './ProductReviews.scss';

const ProductReviews = ({ reviewLink, averageRating, totalReviews }) => {
    const makeStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            const modulo = averageRating % 1;
            const floor = Math.floor(averageRating);
            const dynStyle = i < floor ? {} : { width: `${100 * modulo}%` };

            stars.push(
                <span className={i < averageRating ? 'star on' : 'star'} key={i}>
                    <span className="empty">☆</span>
                    <span className="full" style={dynStyle}>★</span>
                    <style jsx>{style}</style>
                </span>,
            );
        }
        return stars;
    };

    const roundedAverageRating = averageRating > 0 ? Math.round(averageRating * 10) / 10 : 0;
    const totalReviewsClassName = classnames('numbers', { hidden: totalReviews < 1 });
    const reviewsLinkClassName = classnames({ hidden: totalReviews < 1 });

    return (
        <div className="reviews">
            <div className="stars">
                {makeStars()}
            </div>
            <div className={totalReviewsClassName}>
                {roundedAverageRating}/5 ({totalReviews})
            </div>
            <a
                className={reviewsLinkClassName}
                href={reviewLink}
                target="_blank"
                title="レビューを確認する"
                rel="noopener noreferrer"
            >
                レビューを確認する
            </a>
        </div>
    );
};

ProductReviews.propTypes = {
    averageRating: PropTypes.number,
    reviewLink: PropTypes.string,
    totalReviews: PropTypes.number,
};

export default ProductReviews;
