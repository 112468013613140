import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import useIsInViewport from 'use-is-in-viewport';
import LazyLoad from 'react-lazyload';
import useScreenSize from '../Hooks/useScreenSize';
import { isProductPages, isProductDiagnosisPages } from '../../helpers/uiHelper';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import style from './SmallPromoBannersContainer.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const SmallPromoBannersContainer = ({ banners }) => {
    let resolution = useScreenSize();
    const router = useRouter();
    const ratio = 47;

    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';
    const [isInViewport, targetRef] = useIsInViewport();

    if (!resolution) {
        resolution = { isMobile: true };
        return null;
    }

    const renderBanners = () => banners.items.map((banner, i) => {
        const { imageSp, imageDt, button, smallText, bigText, midText } = banner;
        const image = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(imageSp.asset.url, {}) : getImageUrl(imageDt.asset.url, {}));

        const url = button.url ? button.url.seoHead.url : button.externalUrl;
        const target = button.target === '_blank' ? '_blank' : '_self';

        const bannerStyle = resolution.isMobile
            ? {
                backgroundImage: `url(${image})`,
                width: '100%',
                paddingBottom: `${ratio}%`,
            }
            : {
                backgroundImage: `url(${image})`,
                width: '100%',
                height: '182px',
            };

        const textContainerStyle = resolution.isMobile
            ? { height: `${resolution.width * (ratio / 100)}px` }
            : null;

        return (
            <div className="col-md-6 col-sm-12" key={i}>
                <a href={url} data-action-detail={url} target={target} aria-label="promo">
                    <div className="small-promo-banner-cta" style={bannerStyle}>
                        <div className="text-container" style={textContainerStyle}>
                            {smallText
                                && <p className="banner-text-small">{smallText}</p>}
                            {bigText
                                && <p className="banner-text-big">{bigText}</p>}
                            {midText && <p className="banner-text-mid">{midText}</p>}
                            <a href={url} className="btn-round cta event_button_click" data-action-detail={url} target={target} aria-label={button.text}>{button.text}</a>
                        </div>
                    </div>
                </a>
                <style jsx>{style}</style>
            </div>
        );
    });

    return isHomePage || isProductPages(router.asPath) || isProductDiagnosisPages(router.asPath)
        ? (
            <div className="small-promo-banners-container homepage-small-promo container">
                <LazyLoad offset={20}>
                    <div ref={targetRef} className={isInViewport ? 'hm-animate row' : 'hm-hide row'}>
                        {renderBanners()}
                    </div>
                </LazyLoad>
                <style jsx>{style}</style>
            </div>
        )
        : (
            <div className="small-promo-banners-container container">
                <LazyLoad offset={20}>
                    <div className="row">
                        {renderBanners()}
                    </div>
                </LazyLoad>
                <style jsx>{style}</style>
            </div>
        );
};

SmallPromoBannersContainer.propTypes = {
    banners: PropTypes.object,
};

export default SmallPromoBannersContainer;
