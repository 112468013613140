import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sha256 } from 'js-sha256';
import {
    Dialog,
    DialogTitle,
} from '../src/components/Dialog';
import { buildContentfullImageUrl } from '../src/components/ReusableComponents/imageHelper';

function RippleMockPopUp(props) {
    const { openNotify, closeNotifyPop, notifypop, sizePop } = props;
    const [open, setOpen] = useState(false);

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (openNotify === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [openNotify]);

    const notifyBUttonClick = () => {
        const mailId = window.sessionStorage.getItem('customerEmail');
        const Elabel = sha256(mailId);
        setGATagsAndEvents('event_notify_mail_page', Elabel, 'event_notify_action');
        closeNotifyPop();
    };

    return (
        <>
            {sizePop && (
                <div className="homepage-popup ripplemock-popup">
                    <Dialog open={open} onClose={handleClose}>

                        <DialogTitle className="Dialog-overlay" onClick={closeNotifyPop} component="div">
                            <div className="Dialog-content">
                                <img src={buildContentfullImageUrl("https://images.ctfassets.net/8g0fievzqg8k/3mQhqpslpQeSiKZZRqphDd/58ef658fc2206bbe22e21d1baa2b1a98/size_img.png")} alt="img" />

                                <button
                                    type="button"
                                    className="closeBtn"
                                    onClick={closeNotifyPop}
                                />
                            </div>
                        </DialogTitle>
                    </Dialog>
                </div>
            )}
            {notifypop && (
                <div className="lottery-rejection ripple-another">
                    <Dialog open={open} onClose={handleClose}>

                        <DialogTitle className="Dialog-overlay" onClick={closeNotifyPop} component="div">
                            <div className="Dialog-content">
                                <div className="Dialog-error-content">
                                    <h2 className="Dialog-error-title">
                                        ありがとうございます。
                                    </h2>
                                    <p className="Dialog-error-txt notify-txt">
                                        この製品がご購入可能になりましたら <br />
                                        Eメールにてお知らせさせていただきます。
                                    </p>
                                </div>

                                <button
                                    type="button"
                                    className="notifybtn"
                                    onClick={notifyBUttonClick}
                                />

                                <button
                                    type="button"
                                    className="closeBtn"
                                    onClick={closeNotifyPop}
                                />
                            </div>
                        </DialogTitle>
                    </Dialog>
                </div>
            )}
        </>
    );
}

RippleMockPopUp.propTypes = {
    openNotify: PropTypes.any,
    closeNotifyPop: PropTypes.any,
    notifypop: PropTypes.any,
    sizePop: PropTypes.any,
};
export default RippleMockPopUp;
