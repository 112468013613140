const preview = process.env.PREVIEW;
module.exports = `
... on ProductContainer {
    title
    contentTypeCollection(limit: 5,preview: ${preview}) {
      items {
        __typename
        ... on ImageSet {
          imageDesktop {
            description
            cloudinaryId
            asset{url}
          }
          imageSmartphone {
            description
            cloudinaryId
            asset{url}
          }
        }
        ... on ContentTypeRichText {
          body
          styles
        }
        ... on SectionContainer {
          sectionContentCollection(limit: 5,preview: ${preview}) {
            items {
              __typename
              ... on ContentLt {
                content
                contentMobile
                styles
              }
              ... on ImageSet {
                imageDesktop {
                  description
                  cloudinaryId
                  asset{url}
                }
                imageSmartphone {
                  description
                  cloudinaryId
                  asset{url}
                }
              }
              ... on CategoryList {
                name
                category
                page {
                  seoHead {
                    url
                  }
                }
                image {
                  title
                  cloudinaryId
                  description
                  asset{url}
                }
                categoryImageSet {
                  imageDesktop {
                    description
                    cloudinaryId
                    asset{url}
                  }
                  imageSmartphone {
                    description
                    cloudinaryId
                    asset{url}
                  }
                  style
                }
                products: productsCollection(limit: 12,preview: ${preview}) {
                  items {
                    ... on ProductCard {
                      __typename
                      title
                    }
                  }
                }
              }
              ... on SectionContainer {
                sectionContentCollection(limit: 6,preview: ${preview}) {
                  items {
                    __typename
                    ... on ProductCard {
                      title
                      productCategory
                      imageDt {
                        description
                        cloudinaryId
                        asset{url}
                      }
                      imageSp {
                        description
                        cloudinaryId
                        asset{url}
                      }
                      productVariant {
                        description
                        url
                      }
                      rnrId:ratingsreviewsIdBazaarvoice
                      gtin
                      urlId}}}
                styles}}}
          styles}}}}
`;
