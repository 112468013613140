import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useIsInViewport from 'use-is-in-viewport';
import useScreenSize from '../Hooks/useScreenSize';
import ProductCategoryLineupItem from '../ProductCategoryLineupItem/ProductCategoryLineupItem';

import Style from './ProductFilterSection.scss';

const ProductFilterSection = ({ title, heading, category1, category2, ...props }) => {
    const [isInViewport, targetRef] = useIsInViewport();
    const [activeFilter, setActiveFilter] = useState([]);
    const [productList, setProductList] = useState(props.productsCollection.items);
    const resolution = useScreenSize();

    const Filters = ['ベーシックライン', 'プレミアムライン', 'ライナータイプ', 'パンツタイプ', 'パッドタイプ', 'ナプキンタイプ', 'ちょい漏れ', '長時間移動'];

    if (!resolution) {
        return null;
    }

    const setFilter = (filter) => {
        let filterArray = [];
        if (activeFilter.indexOf(filter) > -1) filterArray = activeFilter.filter((item) => item !== filter);
        else if (activeFilter.length < 2) filterArray = [...activeFilter, filter];
        else return;

        const filteredProduct = [];
        props.productsCollection.items.forEach((product) => {
            for (let i = 0; i < filterArray.length; i++) {
                if (product.filterCategory.indexOf(filterArray[i]) > -1) {
                    filteredProduct.push(product);
                    break;
                }
            }
        });

        setActiveFilter([...filterArray]);
        if (filteredProduct.length === 0) setProductList(props.productsCollection.items);
        else setProductList([...filteredProduct]);
    };

    return (
        <div ref={targetRef} className={isInViewport ? 'productSection hm-animate' : 'productSection hm-hide '}>
            <h2>{title}</h2>
            <div ref={targetRef} className={isInViewport ? 'filterMenuSection hm-animate' : 'filterMenuSection  hm-hide'}>
                <h3>{heading}</h3>
                <ul>
                    {Filters.map((filter) => <li className={activeFilter.indexOf(filter) > -1 ? 'filterMenu active' : 'filterMenu '} onClick={() => setFilter(filter)}>{filter}</li>)}
                </ul>
            </div>
            <div ref={targetRef} className={isInViewport ? 'productLineup hm-animate' : 'productLineup hm-hide'}>
                <div ref={targetRef} className={isInViewport ? 'basicLine category1  hm-animate' : 'basicLine category1  hm-hide'}>
                    {productList.filter((item) => item.productType === 'Basic Line').length > 0 && <h3>{category1}</h3>}
                    <div className="productCategory">
                        {
                            productList.filter((item) => item.productType === 'Basic Line').map((item) => <ProductCategoryLineupItem item={item} />)
                        }
                    </div>
                </div>
                <div className="basicLine category2">
                    {productList.filter((item) => item.productType === 'Premium Line').length > 0 && <h3>{category2}</h3>}
                    <div className="productCategory">
                        {
                            productList.filter((item) => item.productType === 'Premium Line').map((item) => <ProductCategoryLineupItem item={item} />)
                        }
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

ProductFilterSection.propTypes = {
    title: PropTypes.string,
    heading: PropTypes.string,
    productsCollection: PropTypes.object,
    category1: PropTypes.string,
    category2: PropTypes.string,
    props: PropTypes.object,
};

export default ProductFilterSection;
