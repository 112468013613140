import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './Cashbackcontent.scss';

const CashbackContent = () => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }
    return (
        <div className="Cashback-Content col-container">
            <div className="Cashback-container">
                <div className="Cashback-leftsection ls1 col">
                    <p> 対象商品</p>
                </div>
                <div className="Cashback-rightsection rs1 col">
                    <div className="right-content rc1">
                        <h3>ウィスパー全商品</h3>
                        <p>※大容量パック、2パック以上のまとめ売り商品、生理用ウィスパーは対象外</p>
                    </div>
                </div>
            </div>
            <div className="Cashback-container">
                <div className="Cashback-leftsection ls2">
                    <p>応募方法</p>
                </div>
                <div className="Cashback-rightsection rs1">
                    <div className="right-content rc2">
                        <p>専用応募はがきまたは郵便はがきにレシート原本とバーコード原本を貼付し必要事項をご記入の上、所定の郵便料金（63円以上）の切手を貼って下記の応募宛先までご郵送ください。なお、キャンペーン期間中、ご応募はお一人様１回１個限りとなります。</p>
                        <h3>以下をお貼りください。</h3>
                        <div className="right-boxtext rb-1">
                            <h4>① 対象商品ご購入時のレシート原本</h4>
                            <ul className="rh-box-list rh-1">
                                <li>● レシートの対象商品に〇をお付けください。</li>
                                <li>● レシートは原本を貼付してください。コピーの貼付は無効となります。原本貼付がはがれた場合も無効となります。</li>
                                <li>● レシートに購入日・対象商品・購入金額・購入店の記載がないものは無効となります。</li>
                                <li>● はがれないように4辺をテープなどでしっかり貼り付けてください。（レシートが長い場合は折り畳んでお貼りください。）</li>
                                <li>● 印字が消えたレシートは無効となります。印字の上にテープを貼ると印字が消える可能性がありますのでご注意ください。</li>
                                <li>●レシート・領収書・納品書等に印字されているお客様の個人情報（会員番号、有効期限、クレジットカード番号、お名前、電話番号、住所、メールアドレス等）は切り離すかまたは塗りつぶして貼付してください。</li>
                                <li>●レシートは返却できません。</li>
                            </ul>
                            <h4>② 対象商品パッケージのバーコード原本</h4>
                            <ul className="rh-box-list rh-1">
                                <li>● バーコードは「４９０２４３０」から始まる１３桁の数字が見えるように切り取って貼付してください。</li>
                                <li>● コピーの貼付は無効となります。原本貼付がはがれた場合も無効となります。</li>
                            </ul>
                        </div>
                        <h3>以下の必要事項をご記入ください。</h3>
                        <div className="right-boxtext rb-2">
                            <h4>以下の必要事項をご記入ください。必ずすべてご記入ください。未記入の場合は無効となります。</h4>
                            <ul className="rh-box-list rh-2">
                                <li>[1] お名前（フリガナ）</li>
                                <li>[2] 年代</li>
                                <li>[3] 郵便番号、ご住所（※アパートマンション名等まで正確にご記入ください。）</li>
                                <li>[4] 日中連絡が取れる電話番号</li>
                                <li>[5] 対象商品購入金額（税込）（※ご返金は対象商品毎の上限金額までとさせていただきます。）</li>
                                <li>[6] ご満足いただけなかった理由（複数回答可能）</li>
                                <li>□ モレてしまった</li>
                                <li>□ 製品のニオイが気になった</li>
                                <li>□ 消臭に不満があった</li>
                                <li>□ 付け心地が良くなかった</li>
                                <li>□ 肌にトラブルが起きた</li>
                                <li>□ 製品が薄すぎる</li>
                                <li>□ ムレが気になった</li>
                                <li>□ 不安が解消しなかった</li>
                                <li>□ その他（自由記述）</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CashbackContent;
