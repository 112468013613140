import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.product-box {
    width: 100%;
    text-align: left;
    float: left;
    margin: 80px 0 40px;
    @media only screen and (max-width: 767px) {
        &:before {
            content: "";
            padding: 90px 60px 20px;
            left: -17px;
            top: -17px;
        }
        &:after {
            background: url(https://images.ctfassets.net/8g0fievzqg8k/3pYQgPSzkwUz0IdUYkthPp/172fd92e8d4a45adedc3eb11f661c381/btm-sp.png)
                no-repeat;
            bottom: -84px;
            right: -17px;
            padding: 75px 75px 42px;
            z-index: 0;
        }
    }
}
.product-box.result {
    .result-box {
        display: flex;
        img {
            width: 50%;
            float:left;
            clear:both;
            @media only screen and (max-width: 767px){
                width:100%;
            }
        }
        .prod-title {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            .prod-name {
                color: $purple;
                font-size: 16px;
                font-weight: normal;
                text-align: left;
                font-family: 'Noto Sans JP';
                margin-bottom:8px;
            }
            .prod-variant {
                background: $purple;
                display: flex;
                width: 85px;
                color: #fff;
                float: left;
                margin: 0 10px 10px 0;
                height: 30px;
                text-align: center;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                font-size: 20px;
                font-family: "Noto Sans JP";
            }
        }
        .ps-widget[ps-sku],
        .ps-widget[data-ps-sku], .ps-widget {
            float: left;
            display: block;
            margin: 0 0 0 0;
            padding: 0 12px;
            background-color: #e11383;
            border-color: transparent;
            border-style: solid;
            border-width: 1px;
            border-radius: 7px;
            min-width: 0;
            font-family: "Helvetica", sans-serif;
            font-size: 16px;
            line-height: 40px;
            font-weight: 700;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            transition-duration: 0.2s;
            -moz-transition-duration: 0.2s;
            -webkit-transition-duration: 0.2s;
            .ps-button-label {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                font-size: 19px;
                img {
                    width: 49px;
                    vertical-align: middle;
                    padding: 5px;
                }
            }
        }
        .result-box-container {
            // flex: 0 0 50%;
            // max-width: 50%;
            position: relative;
            width: 320px;
            padding-right: 15px;
            padding-left: 15px;
            margin: 0;
            float:left;
            // @media (min-width: 576px) {
            //     flex: 0 0 50%;
            //     max-width: 50%;
            // }
            @media only screen and (max-width: 767px) {
                // max-width: 100%;
                width:48% !important;
                float:left;

            }
        }
    }
    .backToContainer {
        margin-top: 40px;
        margin-bottom: 140px;
        float:left;
        width:100%;
        text-align: center;
        .backTo {
            font-size: 20px;
            font-family: "M PLUS Rounded 1c", sans-serif;
            &:hover {
                cursor: pointer;
            }
        }
        @media only screen and (max-width: 767px){
            float:left;
            width:100%;
            .backTo{
                width:100%;
                float:left;
                margin: 0 auto;
                text-align:center;
            }

        }
    }
    @media only screen and (max-width: 767px) {
        padding: 40px 0 12px;
        margin: 0;
        background:#fff;
        .questions {
            background: url(https://images.ctfassets.net/8g0fievzqg8k/1aRjuVlHn2VHTuk9lrSrHp/8b2dad43695616796af50b23646c7ac2/ques-bx.png)
                no-repeat 50%;
            width: 100%;
            padding: 9px 0;
            background-size: contain;
            .ques {
                h2 {
                    font-size: 15px;
                    line-height: 23px;
                    margin: 0;
                }
            }
        }
        .result-box {
            display: inherit;
            width: 100%;
            // .col-12{
            //     flex: 0 0 100%;
            //     max-width: 100%;
            // }
            // .ps-widget.ps-enabled{
            //     min-width: 0;
            //     background-color: #e11383;
            //     padding: 0 12px;
            //     .ps-button-label{
            //         line-height: 21px;
            //         img{
            //             width:49px;
            //             vertical-align: middle;
            //             padding:5px;
            //         }
            //     }
            // }
            // .buy-now-button{
            //     .ps-widget{
            //         .ps-button-label{
            //             display: flex;
            //             justify-content: center;
            //             align-items: center;
            //             height: 100%;
            //             font-size: 19px;
            //         }
            //         img{
            //             width:49px;
            //         }
            //     }
            // }
        }
    }
}

.loader-part {
    float: left;
    width: 100%;
    text-align: center;
    margin: 40px 0 0 0;
    .loder {
        text-align: center;
        border-radius: 50%;
    }
}

.Article-Banner-Promo {
    float: left;
    width: 100%;
    position: relative;
    margin-top: 150px;
    @media only screen and (max-width: 767px) {
        margin-top: 80px;
    }
}
`;

export default styles;