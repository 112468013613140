const preview = process.env.PREVIEW;
module.exports = `
... on ArticleRecommendedProducts {
  title
  categoriesCollection(limit: 10,preview: ${preview}) {
    items {
      imageDtArticleRecommendedProducts {
        title
        description
        cloudinaryId
        asset{url}
      }
      imageSpArticleRecommendedProducts {
        title
        description
        cloudinaryId
        asset{url}
      }
      page {
        seoHead {
          url
        }
      }
    }
  }
}
`;
