import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.id-binding-main-section2{
    border-radius: 18px;
    margin: 40px 0 10px;
    padding: 20px 20px 22px;
    box-sizing: border-box;
    position: relative;
    float: left;
    width: 100%;
    border: 2px solid #8347ad;
    background: linear-gradient(180deg,#fff 15%,rgba(225,19,131,0.2) 180%);
    .section2-heading{
        // background: url(https://images.ctfassets.net/8g0fievzqg8k/1mxPCSGQFMeYaAdEC9fdaG/53f142bad11014357041bdaf23675bf4/question-box.png) no-repeat 50%;
        background:#592C82;
        padding: 9px 40px;
        height: 54px;
        width: 93%;
        margin: 20px auto 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius:30px;
        h1{
            color: #fff;
            font-size: 30px;
            line-height: 28px;
            margin: 0;
            font-weight: 700;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            text-align: center;
            }
        }
        .section2-content{
            padding: 20px 25px;
            .section2-logo-text{
                // display: flex;
                // align-items: center;
                padding: 10px 0;
                float:left;
                width:100%;
                position: relative;
                img{
                    margin-right: 30px;
                    float:left;
                }
                .logo-text{
                    font-family: 'M PLUS Rounded 1c',sans-serif;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 54px;
                    color:$purple-dark;
                    span{                                           
                        font-size: 60px;                        
                        line-height: 74px;
                       
                    }
                    .textbig {font-size: 60px;}
                }
                // .logo-text-3 {
                //     @media only screen and (min-width: $breakpoint-ipad-max){
                //         padding-right: 170px;
                //     }
                // }

            }
        }
        .section2-richtext{
            font-family: 'M PLUS Rounded 1c',sans-serif;
            color:$purple-dark;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;   
            width:100%; 
        }
        .termandconditon{
            position: absolute;
            bottom: -65px;
            font-size: 14px;
            left: 45px;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            color:$purple-dark;
            font-weight: 700;   
        }

        @media (max-width: $breakpoint-max){
            border: none;
            border-top: 2px solid $purple-dark;
            border-radius: 0;
            border-bottom: 2px solid $purple-dark;
            margin-bottom: 30px;
            float: left;
            width: 100%;
          
            .section2-heading{
                width: 100%;
                float: left;
                height: auto;
                display: block;
                background: none;
                padding: 0;
                margin-top: 10px;
                h1{
                    font-size: 20px;
                    line-height: 24px;
                    border: 1px solid $purple-dark;
                    padding: 10px;
                    width: 100%;
                    color:$purple-dark;
                    border-radius: 30px;
                    }
                }
                .section2-content{
                    padding: 0;
                    float: left;
                    width: 100%;
                    position: relative;                    
                    .section2-logo-text{
                        display: block;
                        text-align: center;
                        padding: 5px 0;
                        float: left;
                        width: 100%;
                        margin: 0;
                        img{
                            margin: 10px 0;
                            text-align: center;
                            width: 25%;
                            float:unset;
                        }
                        .logo-text{                       
                            font-size: 22px; 
                            line-height: 24px;                           
                            span{                                           
                                font-size: 33px; 
                                display: block; 
                                line-height: 45px;                             
                            }
                        }
                        .logo-text-2{
                            .bigtext{
                                display:inline-block;
                            }
                        }
                    }
                }
                .section2-richtext{
                    float:left;
                    width:100%;
                    font-size: 14px;
                }
                .termandconditon{
                    bottom: -70px;                  
                    left: 0; 
                    margin: 0 20px;                 
                }

        }
    }`;

export default styles;