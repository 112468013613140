import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.HeadingContainer{
    color:red;
}
.postStatus {
    text-align: left;
    margin: 20px 20px 0 0;
    p {
        color: green;
    }


    textarea{
        height:250px;
        overflow-y: scroll;
        resize: none;
    }
    .bottom-br{
        width: 100%;
        float: left;
        height: 2px;
        background: #ccc;
        display: block;
    }
    .footerfix {
        display: none;
        width: 100%;
        position: fixed;
        left: 0px;
        bottom: 2px;
        z-index: 9999;
        text-align: center;
        padding: 0 auto;
        background-color: #8347ad;
        padding: 10px;
        span {
            color:#fff;
        }
    }
}`;

export default styles;