import React, { useEffect, useState } from 'react';
// import { useSelector, shallowEqual, connect } from 'react-redux';
import Router from 'next/router';
import axios from 'axios';
import { connect } from 'react-redux';

import style from '../../../styles/userProfile/Lotteryquestions.scss';
import { storageGet } from '../../helpers/StorageEvents';

const LotteryCampaignPreview = () => {
    const [userCredentials, setUserCredentials] = useState(null);

    const handleReturn = () => {
        Router.push('/editprofile');
    };

    const handleSubmit = () => {
        if (storageGet('userFromRegistration') === 'true') {
            window.dataLayer.push({
                'event': 'customEvent',
                'GAeventCategory': 'event_informational_action',
                'GAeventAction': 'event_button_click',
                'GAeventLabel': 'confirm_lottery_register',
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        } else {
            window.dataLayer.push({
                'event': 'customEvent',
                'GAeventCategory': 'event_informational_action',
                'GAeventAction': 'event_button_click',
                'GAeventLabel': 'confirm_lottery_login',
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        }

        let consumerResponse = window.sessionStorage.getItem(`${window.location.hostname}crmDetails`);
        consumerResponse = JSON.parse(consumerResponse);
        const payload = {
            'entity_type': process.env.MAIL_SERVICE_ENTITY_TYPE,
            'uuid': consumerResponse && consumerResponse.uuid,
            'carrier_id': process.env.MAIL_SERVICE_CARRIER_ID,
            'template_id': process.env.MAIL_SERVICE_TEMPLATE_ID,
        };

        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        };

        axios.post(`${process.env.JANRAIN}lotteryemailApi`, payload, headers)
            .then((res) => {
                if (res.data.status === 'SUCCESS') {
                    window.sessionStorage.setItem('isLotteryCampaignUser', true);
                    Router.push('/campaign/lotterycp-thankyou/');
                }
                console.log(res);
            })
            .catch((err) => console.error('Error fetching Video Comments', err));
    };

    const FetchProfile = (accessToken) => new Promise((resolve, reject) => {
        axios.post(`${process.env.JANRAIN}viewprofileApi`,
            accessToken,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((consumerResponse) => {
                if (
                    consumerResponse.status === 200
                    || consumerResponse.status === 201
                ) {
                    resolve({ status: 'SUCCESS', response: consumerResponse.data });
                } else {
                    resolve({ status: 'FAIL', response: consumerResponse.data });
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    useEffect(() => {
        const crmDetails = window.sessionStorage.getItem(`${window.location.hostname}crmDetails`);
        let token = crmDetails.split(',')[0].split('{')[1];
        token = JSON.parse(`{ ${token} }`);
        FetchProfile(token).then((res) =>
            setUserCredentials(res.response));

        if (window.dataLayer) {
            window.dataLayer.push({
                'event': 'customEvent',
                'GAeventCategory': 'event_coupon_action',
                'GAeventAction': 'event_coupon_confirm',
                'GAeventLabel': 'Lottery',
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        }
    }, []);

    return (
        <div className="content-wrapper">
            <div className="whisper-jp-lottery-preview">
                <div className="frm-surveyQuestion container">
                    <h1 className="form_head">応募内容のご確認</h1>
                </div>
                <div className="preview-page-container">
                    <p className="preview_text">入力内容をご確認いただきましたら「応募する」ボタンをクリックしてください。</p>
                    <div className="lottery-qiz-area-wrap">
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">氏名</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {/* {JSON.parse(props.previewPageData)} */}
                                    {userCredentials && userCredentials.lastName}
                                    &nbsp;
                                    {userCredentials && userCredentials.firstName}
                                    {/* "sdfsfsfsfs" */}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">メールアドレス</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.emailAddress}

                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">郵便番号</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.postalcode1}

                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">ご住所</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.prefecture}
                                    &nbsp;
                                    {userCredentials && userCredentials.addresscity}
                                    &nbsp;
                                    {userCredentials && userCredentials.fulladdress}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">電話番号</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.phone}
                                </span>
                            </div>
                        </div>

                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">ご希望の製品</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.traitProductPrizePreference}
                                </span>
                            </div>
                        </div>
                        <div className="preview-profile-container">
                            <div className="preview-profile-question">
                                <span className="preview-profile-question-text">本キャンペーンをどこでお知りましたか？</span>
                            </div>
                            <div className="preview-profile-answer">
                                <span className="preview-profile-answer-text">
                                    {userCredentials && userCredentials.traitHowLearnCampaign}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="info-content">
                        <h2>ウィスパーでの個人情報の取り扱いについて</h2>
                        <p>

                            お客様からいただいた個人情報は、お客様が請求された製品やサービスのご提供や
                            メール等によるご案内、サンプル・プレゼント等の送付、P&G が提供するその他の
                            製品やサービスのご案内、本ウェブサイトやサービスの管理、統計的データとしての
                            利用のために、必要な範囲でP&G グループ企業（日本国外を含む）と共同で利用いたしま
                            す*1。また潜在的な顧客に広告配信を行うために、お客様のメールアドレスを利用する場合がございます。
                            その際は、復元不可能な手段でデータ変換を行い、個人を特定できない情報として利用いたします。
                            なお、お客様からいただいた個人情報は、 その秘密が保持されるよう P&G グループのデータベースに保管され、
                            ご本人の同意を得ずに外部機関に対してそれらの情報の交換・売買・共有を行うことはありません。ただし、
                            弊社またはP&G グループ企業は、業務代行者（日本国外を含む）へ業務遂行に必要最小限の個人情報を開示す
                            る場合があります。業務代行者は、開示された個人情報を委託した内容を超えて使用することを禁じられており、
                            情報を適切に取り扱います。P&G グループのプライバシーポリシーは<a href="https://privacypolicy.pg.com/ja/">こちら</a>
                            をご覧下さい。*1 共同利用の管理責任者：P&Gジャパン合同会社 （SK-IIに関しては、P&G プレステージ合同会社)
                        </p>
                    </div>

                    <div className="frm-button-section">
                        <div className="button-left">
                            <button type="button" onClick={handleSubmit}>応募する</button>
                        </div>
                        <div className="button-right">
                            <button type="button" onClick={handleReturn}>戻る</button>
                        </div>
                    </div>

                </div>
            </div>
            <style jsx>{style}</style>
        </div>
    );
};

LotteryCampaignPreview.propTypes = {

};

const mapStateToProps = (state) => ({ userAllData: state });
// const mapStateToProps = state => ({ allPages: state.allPages, userCredentials: state.userCredentials });

export default connect(mapStateToProps)(LotteryCampaignPreview);
