import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.VF-Banner-container{
    margin:30px 0;
    .VF-Banner-header{
        color:$purple-dark;
        font-size: 20px;
        line-height: 40px;
        text-align: center;        
    }

.VF-Banner{
    width:100%;
    float:left;
    position: relative;

    .VF-Banner-img{
        width: 80%;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    img{
        width:100%;
    }
    }
    .VF-Banner-content{
        position: absolute;
        top: 52px;
        left: 25%;
        p{
            color:$purple-dark;
            font-size: 15px;
        }
        h3{
            color:#fff;
            font-weight: normal;
            .hyper-text{
                font-size: 20px;
            }
        }
    }
}
.VC-Banner{
    .VF-Banner-img{
        width:100%;
    }
    .VF-Banner-content{
        position: absolute;
        top: 30px;
        left: 28%;
        h3{
            color:#fff;
            font-size: 30px;
            line-height: 45px;
            font-weight: normal;
            .hyper-text{
                font-size: 19px;
            }
        }
    }
}
@media only screen and (max-width: $breakpoint-max){
    margin:20px 0;
    .VF-Banner-header{
        font-size: 15px;
        line-height: 20px;  
        margin:0 50px 20px;
        text-align:center;     
                    
    }
    .VF-Banner{
   
        .VF-Banner-img{
            width: 100%;
         }
        .VF-Banner-content{
            position: absolute;
            top: 30px;
            left: 28%;
            h3{
                color:#fff;
                font-size: 30px;
                .hyper-text{
                    font-size: 19px;
                }
            }
        }
    }
    .VC-Banner{
        .VF-Banner-img{
            width:100%;
        }
        .VF-Banner-content{
            position: absolute;
            top: 30px;
            left: 28%;
            p{
                font-size: 15px;  
                color:$purple-dark;              
            }
            h3{
                color:#fff;
                font-size: 30px;
                line-height: 45px;
                font-weight: normal;
                .hyper-text{
                    font-size: 19px;
                }
            }
        }
    }
}
}`;

export default styles;