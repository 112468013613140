import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.popup-container {
    width: 100%;
    float: left;
    position: relative;
    margin: 0 auto;
    .popupContainer.relative {
        position: relative;
    }

    .popupContainer {
        margin: auto;

        @media only screen and (min-width: 320px) and (max-width: 767px) {
            width: 265px;
            height: 265px;
        }
    }

    .homePagePopup {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/1y2tkIY17jbafS11FdM5sb/8b798d93f2e48d4b6a4a6782b6d828fd/home-banner-image.png");
        background-position: center;
        background-repeat: no-repeat, no-repeat;
        margin: auto;
        width: 265px;
        height: 265px;
    }
    .text-right {
        text-align: right;
    }
    .popCloseIcon {
        position: absolute;
        right: 15px;
        color: #592c82;
        top: 15px;
        width: 20px;
        height: 20px;
        border-radius: 9999px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
}

.image-carousel {
    position: relative;
    margin-bottom: 25px;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    @media (max-width: $breakpoint-max) {
        margin-top: 0;
        width: 100%;
        margin-bottom: 0;
        z-index: 0;
    }
}
`;

export default styles;