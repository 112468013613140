import React from 'react';
import dynamic from 'next/dynamic';


const ComponentsMapper = (props) => {
    let Component;
    const modifiedprops = props;
    try {
        switch(modifiedprops.__typename){
            case 'ProductFilterSection': {
                Component = dynamic(()=>
                    import(
                        `../components/${modifiedprops.__typename}/${modifiedprops.__typename}`
                        ))
                return <Component {...modifiedprops} pageInfo={props.pageInfo} />
            }
            case 'RetailersDropdown': {
                Component = dynamic(()=>
                    import(
                        `../components/${modifiedprops.__typename}/${modifiedprops.__typename}`
                        ))
                return <Component {...modifiedprops} pageInfo={props.pageInfo} />
            }
            case 'SmallPromoBannersContainer': {
                Component = dynamic(()=>
                    import(
                        `../components/${modifiedprops.__typename}/${modifiedprops.__typename}`
                        ))
                return <Component {...modifiedprops} pageInfo={props.pageInfo} />
            }
            case 'PromoBanner': {
                Component = dynamic(()=>
                    import(
                        `../components/${modifiedprops.__typename}/${modifiedprops.__typename}`
                        ))
                return <Component {...modifiedprops} pageInfo={props.pageInfo} />
            }
            default: {
                Component = require(`../components/${modifiedprops.__typename}/${modifiedprops.__typename}`).default;
                return <Component {...modifiedprops} pageInfo={props.pageInfo} />;
            }
        }
    }
    catch {
        return null;
    }
};
export default ComponentsMapper;
