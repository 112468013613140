import React from 'react';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './Cashbackcontent.scss';

const CashbackContentII = () => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    return (
        <div className="cashback-contentII">
            <div className="Cashback-PDF">
                <h3 className="PDF-head">専用応募はがきは、こちらのボタンからPDFをダウンロードして、<br />印刷したものもご利用いただけます。</h3>
                <a className="PDF-CTA" href={`${window.location.origin}/static/images/applicationpostcard_extensionofperiod.pdf`} target="_blank" rel="noreferrer">専用応募はがきダウンロード</a>
            </div>
            <div className="Cashback-Content">
                <div className="Cashback-container">
                    <div className="Cashback-leftsection ls3">
                        <p> 応募宛先</p>
                    </div>
                    <div className="Cashback-rightsection rs3">
                        <div className="right-content rc1">
                            <h3>〒100-8645<br />
                                日本郵便(株) 銀座郵便局郵 便私書箱第527号<br />
                                ウィスパー｢全額返金保証キャンペーン｣係
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="Cashback-container">
                    <div className="Cashback-leftsection ls4">
                        <p>購入期間</p>
                    </div>
                    <div className="Cashback-rightsection rs4">
                        <div className="right-content rc2">
                            <h3>2020年10月15日（木）～2021年 6月30日（水）</h3>
                        </div>
                    </div>
                </div>
                <div className="Cashback-container">
                    <div className="Cashback-leftsection ls5">
                        <p>応募締切</p>
                    </div>
                    <div className="Cashback-rightsection rs5">
                        <div className="right-content rc2">
                            <h3>2021年7月12日(月) 当日消印有効</h3>
                        </div>
                    </div>
                </div>
                <div className="Cashback-container">
                    <div className="Cashback-leftsection ls6">
                        <p>ご返金方法</p>
                    </div>
                    <div className="Cashback-rightsection rs6">
                        <div className="right-content rc2">
                            <h3>応募受付後、約1ヶ月半前後にレシート記載の対象商品購入金額（税込）を定額小為替にて郵送します。</h3>
                            <div className="right-boxtext rb-2 no-box">
                                <h4>やむを得ない事情によりお届けが遅れる場合もありますので、予めご了承ください。</h4>
                                <h4>
                                    ※商品ご購入時の送料、手数料等の返金はございません。<br />
                                    ※返金金額は下記を上限とさせていただきます。
                                </h4>

                                <ul className="rh-box-list rh-2">
                                    <li className="bold">●うすさらシルキー（お試し2枚パックを除く） : 2,507円（税込）</li>
                                    <li className="bold">●うすさらシルキーお試し2枚パックとその他対象商品 :1,200円（税込）</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Cashback-container cc6">
                    <div className="Cashback-leftsection ls6">
                        <p>ご注意</p>
                    </div>
                    <div className="Cashback-rightsection rs6">
                        <div className="right-content rc2">
                            <div className="right-boxtext rb-2 no-box">
                                <ul className="rh-box-list rh-2">
                                    <li className="bold">●キャンペーン期間中、ご応募はお一人様1回1個限りとなります。</li>
                                    <li className="bold">●ポイントでのご購入もご応募いただけますが、レシートは必ず対象商品購入金額が分かるものを貼付してください。</li>
                                    <li className="bold">●転売店、中古販売店、個人取引などでご購入の商品は対象外となります。</li>
                                    <li className="bold">●事務局で不備、不正、重複と判断した場合は、ご連絡することなく、ご応募は無効とさせていただきます。</li>
                                    <li>●応募時の郵送料金は応募者様負担となります。</li>
                                    <li>●応募にはレシート原本と商品パッケージのバーコード原本が必要です。コピーは無効です。</li>
                                    <li>●レシートに購入日・購入商品・購入金額・購入店の記載がないものは無効となります。通信販売等でご購入の場合もこちらの記載がある領収書・納品書等を貼付してください。</li>
                                    <li>●応募期間を過ぎてもキャンペーン告知をしている場合がありますが、期限を過ぎた応募は無効となります。</li>
                                    <li>●応募時にいただいたご質問やご意見には返答いたしかねます。</li>
                                    <li>●応募は日本国内に在住で且つ連絡先が日本国内の方に限らせていただきます。</li>
                                    <li>●応募者様と連絡がつかず返金が郵送でお届けできない場合、応募は無効となります。</li>
                                    <li>●P&G社員とその家族および関係者の応募はできません。</li>
                                    <li>●販売店様で取りまとめてのご請求はご容赦願います。</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Cashback-PDF">
                <a className="PDF-CTA" href={`${window.location.origin}/static/images/application_postcard.pdf`} target="_blank" rel="noreferrer">専用応募はがきダウンロード</a>
            </div>
            <div className="Cashback-Content">
                <div className="Cashback-container">
                    <div className="Cashback-leftsection ls3">
                        <p> お問い合わせ</p>
                    </div>
                    <div className="Cashback-rightsection rs3">
                        <div className="right-content rc1">
                            <h2>0120-169-432</h2>
                            <h3>電話(フリーダイヤル)<br />
                                平日10：00～17：00<br />
                                土･日･祝日･夏季休(8/12～8/13)を除く2021年8月20日(金)まで
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="disclaimer">
                <div className="disclaimer-text">
                    <h3>【個人情報の取り扱いについて】<br />
                        お客様の個人情報は、返金の送付、ご連絡等、当キャンペーン遂行の目的で、P&Gグループ企業（日本国外を含む）と共同で利用します*1。また、業務代行者（日本国外を含む）へ個人情報の取り扱い業務を委託する場合があります。<br />
                        <a href="https://privacypolicy.pg.com/ja/"> P&Gグループのプライバシーポリシー</a><br />
                        ＊1共同利用の管理責任者：プロクター・アンド・ギャンブル・ジャパン株式会社
                    </h3>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CashbackContentII;
