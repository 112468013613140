import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.paginationStyles button:first-child, .paginationStyles button:last-child {
  padding: 4px 0px;
  margin-right: 8px;
  @media only screen and (min-width: 414px) {
    padding: 4px 12px;
    margin: 0;
    margin-right: 8px;
  }
  @media only screen and (min-width: 768px){
    padding: 10px 0px;
    margin-right: 12px;  
    width: 45px; 
  }
  
}
.paginationStyles button:last-child{
  margin-right: 0;

  @media only screen and (min-width: 768px){
    margin-right: 0;
  }
}
.paginationStyles {
  display: flex;
  button {
    border: 1px #8347AD solid;
    color: #8347AD;
    background: #fff;
    padding: 8px 21px;
    margin: 0 11px;
    font-size: 28px;
    width: 40px;
    @media only screen and (max-width: 414px) {
      padding: 3px 10px;
      margin: 0 8px;
      height: auto;
    }
    @media only screen and (max-width: 375px) {
      padding: 0px 5px;
      margin: 0 5px;
      height: auto;
    }
  }
  .StartButtonClass {
    border: none;
    background: none;
    padding: 0;
    margin: 10px;
    border-bottom: 1px #575757 solid;
    color: #575757;
  }
  .dots {
    background-color: white;
    color: black;    
    width: 30px;
  }
  p {
    display: inline-flex;
    margin: 0 8px 0 0;
    background: #8347AD;
    color: #fff;
    padding: 11px 25px;
    opacity: 0.3;
    font-size: 28px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: auto;
    @media only screen and (max-width: 600px) {
      padding: 4px 0;
      height: auto;
      border: 1px solid transparent;
    }
    @media only screen and (min-width:768px) {
      width: 45px;
    margin: 0 12px 0 0;      
    }
    &:last-child{
      margin-right: 0;
      @media only screen and (min-width:768px) {
        margin: 0 12px 0 0;      
      }
    }
  }
  .break {
    color: #592c82;
    background: none;
    opacity: 1;
    @media only screen and (max-width: 375px) {
      width: 23px;
      height: auto;
      padding: 0;
    }
  }
  p.active {
    opacity: 1;
  }
}
`;

export default styles;