const ReactDOM = require('react-dom');
const { act } = require('react-dom/test-utils');
const ArticleHelper = require('./Article.helper');

const { prepareRichTextContent } = ArticleHelper;

let container;

beforeEach(() => {
    container = document.createElement('div');
    document.body.appendChild(container);
});

afterEach(() => {
    document.body.removeChild(container);
    container = null;
});

test('Testing if rich text json is being rendered to HTML elements correctly', () => {
    const json = {
        'data': {},
        'content': [
            {
                'data': {},
                'content': [
                    {
                        'data': {},
                        'marks': [],
                        'value': 'normal text',
                        'nodeType': 'text',
                    },
                ],
                'nodeType': 'paragraph',
            },
            {
                'data': {},
                'content': [
                    {
                        'data': {},
                        'marks': [
                            {
                                'type': 'bold',
                            },
                        ],
                        'value': 'bold text',
                        'nodeType': 'text',
                    },
                ],
                'nodeType': 'paragraph',
            },
            {
                'data': {},
                'content': [
                    {
                        'data': {},
                        'marks': [],
                        'value': 'heading 1',
                        'nodeType': 'text',
                    },
                ],
                'nodeType': 'heading-1',
            },
        ],
        'nodeType': 'document',
    };

    act(() => {
        ReactDOM.render(prepareRichTextContent(json), container);
    });

    const paragraph = container.querySelector('p').innerHTML;
    const paragraphBold = container.querySelector('p b').innerHTML;
    const heading = container.querySelector('h1').innerHTML;

    expect(paragraph).toBe('normal text');
    expect(paragraphBold).toBe('bold text');
    expect(heading).toBe('heading 1');
});
