import React, { useEffect, useState } from 'react';
import Style from './couponBarcode.scss';
import useScreenSize from '../Hooks/useScreenSize';
import { storageGet } from '../../../src/helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const CouponAdBanner = () => {
    const [adBannerType, setAdBannerType] = useState('');
    const [banner, setBanner] = useState('');
    const [retailerBanner, setRetailerBanner] = useState('');

    const gaTagsOnPageVisit = {
        '/s5trgfn7rk': 'whisper_coupon_request_fb_sundrug',
        '/s5trgy8gda': 'whisper_coupon_request_yh_sundrug',
        '/s5trge1y6m': 'whisper_coupon_request_whedm_sundrug',
        '/w3lcafn7rk': 'whisper_coupon_request_fb_welcia', // welcia-fb
        '/w3lcay8gda': 'whisper_coupon_request_yh_welcia', // welcia-ydm
        '/w3lcae1y6m': 'whisper_coupon_request_whedm_welcia', // welcia-edm
        '/w3lcas3e1m': 'whisper_coupon_request_sem_welcia', // welcia-sem
    };

    const setGATagsAndEvents = async (eLabal, eAction) => {
        await window.dataLayer && window.dataLayer.push({
            event: 'customEvent',
            GAeventCategory: 'event_coupon_action',
            GAeventAction: eAction,
            GAeventLabel: eLabal,
            GAeventValue: 0,
            GAeventNonInteraction: false,
        });
    };

    useEffect(() => {
        setAdBannerType(storageGet('adBannerType'));
        setBanner(storageGet('banner'));
        const couponBanner = banner ? `${adBannerType}${banner}` : `${adBannerType}`;
        if (couponBanner) {
            setGATagsAndEvents(gaTagsOnPageVisit[couponBanner], 'event_coupon_request');
        }
    }, [adBannerType, banner]);
    useEffect(() => {
        const retailer = storageGet('retailerUrl');

        if (retailer) {
            setRetailerBanner(retailer.split('~')[0]);
        } else {
            const params = window.location.pathname;
            setRetailerBanner(params);
        }
    }, []);

    const resolution = useScreenSize();
    if (!resolution) return null;
    const barCodeimg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/4PegNRy3rHslsjVdW4Hp6v/79b0cd8f4b88db4e0534fbd070851ec4/barcode_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2CRk5qdvVV9QeBSzxvUIKE/6d606495f0aae4a9ac3deb86efe42711/__________________________________2x.png';
    const welciabarcode = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/1a86Gjaim6i9Gyh4VyJyvM/f98cdc48453f0b2dc2f48566bcc40dfc/welcia_barcode_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2p6t54PGUpyqyc8hJ0iirF/58b7fa6641bad4b655c7bf595355a184/welcia_barcode_pc.png';
    return (
        <>
            {((retailerBanner === 's5trg') || (retailerBanner === '/s5trg'))
                && (
                    <div className="coupon-barcode">
                        <div className="barcode-wrapper">
                            <img src={buildContentfullImageUrl(barCodeimg)} alt="クーポンNO：9585" />
                        </div>
                        <style jsx>{Style}</style>

                    </div>
                )}
            {((retailerBanner === 'w3lca') || (retailerBanner === '/w3lca') || (retailerBanner === '/welcia_lp2/'))
                && (
                    <div className="coupon-barcode">
                        <div className="barcode-wrapper">
                            <img src={buildContentfullImageUrl(welciabarcode)} alt="クーポンコード：373" />
                        </div>
                        <style jsx>{Style}</style>
                    </div>
                )}
        </>
    );
};

export default CouponAdBanner;
