import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import style from './IdBindLohacoBoxText.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const IdBindLohacoBoxText = (props) => {
    const { description, imageWhisper, dividerImage, lohacoImage, subtext1,
        subtext2, microText1, microText2 } = props;

    const newLocal = props.idBindLohacoBox.json.content.content;
    const content = newLocal;

    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    const Whisper = resolution.isMobile
        ? getImageUrl(imageWhisper.imageSmartphone.asset.url, {})
        : getImageUrl(imageWhisper.imageDesktop.asset.url, {});

    const Divider = resolution.isMobile
        ? getImageUrl(dividerImage.imageSmartphone.asset.url, {})
        : getImageUrl(dividerImage.imageDesktop.asset.url, {});

    const Lohaco = resolution.isMobile
        ? getImageUrl(lohacoImage.imageSmartphone.asset.url, {})
        : getImageUrl(lohacoImage.imageDesktop.asset.url, {});
    return (
        <div className="IdBindLohacoBoxText">

            <div className="id-binding-heading">
                <div className="id-binding-imagewhisper">
                    <img src={buildContentfullImageUrl(Whisper)} alt={description} />
                </div>
                <div className="id-binding-imageDivider">
                    <img src={buildContentfullImageUrl(Divider)} alt={description} />
                </div>
                <div className="id-binding-imageLohaco">
                    <img src={buildContentfullImageUrl(Lohaco)} alt={description} />
                </div>
            </div>

            <div className="IdBindLohacoBox">
                <div className="IdBindLohacoBoxText-header">
                    <h3>Yahoo! JAPAN ID を連携すると <span> こんなにおトク♪</span></h3>
                </div>
                <div className="points">
                    <div className="point1">
                        {resolution && resolution.isMobile
                            ? <h3><span className="checkbox" /><span className="lohaco"> LOHACO</span>で使える <br /><span className="blue-br">クーポンプレゼント！</span><br /></h3>
                            : <h3><span className="checkbox" /><span className="lohaco"> LOHACO</span>で使える <span className="blue-br">クーポンプレゼント！</span></h3>}
                        <h3>{subtext1}</h3>
                        <div className="hightext">
                            {resolution && resolution.isMobile
                                ? <h3><span className="txt">5つ購入</span>で<span className="pink-dot">200円</span><br /> <span className="blue-br">クーポンプレゼント</span><br /></h3>
                                : <h3><span className="txt">5つ購入</span>で<span className="pink-dot">200円</span> <span className="blue-br">クーポンプレゼント</span></h3>}
                        </div>
                        <h3>{subtext2}</h3>
                        <div className="hightext">
                            {resolution && resolution.isMobile
                                ? <h3><span className="txt">3つ購入</span>で<span className="pink-dot">500円</span><br /> <span className="blue-br">クーポンプレゼント</span><br /></h3>
                                : <h3><span className="txt">3つ購入</span>で<span className="pink-dot">500円</span> <span className="blue-br">クーポンプレゼント</span></h3>}
                        </div>
                        <p>{microText1}</p>
                        <p>{microText2}</p>
                    </div>
                    <div className="pt point2">
                        {resolution && resolution.isMobile
                            ? <h3> <span className="checkbox" />連携した人だけの<span className="grey-br">特別な<br />キャンペーン</span>に参加できる！</h3>
                            : <h3> <span className="checkbox" />連携した人だけの<span className="grey-br">特別なキャンペーン</span>に参加できる！</h3>}
                    </div>
                    <div className="pt point3">
                        {resolution && resolution.isMobile
                            ? <h3><span className="checkbox" /> 新製品サンプルなど<span className="grey-br">特別な<br />プレゼント</span>が当たる！</h3>
                            : <h3><span className="checkbox" /> 新製品サンプルなど<span className="grey-br">特別なプレゼント</span>が当たる！</h3>}
                    </div>
                    <div className="pt point4">
                        {resolution && resolution.isMobile
                            ? <h3><span className="checkbox" />他にもあなたにぴったりの <br /><span className="grey-br">おトクな情報</span>をお届け！</h3>
                            : <h3><span className="checkbox" />他にもあなたにぴったりの <span className="grey-br">おトクな情報</span>をお届け！</h3>}
                    </div>
                </div>
            </div>
            <div className="box-text">
                {content}
            </div>
            {/* <div className="microtext">
                <p>{microText3}</p>

            </div> */}

            <style jsx>{style}</style>

        </div>

    );
};

IdBindLohacoBoxText.propTypes = {
    description: PropTypes.string,
    imageWhisper: PropTypes.object,
    dividerImage: PropTypes.object,
    lohacoImage: PropTypes.object,
    subtext1: PropTypes.string,
    subtext2: PropTypes.string,
    microText1: PropTypes.string,
    microText2: PropTypes.string,
    content: PropTypes.object,
    idBindLohacoBox: PropTypes.object,

};

export default IdBindLohacoBoxText;
