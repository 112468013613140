import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TabItems from '../TabItems/TabItems';
import Styles from '../TabsContainer/TabsContainer.scss';

const TabsContainer = ({ tabsListCollection, styles }) => {
    const [tabMenuItems, setTabMenuitems] = useState(tabsListCollection.items[0].tabListItemsCollection.items);
    const [activeTabMenuItems, setActiveTabMenuitems] = useState(tabsListCollection.items[0]);

    const setTabMenu = (tabsListItem) => {
        setTabMenuitems([...tabsListItem.tabListItemsCollection.items]);
        setActiveTabMenuitems(tabsListItem);
    };
    return (
        <div className={styles}>
            {tabsListCollection.items.length > 1
                && (
                    <div className="tab-menu">
                        {
                            tabsListCollection.items.map((tabsListItem) => (
                                <div className={activeTabMenuItems === tabsListItem ? 'menu active' : 'menu'} onClick={() => setTabMenu(tabsListItem)}>
                                    {tabsListItem.menuTitle}
                                </div>
                            ))
                        }
                    </div>
                )}
            <div className="tabs-container">
                {
                    tabMenuItems.map((tabsListItem, i) => <TabItems key={i} tabsListItem={tabsListItem} />)
                }
            </div>
            <style jsx>{Styles}</style>
        </div>
    );
};

TabsContainer.propTypes = {
    tabListItemsCollection: PropTypes.object,
    tabsListCollection: PropTypes.object,
    styles: PropTypes.any,
};

export default TabsContainer;
