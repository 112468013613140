import React from 'react';
import PropTypes from 'prop-types';
import Style from './IdBindingMainpageTextboxSection3.scss';

const IdBindingMainpageTextboxSection3 = (props) => {
    const { heading } = props;
    return (
        <div className="id-binding-main-section-text">
            <h3>{heading}</h3>
            {/* <ReactMarkDown source={description} /> */}
            {/* <div className="id-binding-main-section-subtext" dangerouslySetInnerHTML={{ __html: description }} /> */}

            <div className="id-binding-main-section-subtext">
                <p>
                    <span className="subtext-head">ご購入されるお店の会員ID（会員情報）をウィスパー会員に連携すると、</span>
                    連携した方だけの <span>【特別なキャンペーン】</span>や、新製品のサンプルなど<span>【特別なプレゼント】 </span>の情報をご案内します。<br />
                    他にも、あなたにぴったりな <span>【おトクな情報】</span>をお届けします。
                </p>
            </div>
            <style jsx> {Style} </style>
        </div>
    );
};

IdBindingMainpageTextboxSection3.propTypes = {
    heading: PropTypes.object,
};

export default IdBindingMainpageTextboxSection3;
