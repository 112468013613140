import { idBindRetailerFetching, idBindRetailerSuccessFetching, idBindRetailerErrorFetching } from '../reducers/IdbindRetailers';
import getRetailer from '../../adapters/api.adapter';
import { storageSet } from '../../helpers/StorageEvents';

export const IdbindRetailers = () => async (dispatch) => {
    dispatch(idBindRetailerFetching());
    await getRetailer.getRetailerList().then(async (response) => response).then(async (response) => {
        if (response && response.status === 200) {
            if (response.data.length > 0) {
                await storageSet('retailerId', response.data[0].retailerId);
            }
            dispatch(idBindRetailerSuccessFetching(response));
        }
    }).catch((error) => {
        if (error && error.response && error.response.status) {
            dispatch(idBindRetailerErrorFetching({ error }));
        }
    });
};
