import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Style from './SectionContainer.scss';
import ComponentsMapper from '../../helpers/components-mapper';

const PageComponents = ({ data, pageInfo }) => data.map((props, index) => <ComponentsMapper {...props} pageInfo={pageInfo} key={index} />);

function SectionContainer(props) {
    const { children, styles, background, sectionContentCollection, pageInfo,jumpLinkId,header } = props;
    let sectionStyle = '';

    if (styles) {
        sectionStyle = styles.trim();
    }
 
    return (
        <div
            className={classNames(sectionStyle)}
            style={background}
            id={jumpLinkId??''}
        >
            {children && children}
            {header&&<h1>{header}</h1>}
            {sectionContentCollection && sectionContentCollection.items && (
                <PageComponents data={sectionContentCollection.items} pageInfo={pageInfo} />
            )}
            <style jsx>{Style}</style>
        </div>
    );
}
SectionContainer.propTypes = {
    styles: PropTypes.string,
    children: PropTypes.array.isRequired,
    background: PropTypes.string,
    sectionContentCollection: PropTypes.object,
    pageInfo: PropTypes.object,
};
export default SectionContainer;
