import css from 'styled-jsx/css';

const styles = css`.search-icon {
    bottom: 13px;
    position: absolute;
    right: 108px;
}

.svg-icon {
    cursor: pointer;
    vertical-align: middle;
    position: relative;
    bottom: 8px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .svg-icon.h-22px {
        height: 22px;
        width: 28px;
    }
    .svg-icon.mx-10 {
        margin: 0 5px 0 10px;
        bottom: 0;
    }
}
.search-input {
    position: relative;
    float:left;
    input {
        border-radius: 20px;
        padding: 4px 15px;
        margin: 0 5px;
        font-family: "Noto Sans JP";
        border: 1px solid #ccc;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
        @media (max-width: 400px) {
            width: 220px;
        }
    }
    @media (max-width: 320px) {
        input {
            width: 200px;
            padding: 4px 5px;
            margin: 0;
        }
    }
}

.search-icon {
    position: absolute !important;
    right: 10px;
    top: 3px;
}
.search {
    height: 0;
    width: 100%;

    input {
        border: 0;
        box-sizing: border-box;
        font-size: 26px;
        height: 75px;
        margin-top: -15px;
        opacity: 0;
        padding: 0.5rem;
        position: relative;
        top: -60px;
        width: 100%;
        z-index: -1;
    }

    &.visible {
        height: 40px;

        transition: height 200ms;

        input {
            opacity: 1;
            top: 0;
            transition: opacity 500ms;
            z-index: 101;
        }
    }

    ::placeholder {
        color: #ccc;
    }
}

::placeholder {
    color: #c1a3d6;
}
`;

export default styles;