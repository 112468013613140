import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import useResolution from '../Hooks/useScreenSize';
import useOnClickOutside from '../Hooks/useClickedOutside';

import DropdownContent from './DropdownContent';

import Style from './RetailersDropdown.scss';

const Retailers = ({ dropdownTitle, retailersCollection = [], isForHeader }) => {
    const dropdownRef = useRef();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    useOnClickOutside(dropdownRef, () => setDropdownOpen(false));
    const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';

    const resolution = useResolution();
    if (!resolution) {
        return null;
    }

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const dropdownClass = classnames({
        'dropdown-content': true,
        'open': resolution.isMobile && isDropdownOpen,
        'retailersDropdown': resolution.isMobile && isDropdownOpen,
        'header': isForHeader,
    });

    if (isForHeader) {
        return resolution.isMobile
            ? (
                <div className="dropdown retailers" ref={dropdownRef} onClick={toggleDropdown}>
                    <span className="title">購入する</span>
                    <DropdownContent
                        className={dropdownClass}
                        retailersCollection={retailersCollection}
                    />
                    <style jsx>{Style}</style>
                </div>
            )
            : (
                <div className="retailers-dropdown" ref={dropdownRef}>
                    <div className="dropdown">
                        <span>{dropdownTitle}</span>
                        <DropdownContent
                            className={dropdownClass}
                            retailersCollection={retailersCollection}
                        />
                    </div>
                    <style jsx>{Style}</style>
                </div>
            );
    }
    return (
        isHomePage
            ? (
                <div className="retailers-dropdown homepage-retailers-dropdown" ref={dropdownRef}>
                    <div className="dropdown">
                        <span onClick={toggleDropdown}>{dropdownTitle}</span>
                        <DropdownContent
                            className={dropdownClass}
                            retailersCollection={retailersCollection}
                        />
                    </div>
                    <style jsx>{Style}</style>
                </div>
            )
            : (
                <div className="retailers-dropdown" ref={dropdownRef}>
                    <div className="dropdown">
                        <span onClick={toggleDropdown}>{dropdownTitle}</span>
                        <DropdownContent
                            className={dropdownClass}
                            retailersCollection={retailersCollection}
                        />
                    </div>
                    <style jsx>{Style}</style>
                </div>
            )
    );
};

Retailers.propTypes = {
    dropdownTitle: PropTypes.string,
    isForHeader: PropTypes.bool,
    // onToggle: PropTypes.func,
    retailersCollection: PropTypes.object,
};

export default Retailers;
