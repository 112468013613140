import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ textValue, shouldDisable = false, onChangeText }) => (

    <p>
        <label className="survey_option_checkbox survey_option_lastTextArea">
            <textarea
                placeholder="チェックボックスをクリックしてください"
                name="freecomment"
                className="survey_option_textarea"
                cols="40"
                rows="5"
                value={textValue}
                disabled={shouldDisable}
                onChange={({ target: { value } }) => onChangeText(value)}
            />
        </label>
    </p>

);
TextArea.propTypes = {
    textValue: PropTypes.any,
    onChangeText: PropTypes.any,
    shouldDisable: PropTypes.any,
};
export default TextArea;
