import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './PromoBannerInterview.scss';

import { getImageUrl } from '../../adapters/cloudinary.adapter';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const PromoBannerInterview = ({ imageDt, imageSp, button }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    const image = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(imageSp.asset.url, {}) : getImageUrl(imageDt.asset.url, {}));

    const dynamicStyle = {
        backgroundImage: `url(${image})`,
    };

    if (!resolution.isMobile) {
        dynamicStyle.cursor = 'pointer';
    }

    const url = button.url ? button.url.seoHead.url : button.externalUrl;
    const target = button.target === '_blank' ? '_blank' : '_self';

    const onBannerClick = () => {
        if (typeof window !== 'undefined' && !resolution.isMobile) {
            if (target === '_blank') {
                window.open(url, '_blank');
            } else {
                window.location = url;
            }
        }
    };

    return (
        <div className="promo-banner-with-cta" style={dynamicStyle} onClick={onBannerClick}>
            {resolution.isMobile && (
                <div>
                    <a className="cta event_button_click" data-action-detail={url} href={url} target={target}>{button.text}</a>
                </div>
            )}
            <style jsx>{Style}</style>
        </div>
    );
};

PromoBannerInterview.propTypes = {
    button: PropTypes.object,
    imageDt: PropTypes.object,
    imageSp: PropTypes.object,
};

export default PromoBannerInterview;
