import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import ReactMarkdown from 'react-markdown';
import Style from './ContentTypeRichText.scss';
// import { prepareRichTextContent } from './ContentTypeRichText.helper';
import Typography from '../Typography/Typography';

function ContentTypeRichText(props) {
    // console.log(props, 'pagespagespagespages');
    // const content = prepareRichTextContent(props?.advancedText?.json);
    let sectionStyle = '';

    if (props.styles) {
        sectionStyle = props.styles.trim();
    }
    return (
        <div className={classNames(sectionStyle)}>
            <Typography
                className="conditionText"
                component="h2"
                dangerouslySetInnerHTML={{
                    __html: props?.body,
                }}
            />
            <style jsx>{Style}</style>
        </div>
    );
}

ContentTypeRichText.propTypes = {
    // advancedText: PropTypes.object,
    body: PropTypes.string,
    styles: PropTypes.string,
};

export default ContentTypeRichText;
