import { flatten, sortBy, includes } from 'lodash';
import objectToArray from '../../helpers/object-to-array';

const buildProducts = (product) => {
    const { name, nameEn, page, absorbency, lenght, style, size, images, gtin, ratingsReviewsId } = product;
    return {
        name,
        nameEn,
        gtin,
        url: page.seoHead.url,
        absorbency: absorbency && absorbency.nameEn,
        length: lenght && lenght.nameEn,
        style: style && style.nameEn,
        size: size && size.nameEn,
        image: images.items[0].imageThumbnail,
        rnrId: ratingsReviewsId,
    };
};

const buildCategories = (categories) =>
    categories.items.map((category) => {
        const { name, nameEn, page, products } = category;

        return {
            nameEn,
            name,
            url: page.seoHead.url,
            products: products.items.map(buildProducts),
        };
    });

const extractRnrIds = (categories) => flatten(categories.map((category) => category.products.map((product) => product.rnrId)));

const getSectionTitles = (data) => ({
    allProducts: data.allProducts,
    categories: data.lookForAProduct,
    absorbency: data.lookForTheWa,
    length: data.searchByLength,
    size: data.pantsType,
});

const getFilteredProducts = (categories, filter, value) => {
    const filteredProducts = categories.map((category) => {
        const filtered = category.products.filter((product) => product[filter] === value);

        return {
            ...category,
            products: filtered,
        };
    });

    const categoriesWithProducts = filteredProducts.filter((t) => t.products.length > 0);

    return categoriesWithProducts;
};

const countFilters = (filters, filterToCount) => filters
    .map((item) => item[filterToCount])
    .reduce((acc, item) => {
        if (item === null) {
            return acc;
        }

        const count = acc[item] || 0;
        acc[item] = count + 1;

        return acc;
    }, {});

const sortFilters = (filters) => sortBy(filters, (filter) => parseInt(Object.keys(filter), 10));

const getFilters = (categories) => {
    const filters = flatten(categories.map((category) =>
        category.products.map((product) => ({
            absorbency: product.absorbency,
            length: product.length,
            size: product.size,
        }))));

    return {
        absorbency: sortFilters(objectToArray(countFilters(filters, 'absorbency'))),
        length: sortFilters(objectToArray(countFilters(filters, 'length'))),
        size: sortFilters(objectToArray(countFilters(filters, 'size'))),
    };
};

const isCurrentCategory = (categoryUrl) => {
    if (typeof window === 'undefined') {
        return false;
    }

    const currentPageUrl = window.location.pathname;

    return includes([currentPageUrl, `${currentPageUrl}/`, currentPageUrl.slice(0, -1)], categoryUrl);
};

const getSelectedCategory = (categories) => {
    if (!process.browser) {
        return [];
    }

    const currentPageUrl = window.location.pathname;

    const categoryUrls = categories.map((category) => category.url);

    if (!includes(categoryUrls, currentPageUrl) && !includes(categoryUrls, currentPageUrl.slice(0, -1))) {
        return categories;
    }

    return categories.filter((category) => {
        const { url } = category;

        return isCurrentCategory(url);
    });
};

const extractRatings = (ratings) => {
    const ratingsByProduct = {};

    ratings.forEach((rating) => {
        const currentProductId = rating.ProductStatistics.ProductId;

        ratingsByProduct[currentProductId] = {
            AverageOverallRating: rating.ProductStatistics.ReviewStatistics.AverageOverallRating,
            TotalReviewCount: rating.ProductStatistics.ReviewStatistics.TotalReviewCount,
        };
    });

    return ratingsByProduct;
};

const assignRatingsToCategories = (categories, ratings) => {
    const extractedRatings = extractRatings(ratings);

    return categories.map((category) => ({
        ...category,
        products: category.products.map((product) => {
            const productRatings = extractedRatings && extractedRatings[product.rnrId];
            const averageOveralRating = (productRatings && productRatings.AverageOverallRating) || 0;
            const totalReviewCount = (productRatings && productRatings.TotalReviewCount) || 0;
            const reviewsUrl = productRatings && productRatings.ProductPageUrl;

            return {
                ...product,
                averageOveralRating,
                totalReviewCount,
                reviewsUrl,
            };
        }),
    }));
};

export {
    buildCategories,
    getSectionTitles,
    getFilteredProducts,
    getFilters,
    isCurrentCategory,
    getSelectedCategory,
    assignRatingsToCategories,
    buildProducts,
    countFilters,
    extractRnrIds,
};
