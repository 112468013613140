import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.Coupon-Campaing-Contentsection{
    width:100%;
    position: relative;
    margin: 0 auto;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: normal;
    .Coupon-Campaing-Content{
        width:100%;
        margin: 0 auto 80px;
        .Coupon-Campaing-header{
            color:$purple-dark;
            font-size: 28px;
        }
        .Coupon-Campaing-text{
            font-size: 18px;
            line-height: 34px;
            margin: 15px 0;
            width: 100%;
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        .Coupon-Campaing-Content{
            width:100%;
            // padding:0 20px;
            .Coupon-Campaing-header{
                color:$purple-dark;
                font-size: 24px;
            }
            .Coupon-Campaing-text{
                font-size: 16px;
                line-height: 30px;
            }
        }

    }

@media only screen and (max-width: 767px) {
    .Coupon-Campaing-Content{
        width:100%;
        // padding:0 20px;
        margin: 0 auto ;

        .Coupon-Campaing-header{
            color:$purple-dark;
            font-size: 17px;
        }
        .Coupon-Campaing-text{
            font-size: 16px;     
            line-height: 30px;
        }
    }
}


}`;

export default styles;