import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';
import Typography from '../Typography/Typography';
import Style from './ProductCategoryLineup.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ProductCategoryLineupItem = ({ item }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }
    const img = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/6TFg7VNRcO2c8oGADT7bel/87ac6ded3c5cbbf256a1b0439e06c703/whisper_20fall_toppage_sp_3rd_0923_last-15.png' : 'https://images.ctfassets.net/8g0fievzqg8k/6nukldvsyjNGKz3LFVYBjX/5c4661d2896716b05dab15aa67e3c83d/whisper_20fall_toppage_pc_3rd_0917_last-20.png';

    return (
        <LazyLoad>
            <div className="products-wrapper">
                <div className="image-wrapper">
                    <a href={item.link && item.link.seoHead.url}>
                        <Image image={resolution.isMobile ? item.imageSp : item.imageDt} />
                        <img src={buildContentfullImageUrl(img)} alt="banner" height="auto" width="auto" />
                    </a>
                </div>
                <a href={item.link && item.link.seoHead.url}>
                    <div className="title gold-title">
                        {resolution.isMobile
                            ? (
                                <Typography
                                    className="imageSection-text"
                                    component="h2"
                                    dangerouslySetInnerHTML={{
                                        __html: item.nameSp,
                                    }}
                                />
                            )
                            : item.name}
                    </div>
                </a>
                <div className="description">{item.description}</div>
                <style jsx>{Style}</style>
            </div>
        </LazyLoad>
    );
};

ProductCategoryLineupItem.propTypes = {
    item: PropTypes.object,
};

export default ProductCategoryLineupItem;
