import algoliasearch from 'algoliasearch';

const algoliaAppId = process.env.ALGOLIA_APP_ID;
const algoliaIndex = process.env.ALGOLIA_INDEX;
const algoliaAppSearchKey = process.env.ALGOLIA_APP_SEARCH_KEY;

const article = 'article';
const product = 'product';
const remapSearchResults = (data) => ({
    articles: data.filter((item) => item.type === article),
    products: data.filter((item) => item.type === product),
});

const getSearchResults = async (searchTerm) => {
    if (!algoliaAppId || !algoliaAppSearchKey || !algoliaIndex) {
        throw new Error(
            'Cannot create algolia search client: ALGOLIA_APP_ID, ALGOLIA_APP_SEARCH_KEY or ALGOLIA_INDEX envs are empty',
        );
    }

    try {
        const client = algoliasearch(algoliaAppId, algoliaAppSearchKey);
        const index = client.initIndex(algoliaIndex);

        const searchResults = await index.search(searchTerm);

        return remapSearchResults(searchResults.hits);
    } catch (error) {
        console.error('Error fetching search results: ', error);
    }
};

export default getSearchResults;
