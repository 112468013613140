/* eslint-disable react/prop-types */
import React from 'react';
// import Link from 'next/link';

// const linkButton = () => (
//     <p className="navigation_button next">
//         <Link
//             className="submit_link"
//             as="/previewPage"
//             onClick={e => console.log(e)}
//             href={{
//                 pathname: '/previewPage',
//                 query: {
//                     // question14: JSON.stringify(answerId),
//                     // surveyPageData: JSON.stringify(surveyPageData),
//                     // editProfileData: profileData.editProfileData,
//                 },
//             }}
//         >
// 次へ
//         </Link>
//     </p>
// );

const Navigation = ({ screen, onNext, onBack, onPrevPage }) => (
    <>
        <button type="button" className="navigation_button next  event_survey_next_questions" onClick={onNext}>次へ</button>
        {screen !== 0 ? (
            <div
                className="navigation_button return event_survey_back_questions"
                onClick={onBack}
            >
                <button type="button">戻る</button>
            </div>
        ) : (
            <div
                className="navigation_button return event_survey_back_questions"
                onClick={onPrevPage}
            >
                <button type="button">戻る</button>
            </div>
        )}
    </>
);

export default Navigation;
