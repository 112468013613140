import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.dropdown {
    background: url("/static/images/whisper_20fall_toppage_pc_3rd_0917_last-21.png") no-repeat;
    background-position: center;
    background-size: 100%;
    color: white;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    height: 70px;
    width: 478px;
    @media (max-width: $breakpoint-max) {
        width: 100%;
        margin: 0 ;
        height: 60px;
    }


    span {
        border-radius: 8px;
        display: inline-block;
        font-family: "Noto Sans JP";
        font-weight: bold;
        padding: 20px 40px;
        font-size: 20px;
        @media (max-width: $breakpoint-max) {
            font-size: 16px;
            padding-top: 13px;
            padding: 17px 40px;
        }
        
    }

    &:hover {
        .dropdown-content {
            @media (min-width: $breakpoint-min) {
                display: block;
                width: 100%;
            }
        }
    }

    .dropdown-content {
        background-color: #efefef;
        border-radius: 8px;
        display: none;
        min-width: 160px;
        padding: 1rem;
        position: absolute;
        z-index: 1;
        &.open {
            display: block;
            width: 100%;

            &.header {
                margin-left: -100px;
                width: 235px;
            }
        }

        img {
            width: 100%;
        }
    }
}
.retailers {
    width: 25%;
    height: 90px;
    .title {
        margin-top: 10px;
        padding: 0;
        width: 100%;
        font-size: 13px;
        text-align: center;
        position: absolute;
        bottom: 3px;
    }
}
.retailers-dropdown {
    align-self: center;
    color: white;
    font-size: 15px;
    font-weight: bold;
    margin: 0 auto 20px;
    position: relative;
    text-align: center;

    @media (max-width: $breakpoint-max) {
        margin-bottom: 25px;
        margin-top: 0px;
    }
}
.flotingMenu-retailer {
    background: #efefef;
    padding: 10px 50px;
}
.homepage-retailers-dropdown {
    .dropdown {
        background: url("/static/images/bin.png") no-repeat;
    }
}

.homepage-retailers-dropdown {
    .dropdown {
        background: url("/static/images/bin.png") no-repeat;
    }
}

.homepage-retailers-dropdown {
    .dropdown {
        background: url("/static/images/whisper_20fall_toppage_pc_3rd_0917_last-21.png") no-repeat;
        background-position: center;
        background-size: 100%;
        color: white;
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 80px;
        position: relative;
        @media (max-width: $breakpoint-max) {
            margin: 0;
        }
    }
}
`;

export default styles;