import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';
import Image from '../CloudinaryImage/CloudinaryImage';
import Style from './CategoryList.scss';

const CategoryList = ({ content, resolution }) => {
    const { url } = content.page.seoHead;
    const image = resolution?.isMobile ? content.categoryImageSet.imageSmartphone : content.categoryImageSet.imageDesktop;
    return (
        <div className="allprodcut-container">
            <div className="categoryListWrapper">
                <div className="premiumCategory">
                    <a className="cta event_button_click" href={url}>
                        <LazyLoad>
                            <Image image={image} className={content.categoryImageSet.style} loading="lazy" />
                        </LazyLoad>
                    </a>
                    <a className="cta event_button_click" href={url}>
                        <div className="category-name">{content.name}</div>
                    </a>
                    <div className="variants">
                        {
                            content.products.items.map((item) => {
                                const title = item.title?.split(' ')[0];
                                return (
                                    <a className="cta event_button_click" href={url}>
                                        <span className="variant">{title}</span>
                                    </a>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

CategoryList.propTypes = {
    content: PropTypes.object,
    resolution: PropTypes.object,
};

export default CategoryList;
