import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.content-wrapper {
    width: 100%;
    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding: 0;
        margin: 0px auto;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding: 0;
        margin: 0px auto;
    }
    .landing {
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-weight: 500;
        width: $desktop-elements-width;
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            width: 100%;
            margin-top: -14px;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            width: 100%;
            padding: 0 15px;
        }
        @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
            padding: 0 15px;
            width: 100%;
        }

        .mainbanner {
            //  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
            //     display: none;
            // }
            .viewbanner {
                .viewbannerdp {
                    display: block;
                    width: 100%;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        display: none;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        width: 100%;
                    }
                }
                .viewbannersp {
                    display: none;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        .goldenHeading {
            text-align: center;
            margin: 30px;
            .goldenHeadingdp {
                display: inline;
                text-align: center;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    display: none;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    text-align: center;
                }
            }
            .goldenHeadingsp {
                display: none;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    display: inline;
                    width: 100%;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    text-align: center;
                    width: 100%;
                }
            }
        }

        .cardview {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                flex-direction: column;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
            }
            .cardimg {
                //  width:300px;

                .cardimgdp {
                    width: 296px;
                    margin: 0px auto;
                    display: flex;

                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        display: none;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        display: inline;
                        width: 98%;
                        margin: 0px auto;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .cardimgsp {
                    display: none;
                    text-align: center;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        display: inline;
                        width: 100%;
                    }
                }
            }
        }
        .heading {
            text-align: center;
            font-size: 24px;
            margin-top: 50px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 16px;
                margin-top: 30px;
            }
        }
        .paragraph {
            font-size: 18px;
            margin-bottom: 50px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-top: 30px;
            }
        }
        .headingone {
            text-align: center;
            font-size: 24px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 16px;
                margin-top: 30px;
            }
        }
        .paragraphone {
            font-size: 18px;
            color: #ff0000;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-top: 30px;
            }
        }
        .headingtwo {
            text-align: center;
            font-size: 24px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 16px;
                margin-top: 30px;
            }
        }
        .paragraphthree {
            font-size: 18px;
            a {
                color: #000;
                text-decoration: underline;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-top: 30px;
            }
        }

        .richtextparagraph {
            font-size: 18px;
            margin-bottom: 50px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-top: 30px;
                padding: 0 15px;
            }
        }
        .paragraphthree {
            margin-bottom: 50px;
            font-size: 18px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-top: 30px;
                padding: 0 15px;
            }
        }
        .headingthree {
            text-align: center;
            font-size: 24px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 16px;
                padding: 0 15px;
            }
        }
        .paragraphfour {
            margin-bottom: 50px;
            font-size: 18px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-bottom: 30px;
                padding: 0 15px;
            }
        }
        .headingfive {
            text-align: center;
            font-size: 24px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 16px;
            }
        }
        .richText {
            a {
                color: #000;
                text-decoration: underline;
            }
        }
        .paragraphsix {
            margin-bottom: 70px;
            font-size: 18px;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                font-size: 14px;
                margin-bottom: 40px;
                padding: 0 15px;
            }
        }
        .sectionbanner {
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            margin-top: 5%;
            .productlist_banner {
                position: relative;
                padding-right: 20px;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                    padding: 0 15px;
                    margin: 10px 0;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    justify-content: space-between;
                    padding-bottom: 25px;
                    padding-right: 0px;
                }
                .productlist_img {
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        width: 100%;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        width: 100%;
                    }
                }
            }
            .text_container {
                position: absolute;
                color: #592c82;
                display: flex;
                flex-direction: column;
                padding: 10px;
                float: left;
                bottom: 0;
                padding: 20px;
                margin-top: 30px;
                text-align: center;
                height: 100%;
                width: 190px;
                justify-content: space-around;
                p {
                    font-size: 16px;
                    color: #592c82;
                    font-weight: 500;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .banner-text-small {
                    font-size: 16px;
                    color: #592c82;
                    font-weight: 500;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .bigtext {
                    font-size: 28px;
                    font-weight: 500;
                    margin-bottom: 13px;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .banner-text-mid {
                    font-size: 23px;
                    font-weight: bold;
                    margin: -20px 0 5px;
                    text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                }
                .promobtn {
                    position: relative;
                    margin: 0px auto;
                    cursor: pointer;
                    font-family: inherit;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 2px 0;
                    width: 140px;
                    a {
                        &::after {
                            border-bottom: 7px solid transparent;
                            border-left: 12px solid white;
                            border-right: 7px solid transparent;
                            border-top: 7px solid transparent;
                            content: "";
                            font-size: 0;
                            position: absolute;
                            right: 4px;
                            top: 50%;
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                        }
                    }
                    @media only screen and (max-width: 767px) {
                        margin: 2px 5px;
                    }
                }
                a {
                    text-decoration: none;
                    color: white;
                }
                @media (max-width: $breakpoint-max) {
                    position: absolute;
                    color: #592c82;
                    display: flex;
                    flex-direction: column;
                    padding: 10px 20px;
                    float: left;
                    top: 0px;
                    // bottom: 20%;
                    // padding: 20px;
                    // margin-top: 30px;
                    text-align: left;
                    justify-content: space-around;
                    margin: 0;
                    p {
                        font-size: 16px;
                        color: #592c82;
                        font-weight: 500;
                        text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                    }
                    .bigtext {
                        font-size: 18px;
                        font-weight: 500;
                        margin-bottom: 13px;
                        text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
                    }
                }
            }
            @media (max-width: $breakpoint-max) {
                flex-direction: column;
                margin-bottom: 100px;
            }
            .ctaone {
                background-color: #592c82;
                border: 0;
                box-sizing: border-box;
                color: white;
                display: block;
                position: absolute;
                word-break: keep-all;
                right: 2%;
                width: 100px;
                @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    background-color: #592c82;
                    border: 0;
                    box-sizing: border-box;
                    color: white;
                    display: block;
                    position: absolute;
                    word-break: keep-all;
                    right: 0.5%;
                    font-size: 20px;
                    width: 90px;
                    font-size: 14px;
                    text-align: center;
                    top: 20px;
                }
            }
        }
        .productbanner {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            width:100%;
            @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                flex-direction: column;
            }
            .productsubbanner {
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    width: 32%;
                }
                .productbannerone {
                    background-color: #fdf4f8;
                    display: flex;
                    .productview {
                        width: 170px;
                        float: left;
                        margin-right: 20px;
                        float: left;
                        margin: 0 15px 20px 0;
                        text-align: center;
                        display: block;
                        .img {
                            width: 100%;
                        }
                        .product-text{
                            // min-height: 135px;
                            .productName {
                                font-size: 18px;
                                float: left;
                                width: 100%;
                                margin: 10px auto;
                                text-align: center;
                                color: $purple-dark;
                            }
                            .productNumber {
                                font-size: 20px;
                                float: left;
                                width: 100%;
                                margin: 0 auto;
                                text-align: center;
                                color: #592c82;
                                font-weight: bold;
                            }
                        }
                       
                        .productviewsub {
                            width: 100%;
                        }
                    }
                }
                .productbannertwo {
                    display: block;
                    width: 940px;
                    float: left;
                    margin: 0 auto;
                    text-align: center;
                    margin-left: 10%;
                    position: relative;
                    .productview {
                        cursor: pointer;
                        width: 170px;
                        float: left;
                        margin-right: 20px;
                        float: left;
                        margin: 0 15px 20px 0;
                        text-align: center;
                        display: block;
                        .img {
                            width: 100%;
                        }
                        .productName {
                            font-size: 18px;
                            float: left;
                            width: 100%;
                            margin: 10px auto;
                            text-align: center;
                            color: $purple-dark;
                        }
                        .productNumber {
                            font-size: 20px;
                            float: left;
                            width: 100%;
                            margin: 0 auto;
                            text-align: center;
                            color: #592c82;
                            font-weight: bold;
                        }
                        .productviewsub {
                            width: 100%;
                        }
                        .cta {
                            background-color: #592c82;
                            border: 0;
                            box-sizing: border-box;
                            color: white;
                            display: block;
                            position: relative;
                            word-break: keep-all;
                            border-radius: 25px;
                            width: 150px;
                            padding: 4px 12px;
                            margin: 10px auto;
                            text-align: center;
                            float: left;
                        }
                    }
                    @media (max-width: $breakpoint-max) {
                        width: 100%;
                        margin-left: 0;
                        padding: 0 20px;
                        .productview {
                            width: 50%;
                            margin: 0 0px 20px 0;
                            .img {
                                width: 80%;
                                margin: 0 auto;
                            }
                        }
                    }
                }
                .productbannerthree {
                    display: block;
                    width: 940px;
                    float: left;
                    margin: 0 auto;
                    text-align: center;
                    margin-left: 10%;
                    position: relative;
                    .productview {
                        cursor: pointer;
                        width: 170px;
                        float: left;
                        margin-right: 20px;
                        float: left;
                        margin: 0 15px 20px 0;
                        text-align: center;
                        display: block;
                        .img {
                            width: 80%;
                            margin: 0 auto;
                            text-align: center;
                        }
                        .productName {
                            font-size: 18px;
                            float: left;
                            width: 100%;
                            margin: 10px auto;
                            text-align: center;
                            color: $purple-dark;
                        }
                        .productNumber {
                            font-size: 20px;
                            float: left;
                            width: 100%;
                            margin: 0 auto;
                            text-align: center;
                            color: #592c82;
                            font-weight: bold;
                        }
                        .productviewsub {
                            width: 80%;
                            margin: 0 auto;
                            text-align: center;
                        }
                    }
                    @media (max-width: $breakpoint-max) {
                        width: 100%;
                        margin-left: 0;
                        .productview {
                            width: 50%;
                            margin: 0 0 20px 0;
                        }
                    }
                }
                .sectionbannerone {
                    .sectionbanneronedp {
                        display: inline;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: none;
                        }
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                            width: auto;
                        }
                    }
                    .sectionbanneronesp {
                        display: none;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: inline;
                            width: 100%;
                        }
                    }
                }
                .sectionbannertwo {
                    .sectionbannertwodp {
                        display: inline;
                        background-color: #fdf4f8;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: none;
                        }
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                            width: auto;
                        }
                    }
                    .sectionbannertwosp {
                        display: none;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: inline;
                            width: 100%;
                        }
                    }
                }
                .sectionbannerthree {
                    .sectionbannerthreedp {
                        display: inline;
                        background-color: #fdf4f8;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: none;
                        }
                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                            width: auto;
                        }
                    }
                    .sectionbannerthreesp {
                        display: none;
                        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                            display: inline;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .product-disclaimer-section{
            width: 100%;
            float: left;
            position: relative;
            margin: 0 auto 40px;
            background: #fdf4f8;
            padding: 20px 0 40px 0;
            .Product-disclaimer{
                width: 960px;
                margin: 0 auto;
                text-align: center;
                p{
                    font-size: 16px;
                    color:#000;
                }
            }
            @media only screen and (max-width: $breakpoint-max){
                .Product-disclaimer{
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                    p{
                        font-size: 12px;
                        line-height: 18px;
                        span{
                            width:100%;
                            display:block;
                            float:left;
                            margin:0 auto;
                        }                        
                    }
                }
            }
        }
        .Product-Banner-Title-Section{
            width: 100%;
            float: left;
            position: relative;
            margin: 0 auto;
            background: #fdf4f8;  
            @media only screen and (max-width: $breakpoint-max){
               .bannerImageSet{
                   width:100%;
                   float:left;
                .bannerImageSetsp{
                    width:100%;
                    padding:20px 0;
                }
            }
            } 

        }
        .sectionbannertitle {
            background: #fdf4f8;
        }
        .sectionbannerone {
            background: #fdf4f8;
        }
        .productbanner-items {
            background: #fdf4f8;
            .productsubbanner {
                .productbannerone {
                    .productview {
                        width: 170px;
                        float: left;
                        margin-right: 20px;
                        float: left;
                        margin: 0 15px 20px 0;
                        text-align: center;
                        display: block;
                        .productview-img {
                            min-height: 170px;
                        }
                        .cta {
                            background-color: #592c82;
                            border: 0;
                            box-sizing: border-box;
                            color: white;
                            display: block;
                            position: relative;
                            word-break: keep-all;
                            border-radius: 25px;
                            width: 150px;
                            padding: 4px 12px;
                            margin: 10px auto;
                            text-align: center;
                            float: left;
                        }
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10) {
                            .product-text{
                                min-height:135px;
                            }
                        }
                    }
                    @media (max-width: $breakpoint-max) {
                        .productview {
                            width: 50%;
                            float: left;
                            margin-right: 20px;
                            float: left;
                            margin: 0 0px 20px 0;
                            text-align: center;
                            display: block;
                            &:nth-child(6) {
                                margin-left: 0;
                                .product-text{
                                    min-height: auto;
                                }
                            }

                            // &:nth-child(6),
                            &:nth-child(7),
                            &:nth-child(8),
                            &:nth-child(9),
                            &:nth-child(10) {
                                .product-text{
                                    min-height:152px;
                                }
                            }

                        }
                    }
                }
                .productbannerthree {
                    padding: 0 0 100px;
                    display: block;
                    width: 940px;
                    float: left;
                    margin: 0 auto;
                    text-align: center;
                    margin-left: 10%;
                    position: relative;
                    .productview {
                        cursor: pointer;
                        width: 170px;
                        float: left;
                        margin-right: 20px;
                        float: left;
                        margin: 0 15px 20px 0;
                        text-align: center;
                        display: inline-grid;
                        .cta {
                            background-color: #592c82;
                            border: 0;
                            box-sizing: border-box;
                            color: white;
                            display: block;
                            position: relative;
                            word-break: keep-all;
                            border-radius: 25px;
                            width: 150px;
                            padding: 4px 12px;
                            margin: 10px auto;
                            text-align: center;
                            float: left;
                        }
                    }
                    @media (max-width: $breakpoint-max) {
                        width: 100%;
                        margin-left: 0;
                        padding: 0 20px 50px 20px;
                        .productview {
                            width: 50%;
                            margin: 0 0 20px 0;
                        }
                    }
                }
            }
        }

        .smartArrow {
            .smartlabel {
                .smartlabelsp {
                    display: none;
                    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
        .landing-view-btn {
            //    text:none;
            // display: inline-block;
            // padding: 10px 12px 10px 42px;
            // color: #5f3c85;
            // margin: 0 auto;
            // font-size: 24px;
            // font-weight: bold;
            // position: relative;
            border: none;
            background-color: transparent;
            cursor: pointer;
            background-image: url(/static/images/pc_regi_btm.png);
            background-repeat: no-repeat;
            width: 250px;
            height: 50px;
            background-size: 100%;
            a {
                color: #fff;
                text-decoration: none;
            }
            // ::after {

            //     content: ">"; // non-breaking-space in content (Alt+0160)
            //     font-size: 35px;
            //     font-weight: bold;
            //     color: white;
            //     background-color: #592C82;
            //     height: 35px;
            //     width: 35px;
            //     padding: 5px;
            //      border-radius: 50%;
            //     display: inline-block;
            // }
        }
        .Sampling-appology-text{
            color:$purple-dark;
            font-size: 16px;
            line-height: 25px;
            padding: 0 20px;
            font-family: "Noto Sans JP";           
        }
        .footerfix {
            display: block;
            width: 100%;
            position: fixed;
            left: 0px;
            bottom: 2px;
            z-index: 9999;
            text-align: center;
            padding: 0 auto;
            background-color: #ffff33;
            opacity: 0.9;
            padding: 10px;
            @media (max-width: $breakpoint-max) {
                position: sticky;
                bottom: 90px;
                z-index: 99;
            }
        }
    }
}

/*  October sampling Campaign CSS fix */
.sampling-campaign {
    .oct-sampling {
        float: left;
        position: relative;
        margin: 0 auto;
        width: 100%;
        background-image: url(/static/images/pc_foot_img.png);
        background-repeat: no-repeat;
        background-position: bottom center;
        padding: 0 0 150px 0;
        background-size: 100%;
        text-align: center;
        font-family: "M PLUS Rounded 1c", sans-serif;
        .samplingbanner {
            width: 100%;
            margin: 0 auto;
            // background-image: url(/static/images/pc_kv_bannerimg.png);
            background-image: url("https://images.ctfassets.net/8g0fievzqg8k/5Bu2z9D2m4G1BdPPfZyiOy/83328dc4191463a45592ac9678adfb5d/pc_kv_backimg-logo-on.png");
            text-align: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            float: left;
            position: relative;
            height: 1250px;

            .samplingBannerText {
                background-image: url(/static/images/pc_title_bgimg.png);
                background-repeat: no-repeat;
                width: 940px;
                display: inline-block;
                margin: 13% auto 0;
                background-size: 85%;
                background-position: center;
                height: 126px;
                p {
                    font-size: 30px;
                    line-height: 40px;
                    color: #8347ad;
                    padding: 30px 0 0 0;
                    text-align: center;
                    margin: 0 auto;
                    width: 100%;
                    float: left;
                }
            }
            .inlineText {
                width: 940px;
                margin: 12px auto 25px;
                display: inline-block;
                p {
                    font-size: 42px;
                    color: #fff;
                    margin: 0 auto;
                    font-weight: normal;
                }
            }
            .bannerButton {
                width: 940px;
                margin: 0 auto;
                text-align: center;
                p {
                    background: #fff;
                    color: #8347ad;
                    width: 350px;
                    margin: 0 auto 20px;
                    padding: 12px 18px;
                    border-radius: 30px;
                    font-size: 28px;
                }
            }
            .newLineText {
                display: none;
            }
            .bannerImageSetdp {
                width: 58%;
                text-align: center;
                margin: 0 auto;
            }
            .bannerImageSetsp {
                display: none;
            }
            .disclaimerText {
                width: 100%;
                float: left;
                position: relative;
                color: #fff;
                font-size: 18px;
                margin: 18px auto 20px;
                font-weight: normal;
                br {
                    display: none;
                }
            }
            .bannerDisclaimer1 {
                display: inline-block;
                width: 940px;
                float: none;
                position: relative;
                margin: 5px auto 20px;
                background: rgba(0, 0, 0, 0.2);
                padding: 25px 30px;
                z-index: 1;
                color: #fff;
                font-size: 18px;
                font-weight: normal;
                text-align: left;
            }
        }
        .mainbanner {
            width: 940px;
            margin: 0 auto;
        }
       
        .productbanner {
            div {
                width: 100%;
                .productbanner {
                    width: 940px;
                    margin: 0 auto;
                    button {
                        background: none;
                        border: none;
                    }
                }
                .sectionbannertitle {
                    background: #fdf4f8;
                    margin: 0 auto;
                    text-align: center;
                }
                .abc-products-section {
                    width: 100%;
                    background: #fdf4f8;
                    float: left;
                    position: relative;
                    text-align: center;

                    .productbanner-items {
                        width: 940px;
                        margin: 0 auto;
                        display: inline-block;
                        .productbannerone {
                            width: 940px;
                            margin: 0 auto;
                            display: block;
                            float: left;
                        }
                    }
                }
            }
        }
        .richtextparagraph {
            width: 940px;
            margin: 0 auto 50px;
            text-align: left;
        }
    }

    @media (max-width: $breakpoint-max) {
        .oct-sampling {
            background-image: url(/static/images/sp_foot_img.png);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 100%;
            padding: 0 0 100px 0;
            margin-bottom: 100px;
            margin-top: 50px;
            .samplingbanner {
                width: 100%;
                margin: 0 auto;
                // background-image: url('https://images.ctfassets.net/8g0fievzqg8k/249zAy5W3P9XY2mbhMsMex/9c3c774282a968fbc2ec8a926254a6ca/sp_kv_backimg-logo-on.png');
                background-image: url(/static/images/sp_kv_bgimg.png);
                text-align: center;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                float: left;
                position: relative;
                height: auto;

                .samplingBannerText {
                    background-image: url('https://images.ctfassets.net/8g0fievzqg8k/2rsWmVsmDh2gznTcmz5Dvy/eaf2784ff06936316953f9aa9a13d514/sp_title_bgimg.png');
                    width: 100%;
                    display: block;
                    margin: 90px auto 0;
                    float: left;
                    p {
                        font-size: 12px;
                        line-height: 18px;
                        padding: 50px 0 0 0;
                        width: 100%;
                        float: left;
                    }
                }
                .inlineText {
                    width: 100%;
                    margin: 12px auto 15px;
                    display: block;
                    float: left;
                    position: relative;
                    p {
                        font-size: 22px;
                        color: #fff;
                        margin: 0 auto;
                        .textInline {
                            display: none;
                        }
                    }
                }
                .bannerButton {
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                    float: left;
                    p {
                        width: 60%;
                        padding: 8px 18px;
                        border-radius: 20px;
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .newLineText {
                    display: block;
                    color: #fff;
                    font-size: 30px;
                    width: 100%;
                    float: left;
                }
                .bannerImageSetdp {
                    display: none;
                }
                .bannerImageSetsp {
                    display: block;
                    width: 80%;
                    padding: 0;
                    margin: 0 auto;
                }
                .disclaimerText {
                    width: 100%;
                    float: left;
                    position: relative;
                    color: #fff;
                    font-size: 12px;
                    font-weight: normal;
                    br {
                        display: block;
                    }
                }
                .bannerDisclaimer1 {
                    display: block;
                    width: 100%;
                    float: left;
                    margin: 5px auto 20px;
                    padding: 10px 15px;
                    font-size: 12px;
                }
                &::before {
                    content: "";
                    background-image: url('https://images.ctfassets.net/8g0fievzqg8k/5WBb2TjMdwD7oAq5cWYVEQ/8e11c6b601db1f32dbbf0bfdcb085b08/whisper_SP_Headers_and_footers-10.png');
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 90px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin: 0 auto;
                    float: left;
                    left: 0;
                    right: 0;
                }
            }
            .mainbanner {
                width: 100%;
                margin: 0 auto;
            }
            
            .productbanner {
                div {
                    width: 100%;
                    .productbanner {
                        width: 100%;
                        margin: 0 auto;
                        button {
                            width: 100%;
                            margin: 0 auto;
                            background: none;
                            border: none;
                        }
                    }
                    .sectionbannertitle {
                        background: #fdf4f8;
                        margin: 0 auto;
                        text-align: center;
                        img {
                            width: 100%;
                        }
                    }
                    .abc-products-section {
                        .productbanner-items {
                            width: 100%;
                            margin: 0 auto;
                            .productbannerone {
                                width: 100%;
                                margin: 0 auto;
                                padding: 0 20px;
                            }
                        }
                    }
                }
            }
            .richtextparagraph {
                width: 100%;
                margin: 0 auto 50px;
            }
        }
    }
    @media only screen and (min-width: 1290px) and (max-width: 1404px) {
        .samplingbanner {
            height: 1390px !important;
        }
    }
    @media only screen and (min-width: 1600px) {
        .samplingbanner {
            height: 1440px !important;
        }
    }
}
`;

export default styles;