export const productList = [
    {
        altText: 'ウィスパーうすさら安心270cc',
        url: '/brands/whisper/ususara-anshin_270cc_review/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/3m0ognnJfZePAUMfg7QNZS/b49b882e748fc2dae7b2ac8125aa88b3/ususara-anshin-270cc.png',
    },
    {
        altText: 'ウィスパーうすさら安心300cc',
        url: '/brands/whisper/ususara-anshin_300cc_review/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/q2ExQFkN1KbgkMQGRzfuF/644f3ceb3af15e0d3a04a75482dbc6f8/ususara-anshin-300cc.png?',
    },
    {
        altText: 'ウィスパーうすさら安心80cc',
        url: '/brands/whisper/ususara-anshin-80cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/3dEejUaNH9Gw4RBPhbUxjd/40409290f750eba303b318f9050c74ca/ususara-anshin-80cc.png?',
    },
    {
        altText: 'ウィスパーうすさら安心170cc',
        url: '/brands/whisper/ususara-anshin-170cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/2K4qt99oz1DXLXxRy6qhqB/986f5e5b9f5ac0385e8aa53b11e3b4db/ususara-anshin-170cc.png',
    },
    {
        altText: 'ウィスパーうすさら安心20cc',
        url: '/brands/whisper/ususara-anshin-20cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/72PjUkCGaMv53CzVIYMIVk/ec88925b47689c4562f58a0872f153f0/ususara-anshin-20cc.png',
    },
    {
        altText: 'ウィスパーうすさら安心45cc',
        url: '/brands/whisper/ususara-anshin-45cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/35KHUMX3jbTQ6f0ATaG3hs/9f4d1b7493dd8e53a348ee4c386f3a8f/ususara-anshin-45cc.png',
    },
    {
        altText: 'ウィスパーうすさらエアリー15cc',
        url: '/brands/whisper/ususara-airly-15cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/19x82v1Z1yb8kK7pE9G33j/e4f0cb540a3319405c7fae8ba42e9c47/ususara-airly-15cc.png',
    },
    {
        altText: 'ウィスパーうすさらエアリー85cc',
        url: '/brands/whisper/ususara-airly-85cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/LejaiaYDZ27YFheX6xA9s/4084a2087b07998074a0475a3bb7e29b/ususara-airly-85cc.png',
    },
    {
        altText: 'ウィスパーうすさらエアリー＋80cc',
        url: '/brands/whisper/ususara-airly-plus-80cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/7zTchTKywkO8DtYHbQwjmh/630a6be83e23378961a775719fb4abf0/ususara-airly-plus-80cc.png',
    },
    {
        altText: 'ウィスパーうすさらエアリー＋120cc',
        url: '/brands/whisper/ususara-airly-plus-120cc/',
        img: 'https://images.ctfassets.net/8g0fievzqg8k/7fnkWVTGAy7SRe09JeHMmL/2bef868f0ceeed058e9b3436a80066dc/ususara-airly-plus-120cc.png',
    },
];
