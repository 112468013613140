import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';

import {
    Dialog,
    DialogTitle,
} from '../../../src/components/Dialog';

function OpenCampaignRejectionPopUp(props) {
    const { isAlreadyUser } = props;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        window.sessionStorage.removeItem('userFromLotteryFlag');
        window.sessionStorage.removeItem('lotteryflowStarts');
        Router.push('/');
    };

    const handleRedirect = () => {
        Router.push('/');
    };

    useEffect(() => {
        if (isAlreadyUser === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isAlreadyUser]);

    return (
        <div className="lottery-rejection">
            <Dialog open={open} onClose={handleClose}>

                <DialogTitle className="Dialog-overlay" onClick={handleClose} component="div">
                    <div className="Dialog-content">
                        <div className="Dialog-error-content">
                            <h2 className="Dialog-error-title">
                                すでにウィスパー会員に登録済みの方
                            </h2>
                            <p className="Dialog-error-txt">※「ウィスパー現品プレゼントキャンペーン」にご応募された方はご応募いただけません。<br /><br />
                                「ウィスパー現品プレゼントキャンペ－ン」に以前ご応募いただいた方は、ご参加いただけません。
                            </p>
                        </div>

                        <button
                            type="button"
                            className="gobutton"
                            onClick={handleRedirect}
                        >戻る
                        </button>

                        <button
                            type="button"
                            className="closeBtn"
                            onClick={handleClose}
                        />
                    </div>
                </DialogTitle>
            </Dialog>
        </div>
    );
}

OpenCampaignRejectionPopUp.propTypes = {
    isAlreadyUser: PropTypes.any,
};
export default OpenCampaignRejectionPopUp;
