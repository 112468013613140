import React from 'react';
import PropTypes from 'prop-types';

import HeaderNavigation from '../HeaderNavigation';

import Style from './Header.scss';

const Header = ({ navigation, retailers, pageInfo, loginColumn }) => (
    <div>
        <HeaderNavigation navigationItems={navigation} retailers={retailers} pageInfo={pageInfo} loginColumn={loginColumn} />

        <style jsx>
            {Style}
        </style>
    </div>
);

Header.propTypes = {
    navigation: PropTypes.array,
    pageInfo: PropTypes.object,
    retailers: PropTypes.object,
    loginColumn: PropTypes.any,
};

export default Header;
