import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";

.article-container{
  position: relative;
}

.article {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  width: $desktop-elements-width;
  margin-top:70px;
  position: relative;

  @media (max-width: $breakpoint-max) {
    margin-bottom: 100px;
    margin-top: 10px;
    width: 100%;
  }
  

  @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
    max-width: 100%;
  }

  .article-header {
    margin-top: 50px;

    @media (min-width: 1024px) {
      display: grid;
      float: left;

  }

    h1 {
      color: $purple;
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
      word-break: keep-all;
      font-family: "Noto Sans JP";
      font-weight: bold;
      margin:20px 0 25px 0;
      @media (max-width: $breakpoint-max) {
        font-size: 23px;
        line-height: 28px;
        margin-bottom: 18px;
        margin-top:15px;
        padding-left: 15px;
        padding-right: 15px;
        word-break: break-word;
      }

      @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @media (max-width: $breakpoint-antediluvian-sp-max) {
        font-size: 20px;
      }
    }

    h2 {
      color: $text-article;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      margin: 0;
      font-family: "Noto Sans JP";
      font-weight: normal;

      @media (max-width: $breakpoint-max) {
        font-size: 18px;
        line-height: 26px;
        padding-left: 15px;
        padding-right: 15px;
      }

      @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media (max-width: $breakpoint-antediluvian-sp-max) {
        font-size: 19px;
      }
    }

    .header-image {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      justify-self: center;
      margin-bottom: 20px;
      margin-top: 50px;
      max-width: 940px;
      @media (max-width: $breakpoint-min) {
        min-height: 225px;
      }

      span {
        align-self: flex-end;
        font-size: 18px;

        @media (max-width: $breakpoint-max) {
          font-size: 10px;
          margin-right: 20px;
        }
      }

      @media (max-width: $breakpoint-max) {
        flex-direction: column-reverse;
        margin-bottom: 0;
        margin-top: 12px;
        width: 100%;
      }

      @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        max-width: 100%;
      }
    }
  }
  .article-textbox{
    p{
    font-family: "Noto Sans JP";
    font-weight: 500;
    color:#000;
    font-size: 16px;
    line-height: 24px;
    padding: 0 0 20px 0;
    float:left;
    width: 100%;
    text-align: right;
    @media (max-width: $breakpoint-max){
      font-size: 12px;
      line-height: 26px;
      padding: 0 20px 15px 20px;
    }
  }

  }

  .article-text {
    color: $text-article;
    font-size: 16px;
    line-height: 24px;
    font-family: "Noto Sans JP";
    font-weight: normal;
    @media (max-width: $breakpoint-max) {
      overflow-x:hidden;
    }

    .img-wrapper{
      img{

        @media (max-width: $breakpoint-max) {
            width:95%;
        }
      }
      .embeddedImg{
        max-width:300px;
        margin:0 auto;
        @media (max-width: $breakpoint-max) {
          max-width:315px;
        }

      }
    }

    @media (max-width: $breakpoint-max) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
      padding-left: 15px;
      padding-right: 15px;
    }

    p {
      line-height: 24px;
      margin: 0;
      font-size:16px;
      font-family: "Noto Sans JP";
    font-weight: normal;

      &:empty {
        margin-bottom: 0;
        margin-top: 0;
        padding: 21px;
      }

      @media (max-width: $breakpoint-max) {
        &:empty {
          padding: 5px;
        }
      }

      :empty + p {
        margin: 0 0 47px;

        @media (max-width: $breakpoint-max) {
          margin: 20px 0;
        }

        &:nth-last-child(2) {
          margin: 0;
        }
      }
      @media (max-width: $breakpoint-max){
        font-size: 16px;
        line-height: 24px;

      }
    }

    p + p {
      margin: 47px 0 0;
      @media (max-width: $breakpoint-max) {
        margin: 20px 0;
      }
    }
    h1{
      color: $purple;
      font-size: 30px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 0;
      word-break: keep-all;
    }

    h2{
      font-size: 28px;
      line-height:38px;
      color:$purple;
      font-family: "Noto Sans JP";
      font-weight: 500;
      @media (max-width: $breakpoint-max){
        font-size: 20px;
        line-height:27px;
      }
    }
    h3{
      font-size: 26px;
      line-height: 36px;      
      color:#fff;
      background:#8347AD ;
      padding:5px; 
      font-family: "Noto Sans JP";
      font-weight: normal;  
      @media (max-width: $breakpoint-max){
        font-size: 16px;
        line-height: 20px;
        
      }   
    }
    h4{
      font-size: 18px;
      line-height:22px;
      color:$purple;
      font-family: "Noto Sans JP";
      font-weight: normal;
      @media (max-width: $breakpoint-max){
        line-height: 26px;        
      }

    }
    h5{
      font-family: "Noto Sans JP";
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color:$purple;
      @media (max-width: $breakpoint-max){
        line-height: 24px;
      }
         }
    b{
      color:$text-article;
      font-weight:bold;
    }     

    i {
      color: $purple;
      font-style: normal;
      font-weight: 500;
    }

    a {
      color: $link-blue;
      text-decoration: underline;
    }

    
    h6 {
      font-size: inherit;
      line-height: 42px;
      margin: 0;

      &:nth-of-type(1),
      &:nth-child(6) {
        @media (max-width: $breakpoint-max) {
          margin-top: 66px;
        }
      }

      &:nth-of-type(3) {
        @media (max-width: $breakpoint-max) {
          margin-bottom: 66px;
        }
      }
    }

    ul {
      margin: 20px auto;
      padding:30px 20px;
      border:1px solid $purple-dark;

      li {
        list-style-type: none;
        position: relative;
        padding:0 0 15px 0;
        margin:0 0 0 30px;
        list-style: disc;
        color:#000;
        font-family: "Noto Sans JP";
        font-weight: 500;

        p {
          margin-bottom: 0;
          margin-top: 0;
        }

       
      }
    }
    ol{
      border:1px solid $purple-dark;
      margin:0 auto;
      padding:30px 20px;
      p{
        margin:0;
      }
      li{
        padding:0 0 15px 0;
        margin:0 0 0 30px;
        color:#000;
        font-family: "Noto Sans JP";
        font-weight: 500;
      }

    }

    & > p {
      &:last-child {
        margin-bottom: 47px;
      }
    }

    @media (max-width: $breakpoint-max) {
      font-size: 18px;

      p {
        line-height: 28px;
        margin: 15px 0;
      }
    }
  }
  .article-youtube-video {
    text-align: center;
    .movieAdditionBtn  {
      background: #7c4195;
      padding: 10px 50px;
      color: #fff;
      border-radius: 15px;
      outline: none;
      border: none;
      font-size: 24px;
      margin: 10px 0 60px;
      @media only screen and (max-width: 768px){
        width: auto;
      }
      @media only screen and (max-width: 767px){
        width: 80%;
        font-size: 20px;
        padding: 10px 15px;
    }
    }
    .youtubeVideo {
      margin: 48px auto 20px;
      width: 640px;
      padding: 20px;
      background: #f5f5f5;
      @media only screen and (max-width: $breakpoint-max) {
        width: 100%;
        margin: 34px auto 25px;
        iframe {
          width: 100% !important;
          height: 300px !important;
        }
      }
    }
    .article-youtube-video{  
        
    .youtubeVideo{
        margin:48px auto 80px;
        width:640px;
        padding:20px;
        background:#f5f5f5;
        .movieAdditionBtn  {
          background: #7c4195;
          padding: 10px 50px;
          color: #fff;
          border-radius: 15px;
          outline: none;
          border: none;
          font-size: 24px;
          margin: 10px 0 60px;
          @media only screen and (max-width: 768px){
            width: auto;
          }
          @media only screen and (max-width: 767px){
            width: 80%;
            font-size: 20px;
            padding: 10px 15px;
        }
        }
        @media only screen and (max-width: $breakpoint-max){
            width:100%;
            margin: 34px auto 25px;
            iframe{
                width:100% !important;
                height:300px !important;
            }

        } 
    }
}

  @media (min-width: 992px) {
    .article-promo-banners {
      .small-promo-banner-cta {
        box-shadow: none;
      }
    }
  }

  @media screen and (min-width: 0\\0) {
    .article-text {
      ul {
        li {
          &::before {
            margin-top: 16px;
          }
        }
      }

      h2 {
        height: 27px;
        line-height: 34px;
      }

      b {
        font-weight: bold;
      }
    }
  }
}

}
`;

export default styles;