import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Slider from 'react-slick';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Image from '../CloudinaryImage/CloudinaryImage';
import Style from './FiftyfiveComp.scss';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import SlickDefaultStyle from '../../../styles/slick.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const CustomArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={`${className}`}
            onClick={onClick}
        >

            <style jsx>{Style}</style>
        </div>
    );
};

const FiftyfiveComp = ({ fiftyFiveData }) => {
    const [dropDownValue, setDropDownValue] = useState('お悩みは・・・');
    const promoImageOne = getImageUrl(fiftyFiveData?.promoBanner1?.imageDt?.asset.url);
    const promoImageTwo = getImageUrl(fiftyFiveData?.promoBanner2?.imageDt?.asset.url);
    const promoImageThree = getImageUrl(fiftyFiveData?.promoBanner3?.imageDt?.asset.url);
    const promoImageFour = getImageUrl(fiftyFiveData?.promoBanner4?.imageDt?.asset.url);
    const promoImageFive = getImageUrl(fiftyFiveData?.promoBanner5?.imageDt?.asset.url);

    const productCarouselCollection = fiftyFiveData;
    const slidesContent = productCarouselCollection?.items
        && productCarouselCollection?.items[0]
        && productCarouselCollection?.items[0].slidesCollection?.items;
    const settings = {
        dots: false,
        infinite: true,
        lazyLoad: 'ondemand',
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow />,
    };

    const getLinkUrl = (link) => {
        switch (link.__typename) {
        case 'ExternalLink':
            return link.url;
        case 'Page':
            return link.seoHead.url;
        default:
            return '';
        }
    };

    const renderItem = (item, i) => {
        if (item.link) {
            return (
                <div key={i}>
                    <div className="fiftyfive-carousel-container">
                        <div className="fiftyfive-carousel-image">
                            <a
                                className={classNameForGA(getLinkUrl(item.link))}
                                {...dataActionForGA(getLinkUrl(item.link))}
                                href={getLinkUrl(item.link)}
                            >
                                <Image image={item.imageDt} />
                            </a>
                        </div>
                        <div className="fiftyfive-carousel-content">
                            {item && item?.header
                                ? <h2 className="fiftyfive-carousel-header"> {item && item?.header} </h2> : ''}
                            <p className="fiftyfive-carousel-description" dangerouslySetInnerHTML={{ __html: item && item.description }} />
                        </div>
                    </div>
                </div>

            );
        }
        return (
            <div key={i}>
                <div className="fiftyfive-carousel-container">
                    <div className="fiftyfive-carousel-image">
                        <Image image={item.imageDt} />
                    </div>
                    <div className="fiftyfive-carousel-content">
                        <h2 className="fiftyfive-carousel-header"> {item && item.header} </h2>
                        <p className="fiftyfive-carousel-description" dangerouslySetInnerHTML={{ __html: item && item.description }} />
                    </div>
                </div>
            </div>
        );
    };

    const handleOnChangeEvent = (textValue, value) => {
        document.getElementById(`promoBanner${value}`).scrollIntoView({
            behavior: 'smooth',
        });
        document.getElementById('myDropdown').classList.remove('show');
        setDropDownValue(textValue);
    };

    const handleDropDown = () => {
        document.getElementById('myDropdown').classList.toggle('show');
    };

    if (typeof window !== 'undefined') {
        window.addEventListener('click', (event) => {
            if (!event.target.matches('.dropbtn')) {
                const dropdowns = document.getElementsByClassName('dropdown-content');
                let i;
                for (i = 0; i < dropdowns.length; i++) {
                    const openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        });
    }

    return (
        <div className="fiftyfive-lp">
            <h1>{fiftyFiveData?.header}</h1>
            <div className="dropdown">
                <button type="button" onClick={() => handleDropDown()} className="dropbtn">{dropDownValue}</button>
                <div id="myDropdown" className="dropdown-content">
                    <div
                        onClick={() => handleOnChangeEvent(fiftyFiveData?.promoText1, 1)}
                        dangerouslySetInnerHTML={{ __html: fiftyFiveData?.promoHeader1 }}
                    />
                    <div
                        onClick={() => handleOnChangeEvent(fiftyFiveData?.promoText2, 2)}
                        dangerouslySetInnerHTML={{ __html: fiftyFiveData?.promoHeader2 }}
                    />
                    <div
                        onClick={() => handleOnChangeEvent(fiftyFiveData?.promoText3, 3)}
                        dangerouslySetInnerHTML={{ __html: fiftyFiveData?.promoHeader3 }}
                    />
                    <div
                        onClick={() => handleOnChangeEvent(fiftyFiveData?.promoText4, 4)}
                        dangerouslySetInnerHTML={{ __html: fiftyFiveData?.promoHeader4 }}
                    />
                </div>
            </div>

            <div className="promoBanners">
                <div id="promoBanner1" className="promo-banner">
                    <h2>{fiftyFiveData?.promoText1}</h2>
                    <Link href={{ pathname: fiftyFiveData?.promoBanner1.link.seoHead.url }}><img src={buildContentfullImageUrl(promoImageOne)} alt="あなたの尿もれはどのタイプ？" /></Link>
                </div>
                <div id="promoBanner2" className="promo-banner">
                    <h2>{fiftyFiveData?.promoText2}</h2>
                    <Link href={{ pathname: fiftyFiveData?.promoBanner2.link.seoHead.url }}><img className="img1" src={buildContentfullImageUrl(promoImageTwo)} alt="骨盤底筋強化の方法" /></Link>
                    <Link href={{ pathname: fiftyFiveData?.promoBanner3.link.seoHead.url }}><img className="img2" src={buildContentfullImageUrl(promoImageThree)} alt="骨盤底筋トレーニング" /></Link>
                </div>
                <div id="promoBanner3" className="promo-banner">
                    <h2>{fiftyFiveData?.promoText3}</h2>
                    <a href="/productdiagnosis/"><img src={buildContentfullImageUrl(promoImageFour)} alt="吸水ケア商品診断" /></a>
                </div>
                <div id="promoBanner4" className="promo-banner">
                    <h2>{fiftyFiveData?.promoText4}</h2>

                </div>
                <div className="header-carousel">
                    <Slider {...settings}>
                        {slidesContent?.map(renderItem)}
                    </Slider>

                </div>
                <div id="promoBanner5" className="promo-banner5 promo-banner">
                    <div className="header-text-promo">
                        <h3>{fiftyFiveData?.headerText}</h3>
                    </div>
                    <div className="promo-banner5-cta">
                        <img src={buildContentfullImageUrl(promoImageFive)} alt="尿もれケアサンプルプレゼント" />
                        <a href="/campaign/whisper-request-sampling2020" data-action-detail="/campaign/whisper-request-sampling2020/" className="event_banner_click promo-banner-with-cta smpling-cta">詳しくは
                        </a>
                    </div>
                </div>
            </div>
            <footer>
                <p>{fiftyFiveData?.copyrightText}</p>
            </footer>
            <style jsx>{SlickDefaultStyle}</style>
            <style jsx>{Style}</style>
        </div>
    );
};

CustomArrow.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
};

FiftyfiveComp.propTypes = {
    fiftyFiveData: PropTypes.object,

};

export default FiftyfiveComp;
