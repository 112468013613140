const preview = process.env.PREVIEW;
module.exports = `
... on Sitemap {
links: sitemapItemsCollection(limit: 15,preview: ${preview}) {
items {
  nameEn
  name
  page {
    __typename
    ... on ExternalLink {
      url
      label
      newWindow
    }
    ... on Page {
      seoHead {
        url
        title
      }
    }
  }
}
}
}
`;
