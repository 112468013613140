const preview = process.env.PREVIEW;
module.exports = `
... on ERetailers{
      name
      header
      headerIcon{
        url
      }
      customClass
      retailersCollection(limit:10){
        items{
          name
          image{
            url
          }
          altText
          customClass
          target
          link
        }
      }
    }
`;
