import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import style from "./DesktopNavigation.scss";
import searchStyle from "../../UI/SearchInput/SearchInput.scss";
import SearchInput from "../../UI/SearchInput/SearchInput";
import RetailersDropdown from "../../RetailersDropdown/RetailersDropdown";
import { buildContentfullImageUrl } from "../../ReusableComponents/imageHelper";
import {
  headerAndFooter,
  headerStickyDetails,
  myrepiLogo,
  regUrl,
} from "../../../helpers/constants";

import ReactMarkdown from "react-markdown";
import Modal from "../../Dialog/Modal";
import { useRouter } from "next/router";

const DesktopHeaderNavigation = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const router = useRouter();
  const hashNavigation = ["product-feature", "product-section"];

  const { asPath } = router;
  const hash = asPath?.split("#")[1];
  useEffect(() => {
    if (hash && hashNavigation.includes(hash)) {
      setTimeout(() => handleJumpLink(`#${hash}`)(null), 200);
    }
  }, [hash]);

  const handleJumpLink = (target) => (ev) => {
    if (target?.includes("http")) {
      window.location.href = target;
      return;
    } else if (target?.includes("#")) {
      ev?.preventDefault();
      const targetEl = document.querySelector(target);
      if (targetEl) {
        const headerOffset = 110;
        const elementPosition = targetEl.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else {
        let { origin } = window.location;
        router.push(`${origin}/${target}`);
      }
    }
  };

  const renderLoginColumn = (item) => (
    <>
      <ul className="nav-sub-sub" key="nav-sub-sub">
        {item?.loginColumn?.menuCategoryCollection?.items?.map((subItem, i) => (
          <li key={i}>
            {subItem.url && (
              <a
                className={`event_crm_action ${subItem.title}`}
                data-action-detail={subItem.label}
                href={subItem.url}
                target={subItem.newWindow == "yes" ? "_blank" : "self"}
              >
                {subItem.label}
              </a>
            )}
          </li>
        ))}
      </ul>
      <style jsx>{style}</style>
    </>
  );

  const renderSubMenu = (item, i) => {
    switch (item.__typename) {
      case "ExternalLink":
        return (
          <li>
            {item.url ? (
              <a
                className="event_menu_click"
                data-action-detail={item.label}
                href={item.url}
              >
                {item.label}
              </a>
            ) : (
              <span className="event_menu_click">{item.label}</span>
            )}
          </li>
        );
      case "NavigationItem":
        return (
          <>
            <li className="wrapper">
              {item.displayTitle && (
                <div className="heading">{item.displayTitle}</div>
              )}
              <ul className="nav-sub-sub" key="nav-sub-sub">
                {item.childPagesCollection.items.map((subItem) => {
                  switch (subItem.__typename) {
                    case "ExternalLink":
                      return (
                        <li>
                          {subItem.url ? (
                            <a
                              className="event_menu_click"
                              data-action-detail={item.label}
                              href={subItem.url}
                            >
                              {subItem.label}
                            </a>
                          ) : (
                            <div className="heading disabled">
                              {subItem.label}
                            </div>
                          )}
                        </li>
                      );
                    default:
                      return (
                        <li key={i}>
                          <a
                            className="event_menu_click"
                            data-action-detail={subItem.label}
                            href={subItem.url}
                          >
                            {subItem.label}
                          </a>
                        </li>
                      );
                  }
                })}
              </ul>
            </li>
            <style jsx>{style}</style>
          </>
        );
      case "Menu":
        return (
          <ul>
            {item.navigationColumn.items.map((items, j) =>
              renderMenuItems(items, j)
            )}
          </ul>
        );
      case "LinkGroup":
        return (
          <>
            {item.linksCollection.items.map((subItem) => (
              <li>
                {subItem.url ? (
                  <a
                    className="event_menu_click"
                    data-action-detail={subItem.label}
                    href={subItem.url}
                  >
                    {subItem.label}
                  </a>
                ) : (
                  <div
                    className="heading"
                    style={{ font: "24px", color: "purple" }}
                  >
                    {subItem.label}
                  </div>
                )}
              </li>
            ))}
          </>
        );
      default:
        return null;
    }
  };

  const renderMenuItems = (item, i) => {
    let text;
    switch (item?.__typename) {
      case "Link":
        return (
          <li>
            <a href={item.url} onClick={handleJumpLink(item.url)}>
              {item.textValue}
            </a>
          </li>
        );
      case "MenuList":
        text = item.subHeading !== null ? item.subHeading : item.title;
        if (item.url === "#product-section") {
          const linkObj = item.menuCategoryCollection?.items?.filter(
            (itm) => itm.__typename === "Link"
          )?.[0];
          const productMenu = item.menuCategoryCollection?.items?.filter(
            (itm) => itm.__typename === "SectionContainer"
          )?.[0];
          return (
            <li key={i} className="product-menu-section">
              <a href={item.url} onClick={handleJumpLink(item.url)}>
                {item.title || item.displayTitle}
              </a>
              <ul className="nav-sub product-menu-container" key="nav-sub">
                <li className="view-all-product">
                  <a href={linkObj.url}>{linkObj.textValue}</a>
                </li>
                <li className="product-menu">
                  {productMenu.sectionContentCollection?.items?.map(
                    (
                      {
                        cardMedia,
                        cardActionCollection,
                        cardDescription,
                        header,
                        customClass,
                      },
                      ind
                    ) => {
                      return (
                        <div className={`card ${customClass ?? ""}`}>
                          <img className="card-media" src={cardMedia.url} />
                          <div className="card-body">
                            <a href={cardActionCollection.items?.[0]?.url}>
                              {header}
                            </a>
                            <ReactMarkdown
                              source={cardDescription}
                              escapeHtml={false}
                              disallowedTypes={[]}
                              unwrapDisallowed
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </li>
              </ul>
              <style jsx>{style}</style>
            </li>
          );
        }
        return (
          <li key={i}>
            {item.title || item.displayTitle}
            <ul className="nav-sub" key="nav-sub">
              {text && <div className="heading">{text}</div>}
              {item.menuCategoryCollection?.items.map((subItem, j) =>
                renderSubMenu(subItem, j)
              )}
            </ul>
            <style jsx>{style}</style>
          </li>
        );
      case "NavigationItem":
        return (
          <>
            <div className="heading" style={{ font: "24px", color: "purple" }}>
              {item.displayTitle}
            </div>
            <ul className="nav-sub-sub" key="nav-sub-sub">
              {item.childPagesCollection.items.map((subItem) => {
                switch (subItem.__typename) {
                  case "ExternalLink":
                    return (
                      <li>
                        {subItem.url ? (
                          <a
                            className="event_menu_click"
                            data-action-detail={item.label}
                            href={subItem.url}
                          >
                            {subItem.label}
                          </a>
                        ) : (
                          <div
                            className="heading"
                            style={{ font: "24px", color: "purple" }}
                          >
                            {subItem.label}
                          </div>
                        )}
                      </li>
                    );
                  default:
                    return (
                      <li key={i}>
                        <a
                          className="event_menu_click"
                          data-action-detail={subItem.label}
                          href={subItem.url}
                        >
                          {subItem.label}
                        </a>
                      </li>
                    );
                }
              })}
            </ul>
            <style jsx>{style}</style>
          </>
        );
      case "LinkGroup":
        return (
          <>
            {item.linksCollection.items.map((subItem) => (
              <li style={{ padding: "10px" }}>
                {subItem.url ? (
                  <a
                    className="event_menu_click"
                    data-action-detail={subItem.label}
                    href={subItem.url}
                  >
                    {item.title}
                  </a>
                ) : (
                  <div
                    className="heading"
                    style={{ font: "24px", color: "purple" }}
                  >
                    {subItem.label}
                  </div>
                )}
              </li>
            ))}
          </>
        );
      default:
    }
  };

  const isHeaderLogo =
    typeof window !== "undefined" && window.location.pathname === "/";
  const retailerDropdown = props.retailers.items[0];

  return (
    <div id="header" className="desktop-navigation Homepage-navigation">
      <div className="container">
        <div className="header-wrapper">
          {isHeaderLogo !== true ? (
            <div className="brandLogoWrapper">
              <a href="/">
                <img
                  src={buildContentfullImageUrl(headerAndFooter)}
                  className="logo"
                  alt="Whisper logo"
                  title="Whisper logo"
                />
              </a>
            </div>
          ) : (
            <div className="brandLogoWrapper brandLogoWrapper-h1">
              <h1>
                <a href="/">
                  <img
                    src={buildContentfullImageUrl(headerAndFooter)}
                    className="logo"
                    alt="Whisper logo"
                    title="Whisper logo"
                  />
                  吸水ケア・尿漏れケアのウィスパー
                </a>
              </h1>
            </div>
          )}
          <div className="Desktop-navigation-container">
            <ul className="nav-root">
              {props.menuItems.map((item, i) => renderMenuItems(item, i))}
            </ul>
            <div className="search-wrapper">
              <div className="optionsWrapper">
                <span className="log-menu header-login">
                  <img src={myrepiLogo} alt={props?.loginColumn?.title} />
                  <ul className="nav-root">{renderLoginColumn(props)}</ul>
                </span>
              </div>
            </div>
          </div>
          <div className="retailer-container header updated-header">
            <button
              className="pop-btn"
              onClick={() => setOpenModal(!openModal)}
            >
              {retailerDropdown.dropdownTitle}
              <span className="desktop-popup-btn-arrow">{">"}</span>
            </button>
            {openModal && (
              <Modal
                open={openModal}
                backdropStyle={"retailer-popup-backdrop"}
                modalStyle={"retailer-popup"}
              >
                <div className="retail-btn-wrapper">
                  {retailerDropdown?.retailersCollection?.items?.map(
                    ({ imageDt, link }) => {
                      return (
                        <a
                          href={link.url}
                          aria-label={imageDt.description}
                          target="_blank"
                          className="image-btn"
                        >
                          <img src={imageDt.asset.url} />
                        </a>
                      );
                    }
                  )}
                </div>
                <button
                  onClick={() => setOpenModal(false)}
                  className="header-popup-btn"
                >
                  <span className="modal-popup-close-btn">&times;</span>
                  閉じる
                </button>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <style jsx>{style}</style>
      <style jsx>{searchStyle}</style>
    </div>
  );
};

DesktopHeaderNavigation.propTypes = {
  menuItems: PropTypes.object,
  loginColumn: PropTypes.object,
  retailers: PropTypes.object,
};

export default DesktopHeaderNavigation;
