import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.promo-banner {
    align-self: center;
    margin-bottom: 50px;
    width: 940px;

    img {
        box-shadow: $box-shadow-25;
    }

    &:hover {
        cursor: pointer;
    }

    @media (max-width: $breakpoint-max) {
        margin-bottom: 8px;
        overflow: hidden;
        width: 100%;

        img {
            width: 100%;
        }
    }
}
.Homepage-promo-banner {
    max-width: 970px;
    position: relative;
    margin-bottom: 140px;
    .heading {
        color: #ccc;
        font-family: "Caveat-Regular";
        text-align: center;
        font-size: 48px;
        margin-bottom: 30px;
        position: relative;
    }
    @media (max-width: $breakpoint-max) {
        margin-bottom:70px;
        .heading {
            margin-bottom: 0px;
        }
    }
}
.firstHomeBanner {
    margin-top: 100px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
}
.homeSecondaryBanner {
    width: 100%;
    max-width: 1180px;
    margin-bottom: 0;

    @media (max-width: 1279px) {
        width: 90%;

        &:nth-of-type(2) {
            margin-top: 30px;
        }
    }
}
`;

export default styles;