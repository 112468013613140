import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.product-feature {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    width: 100%;

    @media (max-width: $breakpoint-max) {
        margin-bottom: 100px;
        width: $mobile-elements-width;
    }

    .feature {
        background-color: white;
        border: 1px solid $purple;
        border-radius: 8px;
        box-shadow: 4px 4px 11px rgba(0, 0, 0, .125);
        color: $purple-dark;
        display: flex;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        margin: 3rem 0;
        padding: 20px 70px;
        position: relative;
        width: $desktop-elements-width;

        @media (max-width: $breakpoint-max) {
            margin-bottom: 0;
            margin-top: 22px;
        }

        h2 {
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 2.7rem;
            font-style: italic;
            font-weight: 500;
            line-height: 1;
            margin: 11px 0 27px;

            @media (max-width: $breakpoint-max) {
                font-size: 1.8rem;
                margin: 0 0 15px;
            }
        }

        h3 {
            border-bottom: 1px solid rgba($purple, .5);
            display: block;
            font-size: 1.5rem;
            line-height: 1;
            margin: 9px 0;
            padding-bottom: 5px;

            @media (max-width: $breakpoint-max) {
                font-size: 1rem;
                margin: 4px 0;
            }
        }

        .description-sizer {
            @media (min-width: $breakpoint-min) {
                min-width: 310px;
            }
        }

        .highlight {
            color: $pink;
            font-size: 120%;
        }

        .info {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 70%;

            @media (max-width: $breakpoint-max) {
                z-index: 1;
            }
        }

        .image {
            position: relative;
            z-index: 2;
        }

        @media (max-width: $breakpoint-max) {
            flex-direction: column-reverse;
            padding: 29px;
            width: $mobile-elements-width;

            .info {
                width: 100%;
            }

            img {
                width: 98%;
            }
        }

        @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
            width: $mobile-elements-width;
        }

        &::before,
        &::after {
            background: url('/static/images/corner-decoration.jpg') left top no-repeat,
            url('/static/images/corner-decoration-2.jpg') right top no-repeat;
            border-radius: 8px;
            content: '';
            height: 45px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            @media (max-width: $breakpoint-max) {
                background: url('/static/images/corner-decoration.jpg') left 1px top no-repeat,
                url('/static/images/corner-decoration-2.jpg') right top no-repeat;
            }
        }

        &::after {
            bottom: 0;
            top: auto;
            transform: rotate(180deg);
        }
    }

    .note {
        color: $off-black;
        font-size: 12px;
        margin-top: 20px;

        .note-part {
            display: block;
            padding-left: 13px;

            &:first-child {
                padding-left: 0;
            }
        }

        @media (max-width: $breakpoint-max) {
            margin-bottom: -2px;
            margin-top: 7px;

            .note-part {
                display: inline;
                padding-left: 0;

                &:last-child {
                    display: block;
                }
            }
        }

    }
}
`;

export default styles;