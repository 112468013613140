import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.carousel-container {
  width: 100%;
  position: relative;

  .carousel-inner {
    width: 100%;
    position: relative;
    height: 768px;
    .img-wrap{
      width: 100%;
      position: absolute;
      // top: 0;
      img{
        width: 100%;
      }
    }
    .hide {
      display: none;
    }
    .show {
      display: block;
    }

    .carousel-dots {
      width: 100%;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      text-align: center;
    .dot {
      height: 15px;
      width: 15px;
      margin: 0 2px;
      background-color: $purple-light;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }
    .active {
      background-color: $purple-dark;
    }
  }
  @media only screen and (max-width: 767px) {
  height: 100vw;
  }
}

}
`;

export default styles;