import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.main-container {
    .desktop-navigation {
        height: 343px;
    }
}

.product-details {
    color: $purple-dark;
    font-family: "M PLUS Rounded 1c", sans-serif;

    @media (max-width: $breakpoint-max) {
        margin-bottom: 18px;
    }

    .col-product-images {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        position: relative;
        @media (max-width: $breakpoint-min) {
            min-height: 280px;
        }

        @media (max-width: $breakpoint-max) {
            margin: 0 auto;
            padding-top: 40px;
            width: auto;
        }

        .magnifier {
            align-self: flex-end;
            cursor: pointer;
            position: absolute;
            right: 10px;

            @media (max-width: $breakpoint-max) {
                right: -10px;
            }
        }

        .enlarged {
            background-color: white;
            border-radius: 8px;
            box-shadow: $box-shadow-25;
            left: 15px;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 940px;
            z-index: 100;

            @media (max-width: $breakpoint-max) {
                left: 9px;
                top: 0;
                width: 95%;
            }

            img {
                max-width: 100%;
            }

            .close-enlarged {
                position: absolute;
                right: 5px;
                top: -5px;
                transform: rotate(45deg);

                &::after {
                    content: "+";
                    cursor: pointer;
                    font-size: 34px;
                }
            }
        }
    }

    .product-name {
        margin-bottom: 2px;
    }

    .col-product-data {
        @media (max-width: $breakpoint-max) {
            padding: 0 25px;
        }
    }

    .description {
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-size: 33px;

        @media (max-width: $breakpoint-max) {
            display: none;
        }
    }

    a {
        color: inherit;
    }
}
`;

export default styles;