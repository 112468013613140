import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Style from './ContentLt.scss';
import useScreenSize from '../Hooks/useScreenSize';
import { storageSet } from '../../helpers/StorageEvents';

const handleGAEvents = async () => {
    await window.dataLayer.push({
        'event': 'customEvent',
        'GAeventCategory': 'event_informational_action',
        'GAeventAction': 'event_view_movie_page',
        'GAeventLabel': 'whisper_movie_pageview',
        'GAeventValue': 0,
        'GAeventNonInteraction': false,
    });
    return true;
};

const handleNavigateEvent = async () => {
    if (handleGAEvents()) {
        storageSet('videoFeedbackVia_incontinence', false);
        storageSet('videoFeedbackVia_pelvic', true);
        Router.push('/pelvic-training-exercise-video');
    }
};

const ContentLt = ({ content, contentMobile, currentIndex }) => {
    const resolution = useScreenSize();
    const currentLocation = !!(typeof window !== 'undefined' && (window.location.pathname === '/sugiyama-san/special-Interview/' || window.location.pathname === '/sugiyama-san/special-Interview'));
    if (currentLocation && currentIndex === 7) {
        const element = typeof document !== 'undefined' && document.getElementById('youtube-feedback');
        element.addEventListener('click', handleNavigateEvent);
    }

    return (
        <div className="content-lt">
            <ReactMarkdown
                source={resolution && resolution.isMobile ? contentMobile : content}
                escapeHtml={false}
                disallowedTypes={[]}
                unwrapDisallowed
            />
            <style jsx>{Style}</style>
        </div>
    );
};

ContentLt.propTypes = {
    content: PropTypes.string,
    contentMobile: PropTypes.string,
    currentIndex: PropTypes.number,
};

export default ContentLt;
