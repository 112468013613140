/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Style from './couponretaileradbanner.scss';
import { storageGet } from '../../../src/helpers/StorageEvents';

const CouponAdBanner = () => {
    const [adBannerType, setAdBannerType] = useState('');
    const [showAllBanners, setShowAllBanners] = useState(false);
    const [banner, setBanner] = useState('');
    const [barCode, setBarCode] = useState(false);
    const [centerClass, setCenterClass] = useState('');

    // Below are the encrypted url for the different banners.
    // retailer~cocokara-doubleoptin = pEQ2t~cUvy9f-dBlpTin
    // retailer~tomods-doubleoptin = pEQ2t~tNzcR9-dBlpTin
    // retailer~life-doubleoptin = pEQ2t~ldx4bG-dBlpTin
    // whisperedm-doubleoptin = wSKE2-dBlpTin
    // linead~cocokara-doubleoptin = lspP6~cUvy9f-dBlpTin
    // linead~tomods-doubleoptin = lspP6~tNzcR9-dBlpTin

    const retailerUrls = {
        // '/pEQ2tcUvy9f': 'https://lin.ee/VBJPhtJ/llwa',
        // '/pEQ2ttNzcR9': 'https://lin.ee/pJNOT9x/llwa',
        // '/pEQ2tldx4bG': 'https://lin.ee/UZLn7xb/llwa',
        // '/wSKE2cUvy9f': 'https://lin.ee/QZKZxVeO/llwa',
        // '/wSKE2tNzcR9': 'https://lin.ee/11bwkeY/llwa',
        // '/wSKE2ldx4bG': 'https://lin.ee/VvFVkhFt/llwa',
        // '/lspP6cUvy9f': 'https://lin.ee/IUFVtK0/llwa',
        // '/lspP6tNzcR9': 'https://lin.ee/TSYDXpI/llwa',
        // '/fcI2QcUvy9f': 'https://lin.ee/IUFVtK0/llwa', // fb-cocokara
        // '/fcI2QtNzcR9': 'https://lin.ee/TSYDXpI/llwa', // fb-tomods
        // '/fcI2Qldx4bG': 'https://lin.ee/19Uy5D9/llwa', // fb-life
        // '/gPtdNcUvy9f': 'https://lin.ee/QZKZxVeO/llwa', // gdn-cocokara
        // '/gPtdNtNzcR9': 'https://lin.ee/11bwkeY/llwa', // gdn-tomods
        // '/gPtdNldx4bG': 'https://lin.ee/VvFVkhFt/llwa', // gdn-life
    };

    const gaTagsMapping = {
        '/s5trgfn7rk': 'add tags later in this place.',
        // '/s5trgy8gda'
        // '/s5trge1y6m'

        // '/pEQ2tcUvy9f': 'whisper_coupon_request_edm_cocokara', // retailer-cocokara
        // '/pEQ2ttNzcR9': 'whisper_coupon_request_edm_tomods', // retailer-tomods
        // '/pEQ2tldx4bG': 'whisper_coupon_request_edm_life', // retailer-life
        // '/wSKE2cUvy9f': 'whisper_coupon_request_whedm_cocokara', // whispereDM-cocokara
        // '/wSKE2tNzcR9': 'whisper_coupon_request_whedm_tomods', // whisper-tomods
        // '/wSKE2ldx4bG': 'whisper_coupon_request_whedm_life', // whisper-life
        // '/lspP6cUvy9f': 'whisper_coupon_request_line_cocokara', // linead-cocokara
        // '/lspP6tNzcR9': 'whisper_coupon_request_line_tomods', // linead-tomods
        // '/fcI2QcUvy9f': 'whisper_coupon_request_facebook_cocokara', // fb-cocokara
        // '/fcI2QtNzcR9': 'whisper_coupon_request_facebook_tomods', // fb-tomods
        // '/fcI2Qldx4bG': 'whisper_coupon_request_facebook_life', // fb-life
        // '/gPtdNcUvy9f': 'whisper_coupon_request_gdn_cocokara', // gdn-cocokara
        // '/gPtdNtNzcR9': 'whisper_coupon_request_gdn_tomods', // gdn-tomods
        // '/gPtdNldx4bG': 'whisper_coupon_request_gdn_life', // gdn-life
    };

    const gaTagsOnPageVisit = {
        '/s5trgfn7rk': 'add page visit tags later in this place.',
        // '/s5trgy8gda
        // '/s5trge1y6m'

        // '/pEQ2tcUvy9f': 'whisper_coupon_visit_edm_cocokara', // retailer-cocokara
        // '/pEQ2ttNzcR9': 'whisper_coupon_visit_edm_tomods', // retailer-tomods
        // '/pEQ2tldx4bG': 'whisper_coupon_visit_edm_life', // retailer-life
        // '/wSKE2tNzcR9': 'whisper_coupon_request_whedm_tomods', // whisper-tomods
        // '/wSKE2ldx4bG': 'whisper_coupon_request_whedm_life', // whisper-life
        // '/lspP6cUvy9f': 'whisper_coupon_visit_line_cocokara', // linead-cocokara
        // '/lspP6tNzcR9': 'whisper_coupon_visit_line_tomods', // linead-tomods
        // '/fcI2Q': 'whisper_coupon_visit_line_facebook', // fb
        // '/fcI2QtNzcR9': 'whisper_coupon_request_facebook_tomods', // fb-tomods
        // '/fcI2Qldx4bG': 'whisper_coupon_request_facebook_life', // fb-life
        // '/gPtdN': 'whisper_coupon_request_gdn_cocokara', // gdn
        // '/gPtdNtNzcR9': 'whisper_coupon_request_gdn_tomods', // gdn-tomods
        // '/gPtdNldx4bG': 'whisper_coupon_request_gdn_life', // gdn-life
        // '/wl1ba': 'whisper_coupon_request_edm_welcia',
        // '/ftq2k': 'whisper_coupon_request_fb_welcia',
        // '/bSqE8': 'whisper_coupon_request_whedm_welcia',
        // '/we2pm': 'whisper_coupon_request_from_menu',
    };

    const setGATagsAndEvents = async (eLabal, eAction) => {
        await window.dataLayer && window.dataLayer.push({
            event: 'customEvent',
            GAeventCategory: 'event_coupon_action',
            GAeventAction: eAction,
            GAeventLabel: eLabal,
            GAeventValue: 0,
            GAeventNonInteraction: false,
        });
    };

    const redirectToUrl = (clickedBanner) => {
        const couponBanner = banner ? `${adBannerType}${banner}` : `${adBannerType}${clickedBanner}`;
        setGATagsAndEvents(gaTagsMapping[couponBanner], 'event_coupon_request');
        window.open(retailerUrls[couponBanner]);
    };

    useEffect(() => {
        setAdBannerType(storageGet('adBannerType'));
        setBanner(storageGet('banner'));

        const couponBanner = banner ? `${adBannerType}${banner}` : `${adBannerType}`;
        if (couponBanner) {
            ['/wl1ba', '/ftq2k', '/bSqE8', '/we2pm'].indexOf(adBannerType) !== -1 ? setBarCode(true) : setBarCode(false);
            setGATagsAndEvents(gaTagsOnPageVisit[couponBanner], 'event_coupon_request');
        }
        console.log('adBannerType: ', adBannerType, '  banner: ', banner);
        const showBanners = ['/wSKE2', '/fcI2Q', '/gPtdN'].indexOf(adBannerType) !== -1;
        showBanners ? setShowAllBanners(true) : setShowAllBanners(false);
        const classes = classnames('Banner-image', { 'center-image': !showBanners });
        setCenterClass(classes);
    }, [adBannerType, banner]);

    return (
        <div className="Coupon-Retailer">
            <div className="Coupon-Retailer-Banner">
                <h3 className="Banner-header">ご希望店舗の「クーポンを取得する」ボタンをクリックしてください。</h3>

                <div className="Retailer-Banner">

                    {barCode
                        ? (
                            <div className="img1 bar-code">
                                <p>使用可能期間：2021/1/1〜1/31</p>
                                <img src=" https://images.ctfassets.net/8g0fievzqg8k/1JRExfQOBTEFEbWsqAmHAI/94b8c7755a71e6f3c41e5001c8a8eef3/Welcia_PC_LP2.png" alt="banner" height="auto" width="auto" />
                            </div>
                        )
                        : ''}

                    {(showAllBanners || banner === 'ldx4bG')
                        ? (
                            <div
                                className={`Banner-image img1 life ${centerClass}`}
                                onClick={() => redirectToUrl('ldx4bG')}
                            >
                                <img src="https://images.ctfassets.net/8g0fievzqg8k/6GFMCpza3XfLuh47HUJcvs/16df2c26c18729eb16e8319c5084489f/life_logo.png" alt="banner" height="auto" width="auto" />
                                <p>クーポンを取得する</p>
                            </div>
                        )
                        : ''}

                    {(showAllBanners || banner === 'cUvy9f')
                        ? (
                            <div
                                className={`Banner-image img1 kokokara ${centerClass}`}
                                onClick={() => redirectToUrl('cUvy9f')}
                            >
                                <img src="https://images.ctfassets.net/8g0fievzqg8k/FZ1X9rbAgwwc6oQmYWJrr/fde6e054211a6c5e76629bdd3696c3ae/kokokara_logo.png" alt="banner" height="auto" width="auto" />
                                <p>クーポンを取得する</p>
                            </div>
                        )
                        : ''}

                    {(showAllBanners || banner === 'tNzcR9')
                        ? (
                            <div
                                className={`Banner-image img1 tomods ${centerClass}`}
                                onClick={() => redirectToUrl('tNzcR9')}
                            >
                                <img src="https://images.ctfassets.net/8g0fievzqg8k/1q0V9J7grl8MVvUGMJLYkw/38117a356212a18aaa3d11d953604c82/tomods_pls_use_blue_one.jpg" alt="banner" height="auto" width="auto" />
                                <p>クーポンを取得する</p>
                            </div>
                        )
                        : ''}
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default CouponAdBanner;
