import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.buy-now-button {
  .buy-now-cta {
    position: relative;
    overflow: hidden;
    background: none;
    border: none;
    width: 100%;
    float: left;
    padding: 0;
    #buy-now-ps-div {
      z-index: -1000;
      background: none;
      background-color: #64369f;
    }
    .inactive-label-text {
      display: none;
    }
    .active-label-text {
      background-color: transparent !important;
    }
    .buttoncover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .ps-disabled {
      .inactive-label-text {
        background-color: transparent !important;
        display: block;
      }
      .active-label-text {
        display: none;
      }
    }
  }
  @media (max-width: $breakpoint-max) {
    margin-top: 3rem;
    .ps-widget {
      line-height: 1;
    }
  }
}
`;

export default styles;