import React from 'react';

const CarouselArrow = () => (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="9" height="16.29" viewBox="0 0 9 16.29">
        <path id="Path_47534" fill="#09357a" data-name="Path 47534" d="M516.08,512.3l-6.33-8.14h-2.67l6.34,8.14-6.34,8.15h2.67Z" transform="translate(-507.08 -504.16)" />
    </svg>
);

CarouselArrow.defaultProps = {
    className: 'w-16 h-10',
};

export default CarouselArrow;
