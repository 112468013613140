module.exports = `... on TemporaryThanksForRegistration {
    heading
    subheading
    shortdesc1
    shortdesc2
    shortdesc3
    shortdesc4 {
      json
    }
  }`;
