/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import ProductDiagnosisRecommendation from './ProductDiagnosisRecommendation';
import PromoBanner from '../PromoBanner/PromoBanner';
import StaticHeader from '../StaticHeader/StaticHeader';
import SmallPromoBannersContainer from '../SmallPromoBannersContainer/SmallPromoBannersContainer';
import { getContent } from '../../helpers/uiHelper';
import productdiagnosis from './productdiagnosis.scss';

const ProductDiagnosis = (props) => {
    const { styles, banner, content, promoBanner, smallPromoBanners, resultPageCollection } = props;
    const resolution = useScreenSize();

    const [isRecommendationActive, setisRecommendationActive] = useState(false);

    const onStartRecommendation = () => (
        setisRecommendationActive(!isRecommendationActive)
    );
    if (!resolution) {
        return null;
    }
    return (
        <div className={styles}>
            {!isRecommendationActive && <div className="Newdiagnosispage"><StaticHeader {...banner} /> </div>}
            <h1> {
                !isRecommendationActive && getContent(content, resolution)
            }
            </h1>
            {
                !isRecommendationActive
                && (
                    <div className="start-button" onClick={onStartRecommendation}>
                        START
                    </div>
                )
            }
            {
                isRecommendationActive
                && (
                    <ProductDiagnosisRecommendation
                        resolution={resolution}
                        resultPageCollection={resultPageCollection}
                        setisRecommendationActive={setisRecommendationActive}
                    />
                )
            }
            {!isRecommendationActive && <PromoBanner {...promoBanner} />}
            {!isRecommendationActive && <SmallPromoBannersContainer {...smallPromoBanners} />}
            <div id="mw-campaign">get-my-free-sample</div>
            <style jsx>{productdiagnosis}</style>
        </div>
    );
};

ProductDiagnosis.propTypes = {
    styles: PropTypes.any,
    banner: PropTypes.any,
    content: PropTypes.any,
    promoBanner: PropTypes.any,
    smallPromoBanners: PropTypes.any,
    resultPageCollection: PropTypes.any,
};

export default ProductDiagnosis;
