import React, { useState, useEffect } from 'react';
import CouponContentLP2 from '../CouponCampaign/couponcampaignlp2';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './couponlp.scss';
import CouponBarcode from './couponBarcode';
import { storageGet } from '../../../src/helpers/StorageEvents';
import Welciacontent from './welciacontent';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const CouponRetailer = () => {
    const [couponBanner, setCouponBanner] = useState('');

    useEffect(() => {
        const retailer = storageGet('retailerUrl');
        console.log(retailer);

        if (retailer) {
            console.log(retailer.split('~')[0]);
            setCouponBanner(retailer.split('~')[0]);
        } else {
            const params = window.location.pathname;
            console.log(params, window.location.pathname);
            setCouponBanner(params);
        }
    }, []);

    const resolution = useScreenSize();
    if (!resolution) return null;
    const img = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/35za4x7hY9syeL9g8LYg2H/df683f4701a6bb2894c204e6c7e0185e/sundrug1.png' : 'https://images.ctfassets.net/8g0fievzqg8k/7pw9uGduB3rdgwmic0snC0/92ccb3db63151dc79587bbe6490de588/______________________________158.png';
    const weciaimg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/2mPpALx70J0mX6F4AAyn8P/6f32b63d9ee0b07852294f9da4b2b3df/welcia_top_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2fn2nbBcMPylz6vWMjQHpA/898948884aee76c522270bc0b089125f/welcia_top_pc.png';
    return (
        <>
            { ((couponBanner === 's5trg') || (couponBanner === '/s5trg'))
                && (
                    <div className="coupon-Campaign-lp">
                        <p className="coupon-title"><span>【ウィスパー公式サイト】</span></p>
                        <div className="Coupon-Campaing-Banner">
                            <h1>
                                <img src={buildContentfullImageUrl(img)} alt="サンドラッグキャンペーン" height="auto" width="auto" />
                                <span>サンドラッグキャンペーン</span>
                            </h1>
                        </div>
                        <h2 className="line-below-banner">クーポン使用可能期間:2021/5/1（土）〜 6/30（水）</h2>
                        <CouponBarcode />
                        <CouponContentLP2 />
                        <style jsx>{Style}</style>
                    </div>
                )}

            { ((couponBanner === 'w3lca') || (couponBanner === '/w3lca') || (couponBanner === '/welcia_lp2/'))
                && (
                    <div className="coupon-Campaign-lp">
                        <p className="coupon-title"><span>【ウィスパー公式サイト】</span></p>
                        <div className="Coupon-Campaing-Banner">
                            <h1>
                                <img src={buildContentfullImageUrl(weciaimg)} alt="ウエルシアキャンペーン" height="auto" width="auto" />
                                <span>サンドラッグキャンペーン</span>
                            </h1>
                        </div>
                        <h2 className="line-below-banner welcia-banner">クーポン使用可能期間:2021/6/1（火）～2021/6/30（水）</h2>
                        <CouponBarcode />
                        <Welciacontent />
                        <style jsx>{Style}</style>
                    </div>
                )}
        </>
    );
};

export default CouponRetailer;
