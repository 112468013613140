import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.VC-comments-container{
    width: 100%;
    float:left;
    position: relative;
    .VC-comments{
        width:300px; 
        border: 1px solid #ccc;
        padding:10px;       
    }
    @media only screen and (max-width: $breakpoint-max) {

    }
}
`;

export default styles;