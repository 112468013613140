import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.Heading-Section {
    padding: 70px 0;

    .Akashi-Title {
        font-size: 30px;
        line-height: 52px;
        color: #8347ad;
        text-align: center;
        font-family: "Noto Sans JP";
        font-weight: normal;
    }
}
@media only screen and (max-width: 767px) {
    .Heading-Section {
        padding: 25px 20px 0;
        .Akashi-Title {
            font-size: 23px;
            text-align: left;
            line-height: 35px;
            padding: 28px 0;
        }
    }
}
`;

export default styles;