module.exports = (preview) => `{
  fiftyfivePromotionCollection(limit:10, ,preview:${preview}) {
    items{
        name
        header
        promoText1
        promoText2
        promoText3
        promoText4
        promoHeader1
        promoHeader2
        promoHeader3
        promoHeader4
        headerText
        copyrightText
        promoBanner1 {
          nameEn
          link {
            seoHead {
              url
            }
          }
          imageDt {
            title
            cloudinaryId
            asset{
              url
            }
          }
          imageSp {
            title
            cloudinaryId
            asset{
              url
            }
          }        
        }
        promoBanner2 {
          nameEn
          link {
            seoHead {
              url
            }
          }
          imageDt {
            title
            cloudinaryId
            asset{
              url
            }
          }
          imageSp {
            title
            cloudinaryId
            asset{
              url
            }
          }        
        }
        promoBanner3 {
          nameEn
          link {
            seoHead {
              url
            }
          }
          imageDt {
            title
            cloudinaryId
            asset{
              url
            }
          }
          imageSp {
            title
            cloudinaryId
            asset{
              url
            }
          }        
        }
        promoBanner4 {
          nameEn
          link {
            seoHead {
              url
            }
          }
          imageDt {
            title
            cloudinaryId
            asset{
              url
            }
          }
          imageSp {
            title
            cloudinaryId
            asset{
              url
            }
          }        
        }
        promoBanner5 {
          nameEn
          imageDt {
            title
            cloudinaryId
            asset{
              url
            }
          }
          imageSp {
            title
            cloudinaryId
            asset{
              url
            }
          }   
        }
        imageSp {
          title
          cloudinaryId
          asset{url}
        }   
      }
      productCarouselCollection(limit:2,preview:${preview}) {
        items{
          __typename
          ... on HeaderCarousel {
            slidesCollection {
        items {
          nameEn
          header
          description
          imageDt {
            description
            cloudinaryId
            asset{
              url
            }
          }
        imageSp {
          description
          cloudinaryId
          asset{
            url
          }
        } 
        link {
          __typename
          ...on ExternalLink {
              url
            }
          ...on Page {
            seoHead {
              url
            }
          }
        }     
      }
    }
  }
  }
  }
  }
  }
  }`;
