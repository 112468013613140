import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.static-header {
    background-repeat: no-repeat;
    background-size: 100%;
    // margin-top: -138px;
    padding-bottom: 35%;
    width: 100%;

    @media (max-width: $breakpoint-max) {
        padding-bottom: 195%;
        margin-top: -97px;
    }
}
@media (max-width: $breakpoint-max) {
    .mobile-renewal {
        padding-bottom: 110%;
        margin-top: -57px;

        // background-color: #faf7fb;
    }
}

.mobile-renewal {
    height: auto;
    padding-bottom: 100%;
    @media (min-width: $breakpoint-min) {
    background-size: cover;
    height: 800px;
    padding-bottom: 63%;
    }
}

.Product-Series {
    padding-bottom: 42%;
    background-size: 100% 100%;
    @media (max-width: $breakpoint-max) {
        padding-bottom: 100%;
    }
}

.static-header-banner {
    display:inline-block;
    width:100%;
    @media (max-width: $breakpoint-min) {
        min-height: 340px;
    }
    h1{
        position: relative;
        margin: 0;
        span {
            border: 0!important;
            clip: rect(0,0,0,0)!important;
            height: 1px!important;
            margin: -1px!important;
            overflow: hidden!important;
            padding: 0!important;
            position: absolute!important;
            width: 1px!important;
        }
    }
}
`;

export default styles;