const preview = process.env.PREVIEW;
module.exports = `
... on CardPromo {
    title
    contentTypeCollection(limit: 6,preview: ${preview}) {
        items {
          __typename
          ... on PromoBannerWithCta {
            imageDt {
              cloudinaryId
              description
              asset{url}}
            imageSp {
              cloudinaryId
              description
              asset{url}}
            button {
              ... on ButtonExternal {
                externalUrl
                text
                target}}}
          ... on CampaignContentItem{
            title
            description
            styles
            cta{
              title
              externalUrl
            }
          }
          ... on ContentLt {
            content
            contentMobile
          }
          ... on ImageAndText{
            text
            button {
              ... on ButtonInternal{
                text
                url {
                  seoHead{
                    url
                  }
                }
              }
            }
            imageSection{
              imageDesktop{
                description
                cloudinaryId
                asset{url}
              }
              imageSmartphone{
                description
                cloudinaryId
                asset{url}
              }}}}}}
`;
