module.exports = (preview) => `
{
    categories: categoryListCollection(limit: 8,preview: ${preview}) {
      items {
        name
        category
        page {
          seoHead {
            title
            description
            url
          }
        }
        products: productsCollection(limit:12,preview: ${preview}){
          items{
            title
            productCategory
          }
        }
      }
    }
  }
`;
