import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import { storageGet } from '../../helpers/StorageEvents';
import Style from './DigitalCampaignlpcontent.scss';

const DigitalCampaignLP3Content = () => {
    const [welcia, setWelcia] = useState(false);
    const [cawachi, setCawachi] = useState(false);
    const [kyorindo, setKyorindo] = useState(false);
    const [fukutaro, setFukutaro] = useState(false);
    const [cf, setCf] = useState(false);
    const [satsudora, setSatsudora] = useState(false);
    const [Sundrug, setSundrug] = useState(false);
    const [tsuruha, setTsuruha] = useState(false);
    const [bnD, setBnD] = useState(false);
    const [mk, setMk] = useState(false);
    const [lady, setLady] = useState(false);

    useEffect(() => {
        const retailerlogo = (storageGet('digitalRetailer'));
        const userLoggedIn = storageGet('isUserLoggedIn');
        if (userLoggedIn !== 'true') {
            if (retailerlogo === 'all') {
                Router.push('/campaign/coupon-all-lp1/');
            } else if (retailerlogo === 'mkcf') {
                Router.push('/campaign/coupon-mkcf-lp1/');
            } else if (retailerlogo === 'tsuruha') {
                Router.push('/campaign/coupon-tsuruha-lp1/');
            } else if (retailerlogo === 'sundrug') {
                Router.push('/campaign/coupon-sundrug-lp1/');
            }
        }
        if (retailerlogo === 'ウエルシア') {
            setWelcia(true);
        } else if (retailerlogo === 'カワチ') {
            setCawachi(true);
        } else if (retailerlogo === '杏林堂') {
            setKyorindo(true);
        } else if (retailerlogo === 'くすりの福太郎') {
            setFukutaro(true);
        } else if (retailerlogo === 'ココカラファイン') {
            setCf(true);
        } else if (retailerlogo === 'サツドラ') {
            setSatsudora(true);
        } else if (retailerlogo === 'サンドラッグ') {
            setSundrug(true);
        } else if (retailerlogo === 'ツルハドラッグ') {
            setTsuruha(true);
        } else if (retailerlogo === 'B&Dドラッグストア') {
            setBnD(true);
        } else if (retailerlogo === 'マツモトキヨシ') {
            setMk(true);
        } else if (retailerlogo === 'レディ') {
            setLady(true);
        }
    }, []);
    return (
        <div className="digital-campaign-contentsection">
            <div className="digital-campaign-content">
                <h2 className="digital-campaign-title">【クーポン取得に関しての注意事項】</h2>
                <h2 className="digital-campaign-header">●利用方法</h2>
                <p className="digital-campaign-text">クーポン有効期間中に、対象商品と本クーポン画面をお会計時にレジでご提示ください。 </p>
                {welcia
                    && (
                        <>
                            <h2 className="digital-campaign-header">●対象店舗</h2>
                            <p className="digital-campaign-text">
                                ウエルシアグループ（ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店）
                                <br />※一部商品の取り扱いのない店舗がございます。
                            </p>
                            <h2 className="digital-campaign-header">● クーポン種類</h2>
                            <p className="digital-campaign-text">
                                300Tポイント付与クーポン
                            </p>
                            <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                            <p className="digital-campaign-text">
                                ※他のクーポン (ガッチャモールクーポン、Tポイントクーポン、割引・値引きクーポン等) との併用はできません。<br />
                                ※クーポンご利用で該当商品お買上１点につき300Tポイント付与させていただきます。<br />
                                ※対象期間中、お一人様1回限りご利用できます。<br />
                                ※ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店において：<br />
                                ※Tポイントは、ご購入から3日以内に付与されます。<br />
                                ※お会計時に有効期限切れ等、Tカード番号が無効となっている場合は、ポイント付与ができませんのでご注意ください。<br />
                            </p>
                        </>
                    )}
                {cawachi && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            カワチ薬品<br />
                            ※一部商品の取り扱いのない店舗がございます。
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300円OFFクーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300円引きさせていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {kyorindo && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            杏林堂薬局<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300杏林堂ポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {fukutaro && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            くすりの福太郎<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ツルハグループポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {cf && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            ココカラファイン<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ココカラポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {satsudora && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            株式会社 サッポロドラッグストアー<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {Sundrug && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            サンドラッグ<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300円OFFクーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300円引きさせていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {tsuruha && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            ツルハドラッグ<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                            ドラッグストアウェルネス各店、ウォンツ各店、ドラッグイレブン各店は対象外です。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ツルハグループポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {bnD && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            B&D<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ツルハグループポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {mk && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>
                        <p className="digital-campaign-text">
                            マツモトキヨシ<br />
                            (＜対象外店舗＞マツモトキヨシ：沖縄県内のマツモトキヨシ,沖縄県のミドリ薬品,H&B place,Mediマツキヨ,Petit madoca野田みずき店,アウトレット店舗)<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300マツキヨポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                {lady && (
                    <>
                        <h2 className="digital-campaign-header">●対象店舗</h2>

                        <p className="digital-campaign-text">
                            くすりのレデイ　<br />
                            ※一部商品の取り扱いのない店舗がございます。<br />
                        </p>
                        <h2 className="digital-campaign-header">● クーポン種類</h2>
                        <p className="digital-campaign-text">
                            300ツルハグループポイント付与クーポン
                        </p>
                        <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                        <p className="digital-campaign-text">
                            ※他のクーポンとの併用はできません。<br />
                            ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                            ※対象期間中、お一人様1回限りご利用できます。<br />
                        </p>
                    </>
                )}
                <h2 className="digital-campaign-header"> ●クーポン対象商品</h2>
                <p className="digital-campaign-text">
                    ●クーポン対象商品<br />
                    ウィスパー１枚２役Wガード各種<br />
                    うすさら吸水 各種<br />
                    うすさら安心 各種<br />
                    うすさらエアリーシリーズ 各種<br />
                </p>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};
export default DigitalCampaignLP3Content;
