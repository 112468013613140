import React, { useState, useEffect } from 'react';
import style from './Commentstyle.scss';
import getComments from '../../adapters/api.adapter';
import PaginationActions from './PaginationAction';

const VideoComments = () => {
    const [commentsData, setCommentsData] = useState();
    const [initial, setInitial] = useState(0);


    const nextPage = (e) => {
        e.preventDefault();
        if (initial + 7 <= commentsData.length) {
            setInitial(initial + 6);
        }
    };
    const previousPage = (e) => {
        e.preventDefault();

        if (initial >= 6) {
            setInitial(initial - 6);
        }
    };
    const defaultPage = (e) => {
        e.preventDefault();
        setInitial(0);
    };
    
    return (
        <div className="VC-comments-container">
            {commentsData && commentsData.map((comment, i) =>

                initial <= i && i < initial + 6 && (

                    <div className="VC-Evencomments">
                        <div className="VC-comments-header">
                            <div className="topContent">
                                <div className="VC-comments-name">
                                    <label>Name</label>
                                    <div className="nickName">{comment && comment['Nick name']}</div>
                                </div>
                                <div className="VC-comments-age">
                                    <label>Age</label>
                                    <div className="ageCostomer">{comment && comment['Age selection']}</div>
                                </div>
                            </div>
                            <div className="VC-comments-body">
                                <p>{comment && comment['Comments ']}</p>
                            </div>
                        </div>
                    </div>
                ))}
            <div className="paginate">
                { commentsData && (
                    <PaginationActions
                        data={commentsData}
                        next={(e) => nextPage(e)}
                        previous={(e) => previousPage(e)}
                        defaultPage={(e) => defaultPage(e)}
                        activePage={Math.round(initial / 6) + 1}
                        previousButtonClass="previous"
                        nextButtonClass="next"
                        pageNumberClass="pageNumber"
                        breakClass="break"
                        containarClass="containar"
                        StartButtonClass="StartButtonClass"
                    />
                )}
            </div>
            <style jsx> {style} </style>
        </div>
    );
};
export default VideoComments;
