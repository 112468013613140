const preview = process.env.PREVIEW;
module.exports = `
... on ProductFeature {
  productFeatures: itemsCollection (limit: 15,preview: ${preview}) {
    items {
      nameEn
      title
      description1
      description2
      image {
        title
        description
        cloudinaryId
        asset{url}
      }
      note
    }
    }
}
`;
