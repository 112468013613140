import React, { useEffect, useState } from 'react';
import Style from './DigitalCampaignbarcode.scss';
import { storageGet } from '../../helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const DigitalCampaignbarcode = () => {
    const allRetailerBarcode = {
        sundrug: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/5IBS1aY5cJo8tnE61JZkFd/15e0c6c936d998a7ab23968158292455/Digital_Coupon_Sundrug.jpg',
            alt_text: 'クーポンNO 10021',
            productImage: '',
        },
        マツモトキヨシ: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/4vagJVsg77kYyVLYeY7MPq/a8745740cbb079e7243e830f5fba010c/MK_barcode.jpg',
            alt_text: 'マツモトキヨシ',
            productImage: '',

        },
        ココカラファイン: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/71RHvAJpJfUor8BoQCXIZf/9368403fe4421ff6632ed3ed86d380c1/cocokarafine_barcode.jpg',
            alt_text: 'ココカラファイン',
            productImage: '',

        },
        サンドラッグ: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/5IBS1aY5cJo8tnE61JZkFd/15e0c6c936d998a7ab23968158292455/Digital_Coupon_Sundrug.jpg',
            alt_text: 'サンドラッグ',
        },
        カワチ: {
            barcodeUrl: '',
            alt_text: ' カワチ',
        },
        ツルハドラッグ: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/7mruEe47rkd4de01B3wtzx/a71302ef8440effd130716149737ad09/Tsuruha_barcode_latest.jpg',
            alt_text: 'ツルハドラッグ',
        },
        くすりの福太郎: {
            barcodeUrl: 'https://assets.ctfassets.net/8g0fievzqg8k/qcRsQ5J04yJfhEHwClXlI/9eb72bf5251749d0b8a8c69d344cb682/fukutaro_barcode.bmp',
            alt_text: 'くすりの福太郎',
        },
        ウォンツ: {
            barcodeUrl: '',
            alt_text: 'ウォンツ',
        },
        ドラッグストアウェルネス: {
            barcodeUrl: '',
            alt_text: 'ドラッグストアウェルネス',
        },
        レディ: {
            barcodeUrl: 'https://assets.ctfassets.net/8g0fievzqg8k/18t2xKLOoteOmuIh9CaXwj/3cfa9489446c2ce611f045eaafa5ad61/lady_barcode.bmp',
            alt_text: 'レディ',
        },
        杏林堂: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/6p5E37Ms66avfvUDKH6M9j/d2b70cc88c5d8c42df599cf23743a168/kyorindo_barcode.jpg',
            alt_text: '杏林堂',
        },
        'B&Dドラッグストア': {
            barcodeUrl: 'https://assets.ctfassets.net/8g0fievzqg8k/4jvHEUEL3gl0kbjpQBmYBd/3dea39efa483f680e97dae0d0751f478/b-and-d-barcode.bmp',
            alt_text: 'B&Dドラッグストア',
        },
        サツドラ: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/3LBWnlmqhm0j2G7HfzkJjq/73ba16000c601daadb9727aac1b0878c/sapordrug_barcode.jpg',
            alt_text: 'サツドラ',
        },
        ウエルシア: {
            barcodeUrl: 'https://images.ctfassets.net/8g0fievzqg8k/OG4AShAZBEbGHvJfHd5XQ/4a97408afc37d0555f2170878c7a96bb/Welcia_barcode-latest.jpg',
            alt_text: 'ウエルシア',
        },
    };

    const kawachiProducts = [
        {
            id: 1,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/6WMQeCLN3E1zDn9fpWxvdp/d6ab08daa9a11f576f77da12b8ec3521/Anshin_20cc32pads_barcode1.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2mxBkfjGq2xVDkT1HqdCO1/fe418e83fbd63497be652b3fc16c9a25/Anshin_20cc32pads_pd1.jpg',
            alt: 'カワチ',
        },
        {
            id: 2,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/71exkigimZm2ejiuLkfLVL/3fcad5a4ce540ccaee48230fcbfab6a8/Anshin_45cc22pads_barcode2.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2mapWrfFG3lQX0yFvmBCm7/bb153d0417b33634b32ff97374f9c699/Anshin45cc22pads_pd2.jpg',
            alt: 'カワチ',
        },
        {
            id: 3,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/5SC6oI5HYRCc7zKv1BfCqU/0c617b2468e34751870aa27b8b29f2a4/Anshin_80cc20pads_59_barcode3.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/6lhujNHJL2kb8uqSRHh9RZ/4728371c1264f76d9539bf33afdb74c1/Anshin_80cc20pads_pd3.jpg',
            alt: 'カワチ',
        },
        {
            id: 4,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/6DwHNvaNVTRc8jcHfBXHuh/740d2a44f30a482722ca49b3267e298c/Anshin_80cc30pads_barcode4.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2oFlPTHUBgVtjyW67rxACg/3a4f18aff2b3f56ce907e8a701161b05/Anshin_80cc30pads_pd4.jpg',
            alt: 'カワチ',
        },
        {
            id: 5,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4Hv9xw9GmQG5jeJssYLuTR/e9ceea98b4e67f8a12ad32ad2309a16f/Anshin_120cc16pads_barcode5.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/67bq6MXUMl474nzXDaNFHp/c5ffccbfe28736e5176ec987b7bb75fd/Anshin_120cc16pads_pd5.jpg',
            alt: 'カワチ',
        },
        {
            id: 6,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/7kv5nrfwTfSQtLy5UH6Iaw/028d061adf8ef0ef1291adbfe761e1e2/Anshin_120cc24pads_barcode6.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/aUWnf5g2In5NGp5CHXX7U/9aba36b014ffb7c591d7e9509762cb94/Anshin_120cc24pads_pd6.jpg',
            alt: 'カワチ',
        },
        {
            id: 7,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/59Udds3DdxmERkAlbFHsm7/84c19f654f6589e6d5c1da9592fa8c0c/Anshin_170cc14pads_barcode7.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2ayBqq37m6dGbxyRb9xjfH/a3d5f5d1c4888c1e7ddf88e95c6e8cec/Anshin_170cc14pads_pc7.jpg',
            alt: 'カワチ',
        },
        {
            id: 8,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/ZwuxAkqrXmCkE3spNUmOS/9985dd58f0dbd1bf870f8e0da42a933e/Anshin_170cc22pads_barcode8.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/53iOBYgECIVVDLMWN2Je1a/c6f3afc536081baf9448d24424b86ba0/Anshin_170cc22pads_pc8.jpg',
            alt: 'カワチ',
        },
        {
            id: 9,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/1brSUH2sUkUFYzubbMT9gV/5cc5e76b5023e21368efbd0d86d15a6f/Anshin_220cc12pads_barcode9.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/LWgNw5aXPdsXOxmUXZ2Ju/fe634322add046cb155928bf8ad29d32/Anshin_220cc12_pc9.jpg',
            alt: 'カワチ',
        },
        {
            id: 10,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/3n0SVfTj2JEytDl9ds7U5f/5bc0a137aa84b758e4285d63a482ca23/Anshin_220cc18pads_barcode10.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2afwubFcUu8x7lmyyN6KmE/13f2442c287511077ffa8bdd9d1b5d7a/Anshin_220cc18pads_pd10.jpg',
            alt: 'カワチ',
        },
        {
            id: 11,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4wSCOjV7JU6kOzldPXlumH/19a5f3f20a083c09b51e0e5866b9270b/Anshin_30cc22pads_barcode11.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2WT9MobXDpLemzwcuQFBrs/af55ee70297bd8f3e3aa1b79e7e5119a/Kyusui_30cc22pads_pd11.jpg',
            alt: 'カワチ',
        },
        {
            id: 12,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/1vGzYRywyorxJa431u7Zuf/9b921bb8f705f5be3e3b6dc6a0114955/Anshin_30cc44pads_barcode12.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/5GMWMtyybBb9Etx3a7rE11/44d3cdfa7a7aa896217d151f2608094c/Kyusui_30cc44pads_pd12.jpg',
            alt: 'カワチ',
        },
        {
            id: 13,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/62Yy47Wcbozjuue5tAiq1b/97574608e7c8296a59a4aa410beea31b/Kyusui_50cc18pads_barcode13.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2qdcmiDjGiIhD0dEeDmfog/d7e7d0fb47ea7f7de35a4e8ee9a8b856/Kyusui_50cc18pads_pd13.jpg',
            alt: 'カワチ',
        },
        {
            id: 14,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/2nWbkj9Vl6DKPnxq8UwCzk/ad0aead87a81d2690aad7c2a5e1069b7/Kyusui_50cc38pads_barcode14.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/1fik3HFkp5eK3WerhUxevU/a3df5ccacb07a4e11b1b28698d194a88/Kyusui_50cc38pads_pd14.jpg',
            alt: 'カワチ',
        },
        {
            id: 15,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/6EI90n7U5Puw7PynZKjuCB/998e29cde81628e4123c609f51be6961/Kyusui_70cc15pads_barcode15.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/77MPCopvONLbCyCjWWBI0H/2fde871b488f67c53513bddfea230baa/Kyusui_70cc16pads_pd15.jpg',
            alt: 'カワチ',
        },
        {
            id: 16,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/68dRvs6naP3nrDd2zxfmtc/7f77e06e4be045772b316fb44fc30c4f/Kyusui_85cc16pads_barcode16.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2lIjy2r5UkWeGigPvcgs72/0485cd078a420f9469f2d145df037442/Kyusui_85cc16pads_pd16.jpg',
            alt: 'カワチ',
        },
        {
            id: 17,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/6KdxXVZvNprnsaQ2AV4SfT/bec1fa19bac8fcef23e6462ca2819347/Kyusui_85cc22pads_barcode17.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/6jBZZVGrl1n16YNLm6t6qL/ed22d59a96c6c52f8827e5d2b54ec6d1/Kyusui_85cc22pads_pd17.jpg',
            alt: 'カワチ',
        },
        {
            id: 18,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/6HRPRdj4tnWPDCga0LOh09/9e1f34cc6985a1f670e43d5cd50b751a/Kyusui_100cc14pads_barcode18.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/6YAKhN01aEZIZbzdwkYBPA/66c67655c085ba846cab8f5522e07b4f/Kyusui_100cc14_pd18.jpg',
            alt: 'カワチ',
        },
        {
            id: 19,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/7Lt1rLWzwSYlYPqZT8nn6V/c642b15e9ababe13c51cb128e97ec402/Kyusui_100cc20pads_barcode19.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/3QT8quqr5rd9s9XQA2mjWr/a7798d5489a83df12a5a477517afec81/Kyusui_100cc20pads_pd19.jpg',
            alt: 'カワチ',
        },
        {
            id: 20,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4ZYsuMbjZFQImkYsZg25f3/344a268e09a8af8366ff69fe2e3f0e19/Kyusui_150cc12pads_barcode20.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/4Re5spJ4kaDyP1DBQ96tPo/d2f00dff402e91ef06d7d46dc93bdfa3/Kyusui_150cc12pads_pd20.jpg',
            alt: 'カワチ',
        },
        {
            id: 21,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/5FbREotlTAkJLuGSzpAR5I/a751be5639d1d0cae6a41852b8ebf1b9/Wguard_3cc40pads_barcode21.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2koPTb6amLG1M4jNpVhz6K/fa595ca6f1c30350baff98b398d4baac/WG__3cc40pads_pd21.jpg',
            alt: 'カワチ',
        },
        {
            id: 22,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4ESW54tKDhVo4WQbdW8yff/e6178df2983c7b1dcdd6552515f3f120/Wguard_3cc80pads_barcode22.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/43tTrv5APRDAfu4ogEbmDd/51751e671dcf1695dd05257a764c851e/WG_3cc80pads_pd22.jpg',
            alt: 'カワチ',
        },
        {
            id: 23,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/3ylqm1Ai3EPrOqg5v17Ky2/9e91295bc8f05f7457e0cf3f7a82d08d/Wguard_10cc28pads_barcode23.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/3wdH62xv5XuQ6qg3ZGYrSv/b78a9aa96b10b1f2557638529afdde4f/WG_10cc28pads_pd23.jpg',
            alt: 'カワチ',
        },
        {
            id: 24,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/7BmiBuJ88iCuMFIPTtFhPP/057490962fa565abc63a9888fdf99470/Wguard_10cc52pads_barcode24.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/30sywtNpMUCO8gcaXee8Lg/b75f9d67b79f0c9772fd16f1f703c543/WG_10cc52pads_pd24.jpg',
            alt: 'カワチ',
        },
        {
            id: 25,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/3BfYnLBFvq9cLfK9iyKwD3/a55605280f2ff75a41919b4ee041b5d8/Wguard_15cc32pads_barcode25.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/46u6m5vTPsxLfMg8gtsyHy/d1553d259fb7505ddb883aecca718eda/WG_15cc32Pads_pd25.jpg',
            alt: 'カワチ',
        },
        {
            id: 26,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/2vba24Uw970sjIBP9KnECb/81849c8f47a0bb78a29bae670bbfcbe4/Wguard_15cc66pads_barcode26.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/3LnanTbHZzkxWauOP9pmUn/c77f87b05236a46b9182635edf53e9b9/WG_15cc66pads_pd26.jpg',
            alt: 'カワチ',
        },
        {
            id: 27,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/3whDyivzNvCnD46CwQci5A/29419ff4f481729602162b94b070556f/Airy_3cc40pads_barcode27.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/7LETW5MUfqcZL6cRbV00no/e6bd8411ee7dea585a996d4f03e06091/Airy_3cc40pads_pd27.jpg',
            alt: 'カワチ',
        },
        {
            id: 28,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4lbfxqcew7K16OeEMM8t7C/d9d4494f031abb09e6a1cbe42213b900/Airy_5cc32pads_bc_barcode_28.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/49gFbv23o1JJcaBsGBmgHc/739eb1377ff0b19a740040871bf07413/Airy_5cc32pads_pd28.jpg',
            alt: 'カワチ',
        },
        {
            id: 29,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/3mtlDOsmHP7e07h1eHMfy9/3999a9da60e57435d1d75eb3f689d44b/Airy_30cc22pads_bc_barcode29.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/3rU0GCtnx4LlXX1vovpU3v/2e657cdc208cfb1e226459a8bcaf1e1b/Airy_30cc22pads_pd29.jpg',
            alt: 'カワチ',
        },
        {
            id: 30,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/UnAn0iUf323zeqZh0aP4J/be7cf0b9c447e6b06f8e7c5b0d1c1210/Airy_50cc18pads_bc_barcode_30.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/5KGbCrv5GdQyUI29L6VFNy/11d4ac074985af9645c9e93ced7a91ec/Airy_50cc18pads_pd30.jpg',
            alt: 'カワチ',
        },
        {
            id: 31,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4xSX0QVXZpum5W3E858UMa/b2c533280a7afaa969ef798e98211b05/Silky_NB_SM_barcode31.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/67n8SVdSPc5ZGowMBQjmOI/5e845ed7cf0f1fb38b9d8c649d12daaf/Silky_NB_SM_pd31.jpg',
            alt: 'カワチ',
        },
        {
            id: 32,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/5EcCUTRswOQ129ThgISzTw/16abfd99b1d988eeb694fc23c96b1cd0/Silky_RO_SM_barcode32.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/3NYz7uAWvzoCsW7xfgxJkY/963afdfdbf1c613808654ccbce1767e9/Silky_ROSM_pd32.jpg',
            alt: 'カワチ',
        },
        {
            id: 33,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/4w8ktpKgQel9MMxGtmALrL/5f2fee83195fd84a7ac617de2c1b4f37/Silky_RO_L_barcode33.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/6BskAqi3jMI67ePvhQcKsV/87ae59a290ee88db5f7285c61da42542/Silky_ROL_pd33.jpg',
            alt: 'カワチ',
        },
        {
            id: 34,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/2wU11dMhbX5pJxXJHCDVuP/d9509d8b3f26122014dbc3c863d160b7/Silky_NB_L__barcode34.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/4YiIb0PTbmByDUN9OchhaA/0ed5a69369f6a347fd867b88c2487532/Silky_NB_L_pd34.jpg',
            alt: 'カワチ',
        },
        {
            id: 35,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/5xEvLvE90Iq1Z6oMzWLKXo/f9d4ff028db1d317b934776d87e75a8c/Silky_NB_SM_trial_barcode35.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/16tpaXNMgzlJiuoRajhYtg/767e35fc1541c288bf228636ad522ade/Silky_NB_SM_trial_pd35.jpg',
            alt: 'カワチ',
        },
        {
            id: 36,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/7gdw0AoyvfQehuIwb0Efar/dbb7df726ee5dae6b538ac40dc299d3e/Silky_NB_L_trial_barcode36.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/40JBtWH9s5Dxsh5QhHCFJO/74cd0c7b50c3cb07472235a4280fcca4/Silky_NB_L_tril_pd36.jpg',
            alt: 'カワチ',
        },
        {
            id: 37,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/5PvZqq8NMAm4oVAiPU2GT9/3e35b310ee89a977bb2a949be61c72c7/Silky_RO_SM_trial_barcode37.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/7yNYqAVnf8Idc52ljI7t61/2a37b968ae7fe2545bd0043a27f8464d/Silky_RO_SM_trial_pd37.jpg',
            alt: 'カワチ',
        },
        {
            id: 38,
            barCode: 'https://images.ctfassets.net/8g0fievzqg8k/ZcrJ85AmOaS5BdoYsStHa/616cebf78a7f48fce55f2c5b698ea38b/Silky_RO_L_trial_barcode38.jpg',
            productimage: 'https://images.ctfassets.net/8g0fievzqg8k/2V2M2WB5vDTPYmsp47WQtk/d6b72b5cf4e6a1958e3c2996cb9d1a83/Silky_RO_L_triql_pd38.jpg',
            alt: 'カワチ',
        },

    ];

    const [retailer, setRetailer] = useState('');
    const [barcodeImg, setBarcodeImg] = useState('');
    const [barcodeAlt, setBarcodeAlt] = useState('');

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    useEffect(() => {
        const retailerName = storageGet('digitalRetailer');
        setRetailer(retailerName);
        console.log(allRetailerBarcode[retailerName]);
        if (retailerName !== undefined && allRetailerBarcode[retailerName] !== undefined) {
            setBarcodeImg((allRetailerBarcode[retailerName])['barcodeUrl']);
            setBarcodeAlt((allRetailerBarcode[retailerName])['alt_text']);
        }
        if (retailerName === 'sundrug') {
            setGATagsAndEvents('event_coupon_request', 'whisper_coupon_request_swingpop_sundrug', 'event_coupon_action');
        }
    }, []);

    return (
        <>
            <div className="digital-campaign-barcode">
                {retailer && retailer === 'カワチ'
                    ? (
                        <>
                            <p className="kawachi-info">※お会計時にレジにて対象商品と、お買い求めの商品のクーポンをご提示ください。
                                クーポンバーコードは商品ごとになっております。
                            </p>
                            { kawachiProducts.map((product, key) => (
                                <div className="barcodewithimage" key={key}>
                                    <div className=" product">
                                        <img src={buildContentfullImageUrl(product.productimage)} alt={barcodeAlt} height="auto" width="auto" />

                                    </div>
                                    <div className="barcode">
                                        <img src={buildContentfullImageUrl(product.barCode)} alt={barcodeAlt} height="auto" width="auto" />

                                    </div>
                                </div>
                            )) }
                        </>
                    )
                    : (
                        <div className="barcode-img">
                            <img src={buildContentfullImageUrl(barcodeImg)} alt={barcodeAlt} height="auto" width="auto" />
                        </div>
                    )}
                <div className="digital-campaign-barcode-button">
                </div>
                <p className="digital-campaign-barcode-txt"> ※クーポンは1回のご使用に限ります。</p>
                <style jsx>{Style}</style>
            </div>
        </>
    );
};

export default DigitalCampaignbarcode;
