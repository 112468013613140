module.exports =  `
... on ContentTypeRichText{
    name
    body
    advancedText {
        json
        links {
          entries {
            inline {
              sys {
                id
              }
                
              __typename
            }
            block {
              sys {
                id
              }
              ... on CloudinaryImage {
                __typename
                title
                cloudinaryId
                asset{url}
               }
              __typename
            }
          }
        }
      }
    styles
}

`;
