import React from 'react';
import Head from 'next/head';
import useScreenSize from '../Hooks/useScreenSize';
import Style from '../ReviewCampaign/ReviewCampaignLP.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ReviewCampaignLPBanner = () => {
    let resolution = useScreenSize();

    if (!resolution) {
        resolution = { isMobile: true };
    }
    const mobileImg = 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/97408a9ea3e4c41e4f99199276ec577a/mv_SP.png';
    const desktopImg = 'https://images.ctfassets.net/8g0fievzqg8k/3C6OxYIENo7391LDvHigIK/afcb7e50f4715186adebc9eef947683a/mv_PC.png';
    const banner = resolution.isMobile ? mobileImg : desktopImg;

    return (
        <>
            <Head>
                {<link rel="preload" as="image" href={buildContentfullImageUrl(banner)} />}
            </Head>
            <div className="review-campaign-lp">
                <div className="header-section"><div className="leftdiv"><span>-</span></div><div className="middlediv"><h1>【ウィスパー公式サイト】</h1></div><div className="rightdiv"><span>-</span></div></div>
                <div className="banner-image-section">
                    <div className="banner-image">
                        <img src={buildContentfullImageUrl(banner)} alt="ウィスパーを1個お買い上げごとにツルハグループポイント50ポイントプレゼント！" />
                    </div>
                </div>
                <style jsx>{Style}</style>
            </div>
        </>
    );
};

export default ReviewCampaignLPBanner;
