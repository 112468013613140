import React from 'react';
import PropTypes from 'prop-types';
import Image from '../CloudinaryImage/CloudinaryImage';
import useResolution from '../Hooks/useScreenSize';
import Style from './RetailersDropdown.scss';

const DropdownContent = ({ className, retailersCollection }) => {
    const resolution = useResolution();
    if (!resolution) return null;

    return (
        <div className={className}>
            {retailersCollection.items && retailersCollection.items.map((retailer, i) => {
                const image = resolution.isMobile ? retailer.imageSp : retailer.imageDt;
                const isHomePage = typeof window !== 'undefined' && window.location.pathname === '/';

                return isHomePage
                    ? (
                        <div className=" home-retailer-dropdown">
                            <a
                                className="event_outbound_link event_buy_now_eretailer_exit home-retailer"
                                data-action-detail={retailer.link.url}
                                href={retailer.link.url}
                                target={(retailer.link.newWindow) ? '_blank' : ''}
                                key={i}
                                rel="noreferrer"
                            >
                                <Image image={image} />
                            </a>
                        </div>
                    )
                    : (
                        <a
                            className="event_outbound_link event_buy_now_eretailer_exit"
                            data-action-detail={retailer.link.url}
                            href={retailer.link.url}
                            target={(retailer.link.newWindow) ? '_blank' : ''}
                            key={i}
                            rel="noreferrer"
                        >
                            <Image image={image} />
                        </a>
                    );
            })}
            <style jsx>{Style}</style>
        </div>
    );
};

DropdownContent.propTypes = {
    className: PropTypes.string,
    retailersCollection: PropTypes.object,
};

export default DropdownContent;
