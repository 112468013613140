import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.experts-voice {
    clear: both;
    display: flex;
    flex-direction: column;
    
    @media only screen and (max-width: $breakpoint-tb-max) {
        padding: 0 15px;
    }

    .top-line {
        overflow: hidden;

        img {
            padding-bottom: 40px;
        }
    }

    .bottom-line {
        overflow: hidden;

        img {
            padding: 30px 0 50px;
        }
    }

    .title-block {
        img {
            float: left;
            margin-right: 5px;
            width: 290px;
            order: 2;
        }

        h2,
        .subtitle h3,
        .subtitle h4 {
            float: left;
            width: calc(98% - 290px);

            @media only screen and (max-width: $breakpoint-max) {
                width: 100%;
            }
        }

        h2 {
            display: block;
            color: $purple;
            font-size: 22px;
            font-weight: 500;
            line-height: 26px;
            margin: 10px 0 30px;
            padding: 0;
            order: 1;
            text-indent: 0;
            @media only screen and (max-width: 768px) {
                margin: 10px 0 50px;
            }
        }

        .subtitle {
            order: 3;

            h3 {
                font-family: "Noto Serif JP", serif;
                font-size: 10px;
                font-weight: normal;
                line-height: 15px;
                margin: 0;
            }

            h4 {
                font-family: "Noto Serif JP", serif;
                font-size: 22px;
                font-weight: normal;
                font-weight: 200;
                margin: 0;
            }
        }

        @media only screen and (max-width: $breakpoint-max) {
            display: flex;
            flex-direction: column;

            img {
                order: 2;
            }

            h2 {
                order: 1;
                width: 100%;
            }

            .subtitle {
                width: 100%;
                order: 3;
                margin-top: -50px;           
            }
        }
    }

    :global(h2) {
        color: #A61757;
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 42px;
        padding-left: 100px;

        @media only screen and (max-width: 700px) {
            margin-left: 22px;
            text-indent: -22px;
        }
    }

    :global(p) {
        text-indent: 22px;
        color: $text-article;
        font-size: 22px;
        line-height: 42px;
        margin: 0;
        padding-left: 100px;
    }

    @media only screen and (max-width: $breakpoint-max) {

        .top-line {
            img {
                padding: 30px 0 10px;
            }
        }

        :global(p),
        :global(h2) {
            padding-left: 0;
            font-size: 18px;
            line-height: 28px;
        }
    }

    
}`;

export default styles;