import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.news-banner {
    width: 100%;
    float: left;
    margin: 0 auto;
    position: relative;
    padding: 0px 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #ccc;
    .dt {
        font-family: "Noto Sans JP";
        float: left;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        div {
            padding: 3px 25px;
            color: #fff;
            width: 160px;
            text-align: center;
        }
        .pink {
            background: #df1683;
        }
        .purple {
            background: #8347ad;
        }
        .indigo {
            background: #e79d2e;
        }
        .description {
            font-size: 14px;
            margin: 0 20px;
            width: auto;
            color: #000;
            padding: 0;
        }
        a {
            text-decoration: none;
            color: #000;
            font-size: 20px;
            line-height: 14px;
        }
    }
    @media (max-width: $breakpoint-max) {
        padding: 0 20px;
        border: none;
        margin: 0 0 20px 0;
        .dt {
            display: block;
            border-bottom: 1px solid #ccc;
            div {
                width: 160px;
                float: left;
            }
            .pink,
            .indigo,
            .purple {
                padding: 5px 25px;
            }
            .description {
                font-size: 16px;
                margin: 5px 15px;
                float: left;
            }
            a {
                width: 100%;
                float: left;
                margin: 20px auto;
                line-height: normal;
            }
        }
    }
}
`;

export default styles;