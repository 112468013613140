import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import './styles/global-variables';

.error-404 {
    margin-bottom: 70px;
    margin-top: 70px;
    text-align: center;
    width: $desktop-elements-width;

    @media (max-width: $breakpoint-max) {
        margin-bottom: 60px;
        width: 100%;
    }

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        max-width: 100%;
    }

    h1 {
        font-size: 100px;
        margin-bottom: 0;

        @media (max-width: $breakpoint-max) {
            font-size: 60px;
        }
    }

    h2 {
        color: $text-article;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 42px;
        margin: 0 auto;

        @media (max-width: $breakpoint-max) {
            font-size: 14px;
            line-height: 30px;
            max-width: 80%;
        }

        a {
            color: $purple;
            text-decoration: underline;
        }
    }

    h3 {
        font-size: 20px;
        margin-bottom: 60px;
        margin-top: 0;
        text-transform: uppercase;

        @media (max-width: $breakpoint-max) {
            margin-bottom: 40px;
        }
    }

    h1,
    h3 {
        color: $purple-dark;
        font-family: 'Open Sans', sans-serif;
    }

    .search {
        margin: 0 auto;
        margin-top: 60px;
        max-width: 55%;
        position: relative;

        @media (max-width: $breakpoint-max) {
            margin-top: 40px;
            max-width: 80%;
        }

        input {
            border: 1px solid $off-black;
            height: 50px;
            padding: 5px;
            width: 100%;

            @media (max-width: $breakpoint-max) {
                height: 40px;
            }
        }

        .search-icon {
            cursor: pointer;
            display: inline;
            position: absolute;
            right: 0;
            top: 4px;

            @media (max-width: $breakpoint-max) {
                top: -5px;
            }
        }
    }
}
`;

export default styles;