import React from 'react';
import PropTypes from 'prop-types';
import Image from '../CloudinaryImage/CloudinaryImage';
import useScreenSize from '../Hooks/useScreenSize';

import Style from './RelatedInterviews.scss';

const RelatedInterviews = (props) => {
    const { interviews } = props;

    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    return (
        <div className="related-interviews">
            <h2>関連する記 事</h2>
            <div className="interviews">
                {interviews.items.map((interview, index) => {
                    const { heading, heading1, heading2, featuredImage, featuredImageNumber, page } = interview;
                    return (
                        <div className="interview-card" key={index}>
                            <a href={page.seoHead.url}>
                                <Image image={resolution.isMobile ? featuredImageNumber : featuredImage} />
                                <h4>{heading}</h4>
                                <h3>{heading1}</h3>
                                <h3>{heading2}</h3>
                            </a>
                        </div>
                    );
                })}

            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

RelatedInterviews.propTypes = {
    interviews: PropTypes.object,
};

export default RelatedInterviews;
