import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.mobile-menu {
  font-family: "M PLUS Rounded 1c", sans-serif;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;

  .floating-menu {
    .menu-items {
      display: flex;
      z-index: 9999;
      justify-content: flex-end;

      .MenuButton {
        .hamburger-menu-cross {
          margin-right: 40px;
          font-size: 60px;
          color: $purple-darker;

          @media (max-width: $breakpoint-tb-min) {
            margin-right: 20px;
          }
        }

        background-color: transparent;
        cursor: pointer;
        border: none;
        background-position: 0 -15px;

        &.active {
          background-image: url("https://images.ctfassets.net/8g0fievzqg8k/7wi0DgzgRyC0tI0RBtHvLf/1af82611d2c4083ee9c6f74664cfa4e7/footers-01-2.webp");
          background-repeat: no-repeat;
          background-size: cover;
          background-color: transparent;
          border: none;
          background-position: 0 -15px;

          .title {
            color: $purple;
          }
        }

        img {
          height: 50px;
          margin: 20px;
        }
      }

      .ProductButton {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/1sp0I9szg7H7Lms6SJORcT/0bc12b06207392fe91ce416f5eb6d3ea/footers-02.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        border: none;
        background-position: 0 -15px;

        &.active {
          background-image: url("https://images.ctfassets.net/8g0fievzqg8k/yqbJWDlpS3Yu6vfI71sKV/9a85462c8a50dd612b15032354e99c93/footers-02-2.webp");
          background-repeat: no-repeat;
          background-size: cover;
          background-color: transparent;
          border: none;
          background-position: 0 -15px;

          .title {
            color: $purple;
          }
        }
      }

      .PickUpButton {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/2tvSdRm5Pv9GdXjGI2OssF/cd4c5b5d6964883776786841255d510f/footers-03.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        border: none;
        background-position: 0 -15px;

        &.active {
          background-image: url("https://images.ctfassets.net/8g0fievzqg8k/KUioSfm14k1hBdB4xd0zS/d4b1578124b2f989dd1c7b852fbeb545/footers-03-2.webp");
          background-repeat: no-repeat;
          background-size: cover;
          background-color: transparent;
          border: none;
          background-position: 0 -15px;

          .title {
            color: $purple;
          }
        }
      }

      .RetailerButton {
        background-image: url("https://images.ctfassets.net/8g0fievzqg8k/Vmk6XUwOGmUjPb3Z3uS5m/17925b65521e952db64eb0ed507e9d2d/footers-04.webp");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        border: none;
        background-position: 0 -15px;

        &.active {
          background-image: url("https://images.ctfassets.net/8g0fievzqg8k/4x8I9pw7Hf2mGKakxzHuf2/602c0871cd59a7f45d6aea500e22566d/footers-04-2.webp");
          background-repeat: no-repeat;
          background-size: cover;
          background-color: transparent;
          border: none;
          background-position: 0 -15px;

          .title {
            color: $purple;
          }
        }
      }

      button {
        all: unset;
        width: auto;
        height: 100px;
        position: relative;

        .title {
          width: 100%;
          font-size: 13px;
          text-align: center;
          position: absolute;
          bottom: 3px;
          left: 0;
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  &.outer {
    &>li {
      border-bottom: 1px solid $purple-dark;
      box-shadow: 25px 0 20px -15px inset rgba(black, 0.5);
      position: relative;
    }

    li {
      &.opener {
        &::after {
          content: unset;
        }

        span {
          position: relative;

          &::after {
            border: 1px solid $purple-dark;
            border-width: 2px 2px 0 0;
            content: "";
            height: 10px;
            margin-left: 5px;
            position: absolute;
            top: 6px;
            transform: rotate(135deg);
            width: 10px;
          }
        }

        &.isOpen {
          & span {
            &::after {
              transform: rotate(-45deg);
            }
          }

          ul {
            &.inner {
              height: auto;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  h2 {
    a {
      &::after {
        display: none;
      }
    }
  }

  .header {
    display: flex;
    height: auto;
    justify-content: space-between;
    padding-top: 20px;
    position: absolute;
    z-index: 101;
    padding: 4%;
    margin: 0;

    .mobile-header-logo {
      height: auto;

      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    @media (max-width: 320px) {
      padding: 2%;
    }

    h2 {
      margin: -8px 0.5rem 0;
    }

    @media (max-width: $breakpoint-max-md-ipad) {
      top: 20px;
    }
  }

  .header {

    // for h1 tag in header
    h1 {
      position: relative;
      margin: 0;
      line-height: 0 !important;

      span {
        border: 0 !important;
        clip: rect(0, 0, 0, 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
      }
    }
  }

  .menu {
    background-color: aquamarine;
    font-size: 18px;
    position: absolute;
    top: 100px;
    height: 1000px;
    z-index: 100;
    width: 100%;

    &.isOpen {
      flex: 1;
      opacity: 1;
      transition: width 250ms, opacity 500ms;
      width: 100%;
      background-color: $light-purple-bg;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: $breakpoint-max) {
        overflow-y: scroll;
        height: 730px;
      }

      .pop-btn {
        background-color: $purple-darker;
        color: #fff;
        margin: 20px auto;
        display: flex;
        padding: 12px 40px;
        border-radius: 56px;
        border: 1px solid $purple-darker;
        font-size: 16px;

        .mobile-popup-btn-arrow {
          position: relative;
          right: -23px;
          font-size: 16px;
        }
      }

      .retailer-popup-backdrop {
        width: 100%;
        margin: auto;
        text-align: center;
        background-color: #fff;
        height: 100%;
        overflow-y: scroll;

        .image-btn img {
          width: 300px;
        }
      }

      .product-feature-mobile {
        .header-li-underline {
          border-bottom: 2px solid $purple-dark;
          padding: 15px 0 20px;

          &:hover {
            text-decoration: none !important;
          }
        }

        ul.header-submenu-ul {
            li {
              a {
                font-size: 16px;
              }
            }
        }

        a {
          font-size: 20px;
          font-weight: 600;
          color: $text-black;
          font-family: "Noto Sans JP";
          display: flex;
          justify-content: space-between;

          span {
            background-color: $purple-darker;
            color: #fff;
            border-radius: 57%;
            padding: 0px 14px;
            font-size: 18px;
            font-weight: 600;
            height: 40px;
          }
        }

        ul {
          li {
            border-bottom: 1px solid $purple-darker;

            a {
              padding: 15px 0 0 30px;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      .product-feature-mobile:last-child {
        border: none;
      }

      .mobile-login-logo {
        @media (max-width: $breakpoint-tb-min) {
          margin-bottom: 30px;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        color: $purple-dark;
        font-size: 16px;
        line-height: 39px;
        padding: 0 20px;

        @media (max-width: 320px) {
          font-size: 15px;
        }

        ul {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            span {
              background-color: $purple-darker;
              color: #fff;
              border-radius: 57%;
              padding: 0px 14px;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }

      &.inner {
        height: 0;
        margin-left: -20px;
        opacity: 0;
        transition: opacity 0.75s;
        visibility: hidden;

        li {
          background-color: rgba($purple-dark, 0.1);
          border-top: 1px solid $purple-dark;
          width: calc(100% + 20px);
        }
      }

      &.outer {
        width: 100%;
        font-family: "Noto Sans JP";

        &>li {
          border-bottom: 2px solid $purple-dark;
          width: 93%;
          box-shadow: none;
          position: relative;
          padding: 20px 0;
          margin: auto;

          &:first-child {
            padding-top: 20px;
          }
        }

        li {
          &.opener {
            &::after {
              content: unset;
            }

            span {
              position: relative;

              &::after {
                border: 1px solid $purple-dark;
                border-width: 2px 2px 0 0;
                content: "";
                height: 10px;
                margin-left: 5px;
                position: absolute;
                top: 6px;
                transform: rotate(135deg);
                width: 10px;
              }
            }

            &.isOpen {
              & span {
                &::after {
                  transform: rotate(-45deg);
                }
              }

              ul {
                &.inner {
                  height: auto;
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        @media (max-width: 450px) {
          font-size: 16px;
        }

        @media (max-width: 375px) {
          font-size: 14px;
        }
      }
    }
  }
}`;

export default styles;