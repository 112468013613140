import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Style from './ArticleStep.scss';
import useScreenSize from '../Hooks/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ArticleStep = ({ title, titleMobile, text, image1Dt, image1Sp, image2Dt, image2Sp }) => {
    const resolution = useScreenSize();
    const image1 = resolution && resolution.width <= 991 ? image1Sp : image1Dt;
    const image2 = resolution && resolution.width <= 991 ? image2Sp : image2Dt;
    const titleFormated = resolution && resolution.width < 768 ? titleMobile : title;

    const isTablet = !!(resolution && resolution.width <= 991);

    return (
        <div className="article-step">
            <h2>
                <ReactMarkdown
                    source={titleFormated && titleFormated}
                    escapeHtml={false}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                />
            </h2>
            <p>
                {!isTablet && image1 && <img className="image-1" src={buildContentfullImageUrl(getImageUrl(image1.asset.url))} alt={image1.title} />}
                <ReactMarkdown
                    source={text && text}
                    escapeHtml={false}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                />
                {isTablet && image1 && <img className="image-1" src={buildContentfullImageUrl(getImageUrl(image1.asset.url))} alt={image1.title} />}
            </p>
            {image2 && <img className="image-2" src={buildContentfullImageUrl(getImageUrl(image2.asset.url))} alt={image2.title} />}
            <style jsx>{Style}</style>
        </div>
    );
};

ArticleStep.propTypes = {
    title: PropTypes.string,
    titleMobile: PropTypes.string,
    text: PropTypes.string,
    image1Dt: PropTypes.object,
    image1Sp: PropTypes.object,
    image2Dt: PropTypes.object,
    image2Sp: PropTypes.object,
};

export default ArticleStep;
