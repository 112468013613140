import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
// img{
//     width: 100%
// }
    .banner-image-section{
        position: relative;
        height: 100%;
        @media only screen and (min-width: 991px){
            width:940px;margin:auto;
        }
        .banner-image{
            img{
            width: 100%;
            }
        }
        .image-text
        {
            position: absolute;
            top: 148px;
            right: 0;
            bottom: 76px;
            font-size: 50px;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            font-weight: 700;
            width: 460px;
            text-indent: 130px; 
            color:$purple-dark;
            .idbind-bannertext {position: relative;right: 60px;}
           }
           @media (max-width: $breakpoint-max){
                position: relative;
                width: 100%;
                float: left;
                margin: 40px 0 0 0;
                .image-text {
                right: auto;
                bottom: 0;
                font-size: 23px;            
                width:300px;
                text-indent: 0;
                left:32%;  
                top: 80%;
                @media only screen and (max-width: 450px){
                    top: 75%;
                }
                .idbind-bannertext {
                    position: relative;right: auto;
                }           
            }
        }
   }
  

`;

export default styles;