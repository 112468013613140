import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Container } from 'reactstrap/lib';
import Loader from 'react-loader-spinner';
import { getApiFormatter } from '../../adapters/contentful.adapter';
import Qnumber from './qnumber';
import DisplayQuestions from './DisplayQuestions';
import ResultPage from './ResultPage';
import prodStyles from './productdiagnosis.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import {questionnaireJSON} from './questionnaire.constant'

const ProductDiagnosisRecommendation = (props) => {
    const [currentScreen, setScreen] = useState(1);
    const questionref = createRef();
    const { resolution, resultPageCollection } = props;
    const [Data, setQuestions] = useState({});
    const [savedResponse, setSavedResponse] = useState([]);
    const [visitedScreen, setVisited] = useState(1);
    const [answers, setAnswers] = useState([
        {
            product_used: '',
            satisfaction_level: '',
            bladder_leakage: '',
            stress_level: '',
            urge_level: '',
        },
    ]);
    const [products, setProducts] = useState();
    const [showError, setError] = useState(false);

    useEffect(() => {
        setQuestions(JSON.parse(questionnaireJSON));
       
    }, []);

    function selectAnswer(key, val) {
        const newanswers = answers[0];
        newanswers[key] = val;
        const ans = [];
        ans.push(newanswers);
        console.log(ans, 'key');

        setAnswers(ans);
        setError(false);
    }
    const getProductDetails = () => {
        axios
            .post(
                process.env.MLAPI_URL,
                { Questions: [answers[0]] },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Ocp-Apim-Subscription-Key': process.env.MLAPI_OCP_APIM_SUBSCRIPTION_KEY,
                    },
                },
            )
            .then((res) => {
                setProducts(res.data);
                
                const setSavedData = Object.values(res.data).map((val)=>{
                    return {
                        EANCode:val.ProductGTINs
                    }
                })
                
                setSavedResponse(setSavedData);

            })
            .catch((err) => {
                console.log(err);
            });
    };
    const dtQuestionCard = [
        'https://images.ctfassets.net/8g0fievzqg8k/1j3halkSiGpJpJHn9MKVSx/a45d51e307267dba6fa1f74205e919ca/whisper_20fall_check_pc-17.png',
        'https://images.ctfassets.net/8g0fievzqg8k/1xQZ4OYbnPlJoaqcBXQLvd/f545fb3a52b3b957a1b06a08df880591/whisper_20fall_check_pc-18.png',
        'https://images.ctfassets.net/8g0fievzqg8k/59UE7zrxns4AmwweMsFYHP/b56e226b601ae9d1e67a7c74f73e9fdb/whisper_20fall_check_pc-19.png',
        'https://images.ctfassets.net/8g0fievzqg8k/4WHDWcSlS86eg8b7E1ialC/0d9c96591ff396bea2dd763116dcaecb/whisper_20fall_check_pc-20.png',
        'https://images.ctfassets.net/8g0fievzqg8k/2FE4aJkYpSP5MbLZy9axnP/cedb882bdfbfa290d0918f5b38fd5028/whisper_20fall_check_pc-21.png',
    ];
    const spQuestionCard = [
        'https://images.ctfassets.net/8g0fievzqg8k/43OVNpkZB49FI7QGwNKlJ2/c9b75b684bac00e07480bb4e110e3c68/whisper_20fall_check_sp-17_W768xH355__1_.png',
        'https://images.ctfassets.net/8g0fievzqg8k/2VXduuMQvMd91w2pgrRm2K/edde1bcae0d0e45abcf6924a73fd2464/whisper_20fall_check_sp-18_W768xH355.png',
        'https://images.ctfassets.net/8g0fievzqg8k/a1flIl3lO7xjWqF9IbtcA/4633bd7924295b98362278e3cad21983/whisper_20fall_check_sp-19_W768xH355.png',
        'https://images.ctfassets.net/8g0fievzqg8k/7pBc5lsfsxHuXmI93sukNt/7f5a06548cc0194829c16fbe69c5d4de/whisper_20fall_check_sp-20_W768xH355.png',
        'https://images.ctfassets.net/8g0fievzqg8k/RaPTtD32qAwjadiL51tGw/028a3a9c1850d158ded9b9148e12c073/whisper_20fall_check_sp-21_W768xH355.png',
    ];
    const setImage = (resolut) => (resolut.isMobile
        ? <img alt="img1" src={buildContentfullImageUrl(spQuestionCard[currentScreen - 1])} />
        : <img alt="img2" src={buildContentfullImageUrl(dtQuestionCard[currentScreen - 1])} />);

    return (
        <div className="prod-main-container">
            <Container className={savedResponse.length ? 'Result-product-container' : ''}>
                {currentScreen === 6
                    ? (
                        savedResponse.length ? (
                            <div className="result-container">
                                <ResultPage
                                    setScreen={setScreen}
                                    savedResponse={savedResponse}
                                    products={products}
                                    resolution={resolution}
                                    resultPageCollection={resultPageCollection}
                                />
                            </div>
                        ) : (
                            <div className="loader-part">
                                <Loader
                                    type="TailSpin"
                                    color="#8347ad"
                                    className="loader"
                                    height={96}
                                    width={96}
                                    visible={true}
                                />
                            </div>

                        )
                    )
                    : (
                        <>
                            <div className="questions-row">
                                <Qnumber screen={currentScreen} ref={questionref} />
                                <div className="questions-row-image">
                                    {setImage(resolution)}
                                </div>
                            </div>
                            {Object.keys(Data).length > 0 ? (
                                <div className="product-box">
                                    <div className="questions">
                                        <h2 className="heading">
                                            {(Data[currentScreen] && Data[currentScreen].heading) || ''}
                                        </h2>
                                        <div className="ques">
                                            <h2>{Data[currentScreen].question}</h2>
                                            {currentScreen === 5 ? (
                                                <div><h2>{Data[currentScreen].question1}</h2></div>
                                            ) : (
                                                <h2>{Data[currentScreen].question1}</h2>
                                            )}
                                        </div>
                                    </div>

                                    <DisplayQuestions
                                        currentScreen={currentScreen}
                                        Data={Data}
                                        answers={answers[0]}
                                        selectAnswer={selectAnswer}
                                    />

                                    {currentScreen !== 5 ? (
                                        <button
                                            type="button"
                                            data-action-detail={`productrecommender_next${currentScreen}`}
                                            onClick={() => {
                                                if (answers[0][Data[currentScreen]['apikey']] !== '') {
                                                    setScreen(currentScreen + 1);
                                                    setVisited(currentScreen + 1);
                                                } else {
                                                    setError(true);
                                                }
                                            }}
                                            className="event_button_click next"
                                        >
                                            次の質問へ
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            data-action-detail={`productrecommender_next${currentScreen}`}
                                            onClick={() => {
                                                if (answers[0][Data[currentScreen]['apikey']] !== '') {
                                                    setScreen(currentScreen + 1);
                                                    setVisited(currentScreen + 1);
                                                    getProductDetails();
                                                } else {
                                                    setError(true);
                                                }
                                            }}
                                            className="event_button_click next"
                                        >
                                            結果を見る
                                        </button>
                                    )}
                                    {!(visitedScreen > currentScreen) && showError && (
                                        <p className="surveyError">
                                            質問に答えてから「次へ」を押してください。
                                        </p>
                                    )}
                                    {currentScreen !== 1 && (
                                        <button
                                            type="button"
                                            data-action-detail={`previous_from${currentScreen}`}
                                            onClick={() => currentScreen !== 1 && setScreen(currentScreen - 1)}
                                            className="event_button_click previous"
                                        >
                                            {'\u2190'}  戻る
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className="loader-part">
                                    <Loader
                                        type="TailSpin"
                                        color="#8347ad"
                                        className="loader"
                                        height={96}
                                        width={96}
                                        visible={true}
                                    />
                                </div>
                            )}
                        </>
                    )}
            </Container>
            {/* <style jsx>{Styles}</style> */}
            <style jsx>{prodStyles}</style>
        </div>

    );
};

ProductDiagnosisRecommendation.propTypes = {
    resolution: PropTypes.object,
    resultPageCollection: PropTypes.object,
};
export default ProductDiagnosisRecommendation;
