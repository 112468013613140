import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";



.id-binding-main-section4{
    width:100%;
    width: 100%;
    float: left;
    position: relative;
    .id-binding-userlog-status{
        font-size: 21px;
        width: 100%;
    }
    .main-page-section4{
        display: flex;
        align-items: center;
        
        .left-Image{
            width: 15%;
            float: left;
            height:77px;
            position: absolute;
            top: -30px;
            img{
                width:100%;
            }
            .section4-text{
                background-image: none;
            }
        }
        .middle-text{
            
            p{
                font-family: 'M PLUS Rounded 1c',sans-serif;
                color: #592C82;
                font-size: 21px;
                line-height:24px; 
                text-align: center;
                font-weight: 500;
                margin-left: 20px;
            }
          
        }
       
        .right-image{
            width: 10%;
            height: 77px;
            position: absolute;
            right: 0;
            top: -40px;
            img{
                width:100%;
            }
        }
        .section4-text{
            background-image: url(/static/images/id-bind-text-bg.png);
            background-repeat: no-repeat;
            height: 77px;
            width: 200px;
            background-size: contain;
            position: relative;
            P{
                font-family: 'M PLUS Rounded 1c',sans-serif;
                color:#fff;
                font-size: 24px;
                line-height:24px; 
                font-weight: 500;
                position: absolute;
                top: 40%;
                left: 50%;
                -webkit-transform: translate(-50%,-50%);
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                width: 100%;
                text-align:center;
            }
        }
        .left-logo-section + .middle-text{
            display:none;
        }

        .left-logo-section{
            width: 100%;
            float: left;
            display: flex;
            align-items: center;
            .left-txt{
                font-family: 'M PLUS Rounded 1c',sans-serif;
                color: #592C82;
                font-size: 30px;
                line-height:24px; 
                text-align: center;
                font-weight: 500;
                margin: 0 auto;
            }
        }
    }

    @media (max-width: $breakpoint-max) {
         .main-page-section4{
            display: block;
            text-align: center;
            width:100%;
            float: left;
            position: relative;
            
            .left-Image{
                width: 20%;
               position: absolute;
               top:-14px;
                img{
                    width:100%;
                }
                .section4-text{
                    background-image: none;
                }
            }
            .middle-text{
                
                p{
                    font-size: 20px;
                    line-height: 10px;
                    position: absolute;
                    top: 50% !important;
                    margin: 0 auto;
                    width: 100%;
                    color:#fff;
                    
                }
              
            }
           
            .right-image{
                width: 30%;
                height: 77px;
                position: static;
               float:right;
               text-align:right;
                img{
                    width:100%;
                }
            }
            .section4-text{
                height: 150px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                float: left;
                background-position: center;
                P{
                    font-size: 16px;
                    line-height:16px; 
                                  
                }
            }
            .left-logo-section + .middle-text{
                display:none;
            }
    
            .left-logo-section{
                width: 100%;
                float: left;
                display: flex;
                align-items: center;
                position: relative;
                .left-txt{
                    font-family: 'M PLUS Rounded 1c',sans-serif;
                    color: #592C82;
                    font-size: 20px;
                    line-height:24px; 
                    text-align: center;
                    font-weight: 500;
                    margin: 0 auto;
                }
            }
        }
        
    }
    @media only screen and (max-width: 320px){
        .middle-text{
            p{
                font-size: 18px;
            }
        }

    }

}
.id-binding-main-section4 + .id-binding-main-section4 + .id-binding-main-section4 + .id-binding-main-section4{
    .section4-text{
        background-image: url(/static/images/id-bind-text-bg-1.png);
        background-size: cover;
        height:55px;
        p{
            top:48%;
        }

        @media (max-width: $breakpoint-max){        
            .section4-text{
                height:100px;
            }
        }
    }
    
    @media (max-width: $breakpoint-max){
        .section4-text{
            height: 90px;
           p{
                top:35%;
            }
        }
        .middle-text{
            p{
                top:30% !important;
            }
        }

     

    }

    @media only screen and (max-width: 320px){
        .middle-text{
            p{
                font-size: 18px;
            }
        }

    }


}


`;

export default styles;