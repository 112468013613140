import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.cashback-contentII{
    width:100%;
    float: left;
    position: relative;
    margin:0 auto;
    .Cashback-PDF{
        width:940px;        
        position: relative;
        text-align: center;
        margin: 30px auto;
        .PDF-head{
            font-size: 24px;
            line-height: 36px;
            float: left;
            width:100%;
            text-align: left;
            margin-bottom:20px;
        }
        .PDF-CTA{
            background:#871284;
            font-size: 36px;
            color:#fff;
            border-radius: 40px;
            margin:20px auto;
            border:none;
            padding: 4px 40px;
            cursor: pointer;
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .disclaimer{
        width:100%;
        float: left;
        position: relative;
        margin:0 auto;
        .disclaimer-text{
            width:940px;
            margin: 20px auto 30px;
            h3{
            font-size: 14px;
            line-height:20px;
            color:#000;
            }
            a{
                color:#000;           
            }
         }
    }
    @media (max-width: $breakpoint-max){
        .Cashback-PDF{
            width:100%;
            margin: 0 auto 20px;
            padding: 0 20px;
            .PDF-head{
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;              
            }
            .PDF-CTA{       
                font-size: 20px;               
                border-radius: 20px;          
                padding: 8px 32px;
            }
        }
        .disclaimer{  
            border-top: 2px solid #000;         
            padding-top:20px;
            .disclaimer-text{
                width:100%;
                margin: 0 auto;
                padding: 0 20px;                
             }
        }

    }
}

.Cashback-Content{
    width:100%;
    float:left;
    margin: 0 auto;    
    .Cashback-container{
        width:940px;
        margin:0 auto; 
        display: grid;
        display: -ms-grid;
        // display: -ms-inline-grid;
        grid-auto-flow: column; 
        // -ms-grid-auto-flow: column;         
    .Cashback-leftsection{       
        background-color:#582b8c;
        color:#fff;
        font-size: 24px;
        line-height: 36px;
        padding: 15px 0 0 15px;
        width: 200px;
        float: left;
        margin-right: 10px;
        margin-bottom:10px;
        
        p{
            margin-bottom:0;
        }
    }
    .Cashback-rightsection{ 
        background-color:#EEEEEE;
        color:#000;
        padding: 15px 0 0 15px;
        width: 730px;
        float: left; 
        margin-bottom:10px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
            margin-left:210px;
        }
        h3{
            font-size: 24px;
            line-height: 36px;
        } 
        p{
            font-size: 20px;
            line-height: 30px;
        } 
        .right-boxtext{
            background: #fff;
            position: relative;
            width: auto;
            margin: 5px 10px 30px 0;
            float: left;
            padding: 20px;  
            h4{
                font-size: 18px;
                line-height: 30px;
                font-weight: 500;
                width:100%;
                float:left;
            }  
            ul{
                float: left;
                position: relative;
                width: 100%;
                margin: 0 auto 20px;
                padding: 0;
                li{
                    list-style: none;
                    font-size: 18px;
                    line-height: 30px;
                }  
                .bold{
                    font-weight: 500;
                }              
            }                 
        }  
        .no-box{
            background: none;
            padding: 0;            
        }  
    }
    }
    .cc6{
        margin-bottom:30px;
    }
    @media (max-width: $breakpoint-max){
        .Cashback-container{            
            display: block;
            grid-auto-flow: unset; 
            width:100%;         
        .Cashback-leftsection{                   
            padding: 15px;         
            margin-right: 0px;
            margin-bottom:0px;
            text-align:center; 
            width: 100%;     
        }
        .Cashback-rightsection{ 
            background-color:#fff;
            padding:20px;           
            margin-bottom:0px;
            width:100%;
            text-align: justify;
            h3{
                font-size: 20px;
                line-height: 26px;
            } 
            p{
                font-size: 20px;
                line-height: 30px;
            } 
            .right-boxtext{
                background: #eee;                
                width: auto;
                margin: 5px 0px 30px 0;                 
                h4{
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 500;
                } 
                      
            }  
            .no-box{
                background: none;
                padding: 0;            
            }  
        }
        }
        .cc6{
            margin-bottom:20px;
        }
    }
}`;

export default styles;