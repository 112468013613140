module.exports = `...on IdBindingMainpageTextboxSection3
{
  sys
  {
    id
  }
  heading
  description
}
`;
