/* eslint-disable no-unused-expressions */
typeof window === 'undefined' && require('dotenv').config();
const axios = require('axios');

const surveyParams = {
    campaignId: Number(process.env.SAMPLING_CAMPAIGN_ID),
    locale: process.env.SAMPLING_CAMPAIGN_LOCALE,
};
const getAuthToken = () => window && window.sessionStorage.authCode;

const bindingID = (typeof window !== 'undefined' && window.sessionStorage.bindingID)
    ? window.sessionStorage.bindingID : '';

const getSurveyQuestions = () => { return {}}

module.exports = {
    getSurveyQuestions
};
