import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';
import { getRichText, getContent } from '../../helpers/uiHelper';
import Styles from '../TabItems/TabItems.scss';

const renderText = (item, resolution) => {
    switch (item.__typename) {
    case 'ContentLt':
        return getContent(item, resolution);
    case 'ContentTypeRichText':
        return getRichText(item);
    default:
        return null;
    }
};
const TabItems = ({ tabsListItem }) => {
    const { imageDt, imageSp, tabSectionTextCollection } = tabsListItem;

    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    const image = resolution.isMobile ? imageSp : imageDt;

    return (
        <div className="tab-items">
            <div className="image-left">
                <Image image={image} />
            </div>
            <div className="text-right">
                <div className="text-right-wrapper">
                    {
                        tabSectionTextCollection.items.map((item) => renderText(item, resolution))
                    }
                </div>
            </div>
            <style jsx>{Styles}</style>
        </div>
    );
};

TabItems.propTypes = {
    tabsListItem: PropTypes.object,
};

export default TabItems;
