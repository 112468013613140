import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';
.digital-campaign-banner-container{
    width:100%;
    float:left;
    position: relative;
    margin:0 auto 30px;
    display: flex;
    flex-direction: column;
    .digital-campaign-retailer{
        width:220px;
        position: relative;
        margin: 10px auto 30px;
        float: left;
        text-align: left;
        img{
            width:100%;
        }
    }
    .retailer-section{
        margin:0 auto;
        text-align: center;
    }
    .digital-campaign-banner{

        img{
            width:100%;
        }
        .digital-campaign-banner-txt{
            font-size: 24px;
            color:$purple-dark;     
            margin: 20px auto;
            width: 100%;
            text-align: center;            
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        .digital-campaign-retailer{
            width:100%;
            margin: 20px 0 40px;   
            padding:0 20px;        
        }
    }    
}
.banner-reverse{
    flex-direction: column-reverse;
    .digital-campaign-retailer{
        text-align: center;
    }
}

`;

export default styles;