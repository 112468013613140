const { excludeUnwantedPages } = require('./Sitemap.helper');

test('Excludes unwanted pages from sitemap', () => {
    const categories = {
        'items': [
            {
                'page': {
                    'seoHead': {
                        'url': '/article-top/',
                        'hideInSitemap': false,
                    },
                },
                'nameEn': 'Article category - Article Top Page',
                'name': '全てのトピック',
                'articles': {
                    'items': [{
                        'page': {
                            'seoHead': {
                                'hideInSitemap': true,
                            },
                        },
                    }],
                },
            },
            {
                'page': {
                    'seoHead': {
                        'url': '/article-top/highlights/',
                        'hideInSitemap': true,
                    },
                },
                'nameEn': 'Article category - Highlights',
                'name': '最新トピック',
                'articles': {
                    'items': [{
                        'page': {
                            'seoHead': {
                                'hideInSitemap': false,
                            },
                        },
                    }],
                },
            },
        ],
    };

    const expectedOutput = [
        {
            'articles': {
                'items': [],
            },
            'name': '全てのトピック',
            'nameEn': 'Article category - Article Top Page',
            'page': {
                'seoHead':
                {
                    'hideInSitemap': false,
                    'url': '/article-top/',
                },
            },
        }];

    expect(excludeUnwantedPages(categories)).toEqual(expectedOutput);
});
