import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */

@import "./styles/global-variables";

body {
    background-image: url('/static/images/Background_Productline_pink.jpg'),
                      linear-gradient(180deg, rgba(255,255,255,1) 1014px, rgba(250,229,238,1) 2243px);
    background-position: center 570px, 0 170px;
    background-repeat: no-repeat, no-repeat;

    @media (max-width: $breakpoint-max) {
        background: white;
    }
}
`;

export default styles;