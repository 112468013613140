/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useScreenSize from '../Hooks/useScreenSize';

import CategorySelector from './CategorySelector/CategorySelector';
import Products from './Products/Products';
import Filters from './Filters/Filters';

import PromoBannerWithCta from '../PromoBannerWithCta/PromoBannerWithCta';
import SmallPromoBannersContainer from '../SmallPromoBannersContainer/SmallPromoBannersContainer';
import PromoBannerInterview from '../PromoBannerInterview/PromoBannerInterview';
import RetailersDropdown from '../RetailersDropdown/RetailersDropdown';

import Style from './ProductList.scss';

import { buildCategories, getSectionTitles, getFilteredProducts, getFilters, getSelectedCategory, assignRatingsToCategories, extractRnrIds } from './ProductList.helper';
import BazaarVoice from '../../adapters/bazaarVoice.adapter';

const ProductList = (props) => {
    const categories = [...buildCategories(props.productCategories.categories)].reverse();

    const [selectedCategory, setSelectedCategory] = useState(getSelectedCategory(categories));
    const [filteredCategories, setFilteredCategories] = useState(selectedCategory);
    const [activeFilter, setActiveFilter] = useState(null);
    const [openFilterBox, setOpenFilterBox] = useState(null);
    const menuRef = useRef();

    const sectionTitles = getSectionTitles(props);

    const toggleFilterbox = (box) => {
        if (box === openFilterBox) setOpenFilterBox(null);
        else setOpenFilterBox(box);
    };

    const handleClick = (e) => {
        const clickedOutsideMenu = !menuRef.current.contains(e.target);

        if (clickedOutsideMenu) {
            toggleFilterbox(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);

        const rnrIds = extractRnrIds(categories);

        BazaarVoice.getMultipleProductRatings(rnrIds)
            .then((result) => {
                const categoriesWithRatings = assignRatingsToCategories(filteredCategories, result.Results);
                setSelectedCategory(categoriesWithRatings);
                setFilteredCategories(categoriesWithRatings);
            });

        return () => window.removeEventListener('click', handleClick);
    }, []);

    const onFilterChange = (filter, value) => {
        setActiveFilter({ filter, value });
        setFilteredCategories(getFilteredProducts(selectedCategory, filter, value));
        toggleFilterbox();
    };

    const filters = getFilters(selectedCategory);
    const resolution = useScreenSize();

    const { promoBannerWithCta, smallPromoBannersContainer, promoBannerInterview, retailersDropdown } = props;

    return (
        <div className="product-list container">
            <div className="row">
                <div className="col-md-3">
                    <div className="products-list-search" ref={menuRef}>
                        <CategorySelector
                            sectionTitle="商品を探す"
                            categories={categories}
                            onTitleClick={toggleFilterbox}
                            isOpen={(resolution && !resolution.isMobile) || openFilterBox === 'categories'}
                        />

                        <Filters
                            sectionTitles={sectionTitles}
                            filters={filters}
                            onFilterChange={onFilterChange}
                            activeFilter={activeFilter}
                            toggleFilterbox={toggleFilterbox}
                            openFilterBox={openFilterBox}
                        />
                    </div>
                </div>

                <div className="col-md-9 product-list-sp">
                    <Products categories={filteredCategories} />

                    {retailersDropdown && (
                        <RetailersDropdown
                            dropdownTitle={retailersDropdown.dropdownTitle}
                            retailersCollection={retailersDropdown.retailersCollection}
                        />
                    )}

                    <div className="promo-banners">
                        {promoBannerWithCta && <PromoBannerWithCta {...promoBannerWithCta} />}
                        {promoBannerInterview && <PromoBannerInterview {...promoBannerInterview} />}
                        {smallPromoBannersContainer && (
                            <SmallPromoBannersContainer banners={smallPromoBannersContainer.bannersCollection} />
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

ProductList.propTypes = {
    productCategories: PropTypes.object,
    promoBannerWithCta: PropTypes.object,
    promoBannerInterview: PropTypes.object,
    retailersDropdown: PropTypes.object,
    smallPromoBannersContainer: PropTypes.object,
};

const mapStateToProps = (state) => ({ productCategories: state.productCategories });

export default connect(mapStateToProps)(ProductList);
