/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import Style from './ContentLt.scss';
import useScreenSize from '../Hooks/useScreenSize';
import NomuraStyle from './ContentNomura.scss';
import { renderRichText } from '../../helpers/richTextHelper';

const ContentNorma = ({ items }) => {
    const contentData = items && items.items ? (items && items.items[0] && items.items[0].advancedText)
        : (items && items[0] && items[0].contentMobile);

    const [content, setContent] = useState(contentData);
    const [pageCount, setPageCount] = useState(0);
    const resolution = useScreenSize();
    const totalPages = 3;

    const handleBtnClickForward = () => {
        if (pageCount > 0 && pageCount <= 2) {
            setPageCount((prevCount) => prevCount - 1);
            const counter = pageCount;
            const latestCount = counter - 1;
            const contentsData = items && items.items ? items.items[latestCount] && items.items[latestCount].advancedText
                : items[latestCount] && items[latestCount].contentMobile;
            if (contentsData) {
                setContent(contentsData);
                window.scrollTo(0, 0);
            }
        }
    };

    const handleBtnClickBackward = () => {
        if (pageCount < 2) {
            setPageCount((prevCount) => prevCount + 1);
            const contentData2 = items && items.items ? items.items[pageCount + 1]
                && items.items[pageCount + 1].advancedText : items[pageCount + 1]
            && items[pageCount + 1].contentMobile;
            if (contentData2) {
                setContent(contentData2);
                window.scrollTo(0, 0);
            }
        }
    };

    return (
        items && items.items
            ? (
                <div>

                    {resolution && resolution.isMobile
                        ? (
                            <>
                                {items && items.items
                                    ? renderRichText(content.json, content.links)
                                    : (
                                        <ReactMarkdown
                                            className="active"
                                            source={content}
                                            escapeHtml={false}
                                            disallowedTypes={[]}
                                            unwrapDisallowed
                                        />
                                    )}
                                <div className="Pagination">
                                    <button
                                        type="button"
                                        className={classNames('prev', pageCount === 0 && 'disabled')}
                                        onClick={(e) => handleBtnClickForward(e)}
                                        disabled={pageCount === 0}
                                    >{'<'}
                                    </button>

                                    {
                                        totalPages && Array(...Array(totalPages)).map((contentMobile, i) => ((
                                            <button
                                                type="button"
                                                key={i}
                                                className={i + 1 === pageCount + 1 ? 'active pageNumber' : 'inactive pageNumber'}
                                            >
                                                {i + 1}
                                            </button>
                                        )
                                        ))
                                    }

                                    <button
                                        type="button"
                                        className={classNames('next', pageCount + 1 === (totalPages) && 'disabled')}
                                        onClick={(e) => handleBtnClickBackward(e)}
                                        disabled={pageCount + 1 === (totalPages)}
                                    >{'>'}
                                    </button>
                                </div>
                            </>
                        )
                        : (
                            <>
                                {
                                    items && items.items ? items.items.map((item) => (
                                        renderRichText(item.advancedText.json, item.advancedText.links)
                                    ))
                                        : items.map((item, index) => (
                                            <ReactMarkdown
                                                key={index}
                                                source={item.content}
                                                escapeHtml={false}
                                                disallowedTypes={[]}
                                                unwrapDisallowed
                                            />
                                        ))
                                }
                            </>
                        )}
                    <style jsx>{Style}</style>
                    <style jsx>{NomuraStyle}</style>
                </div>
            )
            : (
                <div className="content-lt">

                    {resolution && resolution.isMobile
                        ? (
                            <>
                                {items && items.items
                                    ? renderRichText(content.json, content.links)
                                    : (
                                        <ReactMarkdown
                                            className="active"
                                            source={content}
                                            escapeHtml={false}
                                            disallowedTypes={[]}
                                            unwrapDisallowed
                                        />
                                    )}
                                <div className="Pagination">
                                    <button
                                        type="button"
                                        className={classNames('prev', pageCount === 0 && 'disabled')}
                                        onClick={(e) => handleBtnClickForward(e)}
                                        disabled={pageCount === 0}
                                    >{'<'}
                                    </button>

                                    {
                                        totalPages && Array(...Array(totalPages)).map((contentMobile, i) => ((
                                            <button
                                                type="button"
                                                key={i}
                                                className={i + 1 === pageCount + 1 ? 'active pageNumber' : 'inactive pageNumber'}
                                            >
                                                {i + 1}
                                            </button>
                                        )
                                        ))
                                    }

                                    <button
                                        type="button"
                                        className={classNames('next', pageCount + 1 === (totalPages) && 'disabled')}
                                        onClick={(e) => handleBtnClickBackward(e)}
                                        disabled={pageCount + 1 === (totalPages)}
                                    >{'>'}
                                    </button>
                                </div>
                            </>
                        )
                        : (
                            <>
                                {
                                    items && items.items ? items.items.map((item) => (
                                        renderRichText(item.advancedText.json, item.advancedText.links)
                                    ))
                                        : items.map((item, index) => (
                                            <ReactMarkdown
                                                key={index}
                                                source={item.content}
                                                escapeHtml={false}
                                                disallowedTypes={[]}
                                                unwrapDisallowed
                                            />
                                        ))
                                }
                            </>
                        )}
                    <style jsx>{Style}</style>
                    <style jsx>{NomuraStyle}</style>
                </div>
            )
    );
};

ContentNorma.propTypes = {
    // content: PropTypes.string,
    contentMobile: PropTypes.string,
    items: PropTypes.object,
};

export default ContentNorma;
