module.exports = `
... on StaticHeader {
    nameEn
    imageDt {
        cloudinaryId
        asset{url}
        description
    }
    imageSp {
        cloudinaryId
        asset{url}
        description
    }
    }
`;
