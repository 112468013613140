import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Style from "./SimpleCard.scss";
import ReactMarkdown from "react-markdown";
import LinkButton from "../Link/Link";

export const SimpleCard = ({
  cardMedia,
  cardActionCollection,
  cardDescription,
  header,
  customClass,
  isCardLink = false,
  altText
}) => {
  const content = (
    <div className={!isCardLink?`card ${customClass ?? ""}`:''}>
      <img className="card-media" src={cardMedia.url} alt={altText??cardActionCollection?.items?.[0]?.url}/>
      <div className="card-body">
        <h2>{header}</h2>
        <ReactMarkdown
          source={cardDescription}
          escapeHtml={false}
          disallowedTypes={[]}
          unwrapDisallowed
        />
        {!isCardLink && (
          <div className="card-actions">
            {cardActionCollection?.items?.map((action, ind) => {
              return <LinkButton {...action} key={`Link-${ind + 1}`} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
  return isCardLink ? <a className={isCardLink&&`card ${customClass ?? ""}`} aria-label={`Go to ${altText??cardActionCollection?.items?.[0]?.url}`} href={cardActionCollection?.items?.[0]?.url}>{content}</a> : content;
};

SimpleCard.propTypes = {
  cardDescription: PropTypes.string,
  children: PropTypes.array.isRequired,
  header: PropTypes.string,
  cardMedia: PropTypes.object,
  cardActionCollection: PropTypes.object,
  customClass: PropTypes.string,
  isCardLink: PropTypes.bool,
};

export default SimpleCard;
