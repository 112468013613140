import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';
import Style from './AkashiTitle.scss';

const AkashiTitle = (props) => {
    const { contentTitle, styles } = props;

    return (
        <div className="Heading-Section">
            <h1
                className={styles}
                dangerouslySetInnerHTML={{
                    __html: contentTitle,
                }}
            />
            <style jsx>{Style}</style>
        </div>
    );
};

AkashiTitle.propTypes = {
    contentTitle: PropTypes.object,
    styles: PropTypes.object,
};

export default AkashiTitle;
