export function getImageUrl(imageUrl, width) {
    let receiveWidth = width;
    if (receiveWidth === undefined || null) {
        receiveWidth = '1920';
    } else if (receiveWidth && Object.keys(receiveWidth).length === 0) {
        receiveWidth = '1920';
    } else if (receiveWidth && Object.keys(receiveWidth).length === 1) {
        receiveWidth = receiveWidth['width'] ? receiveWidth['width'] : '1920';
    }

    return `${`${imageUrl}?w=${receiveWidth}`}`;
}
