import React from 'react';
import Router from 'next/router';
import { storageKeysRemove } from '../../helpers/StorageEvents';
import style from './EmailThanksForRegistration.scss';

const EmailThanksForFail = () => {
    const handleClearSession = () => {
        const crmDetails = `${window.location.hostname}crmDetails`;
        storageKeysRemove([crmDetails, 'doiVerifiyStatus', 'isUserLoggedIn', 'resendCount', 'userData']);
    };

    const handleRegistration = () => {
        handleClearSession();
        Router.push('/registration');
    };

    return (
        <div className="email-thanks-reg-container">
            <div className="email-error-reg">
                <h1>URLの有効期限が切れています。</h1>
                <h2>こちらから再度会員登録を行ってください。</h2>
                <div className="email-thanks-reg">
                    <div className="ID-Bind-Section">
                        <div className="ID-Bind-container">
                            <button type="button" className="idbind-cta" onClick={handleRegistration}> 会員登録 </button>
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{style}</style>
        </div>
    );
};

export default EmailThanksForFail;
