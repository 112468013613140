import React, { useEffect, useState } from 'react';
import { storageGet } from '../../helpers/StorageEvents';
import Style from './TemporaryThanksForRegistration.scss';
import useResolution from '../Hooks/useScreenSize';

const TemporaryThanksForRegistrationIntro = () => {
    const [emailAddress, setEmailAddress] = useState();

    const handleGAEvents = async (event, eventCate, eventAction, eventLabel) => {
        if(typeof window !== undefined) {
            if(window.dataLayer) {
        await window.dataLayer.push({
            'event': event,
            'GAeventCategory': eventCate,
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    }
    }
        return true;
    };

    useEffect(() => {
        //   Below code is for FB and GDN GA and CV tags.
        const fbAndGdnGAEvents = {
            'fcI2Q-dBlpTin': 'whisper_register_coupon_complete_facebook',
            'gPtdN-dBlpTin': 'whisper_register_coupon_complete_gdn',
        };
        const retailerUrl = storageGet('retailerUrl');
        const fbAndGdnPath = ['fcI2Q-dBlpTin', 'gPtdN-dBlpTin'];

        if (fbAndGdnPath.indexOf(retailerUrl) !== -1) {
            handleGAEvents('customEvent', 'event_coupon_action', 'event_profile_coupon_register', fbAndGdnGAEvents[retailerUrl]);
        }

        const crmDetails = `${window.location.hostname}crmDetails`;
        const sessionData = window.sessionStorage.getItem(crmDetails) !== null ? JSON.parse(window.sessionStorage.getItem(crmDetails)) : '';
        setEmailAddress(sessionData && sessionData.emailAddress);
        handleGAEvents('customEvent', 'event_crm_action', 'event_profile_temporally', 'whisper_profile_temporally');
    }, []);

    const resolution = useResolution();
    if (!resolution) return null;

    return (
        <div>
            {
                resolution && resolution.isMobile
                    ? (
                        <div className="mail-section">
                            <h3 className="mail-section-header"> {`メールアドレス“${emailAddress}”へ、`}<br /><span className="shortdesc-purple">確認用メールを送信いたしました。</span> </h3>
                            <div className="mail-section-desc">
                                <h3>現在は<span className="shortdesc-purple">仮登録状態</span>となっており、<br />メール本文に記載されている <br /> URLにアクセスすることで、<br />会員登録は完了します。</h3>
                            </div>
                        </div>
                    ) : (
                        <div className="mail-section">
                            <h3 className="mail-section-header"> {`メールアドレス“${emailAddress}”へ、`}<br /><span className="shortdesc-purple">確認用メールを送信いたしました。</span> </h3>
                            <div className="mail-section-desc">
                                <h3>現在は<span className="shortdesc-purple">仮登録状態</span>となっており、メール本文に記載されている <br /> URLにアクセスすることで、会員登録は完了します。</h3>
                            </div>
                        </div>
                    )
            }
            <style jsx>
                {Style}
            </style>
        </div>
    );
};

export default TemporaryThanksForRegistrationIntro;
