import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.small-promo-banners-container {
    margin-bottom: 100px;

    @media (max-width: $breakpoint-max) {
        overflow: hidden;
        padding: 0;
    }
}

.small-promo-banner-cta {
    background-repeat: no-repeat;
    background-size: 100%;
    box-shadow: $box-shadow-25;

    &.banner-mobile {
        padding-bottom: 180px;
        width: 100%;
    }

    &.banner-desktop {
        height: 182px;
        width: 100%;
    }

    .text-container {
        align-items: center;
        color: $purple-dark;
        display: flex;
        flex-direction: column;
        float: left;
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-weight: 500;
        height: 100%;
        justify-content: space-around;
        padding: 25px;
        text-align: center;

        p {
            margin: 0;
        }

        .banner-text-small {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: -5px;
            text-shadow: $text-glow;
        }

        .banner-text-big {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 13px;
            text-shadow: $text-glow;
        }

        .banner-text-mid {
            font-size: 23px;
            font-weight: bold;
            margin: -20px 0 11px;
            text-shadow: $text-glow;
        }

        @media (max-width: $breakpoint-max) {
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0 25px 14px 18px;

            .banner-text-small {
                font-size: 14px;
            }

            .banner-text-big {
                font-size: 24px;
            }

            .banner-text-mid {
                font-size: 20px;
            }
        }
        @media only screen and (max-width: $breakpoint-max) {
            padding: 30px 25px 14px 18px;
            height: auto !important;
        }
    }

    .cta {
        font-family: inherit;
        font-size: 18px;
        font-weight: 500;
        padding: 3px 0;
        width: 150px;

        &::after {
            border-bottom: 7px solid transparent;
            border-left: 12px solid white;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            content: "";
            font-size: 0;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width: $breakpoint-max) {
            font-family: "M PLUS Rounded 1c", sans-serif;
            font-size: 16px;
        }
    }

    @media screen and (min-width: 0\\0) {
        .cta {
            height: 31px;
            line-height: 30px;
        }
    }

    @media (max-width: $breakpoint-max) {
        margin-bottom: 11px;
    }
}

.col-md-6 {
    padding-left: 12.5px;
    padding-right: 12.5px;
}

.homepage-small-promo {
    font-family: "Noto Sans JP";
    width: 1100px;
    max-width: 1100px;
    display: none;
    .small-promo-banner-cta {
        height: 335px !important;
        box-shadow: none;
        .text-container {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            color: $purple;
            .banner-text-small {
                text-shadow: none;
                width: auto;
                float: left;
                margin: 8px 5px 0 0;
                font-weight: 400;
                font-family: 'Noto Sans JP';
            }
            .banner-text-big {
                text-shadow: none;
                width: auto;
                float: left;
                font-size: 26px;
                font-family: 'Noto Sans JP';
                font-weight: normal;
            }
            .btn-round {
                font-family: "Noto Sans JP";
                font-weight: 400;
                position: absolute;
                bottom: 55px;
                margin: 0 auto;
                float: left;
                left: 0;
                right: 0;
                display: block;
                text-decoration: none;
                color: white;
            }
        }
        .cta {
            font-size: 14px;
            font-weight: 400;
            &::after {
                right: 8px;
            }
        }
    }

    @media (max-width: $breakpoint-max) {
        width: 100%;
        max-width: 100%;
        .row{
            .col-sm-12:nth-of-type(1){
                margin-bottom: 30px;  
            }
        }
        .small-promo-banner-cta {
            height: 320px !important;

            .text-container {
                display: block;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                padding: 15px 20px;
                .banner-text-small {
                    text-shadow: none;
                    width: 100%;
                    float: left;
                    margin: 8px 5px 0 0;
                    text-align: center;
                }
                .banner-text-big {
                    text-shadow: none;
                    width: 100%;
                    float: left;
                    font-size: 26px;
                    text-align: center;
                }
                .btn-round {
                    bottom: 80px;
                    width: 180px;
                }
            }
        }
    }
    @media (max-width: 400px) {
        .small-promo-banner-cta {
            height: 290px !important;
            .text-container {
                .btn-round {
                    bottom: 60px;
                }
            }
        }
    }
}
`;

export default styles;