import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.product-footer {
    height: 584px;

    h3 {
        color: $purple-darker;
        font-family: "Noto Sans JP";
        font-size: 12px;
        margin: 59px 0 43px;
    }

    .product-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 80px;
        justify-content: center;
        width: 100%;

        h4 {
            align-self: flex-start;
            color: $purple-darker;
            font-family: "Noto Sans JP", sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            text-align: center;
            text-shadow: 1px 1px 1px rgba($purple-dark, 0.5);

            @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                font-size: 12px;
            }

            a {
                color: #6D5522;
            }
        }

        .image-sizer {
            align-self: flex-start;
            height: 89px;

            img {
                width: 95%;
            }

            &.v {
                width: 95px;

                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    width: 80px;
                }
            }

            &.h {
                width: 80px;
            }
        }
    }

    .button-register {
        font-size: 16px;
        font-weight: bold;
        height: 38px;
        line-height: 1;
        margin-bottom: 22px;
        margin-top: 40px;

        &.btn-empty {
            padding: 10px 51px;
        }
    }

    .product-footer-list {
        color: $purple;
        list-style: none;
        margin: 20px 7px 0;
        padding: 0;

        &.gold {
            color: $gold;
        }

        li {
            font-family: "Noto Sans JP";
            font-size: 16px;
            line-height: 30px;
            padding-left: 16px;
            position: relative;

            &::before {
                border-bottom: 7px solid transparent;
                border-left: 10px solid $purple;
                border-left-color: inherit;
                border-top: 7px solid transparent;
                content: "";
                height: 0;
                left: -1px;
                position: absolute;
                top: 7px;
                width: 0;
            }

            a {
                color: inherit;
                font-family: "Noto Sans JP";
                font-weight: normal;
            }
        }
    }

    .footer-product-align {
        display: flex;
        .basicline {
            display: flex;
            flex-direction: column;
            width: 50%;
            h3 {
                font-size: 20px;
            }
        }
        .premiumline {
            display: flex;
            flex-direction: column;
            width: 31%;
            h3 {
                font-size: 20px;
                color:$darkgold;
                margin-left:32px;
            }
        }
        .basicline-wrapper {
            display: flex;
            justify-content: space-around;
            margin-left:-35px;
        }
        .premiumline-wrapper {
            display: flex;
            justify-content: space-around;
            h4 {
                color: #a38135 !important;
            }
            .product-footer-list {
                li {
                    &::before {
                        border-bottom: 7px solid transparent;
                        border-left: 10px solid $gold !important;
                        border-left-color: inherit;
                        border-top: 7px solid transparent;
                        content: "";
                        height: 0;
                        left: -1px;
                        position: absolute;
                        top: 7px;
                        width: 0;
                    }
                    a {
                        color: #6D5522 !important;
                    }
                }
                margin-left:7px;
            }
        }

    }

    .mobile-footer-hide {
        @media all and (max-width: $breakpoint-max) {
            & {
                display: none;
            }
        }
    }
    @media all and (max-width: $breakpoint-max) {
        & {
            height: auto;
        }
    }
}
.footer-button-container {
    @media all and (min-width: $breakpoint-ipad-max) {
        width: 940px;
        margin: 0 auto;
    }
    .user-logout-container {
        width: 100%;
        .user-logout {
            width: auto;
            font-family: "Noto Sans JP";
            padding: 16px 30px;
            min-width: 314px;
            font-size: 20px;
            height: 70px;
            border: 1px solid $purple-darker;
            background: transparent;
            color: $purple-darker;
            cursor: pointer;
            float: right;
            background-image: url(/static/images/mypage.png);
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: center left;
            text-align: center;
            background-position-x: 20px;
            background-position-y: 20px;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
@media all and (max-width: $breakpoint-max) {
    .user-logout-container {
        margin-top: 15px;
        margin-bottom: 20px;
        .user-logout {
            display: block;
            float: none;
            margin: 0px auto;
        }
    }
}

.Buy-whisper-products {
    width: 100%;
    float: left;
    position: relative;
    background: #f8f8f8;
    text-align: center;
    margin: 0 auto 80px;
    .Buy-products-container {
        margin-bottom: 100px;
        .Buy-products-promo {
            width: 100%;
            display: block;
            margin: 95px auto 0;
            text-align: center;
            h3 {
                font-weight: normal;
                font-size: 26px;
                margin: 0 auto 25px;
                color:$purple;
            }
            .Buy-products-img {
                width: 478px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
                button {
                    clear: both;
                    display: block;
                    margin: 0 auto;
                    background-image: url("https://images.ctfassets.net/8g0fievzqg8k/4w4kwyDY3snUwHBSPibXBq/3b5acc3f6fb21a9b6b34cb2c168c3a52/whisper_shopingcart.png");
                    background-size: 100%;
                    background-position: center;
                    height: 74px;
                    border: none;
                    background-repeat: no-repeat;
                    color: #fff;
                    font-size: 24px;
                    width: 100%;
                }
            }
            @media (max-width: $breakpoint-max) {
                .Buy-products-img {
                    width: 81%;
                }
            }
            @media (max-width: 400px) {
                .Buy-products-img {
                    width: 90%;
                }
            }
        }
        @media (max-width: $breakpoint-max) {
            margin-bottom:0;
        }
    }
}
.footer-container {
    width: 100%;
    float: left;
    margin: 0 auto;
    position: relative;
    max-width: 100%;
    .footer-product-section {
        @media (min-width: $breakpoint-ipad-max) { 
            width: 970px;
        }
        margin: 0 auto;
        h3 {
            margin: 0 0 20px;
        }
        .footer-product-align {
            width: 100%;
            margin: 0;
            .product-footer-nth {
                .product-header {
                    float: left;
                    width: 100%;
                    display: flex;
                    clear: both;
                    height: auto;
                    .image-sizer {
                        margin: 0 auto 15px;
                        text-align: left;
                        float: left;
                    }
                    h4 {
                        text-shadow: none;
                        font-size: 14px;
                        width: 100%;
                        float: left;
                        text-align: left;
                        padding: 0;
                        font-weight: 500;
                        br {
                            display: none;
                        }
                    }
                }
                .product-footer-list {
                    li {
                        font-size: 14px;
                    }
                }
                &:nth-child(3n + 1) {
                    .product-header {
                        .product-footer-list {
                            color: #a38135 !important;
                            li {
                                color: #a38135;
                                a {
                                    color: #a38135 !important;
                                }
                                &:before {
                                    border-left: 10px solid#A38135 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: $breakpoint-max) {
    .product-footer {
        .brandLogoWrapper {
            width: 100%;
            float: left;
            text-align: center;
            margin-bottom: 60px;
        }
    }
}
`;

export default styles;