const { buildProductItem, buildCategoryItem } = require('./FooterProducts.helper');

test('It builds product item correctly', () => {
    const product = {
        'page': {
            'seoHead': {
                'url': '/all-product/ususara-silky/ususara-silky-s-msize/',
            },
        },
        'absorbency': null,
        'style': null,
        'lenght': null,
        'size': {
            'name': 'S～M',
        },
    };

    const expectedResult = {
        'measure': 'S～M',
        'url': '/all-product/ususara-silky/ususara-silky-s-msize/',
    };

    expect(buildProductItem(product, 'size')).toEqual(expectedResult);
});

test('It builds category item correctly', () => {
    const category = {
        'nameEn': 'Ususara Silky',
        'name': 'うすさらシルキー',
        'page': {
            'seoHead': {
                'url': '/all-product/ususara-silky/',
            },
        },
        'color': {
            'color': '#8547b4',
        },
        'brand': {
            'nameEn': 'Whisper',
            'name': 'ウィスパー',
        },
        'image': {
            'title': 'whisper footer productline4',
            'url': 'https://images.ctfassets.net/8g0fievzqg8k/4l6NiG55grnzYXc75oz5OI/ad7a54090c8944963b7079fa82a6846b/whisper_footer_productline4.png',
            'description': null,
        },
        'products': {
            'items': [
                {
                    'page': {
                        'seoHead': {
                            'url': '/all-product/ususara-silky/ususara-silky-s-msize/',
                        },
                    },
                    'absorbency': null,
                    'style': null,
                    'lenght': null,
                    'size': {
                        'name': 'S～M',
                    },
                },
                {
                    'page': {
                        'seoHead': {
                            'url': '/all-product/ususara-silky/ususara-silky-lsize/',
                        },
                    },
                    'absorbency': null,
                    'style': null,
                    'lenght': null,
                    'size': {
                        'name': 'L',
                    },
                },
            ],
        },
    };

    const expectedOutput = {
        'nameEn': 'Ususara Silky',
        'name': 'うすさらシルキー',
        'image': {
            'title': 'whisper footer productline4',
            'url': 'https://images.ctfassets.net/8g0fievzqg8k/4l6NiG55grnzYXc75oz5OI/ad7a54090c8944963b7079fa82a6846b/whisper_footer_productline4.png',
            'description': null,
        },
        'url': '/all-product/ususara-silky/',
        'color': '#8547b4',
        'brand': 'ウィスパー',
        'products': [
            {
                'measure': 'S～M',
                'url': '/all-product/ususara-silky/ususara-silky-s-msize/',
            },
            {
                'measure': 'L',
                'url': '/all-product/ususara-silky/ususara-silky-lsize/',
            },
        ],
    };

    expect(buildCategoryItem(category, 'size')).toEqual(expectedOutput);
});
