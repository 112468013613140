import React from 'react';
import PropTypes from 'prop-types';

import Style from '../ProductList.scss';

const FilterBox = (props) => {
    const { name, title, onFilterClick, onTitleClick, filters, activeFilter, isOpen } = props;

    if (filters.length === 0) return null;

    return (
        <div className="filters">
            <h2 onClick={() => onTitleClick(name)} className="filter-title">{title}</h2>
            {isOpen && (
                <ul className="search-list">
                    {filters.map((filter, i) => {
                        const filterName = Object.keys(filter).toString();
                        const count = Object.values(filter).toString();
                        const className = filterName === activeFilter ? 'active' : '';

                        return (
                            <li
                                key={i}
                                className={className}
                                onClick={() => onFilterClick(name, filterName)}
                            >
                                <a href="#categoryContainer" className="event_menu_click" data-action-detail={filterName}>{filterName} ({count})</a>
                            </li>
                        );
                    })}
                </ul>
            )}
            <style jsx>{Style}</style>
        </div>
    );
};

FilterBox.propTypes = {
    activeFilter: PropTypes.string,
    filters: PropTypes.array,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    onFilterClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    title: PropTypes.string,
};

export default FilterBox;
