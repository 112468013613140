import React from 'react';
import Head from 'next/head';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './Cashback.scss';
import CashbackKV from './CachbackKV';
import CashbackContent from './Cashbackcontent';
import CashbackContentII from './CashbackcontentII';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const Cashback = () => {
    let resolution = useScreenSize();

    if (!resolution) {
        resolution = { isMobile: true };
    }
    const mobileImg = 'https://images.ctfassets.net/8g0fievzqg8k/19XgCvdwJHsCDhzaPD4esE/a2327e0f0eba8e77706a7e756bec3b60/sp_top_kvimgnew.png';
    const desktopImg = 'https://images.ctfassets.net/8g0fievzqg8k/7BfLqxA9bljUrB0rxDVHa0/853a7c3be754977ce1a0274da1b9fa86/pc_top_kvimgnew.png';
    const banner = resolution.isMobile ? mobileImg : desktopImg;
    return (
        <>
            <Head>
                <link rel="preload" as="image" href={buildContentfullImageUrl(banner)} />
            </Head>
            <div className="Cashback-Campaign-Container">
                <div className="banner-image-section">
                    <div className="banner-image">
                        <img src={buildContentfullImageUrl(banner)} alt={banner} />
                    </div>
                </div>
                <CashbackKV />
                <CashbackContent />
                <CashbackContentII />
                <style jsx>{Style}</style>
            </div>
        </>
    );
};

export default Cashback;
