import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.content-lt {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-tb-max) {
        padding: 0 15px;
    }
    p {
        a {
            text-decoration: underline !important;
        }
    }
    :global(h2) {
        color: $purple;
        font-size: 28px;
        font-weight: 500;
        line-height: 48px;
        margin: 0;
    }

    :global(h1) {
        color: $purple;
        font-size: 48px;
        font-weight: 500;
        line-height: 58px;
        margin-top: 0;
    }

    :global(p) {
        color: $text-article;
        font-size: 22px;
        line-height: 42px;
       
            a {
                text-decoration: underline !important;
            }
        
    }

    :global(span) {
        color: $text-article;
        display: block;
        font-size: 18px;
        text-align: right;
        width: 100%;
    }

    :global(img) {
        width: 100%;
    }
    :global(.disclaimer) {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    :global(.disclaimer) {
        font-size: 12px !important;
        line-height: 18px !important;
    }

    @media only screen and (max-width: $breakpoint-max) {
        margin-top: 25px;
        margin-bottom: 20px;

        :global(h1) {
            font-size: 24px;
            line-height: 34px;
        }

        :global(p) {
            font-size: 18px;
            line-height: 28px;
            margin-top: 0;
        }

        :global(h2) {
            font-size: 18px;
            line-height: 28px;
        }

        :global(img) {
            width: calc(100% + 30px);
            margin-left: -15px;
        }

        :global(span) {
            font-size: 10px;
        }
    }
}
:global(.DrNomura-article) {
    margin-top:120px !important;
    :global(.rightimage) {
        float: right;
        clear: both;
        width: 50%;
    }
    :global(.leftimage) {
        float: left;
        clear: both;
        width: 50%;
    }
    :global(a) {
        color: $purple;
        text-decoration: underline !important;

    }
    :global(.purple) {
        display: inline !important;
        color: #8347ad !important;
        font-size: 21px !important;
    }
    :global(.DrNomuraImg) {
        width: 100%;
        float: left;
        position: relative;
        margin: 0 auto 40px;
    }
    :global(sup) {
        top: -0.3em;
    }
    :global(p) {
        font-size: 21px !important;
    }
    :global(h1) {
        color: $purple-dark !important;
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            font-size: 46px;
        }
    }
    :global(h3) {
        color: $purple;
        font-size: 28px;
        font-weight: 500;
        line-height: 48px;
        margin: 32px 0 25px;
    }
    :global(h4) {
        font-size: 22px;
        line-height: 40px;
        color: $text-article;
    }
    :global(.Dr-hyper) {
        display: inline !important;
        font-size: 28px !important;
        line-height: 40px !important;
        color: #fff !important;
        background: $purple-dark;
        border-radius: 5px;
    }
    :global(.DrNomura-movie) {
        width: 100%;
        float: left;
        position: relative;
        margin: 0 auto;
        text-align: center;
    }

    :global(.DrNomura-part1-doubleimg) {
        width: 100%;
        position: relative;
        float: left;
        margin: 20px 28px 40px;
        text-align: center;
        :global(.DrNomura-part1-doubleimg-left) {
            width: 45%;
            float: left;
            margin: 0 10px;
        }
        :global(.DrNomura-part1-doubleimg-right) {
            width: 45%;
            float: left;
            margin: 0 10px;
        }
    }
    :global(.DrNomura-Section2) {
        :global(.DrNomura-Section2-img) {
            float: left;
            width: 100%;
            position: relative;
            margin: 20px auto 50px;
            text-align: center;
            :global(img) {
                width: 50%;
            }
        }
        :global(.disclaimer) {
            margin: 68px 0 35px;
        }
    }
    @media only screen and (max-width: $breakpoint-max) {
        :global(.rightimage),
        :global(.leftimage) {
            width: 100% !important;
            margin: 30px auto;
        }
        :global(.DrNomuraImg) {
            width: 100%;
            float: left;
            position: relative;
            margin: 0 auto;
        }
        :global(.purple) {
            display: inline !important;
            color: #8347ad !important;
            font-size: 18px !important;
        }
        :global(.Dr-hyper) {
            display: inline-block !important;
            width: auto !important;
            text-align: center !important;
            margin: 0 0 15px 0;
        }
        :global(h4) {
            font-size: 20px;
            line-height: 24px;
        }
        :global(h1) {
            font-size: 30px !important;
            line-height: 40px !important;
            color: $purple-dark !important;
        }
        :global(h3) {
            font-size: 20px;
            line-height: 36px;
            margin: 32px 0 25px;
        }
        :global(p) {
            font-size: 18px !important;
        }
        :global(.DrNomura-movie) {
            margin: 20px auto 36px;
        }
        :global(.DrNomura-part1-doubleimg) {
            margin: 20px auto 40px;
            :global(.DrNomura-part1-doubleimg-left) {
                width: 100%;
                float: left;
                margin: 0 auto 36px;
                :global(img) {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }
            :global(.DrNomura-part1-doubleimg-right) {
                width: 100%;
                float: left;
                margin: 0 auto;
                :global(img) {
                    width: 100% !important;
                    margin: 0 auto !important;
                }
            }
        }
        :global(.DrNomura-Section2) {
            :global(.DrNomura-Section2-img) {
                :global(img) {
                    width: 100%;
                    margin: 0 auto;
                }
            }
            :global(.disclaimer) {
                margin: 34px 0 0px;
            }
        }
    }
    @media only screen and (max-width: $breakpoint-max){
        margin-top:60px;
    }
}
:global(.InterviewSugiyama) {
    :global(.content-lt) {
        :global(img) {
            width: 100%;
            @media only screen and (max-width: $breakpoint-max) {
                width: 100%;
                margin-left: 0;
            }
        }

        :global(.section-1) {
            display: flex;
            margin: 20px 0;
            @media only screen and (max-width: $breakpoint-max) {
                flex-direction: column;
            }
            :global(.article-sports) {
                width: 15%;
                :global(img) {
                    width: auto;
                }
            }
            :global(.intro-title) {
                width: 85%;
                padding-left: 10px;
                font-size: 24px;
                line-height: 42px;
                color: #595757;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    padding: 0;
                    margin-top: 25px;
                    font-size: 22px;
                    line-height: 24px;
                }
                :global(.heading-h2) {
                    font-size: 40px;
                    line-height: 56px;
                    color: #602d84;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 30px;
                        line-height: 40px;
                        margin-top: 15px;
                    }
                }
                :global(span) {
                    display: inline;
                    font-size: 24px;
                    line-height: 42px;
                    color: #602d84;
                    padding: 0 15px;
                    @media only screen and (max-width: $breakpoint-max) {
                        width: 100%;
                        padding: 0;
                        margin-top: 10px;
                        font-size: 22px;
                        line-height: 24px;
                        text-align: left;
                    }
                }
            }
            :global(.article-sports-sp) {
                @media only screen and (max-width: $breakpoint-max) {
                    text-align: center;
                }
                :global(img) {
                    @media only screen and (max-width: $breakpoint-max) {
                        width: auto;
                    }
                }
            }
        }
        :global(.section-2) {
            display: flex;
            :global(.section2Text) {
                width: 55%;
                font-size: 22px;
                line-height: 42px;
                color: #595757;
                text-align: justify;
                :global(img) {
                    float: right;
                    width: auto;
                }
                @media only screen and (max-width: $breakpoint-max) {
                    font-size: 18px;
                    line-height: 36px;
                    width: 100%;
                }
                :global(span) {
                    color: #8347ad;
                    display: inline;
                    font-size: 22px;
                    line-height: 42px;
                    text-align: justify;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                }
            }
            :global(.section2Img) {
                width: 45%;
            }
        }
        :global(.section-3) {
            margin-top: 60px;
            @media only screen and (max-width: $breakpoint-max) {
                margin: 15px -15px 0;
            }
            :global(h2) {
                background-color: #e85a9a;
                color: white;
                display: inline-block;
                font-size: 22px;
                line-height: 35px;
                font-weight: 500;
                border-radius: 3px;
                margin: 0 0 40px;
                padding: 1px 8px 2px;
                @media only screen and (max-width: $breakpoint-max) {
                    margin: 0 15px 30px 15px;
                    padding: 2px 15px;
                }
            }
            :global(.section-3Text) {
                margin-top: 60px;
                text-align: justify;
                @media only screen and (max-width: $breakpoint-max) {
                    font-size: 18px;
                    line-height: 36px;
                    padding: 0;
                    margin-top: 30px;
                }
                :global(p) {
                    font-size: 22px;
                    line-height: 42px;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                        padding: 0 15px;
                    }
                    :global(span) {
                        color: #8347ad;
                        display: inline;
                        font-size: 22px;
                        line-height: 42px;
                        @media only screen and (max-width: $breakpoint-max) {
                            font-size: 18px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }
        :global(.section-4) {
            display: flex;
            flex-direction: row;
            margin: 60px 0 0;
            @media only screen and (max-width: $breakpoint-max) {
                margin: 0;
            }
            :global(h2) {
                background-color: #e85a9a;
                color: white;
                display: inline-block;
                font-size: 22px;
                line-height: 35px;
                font-weight: 500;
                border-radius: 3px;
                margin: 0 0 40px;
                padding: 1px 8px 2px;
                @media only screen and (max-width: $breakpoint-max) {
                    margin-bottom: 30px;
                    padding: 2px 15px;
                }
            }
            :global(.whisperkol-ArticleImg) {
                width: 441px;
                max-width: 441px;
                padding-left: 15px;
                padding-right: 15px;
            }
            :global(.section-4Text) {
                font-size: 22px;
                line-height: 42px;
                width: 486px;
                max-width: 486px;
                padding-left: 15px;
                padding-right: 0px;
                text-align: justify;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    max-width: 100%;
                    padding: 0;
                    font-size: 18px;
                    line-height: 36px;
                }
                :global(img) {
                    @media only screen and (max-width: $breakpoint-max) {
                        float: right;
                        width: auto;
                    }
                }
                :global(span) {
                    color: #8347ad;
                    display: inline;
                    font-size: 22px;
                    line-height: 42px;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                }
            }
        }
        :global(.section-5) {
            margin-top: 60px;
            @media only screen and (max-width: $breakpoint-max) {
                margin-top: 10px;
            }
            :global(h2) {
                background-color: #e85a9a;
                color: white;
                display: inline-block;
                font-size: 22px;
                line-height: 35px;
                font-weight: 500;
                border-radius: 3px;
                margin: 0 0 40px;
                padding: 1px 8px 2px;
                @media only screen and (max-width: $breakpoint-max) {
                    padding: 2px 15px;
                }
            }
            :global(.RichText) {
                font-size: 22px;
                line-height: 42px;
                text-align: justify;
                @media only screen and (max-width: $breakpoint-max) {
                    font-size: 18px;
                    line-height: 36px;
                }
                :global(span) {
                    color: #8347ad;
                    display: inline;
                    font-size: 22px;
                    line-height: 42px;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                }
            }
        }
        :global(.section-6) {
            margin-top: 60px;
            @media only screen and (max-width: $breakpoint-max) {
                flex-direction: column-reverse;
                margin-top: 0;
            }
            :global(.RichText-6) {
                font-size: 22px;
                line-height: 42px;
                width: 55%;
                padding: 0 15px;
                text-align: justify;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    font-size: 18px;
                    line-height: 36px;
                    margin-top: 30px;
                }
                :global(p) {
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                }
                :global(span) {
                    color: #8347ad;
                    display: inline;
                    font-size: 22px;
                    line-height: 42px;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                }
            }
            :global(.whisperkol_article-6) {
                width: 45%;
                padding: 0 15px 0 0;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    padding: 0 15px;
                }
            }
        }
        :global(.section-7) {
            margin: 60px 0 0 0;
            @media only screen and (max-width: $breakpoint-max) {
                margin-top: 10px;
            }
            :global(h2) {
                font-size: 30px;
                margin-bottom: 40px;
                @media only screen and (max-width: $breakpoint-max) {
                    font-size: 24px;
                    line-height: 34px;
                    margin-bottom: 20px;
                }
            }
            :global(.col-sm-12) {
                padding: 0;
            }
            :global(.section-7Richtext) {
                text-align: justify;
                @media only screen and (max-width: $breakpoint-max) {
                    margin-top: 20px;
                }
                :global(p) {
                    font-size: 22px;
                    line-height: 42px;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                    :global(span) {
                        color: #8347ad;
                        font-size: 22px;
                        line-height: 42px;
                        display: inline;
                        @media only screen and (max-width: $breakpoint-max) {
                            font-size: 18px;
                            line-height: 36px;
                        }
                    }
                    :global(a) {
                        font-size: 22px;
                        line-height: 42px;
                        display: inline;
                        @media only screen and (max-width: $breakpoint-max) {
                            font-size: 18px;
                            line-height: 36px;
                        }
                    }
                }
            }
            :global(button) {
                background: $pink;
                padding: 10px 50px;
                color: #fff;
                border-radius: 15px;
                outline: none;
                border: none;
                font-size: 24px;
                margin: 10px 0 0px;
                @media only screen and (max-width: 768px) {
                    width: auto;
                }
                @media only screen and (max-width: 767px) {
                    width: 80%;
                    font-size: 20px;
                    padding: 10px 15px;
                    margin: 10px 0 30px;
                }
            }
            :global(.youtube-video) {
                margin: 30px auto 30px;
                width: 640px;
                padding: 20px;
                background: #f5f5f5;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    margin: 25px auto 15px;
                    iframe {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }
            }
        }
        :global(.section-8) {
            margin: 60px 0 30px 0;
            @media only screen and (max-width: $breakpoint-max) {
                margin-top: 5px;
                margin-bottom: 30px;
            }
            :global(.section-8-image) {
                width: 30%;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                }
            }
            :global(.section-8text) {
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                @media only screen and (max-width: $breakpoint-max) {
                    width: 100%;
                    margin-top: 20px;
                }
                :global(p) {
                    font-size: 22px;
                    line-height: 42px;
                    margin-bottom: 0;
                    @media only screen and (max-width: $breakpoint-max) {
                        font-size: 18px;
                        line-height: 36px;
                    }
                    :global(span) {
                        color: #8347ad;
                        font-size: 22px;
                        line-height: 42px;
                        display: inline;
                        @media only screen and (max-width: $breakpoint-max) {
                            font-size: 18px;
                            line-height: 36px;
                        }
                    }
                    :global(a) {
                        font-size: 22px;
                        line-height: 42px;
                        display: inline;
                        @media only screen and (max-width: $breakpoint-max) {
                            font-size: 18px;
                            line-height: 36px;
                        }
                    }
                }
            }
        }
        :global(.section-9) {
            @media only screen and (max-width: $breakpoint-max) {
                margin-bottom: 20px;
            }
            :global(span) {
                font-size: 14px;
                @media only screen and (max-width: $breakpoint-max) {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: left;
                }
            }
        }
        :global(.section-10image) {
            margin: 10px 0 50px;
            padding: 20px 0;
            border-top: 2px #522d80 solid;
            border-bottom: 2px #522d80 solid;
            @media only screen and (max-width: $breakpoint-max) {
                padding: 0;
                margin: 10px -15px 0px -15px;
            }
        }
    }
}

// /* -------------------------------------- Interview with Naomi Masuko -----------------------------   */
// :global(.InterviewNaomiMasuko) {
//     :global(.content-lt) {
//         :global(img) {
//             width: auto;
//             @media only screen and (max-width: $breakpoint-max) {
//                 width: 100%;
//                 margin-left: 0;
//             }
//         }

//         :global(.section-1) {
//             display: flex;
//             margin: 20px 0;
//             @media only screen and (max-width: $breakpoint-max) {
//                 flex-direction: column;
//             }
//             @media only screen and (min-width: $breakpoint-ipad-max) {
//                 float: left;
//                 display: block;
//             }
//             :global(.article-sports) {
//                 width: 15%;
//                 :global(img) {
//                     width: auto;
//                 }
//                 @media only screen and (min-width: $breakpoint-ipad-max) {
//                     float: left;
//                 }
//             }

//             :global(.intro-title) {
//                 width: 85%;
//                 padding-left: 10px;
//                 font-size: 24px;
//                 line-height: 42px;
//                 color: #595757;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                     padding: 0;
//                     margin-top: 25px;
//                     font-size: 22px;
//                     line-height: 24px;
//                 }
//                 @media only screen and (min-width: $breakpoint-ipad-max) {
//                     width: 85%;
//                     float: right;
//                     text-align: left;
//                 }
//                 :global(.heading-h2) {
//                     font-size: 40px;
//                     line-height: 56px;
//                     color: #602d84;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 30px;
//                         line-height: 40px;
//                         margin-top: 15px;
//                     }
//                 }
//                 :global(span) {
//                     display: inline;
//                     font-size: 24px;
//                     line-height: 42px;
//                     color: #602d84;
//                     padding: 0;
//                     width: auto;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         width: 100%;
//                         padding: 0;
//                         margin-top: 10px;
//                         font-size: 22px;
//                         line-height: 24px;
//                         text-align: left;
//                     }
//                 }
//                 :global(.intro-head) {
//                     width: 80%;
//                 }
//             }

//             :global(.article-sports-sp) {
//                 @media only screen and (max-width: $breakpoint-max) {
//                     text-align: center;
//                 }
//                 :global(img) {
//                     @media only screen and (max-width: $breakpoint-max) {
//                         width: auto;
//                     }
//                 }
//             }
//         }
//         :global(.section-2) {
//             display: flex;
//             margin-top: 28px;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin-top: 14px;
//             }
//             :global(.section2Text) {
//                 width: 56.5%;
//                 font-size: 22px;
//                 line-height: 42px;
//                 color: #595757;
//                 text-align: justify;
//                 margin-right: 20px;
//                 :global(img) {
//                     float: right;
//                     width: auto;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         margin: 0 0 0 15px;
//                     }
//                 }
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 18px;
//                     line-height: 36px;
//                     width: 100%;
//                     margin-right: 0;
//                 }
//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     text-align: justify;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//             :global(.section2Img) {
//                 width: 35%;

//                 @media only screen and (min-width: $breakpoint-ipad-max) {
//                     margin-left: 30px;
//                 }
//             }
//         }
//         :global(.section-3) {
//             margin-top: 60px;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin: 15px -15px 0;
//             }
//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     margin: 0 15px 30px 15px;
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                 }
//             }
//             :global(.section-3Text) {
//                 margin-top: 35px;
//                 text-align: justify;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 22px;
//                     line-height: 45px;
//                     padding: 0;
//                     margin-top: 20px;
//                 }
//                 :global(p) {
//                     font-size: 22px;
//                     line-height: 45px;

//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                         padding: 0 15px;
//                     }
//                     :global(span) {
//                         color: #8347ad;
//                         display: inline;
//                         font-size: 22px;
//                         line-height: 42px;
//                         padding: 0;
//                         @media only screen and (max-width: $breakpoint-max) {
//                             font-size: 18px;
//                             line-height: 36px;
//                         }
//                     }
//                 }
//             }
//         }
//         :global(.section-4) {
//             display: flex;
//             flex-direction: row;
//             margin: 35px 0 0;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin: 0;
//             }
//             :global(.whisperkol-header) {
//                 float: left;
//                 width: 100%;
//                 display: block;
//                 :global(h2) {
//                     background-color: #e85a9a;
//                     color: white;
//                     display: inline-block;
//                     font-size: 22px;
//                     line-height: 35px;
//                     font-weight: 500;
//                     border-radius: 3px;
//                     margin: 0 0 40px;
//                     padding: 1px 8px 2px;

//                     @media only screen and (max-width: $breakpoint-max) {
//                         margin-bottom: 30px;
//                         padding: 2px 15px;
//                         font-size: 18px;
//                         line-height: 34px;
//                     }
//                 }
//             }
//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;

//                 @media only screen and (max-width: $breakpoint-max) {
//                     margin-bottom: 30px;
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                 }
//             }
//             :global(.whisperkol-ArticleImg) {
//                 width: 45%;
//                 max-width: 45%;
//                 padding-left: 0px;
//                 padding-right: 15px;
//             }
//             :global(.section-4Text) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 width: 55%;
//                 max-width: 55%;
//                 padding-left: 15px;
//                 padding-right: 0px;
//                 text-align: justify;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                     max-width: 100%;
//                     padding: 0;
//                     font-size: 18px;
//                     line-height: 36px;
//                     color: #595757;
//                 }
//                 @media only screen and (min-width: $breakpoint-ipad-max) {
//                     width: 55%;
//                     max-width: 55%;
//                 }
//                 :global(p) {
//                     font-size: 22px;
//                     line-height: 45px;
//                 }
//                 :global(img) {
//                     @media only screen and (max-width: $breakpoint-max) {
//                         float: right;
//                         width: auto;
//                         margin: 0 0 0 10px;
//                     }
//                 }
//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//         }
//         :global(.section-5) {
//             margin-top: 60px;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin-top: 10px;
//             }
//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                 }
//             }
//             :global(.RichText) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 text-align: justify;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 18px;
//                     line-height: 36px;
//                 }
//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     padding: 0;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//             :global(p) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 margin: 10px 0;
//                 float: left;
//                 width: 100%;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 18px;
//                     line-height: 36px;
//                 }

//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//         }
//         :global(.section-6) {
//             margin-top: 35px;
//             @media only screen and (max-width: $breakpoint-max) {
//                 flex-direction: column-reverse;
//                 margin-top: 0;
//                 display: block;
//             }
//             :global(.whisperkol-header) {
//                 float: left;
//                 width: 100%;
//                 display: block;
//                 :global(h2) {
//                     background-color: #e85a9a;
//                     color: white;
//                     display: inline-block;
//                     font-size: 22px;
//                     line-height: 35px;
//                     font-weight: 500;
//                     border-radius: 3px;
//                     margin: 0 0 40px;
//                     padding: 1px 8px 2px;

//                     @media only screen and (max-width: $breakpoint-max) {
//                         padding: 2px 15px;
//                         font-size: 18px;
//                         line-height: 34px;
//                         margin: 20px 0;
//                     }
//                 }
//             }
//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;

//                 @media only screen and (max-width: $breakpoint-max) {
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                     margin: 20px 15px;
//                 }
//             }
//             :global(.RichText-6) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 width: 55%;
//                 padding: 0 15px;
//                 text-align: justify;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                     font-size: 18px;
//                     line-height: 36px;
//                     margin-top: 30px;
//                 }

//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//             :global(p) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 margin: 10px 0;
//                 float: left;
//                 width: 100%;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 18px;
//                     line-height: 36px;
//                 }
//                 :global(span) {
//                     color: #8347ad;
//                     display: inline;
//                     font-size: 22px;
//                     line-height: 42px;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//             :global(img) {
//                 margin: 35px 0;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     margin: 15px 0;
//                 }
//             }

//             :global(.whisperkol_article-6) {
//                 width: 45%;
//                 padding: 0 15px 0 0;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                     padding: 0 15px;
//                 }
//             }
//         }
//         :global(.section-7) {
//             margin: 35px 0 0 0;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin-top: 10px;
//             }
//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                 }
//             }
//             :global(.col-sm-12) {
//                 padding: 0;
//             }

//             :global(p) {
//                 font-size: 22px;
//                 line-height: 45px;
//                 float: left;
//                 width: 100%;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 18px;
//                     line-height: 36px;
//                 }
//                 :global(span) {
//                     color: #8347ad;
//                     font-size: 22px;
//                     line-height: 42px;
//                     display: inline;
//                     padding: 0;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//                 :global(a) {
//                     font-size: 22px;
//                     line-height: 42px;
//                     display: inline;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                 }
//             }
//         }
//         :global(.section-8) {
//             margin: 45px 0 30px 0;
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin-top: 5px;
//                 margin-bottom: 30px;
//             }

//             :global(h2) {
//                 background-color: #e85a9a;
//                 color: white;
//                 display: inline-block;
//                 font-size: 22px;
//                 line-height: 35px;
//                 font-weight: 500;
//                 border-radius: 3px;
//                 margin: 0 0 40px;
//                 padding: 1px 8px 2px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     padding: 2px 15px;
//                     font-size: 18px;
//                     line-height: 34px;
//                 }
//             }
//             :global(.section-8-image) {
//                 width: 30%;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                 }
//             }
//             :global(.section-8text) {
//                 width: 65%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: flex-end;
//                 margin-left: 30px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     width: 100%;
//                     margin-top: 20px;
//                     margin-left: 0;
//                 }
//                 :global(p) {
//                     font-size: 18px;
//                     line-height: 34px;
//                     margin-bottom: 0;
//                     @media only screen and (max-width: $breakpoint-max) {
//                         font-size: 18px;
//                         line-height: 36px;
//                     }
//                     :global(span) {
//                         color: #8347ad;
//                         font-size: 18px;
//                         line-height: 34px;
//                         display: inline;
//                         @media only screen and (max-width: $breakpoint-max) {
//                             font-size: 18px;
//                             line-height: 36px;
//                         }
//                     }
//                     :global(a) {
//                         font-size: 18px;
//                         line-height: 34px;
//                         display: inline;
//                         @media only screen and (max-width: $breakpoint-max) {
//                             font-size: 18px;
//                             line-height: 36px;
//                         }
//                     }
//                 }
//             }
//         }
//         :global(.section-9) {
//             @media only screen and (max-width: $breakpoint-max) {
//                 margin-bottom: 20px;
//             }
//             :global(span) {
//                 font-size: 14px;
//                 @media only screen and (max-width: $breakpoint-max) {
//                     font-size: 12px;
//                     line-height: 18px;
//                     text-align: left;
//                 }
//             }
//         }
//         :global(.section-10image) {
//             margin: 10px 0 50px;
//             padding: 20px 0;
//             border-top: 2px #522d80 solid;
//             border-bottom: 2px #522d80 solid;
//             @media only screen and (max-width: $breakpoint-max) {
//                 padding: 0;
//                 margin: 10px -15px 0px -15px;
//             }
//         }
//     }
// }
`;

export default styles;