import { uniq, includes } from 'lodash';

const countArticles = (categories) => {
    const titles = categories.reduce((acc, cat) => {
        if (cat) { cat.articles.items.forEach((a) => acc.push(a.title)); }
        return acc;
    }, []);
    return uniq(titles).length;
};

const handleArticleTextContent = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    const pathName = window.location.href;
    const segmentArray = pathName.split('/');
    if (segmentArray[segmentArray.length - 1] === '') segmentArray.pop();
    const lastSegment = segmentArray.pop();
    let articleTextContent = false;
    switch (lastSegment) {
    case 'article-top':
        articleTextContent = '尿もれに関する全てのトピック';
        break;
    case 'highlights':
        articleTextContent = '尿もれに関する最新のトピック';
        break;
    case 'incontinence-among-women':
        articleTextContent = '女性の尿尿もれに関する全てのトピック';
        break;
    case 'urinary-incontinence-and-management-of-life':
        articleTextContent = '尿もれと生活の管理に関する全てのトピック';
        break;
    case 'urinary-incontinence-faq':
        articleTextContent = '尿もれ製品のFAQに関する全てのトピック';
        break;
    case 'bladder-leakage-in-women':
        articleTextContent = '女性の膀胱漏出に関する全てのトピック';
        break;
    case 'stress-incontinence':
        articleTextContent = '腹圧性尿失禁に関する全てのトピック';
        break;
    case 'urge-incontinence':
        articleTextContent = '切迫性尿失禁に関する全てのトピック';
        break;
    case 'frequent-urination':
        articleTextContent = '頻尿に関する全てのトピック';
        break;
    case 'overactive-bladder':
        articleTextContent = '過活動膀胱に関する全てのトピック';
        break;
    case 'incontinence-training-for-women':
        articleTextContent = '女性のための尿もれ訓練に関する全てのトピック';
        break;
    case 'incontinence-and-pregnancy':
        articleTextContent = '尿もれと妊娠に関する全てのトピック';
        break;
    case 'changes-in-the-bladder-associated-with-menopause':
        articleTextContent = '閉経に伴う膀胱の変化に関する全てのトピック';
        break;
    default:
        articleTextContent = false;
    }
    return articleTextContent;
};

const isCurrentCategory = (categoryUrl, pageInfo) => {
    if (typeof window === 'undefined' && !pageInfo) {
        return false;
    }

    const currentPageUrl = typeof window !== 'undefined' ? window.location.pathname : pageInfo.url;

    return includes([currentPageUrl, `${currentPageUrl}/`, currentPageUrl.slice(0, -1)], categoryUrl);
};

const getCategoryArticles = (categories, pageInfo) => {
    // if (!process.browser) {
    //     return [];
    // }
    const currentPageUrl = typeof window !== 'undefined' ? window.location.pathname : pageInfo.url;
    const firstItemUrl = categories && categories.items[0] && categories.items[0].page.seoHead.url;
    console.log(currentPageUrl, 'getCategoryArticles  helper - currentPageUrl -83');
    /*
        Removing the first item when on "All Articles Page" because otherwise it would first display
        articles from "All categories" and then again for the each category
    */
    if (currentPageUrl === firstItemUrl || currentPageUrl === `${firstItemUrl}/`) {
        return categories.items.slice(1);
    }
    console.log(categories, 'getCategoryArticles  helper - categories -91');

    return categories.items.filter((category) => {
        const categoryUrl = category && category.page.seoHead.url;

        return isCurrentCategory(categoryUrl, pageInfo);
    });
};

const getArticleLists = (articleCategories) => {
    const articles = [];
    console.log(articleCategories, 'articleCategories helper line -101');
    articleCategories.forEach((data) => {
        if (data) articles.push(...data.articles.items);
    });
    console.log(articles, 'articleslist helper - line -105');
    return articles;
};

export { countArticles, getCategoryArticles, isCurrentCategory, handleArticleTextContent, getArticleLists };
