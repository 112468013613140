import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.email-thanks-reg-container{
    width: 940px;
    float:left;
    position: relative;
    margin:0 auto 60px; 
    font-family: 'M PLUS Rounded 1c', sans-serif;
    .email-thanks-reg{
        float:left;
        position: relative;
        width:100%;
        .heading{
            color:$purple-dark;
            font-size: 40px;
            line-height: 58px;
            text-align:center;
            margin: 0;
        }
        .sub-heading{
            color:$purple-dark;
            font-size: 24px;
            line-height: 42px;
            text-align:center;
            margin: 0 0 25px 0;
        }
        .section{
            width:100%;
            float:left;
            position: relative;
        
            h3{
                font-size: 24px;
                color:$purple-dark;
                text-align: center;
                margin: 0 0 18px 0;
            }
            .cta-container{ 
                width:100%;
                float:left;
                text-align: center;
            }
            button{
                border:none;
                background:none;
                font-size: 26px;
                color:#fff;
                border-radius: 20px;
                width: 380px;
                text-align: center;
                padding: 4px 45px;
                margin:10px 0;
            }

            .section-left{
                // float:left;
                text-align: center;
                width:100%;  
               
                .left-cta{
                    background:$purple;
                }    

               
            }
            // .section-right{
            //     float:left;
            //     width:50%;
            //     .right-cta{
            //         background: linear-gradient(to right,#977076 0%,#efd1c6 15%,#e4c2b8 20%,#e4c2b8 20%,#b68488 28%,#b68488 72%,#e4c2b8 80%,#efd1c6 87%,#977076 100%);
            //     }
            // }
            
        }

        .ID-Bind-Section{
            width:100%;
            float:left;
            position: relative;
            margin: 50px auto 0;
            text-align: center;
            .ID-Bind-container{
                width:100%;
                float:left;
                .idbind-cta{
                    height: 50px;
                    width: auto;
                    padding: 0 20px;
                    font-size: 26px;
                    color: white;
                    border-radius: 10px;
                    cursor: pointer;
                    background-image: linear-gradient(to right,#382f62,#5f3c85,#382f62);
                    border: none;
                    min-width: 280px;
                }
            }
        }
    }
    @media (max-width: $breakpoint-max){
        width: 100%;
        margin:60px auto; 
        padding:0 20px;
        .email-thanks-reg{
            .heading{
                color:$purple-dark;
                font-size: 30px;
                line-height: 40px;
                text-align:left;                
            }
            .sub-heading{
                font-size: 20px;
                line-height: 28px;
                text-align:left;
                margin: 0 0 15px 0;
            }
            .section{
                display:block;
                float:left;
                
                h3{
                    font-size: 20px;
                    text-align: left;
                    margin: 0 0 10px 0;
                }
                button{
                    font-size: 21px;
                    width: 280px;
                    padding: 4px 20px;
                }    
                .section-left{
                    width:100%;   
                    margin: 0 0 20px 0;               
                }
                .section-right{
                    width:100%;
                    margin: 0 0 10px 0; 
                }                
            }
        }
    }
    .email-error-reg {
        text-align: center;
        position: relative;
        top: 30px;
        h1 {
            font-size: 30px;
            color: #8347ad;
        }
        h2 {
            font-size: 30px;
            color: #8347ad;
        }
    }
}
`;

export default styles;