module.exports = (preview) => `
{
  campaginCollection(limit: 1,preview: ${preview}) {
    items {
      campaiginContentCollection(limit: 10,preview: ${preview}) {
        items {
          __typename
          ... on SectionContainer {
            __typename
            backgroundImage {
              url
            }
            styles
            sectionContentCollection(limit: 8,preview: ${preview}) {
              items {
                __typename
                ... on SectionContainer {
                  __typename
                  styles
                  backgroundImage {
                    url
                  }
                  sectionContentCollection(limit: 6,preview: ${preview}) {
                    items {
                      __typename
                      ... on SectionContainer {
                        __typename
                        styles
                        sectionContentCollection(limit: 10,preview: ${preview}) {
                          items {
                            __typename
                            ... on SamplingProductCard {                                                       
                              productName
                              productWeight
                              productImage{
                                url
                              }
                              productUrl
                              style
                            }
                          }
                        }
                      }
                      ... on ImageSet {
                        style
                        desktopImage {
                          url
                        }
                        smartPhoneImage {
                          url
                        }
                      }
                    }
                  }
                }
                ... on ImageSet {
                  style
                  desktopImage {
                    url
                  }
                  smartPhoneImage {
                    url
                  }
                }
                ... on ContentTypeRichText {
                  styles
                  body
                }
                ... on SmallPromoBannerCta {
                  nameEn
                  smallText
                  bigText
                  midText
                  imageDt {
                    cloudinaryId
                    description
                    cloudinaryExtension
                    asset{url}
                  }
                  imageSp {
                    cloudinaryId
                    cloudinaryExtension
                    description
                    asset{url}
                  }
                  button {
                    ... on ButtonExternal {
                      nameEn
                      text
                      title
                      externalUrl
                    }
                    ... on ButtonInternal {
                      text
                      target
                      title
                      url {
                        seoHead {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
