module.exports = `
... on ContentMd {
    content {
        json
    }
    contentMobile {
        json
    }
}
`;
