import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.digital-campaign-barcode {
    width: 940px;
    margin: 30px auto;
    position: relative;
    text-align: center;
    .barcode-img {
        margin: 0 auto;
        width: 50%;
    }
    .kawachi-info {
        color: #592c82;
        font-size: 20px;
        font-weight: 500;
    }
    .barcodewithimage {
        width: 47%;
        display: block;
        // border: 1px solid #592c82;
        box-shadow: -2px 0 8px 0 gray;
        float: left;
        position: relative;
        margin: 10px;
        padding: 10px;

        .product {
            width: 30%;
            height: auto;
            position: relative;
            margin: 0px auto;
            min-height: 203px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
            }
        }
        .barcode {
            width: 70%;
            margin: 0 auto;
            padding: 20px;
            img {
                width: 100%;
            }
        }
    }
    .fadeIn {
        img {
            opacity: 0.05;
            transition: opacity 0.5s;
            -webkit-transition: opacity 0.5s;
        }
    }
    .fadeOut {
        img {
            opacity: 1;
            transition: opacity 0.5s;
            -webkit-transition: opacity 0.5s;
        }
    }
    img {
        width: 100%;
        max-width: 400px;
        min-width: 100px;
    }
    .cta {
        background: url("https://images.ctfassets.net/8g0fievzqg8k/7HUehNp61LASmf5vYQNw2D/bb5f1d5f88a5905ceb0438bfdce67a86/coupon_pc.png")
            no-repeat 5px 0px;
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: center;
        padding: 4px 20px;
        width: 30%;
        height: 50px;
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-weight: 500;
        font-size: 28px;
        letter-spacing: 5px;
    }
    .digital-campaign-barcode-txt {
        display: block;
        margin: 20px 0;
        width: 100%;
        float: left;
    }
    @media only screen and (max-width: $breakpoint-max) {
        width: 100%;
        padding: 0 20px;
        margin: 0 auto 40px;
        .barcodewithimage {
            width: 100%;
            margin: 20px 0;
            .product {
                width: 50%;
                min-height: auto;
                img {
                    width: 100%;
                    margin: 0 auto;
                    text-align: center;
                }
            }
        }
        .cta {
            margin: 20px 0;
            width: 100%;
        }
    }
}
`;

export default styles;