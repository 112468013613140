import React from 'react';
import { find } from 'lodash';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const findUrlById = (id, allPages) => {
    const foundPage = find(allPages, (page) => page.sys.id === id);
    return foundPage.seoHead.url;
};

const getImage = (content, images) => {
    const imageData = content[0] && content[0].props.children[0];

    const imageMatch = imageData.match(/image-(\d)-(left|right)/);

    const imageNumber = imageMatch[1];
    const imageFloat = imageMatch[2];

    const style = {
        float: imageFloat,
        margin: imageFloat === 'left' ? '0 40px 0 0' : '0 0 0px 40px',
    };
    return <div style={style} className="image"><img src={buildContentfullImageUrl(images[imageNumber])} alt="test" /></div>;
};

const prepareRichTextContent = (json, allPages, images) => {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <i>{text}</i>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.QUOTE]: (node, children) => getImage(children, images),
            [BLOCKS.HR]: () => <div className="spacing" />,
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const url = findUrlById(node.data.target.sys.id, allPages);
                return <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url}>{children}</a>;
            },
        },
    };
    return documentToReactComponents(json, options);
};

export { prepareRichTextContent };
