import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import DropdownContent from "../../RetailersDropdown/DropdownContent";
import SearchInput from "../../UI/SearchInput/SearchInput";
import RenderItems from "./RenderItems";
import style from "./MobileNavigation.scss";
import { buildContentfullImageUrl } from "../../ReusableComponents/imageHelper";
import {
  hamburgerIcon,
  mobileHeaderAndFooter,
  myrepiLogo,
} from "../../../helpers/constants";
import Modal from "../../Dialog/Modal";

const MobileMenu = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isProductOpen, setProductOpen] = useState(false);
  const [isPickUpOpen, setPickUpOpen] = useState(false);
  const [isRetailerOpen, setRetailerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [isFirstMenuOpen, setIsFirstMenuOpen] = useState(0);

  const menuRef = useRef();
  const menuIconRef = useRef();
  const pickUpIconRef = useRef();
  const productIconRef = useRef();
  const RetailerIconRef = useRef();

  const prepareMenuItems = () => {
    const loginItem = props.loginColumn;
    const retailers = props.retailers?.items?.[0] ?? {};
    const productFeature = props.menuItems?.filter((item) =>
      item.url?.includes("#product-feature")
    )?.[0];
    const productMenuArr = props.menuItems?.filter((item) =>
      item.url?.includes("#product-section")
    )?.[0];
    const articleSection = props.menuItems?.filter((item) =>
    item?.url?.includes("article-top")
  )?.[0];
    const menuCategoryCollection =
      productMenuArr.menuCategoryCollection.items?.[1]?.sectionContentCollection?.items?.map(
        (itm) => {
          return {
            textValue: itm.header ?? "",
            url: itm.cardActionCollection.items?.[0].url ?? "",
          };
        }
      ) || [];
    let productMenu = { ...productMenuArr };
    productMenu.menuCategoryCollection = [
      productMenuArr.menuCategoryCollection?.items?.[0] ?? {},
      ...menuCategoryCollection,
    ];
    return {
      loginItem,
      retailers,
      productFeature,
      productMenu,
      articleSection
    };
  };

  const menuItems = prepareMenuItems();

  const handleClick = (e) => {
    const clickedOutsideMenu =
      !menuRef.current?.contains(e.target) ||
      menuIconRef.current.contains(e.target) ||
      pickUpIconRef.current.contains(e.target) ||
      productIconRef.current.contains(e.target);
    const clickedMenuIcon =
      menuIconRef.current.contains(e.target) ||
      pickUpIconRef.current.contains(e.target) ||
      productIconRef.current.contains(e.target);

    if (clickedOutsideMenu && !clickedMenuIcon) {
      if (menuIconRef.current.contains(e.target)) {
        setMenuOpen(false);
      } else {
        setMenuOpen(isMenuOpen);
      }
      if (pickUpIconRef.current.contains(e.target)) {
        setProductOpen(false);
      } else {
        setProductOpen(isProductOpen);
      }
      if (productIconRef.current.contains(e.target)) {
        setPickUpOpen(false);
      } else {
        setPickUpOpen(isPickUpOpen);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  const onMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
    setProductOpen(false);
    setPickUpOpen(false);
    setOpenModal(false);
  };
  const onProductToggle = () => {
    setProductOpen(!isProductOpen);
    setMenuOpen(false);
    setPickUpOpen(false);
    setRetailerOpen(false);
  };
  const onPickUpToggle = () => {
    setPickUpOpen(!isPickUpOpen);
    setMenuOpen(false);
    setRetailerOpen(false);
    setProductOpen(false);
  };

  const onRetailerToggle = () => {
    setRetailerOpen(!isRetailerOpen);
    setPickUpOpen(false);
    setMenuOpen(false);
    setProductOpen(false);
  };

  const onSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSearch = () => {
    window.location = `/viewsearchresult?q=${searchTerm}`;
  };

  const menuClassName = classnames("menu", {
    isOpen: isMenuOpen || isPickUpOpen || isProductOpen || isRetailerOpen,
  });
  const isHeaderLogo =
    typeof window !== "undefined" && window.location.pathname === "/";

  const handleJumpLink = (target) => (ev) => {
    setMenuOpen(false);
    if (target?.includes("http")) {
      window.location.href = target;
      return;
    } else if (target?.includes("#")) {
      ev?.preventDefault();
      const targetEl = document.querySelector(target);
      if (targetEl) {
        const headerOffset = 110;
        const elementPosition = targetEl.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      } else {
        let { origin } = window.location;
        router.push(`${origin}/${target}`);
      }
    }
  };

  return (
    <div className="mobile-menu">
      {!isHeaderLogo ? (
        <div className="header">
          <a
            className="event_menu_click"
            data-action-detail="Whisper logo"
            href="/"
          >
            <img
              src={buildContentfullImageUrl(mobileHeaderAndFooter)}
              className="logo"
              width="100"
              alt="Whisper logo"
              title="Whisper logo"
            />
          </a>
        </div>
      ) : (
        <div className="header">
          <h1>
            <a
              className="event_menu_click"
              data-action-detail="Whisper logo"
              href="/"
            >
              <img
                src={buildContentfullImageUrl(mobileHeaderAndFooter)}
                className="logo mobile-header-logo"
                width="100px"
                height="100px"
                alt="Whisper logo"
                title="Whisper logo"
              />
              <span>吸水ケア・尿漏れケアのウィスパー</span>
            </a>
          </h1>
        </div>
      )}
      <div className="floating-menu">
        <div className="menu-items">
          <button
            className={`MenuButton ${isMenuOpen ? "" : ""}`}
            onClick={onMenuToggle}
            ref={menuIconRef}
            type="button"
          >
            {isMenuOpen === true ? (
              <span className="hamburger-menu-cross">&times;</span>
            ) : (
              <img src={hamburgerIcon} />
            )}
          </button>
        </div>
        {isMenuOpen && (
          <div className={menuClassName} ref={menuRef}>
            <ul className="outer">
              <li className="product-feature-mobile">
                <a
                  href={menuItems.productFeature.url}
                  onClick={handleJumpLink(menuItems.productFeature.url)}
                >
                  {menuItems.productFeature.textValue}
                  <span>{">"}</span>
                </a>
              </li>
              <li className="product-feature-mobile">
                <a
                  href={menuItems.productMenu.url}
                  className="header-li-underline"
                  onClick={handleJumpLink(menuItems.productMenu.url)}
                >
                  {menuItems.productMenu.title}
                  <span>{">"}</span>
                </a>
                <ul className="header-submenu-ul">
                  {menuItems.productMenu.menuCategoryCollection?.map((item) => {
                    return (
                      <li>
                        <a href={item.url} onClick={()=>{setMenuOpen(false)}}>{item.textValue}</a>
                        <span>{">"}</span>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="product-feature-mobile">
                <a
                  href={menuItems?.articleSection?.url}
                  onClick={handleJumpLink(menuItems?.articleSection?.url)}
                >
                  {menuItems?.articleSection?.textValue}
                  <span>{">"}</span>
                </a>
              </li>
            </ul>
            <button
              className="pop-btn"
              onClick={() => setOpenModal(!openModal)}
            >
              {menuItems.retailers.dropdownTitle}
              <span className="mobile-popup-btn-arrow">{">"}</span>
            </button>
            {openModal && isMenuOpen && (
              <Modal
                open={openModal}
                backdropStyle={"retailer-popup-backdrop"}
                className="retailer-popup-mobile"
                modalStyle={"retailer-popup"}
              >
                <div className="retail-btn-wrapper">
                  {menuItems.retailers?.retailersCollection?.items?.map(
                    ({ imageDt, link }) => {
                      return (
                        <a
                          href={link.url}
                          aria-label={imageDt.description}
                          target="_blank"
                          className="image-btn"
                        >
                          <img src={imageDt.asset.url} />
                        </a>
                      );
                    }
                  )}
                </div>
                <button
                  onClick={() => setOpenModal(false)}
                  className="header-popup-btn-sp"
                >
                  <span className="modal-popup-close-btn-sp">&times;</span>
                  閉じる
                </button>
              </Modal>
            )}
            <a
              href={
                menuItems.loginItem?.menuCategoryCollection?.items?.[1]?.url
              }
              className="mobile-login-logo"
              target="_blank"
              aria-label={menuItems.loginItem?.title ?? "Login"}
            >
              <img src={myrepiLogo} />
            </a>
            <style>{"body{overflow-y:hidden !important}"}</style>
          </div>
        )}
      </div>
      <style jsx>{style}</style>
    </div>
  );
};

MobileMenu.propTypes = {
  menuItems: PropTypes.array,
  retailers: PropTypes.object,
  loginColumn: PropTypes.any,
};

export default MobileMenu;
