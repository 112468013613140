import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.coupon-Campaign-lp {
    width: 940px;
    // float:left;
    position: relative;  

    .coupon-title {
        text-align: center;
        position: relative;
        font-size: 30px;
        margin:40px 0;
        &:before {
            content: "";
            width:100% ; 
            border-bottom: 3px solid #000;
            position: absolute;
            display: inline-block;
            z-index: -1;
            left:0; 
            top :50%
        }
        span {
            background-color: #fff;
        }
    
    }

    .Coupon-Campaing-Banner{
        width:940px;
        margin:0 auto;
        position: relative;
        img{
            width:100%;
        }
        span {
            border: 0!important;
            clip: rect(0,0,0,0)!important;
            height: 1px!important;
            margin: -1px!important;
            overflow: hidden!important;
            padding: 0!important;
            position: absolute!important;
            width: 1px!important;
        }
    }  
    
    .line-below-banner {
        text-align: center;
        font-size: 24px;
        color : #8347ad;
        font-weight: bold;
        margin-top: 20px;

}
.welcia-banner{
    margin-top:90px;
}

    @media only screen and (max-width: $breakpoint-max) {
        
        .Coupon-Campaing-Banner{
            width:100%;
            // margin:0 auto 10px;
            // padding:0 20px;
            img{
                width:100%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    
    .coupon-Campaign-lp {
        width: 100%;
        padding:10px 20px 0;
        .coupon-title {
            font-size: 25px;
        }
        .Coupon-Campaing-Banner{
            width:100%;
            // margin:0 auto 10px;
            // padding:0 20px;
            img{
                width:100%;
            }
        }

        .line-below-banner {
            text-align: center;
            font-size: 0.8em;
            color : #8347ad;
            font-weight: bold;
    
        }
        .welcia-banner{
            margin-top:30px;
        }

    
    }
}
`;

export default styles;