const preview = process.env.PREVIEW;
module.exports = `
... on ArticleList {
  categories: articleCategoriesCollection(limit: 18,preview: ${preview}) {
    items {
      nameEn
      name
      namesp
      page {
        seoHead {
          url}
      }
      articles: articlesCollection(limit: 18,preview: ${preview}) {
        items {
          title
          title1
          nameEn
          subheading1
          page {seoHead {url}}
          imageDt {
            description
            asset{url}}
          imageSp {
            description
            asset{url}}
        }}
    }}
}
`;
