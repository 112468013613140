const measuresOrder = ['absorbency', 'absorbency', 'absorbency', 'size'];

const buildProductItem = (product, measure) => ({
    measure: (product[measure] && product[measure].name) || '-',
    url: product.page && product.page.seoHead.url,
});

const buildCategoryItem = (category, measureToDisplay) => {
    const { nameEn, name, color, brand, page, products, image } = category;

    return {
        nameEn,
        name,
        image,
        url: page && page.seoHead.url,
        color: color && color.color,
        brand: brand && brand.name,
        products: products.items.map((product) => buildProductItem(product, measureToDisplay)),
    };
};

const buildFooterProductsListing = (categories) =>
    categories.items.map((category, index) => {
        const measureToDisplay = measuresOrder[index];
        return buildCategoryItem(category, measureToDisplay);
    });

export { buildFooterProductsListing, buildCategoryItem, buildProductItem };
