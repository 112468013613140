import css from 'styled-jsx/css';

const styles = css`// @import "./styles/global-variables";
// .main-container{
//     background-image: linear-gradient(to bottom, rgb(255,255,255) 20%, rgb(250,229,238) 75%);
@import "./styles/global-variables";
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
.main-container {
    .footer {
        margin-top: 0px;
    }
}

.content-wrapper {
    background: linear-gradient(180deg, #fff 51%, rgba(225, 19, 131, 0.2) 180%);
    width: 100%;
    padding-bottom: 130px;
    color: #592c82;

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        padding: 0 15px;
        margin: 0px auto;
    }

    .form_head {
        text-align: center;
    }
}

.preview-page-container {
    width: 80%;
    text-align: center;
    margin: auto auto;
    @media only screen and (max-width: 767px){
        width:100%;
        margin:0 auto;
        padding:0 20px;
        }

    .preview_text {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
    }

    .info-content {
        margin-top: 100px;
        text-align: left;
        h2 {
            font-size: 26px;
            font-weight: 600;
        }
        p {
            font-size: 22px;
            font-weight: 500;
        }
    }
    .lottery-qiz-area-wrap {
        width: 80%;
        @media only screen and (max-width: 767px){
            width:100%;
        }
    }

    .frm-button-section {
        width: 100%;
        margin: 100px 0 0;
        font-size: 26px;
        font-weight: 600;
        .button-left {
            text-align: center;
            width: 50%;
            display: inline-block;
            button {
                width: 95%;
                padding: 20px;
                outline: none;
                border: none;
                background: #770692;
                background: -moz-linear-gradient(to right, #770692 0%, #a580b7 100%);
                background: -webkit-linear-gradient(to right, #770692 0%, #a580b7 100%);
                background: linear-gradient(to right, #770692 0%, #a580b7 100%);
                color: #fff;
            }
        }
        .button-right {
            text-align: center;
            width: 50%;
            display: inline-block;
            button {
                width: 95%;
                padding: 20px;
                outline: none;
                border: none;
                background-color: #000;
                color: #fff;
            }
        }
        @media only screen and (max-width: 767px){
            font-size: 20px;

        }
    }
}

.preview-profile-container {
    width: 100%;
    .preview-profile-question {
        margin: 20px 15px 20px 0;
        display: inline-block;
        width: 29%;
        text-align: right;
        .preview-profile-question-text {
            font-size: 24px;
            font-weight: 500;
            @media only screen and (max-width: 767px) {
                font-size: 15px;
            }
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            text-align: left;
            padding-bottom: 0px;
            padding-left: 0;
            margin: 8px 15px 0px 0;
        }
    }

    .preview-profile-answer {
        margin: 20px 0;
        display: inline-block;
        text-align: left;
        width: 69%;
        padding-left: 20px;
        border-bottom: 2px solid #592c82;
        .preview-profile-answer-text {
            width: 100%;
            font-size: 24px;
            font-weight: 500;

            @media only screen and (max-width: 767px) {
                font-size: 15px;
                padding: 5px 5px 5px 0;
            }
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            font-size: 15px;
            padding-left: 0;
            margin:5px 0 10px;
        }
    }
}
`;

export default styles;