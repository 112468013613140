import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.ripplemockmagic-container {
    .color-selection {
        width: 100%;
        float: left;
        position: relative;
        border-bottom: 1px solid #000;
        margin: 20px auto;
        padding: 20px 0;
        .color-header {
            p {
                font-size: 28px;
                color: #000;
                font-weight: 600;
            }
        }
        .color-wrap {
            width: 20%;
            margin: 0 8px;
            display: inline-block;
            border-radius: 50%;
            img {
                width: 100%;
                border-radius: 50%;
            }
        }
    }

    .content-section {
        width: 100%;
        position: relative;
        margin: 20px auto;
        border-bottom: 1px solid #000;
        .ripplemock-title {
            color: $purple-dark;
            font-size: 28px;
            margin: 0 0 50px 0;
        }
        .ripplemock-text {
            font-size: 18px;
            line-height: 34px;
            margin: 15px 0;
            width: 100%;
        }
    }
    .accordian-section {
        width: 100%;
        float: left;
        position: relative;
        margin: 20px auto;
        border-bottom: 1px solid #000;
        .content-toggle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;

            p {
                font-size: 24px;
                color: #000;
                font-weight: 600;
            }
            .purple {
                color: $purple-dark;
            }
        }
    }

    .size-section {
        width: 100%;
        margin: 10px auto;
        border-bottom: 1px solid #000;
        float: left;
        position: relative;
        padding: 25px 0;
        .size-header {
            width: 100%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 28px;
                color: #000;
                font-weight: 600;
            }
            .sizepop-button{
                color: $purple;
            }
        }

        ul {
            margin: 10px auto;
            width: 100%;
            float: left;
            padding: 0;
            li {
                float: left;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 15%;
                border: 1px solid $purple-dark;
                margin: 5px;
                height: 65px;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: centet;
                    color: $purple-dark;
                    font-weight: 500;
                    font-size: 21px;
                }

                // &:hover {
                //     background: $purple-dark;
                //     span {
                //         color: #fff;
                //     }
                // }
            }
            .selected {
                background: $purple-dark !important;
                span {
                    color: #fff !important;
                }
            }
        }
    }
    .price-section {
        ul {
            li {
                width: 30%;
            }
            .selected {
                background: $purple-dark !important;
                span {
                    color: #fff !important;
                }
            }
        }
    }
    .notify-section {
        width: 100%;
        margin: 25px auto;
        float: left;
        text-align: center;
        position: relative;
    }

    .footer-section {
        float: left;
        width: 100%;
        position: relative;
        margin: 0 auto;
    }
}
`;

export default styles;