import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.collapse-open {
    max-height: 100vh;
}

.collapse-close {
    max-height: 0;
}
.collapse-container {
    transition: max-height 1s ease-in;
    overflow: hidden;
    a {
        width: 100%;
        color: $off-black;
        padding: 10px 20px;
        background-color: #fff;
        display: block;
        font-weight: normal;
        border: 1px solid #ccc;
    }
    .disabled {
        opacity: 0.5;
    }
}
.heading {
    width: 100%;
    color: $purple;
    padding: 10px 20px;
    background-color: rgba(193, 163, 214, 0.25);
    display: block;
    font-weight: 500;
    border: 1px solid #ccc;
}
a {
    width: 100%;
    padding: 10px 20px;
    display: block;
    color: $off-black;
    border: 1px solid #ccc;
}
.title {
    width: 100%;
    color: $off-black;
    padding: 10px 20px;
    display: block;
    border: 1px solid #ccc;
    &.active-in {
        color: $purple;
        background: $purple-ultra-light;
        font-weight: 500;
        &::before {
            content: "<";
            color: $purple;
            float: left;
            font-size: 18px;
            padding: 0 30px 0 0;
            font-weight: bold;
        }
    }
    &.active-out {
        &::after {
            content: ">";
            float: right;
        }
    }
}
`;

export default styles;