
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "next/head"

const PriceSpiderMetaTags = () => {
    const [pswDidInit, setPswDidInit] = useState(false)
    const [content, setContent] = useState(null)

    const initPSWOnEvent = (event) => {
        initPSW()
        event.currentTarget.removeEventListener(event.type, initPSWOnEvent)
    }
    const initPSW = () => {
        if (pswDidInit) {
            setContent(null)
            return false
        }
        const config = {
            url: process.env.PS_BUYNOW_URL,
            psKey: process.env.PS_BUYNOW_PASSKEY,
            psLanguage: process.env.PS_BUYNOW_PSLANG,
            psCountry: process.env.PS_BUYNOW_PSCOUNTRY
        }
        const { url, psKey, psCountry, psLanguage } = config

        const markup = (
            <Helmet>
                <script src={url} async />
                <meta name='ps-key' content={psKey} />
                <meta name='ps-country' content={psCountry} />
                <meta name='ps-language' content={psLanguage} />
            </Helmet>
        )
        setContent(markup)
        setPswDidInit(true)
    }
    useEffect(() => {
        document.addEventListener("DOMContentLoaded", () => {
            var timer = 3.5
            setTimeout(initPSW, timer * 1000)
        })
        document.addEventListener("scroll", initPSWOnEvent)
        document.addEventListener("mousemove", initPSWOnEvent)
        document.addEventListener("touchstart", initPSWOnEvent)

        return () => {
            document.removeEventListener("scroll", initPSWOnEvent)
            document.removeEventListener("mousemove", initPSWOnEvent)
            document.removeEventListener("touchstart", initPSWOnEvent)
        }
    }, [])

    return content
}

PriceSpiderMetaTags.propTypes = {
    url: PropTypes.string.isRequired,
    psKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    psCountry: PropTypes.string.isRequired,
    psLanguage: PropTypes.string.isRequired,
}

export default PriceSpiderMetaTags

