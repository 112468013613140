const preview = process.env.PREVIEW;
module.exports = `          ...on ProfileContent{
    nameEn
    title
    extraline
    shortHead1
    shortHead2
    profileImage{
      title
      imageDesktop{
        description
        cloudinaryId
        asset{url}
      }
      imageSmartphone{
        description
        cloudinaryId
        asset{url}}style}
    content
    link {
      __typename
      ...on Page {
        seoHead {url}}}
    akashiBannerCollection(limit:1,preview: ${preview}){
      items{
        nameEn
        ... on BannerWithPara {
          title
          bannerImage {
            imageDesktop {
              description
              cloudinaryId
              asset{url}}
            imageSmartphone {
              cloudinaryId
              description
              asset{url}}}
          title
          extraLine
          paraContent
          styles
        }
      }
    }
    styles
  }`;
