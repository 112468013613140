const { buildFooterLinks } = require('./Footer.helper');

test('Testing if footer navigation is being built correctly', () => {
    const contentfulData = {
        'items': [
            {
                'navigation': {
                    'navigationColumn': {
                        'items': [
                            {
                                'displayTitle': '利用規約',
                                'nameEn': 'Terms of service',
                                'link': {
                                    '__typename': 'ExternalLink',
                                    'url': 'https://termsandconditions.pg.com/ja-clean/',
                                },
                            },
                            {
                                'displayTitle': 'プライバシ',
                                'nameEn': 'Privacy ',
                                'link': {
                                    'newWindow': true,
                                    '__typename': 'ExternalLink',
                                    'url': 'https://privacypolicy.pg.com/ja/',
                                },
                            },
                            {
                                'displayTitle': 'AdChoices ',
                                'nameEn': 'AdChoices ',
                                'link': {
                                    '__typename': 'ExternalLink',
                                    'url': 'https://privacypolicy.pg.com/ja/#iba',
                                },
                            },
                            {
                                'displayTitle': 'サイトマップ',
                                'nameEn': 'Sitemap',
                                'link': {
                                    '__typename': 'Page',
                                    'seoHead': {
                                        'url': '/sitemap',
                                    },
                                },
                            },
                            {
                                'displayTitle': 'お問い合わせ',
                                'nameEn': 'Contact Us',
                                'link': {
                                    '__typename': 'Page',
                                    'seoHead': {
                                        'url': '/contact-us',
                                    },
                                },
                            },
                        ],
                    },
                },
            },
        ],
    };

    const expectedOutput = [
        {
            'title': '利用規約',
            'url': 'https://termsandconditions.pg.com/ja-clean/',
            'newWin': undefined,
        },
        {
            'title': 'プライバシ',
            'url': 'https://privacypolicy.pg.com/ja/',
            'newWin': true,
        },
        {
            'newWin': undefined,
            'title': 'AdChoices ',
            'url': 'https://privacypolicy.pg.com/ja/#iba',
        },
        {
            'newWin': false,
            'title': 'サイトマップ',
            'url': '/sitemap',
        },
        {
            'newWin': false,
            'title': 'お問い合わせ',
            'url': '/contact-us',
        },
    ];

    expect(buildFooterLinks(contentfulData)).toStrictEqual(expectedOutput);
});
