import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import displayquestionstyle from './displayquestions.scss';

const DisplayQuestions = (props) => {
    const { currentScreen, Data, selectAnswer, answers } = props;
    const [isChecked, setisChecked] = useState();
    useEffect(() => {
        window.scroll(0, 0);
    }, [currentScreen]);

    return (
        <div className="question-box">
            {Object.keys(Data[currentScreen]['options']).map((opt, index) => {
                const labelNames = Data[currentScreen]['options'][opt].label.split('~');
                return (
                    <div key={index} className={`opt${index + 1}` === isChecked ? 'list list-selected' : 'list'}>
                        <label>
                            <input
                                type="radio"
                                value={Data[currentScreen]['options'][opt].value}
                                name={Data[currentScreen].apikey}
                                key={Data[currentScreen]['options'][opt].label}
                                defaultChecked={
                                    Data[currentScreen]['options'][opt].value
                                    === answers[Data[currentScreen].apikey]
                                }
                                onChange={(e) => {
                                    selectAnswer(Data[currentScreen].apikey, e.target.value, opt);
                                    setisChecked(opt);
                                }}
                            />
                            <span className="text">
                                {labelNames[0]}<br />
                                {labelNames[1]}
                            </span>
                            <span className="checkmark" />
                        </label>
                    </div>
                );
            })}
            <style jsx>{displayquestionstyle}</style>
        </div>
    );
};
DisplayQuestions.propTypes = {
    currentScreen: PropTypes.func,
    Data: PropTypes.any,
    selectAnswer: PropTypes.string,
    answers: PropTypes.string,
};
export default DisplayQuestions;
