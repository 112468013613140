import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import style from './UserVideocommentstyle.scss';
import VideoCommets from './VideoCommets';
import UserCommentBox from '../../../src/components/UserCommentForm';
import { storageGet, storageSet, GetEmailVerified } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';

const handleGAEvents = async (eventCate, eventAction, eventLabel) => {
    await window.dataLayer.push({
        'event': 'customEvent',
        'GAeventCategory': eventCate,
        'GAeventAction': eventAction,
        'GAeventLabel': eventLabel,
        'GAeventValue': 0,
        'GAeventNonInteraction': false,
    });
    return true;
};
const Login = async (pathname) => {
    console.log('Login function pathname: ', pathname);
    await storageSet('videoFeedbackVia', true);
    if (handleGAEvents('event_crm_action', 'event_profile_feedback_login', 'whisper_login_feedback')) {
        Router.push('/login');
    }
};

const Register = async (pathname) => {
    console.log('Register function pathname: ', pathname);
    await storageSet('videoFeedbackVia', true);
    if (handleGAEvents('event_crm_action', 'event_profile_feedback_register', 'whisper_register_feedback')) {
        Router.push('/registration');
    }
};

// const feedbackVideo = async () => {
//     Router.push('/pelvic-training-exercise-video');
// };

// unused function might use later
// const gotoPelvic = async () => {
//     storageSet('videoFeedbackVia_incontinence', false);
//     storageSet('videoFeedbackVia_pelvic', true);
//     Router.push('/pelvic-training-exercise-video');
// };

// To Do chenage component name  UserVideoComments
const UserVideoComment = ({ pathname }) => {
    const [userloggedInstatus, setUserloggedInstatus] = useState(false);

    useEffect(() => {
        if (storageGet('isUserLoggedIn') && GetEmailVerified()) {
            setUserloggedInstatus(true);
        } else {
            setUserloggedInstatus(false);
        }

        window?.dataLayer?.push({
            'event': 'customEvent',
            'GAeventCategory': 'event_ratings_action',
            'GAeventAction': 'event_movie_review',
            'GAeventLabel': typeof window !== 'undefined' && window.location.href,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
    }, []);

    let resolution = useScreenSize();
    if (!resolution) {
        resolution = { isMobile: true };
    }

    const handleOnChangeEvent = () => {
        document.getElementById('postComments').scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <div className="videofeedback-container">
            <div className="VC-Headersection">
                <div className="VC-Headersection-text">
                    <span>....</span>
                    <h1>気になる!UI (尿もれ)事情 <br />
                        みんなの掲示板
                    </h1>
                </div>
                <p className="commencont"> 日頃のUI（尿もれ）に関するお悩みや対策について共有してみませんか</p>
                <div className="headerFlex">
                    <div className="headerLeft">
                        <p className="commencont">
                            なかなか人に相談できずに一人で悩んでいたり、不安に感じたり…。<br />
                            そんなみなさまへ快適な毎日を過ごしていただくために、<br />
                            みなさまのUI（尿もれ）事情を共有いただける掲示板を開設しました。
                        </p>
                    </div>
                    {/* <div className="headerRight" onClick={gotoPelvic}>
                        <div className="headerRighta">
                            <p>ウィスパー体操 <br/>
                            を見る</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="videocomment-body-content">
                <div className="VC-left-container">
                    <VideoCommets />
                </div>
                <div className="VC-right-container">
                    {!userloggedInstatus && (
                        <div className="HeadingContainerRight">
                            <p>投稿にはログインが必要となります。</p>
                            <div className="VF-login-info-cta">
                                <div className="VF-login-cta">
                                    <p className="videop">▼ウィスパー会員の方▼</p>
                                    <button
                                        className="login"
                                        type="button"
                                        onClick={() => Login(pathname)}
                                    >
                                        ログインする
                                    </button>
                                </div>
                                <div className="VF-register-cta">
                                    <p className="videop">▼会員登録をお済みでない方▼</p>
                                    <button className="register" type="button" onClick={() => Register(pathname)}>会員登録する</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <UserCommentBox className="VideoComments" id="postComments" descStatus={false} isMobile={resolution.isMobile} />
                    {/* <UserVideoBanner bannerStatus="small" descStatus={false} /> */}
                </div>

            </div>

            {resolution && resolution.isMobile
                ? (
                    <div className="footerfix" onClick={handleOnChangeEvent}>
                        <span className="footersection">投稿する</span>
                    </div>
                ) : ''}
            <style jsx> {style} </style>

        </div>

    );
};

UserVideoComment.propTypes = {
    // screen: PropTypes.func,
    // ref: PropTypes.any,
    pathname: PropTypes.string,
};

export default UserVideoComment;
