import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.videofeedback-container {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 500;
    width: 940px;
    margin: 0 auto;
    color: $text-article;
    .footerfix {
        display: block;
        width: 100%;
        position: fixed;
        left: 0px;
        bottom: 10px;
        z-index: 9999;
        text-align: center;
        margin: 0 auto;
        background-color: #8347ad;
        padding: 10px;

        span {
            color: #fff;
        }
        @media (max-width: $breakpoint-max) {
            bottom: 90px;
            z-index: 99;
        }
    }

    .VC-Headersection {
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        .VC-Headersection-text {
            position: relative;
            width: 50%;
            margin: 0 auto;
            span {
                font-size: 40px;
                line-height: 0;
                letter-spacing: 30px;
                position: absolute;
                left: 17px;
                top: -10px;
                color: #592c80;
            }
            h1 {
                color: #592c80;
                font-size: 40px;
                line-height: 58px;
            }
        }

        p {
            font-size: 20px;
            line-height: 40px;
        }

        @media only screen and (max-width: $breakpoint-max) {
            .VC-Headersection-text {
                width: 75%;
                span {
                    font-size: 30px;
                    letter-spacing: 11px;
                    top: -12px;
                }
                h1 {
                    color: #592c80;
                    font-size: 20px;
                    line-height: 40px;
                }
            }
        }
    }

    .videocomment-body-content {
        width: 100%;
        float: left;
        position: relative;
        .VC-left-container {
            width: 620px;
            float: left;
            margin: 0 20px 0 0;
            // border:1px solid #ccc;
            .VC-comments-container {
                display: flex;
                flex-wrap: wrap;
            }
            .VC-Evencomments {
                width: 50%;
            }
        }
        .VC-right-container {
            width: 300px;
            float: left;
            border: 1px solid #ccc;
            text-align: center;
        }
    }
    @media only screen and (max-width: $breakpoint-max) {
        width: 100%;
        margin: 50px auto;
        padding: 0 20px;
    }
}
`;

export default styles;