import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { prepareRichTextContent } from './Error404.helper';

import SearchInput from '../UI/SearchInput/SearchInput';
import SearchIcon from '../UI/SearchInput/SearchIcon';

import Style from './Error404.scss';

const Error404 = ({ message }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const onSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSearch = () => {
        window.location = `/viewsearchresult?q=${searchTerm}`;
    };

    return (
        <div className="error-404">
            <h1>404</h1>
            <h3>Page not found</h3>
            {prepareRichTextContent(message.json)}
            <div className="search">
                <SearchInput
                    placeholder="テキスト"
                    onSearchTermChange={onSearchTermChange}
                    onSearch={onSearch}
                />
                <div className="search-icon">
                    <SearchIcon color="#595757" size={2.5} onClick={onSearch} />
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

Error404.propTypes = {
    message: PropTypes.string,
};

export default Error404;
