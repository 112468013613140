import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Head from 'next/head';
import { prepareRichTextContent } from './Article.helper';
import Img from '../Image/Image';
import ArticlePromoBanners from '../ArticlePromoBanners/ArticlePromoBanners';
import RetailersDropdown from '../RetailersDropdown/RetailersDropdown';
import CustomContent from '../CustomContent/CustomContent';
import Video from '../Video/Video';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import getScreenSize from '../ReusableComponents/useScreenSize';

import Style from './Article.scss';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const Article = (props) => {
    const { title,
        title1,
        subheading,
        subheading1,
        imageSp, imageDt,
        articlePromoBanners,
        retailersDropdown,
        customContentCollection,
        video,
        textBox,
        pageInfo } = props;
    const content = prepareRichTextContent(props.text.json, props.allPages);
    const { isDesktop,isMobile } = getScreenSize()
    let observer = null
 
    useEffect(() => {
        observer = new MutationObserver((_mutations, me) => {
            try {
                const mainDiv =
                    document?.getElementsByClassName(`article-text`)[0]
                const reviewSlides =
                    mainDiv?.getElementsByTagName('a')
                if (reviewSlides.length > 0) {
                    for (let i = 0; i < reviewSlides.length; i++) {
                        me.disconnect()
                        if(reviewSlides?.[i]){
                            // console.log(reviewSlides[i].href)
                            reviewSlides?.[i].setAttribute('aria-label', 'check')
                        }
                        me.observe(mainDiv, {
                            childList: true,
                            subtree: true,
                            attributes: true,
                        })
                        
                    }
                   
                }
            } catch (e) {
                //
            }
        })
 
        observer.observe(document, {
            childList: true,
            subtree: true,
            attributes: true,
        })
    }, [])
    return (
        <>
            <Head>
              { (imageSp || imageDt) &&
                <>
                    {isMobile && <link rel="preload" href={buildContentfullImageUrl(imageSp ? getImageUrl(imageSp?.asset?.url, { 'width': 480 }) : getImageUrl(imageDt?.asset?.url, { 'width': 480 }))} as="image" media="(max-width: 360px)" />}
                    {isDesktop && <link rel="preload" href={buildContentfullImageUrl(imageDt ? getImageUrl(imageDt?.asset?.url, { 'width': 1920 }) : getImageUrl(imageSp?.asset?.url, { 'width': 1920 }))} as="image" media="(max-width: 360px)" />}
                </>
              }
            </Head>
            <div className="article">
                <Breadcrumbs pageInfo={pageInfo} className="article-brdCrumb" />
                {(customContentCollection.items.length === 0)
                    ? (
                        <>
                            <div className="article-header">
                                <h1>
                                    {title}
                                    {title1 && <br />}
                                    {title1 && title1}
                                </h1>
                                {subheading && (
                                    <h2>
                                        {subheading}
                                        {subheading1 && <br />}
                                        {subheading1 && subheading1}
                                    </h2>
                                )}
                                {(imageSp || imageDt) && (
                                    <div className="header-image">
                                        <Img imageDt={imageDt} imageSp={imageSp} />
                                    </div>
                                )}
                            </div>
                            <div className="article-textbox">
                                <p>{textBox && textBox}</p>
                            </div>
                            <div className="article-text">
                                {content}
                            </div>
                        </>
                    ) : <CustomContent {...customContentCollection} />}

                {video && (
                    <div className="article-youtube-video">
                        <Video videoData={video} />
                    </div>
                )}
                <div className="article-promo-banners">
                    {articlePromoBanners && (
                        <ArticlePromoBanners banners={articlePromoBanners.bannersCollection} />
                    )}
                </div>
                {retailersDropdown && (
                    <RetailersDropdown
                        dropdownTitle={retailersDropdown.dropdownTitle}
                        retailersCollection={retailersDropdown.retailersCollection}
                    />
                )}
                <style jsx global>{Style}</style>
            </div>
        </>
    );
};

Article.propTypes = {
    allPages: PropTypes.array,
    imageDt: PropTypes.object,
    imageSp: PropTypes.object,
    retailersDropdown: PropTypes.object,
    subheading: PropTypes.string,
    subheading1: PropTypes.string,
    articlePromoBanners: PropTypes.object,
    text: PropTypes.object,
    title: PropTypes.string,
    title1: PropTypes.string,
    customContentCollection: PropTypes.object,
    textBox: PropTypes.object,
    video: PropTypes.object,
    pageInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({ allPages: state.allPages });

export default connect(mapStateToProps)(Article);
