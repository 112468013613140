import React from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import style from './LohacoIdBinding.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

class LohacoldBindingSuccess extends React.Component {
    render() {
        const { imageWhisper, imageDivider, imageLohaco } = this.props;


        const resolution = {
            isMobile: false,
        };

        if (!resolution) {
            return null;
        }
        const imgWhisper = resolution.isMobile
            ? getImageUrl(imageWhisper.imageSmartphone.asset.url, {})
            : getImageUrl(imageWhisper.imageDesktop.asset.url, {});
        const imgDivider = resolution.isMobile
            ? getImageUrl(imageDivider.imageSmartphone.asset.url, {})
            : getImageUrl(imageDivider.imageDesktop.asset.url, {});
        const imgLohaco = resolution.isMobile
            ? getImageUrl(imageLohaco.imageSmartphone.asset.url, {})
            : getImageUrl(imageLohaco.imageDesktop.asset.url, {});

        return (
            <div className="id-bind-unbind">
                <div className="id-binding-heading">
                    <div className="id-binding-imagewhisper">
                        <img src={buildContentfullImageUrl(imgWhisper)} alt="" />
                    </div>
                    <div className="id-binding-imageDivider">
                        <img src={buildContentfullImageUrl(imgDivider)} alt="" />
                    </div>
                    <div className="id-binding-imageLohaco">
                        <img src={buildContentfullImageUrl(imgLohaco)} alt="" />
                    </div>
                </div>
                <style jsx>{style}</style>
            </div>
        );
    }
}

LohacoldBindingSuccess.propTypes = {
    imageWhisper: PropTypes.object,
    imageDivider: PropTypes.object,
    imageLohaco: PropTypes.object,
};

export default LohacoldBindingSuccess;
