import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.product-category-lineup {
    align-self: center;
    display: flex;
    margin-bottom: 50px;

    .card {
        border-radius: 5px;
        box-shadow: $box-shadow-25;
        display: flex;
        flex-direction: column;
        height: 598px;
        justify-content: space-between;
        margin: .5rem;
        position: relative;
        width: 220px;

        > div {
            display: none;

            @media all and (max-width: $breakpoint-max) {
                display: flex;
                justify-content: center;
            }

            h4 {
                border: 1px solid $purple-dark;
                border-radius: 30px;
                color: $purple-dark;
                display: inline-block;
                font-family: 'M PLUS Rounded 1c', sans-serif;
                font-size: 28px;
                font-weight: 500;
                line-height: 22px;
                margin: 0;
                padding: 10px;
                text-align: center;
                width: 280px;
            }
        }

        .cta {
            align-self: center;
            background-color: $purple-dark;
            border: 0;
            border-radius: 18px;
            bottom: 1.6rem;
            color: white;
            cursor: pointer;
            display: block;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 20px;
            font-weight: 500;
            outline: none;
            padding: 3px;
            text-align: center;
            width: 170px;

            &::after {
                border-bottom: 7px solid transparent;
                border-left: 12px solid white;
                border-right: 7px solid transparent;
                border-top: 7px solid transparent;
                content: '';
                font-size: 0;
                position: absolute;
                right: 4px;
                top: 50%;
                transform: translateY(-50%);
            }

            @media all and (max-width: $breakpoint-max) {
                bottom: 14vw;
                margin-bottom: -30px;
            }
        }

        p {
            color: $purple-dark;
            font-family: 'M PLUS Rounded 1c';
            line-height: 1.2;
            margin-top: 125px;
            text-align: center;
            word-break: keep-all;

            @media screen and (max-width: $breakpoint-max) {
                font-size: 22px;
                margin: 10px 15px 0;
            }
        }

        img {
            align-self: center;
            width: 100%;
        }

        @media screen and (max-width: $breakpoint-max) {
            background-position-y: 120px;
            background-repeat: no-repeat;
            background-size: contain;
            box-shadow: none;
            flex-direction: column;
            height: auto;
            margin: 0 0 20px;
            width: 100%;
        }
    }

    @media all and (max-width: $breakpoint-max) {
        flex-direction: column;
        margin-bottom: 20px;
        width: 100%;
    }
}
`;

export default styles;