const preview = process.env.PREVIEW;
module.exports = `
... on Article {
  nameEn
  title
  subheading
  title1
  subheading1
  imageDt {
    cloudinaryId
    description
    asset{url}
  }
  imageSp {
    cloudinaryId
    description
    asset{url}
  }
  text {
    json
  }
  textBox
  articlePromoBanners {
    bannersCollection (limit: 5,preview: ${preview}) {
      items {
        __typename
        ... on SmallPromoBannerCta {        
          nameEn
          imageDt {
            title
            description
            cloudinaryId
            asset{url}
          }
          imageSp {
            title
            description
            cloudinaryId
            asset{url}
          }
          bigText
          smallText
          midText
          button {
            ... on ButtonExternal {
              externalUrl
              text
              target
            }
            ... on ButtonInternal {
              text
              target
              url {
                seoHead {
                  url
                }
              }
            }
          }
        }       
        ... on SmallPromoBannerNoTextCta {
          nameEn
          imageDt {
            title
            description
            cloudinaryId
            asset{url}
          }
          imageSp {
            title
            description
            cloudinaryId
            asset{url}
          }
          button {
            ... on ButtonExternal {
              externalUrl
              text
              target
            }
            ... on ButtonInternal {
              text
              target
              url {
                seoHead {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  video{
    title
    videoLink
    autoplay
    styles
  }
  retailersDropdown {
    dropdownTitle
    retailersCollection(limit: 5,preview: ${preview}) {
      items {
        nameEn
        imageDt {
          cloudinaryId
          description
          asset{url}
        }
        imageSp {
          cloudinaryId
          description
          asset{url}
        }
        link {
          label
          url
          newWindow
        }
      }
    }
  }
  customContentCollection(limit: 10,preview: ${preview}) {
    items {
      ... on ExpertsVoice {
        type: __typename
        title
        subtitle
        subtitleMobile
        subtitle1: subTitle1
        text
        textMobile
        image{
          cloudinaryId
          title
          description
          asset{url}
        }
        lineImageDt {
          cloudinaryId
          title
          description
          asset{url}
        }
        lineImageSp {
          cloudinaryId
          title
          description
          asset{url}
        }
      }
      ... on ArticleStep {
        type: __typename
        title
        titleMobile
        text
        image1Dt {
          cloudinaryId
          title
          description
          asset{url}
        }
        image1Sp {
          cloudinaryId
          title
          description
          asset{url}
        }
        image2Dt {
          cloudinaryId
          title
          description
          asset{url}
        }
        image2Sp {
          cloudinaryId
          title
          description
          asset{url}
        }
      }
      ... on ContentLt {
        type: __typename
        content
        contentMobile
      }
      ... on SectionContainer {
        type: __typename
        sectionContentCollection(limit: 1,preview: ${preview}) {
          items {
            __typename
            ... on SwiftTypeTagsContainer {
              desktopContentCollection(limit: 3,preview: ${preview}) {
                items {
                  advancedText {
                    json
                    links {
                      entries {
                        inline {
                          sys {
                            id
                          }
                          ... on ContentTypeRichText {
                            body
                            styles
                          }
                          ... on IdbindLohacoJapanId {
                            image {
                              description
                              url
                            }
                            styles
                          }
                          __typename
                        }
                        block {
                          sys {
                            id
                          }
                          ... on ContentTypeRichText {
                            body
                            styles
                          }
                          __typename
                        }
                      }
                    }
                  }
                }
              }
              mobileContentCollection(limit: 2,preview: ${preview}) {
                items {
                  advancedText {
                    json
                    links {
                      entries {
                        inline {
                          sys {
                            id
                          }
                          ... on ContentTypeRichText {
                            body
                            styles
                          }
                          ... on IdbindLohacoJapanId {
                            image {
                              description
                              url
                            }
                            styles
                          }
                          __typename
                        }
                        block {
                          sys {
                            id
                          }
                          ... on ContentTypeRichText {
                            body
                            styles
                          }
                          __typename}}}}}}}}}
        styles
      }
    }
  }
}
`;
