import React, { useEffect, useState } from 'react';
import { storageGet } from '../../helpers/StorageEvents';
import Style from './DigitalCampaignlpcontent.scss';

const DigitalCampaignLPContent = () => {
    const [retailer, setRetailer] = useState('');
    const [sundrug, setSundrug] = useState('');
    const [all, setAll] = useState('');
    const [mkcf, setMkcf] = useState('');
    const [tsuruha, setTsuruha] = useState('');
    const [barcodePage, setBarcodePage] = useState('');

    useEffect(() => {
        const { location } = window;
        //  = new URLSearchParams(search);
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        setRetailer(storageGet('digitalRetailer'));

        const landingPage = location.pathname.split('/')[2].split('-')[2];
        setBarcodePage(landingPage);
        const retailerlogo = mydigiretailer;
        console.log('retailerlogo', retailerlogo);
        if (retailerlogo === 'sundrug') {
            setSundrug('true');
        } else if (retailerlogo === 'all') {
            setAll('true');
        } else if (retailerlogo === 'mkcf') {
            setMkcf('true');
        } else if (retailerlogo === 'tsuruha') {
            setTsuruha('true');
        }
    }, []);
    return (

        <div className="digital-campaign-contentsection">
            <div className="digital-campaign-content">
                <h2 className="digital-campaign-title">【クーポン取得に関しての注意事項】</h2>

                <h2 className="digital-campaign-header">●利用方法</h2>
                <p className="digital-campaign-text">クーポン有効期間中に、対象商品と本クーポン画面をお会計時にレジでご提示ください。 </p>

                <h2 className="digital-campaign-header">●対象店舗</h2>
                {(sundrug === 'true')
                    ? (
                        <p className="digital-campaign-text">
                            サンドラッグ各店<br />
                            ※一部取り扱いのない店舗がございます。
                        </p>
                    ) : (all === 'true')
                        ? (
                            <p>
                                { barcodePage === 'lp3' ? retailer : 'ウエルシアグループ（ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店）、杏林堂薬局、くすりの福太郎、ココカラファイン、株式会社 サッポロドラッグストアー、サンドラッグ、ツルハドラッグ、B&D、マツモトキヨシ、くすりのレデイ、'}<br />
                                ※一部取り扱いのない店舗がございます。<br />
                                (＜対象外店舗＞マツモトキヨシ：沖縄県内のマツモトキヨシ,沖縄県のミドリ薬品,H&B place,Mediマツキヨ,Petit madoca野田みずき店,アウトレット店舗、<br />
                                ツルハグループ：ドラッグストアウェルネス各店、ウォンツ各店、ドラッグイレブン各店)

                            </p>
                        )

                        : (mkcf === 'true')
                            ? (
                                <p>
                                    { barcodePage === 'lp3' ? retailer : 'マツモトキヨシ(＜対象外店舗＞沖縄県内のマツモトキヨシ,沖縄県のミドリ薬品,H&B place,Mediマツキヨ,Petit madoca野田みずき店,アウトレット)、ココカラファイン'}<br />
                                    ※一部取り扱いのない店舗がございます。
                                </p>
                            )
                            : (tsuruha === 'true')
                                ? (
                                    <p>
                                        { barcodePage === 'lp3' ? retailer : 'ツルハドラッグ、くすりの福太郎、くすりのレデイ、杏林堂薬局、B&D'}<br />
                                        ※一部取り扱いのない店舗がございます。<br />
                                        ドラッグストアウェルネス各店、ウォンツ各店、ドラッグイレブン各店は対象外です。<br />
                                    </p>
                                )
                                : (
                                    <></>
                                )}
                {(mkcf === 'true')
                    ? (
                        <>
                            <h2 className="digital-campaign-header">●クーポン種類</h2>
                            <p className="digital-campaign-text">
                                300ポイント付与クーポン：マツモトキヨシ、ココカラファイン
                            </p>
                            <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                            <p className="digital-campaign-text">
                                ※他のクーポンとの併用はできません。<br />
                                ※クーポンご利用で該当商品お買上につき300ポイント付与させていただきます。<br />
                                ※対象期間中、お一人様1回限りご利用できます。<br />
                            </p>
                            <h2 className="digital-campaign-header"> ●クーポン対象商品</h2>
                            <p className="digital-campaign-text">
                                ●クーポン対象商品<br />
                                ウィスパー１枚２役Wガード各種<br />
                                うすさら吸水 各種<br />
                                うすさら安心 各種<br />
                                うすさらエアリーシリーズ 各種<br />
                            </p>
                        </>
                    ) : (tsuruha === 'true')
                        ? (
                            <>
                                <h2 className="digital-campaign-header">●クーポン種類</h2>
                                <p className="digital-campaign-text">
                                    ・300ツルハグループポイント付与クーポン：ツルハドラッグ、くすりの福太郎、くすりのレデイ、B&D<br />
                                    ・杏林堂薬局は杏林堂ポイント付与となります。<br />
                                </p>
                                <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                                <p className="digital-campaign-text">
                                    ※他のクーポンとの併用はできません。<br />
                                    ※クーポンご利用で該当商品お買上１点につき300ポイント付与させていただきます。<br />
                                    ※対象期間中、お一人様1回限りご利用できます。<br />
                                </p>
                                <h2 className="digital-campaign-header">●クーポン対象商品</h2>
                                <p className="digital-campaign-text">
                                    ウィスパー１枚２役Wガード各種<br />
                                    うすさら吸水 各種<br />
                                    うすさら安心 各種<br />
                                    うすさらエアリーシリーズ 各種<br />
                                </p>
                            </>
                        )
                        : (all === 'true') ? (
                            <>
                                <h2 className="digital-campaign-header">●クーポン種類</h2>
                                <p className="digital-campaign-text">
                                    ・300円OFFクーポン：サンドラッグ<br />
                                    ・300ポイント付与クーポン：マツモトキヨシ、ココカラファイン、杏林堂薬局、株式会社サッポロドラッグストアー<br />
                                    ・300ツルハグループポイント付与クーポン：ツルハドラッグ、くすりの福太郎、くすりのレデイ、B&D<br />
                                    ・300Tポイント付与クーポン：ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店<br />
                                </p>
                                <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                                <p className="digital-campaign-text">
                                    ※他のクーポン (ガッチャモールクーポン、Tポイントクーポン、割引・値引きクーポン等) との併用はできません。<br />
                                    ※クーポンご利用で該当商品お買上１点につき300円引きまたは300ポイント付与させていただきます。<br />
                                    ※対象期間中、お一人様1回限りご利用できます。<br />
                                    ※ウエルシア、ハックドラッグ、ダックス、ハッピー・ドラッグ、マルエドラッグ、金光薬品、よどやドラッグ各店において：<br />
                                    ※Tポイントは、ご購入から3日以内に付与されます。<br />
                                    ※お会計時に有効期限切れ等、Tカード番号が無効となっている場合は、ポイント付与ができませんのでご注意ください。<br />
                                </p>
                                <h2 className="digital-campaign-header">●クーポン対象商品</h2>
                                <p className="digital-campaign-text">
                                    ウィスパー１枚２役Wガード各種 <br />
                                    うすさら吸水 各種<br />
                                    うすさら安心 各種<br />
                                    うすさらエアリーシリーズ 各種<br />
                                </p>
                            </>
                        )
                            : (
                                <>
                                    <h2 className="digital-campaign-header">クーポン対象商品</h2>
                                    <p className="digital-campaign-text">
                                        ウィスパー１枚２役Wガード・うすさら吸水・うすさら安心・うすさらエアリーシリーズ　各種
                                    </p>
                                    <h2 className="digital-campaign-header">●ご利用上の注意</h2>
                                    <p className="digital-campaign-text">
                                        ※他のクーポンとの併用はできません。<br />
                                        ※クーポンご利用で該当商品お買上１点につき300円引きさせていただきます。<br />
                                        ※対象期間中、お一人様1回限りご利用できます。
                                    </p>
                                </>
                            )}

            </div>
            <style jsx>{Style}</style>
        </div>
    );
};
export default DigitalCampaignLPContent;
