import React, { useState, useEffect } from 'react';
import Router from 'next/router';
import { useDispatch } from 'react-redux';
import Style from './OpenCampaignLogin.scss';
import { storageSet, storageGet } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';
import { LotteryCampaignCheckAction } from '../../redux/actions/LotteryCampaignCheck';

const OpenCampaignlogin = () => {
    const dispatch = useDispatch();
    const [regLoginText, setRegLoginText] = useState('true');
    const [samplingUser, setSamplingUser] = useState(false);
    const [lotteryUser, setLotteryUser] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        const registered = storageGet('userFromLotteryFlag');
        setLotteryUser(storageGet('isLotteryCampaignUser'));
        setSamplingUser(storageGet('isSamplingCampaignUser'));
        setLoggedIn(storageGet('isUserLoggedIn'));

        console.log(lotteryUser, samplingUser, loggedIn, storageGet('isUserLoggedIn'), registered);
        if (registered) {
            setRegLoginText('false');
        } else {
            setRegLoginText('true');
        }
    }, [regLoginText]);

    const setGATagsAndEvents = async (eAction, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        // GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    const Login = async () => {
        dispatch(LotteryCampaignCheckAction(true));
        storageSet('lotteryflowStarts', 'true');

        setGATagsAndEvents('event_profile_login', 'event_crm_action');
        Router.push('/login');
    };

    const Register = async () => {
        setGATagsAndEvents('event_profile_register', 'event_crm_action');
        dispatch(LotteryCampaignCheckAction(true));
        Router.push('/registration');
    };

    const EditProfile = async () => {
        dispatch(LotteryCampaignCheckAction(true));
        storageSet('userFromLotteryFlag', 'true');
        Router.push('/editprofile');
    };

    const resolution = useScreenSize();
    if (!resolution) return null;

    return (
        <div className="Lottery-Campaing-Loginsection">
            <div className="Lottery-Campaing-PI clearfix">
                {regLoginText === 'true' && loggedIn !== 'true'
                    ? (
                        <div className="login-img pi pi-left">
                            <h2 className="title">
                                初めての方
                            </h2>
                            <div className="Lottery-Campaing-Register ">
                                <button className="Lottery-Campaing-Register-cta cta registration" type="button" onClick={Register} />
                            </div>
                            <p className="txt">
                                無料で登録すれば、ウィスパー会員様限定のクーポン情報などが配信されます
                            </p>
                        </div>
                    )
                    : ''}
                {regLoginText === 'true' && loggedIn !== 'true'
                    ? (
                        <div className="login-img pi pi-right">
                            <h2 className="title">
                                すでにウィスパー会員に登録済みの方
                            </h2>
                            <div className="Lottery-Campaing-Login">
                                {resolution.isMobile && <p className="login txt">すでに会員の方</p>}
                                <button className="Lottery-Campaing-Login-cta cta " type="button" onClick={Login} />
                            </div>
                            <p className="txt">
                                ※「ウィスパー現品プレゼントキャンペーン」にご応募された方はご応募いただけません。
                            </p>
                        </div>
                    )
                    : (
                        (samplingUser || lotteryUser)
                            ? (
                                <div>
                                    <div className="Lottery-Campaing-Login pi align-center">
                                        <h2 className="title">
                                            すでにウィスパー会員に登録済みの方
                                        </h2>
                                        <p className="login txt">※「ウィスパー現品プレゼントキャンペーン」にご応募された方はご応募いただけません。<br />
                                            「ウィスパー現品プレゼントキャンペ－ン」に以前ご応募いただいた方は、ご参加いただけません。
                                        </p>
                                    </div>

                                </div>
                            )
                            : (
                                loggedIn === 'true'
                                    ? (
                                        <div>
                                            <div className="Lottery-Campaing-Login pi align-center">
                                                <h2 className="title">
                                                    すでにウィスパー会員に登録済みの方
                                                </h2>
                                                <button className="Lottery-Campaing-Login-cta cta continue" type="button" onClick={EditProfile} />
                                                <p className="login txt">※「ウィスパー現品プレゼントキャンペーン」にご応募された方はご応募いただけません。</p>
                                            </div>

                                        </div>
                                    )
                                    : (
                                        <div className="Lottery-Campaing-Login pi align-center">
                                            <p className="login txt">ご登録ありがとうございます。</p>
                                            <button className="Lottery-Campaing-Login-cta cta" type="button" onClick={Login} />
                                        </div>
                                    )
                            )

                    )}
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default OpenCampaignlogin;
