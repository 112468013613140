import React from 'react';
// import PropTypes from 'prop-types';
import Style from '../LotteryCampaign/OpenCampaignThankyou.scss';
import RetailersDropdown from '../RetailersDropdown/RetailersDropdown';

const retailersDropdown = {
    'dropdownTitle': 'ウィスパー商品のご購入はコチラ',
    'retailersCollection': {
        'items': [
            {
                'nameEn': 'Amazon',
                'imageDt': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_amazon_FINAL',
                    'description': '',
                },
                'imageSp': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_amazon_FINAL',
                    'description': '',
                },
                'link': {
                    'label': 'Amazon',
                    'url': 'https://www.amazon.co.jp/stores/node/3704127051?_encoding=UTF8&field-lbr_brands_browse-bin=%E3%82%A6%E3%82%A3%E3%82%B9%E3%83%91%E3%83%BC&ref_=bl_dp_s_web_3704127051',
                    'newWindow': '_blank',
                },
            },
            {
                'nameEn': 'Rakuten',
                'imageDt': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_ichiba_FINAL',
                    'description': '',
                },
                'imageSp': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_ichiba_FINAL',
                    'description': '',
                },
                'link': {
                    'label': 'Rakuten',
                    'url': 'https://search.rakuten.co.jp/search/mall/%E3%82%A6%E3%82%A3%E3%82%B9%E3%83%91%E3%83%BC/',
                    'newWindow': '_blank',
                },
            },
            {
                'nameEn': 'LOHACO',
                'imageDt': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_lohaco',
                    'description': '',
                },
                'imageSp': {
                    'cloudinaryId': 'Whisper_JP_JP/retailer_lohaco',
                    'description': '',
                },
                'link': {
                    'label': 'LOHACO',
                    'url': 'https://lohaco.jp/event/pg_whisper/?sc_e=zb_pmkr_aas_bpg_cwsp_dps',
                    'newWindow': '_blank',
                },
            },
        ],
    },
};

const LotteryThankyou = () => (
    <div className="thanks-for-lotterycp">
        <div className="thanks-for-lotterycp-text">ご応募が完了しました。</div>
        <div className="thanks-for-lotterycp-subtexts">
            <p className="thanks-for-lotterycp-subtext-1">
                ご応募ありがとうございました。
            </p>
            <p className="thanks-for-lotterycp-subtext-2">
                厳正な抽選の上、ご当選者様へは発送を持って発表に変えさせていただきます。<br />
                ご当選者様への発送は11月下旬を予定しております。
            </p>
        </div>
        <RetailersDropdown
            dropdownTitle={retailersDropdown.dropdownTitle}
            retailersCollection={retailersDropdown.retailersCollection}
        />

        <style jsx global>
            {Style}
        </style>
    </div>
);

export default LotteryThankyou;
