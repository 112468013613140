/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleList from './CollapsibleList';
import style from './CollapsibleList.scss';

const RenderItems = (props) => {
    const { __typename, url, label, childPagesCollection, displayTitle, linksCollection } = props;
    const { navigationColumn, title, name, subHeading, menuCategoryCollection } = props;
    const { isIndexOpen, setIsIndexOpen } = props;
    switch (__typename) {
    case 'Menu': return (() => {
        const [isOpen, setIsOpen] = useState(0);
        return navigationColumn.items.map((child, index) => (
            <RenderItems
                isIndexOpen={index + 1 === isOpen}
                setIsIndexOpen={() => setIsOpen(index + 1 === isOpen ? 0 : index + 1)}
                key={`Menu-${name}-${index + 1}`}
                {...child}
            />
        ));
    })();

    case 'NavigationItem': return (() => {
        const [isOpen, setIsOpen] = useState(0);
        return (
            <CollapsibleList
                isOpen={isIndexOpen}
                setIsOpen={() => setIsIndexOpen(!isIndexOpen)}
                title={displayTitle}
            >
                {childPagesCollection.items.map((child, index) => (
                    <RenderItems
                        isIndexOpen={index + 1 === isOpen}
                        setIsIndexOpen={() => setIsOpen(index + 1 === isOpen ? 0 : index + 1)}
                        key={`${displayTitle}-child-${index + 1}`}
                        {...child}
                    />
                ))}
            </CollapsibleList>
        );
    })();

    case 'ExternalLink':
        return (
            <>
                {url ? <a href={url}>{label}</a> : <a className="disabled" href={url}>{label}</a>}
                <style jsx>{style}</style>
            </>
        );

    case 'MenuList': return (() => {
        const [isOpen, setIsOpen] = useState(0);

        return (
            <>
                <span className="heading">{subHeading || title}</span>
                {menuCategoryCollection.items.map((child, index) => (
                    <RenderItems
                        isIndexOpen={index + 1 === isOpen}
                        setIsIndexOpen={() => setIsOpen(index + 1 === isOpen ? 0 : index + 1)}
                        key={`${subHeading || title}-menu-category-${index + 1}`}
                        {...child}
                    />
                ))}
                <style jsx>{style}</style>
            </>
        );
    })();
    case 'LinkGroup': return (() => {
        const [isOpen, setIsOpen] = useState(0);
        return linksCollection.items.length <= 1
            ? (
                <>
                    <span className="title-test">{title}</span>
                    {linksCollection.items.map((child, index) => (
                        <RenderItems
                            isIndexOpen={index + 1 === isOpen}
                            setIsIndexOpen={() => setIsOpen(index + 1 === isOpen ? 0 : index + 1)}
                            key={`${displayTitle}-child-${index + 1}`}
                            {...child}
                        />
                    ))}
                </>
            )
            : (
                <CollapsibleList
                    isOpen={isIndexOpen}
                    setIsOpen={() => setIsIndexOpen(!isIndexOpen)}
                    title={title}
                >
                    {linksCollection.items.map((child, index) => (
                        <RenderItems
                            isIndexOpen={index + 1 === isOpen}
                            setIsIndexOpen={() => setIsOpen(index + 1 === isOpen ? 0 : index + 1)}
                            key={`${displayTitle}-child-${index + 1}`}
                            {...child}
                        />
                    ))}
                </CollapsibleList>
            );
    })();

    default: return <></>;
    }
};

RenderItems.propTypes = {
    props: PropTypes.object,
    __typename: PropTypes.any,
    url: PropTypes.any,
    label: PropTypes.any,
    childPagesCollection: PropTypes.any,
    displayTitle: PropTypes.any,
    linksCollection: PropTypes.any,
    navigationColumn: PropTypes.any,
    title: PropTypes.any,
    name: PropTypes.any,
    subHeading: PropTypes.any,
    menuCategoryCollection: PropTypes.any,
    isIndexOpen: PropTypes.any,
    setIsIndexOpen: PropTypes.any,
};
export default RenderItems;
