import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */
@import "./styles/global-variables";

.slick-slider {
    box-sizing: border-box;
    display: block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.fiftyfive-carousel-container{
    position: relative;
    width: 100%;
    float: left;        
    .fiftyfive-carousel-content{
        position: absolute;
        top: 0; 
        padding:20px 40px;
        width: 100%;
        float: left;
        text-align: center;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        font-size:38px;
        line-height: 48px;
        font-weight: 500;
        color:$purple;
        .fiftyfive-carousel-header {
            text-align: center;
            width: 100%;
            float: left;
            font-size: 45px;
            border: 1px solid $purple;
            border-radius: 40px;
            padding: 10px;
            font-weight: 800;
            color:$purple;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            .fiftyfive-carousel-description{
                font-size: 26px;
                text-align: center;
                color:$purple;
            }
        }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px){
        .fiftyfive-carousel-content{
            padding:10px 30px;
            font-size:18px;
            line-height: 25px;          
            .fiftyfive-carousel-header {
                font-size: 28px;
                border-radius: 30px;
                margin:5px 0 0 0;
            }
        }

    }
}


@media only screen and (max-width: 767px) {
    .Homepage {
        .banner-carousel{ 
            .slick-track{
                opacity: 1;
                left:0 !important;
            }
          .c-picture {
              padding-top: calc(345/360 * 100%);
              display: block;
              position: relative;
              img{
                  position:absolute;
                  top:0;
                  left: 0;
                  height: 100% !important;
              }
          }
          
        }
      }
    
    .banner-carousel{ 
        .slick-track{
            opacity: 1;
            left:0 !important;
        }
      .c-picture {
          padding-top: calc(345/360 * 100%);
          display: block;
          position: relative;
          img{
              position:absolute;
              top:0;
              left: 0;
          }
      }
      
    }
    .product-lineup-carousel {
      .slick-track{
        width: 4900px !important;
      }
      .c-picture{
        padding-top: calc(168/360 * 100%);
        display: block;
        position: relative;
        img {
            position:absolute;
            top:0;
            left: 0;
        }
      } 
    }
    }`;

export default styles;