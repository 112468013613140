import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import style from './Videofeedbackstyle.scss';
// import UserVideoBanner from './UserVideoBanner';
import UserVideo from './UserVideo';
import UserCommentBox from '../UserCommentForm';
import { storageSet, storageKeyRemove } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';

const handleGAEvents = async (eventCate, eventAction, eventLabel) => {
    await window.dataLayer.push({
        'event': 'customEvent',
        'GAeventCategory': eventCate,
        'GAeventAction': eventAction,
        'GAeventLabel': eventLabel,
        'GAeventValue': 0,
        'GAeventNonInteraction': false,
    });
    return true;
};
const Login = async () => {
    await storageSet('videoFeedbackVia', true);
    if (handleGAEvents('event_crm_action', 'event_profile_exmovie_login', 'whisper_login_exmovie')) {
        Router.push('/login');
    }
};
const Register = async () => {
    await storageSet('videoFeedbackVia', true);
    if (handleGAEvents('event_crm_action', 'event_profile_exmovie_register', 'whisper_register_exmovie')) {
        Router.push('/registration');
    }
};

// Below function not used, hence commenting.
// const Comments = async () => {
//     Router.push('/urinary-incontinence-message-post');
// };

const UserVideoFeedback = (props) => {
    const resolution = useScreenSize();
    storageKeyRemove('videoFeedbackVia');

    if (!resolution) {
        return null;
    }

    return (
        <div className="videofeedback-container">
            <div className="VF-Headersection">
                {resolution && resolution.isMobile ? (
                    <h2>骨盤底筋をトレーニングして <br /> 体も心もリフトアップする<br />
                        オトナ女性のための「ウィスパー体操」
                    </h2>
                ) : (
                    <h2>骨盤底筋をトレーニングして体も心もリフトアップする<br />
                        オトナ女性のための「ウィスパー体操」
                    </h2>
                )}
                <p>「ウィスパー体操」は、くびれ母ちゃんこと姿勢改善トレーナーの村田友美子先生が <br />
                    制作されたUI (尿もれ)にも効果が期待できるエクササイズです。 <br />
                </p>
            </div>
            <UserVideo />

            <div className="VF-login-info">
                <h2>▼みなさんのご意見をお聞かせください。▼ </h2>
                {!props.isUserLoggedInStatus
                    && (
                        <>
                            {resolution && resolution.isMobile
                                ? (
                                    <p>投稿にはログインが必要となります。<br />会員の方は以下の「ログインする」よりログイン後、<br />投稿ください。<br />
                                        会員登録がお済みでない方は以下の「会員登録する」<br />ボタンより登録後、投稿をお願いします。
                                    </p>
                                )
                                : (
                                    <p>投稿にはログインが必要となります。会員の方は以下の「ログインする」よりログイン後、投稿ください。<br />
                                        会員登録がお済みでない方は以下の「会員登録する」ボタンより登録後、投稿をお願いします。
                                    </p>
                                )}
                            <div className="VF-login-info-cta">
                                <div className="VF-login-cta">
                                    <button className="login " type="button" onClick={Login}>ログインする</button>
                                </div>
                                <div className="VF-register-cta">
                                    <button className="register event_profile_exmovie_register" type="button" onClick={Register}>会員登録する</button>
                                </div>
                            </div>
                        </>
                    )}
            </div>
            <UserCommentBox className="VideoComments" descStatus={true} isMobile={resolution.isMobile} />
            {/* <UserVideoBanner bannerStatus="large" descStatus={true} /> */}
            <style jsx> {style} </style>
        </div>
    );
};
UserVideoFeedback.propTypes = {
    isUserLoggedInStatus: PropTypes.any,
};
export default UserVideoFeedback;
