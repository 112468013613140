module.exports = (preview) => `
{
    categories: categoryCollection(limit: 10,preview:${preview}) {
      items {
        nameEn
        name
        color {
          color
        }
        page {
          seoHead {
            title
            description
            url
            hideInSitemap
          }
        }
        products: productsCollection(limit: 20,preview:${preview}) {
          items {
            ... on Product{
              nameEn
              name
              absorbency {
                nameEn
                name
              }
              lenght {
                nameEn
                name
              }
              style {
                nameEn
                name
              }
              size {
                nameEn
                name
              }
              page {
                seoHead {
                  title
                  description
                  url
                }
              }
              images: imagesCollection(limit: 5,preview:${preview}) {
                items {
                  nameEn
                  image {
                    cloudinaryId
                    description
                    asset{url}
                  }
                  imageThumbnail {
                    cloudinaryId
                    description
                    asset{url}
                  }
                }
              }
              description
              checkTheReviewLink {
                label
                url
                newWindow
                title
              }
              ratingsReviewsId
              gtin
            }
          }
        }
      }
    }
  }`;
