import React from 'react';
import PropTypes from 'prop-types';
import Style from './SearchInput.scss';

const SearchIcon = ({ onClick, color, size, style }) => {
    const stroke = color || '#8347ad';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="35px"
            width="35px"
            onClick={onClick}
            className="svg-icon mx-10 h-22px"
        >
            <g transform={`scale(${size || 2})`} strokeWidth="1" stroke={stroke} fill="none">
                <path d="M11.29 11.71l-4-4" />
                <circle cx="5" cy="5" r="4" />
            </g>
            <style jsx>{Style}</style>
        </svg>
    );
};

SearchIcon.propTypes = {
    color: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.number,
    style: PropTypes.object,
};

export default SearchIcon;
