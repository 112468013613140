const preview = process.env.PREVIEW;
module.exports = `
... on ImageCarousel {
  name
  slidesCollection(limit:5,preview: ${preview}){
    items{
      nameEn
      imageDt{
         description
        cloudinaryId
        asset
        {url
        }
      }
      imageSp{
        description
        cloudinaryId
        asset
        {url
        }
      }
      thumbnailsCollection(limit:1,preview: ${preview}){
         items{
          url
        }}
      link {
        __typename
        ... on ExternalLink {url}
        ... on Page {
          seoHead {url}
}}}}}`;
