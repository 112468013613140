import React from 'react';
import Head from 'next/head';
import { Container } from 'reactstrap/lib';
import useScreenSize from '../Hooks/useScreenSize';
import Style from './ReviewCampaignLP.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ReviewCampaignLPBanner = () => {
    let resolution = useScreenSize();

    if (!resolution) {
        resolution = { isMobile: true };
    }
    const mobileImg = 'https://images.ctfassets.net/8g0fievzqg8k/GtwVv9fKZG4K4jBcaR3Tw/3028f8fcf2be1e99bd96b4c8abb5fe07/review_whisper_amazon_KVsp.png';
    const desktopImg = 'https://images.ctfassets.net/8g0fievzqg8k/6EKiVg0XrjhGVo0H1CEmva/88cf0a17ab32408580a05b01763e8fda/review_whisper_amazon_KVpc.png';
    const banner = resolution.isMobile ? mobileImg : desktopImg;

    return (
        <>
            <Head>
                {<link rel="preload" as="image" href={buildContentfullImageUrl(banner)} />}
            </Head>
            <div className="review-campaign-lp">
                <div className="banner-image-section">
                    <div className="banner-image">
                        <img src={buildContentfullImageUrl(banner)} alt=":Amazonギフト券がもらえるレビュー投稿キャンペーン" />
                    </div>
                    <Container className="review-campaign-content-container">
                        <p>キャンペーン期間中、ウィスパー会員に登録（無料）いただいており、「マイレピ」にウィスパー製品のレビューを投稿いただいた方の中から、抽選で50名様にAmazonギフト券5,000円分をプレゼント！<br />
                            リニューアルした、超吸収＆超消臭のうすさら安心。<br />
                            ちょっとしたUI(尿もれ）が気になる方や本格的に尿ケアをしたい方、ウィスパーを使って見て感じた、率直なご意見を投稿して、キャンペーンにご応募してください。
                        </p>
                    </Container>
                </div>
                <style jsx>{Style}</style>
            </div>
        </>
    );
};

export default ReviewCampaignLPBanner;
