import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'next/link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import SigninPopup from '../../../pages/SigninPopup';
import { SamplingCampaignCheckAction } from '../../redux/actions/SamplingCampaignCheck';
import Style from './Sampling.scss';
import { renderSection } from '../../helpers/uiHelper';
import useScreenSize from '../Hooks/useScreenSize';

const Sampling = (props) => {
    const resolution = useScreenSize();

    return (
        <div className="landing oct-sampling">
            {props.sampleData.data.campaginCollection.items[0].campaiginContentCollection.items.map((content) => renderSection(content, resolution))}
            {console.log(props.sampleData.data.campaginCollection.items[0], 'sample data')}
            <div className="footerfix">
                <span className="footersection">
                    {/* <SigninPopup
                        SamplingCampaignCheckAction={boolean => props.SamplingCampaignCheckAction(boolean)}
                        modalContent=""
                        className="landing-view-btn visible"
                        modalButton="次へ"
                        doiPath="campaign-doubleoptin"
                    /> */}
                    <p className="Sampling-appology-text">
                        Whisperホームページをご覧いただき、誠にありがとうございます。<br />
                        プレゼントキャンペーンは終了いたしました。<br />
                        今後もWhisper会員様に向けた様々なキャンペーンを準備しております。これからも弊社製品をご贔屓いただけますと幸いです。
                    </p>
                </span>
            </div>
            <style jsx global>
                {Style}
            </style>
        </div>
    );
};

Sampling.propTypes = {
    sampleData: PropTypes.object,
    // SamplingCampaignCheckAction: PropTypes.func,
};

Sampling.defaultProps = {
    // SamplingCampaignCheckAction: () => { },
};

const mapStateToProps = (state) => ({ allPages: state.allPages });

const mapDispathToProps = (dispatch) => bindActionCreators({ SamplingCampaignCheckAction }, dispatch);

export default connect(mapStateToProps, mapDispathToProps)(Sampling);
