import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { Dialog, DialogTitle } from '../Dialog';
import { storageKeysRemove } from '../../helpers/StorageEvents';
import { successMailInfo, failMailInfo } from '../../helpers/content';
import Style from './TemporaryThanksForRegistration.scss';
import useResolution from '../Hooks/useScreenSize';
import TemporaryThanksForRegistrationIntro from './TemporaryThanksForRegistrationIntro';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const TemporaryThanksForRegistration = ({ shortdesc3 }) => {
    const [emailAddress, setEmailAddress] = useState();
    const [resendMailCount, setResendMailCount] = useState(0);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [messageInfo, setMessageInfo] = useState('');

    const handleGAEvents = async (event, eventCate, eventAction, eventLabel) => {
        await window.dataLayer.push({
            'event': event,
            'GAeventCategory': eventCate,
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel,
            'GAeventValue': 0,
            'GAeventNonInteraction': false,
        });
        return true;
    };

    useEffect(() => {
        const crmDetails = `${window.location.hostname}crmDetails`;
        const sessionData = window.sessionStorage.getItem(crmDetails) !== null ? JSON.parse(window.sessionStorage.getItem(crmDetails)) : '';
        setEmailAddress(sessionData && sessionData.emailAddress);
        storageKeysRemove(['IDBindingVia', 'doiVerifiyStatus']);
        const resendCount = window.sessionStorage.getItem('resendCount') && parseInt(window.sessionStorage.getItem('resendCount'), 10);
        const ele = document.getElementById('resendMail');
        if (ele && (resendMailCount === 3 || resendCount === 3)) {
            ele.classList.add('disabled');
            ele.disabled = true;
            ele.setAttribute('disabled', 'disabled');
        }
    }, [emailAddress, resendMailCount]);

    const handleTimeout = () => {
        setTimeout(() => {
            setMessageInfo('');
        }, 10000);
    };

    const handleResendMail = async () => {
        const resendCount = await window.sessionStorage.getItem('resendCount');
        const doiPath = await window.sessionStorage.getItem('tempDoiPath');
        if ((resendMailCount < 3 && parseInt(resendCount, 10) < 3) || resendCount === null) {
            await handleGAEvents('customEvent', 'event_crm_action', 'event_profile_register_submit_error', 'whisper_resend_doiemail');
            if (window.sessionStorage.getItem('resendCountStatus') !== null && Boolean(window.sessionStorage.getItem('resendCountStatus')) === true) {
                window.sessionStorage.removeItem('resendCountStatus');
                await setResendMailCount((prevCount) => prevCount + 1);
                await window.sessionStorage.setItem('resendCount', resendMailCount + 1);
            } else if (window.sessionStorage.getItem('resendCount') === null || window.sessionStorage.getItem('resendCount')) {
                const parseIntCount = resendCount === null ? 1 : parseInt(resendCount, 10) + 1;
                await setResendMailCount(() => parseIntCount);
                await window.sessionStorage.setItem('resendCount', parseIntCount);
            }

            const postData = {
                'emailAddress': emailAddress,
                'pathname': doiPath,
            };

            setLoaderStatus(true);
            axios.post(`${process.env.JANRAIN}verifyemailApi`, postData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            }).then(() => {
                setLoaderStatus(false);
                setMessageInfo(true);
                handleTimeout();
            }).catch(() => {
                setLoaderStatus(false);
                setMessageInfo(false);
                handleTimeout();
            });
        }
    };

    const handleRedirectReg = async () => {
        if (handleGAEvents('customEvent', 'event_crm_action', 'event_profile_register_submit_error', 'whisper_resend_doiemail')) {
            Router.push('/registration');
        }
    };

    const resolution = useResolution();
    if (!resolution) return null;

    return (
        <div className="temporary-thanks-reg">
            {loaderStatus ? (
                <Dialog open={true}>
                    <div className="">
                        <DialogTitle className="Loader-overlay" component="div">
                            <Loader
                                type="TailSpin"
                                color="#8347ad"
                                className="loader"
                                height={100}
                                width={100}
                                visible={true}
                            />
                        </DialogTitle>
                    </div>
                </Dialog>
            ) : null}
            <h2 className="heading">お客様の入力した<br />情報を受け付けました。</h2>
            <h3 className="sub-heading">会員登録完了まで<br />ページを閉じないでください。</h3>
            <TemporaryThanksForRegistrationIntro />
            <div className="mail-info-desc">
                <div className="left-content">
                    <div className="left-content-img">
                        <img src={buildContentfullImageUrl("https://images.ctfassets.net/8g0fievzqg8k/613Ml9bgPRuNcZsvhDN7k6/7befa982b2876248343ddc32d4899592/mail.png")} alt="resend-mail" />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: shortdesc3 }} />
                </div>
                <div className="right-content">
                    <ul className="content-list">
                        <li className="content-list-item">
                            <button type="button" className="resend-cta" onClick={handleResendMail} id="resendMail">こちら</button> から本人確認用メールを再送してください。
                        </li>
                        <li className="content-list-item">メールアドレスに誤りがある場合は再度
                            <button type="button" className="resend-cta" onClick={handleRedirectReg}>新規会員登録</button> よりお手続きください。
                        </li>
                        <li className="content-list-item">迷惑メールフォルダへ振り分けられている場合がございますので、ご確認ください。</li>
                    </ul>
                </div>
            </div>
            {messageInfo && <p className="successMsgInfo">{successMailInfo()}</p>}
            {(messageInfo !== '' && !messageInfo) && <p className="errorMsgInfo">{failMailInfo()}</p>}
            <style jsx>
                {Style}
            </style>
        </div>
    );
};

TemporaryThanksForRegistration.propTypes = {
    shortdesc3: PropTypes.any,
};

export default TemporaryThanksForRegistration;
