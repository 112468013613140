/* eslint-disable react/jsx-indent */
import React from 'react';

export const samplingLoginPopupContent = () => `既にご応募いただいております。
            ウィスパー新発売記念キャンペーンのご応募はお一人様１回１製品限りとさせていただいております。
            今後ともウィスパーをご愛顧くださいますよう、よろしくお願い申し上げます`;

export const DOIStatusLoginPopupContent = () => '現在、仮登録状態となっております。<button class="popup-login-cta" id="resendMail">こちら</button>から確認用メールを送信してください。メール本文に記載されている URLにアクセスすることで、 会員登録は完了します。';

export const successMailInfo = () => '確認用メールを送信いたしました。 現在は仮登録状態となっており、 メール本文に記載されている URLにアクセスすることで、 会員登録は完了します。';
export const failMailInfo = () => 'メール送信に失敗しました。';

<button type="button" className="popup-login-cta" id="resendMail">HERE</button>;
