import React, { useEffect, useState } from 'react';
import ReviewCampaignLPProductSection from './ReviewCampaignLPProductSection';
import ReviewCampaignLPBanner from './ReviewCampaignLPBanner';
import ReviewCampaignLogin from './ReviewCampaignLogin';
import Style from './ReviewCampaignLP.scss';
import ReviewCampaignLPContent from './ReviewCampaignLPContent';
import { storageGet } from '../../helpers/StorageEvents';

const ReviewCampaign = () => {
    const [registered, setRegistered] = useState(false);

    useEffect(() => {
        const isRegistered = storageGet('userFromRegistration');
        if (isRegistered && isRegistered === 'true') {
            setRegistered(true);
        } else {
            setRegistered(false);
        }
    }, [registered]);

    return (
        <div className="review-campaign-lp">
            <ReviewCampaignLPBanner />
            <ReviewCampaignLPProductSection />
            {!registered && <ReviewCampaignLogin />}
            <ReviewCampaignLPContent />
            <style jsx>{Style}</style>
        </div>
    );
};

export default ReviewCampaign;
