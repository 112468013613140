import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.id-binding-main-section-text 
{
    margin: 92px 0 70px;
    position: relative;
    width:100%;
    float:left;
    h3
    {
        width: 190px;
        margin: 0 auto;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        color:$purple-dark;
        font-size: 30px;
        padding:0 0 20px 0;
    }
    .id-binding-main-section-subtext{
        .subtext-head{
            display:block;
        }
        
        P{
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        margin: 0px;
        color:$purple-dark;
        font-weight: 500;        
        font-family: "M PLUS Rounded 1c",sans-serif;
    }
    }
    @media (max-width: $breakpoint-max){
        margin: 60px 20px;
        width:auto;
        h3
        {
            font-size: 20px;
            text-align: center;
            width: 100%;
            float: left;           
        }
        .id-binding-main-section-subtext{
            .subtext-head{
                display:inline-block;
                color: #592C82 !important;
            }
            p{
                text-align: left;
                font-size: 18px;
                   span{
                color: #e11383;
            }
            }
        }

    }
}
`;

export default styles;