import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.banner-container {
    width: 100%;
}

.eretailer-container {
    text-align: center;
    margin-bottom: 80px;

    h1 {
        font-size: 1.125rem;
        margin: 30px 0 20px;
        font-family: 'Noto Sans JP';
        font-weight: 500;
        @media only screen and (max-width: $breakpoint-max-md-ipad) {
            font-size: 1rem;
        }
    }
}

.retailbtn-container {
    a>img {
        width: 200px;
        margin-right: 30px;

        @media only screen and (max-width: $breakpoint-max-md-ipad) {
            margin: 0 auto 30px;
            display: block;
        }
    }    
}`;

export default styles;