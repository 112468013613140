import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.productSection {
    width: 100%;
    background-color: #efefef;
    margin-bottom: 100px;
    h2 {
        text-align: center;
        font-size: 22px;
        font-family: "SourceSansPro-Regular";
        color: $purple;
        margin: 70px auto 50px;
        position: relative;
        font-weight: 500;
        &::after {
            position: absolute;
            content: "";
            height: 4px;
            bottom: -16px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 60px;
            background: $purple;
        }
    }
    .filterMenuSection {
        max-width: 970px;
        margin: 0 auto 95px;
        background-color: white;
        padding: 34px;
        font-family: "Noto Sans JP";
        h3 {
            color: $purple;
            font-size: 20px;
            margin-bottom: 30px;
        }
        .filterMenu {
            color: $purple;
            font-size: 16px;
            display: inline-block;
            margin: 10px auto;
            padding: 8px 10px;
            background-color: #efefef;
            margin-right: 10px;
            &:hover {
                background: #8347ad;
                color: #fff;
                cursor: pointer;
            }
        }
        .active {
            background-color: $purple;
            color: #efefef;
            cursor: pointer;
        }
        ul {
            padding-left: 0;
            margin-bottom: 0 !important;
            li {
                // all: unset;
            }
        }
    }
    .productLineup {
        font-family: "Noto Serif JP";
        max-width: 970px;
        margin: 0 auto 100px;
        .basicLine {
            margin: 0 auto 80px;
            h3 {
                font-size: 26px;
                // font-family: "Noto Serif JP", serif;
                margin-bottom: 33px;
            }
            .productCategory {
                display: flex;
                width: 100%;
                float: left;
                position: relative;
            }
        }
        .category1 {
            color: $purple;
        }
        .category2 {
            color: $gold;
            width: 100%;
            float: left;
            margin: 65px 0 95px 0;
            animation: text-move 0.7s ease-in-out 0.9s 1 forwards;
        }
    }

    @media (max-width: $breakpoint-max) {
        .filterMenuSection {
            max-width: 100%;
            margin: 0 auto 20px;
            padding: 20px;
            background: #efefef;
            h3 {
                margin-bottom: 2px;
            }
            .filterMenu {
                background-color: #fff;
                margin-bottom: 0px;
                margin-top: 5px;
                margin-right: 8px;
            }
            .active {
                background-color: $purple;
                color: #efefef;
                cursor: pointer;
            }
        }
        .productLineup {
            max-width: 100%;
            widows: 100%;
            float: left;
            position: relative;
            margin: 0 auto;
            padding: 0 20px;
            .basicLine {
                margin: 0 auto;
                h3 {
                    margin-bottom: 20px;
                    width: 100%;
                    float: left;
                }
                .productCategory {
                    display: block;
                }
            }
            .category1 {
                color: $purple;
                float: left;
                width: 100%;
                position: relative;
            }
            .category2 {
                color: $purple;
                width: 100%;
                float: left;
                margin: 25px 0 88px 0;
            }
        }
    }
}
.Homepage .productSection{
    @media only screen and (min-width: 820px) and (max-width: 1000px){
      width:1000px;
    }
}`;

export default styles;