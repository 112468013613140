import React from 'react';
import Router from 'next/router';
import Style from './ReviewCampaignLP.scss';
import { storageSet } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';

const ReviewCampaignLogin = () => {
    const setGATagsAndEvents = async (eAction, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    const handleRegister = async () => {
        setGATagsAndEvents('event_profile_register', 'event_crm_action');
        storageSet('reviewflowStarts', 'true');
        Router.push('/registration');
    };

    const resolution = useScreenSize();
    if (!resolution) return null;

    return (
        <div className="Review-Campaing-Loginsection">
            <div className="Review-Campaing-PI">
                <div className="login-img pi pi-left">
                    <h2 className="title">
                        初めての方
                    </h2>
                    <p className="txt sub-text-one">
                        下記より会員登録後、レビューをご記入ください
                    </p>
                    <div className="Review-Campaing-Register">
                        <button className="Review-Campaing-Register-cta cta registration" type="button" onClick={handleRegister} />
                    </div>
                    <p className="txt">
                        無料で登録すれば、ウィスパー会員様限定のクーポン情報などが配信されます
                    </p>
                </div>
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

export default ReviewCampaignLogin;
