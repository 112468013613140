import React from "react";
import PropTypes from "prop-types";
import cssStyle from "./BannersContainer.scss";
import PromoBanner from "../PromoBanner/PromoBanner";

const BannersContainer = ({ banners, style }) => {
  return (
    <div className={`banner-container}`}>
      {banners.items.map((banner, ind) => {
        return <PromoBanner key={`banner-${ind + 1}`} {...banner} customClass={style}/>;
      })}
      <style jsx>{cssStyle}</style>
    </div>
  );
};

BannersContainer.propTypes = {
  banners: PropTypes.object,
  style:PropTypes.string
};

export default BannersContainer;
