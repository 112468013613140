import React, { useEffect, useState } from 'react';
import Router from 'next/router';
import Styles from './DigitalCampaignLPAllRetailer.scss';
import { storageSet } from '../../../src/helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const DigitalCampaignAllRetailer = () => {
    const [mainRetailer, setMainRetailer] = useState('');
    const [retailerList, setRetailerList] = useState([]);
    const [mainlandingPage, setMainLandingPage] = useState([]);

    const allRetialers = [
        {
            name: 'welcia',
            retailerName: 'ウエルシア',
            alt_text: 'ウエルシア',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/TIou0fIb269ufowcB1Ova/6d3d1dfbf72376d6373900874480a011/Welcia_logo.jpg',
        },
        {
            name: 'kyorindo',
            retailerName: '杏林堂',
            alt_text: '杏林堂',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/3JreAi9WMNzPq6dLhQckRd/bf3c10fbaeb0bcc38d8a70a401f85574/kyorindo-logo.png',
            isTsuruha: true,
        },
        {
            name: 'fukutaro',
            retailerName: 'くすりの福太郎',
            alt_text: 'くすりの福太郎',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/6xugjPa1e9bpbW4WuD7WpP/c9edbc6a251bac36ab6382ec979f26c3/fukutaro-logo-latest.jpg',
            isTsuruha: true,
        },
        {
            name: 'cocokarafine',
            retailerName: 'ココカラファイン',
            alt_text: 'ココカラファイン',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/32OdzKoDM11tANyXCYvu0O/7fc7ee7008b1a78dd610e4777fa69ecf/cocokarafine-logo.png',
            isMkCf: true,
        },
        {
            name: 'sapordrug',
            retailerName: 'サツドラ',
            alt_text: 'サツドラ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/1OEbk4qGRqhmEeWHoBrFXW/6bf0880d596fe0f6a0b4ca00af231ce0/logo_satsudora_p1_color.jpg',
        },
        {
            name: 'sundrug',
            retailerName: 'サンドラッグ',
            alt_text: 'サンドラッグ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/26K1wyQIzcX9LCmI6kCXFM/33b52b596063280d22bc8288ac41e4e0/sundrug_logo.png',
            isMkCf: false,
        },
        {
            name: 'tsuruha',
            retailerName: 'ツルハドラッグ',
            alt_text: 'ツルハドラッグ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/1Ek74mbZT6iNmvE7sP8MpE/fbcb66564addad4a9f26d950c657d582/tsuruha-logo.png',
            isTsuruha: true,
        },
        {
            name: 'b-and-d',
            retailerName: 'B&Dドラッグストア',
            alt_text: 'B&Dドラッグストア',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/57Pmh7nIn9zVghU8HsvTSW/797d8a3fd3b1acbb2521208dd66c5c8a/b-and-d-logo.png',
            isTsuruha: true,
        },
        {
            name: 'matsumotokiyoshi',
            retailerName: 'マツモトキヨシ',
            alt_text: 'マツモトキヨシ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/6OAOWrX2zNGoUJp9f3N3zH/75955ec4a2b5c736e6790008887825b0/matsumotokiyoshi-logo.png',
            isMkCf: true,
        },
        {
            name: 'lady',
            retailerName: 'レディ',
            alt_text: 'レディ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/7u4CzVwvUlThBOSkeIYRZ0/099ec7d92f47dc180b0bceea49efbe13/lady-logo.png',
            isTsuruha: true,
        }

    ];

    const retailerSelctionGATagsMapping = {
        'マツモトキヨシ': 'whisper_coupon_request_all_mk', // mk
        'ココカラファイン': 'whisper_coupon_request_all_cf', // cf
        'ツルハドラッグ': 'whisper_coupon_request_all_tsuruha',
        'くすりの福太郎': 'whisper_coupon_request_all_fukutaro',
        'レディ': 'whisper_coupon_request_all_dkady',
        '杏林堂': 'whisper_coupon_request_all_kyorindo',
        'B&Dドラッグストア': 'whisper_coupon_request_all_bandd',
        'サンドラッグ': 'whisper_coupon_request_all_sundrug',
        'カワチ': 'whisper_coupon_request_all_cawachi',
        'サツドラ': 'whisper_coupon_request_all_satsudora',
        'ウエルシア': 'whisper_coupon_request_all_welcia',
    };

    useEffect(() => {
        const { location } = window;
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        setMainRetailer(mydigiretailer);
        const landingpage = location.pathname.split('/')[2].split('-')[2];
        setMainLandingPage(landingpage);
        console.log(mydigiretailer);
        if (mydigiretailer === 'mkcf') {
            const retailerlist = allRetialers.filter((retailer) => retailer.isMkCf);
            setRetailerList(retailerlist);
        } else if (mydigiretailer === 'tsuruha') {
            const retailerlist = allRetialers.filter((retailer) => retailer.isTsuruha);
            setRetailerList(retailerlist);
        } else {
            setRetailerList(allRetialers);
        }
    }, []);

    const setGATagsAndEvents = async (eAction, eLabel, eCategory) => await window.dataLayer && window.dataLayer.push({
        event: 'customEvent',
        GAeventCategory: eCategory,
        GAeventAction: eAction,
        GAeventLabel: eLabel,
        GAeventValue: 0,
        GAeventNonInteraction: false,
    });

    const getRetailerBarcode = (retailer) => {
        setGATagsAndEvents('event_coupon_request', retailerSelctionGATagsMapping[retailer], 'event_coupon_action');

        if (mainRetailer === 'mkcf') {
            storageSet('digitalRetailer', retailer);
            Router.push('/campaign/coupon-mkcf-lp3/');
        } else if (mainRetailer === 'tsuruha') {
            storageSet('digitalRetailer', retailer);
            Router.push('/campaign/coupon-tsuruha-lp3/');
        } else if (mainRetailer === 'all') {
            storageSet('digitalRetailer', retailer);
            Router.push('/campaign/coupon-all-lp3/');
        }
    };

    return (
        <div>
            <ul className="digital-campaign-allretailers">

                {retailerList.length && retailerList.map((retailer, key) => (
                    <li key={key}>
                        <div className="logo-wrap">
                            <img src={buildContentfullImageUrl(retailer.img_Url)} alt={retailer.alt_text} width="auto" height="auto" />
                        </div>
                        {mainlandingPage !== 'lp1'
                            && (
                                <a role="button" onClick={() => { getRetailerBarcode(retailer.retailerName); }}>
                                    クーポンを獲得する

                                </a>
                            )}
                    </li>
                ))}
            </ul>
            <style jsx>{Styles}</style>
        </div>
    );
};
export default DigitalCampaignAllRetailer;
