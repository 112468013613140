import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.tabSection {
    margin-top: 150px;
    @media (max-width: $breakpoint-max){
        overflow-x: hidden;
    }
}
.tabs-container {
    float: left;
    margin: 0 auto;
    position: relative;
    width: 1000px;
    @media (max-width: $breakpoint-max) {
        width: 100%;
        float: left;
        margin: 0 auto;
    }
}
.tab-menu {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto 50px;
    .menu {
        border: 2px solid $purple;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding: 15px 0;
        color: $purple;
        font-size: 22px;
        position: relative;
        &:hover {
            background: $purple;
            color: #fff;
            cursor: pointer;
            &::after {
                content: "";
                width: 15px;
                height: 15px;
                border-style: solid;
                border-width: 12px 12px 0 12px;
                border-color: $purple transparent transparent transparent;
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        &.active {
            background: $purple;
            color: #fff;
            &::after {
                content: "";
                width: 15px;
                height: 15px;
                border-style: solid;
                border-width: 12px 12px 0 12px;
                border-color: $purple transparent transparent transparent;
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }
}
.tab-items {
    width: 1000px;
    float: left;
    margin: 0 auto;
    position: relative;
    @media (max-width: $breakpoint-max) {
        width: 100%;
    }
}
`;

export default styles;