import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.Id-Bind-Lohaco-Image-Text{
    margin: 0 0 50px 0;
    width:100%;
    float:left;
    position: relative;
    .Id-Bind-Lohaco-Image{
    width: 100%;
    float: left;
    margin: 40px 0;
    }
    h2{
    color: #592c82;
    font-family: "M PLUS Rounded 1c",sans-serif;
    font-size: 24px;
    margin: 0 0 25px 0;
    }
    p{
        color: #595757;
        font-family: "M PLUS Rounded 1c",sans-serif;
        font-size: 19px;
        font-weight: 500;       
    }

    @media (max-width: $breakpoint-max){
        width: auto;
        padding:0 10px 0 0 ;
        margin:20px 20px 10px 20px;
        background:#f5f5f5;

        .Id-Bind-Lohaco-Image{
            margin: 20px 0;
            width:100%;
            img{
                width:100%;
                margin:0 0 20px 0;
            }
            }
        h2{
            font-size: 14px;
            margin: 0 0 18px 0;
            }
            p{
                font-size: 11px;           
        
            }

            .scrollbar {
                background-color: #F5F5F5;
                float: left;
                height: 250px;
                margin-bottom: 0;
                margin-left: 0;
                margin-top: 0;
                width: 100%;
                overflow-y: scroll;
                padding: 20px;
                .force-overflow {
                    min-height:0px;
                }
            }
            #style-1::-webkit-scrollbar {
                width: 10px;
                background-color: #F5F5F5;
                
            } 
            #style-1::-webkit-scrollbar-thumb {
                background-color: #8347ad;
                border-radius: 5px;
                
            }
            #style-1::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #e5d3ef;
                margin-top: 10px;
                margin-bottom:10px;          
               
                
            }

    }

}`;

export default styles;