import React from 'react';
// import PropTypes from 'prop-types';
import Style from './couponlpcontent.scss';

const CouponContentLP2 = () => (
    <div className="Coupon-Campaing-Contentsection">
        <div className="Coupon-Campaing-Content">
            <h2 className=" Coupon-Campaing-header">【クーポン取得に関しての注意事項】</h2>
            <p className=" Coupon-Campaing-text">
                ●利用方法<br />
                クーポン有効期間中に、対象商品と本クーポン画面をお会計時にレジでご提示ください。
            </p>
            <p className=" Coupon-Campaing-text">
                ●対象店舗<br />
                サンドラッグ各店※一部取り扱いのない店舗がございます。
            </p>
            <p className=" Coupon-Campaing-text">
                ●クーポン対象商品<br />
                ウィスパーWガード・うすさら吸水・うすさら安心　各種
            </p>
            <p className=" Coupon-Campaing-text">
                ●ご利用上の注意<br />
                ※他のクーポンとの併用はできません。<br />
                ※クーポンご利用で該当商品お買上１点につき200円引きさせていただきます。<br />
                ※対象期間中、お一人様1回限りご利用できます。
            </p>
        </div>
        <style jsx>{Style}</style>
    </div>
);
export default CouponContentLP2;
