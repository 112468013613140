import React from 'react';
import PropTypes from 'prop-types';

import isCurrentPage from '../../Hooks/useCurrentPage';
import { areAllCategoriesSelected } from './CategorySelector.helper';

import Style from '../ProductList.scss';

const CategorySelector = ({ sectionTitle, categories, onTitleClick, isOpen }) => {
    const allCategoriesSelected = areAllCategoriesSelected(categories);

    const renderActiveCategory = (category, key) => (
        <li className="active" key={key}><h1><a href={category.url}>{category.name}</a></h1><style jsx>{Style}</style></li>
    );

    const renderAllCategoriesLink = (isSelected) => (isSelected
        ? <li><h1><a href="/all-product/">全ての商品</a></h1><style jsx>{Style}</style></li>
        : <li><a href="/all-product/">全ての商品</a><style jsx>{Style}</style></li>
    );

    return (
        <div className="filters">
            <h1 onClick={() => onTitleClick('categories')} className="filter-title">{sectionTitle}</h1>
            {isOpen && (
                <ul className="search-list">
                    {renderAllCategoriesLink(allCategoriesSelected)}
                    {categories.map((category, i) => (isCurrentPage(category.url)
                        ? renderActiveCategory(category, i)
                        : <li key={i}><a href={category.url}>{category.name}</a></li>
                    ))}
                </ul>
            )}
            <style jsx>{Style}</style>
        </div>
    );
};

CategorySelector.propTypes = {
    categories: PropTypes.array,
    isOpen: PropTypes.bool,
    onTitleClick: PropTypes.func,
    sectionTitle: PropTypes.string,
};

export default CategorySelector;
