/* eslint-disable import/no-cycle */
import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getRichText, getInlineRichText, getVideo, getSimpleImage } from '../helpers/uiHelper';

export const renderRichText = (richTextJson, links) => {
    const document = richTextJson;
    const { block, inline } = links.entries;
    const options = {
        renderText: typeof window === 'undefined' ? (text) => text : (text) => text.split('\n').flatMap((text1, i) => [i > 0 && <br />, text1]),
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                try {
                    const referencedEntry = block.find(({ sys }) => {
                        const nodeSysId = node.data.target.sys.id;
                        return sys.id === nodeSysId;
                    });
                    const { __typename } = referencedEntry;
                    if (referencedEntry) {
                        switch (__typename) {
                        case 'ContentTypeRichText':
                            return referencedEntry.advancedText
                                ? renderRichText(referencedEntry.advancedText.json, referencedEntry.advancedText.links)
                                : getRichText(referencedEntry);

                        case 'Video':
                            return getVideo(referencedEntry);

                        case 'IdbindLohacoJapanId':
                            return getSimpleImage(referencedEntry);

                        default:
                            break;
                        }
                    } else console.error('No entry found');
                } catch (error) {
                    console.error('Unable to generate richtext', error);
                }
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
                try {
                    const referencedEntry = inline.find(({ sys }) => {
                        const nodeSysId = node.data.target.sys.id;
                        return sys.id === nodeSysId;
                    });
                    const { __typename } = referencedEntry;
                    if (referencedEntry) {
                        switch (__typename) {
                        case 'ContentTypeRichText':
                            return getInlineRichText(referencedEntry);
                        case 'IdbindLohacoJapanId':
                            return getSimpleImage(referencedEntry);
                        default:
                            break;
                        }
                    } else console.error('No entry found');
                } catch (error) {
                    console.error('Unable to generate richtext', error);
                }
            },
        },
    };
    return <>{documentToReactComponents(document, options)}</>;
};
