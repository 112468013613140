import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.digital-campaign-allretailers {
    width: 70%;
    margin: 0 auto; 
    margin-bottom: 70px;   

    li {
        width: 24%;
        padding: 20px;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        .logo-wrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 105px;
            img {
                width: 100%;
            }            
        }
        a{
            &::after {
                border-bottom: 7px solid transparent;
                border-left: 10px solid #592C82;
                border-left-color: inherit;
                border-top: 7px solid transparent;
                content: "";
                height: 0;                
                position: absolute;                
                width: 0;
                margin: 3px 0 0 5px;
            }
            font-size: 14px;
            color:$purple;
            text-decoration:underline;
            font-weight: 500;
            font-family: "M PLUS Rounded 1c",sans-serif;
            margin: 10px 0 0 0;
            width: 100%;
            float: left;
            cursor: pointer;
        }
        
    }
    @media (max-width: $breakpoint-max){
        width:100%;
        margin:0 auto;
        padding:0;
        margin-bottom: 40px;
        float: left;
        li{
            width:49%;
            display: block;
            float: left;            
        }
    }
}
`;

export default styles;