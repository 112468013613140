import React, { useEffect, useState } from 'react';
import Style from './DigitalCampaignLP.scss';
import DigitalCampaignLPContent from './DigitalCampaignLPContent';
import DigitalCampaignLPlogin from './DigitalCampaignLPLogin';
import DigitalCampaignLPBanner from './DigitalCampaignLPbanner';
import DigitalCampaignAllRetailer from './DigitalCampaignLPAllRetailer';

const DigitalCampaignLP = () => {
    const [multiReatailer, setmultiRetailer] = useState('false');

    useEffect(() => {
        const { location } = window;
        //  = new URLSearchParams(search);
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        const retailerlogo = mydigiretailer;
        console.log('retailerlogo', retailerlogo);
        if (retailerlogo === 'all' || retailerlogo === 'mkcf' || retailerlogo === 'tsuruha') {
            setmultiRetailer('true');
        }
    }, []);

    return (
        <div className="digital-campaign-lp">
            <p className="digital-title"> <span>【ウィスパー公式サイト】 </span></p>
            <DigitalCampaignLPBanner />
            {multiReatailer === 'true' && <DigitalCampaignAllRetailer />}
            <DigitalCampaignLPlogin />
            <DigitalCampaignLPContent />
            <style jsx>{Style}</style>
        </div>
    );
};

export default DigitalCampaignLP;
