import React, { useEffect, useState } from 'react';
import { storageGet } from '../../helpers/StorageEvents';
import useScreenSize from '../Hooks/useScreenSize';
import Styles from './DigitalCampaignLPbanner.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const DigitalCampaignLPBanner = () => {
    const allRetialers = [

        {
            name: 'welcia',
            retailerName: 'ウエルシア',
            alt_text: 'ウエルシア',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/gsdoqvoJUPRF1XM22kWSd/402d0df21fdad2b13f512dbc9ce8c886/LogoForLP3page.jpg',
        },
        {
            name: 'kyorindo',
            retailerName: '杏林堂',
            alt_text: '杏林堂',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/3JreAi9WMNzPq6dLhQckRd/bf3c10fbaeb0bcc38d8a70a401f85574/kyorindo-logo.png',
            isTsuruha: true,
        },
        {
            name: 'fukutaro',
            retailerName: 'くすりの福太郎',
            alt_text: 'くすりの福太郎',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/6xugjPa1e9bpbW4WuD7WpP/c9edbc6a251bac36ab6382ec979f26c3/fukutaro-logo-latest.jpg',
            isTsuruha: true,
        },
        {
            name: 'cocokarafine',
            retailerName: 'ココカラファイン',
            alt_text: 'ココカラファイン',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/32OdzKoDM11tANyXCYvu0O/7fc7ee7008b1a78dd610e4777fa69ecf/cocokarafine-logo.png',
            isMkCf: true,
        },
        {
            name: 'sapordrug',
            retailerName: 'サツドラ',
            alt_text: 'サツドラ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/1OEbk4qGRqhmEeWHoBrFXW/6bf0880d596fe0f6a0b4ca00af231ce0/logo_satsudora_p1_color.jpg',
        },
        {
            name: 'sundrug',
            retailerName: 'サンドラッグ',
            alt_text: 'サンドラッグ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/26K1wyQIzcX9LCmI6kCXFM/33b52b596063280d22bc8288ac41e4e0/sundrug_logo.png',
            isMkCf: false,
        },
        {
            name: 'tsuruha',
            retailerName: 'ツルハドラッグ',
            alt_text: 'ツルハドラッグ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/1Ek74mbZT6iNmvE7sP8MpE/fbcb66564addad4a9f26d950c657d582/tsuruha-logo.png',
            isTsuruha: true,
        },
        {
            name: 'b-and-d',
            retailerName: 'B&Dドラッグストア',
            alt_text: 'B&Dドラッグストア',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/57Pmh7nIn9zVghU8HsvTSW/797d8a3fd3b1acbb2521208dd66c5c8a/b-and-d-logo.png',
            isTsuruha: true,
        },
        {
            name: 'matsumotokiyoshi',
            retailerName: 'マツモトキヨシ',
            alt_text: 'マツモトキヨシ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/6OAOWrX2zNGoUJp9f3N3zH/75955ec4a2b5c736e6790008887825b0/matsumotokiyoshi-logo.png',
            isMkCf: true,
        },
        {
            name: 'lady',
            retailerName: 'レディ',
            alt_text: 'レディ',
            img_Url: 'https://images.ctfassets.net/8g0fievzqg8k/7u4CzVwvUlThBOSkeIYRZ0/099ec7d92f47dc180b0bceea49efbe13/lady-logo.png',
            isTsuruha: true,
        },

    ];

    const resolution = useScreenSize();
    const [retailer, setRetailer] = useState('');
    const [sundrug, setSundrug] = useState('');
    const [customerRetailer, setCustomerRetailer] = useState();
    const [all, setAll] = useState('');
    const [mkcf, setMkcf] = useState('');
    const [tsuruha, setTsuruha] = useState('');
    const [isLp3, setIsLp3] = useState('false');

    useEffect(() => {
        const { location } = window;
        const mydigiretailer = location.pathname.split('/')[2].split('-')[1];
        setRetailer(mydigiretailer);

        const landingPage = location.pathname.split('/')[2].split('-')[2];
        setIsLp3(landingPage);
        const customer = storageGet('digitalRetailer');
        const filteredRetailor = allRetialers.filter((cust) => cust.retailerName === customer);
        setCustomerRetailer(filteredRetailor[0]);

        const retailerlogo = mydigiretailer;
        console.log('retailerlogo', retailerlogo);
        if (retailerlogo === 'sundrug') {
            setSundrug('true');
        } else if (retailerlogo === 'all') {
            setAll('true');
        } else if (retailerlogo === 'mkcf') {
            setMkcf('true');
        } else if (retailerlogo === 'tsuruha') {
            setTsuruha('true');
        }
    }, []);

    if (!resolution) return null;
    const img = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/v2VFNYDSMbj4lUhxrPyB3/8c856331d3aaa2d826518b3467e6f4eb/digital_coupon_campaign_lp1_sp.jpg' : 'https://images.ctfassets.net/8g0fievzqg8k/4AR78QaV64hOfjCxg7vyqi/0e12f201f42849444bc5719fd2862313/digital_coupon_campaign_lp1_banner_PC.png';
    const allimg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/7JpyWZI0vBpvOPTUGyIZXy/7aa9d377fee3b1f5a47fff4ab6988b0e/DC_all_campaign_sp.jpg' : 'https://images.ctfassets.net/8g0fievzqg8k/2HktJGeNEBgcFa3NPPn8i0/7615bd6595b681af14aafb9da14daf70/Digital_all_campaign_pc.jpg';
    const mkcfimg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/1rUL0Y9sZmdqyvrRrdVWax/b5633fa78ef9fc0b4fd58d44e5c85111/Digital_mkcf_campaign_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/2VNp4B5hpqNIpR0o3QeznX/981eb3d71f01bf7837a14adde3d07af9/Digital_mkcf_campaign_pc.png';
    const tsuruhaimg = resolution.isMobile ? 'https://images.ctfassets.net/8g0fievzqg8k/3ojrnMRDIrKanhV6SHzydl/d7d15090c5190025725934b42420f34e/tsuruha_sp.png' : 'https://images.ctfassets.net/8g0fievzqg8k/3sGyt6QaU8nh2N7iGOmaC/905b12e0cc0ae21d929cabaf50b0b996/tsuruha_pc.png';
    const Retailerimg = (sundrug === 'true') && 'https://images.ctfassets.net/8g0fievzqg8k/26K1wyQIzcX9LCmI6kCXFM/33b52b596063280d22bc8288ac41e4e0/sundrug_logo.png';
    const RetailerPage = (window.location.pathname === '/campaign/coupon-all-lp3/'
                            || window.location.pathname === '/campaign/coupon-mkcf-lp3/'
                            || window.location.pathname === '/campaign/coupon-tsuruha-lp3/'
                            || window.location.pathname === '/campaign/coupon-sundrug-lp2/');
    console.log(window.location.pathname, 'retailer');
    console.log(retailer, 'retailer');
    return (
        <>
            <div className={RetailerPage ? 'digital-campaign-banner-container banner-reverse' : 'digital-campaign-banner-container'}>
                {(all === 'true')
                    && (
                        <h1 className="digital-campaign-banner">
                            <img src={buildContentfullImageUrl(allimg)} alt="店頭で使える！300円OFFクーポン配信中！" height="auto" width="auto" />
                            <p className="digital-campaign-banner-txt">クーポン使用可能期間:2021/11/1（月）〜 12/31（金）</p>
                        </h1>
                    )}

                {(mkcf === 'true')
                && (
                    <h1 className="digital-campaign-banner">
                        <img src={buildContentfullImageUrl(mkcfimg)} alt="ツルハ" height="auto" width="auto" />
                        <p className="digital-campaign-banner-txt">クーポン使用可能期間:2021/11/1（月）〜 12/31（金）</p>
                    </h1>
                )}

                {(tsuruha === 'true')
                && (
                    <h1 className="digital-campaign-banner">
                        <img src={buildContentfullImageUrl(tsuruhaimg)} alt="ツルハ" height="auto" width="auto" />
                        <p className="digital-campaign-banner-txt">クーポン使用可能期間:2021/11/1（月）〜 12/31（金）</p>
                    </h1>
                )}

                { (sundrug === 'true')
                 && (
                     <h1 className="digital-campaign-banner">
                         <img src={buildContentfullImageUrl(img)} alt="店頭で使える！300円OFFクーポン配信中！" height="auto" width="auto" />
                         <p className="digital-campaign-banner-txt">クーポン使用可能期間：2021/10/25（月) 〜12/31（金）</p>
                     </h1>
                 )}

                {retailer === 'sundrug' && (
                    <div className="digital-campaign-retailer">
                        <img src={buildContentfullImageUrl(Retailerimg)} alt="サンドラッグ" height="auto" width="auto" />
                    </div>
                )}
                {(retailer === 'all' || retailer === 'tsuruha' || retailer === 'mkcf') && isLp3 === 'lp3' && customerRetailer !== undefined && (
                    <div className="digital-campaign-retailer">
                        <img src={buildContentfullImageUrl(customerRetailer.img_Url)} alt={customerRetailer.alt_text} height="auto" width="auto" />
                    </div>
                )}
                <style jsx>{Styles}</style>
            </div>

        </>
    );
};

export default DigitalCampaignLPBanner;
