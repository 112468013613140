import React, { useEffect, useState } from 'react';
import { find } from 'lodash';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';
import { liveUrl, liveImgPath, previewImgPath } from '../../helpers/constants';

const fetch = require('node-fetch');

const findUrlById = (id, allPages) => {
    const foundPage = find(allPages, (page) => page.sys.id === id);
    return foundPage.seoHead.url;
};
const prepareRichTextContent = (json, allPages) => {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <i>{text}</i>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p className='artilce-text-common-para'>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const src = node.data.target.sys.id;
                const [advice, setAdvice] = useState('');
                const [alttag, setAlttag] = useState('');
                useEffect(() => {
                    const url = (location.hostname === liveUrl) ? liveImgPath+`/environments/cloudinary-migration/assets/${src}?access_token=`+process.env.CF_DELIVERY_ACCESS_TOKEN:previewImgPath+`assets/${src}?access_token=`+process.env.CF_DELIVERY_ACCESS_TOKEN  
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url);
                            const jsonres = await response.json();
                            setAdvice(jsonres.fields.file.url);
                            setAlttag(jsonres.fields.description);
                        } catch (error) {
                            console.log('error', error);
                        }
                    };

                    fetchData();
                }, []);
                return (
                    <div className="img-wrapper">
                        <img src={buildContentfullImageUrl(advice)} alt={alttag} className='embeddedImg' />
                    </div>
                );
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                const src = node.data.target.sys.id;
                const [advice, setAdvice] = useState('');
                useEffect(() => {
                    const url = (location.hostname === liveUrl) ? liveImgPath+`/environments/cloudinary-migration/entries/${src}?access_token=`+process.env.CF_DELIVERY_ACCESS_TOKEN:previewImgPath+`entries/${src}?access_token=`+process.env.CF_DELIVERY_ACCESS_TOKEN
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url);
                            const jsonres = await response.json();
                            setAdvice(jsonres.fields.videoLink);
                        } catch (error) {
                            console.log('error', error);
                        }
                    };

                    fetchData();
                }, []);
                return (
                    <div className="video-youtube-part-two">
                        <div className="youtubeVideo">
                            <iframe
                                title=" youtube-video"
                                width="600"
                                height="338"
                                src={advice}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                );
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const url = findUrlById(node.data.target.sys.id, allPages);
                return <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url} download>{children}</a>;
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                const src = node.data.target.sys.id;
                const [pdflink, setPdfLink] = useState('');
                useEffect(() => {
                    const url = liveImgPath+`/assets/${src}?access_token=`+process.env.CF_DELIVERY_ACCESS_TOKEN;
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url);
                            const jsonres = await response.json();
                            setPdfLink(jsonres.fields.file.url);
                        } catch (error) {
                            console.log('error', error);
                        }
                    };

                    fetchData();
                }, []);
                return (
                    <a
                        className={classNameForGA(pdflink)}
                        {...dataActionForGA(pdflink)}
                        href={pdflink}
                        target="_blank"
                        rel="noreferrer"
                        download
                    >{children}
                    </a>
                );
            },
        },
    };
    return documentToReactComponents(json, options);
};

const isAboutPage = () => typeof window !== 'undefined' && /about-whisper/.test(window.location.pathname);

export { prepareRichTextContent, isAboutPage };
