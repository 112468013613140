import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.productsLineUp-card.basic-product {
    width: 270px;
    max-width: 100%;
    margin-right: 20px;

    @media (max-width: $breakpoint-max-md-ipad) {
        width: 50%;
        margin-right: 0;
        padding-right: 10px;
    }

    .product-card-image {
        background-color: $light-purple-bg;
        border-radius: 12px;
    }

    h4 {
        font-size: 36px;
        color: #64369F;
        font-family: 'Noto Sans JP';
        font-weight: 500;
        text-align: center;
        margin-top: 20px;

        @media (max-width: $breakpoint-ipad-max) {
            font-size: 28px;
        }
    }
}

.productsLineUp-card-text{
    position: relative;
    text-align: center;
    padding-top: 30px;
    .pack-bar {
        margin: 0 10px;
    }
}`;

export default styles;