const preview = process.env.PREVIEW;
module.exports = `
... on Interview {
    nameEn
      heading
      heading1
      heading2
      subheading
      content {
        json
      }
      images: imagesCollection(preview: ${preview}) {
        items {
          cloudinaryId
          description
          asset{url}
        }
      }
}
`;
