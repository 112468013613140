import React from 'react';
import PropTypes from 'prop-types';

import Style from './Sitemap.scss';

const SitemapOtherLinks = ({ links }) => (
    <div className="other-links">
        <ul>
            {links.items.map((link, i) => {
                const isExternalLink = link?.page?.__typename === 'ExternalLink';
                const url = isExternalLink ? link?.page?.url : link?.page?.seoHead?.url;
                const { name } = link;
                const target = isExternalLink && link?.page?.newWindow ? '_blank' : '_self';

                return (
                    <li key={i}><h3><a href={url} title={name} target={target}>{name}</a></h3></li>
                );
            })}
        </ul>
        <style jsx>{Style}</style>
    </div>
);

SitemapOtherLinks.propTypes = {
    links: PropTypes.object,
};

export default SitemapOtherLinks;
