import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import useScreenSize from '../Hooks/useScreenSize';
import Image from '../CloudinaryImage/CloudinaryImage';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import { isProductPages, isAbsorbencyPages, isLineUpPages } from '../../helpers/uiHelper';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

import Style from './StaticHeader.scss';

const h1Text = {
    'all-product': 'ウィスパー全ての商品ラインナップ紹介',
    'philosophy': 'ウィスパーの想い-すべての女性が自分らしい毎日を送れるように',
    'syouryouyou': 'ウィスパー20cc-30ccの少量用タイプ',
    'orimonoandkyusuicare': 'ウィスパーおりもの対応の吸水ケアシリーズ',
    'cyuuryouyou': 'ウィスパー45cc-85ccの中量用タイプ',
    'ooitoki': 'ウィスパー100cc-300ccの多いとき・長時間用タイプ',
    'adult-diaper': 'ウィスパー大人用おむつうすさらシルキー',
    '1mai2yaku': 'おりものも対応ウィスパー1枚2役Wガード ライナータイプ',
    'ususara-kyusui': 'ウィスパーうすさら吸水30cc-150cc ナプキンタイプ',
    'ususara-anshin': 'ウィスパーうすさら安心20cc-300cc パッドタイプ',
    'ususara-airy': '極上の肌心地。プレミアムシリーズのうすさらエアリー',
    'ususara-airy-plus': '極上の肌心地。プレミアムシリーズのうすさらエアリープラス',
    'ususara-silky': '大人用おむつ ウィスパーうすさらシルキー パンツタイプ',
};

const StaticHeader = ({ imageSp, imageDt, pageInfo }) => {
    let resolution = useScreenSize();
    if (!resolution) {
        resolution = { 'isMobile': true };
    }

    const image = buildContentfullImageUrl(resolution.isMobile ? getImageUrl(imageSp?.asset.url) : getImageUrl(imageDt?.asset.url));
    const imageset = resolution.isMobile ? imageSp : imageDt;
    const dynamicStyle = {
        backgroundImage: `url(${image})`,
    };

    const renewal = () => {
        let currentLocation = '';
        if (typeof window !== 'undefined') {
            currentLocation = window.location.href;
        } else {
            currentLocation = pageInfo.url;
        }
        return !!currentLocation.match(/article-top/g);
    };

    const pathName = typeof window !== 'undefined' ? window.location.pathname : pageInfo?.url;
    const firstPathName = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : pageInfo?.url.split('/')[1];
    const secondPathName = typeof window !== 'undefined' ? window.location.pathname.split('/')[2] : pageInfo?.url.split('/')[2];

    return (
        <>
            <Head>
            <link rel="preload" href={buildContentfullImageUrl(getImageUrl(imageset.asset.url, '1920'))} as="image" />
            </Head>
            {isProductPages(pathName)
                ? (
                    <div className="static-header-banner">
                        <h1>
                            <Image image={imageset} />
                            <span>
                                {((isAbsorbencyPages(pathName) === true || isLineUpPages(pathName) === true) && h1Text[secondPathName])
                                    || (isProductPages(pathName) && h1Text[firstPathName])}
                            </span>
                        </h1>
                    </div>
                )
                : <div className={renewal() ? 'mobile-renewal static-header' : 'static-header'} style={dynamicStyle} />}
            <style jsx>{Style}</style>
        </>
    );
};

StaticHeader.propTypes = {
    imageDt: PropTypes.object,
    imageSp: PropTypes.object,
    pageInfo: PropTypes.object,
};

export default StaticHeader;
