import React from 'react';
// import PropTypes from 'prop-types';
import Style from './couponlpcontent.scss';

const CouponContent = () => (
    <div className="Coupon-Campaing-Contentsection">
        <div className="Coupon-Campaing-Content">
            <h3 className=" Coupon-Campaing-header">【クーポン取得に関しての注意事項】</h3>
            <p className=" Coupon-Campaing-text">
                ●サインインもしくは会員登録後にLINEクーポンページまでお進みいただくと、自動的にクーポンが獲得でき、LINEクーポンの『お気に入りクーポン』ページに登録されます。
                途中で離脱されるとクーポンが取得できませんので、ご注意ください。<br />

                ●商品をお買い求めの際にレジ前でお気に入りクーポンページより『クーポンをつかう』ボタンを押してご利用ください。<br />

                ●クーポンのご利用は「お１人さま１回限り」有効となります。<br />

                ●店舗によりクーポンの対象商品が一部取り扱われていない場合があります。ご了承ください。
            </p>
        </div>
        <style jsx>{Style}</style>
    </div>
);
export default CouponContent;
