import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.IdBindLohacoBoxText{
    width:100%;
    float:left;
    position: relative;
    margin: 10px auto 30px;

    .id-binding-heading {
        position: relative;
        display: flex;
        width: 100%;
        float: left;
        justify-content: center;
        align-items: center;
        .id-binding-imageLohaco{
          width:70%
        }
        .id-binding-imagewhisper{
            width:70%;
        }
        .id-binding-imageDivider{
            width:auto;
            margin:0 40px;
        }
        img{
            width: 100%;
        }
    }
    .IdBindLohacoBox {
        border-radius: 18px;
        margin: 40px 0 10px;
        padding: 20px 20px 22px;
        box-sizing: border-box;
        position: relative;
        float: left;
        width: 100%;
        border: 2px solid #8347ad;
    
        .IdBindLohacoBoxText-header{
            h3{
            color: #592c82;
            font-family: "M PLUS Rounded 1c",sans-serif;
            font-size: 24px;
            text-align: center;
            font-weight: 500;
            span{
                    color: #e11383;
                    font-size: 42px;
                    font-weight: 500;
                } 

            }
        }
        .points{
            width: 85%;
            margin: 0 auto;
            position: relative;
        .point1{
            position: relative;
            margin:20px 0 20px 0;
                h3{
                    color:#595757;
                    font-family: "M PLUS Rounded 1c",sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    margin:0 0 12px;
                    .lohaco{
                        font-size: 42px;
                    }
                    .blue-br{
                        font-size: 42px;
                        color: $purple;
                        background-image: url(/static/images/id-bind-br.png);
                        background-position: 0 35px;
                        background-repeat: no-repeat;
                        background-size: 100%;

                    }
                    .pink-dot{
                        color: #e11383;
                        font-size: 48px;
                        border-bottom: 4px dotted #592c82;
                    }
                    .checkbox{
                        position: absolute;
                        top: 17px;
                        left: -25px;
                        height: 20px;
                        width: 20px;
                        border: 1.2px solid #000;
                        background: #fff;
                    }
                    .txt{                        
                            font-size: 42px;
                     }                  
                    
                }
                p{
                    color: #595757;
                    font-family: "M PLUS Rounded 1c",sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;

                }
                .hightext{
                    margin:5px 0 5px 20px;
                    float: left;
                    width: 100%;
                   

                }
        }
        .pt{
            position: relative;
            margin:0;
            h3{
                color: #595757;
                    font-family: "M PLUS Rounded 1c",sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    .grey-br{
                        font-size: 42px;
                        background-image: url(/static/images/id-bind-br.png);
                        background-position: 0 45px;
                        background-repeat: no-repeat;
                        background-size: 100%;

                    }
                    .checkbox{
                        position: absolute;
                        top: 22px;
                        left: -25px;
                        height: 20px;
                        width: 20px;
                        border: 1.2px solid #000;
                        background: #fff;
                    }
            }
        }
        
    }

    } 
    .microtext{
        color: #000;
        font-family: "M PLUS Rounded 1c",sans-serif;
        font-size: 13px;
        font-weight: 500;
     }

    @media (max-width: $breakpoint-max){
        .id-binding-heading{
            display: inline-block;
            width: 100%;
            float: left;
            margin:40px 0 0 0;
           
            .id-binding-imageLohaco{
                position: relative;
                width: 88%;
                top: 0px;
                float:left;
            }
            .id-binding-imagewhisper{
                width:100%;
                float:left;
            }
            .id-binding-imageDivider{
                float:left;
                width:12%;
                margin:10px 0 0;
            }
        }

        .IdBindLohacoBox{
            border: none;
            border-bottom: 2px solid #8347ad;
            border-radius: 0;
            float: left;
            margin: 10px 0 25px;
            border-top: 2px solid #8347ad;
            .IdBindLohacoBoxText-header{
                margin:0 0 20px 0;
                h3{
                 font-size: 16px;
                 span{
                     font-size: 30px; 
                     display:block;                   
                    }     
                }
            }
            .points{
               width:80%;
            .point1{
                margin:0 0 20px 0;                
                
                    h3{
                        font-size: 12px; 
                        margin:5px 0;                       
                        .lohaco{
                            font-size: 25px;
                        }
                        .blue-br{
                            font-size: 25px; 
                            line-height: 50px; 
                            background-image: url(/static/images/id-bind-br.png);
                            background-position: 0 23px;
                            background-repeat: no-repeat;
                            background-size: 100%;                          
                        }
                        .pink-dot{                            
                            font-size: 25px;                           
                        }
                        .txt{                        
                                font-size: 25px;
                         }  
                         .checkbox{
                            left: -28px;
                            top:6px;
                         }                
                        
                    }
                  
                    .hightext{
                        margin:5px 0 5px 20px;
                        float: left;
                        width: 100%;                     
                    }
                    p{
                        margin:5px 0;
                        font-size: 12px;
                    }
            }
            .pt{
                position: relative;
                margin: 10px 0;
                h3{
                    font-size: 16px;                        
                        .grey-br{
                            font-size: 25px; 
                            // display: block;
                            line-height: 32px; 
                            background-image: url(/static/images/id-bind-br.png);
                            background-position: 0 23px;
                            background-repeat: no-repeat;
                            background-size: 100%;
                        }
                        .checkbox{
                            left: -28px;
                            top:12px;
                         }
                }
            }
            
            
        }
        }
        .microtext{
            margin:20px;
         }

    }
}


@media (max-width: $breakpoint-max){
    .point4{
       .checkbox{
           top:5px !important;
       }
    }
}`;

export default styles;