import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import Style from './ExpertsVoice.scss';
import useScreenSize from '../Hooks/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const ExpertsVoice = ({ title, subtitle, subtitleMobile, subtitle1, image, lineImageDt, text, textMobile }) => {
    const resolution = useScreenSize();
    const subtitleFormated = resolution && resolution.isMobile ? subtitleMobile : subtitle;
    const textFormated = resolution && resolution.width < 700 ? textMobile : text;

    return (
        <div className="experts-voice">
            <div className="top-line">
                <img src={buildContentfullImageUrl(getImageUrl(lineImageDt?.asset?.url, {}))} alt={lineImageDt.title} />
            </div>
            <div className="title-block">
                {image?.aseet?.url && <img src={buildContentfullImageUrl(getImageUrl(image?.aseet?.url, {}))} alt={image.title} /> }
                <h2>
                    <ReactMarkdown
                        source={title && title}
                        escapeHtml={false}
                        disallowedTypes={['paragraph']}
                        unwrapDisallowed
                    />
                </h2>
                <div className="subtitle">
                    <h3>
                        <ReactMarkdown
                            source={subtitleFormated && subtitleFormated}
                            escapeHtml={false}
                            disallowedTypes={['paragraph']}
                            unwrapDisallowed
                        />
                    </h3>
                    <h4>{subtitle1}</h4>
                </div>

            </div>
            <ReactMarkdown
                source={textFormated && textFormated}
                escapeHtml={false}
            />
            <div className="bottom-line">
                <img src={buildContentfullImageUrl(getImageUrl(lineImageDt?.asset?.url, {}))} alt={lineImageDt.title} />
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

ExpertsVoice.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleMobile: PropTypes.string,
    subtitle1: PropTypes.string,
    image: PropTypes.object,
    lineImageDt: PropTypes.object,
    text: PropTypes.string,
    textMobile: PropTypes.string,
};

export default ExpertsVoice;
