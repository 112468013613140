const preview = process.env.PREVIEW;
module.exports = `
... on ProductList {
allProducts
lookForAProduct
searchByLength
lookForTheWa
pantsType
promoBannerWithCta {
  imageDt {
    cloudinaryId
    description
    asset{url}  }
  imageSp {
    cloudinaryId
    description
    asset{url}
  }
  button {
    ... on ButtonExternal {
      externalUrl
      text
      target
    }
    ... on ButtonInternal {
      text
      target
      url {
        seoHead {
          url
        }
      }
    }
  }
}
smallPromoBannersContainer {
  bannersCollection(limit: 2,preview: ${preview}) {
    items {
      smallText
      bigText
      midText
      imageSp {
        cloudinaryId
        description
        title
        asset{url}
      }
      imageDt {
        description
        title
        cloudinaryId
        asset{url}
      }
      button {
        ... on ButtonExternal {
          externalUrl
          text
          target
        }
        ... on ButtonInternal {
          text
          target
          url {
            seoHead {
              url
            }
          }
        }
      }
    }
  }
}
retailersDropdown {
  dropdownTitle
  retailersCollection(limit: 5,preview: ${preview}) {
    items {
      nameEn
      imageDt {
        cloudinaryId
        description
        asset{url}
      }
      imageSp {
        cloudinaryId
        description
        asset{url}
      }
      link {
        label
        url
        newWindow
      }
    }
  }
}
promoBannerInterview {
  nameEn
  imageDt {
    title
    description
    cloudinaryId
    asset{url}
  }
  imageSp {
    cloudinaryId
    title
    description
    asset{url}
  }
    button {
      ... on ButtonExternal {
        externalUrl
        text
        target
      }
      ... on ButtonInternal {
        text
        target
        url {
          seoHead {
            url
          }
        }
      }
    }
  }
}
`;
