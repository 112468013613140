import React from 'react';
import PropTypes from 'prop-types';
import CloudinaryImage from '../CloudinaryImage/CloudinaryImage';

import useScreenSize from '../Hooks/useScreenSize';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const Image = ({ src, alt, title, imageDt, imageSp }) => {
    const resolution = useScreenSize();

    if (!resolution) {
        return null;
    }

    if (imageDt || imageSp) {
        const image = resolution.isMobile ? imageSp : imageDt;

        return <CloudinaryImage image={image} />;
    }
    const image = {
        src,
        alt,
        title,
    };

    return <img src={buildContentfullImageUrl(image.src)} alt={image.alt} title={image.title} />;
};

Image.propTypes = {
    alt: PropTypes.string,
    imageDt: PropTypes.object,
    imageSp: PropTypes.object,
    src: PropTypes.string,
    title: PropTypes.string,
};

export default Image;
