import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import Loader from 'react-loader-spinner';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import style from './LohacoIdBinding.scss';
import getRetailer from '../../adapters/api.adapter';
import { Dialog, DialogTitle } from '../Dialog';
import { storageGet, storageSet, storageKeysRemove, storageKeyRemove, GetEmailVerified } from '../../helpers/StorageEvents';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

class LohacoIdBinding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            successStatus: false,
            errorStatus: false,
            errorMsgStatus: '',
            releaseStatus: false,
            unBindStatus: false,
            unBindloader: false,
            unBindErrorStatus: false,
            loaderStatus: false,
        };
        this.handleIDUnbind = this.handleIDUnbind.bind(this);
    }

    componentDidMount() {
        storageKeyRemove('IDBindingVia');
        if (storageGet('isUserLoggedIn') && GetEmailVerified()) {
            if (storageGet('authCode')) {
                this.setState({ loaderStatus: true });
                const retailerId = storageGet('retailerId');
                getRetailer.getCreateBinding(Number(retailerId))
                    .then(async (response) => {
                        if (response.status === 200) {
                            storageSet('completeIDBinding', true);
                            await this.GADataLayer('event_crm_action', 'event_lohaco_idbinding_complete', true);
                            this.setState({ successStatus: true, errorStatus: false, loaderStatus: false });
                        } else {
                            this.setState({ successStatus: false, errorStatus: true, loaderStatus: false });
                        }
                    }).catch(async (error) => {
                        if (error && error.response && error.response.status === 409) {
                            this.setState({ successStatus: false, errorStatus: true, loaderStatus: false, errorMsgStatus: 'このIDは既に別のウィスパーアカウントで連携されているため利用できません。' });
                        } else {
                            this.setState({ successStatus: false, errorStatus: true, loaderStatus: false, errorMsgStatus: 'アクセスが集中しています。しばらく経ってからお試しください。' });
                        }
                    });
            } else if (storageGet('LohacoRelease')) {
                this.setState({ releaseStatus: true });
            } else if (storageGet('LohacoUnbind')) {
                this.setState({ unBindloader: true });
                this.handleIDUnbind();
            }
        } else {
            Router.push('/id-bind');
        }
    }

    componentWillUnmount() {
        storageKeysRemove(['authCode', 'LohacoRelease']);
    }

    GADataLayer = (eventCategory, eventAction, eventLabel) => {
        if (typeof window !== 'undefined') {
            window.dataLayer.push({
                'event': 'customEvent',
                'GAeventCategory': eventCategory,
                'GAeventAction': eventAction,
                'GAeventLabel': typeof eventLabel === 'boolean' ? window.location.href : eventLabel,
                'GAeventValue': 0,
                'GAeventNonInteraction': false,
            });
        }
        return true;
    }

    handleNextRedirect = async () => {
        await this.GADataLayer('event_informational_action', 'event_lohaco_outbound_link', 'whisper_next_idbinding');
        const nextURL = `${process.env.IDBINDING_YAHOO_URL}`;
        window.location.href = nextURL;
    }

    handleIDUnbind = async () => {
        await getRetailer.getUnBind().then(async (response) => {
            if (response.status === 200) {
                storageKeyRemove('bindingID');
                await this.GADataLayer('event_coupon_action', 'event_lohaco_coupon_unbinding_completion', true);
                this.setState({ unBindloader: false, unBindStatus: true });
            } else {
                console.log('Some issues in ID unbinding log: ', response);
            }
        }).catch(() => {
            this.setState({ unBindErrorStatus: true, unBindloader: false });
        });
    }

    handleIDBindLanding = () => {
        storageKeysRemove(['authCode']);
        Router.push('/id-bind');
    }

    handleUnBind = async () => {
        storageKeyRemove('LohacoRelease');
        storageSet('LohacoUnbind', true);
        await this.GADataLayer('event_coupon_action', 'event_lohaco_coupon_unbinding_confirmation', 'whisper_lohaco_unbinding_confirmation');
        Router.push('/id-bind-lohaco-unbound');
    }

    render() {
        const { description, imageWhisper, imageDivider, imageLohaco } = this.props;
        const imgWhisper = getImageUrl(imageWhisper.imageDesktop?.asset?.url, {});
        const imgDivider = getImageUrl(imageDivider.imageDesktop?.asset?.url, {});
        const imgLohaco = getImageUrl(imageLohaco.imageDesktop?.asset?.url, {});
        const { successStatus, loaderStatus, errorStatus, errorMsgStatus, unBindErrorStatus, releaseStatus, unBindStatus, unBindloader } = this.state;
        const LohacoUnbindStatus = storageGet('LohacoUnbind');

        const currentHref = typeof window !== 'undefined' && window.location.pathname;

        return (loaderStatus) ? (
            <>
                <Dialog open={true}>
                    <div className="">
                        <DialogTitle className="Loader-overlay" component="div">
                            <Loader
                                type="TailSpin"
                                color="#8347ad"
                                className="loader"
                                height={100}
                                width={100}
                                visible={true}
                            />
                        </DialogTitle>
                    </div>
                </Dialog>
            </>
        ) : (
            <div className="id-bind-unbind">
                <div className="id-binding-heading">
                    <div className="id-binding-imagewhisper">
                        <img src={buildContentfullImageUrl(imgWhisper)} alt="whisper-image1" />
                    </div>
                    <div className="id-binding-imageDivider">
                        <img src={buildContentfullImageUrl(imgDivider)} alt="divider-image2" />
                    </div>
                    <div className="id-binding-imageLohaco">
                        <img src={buildContentfullImageUrl(imgLohaco)} alt="lohaco-image3" />
                    </div>
                </div>
                {/** Description */}
                <div className="id-binding-description">
                    <h1>{!loaderStatus && !successStatus && !errorStatus && !releaseStatus && !LohacoUnbindStatus
                            && (currentHref === '/id-bind-lohaco-next' || currentHref === '/id-bind-lohaco-next/')
                            && description}
                    </h1>
                    {releaseStatus && <h1>ID連携を解除します</h1>}
                    {successStatus && (
                        <div>
                            <h1>ありがとうございます<br />
                                ID連携が完了しました
                            </h1>
                        </div>
                    )}
                    {errorStatus && <h1>{errorMsgStatus} </h1>}
                </div>

                {/** ID bind buttons */}
                <div className="id-binding-buttons">

                    {successStatus && <button type="button" className="Go-to-landing" onClick={this.handleIDBindLanding}>戻る</button>}

                    {errorStatus && <button type="button" className="Go-to-landing" onClick={this.handleIDBindLanding}>戻る</button>}

                    {!successStatus && !errorStatus && !releaseStatus && !LohacoUnbindStatus && (currentHref === '/id-bind-lohaco-next' || currentHref === '/id-bind-lohaco-next/')
                            && (
                                <>
                                    {this.GADataLayer('event_informational_action', 'event_lohaco_view_login_successful_page', true)}
                                    <button type="button" className="Go-to-Next" onClick={this.handleNextRedirect}>次へ進む</button>
                                </>
                            )}

                    {releaseStatus && (
                        <div>
                            <button type="button" className="Go-to-landing" onClick={this.handleIDBindLanding}>ID連携ホームへ戻る</button>
                            <button type="button" className="Go-to-Next" onClick={this.handleUnBind}>解除する</button>
                        </div>
                    )}

                    {unBindloader && (
                        <Loader
                            type="TailSpin"
                            color="#8347ad"
                            className="loader"
                            height={40}
                            width={40}
                            visible={true}
                        />
                    )}

                    {unBindErrorStatus
                            && (
                                <div className="id-un-bind-error-status">
                                    <h1 className="unBindError">アクセスが集中しています。しばらく経ってからお試しください。</h1>
                                    <button type="button" className="Go-to-landing" onClick={this.handleIDBindLanding}>戻る</button>
                                </div>
                            )}

                    {unBindStatus && (
                        <div>
                            <h1>ID連携を解除しました</h1>
                            <button type="button" className="Go-to-landing" onClick={this.handleIDBindLanding}>戻る</button>
                        </div>
                    )}

                </div>
                <style jsx>{style}</style>
            </div>
        );
    }
}

LohacoIdBinding.propTypes = {
    description: PropTypes.string,
    imageWhisper: PropTypes.object,
    imageDivider: PropTypes.object,
    imageLohaco: PropTypes.object,

};

export default LohacoIdBinding;
