/* eslint-disable import/no-cycle */
import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import Style from '../SectionContainer/SectionContainer.scss';
// import SectionContainer from '../SectionContainer/SectionContainer';
import { renderSection } from '../../helpers/uiHelper';

const SamplingSectionContainer = ({ section }) => {
    const sectioncontainer = section.sectionContentCollection.items;
    // const [index, setIndex] = useState(1);

    // const handleScroll = (index) => {
    //     // setIndex(index);
    //     document.getElementById(`sec${index}`).scrollIntoView({ behavior: 'smooth' });
    // };
    // const className = section.styles ? section.styles : '';
    // const { styles } = section;
    // let sectionStyle = '';

    // if (styles) {
    //     sectionStyle = styles.trim();
    // }
    // console.log(sectioncontainer[0].sectionContentCollection.items, 'llllllllll')

    return (
        // <div>
        //     <SectionContainer
        //         styles={className}
        //     >
        //         {sectioncontainer[0].sectionContentCollection.items.map((item, index) => (
        //             <button onClick={() => handleScroll(index)}>
        //                 <div className="cardview">
        //                     {getImageSet(item, resolution)}
        //                 </div>
        //             </button>
        //         ))}
        //         <style jsx>{Style}</style>
        //     </SectionContainer>
        //     {getImageSet(sectioncontainer[1], resolution)}
        //     <div className="abc-products-section">
        //         {
        //             sectioncontainer.slice(2).map((item, index) => <div id={`sec${index}`} className="productbanner-items">
        // {renderSection(item)}</div>)
        //         }
        //     </div>
        // </div>
        <div>

            {/* {getImageSet(sectioncontainer[1], resolution)} */}

            <div className="abc-products-section">
                <LazyLoad>
                    {
                        sectioncontainer.map((item, index) => <div id={`sec${index}`} className="productbanner-items">{renderSection(item)}</div>)
                    }
                </LazyLoad>
            </div>
            <style jsx>{Style}</style>
        </div>

    );
};
SamplingSectionContainer.propTypes = {
    // styles: PropTypes.string,
    section: PropTypes.any,
};
export default SamplingSectionContainer;
