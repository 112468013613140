import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { classNameForGA, dataActionForGA } from '../../helpers/dataForGA';
import { getAllPages } from '../../adapters/contentful.adapter';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const fetch = require('node-fetch');

const findUrlById = (id, allPages) => {
    const foundPage = find(allPages, (page) => page.sys.id === id);
    return foundPage.seoHead.url;
};

const prepareRichTextContent = (json) => {
    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <b>{text}</b>,
            [MARKS.ITALIC]: (text) => <i>{text}</i>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
            [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
            [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
            [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
            [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const src = node.data.target.sys.id;
                const [advice, setAdvice] = useState('');
                const [alttag, setAlttag] = useState('');
                useEffect(() => {
                    const url = liveImgPath+`/assets/${src}?access_token=
                    process.env.CF_DELIVERY_ACCESS_TOKEN`;
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url);
                            const jsonres = await response.json();
                            setAdvice(jsonres.fields.file.url);
                            setAlttag(jsonres.fields.description);
                        } catch (error) {
                            console.log('error', error);
                        }
                    };

                    fetchData();
                }, []);
                return (
                    <div className="img-wrapper">
                        <img src={buildContentfullImageUrl(advice)} alt={alttag} />
                    </div>
                );
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                const [pages, setPages] = useState([]);
                useEffect(() => {
                    const fetchData = async () => {
                        const allPages = await getAllPages();
                        setPages(allPages);
                    };

                    fetchData();
                }, []);
                const url = findUrlById(node.data.target.sys.id, pages);
                return <a className={classNameForGA(url)} {...dataActionForGA(url)} href={url} download>{children}</a>;
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                const src = node.data.target.sys.id;
                const [pdflink, setPdfLink] = useState('');
                useEffect(() => {
                    const url = liveImgPath+`/assets/${src}?access_token=
                    process.env.CF_DELIVERY_ACCESS_TOKEN`;
                    const fetchData = async () => {
                        try {
                            const response = await fetch(url);
                            const jsonres = await response.json();
                            setPdfLink(jsonres.fields.file.url);
                        } catch (error) {
                            console.log('error', error);
                        }
                    };

                    fetchData();
                }, []);
                return (
                    <a
                        className={classNameForGA(pdflink)}
                        {...dataActionForGA(pdflink)}
                        href={pdflink}
                        target="_blank"
                        rel="noreferrer"
                        download
                    >{children}
                    </a>
                );
            },
        },
    };
    return documentToReactComponents(json, options);
};

export { prepareRichTextContent };
