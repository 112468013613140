module.exports = (GTIN,preview) => `
{
  productCardCollection (preview: ${preview},where:{gtin: ${GTIN}}){
    items {
      title
      productCategory
      imageDt(preview: ${preview}) {
        title
        asset(preview: ${preview}) {
          url
        }
      }
    }
  }
  productCollection (preview: ${preview},where:{gtin: ${GTIN}}){
    items {
      name 
      absorbency(preview:${preview}){
        name
      }
      size(preview:${preview}){
        name
      }
      imagesCollection(preview:${preview},limit:1){
        items{
          image(preview:${preview}){
            title
            asset(preview:${preview}){
             url
            }
          }
        }
      }
    }
  }
}`;
