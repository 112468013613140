import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.allprodcut-container {
    width: 48%;
    float: left;
    margin: 0 20px 20px 0;
    min-height: 420px;
    .categoryListWrapper {
        width: 100%;
        float: left;
        .cta {
            background: none;
            &:hover {
                text-decoration: none;
            }
        }
        .category-name {
            font-size: 28px;
            font-family: "Noto Sans JP";
            color: $purple;
            margin: 10px 0;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .variants {
        width: 100%;
        position: relative;
        float: left;
        .cta {
            background: $purple;
            display: flex;
            width: 85px;
            float: left;
            margin: 0 10px 10px 0;
            height: 30px;
            text-align: center;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            font-size: 20px;
            font-family: "Noto Sans JP";
            &:hover {
                text-decoration: none;
                color: #fff;
            }
        }
    }
    &:nth-child(even) {
        margin-right: 0;
    }
    @media (max-width: $breakpoint-max) {
        width: 100%;
        float: left;
        margin: 0 auto;
        padding: 0 20px;
    }
}
`;

export default styles;