import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.plpBannerImage {
  img {
    max-width: 100%;
  }
}
.productCategory {
  h1 {
    color: $purple-darker;
    font-size: 40px;
    font-family: "Noto Sans JP";
    font-weight: 500;
    padding: 80px 0 40px;

    @media (max-width: $breakpoint-max-md-ipad) {
      font-size: 24px;
    }
  }
  .product-list-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: baseline;
    flex-wrap: wrap;    
  }  
}

.tab-container {
  .plpCategoryTab {
    margin-right: 20px;
    background-color: #fff;
    border: 1px solid $purple-darker;
    border-radius: 22px;
    font-size: 18px;
    font-family: 'Noto Sans JP';
    font-weight: 500;
    color: $purple-darker;
    padding: 5px 20px;

    @media (max-width: $breakpoint-ipad-max) {
      margin-bottom: 16px;
      font-size: 14px;
    }

    &.active {
      background-color: $purple-darker;
      color: #fff;
    }
  }
}`;

export default styles;