import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty, orderBy } from 'lodash';

import getSearchResults from '../../adapters/algolia.adapter';

import isPinkCategory from '../../helpers/category-color';

import TabState from './TabState.const';
import { SortOrder } from './SortOrder.const';
import style from './SwiftypeSearchResult.scss';
import { logException, logSuccessEvent } from '../../helpers/telemetry';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

class SwiftypeSearchResult extends React.Component {
    constructor(props) {
        super(props);

        this.toggleSelect = this.toggleSelect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.selectRef = React.createRef();

        this.state = {
            activeTab: TabState.PRODUCTS,
            isDataLoaded: false,
            products: [],
            articles: [],
            sortOrder: 'relevance',
            isSelectOpen: false,
        };
    }

    componentDidMount() {
        const searchTerm = (process.browser && window.location.href.split('?q=')[1]) || '';

        const startTime = new Date().getTime();

        getSearchResults(decodeURI(searchTerm))
            .then((res) => {
                const endTime = new Date().getTime();
                logSuccessEvent('SearchSuccess', startTime, endTime, { searchTerm });

                const { products, articles } = res;
                products.filter((product) => product._score > 3).map((p) => ({
                    ...p,
                    rating: Math.random() * 5, /* @TODO - temp! */
                }));
                this.setState({
                    products: orderBy(products, ['title'], ['asc']) || [],
                    articles: articles || [],
                });
            })
            .catch((err) => {
                console.log('err', err); /* @TODO error state - get design! */
                if (err.response && err.response.status) {
                    const endTime = new Date().getTime();
                    logException('SearchFailure', startTime, endTime, err.response.data
                        && (err.response.data.message || ''), err.response.status, '', { searchTerm });
                }
            })
            .finally(() => {
                this.setState({
                    isDataLoaded: true,
                });
            });
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
    }

    handleClick(e) {
        const clickedOutsideMenu = this.selectRef.current && !this.selectRef.current.contains(e.target);

        if (clickedOutsideMenu) {
            this.setState({
                isSelectOpen: false,
            });
        }
    }

    setTab(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    setOrder(order) {
        if (order === this.state.sortOrder) return;

        const { products } = this.state;
        let newOrder;

        switch (order) {
        case 'relevance':
            newOrder = orderBy(products, ['_score', 'title'], ['desc', 'asc']);
            break;

        case 'ratings':
            newOrder = orderBy(products, ['rating', 'title'], ['desc', 'asc']);
            break;

        case 'desc':
            newOrder = orderBy(products, ['title', 'rating'], ['desc', 'desc']);
            break;

        case 'asc':
        default:
            newOrder = orderBy(products, ['title', 'rating'], ['asc', 'desc']);
            break;
        }

        this.setState({
            products: newOrder,
            sortOrder: order,
        }, this.toggleSelect());
    }

    toggleSelect() {
        this.setState({
            isSelectOpen: (prevState) => !prevState.isSelectOpen,
        });
    }

    renderProducts() {
        const { sortOrder, isSelectOpen, products } = this.state;

        if (isEmpty(products)) {
            if (this.state.isDataLoaded) {
                return <div className="no-result">{this.props.noResults}<style jsx>{style}</style></div>;
            }
            return null;
        }

        return (
            <>
                <div className="sort-bar">
                    <div className="select" ref={this.selectRef}>
                        <div className="select-selected" onClick={this.toggleSelect}>{SortOrder[sortOrder]}</div>
                        {isSelectOpen && (
                            <ul className="select-list">
                                <li onClick={() => this.setOrder('relevance')} className={classnames({ active: sortOrder === 'relevance' })}>
                                    {SortOrder.relevance}
                                </li>
                                <li onClick={() => this.setOrder('asc')} className={classnames({ active: sortOrder === 'asc' })}>
                                    {SortOrder.asc}
                                </li>
                                <li onClick={() => this.setOrder('desc')} className={classnames({ active: sortOrder === 'desc' })}>
                                    {SortOrder.desc}
                                </li>
                                <li onClick={() => this.setOrder('ratings')} className={classnames({ active: sortOrder === 'ratings' })}>
                                    {SortOrder.ratings}
                                </li>
                            </ul>
                        )}
                    </div>
                    <div className="sort-by-text">{this.props.sortByText}</div>
                    <h2>{this.props.heading}</h2>
                </div>
                <ul className="result-list products">
                    {products.map((prod, i) => {
                        const className = `item product ${isPinkCategory(prod.category) ? 'pink' : 'purple'}`;
                        return (
                            <li className={className} key={i}>
                                <a href={prod.slug}>
                                    <div className="image">
                                        <img src={buildContentfullImageUrl(prod.image)} alt={prod.category} title={prod.category} />
                                    </div>
                                </a>
                                <div className="info">
                                    <a href={prod.slug}>
                                        <h4>{prod.title && prod.title}</h4>
                                    </a>
                                    
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <style jsx>{style}</style>
            </>
        );
    }

    renderArticles() {
        const { articles } = this.state;

        if (isEmpty(articles)) {
            if (this.state.isDataLoaded) {
                return <div className="no-result">{this.props.noResults}<style jsx>{style}</style></div>; /* @TODO get design! */
            }
            return null; /* @TODO some loading state? get design! */
        }

        return (
            <ul className="result-list articles">
                {articles.map((art, i) => (
                    <li className="item article clearfix" key={i}>
                        <a href={art.slug}>
                            <div className="image">
                                <img src={buildContentfullImageUrl(art.imageSp)} alt={art.title && art.title} title={art.title && art.title} />
                            </div>
                            <div className="info">
                                <h4>{art.title && art.title}</h4>
                                <p>{art.subheading && art.subheading}</p>
                            </div>
                        </a>
                    </li>
                ))}
                <style jsx>{style}</style>
            </ul>
        );
    }

    render() {
        const { heading, subheading } = this.props;
        const { activeTab } = this.state;
        const isProducts = activeTab === TabState.PRODUCTS;
        const searchTerm = (process.browser && window.location.href.split('?q=')[1]) || '';

        return (
            <div className="search-results container">
                <h1>{`${heading} "${decodeURI(searchTerm)}"`}</h1>
                <h2 className="subheading">{subheading}</h2>
                <ul className="tabs">
                    <li className={`tab products ${isProducts ? 'active' : ''}`} onClick={() => this.setTab(TabState.PRODUCTS)}>商品 ({this.state.products.length})</li>
                    <li className={`tab articles ${!isProducts ? 'active' : ''}`} onClick={() => this.setTab(TabState.ARTICLES)}>コンテンツ ({this.state.articles.length})</li>
                </ul>
                <div className="results">
                    {isProducts && this.renderProducts()}
                    {!isProducts && this.renderArticles()}
                </div>
                <style jsx>{style}</style>
            </div>
        );
    }
}

SwiftypeSearchResult.propTypes = {
    heading: PropTypes.string,
    noResults: PropTypes.string,
    sortByText: PropTypes.string,
    subheading: PropTypes.string,
};

export default SwiftypeSearchResult;
