import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.id-binding-main-retailers-content
{
 position: relative;
 width:100%;
}
.id-binding-main-retailers-content
{
   
    border-bottom: 1px solid #592C82;
    padding: 15px 0px;
    .id-binding-userlog-status{
        font-size: 21px;
        width: 100%;
        padding:0;
        margin:0;
        color: #592C82;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        
    }
    .id-binding-userlog{
        width: 100%;
        float: left;
        margin: 30px 0;
        border-bottom: 1px solid #592C82;
        padding-bottom: 30px;
        h3{
        font-size: 18px;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        float: left;
        margin: 5px 0;
        display: block;
        line-height: 24px;
        width:100%;
        color:#592C82;
        a{
            text-decoration: underline;
        }

        }
        div{
            width:auto;
            float: left;
            font-size: 18px;
        }
    }
   .id-binding-retailer-logo{
       width: 50%;
        display: inline-block;
        float: left;
    img{
        width:100%;        
    }
    p{
        font-size: 14px;
        margin: 20px;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        color: #592C82;
        text-align: center;
    }
 
    
   
}
.id-binding-status{
    display: inline-block;
    width: 50%;
    text-align: right;
    float: left;
    .submit_button{
        background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
        width: 393px;
        height: 60px;
        color: #fff;
        margin: 20px auto;
        font-size: 24px;
        border: none;
        box-shadow: none;
        font-weight: 700;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        }

}
.binded-user{
    width: 50%;
    float: left;
    text-align: right;
    display: flex;
    align-items: center;
    height: 100px;
    justify-content: center;
    .binded-user-info{
        font-size: 16px;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        font-weight: 500;
        color: #592C82;
        float: left;
        background: url(/static/images/binded.png) no-repeat left 0px center/30px;
        padding-left: 35px;
        margin: 10px 40px 0 20px;
        
    }
    .unbind-submit_button{
        width: 240px;
        color:  #592C82;
        margin: 0 auto;
        font-size: 16px;
        border: 2px solid #592C82;
        border-radius: 25px;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        background:none;
        padding: 8px 17px;

    }
}
.id-binding-error{
    font-size: 21px;
    line-height:21px;
    color: red;
    line-height: 50px;
}

}

@media (max-width: $breakpoint-max) {
    .id-binding-main-retailers-content    
    {
        padding-bottom: 65px;
        margin-top:15px;
        border-bottom:0;
        .id-binding-retailer-logo{
            width:100%;
            margin:0 auto;
            text-align:center;
            border-top: 1px solid #592C82;
            padding-top: 30px;
            img{
                width:100%;
            }
          
        }
        p{
            position:static;
            margin:0 auto;
            width: 100%;
            text-align:center;
        }
       .id-binding-status{
        display: block;
        width: 100%;
        text-align: center;
        float: left;
        border-bottom: 1px solid #592C82;
        padding-bottom: 25px;
        .submit_button{
            width: 270px;
            border-radius: 7px;   
            font-size: 14px;
          }   
    }   
    .id-binding-userlog{
        border-bottom:0;
        padding-bottom: 0px;
    }
    .binded-user {
        width: 100%;
        float: left;
        display: inline-block;
        border-bottom: 1px solid #592C82;
        .binded-user-info{
            margin: 10px 10px 0 10px;
        }
        .unbind-submit_button{
            width: 190px;
        }
    }
    .id-binding-userlog{
        width: auto;
        float: left;
        margin: 20px;
        border-bottom: 0;
        padding-bottom: 0;
        h3{
            font-size: 18px;
        }
        div{
            font-size: 16px;
        }
       }
       .id-binding-error{
        font-size: 14px;
        line-height: 34px;
        color: red;
        float: left;
        width: auto;
        padding: 0 20px;
        text-align: center;
        border-bottom: 1px solid #592C82;
        padding-bottom: 30px
    }
    
    }
 
}

`;

export default styles;