module.exports = (preview) => `
{
  navigation: menuCollection(where: {name: "Header Menu"}, limit: 1,preview:${preview}) {
    items {
      loginColumnCollection(limit: 1,preview:${preview}) {
        items {
          __typename
          title
          menuCategoryCollection(limit: 3,preview:${preview}) {
            items {
              __typename
              ... on ExternalLink {
                nameEn
                label
                url
                title
              }
            }
          }
        }
      }
      navigationColumn: menuColumnCollection(limit:8,preview:${preview}){
        items {
          __typename
          ... on ExternalLink {
            nameEn
            label
            url
          }
          ...on ${LinkFragement}
          ...on LinkGroup{
            title
            linksCollection(limit:5,preview:${preview}){
              items{
                __typename
                label
                url
              }
            }
          }
          ... on MenuList{
            name
            title
            subHeading
            url
            menuCategoryCollection(limit:8,preview:${preview}){
              items{
                __typename
                ... on ExternalLink {
                  label
                  url
                }
                ...on ${LinkFragement}
                ... on SectionContainer {
                  __typename
                  name
                  styles 
                  sectionContentCollection(limit: 6, preview: ${preview}){
                    items {
                      sys{
                          id
                      }
                      ... on SimpleCard{
                        __typename
                        name
                        header
                        customClass
                        cardMedia {
                            url
                        }
                        cardDescription
                        cardActionCollection(preview: ${preview}, limit: 4) {
                            items {
                              ...on ${LinkFragement}
                         }
                        }
        
                    }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  retailers: retailersDropdownCollection(limit: 1,preview:${preview}) {
    items {
      dropdownTitle
      retailersCollection(limit: 3,preview:${preview}) {
        items {
          imageDt {
            cloudinaryId
            description
            asset{url}
          }
          imageSp {
            cloudinaryId
            description
            asset{url}
          }
          link {
            url
          }
        }
      }
    }
  }
}
`;

const LinkFragement=`Link{
  name
  textValue
  url
  type
  target
  customClass
}`
