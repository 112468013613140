import css from 'styled-jsx/css';

const styles = css.global`/* @styled-jsx=global */
@import "./styles/global-variables";

div.article-brdCrumb, .articleList-brdCrumb{
    width: 100%;
    top: unset !important;
    position: relative !important;
    display: block;
    float: left;
    margin-bottom: 20px;
    // @media (min-width: 1024px) {
    //     // top: -20px;
    //     top: 30px !important;
    // }  
    // @media (min-width: 1366px) {
    //     top: -30px;
    // } 
    .container {
        left: 0;
        margin-left: 0;
    }
}

.breadcrumbs-container {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 12px;
    position: absolute;
    top: 50px !important;
    width: 100%;
    z-index: 10;
    @include ie-only() {
        top: 230px;
    }

    a {
        color: black;
        font-weight: bold;
    }

    @media (max-width: $breakpoint-max) {
        top: 130px;
    }

    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
        top: 325px;
    }
}


.productPage {
    position: absolute;
    top: 1%;  
    color:$text-black;  
      
    @media (max-width: $breakpoint-max) {
        top: 3%;
        font-size:10px;
    }
    .container{
        max-width:65%;
        @media (max-width: $breakpoint-max) {
            max-width:100%;
        }
        @media (min-width: 1280px) {
            max-width:70%;
        }
        @media (min-width: 1600px) {
            max-width:73%;
        }
        @media (min-width: 1920px) {
            max-width:75%;
        }
    }
}







.productDiagnosisPage {
    margin-top: 125px;
    padding-left: 122px;
    @media only screen and (max-width: 767px){
        margin-top:0;
        padding-left:0;
    }
}

.ArticlePage,
.CampaignPage{
    margin-top: 65px;   
   
    @media only screen and (max-width: 767px){
        margin-top:0;
        top:130px;        
    }
    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max){
        top:-10px;
    }
}

.AkashiPage{
    top:0;
    padding-left:100px;
    @media only screen and (max-width: 767px){
        margin-top:0;
        top:130px; 
        left:0;   
        padding-left:0;
    }
    @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max){
        top:-10px;
    }
}
.tsuruhaPageContainer{
    max-width: 80% !important;
    @media only screen and (max-width: 767px){
        max-width: 90% !important;
    }
}
`;

export default styles;