const preview = process.env.PREVIEW;
module.exports = `
... on RelatedInterviews {
    interviews: interviewsCollection(limit: 3,preview: ${preview}) {
        items {
        heading
        heading1
        heading2
        subheading
        page {
            seoHead {
                url
            }
        }
        featuredImage {
            description
            cloudinaryId
        }
        featuredImageNumber {
            description
            cloudinaryId
          }
      }
    }
}
`;
