const allPages = require('./allPages.query');
const singlePage = require('./singlePage.query');
const headerNavigation = require('./header.query');
const footer = require('./footer.query');
const productCategories = require('./productCategories.query');
const articleCategories = require('./articleCategories.query');
const pageContentTypes = require('./pageContentTypes.query');
const samplingCampaignPage = require('./samplingCampaignPage.query');
const retailerCampaignPage = require('./retailerCampaignPage.query');
const ResultPage = require('./ResultPage.query');
const fiftyfive = require('./fiftyfive.query');
const algoliaProducts = require('./algoliaProduct.query');
const productCollection = require('./productCollection.query');

module.exports = {
    allPages,
    singlePage,
    headerNavigation,
    footer,
    productCategories,
    algoliaProducts,
    articleCategories,
    pageContentTypes,
    samplingCampaignPage,
    retailerCampaignPage,
    ResultPage,
    fiftyfive,
    productCollection
};
