import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.promo-banner-with-cta {
    align-items: flex-end;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: $box-shadow-25;
    display: flex;
    flex-direction: column;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: 500;
    height: 295px;
    justify-content: flex-end;
    margin-bottom: 50px;
    padding-bottom: 0;
    width: 942px;

    @media (max-width: $breakpoint-max) {
        align-items: flex-start;
        background-size: 100%;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 12px;
        overflow: hidden;
        padding-bottom: 45%;
        position: relative;
        width: 100%;
    }

    .cta {
        background-color: $purple-dark;
        border: 0;
        border-radius: 25px;
        color: white;
        cursor: pointer;
        display: block;
        font-family: inherit;
        font-size: 28px;
        line-height: 1;
        margin: 2rem 5rem;
        outline: 0;
        padding: 11px 60px;
        position: relative;

        &::after {
            border-bottom: 10px solid transparent;
            border-left: 16px solid white;
            border-top: 10px solid transparent;
            content: '';
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
        }

        @media (max-width: $breakpoint-max) {
            bottom: 0;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 16px;
            line-height: unset;
            margin-bottom: 14px;
            margin-left: 1.5rem;
            padding: 3px 40px;
            position: absolute;
            width: 150px;

            &::after {
                border-bottom: 7px solid transparent;
                border-left: 12px solid white;
                border-top: 7px solid transparent;
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    @media screen and (min-width: 0\\0) {
        .cta {
            font-weight: bold;
            height: 42px;
            line-height: 29px;
        }
    }
}
`;

export default styles;