module.exports = `...on IdBindLohacoBoxText{
    imageWhisper{
      imageDesktop{
        cloudinaryId
        description
        asset{url}
      }
      imageSmartphone{
        cloudinaryId
        description
        asset{url}
      }
    
    }
    dividerImage{
      imageDesktop{
        description
        cloudinaryId
        asset{url}
      }
      imageSmartphone{
        cloudinaryId
        description
        asset{url}
      }
    }
    lohacoImage{
      imageDesktop{
        cloudinaryId
        description    
        asset{url}          
      }
      imageSmartphone{
        description
        cloudinaryId
        asset{url}
      }
    }
    header
    point1
    point2
    point3
    point4
    subtext1
    subtext2
    hightext1
    hightext2
    microText1
    microText2
    microText3
    idBindLohacoBox{
      json
    }
  }
  `;
