import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.article-step {
    margin-bottom: 80px;
    float: left;
    width: 100%;

    @media only screen and (max-width: $breakpoint-tb-max) {
        margin-bottom: 30px;
        padding: 0 15px;
    }

    h2 {
        float: left;
        color: #fff;
        font-size: 22px;
        letter-spacing: -3px;
        background-color: $article-pink;
        border-radius: 3px;
        padding: 0 8px 0 5px;
        font-weight: 500;

        @media only screen and (max-width: $breakpoint-min) {
            padding: 15px;
        }

        @media only screen and (max-width: $breakpoint-max) {
            font-size: 18px;
            line-height: 28px;
            width: 100%;
        }

        @media only screen and (max-width: $breakpoint-tb-max) {
            text-align: center;
        }
    }

    .image-2 {
        display: block;
        margin: 0 auto;
        
        @media only screen and (max-width: $breakpoint-tb-max) {
            margin: 20px auto 0;
            max-width: 288px;
            width: 100%;
        }
    }

    p {
        display: block;
        clear: both;
        font-size: 22px;
        color: $text-article;
        font-size: 22px;
        line-height: 42px;
        text-indent: 22px;

        @media only screen and (max-width: $breakpoint-max) {
            font-size: 18px;
            line-height: 28px;
        }

        @media only screen and (max-width: $breakpoint-tb-max) {
            width: 100%;
        }

        .image-1 {
            display: block;
            float: right;
            margin-left: 20px;

            @media only screen and (max-width: $breakpoint-tb-max) {
                float: none;
                margin: 20px auto 0;
                max-width: 288px;
                width: 100%;
            }
        }

        :global(.purple) {
            color: $purple;
        }
    }
}`;

export default styles;