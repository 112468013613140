import React from 'react';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import { getImageUrl } from '../../adapters/cloudinary.adapter';
import style from './IdBindLohacoImageText.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const IdBindLohacoImageText = (props) => {
    const { idBindLohacoTableImage, description, idBindLohacoTextHeader,
        idBindLohacoText1, idBindLohacoText2, idBindLohacoText3,
        idBindLohacoText4, idBindLohacoText5, idBindLohacoText6 } = props;
    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }
    const Idbindlohacoimg = resolution.isMobile
        ? getImageUrl(idBindLohacoTableImage.imageSmartphone.asset.url, {})
        : getImageUrl(idBindLohacoTableImage.imageDesktop.asset.url, {});

    return (
        <div className="Id-Bind-Lohaco-Image-Text">
            <div className="scrollbar" id="style-1">
                <h2>{idBindLohacoTextHeader}</h2>
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText1 }} />
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText2 }} />
                <div className="Id-Bind-Lohaco-Image">
                    <img src={buildContentfullImageUrl(Idbindlohacoimg)} alt={description} />
                </div>
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText3 }} />
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText4 }} />
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText5 }} />
                <p dangerouslySetInnerHTML={{ __html: idBindLohacoText6 }} />
            </div>
            <style jsx>{style}</style>
        </div>
    );
};

IdBindLohacoImageText.propTypes = {
    idBindLohacoTableImage: PropTypes.object,
    description: PropTypes.string,
    idBindLohacoTextHeader: PropTypes.string,
    idBindLohacoText1: PropTypes.string,
    idBindLohacoText2: PropTypes.string,
    idBindLohacoText3: PropTypes.string,
    idBindLohacoText4: PropTypes.string,
    idBindLohacoText5: PropTypes.string,
    idBindLohacoText6: PropTypes.string,
};
export default IdBindLohacoImageText;
