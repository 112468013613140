import React from 'react';
import PropTypes from 'prop-types';

import { getImageUrl } from '../../adapters/cloudinary.adapter';

import { prepareRichTextContent } from './Interview.helper';

import Style from './Interview.scss';

const Interview = (props) => {
    const { heading, heading1, heading2, subheading } = props;
    const images = props.images.items.map((item) => getImageUrl(item.asset.url));

    const content = prepareRichTextContent(props.content.json, [], images);

    return (
        <div className="interview">
            <h2>{heading}</h2>
            <h1>{heading1} {heading2}</h1>
            <p>{subheading}</p>
            <hr />
            <div className="content">
                {content}
            </div>
            <style jsx>{Style}</style>
        </div>
    );
};

Interview.propTypes = {
    content: PropTypes.object,
    heading: PropTypes.string,
    heading1: PropTypes.string,
    heading2: PropTypes.string,
    images: PropTypes.object,
    subheading: PropTypes.string,
};

export default Interview;
