import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.VF-video-promo{
    width:100%;
    float:left;
    position: relative;
    background: #EDE3F4;
    padding: 20px 25px;
    margin: 10px 0;
    .VF-video-content{
        width: 256px;
        float: left;
        margin-right: 34px;
        height: 100%;
        min-height: 338px;
        position: relative;
        background: #fff url('') no-repeat center bottom;
        &:after{
            background: url('https://images.ctfassets.net/8g0fievzqg8k/5iw8NbpyQ97E39YxQ8Goz4/ce02c9e6e8e280f91cfaec9f473c5a88/video-fb-bk-sp-blur-img.png');
            content: '';
            position: absolute;
            width: 100%;
            height: 35px;
            bottom: -35px;
            background-size: 100%;
            left: 0;
         }
        .VF-video-content {
            position: relative;
        }
        .VF-video-text{
            margin-top: 30%;
            text-align: center;
            .VF-video-steps{
                color:#8347ad;
                
                background: url(https://images.ctfassets.net/8g0fievzqg8k/586vemQWf7GvTbQ90IM6v7/2815342e1afb71026470af931d99cbb6/movie-textbg-sp.png)no-repeat;
                height: 115px;
                width: 100%;
                background-position: 50%;
                position: relative;
                padding-top: 40px;
            }
            .VF-video-header{
                color:#8347ad;
                margin:0 20px;
                font-weight: normal;

            }
        }
    }
    .VF-video-section{
        float:left;
            }

    @media only screen and (max-width: $breakpoint-max){
        padding: 20px 15px;
        .VF-video-content{
            width: 100%;
            float: left;
            margin: 10px 0;
            height: 100%;
            min-height: 100%;       
            .VF-video-text{
                margin-top: 0;
                text-align: left;
                float:left;
                width:100%;                               
                .VF-video-steps{
                    font-size: 12px;                    
                    height:97px;
                    width: 49%;
                    float:left;
                }
                .VF-video-header{
                    color:#8347ad;
                    margin:0;
                    font-weight: normal;
                    font-size: 18px;
                    width:40%;
                    float:left;
                    text-align: right;
    
                }
            }
        }
        .VF-video-section{
            float:left;
            iframe{
                width:100%;
                float:left;
                height:auto;
            }
                }

    }

}

@media only screen and (max-width:767px) {
    .VF-video-text {
        display: flex;
        position: relative;
        .VF-video-steps {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 !important;
            background-position: 10px center !important;
            background-size: 100%  !important;
        }
    .VF-video-header {
        justify-content: center;
        align-items: center;
        display: flex;
    }
}
}

// .VF-video-promo:last-child:before {
//     border-top: 12px #c09fca dotted;
//     content: '';
//     position: absolute;
//     width: 100%;
//     top: -45px;
//     left: 0;
//     @media only screen and (max-width: 767px) {
//         content: none;
//     }
//   }

//   .VF-video-promo:last-child {
//   margin-top: 70px;
//   @media only screen and (max-width: 767px) {
//       margin-top: 10px;
//   }
//   }`;

export default styles;