const preview = process.env.PREVIEW;
module.exports = `
... on InterviewList {
  title
  subtitle
  interviewsCollection(limit: 10,preview: ${preview}) {
    items {
      heading
      heading1
      heading2
      featuredImageNumber {
        title
        description
        cloudinaryId
        asset{url}
      }
      page {
        seoHead {
          url
        }
      }
    }
  }
}
`;
