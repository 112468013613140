import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.container {
    @media (max-width: $breakpoint-max) {
        padding: 0;
    }

    .interview-list {
        margin-bottom: 40px;
        padding: 0;
        text-align: center;

        h2 {
            color: $purple-dark;
            float: center;
            font-family: 'Noto Serif JP', serif;
            font-size: 48px;
            font-weight: 500;
            margin: -25px 0 0;
            white-space: nowrap;
            width: auto;

            &::before {
                background-color: $purple-dark;
                content: '';
                display: inline-block;
                height: 70px;
                left: -25px;
                position: relative;
                top: 20px;
                transform: rotate(-20deg);
                width: 2px;

                @media (max-width: $breakpoint-max) {
                    height: 30px;
                    left: -20px;
                    top: 10px;
                    width: 1px;
                }
            }

            &::after {
                background-color: $purple-dark;
                content: '';
                display: inline-block;
                height: 70px;
                left: 25px;
                position: relative;
                top: 20px;
                transform: rotate(20deg);
                width: 2px;

                @media (max-width: $breakpoint-max) {
                    height: 30px;
                    left: 20px;
                    top: 10px;
                    width: 1px;
                }
            }

            @media (max-width: $breakpoint-max) {
                font-size: 17px;
                margin: -10px 0 0;
            }
        }

        h3 {
            color: $purple-dark;
            font-family: 'Noto Serif JP', serif;
            font-size: 36px;
            font-weight: 500;
            margin: 80px 0 0;

            @media (max-width: $breakpoint-max) {
                font-size: 13px;
                margin: 30px 0 0;
            }
        }

        .row {
            margin-right: 0;
            margin-top: 80px;

            @media (max-width: $breakpoint-max) {
                margin-top: 30px;
            }

            .interview {
                margin-bottom: 40px;
                text-align: left;

                @media (max-width: $breakpoint-max) {
                    margin-bottom: 20px;
                }

                a {
                    &:hover {
                        text-decoration: none;
                    }
                }

                h4 {
                    color: $purple-dark;
                    font-family: 'Noto Serif JP', serif;
                    font-size: 20px;
                    font-weight: 600;
                    margin: 0;

                    @media (max-width: $breakpoint-max) {
                        font-size: 15px;
                    }
                }

                h5 {
                    color: $purple-dark;
                    font-family: 'Noto Serif JP', serif;
                    font-size: 27px;
                    font-weight: 600;
                    line-height: 1.2;
                    margin: 0;

                    @media (max-width: $breakpoint-max) {
                        font-size: 20px;
                    }
                }

                h4,
                h5 {
                    @media (max-width: $breakpoint-max) {
                        padding: 0 30px;
                    }
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

// Overide large margin because margin is set for homepage
div :global(.small-promo-banners-container) {
    @media (max-width: $breakpoint-max) {
        margin-bottom: 0 !important;
    }
}
`;

export default styles;