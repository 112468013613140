import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.productContainer {
    width: 100%;
    float: left;
    background: #efefef;
    position: relative;
    margin-bottom:100px;
    .title {
        color: $purple;
        text-align: center;
        font-size: 22px;
        line-height: 26px;
        position: relative;
        margin: 80px 0 90px 0;
        font-family: "SourceSansPro-Regular";
        &::after {
            position: absolute;
            content: "";
            height: 4px;
            bottom: -16px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 60px;
            background: #8347ad;
        }
    }
}
`;

export default styles;