import React, { useEffect, useState } from 'react';
import style from './UserVideostyle.scss';
import data from '../../data/videofeedback.json';

const UserVideo = () => {
    const [videoData, setVideoData] = useState(null);
    useEffect(() => {
        setVideoData(data.uservideo);
    }, []);

    return (
        <div className="VF-video-container">
            {videoData && videoData.map((v) => (
                <div className="VF-video-promo" key={v.id}>

                    <div className="VF-video-content">
                        <div className="VF-video-text">
                            <h3 className="VF-video-steps">{v.step}</h3>
                            <h2 className="VF-video-header"> {v.header}</h2>
                        </div>
                    </div>
                    <div className="VF-video-section">
                        <iframe
                            title=" youtube-video"
                            width="600"
                            height="338"
                            src={v.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                    <style jsx> {style} </style>
                </div>
            ))}
        </div>

    );
};
export default UserVideo;
