import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.article-list {
        max-width: 1080px;
        margin: 0 auto;
        position: relative;
        
    .article-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media (min-width: 1025px) {
            justify-content: unset;
        }
    }
    .paginate {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 100px;
      }

      .dropdown {
        display: inline-block;
        background: #fff;
        position: relative;
        cursor: pointer;
        margin-bottom: 2rem;

        @media (max-width: $breakpoint-max) {
            width: 100%
        }
      }

    .dropbtn {
        background-color: white;
        color: #8347ad;
        padding: 16px;
        font-size: 16px;
        border: 1px solid #8347ad;
        width: 396px;
        text-align: left;
        height: 100%;
        float: left;
        @media (max-width: $breakpoint-max) {
            margin: 0rem auto;
            width: 100%;
        }
    }

    .dropdown-arrow {
        height: 40px;
        width: 40px;
        background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        radial-gradient(#8347ad 70%, transparent 72%);
      background-position:
      calc(100% - 22px) calc(1em + 4px),calc(100% - 18px) calc(1em + 4px),calc(100% - 0.5em) 0.5em;
      background-size:6px 8px,5px 8px,1.8em 1.8em;
      background-repeat: no-repeat;
        position: absolute;
        top:8px;
        right: 0;
     
      }

      .dropdown:hover .dropdown-arrow {
        height: 40px;
        width: 40px;
        top:8px;
        background-image:
        linear-gradient(45deg, white 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, white 50%),
        radial-gradient(#8347ad 70%, transparent 72%);
        background-position:
        calc(100% - 16px) 1em,
        calc(100% - 22px) 1em,
        calc(100% - .5em) .5em;
        background-size:6px 8px,5px 8px,1.8em 1.8em;
        background-repeat: no-repeat;
      }
  
  
  /* Dropdown Content (Hidden by Default) */
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 396px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 51px;
    border: 1px solid #8347ad;
    list-style-type: none;

    @media (max-width: $breakpoint-max) {
        margin: 0rem auto;
        min-width: 100%;
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
    position: absolute;
    top: 58px;
  }

  
  .active {
    background-color: #D0BBE1;

    a {
        color: $pink;
        @media (max-width: $breakpoint-max){
            color:$purple;
        }
    }

    &::after {
        color: $pink;
    }
}
  
  
  /* Links inside the dropdown */
  
  .dropdown-content a {
    color: #8347ad;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  
  /* Change color of dropdown links on hover */
  
  .dropdown-content a:hover {
    background-color: #D0BBE1
  }
  
  /* Show the dropdown menu on hover */
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  
  .header-title{
    margin-top: 100px;
    font-size: 22px;
    margin-left: 15px;
}

    .title {
        color: $purple;
        text-align: center;
        font-size: 22px;
        line-height: 26px;
        position: relative;
        margin: 80px 0 80px 0;
        font-family: "SourceSansPro-Regular";
        &::after {
            position: absolute;
            content: "";
            height: 4px;
            bottom: -16px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 60px;
            background: #8347ad;
        }
    }

    @media (max-width: $breakpoint-max) {
        margin-top: -34px;
    }

    .sidebar {
        font-family: 'M PLUS Rounded 1c', sans-serif;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-max) {
            background: #faf7fb;
            padding: 70px 0 25px;
        }

        h1 {
            &.list-title {
                font-size: 30px;
                line-height: 33px;
                padding: 16px 10px;
                text-align: center;

                @media (max-width: $breakpoint-max) {
                    background-color: white;
                    border: 1px solid #8347ad;
                    color: #8347ad;
                    float: left;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 9px 16px 9px 12px;
                    text-align: left;
                    display:none;

                    &::after {
                        content: ' >'; // non-breaking-space in content (Alt+0160)
                        font-size: 24px;
                    }
                }

                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    font-size: 28px;
                }
            }

            @include article-list-heading;
        }

        .menu {
            border: 1px solid $purple;

            @media (max-width: $breakpoint-max) {
                clear: both;
                border:none;
                overflow:auto;
                display:block;

                &.collapsed {
                    display: block;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                @media (max-width: $breakpoint-max) {
                    white-space: nowrap;
                }
            }

            li {
                border-bottom: 1px solid $purple;
                list-style: none;
                margin: 0;
                padding: 8px 0 8px 19px;

                @media only screen and (min-width: $breakpoint-tb-min) and (max-width: $breakpoint-tb-max) {
                    // padding: 8px 0 8px 8px;
                }
                @media (max-width: $breakpoint-max){
                    border: 1px solid $purple;
                    padding:0;
                    height: 80px;
                    width: 100px;
                    display: inline-block;
                    position: relative;
                    margin: 0 10px;
                    background:#fff;
                }

                a {
                    color: $purple;
                    font-size: 21px;
                    line-height: 20px;

                    &::after {
                        content: ' >'; // non-breaking-space in content (Alt+0160)
                        font-size: 24px;
                        @media (max-width: $breakpoint-max){
                            content:"";
                        }
                    }
                    @media (max-width: $breakpoint-max){
                        text-align:center;
                        font-size: 15px;
                        float:left;
                        position: relative;
                        top:6px;
                        padding:15px 2px 20px 2px;
                        white-space: normal;
                        width:100%;
                    }
                }

                br {
                    @media (max-width: $breakpoint-max) {
                        display: none;
                    }
                }

                &:hover {
                    background-color: rgba(193, 163, 214, .25);

                    a {
                        color: $pink;
                        text-decoration: none;
                    }
                }

                &.active {
                    background-color: rgba(193, 163, 214, .25);

                    a {
                        color: $pink;
                        @media (max-width: $breakpoint-max){
                            color:$purple;
                        }
                    }

                    &::after {
                        color: $pink;
                    }
                }

                &:last-child {
                    border: 0;
                    @media (max-width: $breakpoint-max){
                        border: 1px solid $purple;
                    }
                }
            }
        }
    }

    .articles {

        @media (max-width: $breakpoint-max) {
            margin-bottom: 20px;
            margin-left: 0;
        }

        h2 {
            @include article-list-heading;
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 16px;
            padding: 16px 32px;

            @media (max-width: $breakpoint-max) {
                font-size: 16px;
                line-height: 24px;
                padding: 13px;
                text-align: center;
            }

            @media (max-width: 320px) {
                font-size: 16px;
                text-align: center;
            }
        }

        h3 {
            color: $text-black;
            font-family: 'M PLUS Rounded 1c', sans-serif;
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 2px;
            margin-top: 2px;

            @media (max-width: $breakpoint-max) {
                margin-top: 8px;
            }
        }

        .article-card-stretch {
            align-items: stretch;
            flex-direction: row;
            margin: 0 -2px;

            .article-card-mb {
                margin-bottom: 18px;
                padding: 0 9px;
                max-width: 100%;

                a {
                    text-decoration: none;
                }

                @media (max-width: $breakpoint-max) {
                    margin-bottom: 10px;
                }
            }

            .article-card {
                .title-container{
                    margin-bottom: 4px;
                    @media (min-width: 768px) {
                        min-height: 100px;
                        margin-bottom: 0;
                    }
                }
                border: 1px solid $purple;
                height: 275px;
                text-align: center;
                width: 335px;

                .card-image {
                    height: 171px;
                }
           
                @media (max-width: $breakpoint-max) {
                    height: auto;
                    padding-bottom: 5px;

                    .mobile-image-cropper {
                        // height: 40vw;
                        overflow: hidden;
                    }
                }

                a,
                .first-title,
                .second-title {
                    color: $purple;
                    display: block;
                    font-family: 'M PLUS Rounded 1c', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25px;
                    margin-top: 0;
                    padding: 11px 20px 0;
                    position: relative;
                    text-align: left;

                    @media (max-width: $breakpoint-max) {
                        display: block;
                        font-size: 18px;
                        line-height: 25px;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 10px;
                    }

                    @media (max-width: 320px) {
                        font-size: 16px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                a, .second-title{
                    padding: 0 19px;
                }

                a + a {
                    background-image: none;
                    margin-top: 0;
                    padding-top: 3px;
                }

                img {
                    width: 100%;
                }
            }
            .banner-promo{
                height: 265px;
                @media (max-width: $breakpoint-max) {
                    height: auto;
                    padding-bottom: 5px;
                }
            }

            @media screen and (min-width: 0\\0) {
                .article-card {
                    padding-top: 1px;

                    a {
                        background-size: 99.85%;
                    }

                    img {
                        max-width: 99.85%;
                    }
                }
            }
        }
    }


    @media (min-width: $breakpoint-min) {
        .article-small-promo-banners {
            margin-bottom: -145px;
            transform: scale(.74);
            transform-origin: top left;
            width: 943px;
            margin-top:50px;
        }
    }

    @media (max-width: $breakpoint-max){
        .article-small-promo-banners {
            margin-bottom:80px;
        }

    }

}
`;

export default styles;