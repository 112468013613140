const preview = process.env.PREVIEW;
module.exports = `
... on RelatedArticles {
  title
  articlesCollection(limit: 10,preview: ${preview}) {
    items {
      nameEn
      title
      title1
      imageDt {
        title
        description
        cloudinaryId
        asset{url}
      }
      imageSp {
        title
        description
        cloudinaryId
        asset{url}
      }
      page {
        seoHead {
          url
        }
      }
    }
  }
}
`;
