import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.promo-banner-with-cta {
    align-items: flex-end;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-shadow: $box-shadow-25;
    display: flex;
    flex-direction: column;
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 500;
    height: 295px;
    justify-content: flex-end;
    margin-bottom: 50px;
    padding-bottom: 0;
    width: 942px;

    :hover {
        text-decoration: none;
    }

    @media (max-width: $breakpoint-max) {
        align-items: flex-start;
        background-size: 100%;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 12px;
        overflow: hidden;
        padding-bottom: 45%;
        position: relative;
        width: 100%;
    }

    .cta {
        align-self: center;
        background-color: #592c82;
        border: 0;
        border-radius: 18px;
        bottom: 1.6rem;
        color: white;
        cursor: pointer;
        display: block;
        font-family: "M PLUS Rounded 1c", sans-serif;
        font-size: 16.8px;
        font-weight: 500;
        outline: none;
        padding: 6px;
        text-align: center;
        width: 210px;
        letter-spacing: 3px;
        font-weight: normal;

        &::after {
            border-bottom: 7px solid transparent;
            border-left: 13px solid white;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
            content: "";
            font-size: 0;
            position: absolute;
            right: 4px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translatey(-50%);
        }

        @media (max-width: $breakpoint-max) {
            bottom: 0;
            font-family: "M PLUS Rounded 1c", sans-serif;
            font-size: 16px;
            line-height: unset;
            margin-bottom: 14px;
            margin-left: 1.5rem;
            padding: 3px 40px;
            position: absolute;
            width: 150px;

            &::after {
                border-bottom: 7px solid transparent;
                border-left: 12px solid white;
                border-top: 7px solid transparent;
                position: absolute;
                right: 7px;
                top: 57%;
                transform: translateY(-50%);
            }
        }
    }

    @media screen and (min-width: 0\\0) {
        .cta {
            font-weight: bold;
            height: 42px;
            line-height: 29px;
        }
    }
}
.homepage-banner-with-cta {
    width: 100%;
    height: 350px;
    box-shadow: none;
    background-size: 100% 100%;
    .cta-button {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 5rem 8rem;
        .cta {
            border-radius: 18px;
            padding: 6px;
            color: white;
            cursor: pointer;
            text-align: center;
            width: 210px;
            font-family: "Noto Sans JP";
            font-size: 16.8px;
            text-decoration: none;
            &::after {
                content: none !important;
            }
        }
    }

    @media (max-width: $breakpoint-max) {
        height: 300px;
        margin: 0 auto 50px;
        .cta-button {
            position: relative;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            .cta {
                width: 200px;
                padding: 4px 20px;
                &::after {
                    content: none !important;
                }
            }
        }
    }
}
`;

export default styles;