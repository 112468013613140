import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.digital-campaign-lp {
    width: 100%;
    float:left;
    position: relative;  
    margin: 0 auto;
    .digital-title {
        text-align: center;
        position: relative;
        font-size: 30px;
        margin:40px auto;
        width:940px;
        &:before {
            content: "";
            width:100% ; 
            border-bottom: 3px solid #000;
            position: absolute;
            display: inline-block;
            z-index: -1;
            left:0; 
            top :50%
        }
        span {
            background-color: #fff;
        }
    
    }

    .digital-campaign-banner{
        width:100%;
        margin:50px auto 0;
        position: relative;
        img{
            width:100%;
        }
        span {
            border: 0!important;
            clip: rect(0,0,0,0)!important;
            height: 1px!important;
            margin: -1px!important;
            overflow: hidden!important;
            padding: 0!important;
            position: absolute!important;
            width: 1px!important;
        }
    }  
    
    .line-below-banner {
        text-align: center;
        font-size: 24px;
        color : #8347ad;
        font-weight: bold;
        margin-top: 20px;
    }
    .digital-banner-text{
        width:940px;
        text-align:left;
        position: relative;
        margin:40px auto;
        font-family: "Noto Sans JP";
        font-family: "M PLUS Rounded 1c",sans-serif;
        font-size: 18px;
    line-height: 34px;
    }
    @media only screen and (max-width: $breakpoint-max) {
        
        .digital-campaign-banner{
            width:100%;
            img{
                width:100%;
            }
        }
        .digital-banner-text{
            width:100%;            
        }
    }
}

@media only screen and (max-width: 767px) {    
    .digital-campaign-lp {
        width: 100%;
        padding:10px 0 0;
        .digital-title {
            font-size: 25px;
            width:100%;            
        }
        .digital-campaign-banner{
            width:100%; 
            margin:30 auto 0;          
            img{
                width:100%;
            }
        }
        .line-below-banner {
            text-align: center;
            font-size: 0.8em;
            color : #8347ad;
            font-weight: bold;    
        }   
        .lotery-banner-text{
            margin:20px 0;
            padding:0 20px;
        }      
    }
}
`;

export default styles;