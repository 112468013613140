import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.Cashback-KVsection{
    width: 100%;
    float:left;
    position: relative;
    margin: 0 auto;
    h3{
        font-size:24px;
        line-height: 36px;      
        color:#582b8c;       
    }
    .KV-text{
        width: 940px;
        margin:0 auto;
    }
    .KVsection-head{
        margin:20px 0;
    }
    .KVsection-subhead{
        margin:30px 0;
        color:#000;        
    }
    img{
        width:100%;
    }
    @media (max-width: $breakpoint-max){
        h3{
            font-size:20px;
            line-height: 26px;                 
        }
        .KV-text{
            width: 100%;
            margin:0 auto;
            padding: 0 20px;
        }
        .KVsection-head{
            margin:30px 0;
        }
        .KVsection-subhead{
            margin:20px 0;
            color:#000;        
        }
    }
}`;

export default styles;