const preview = process.env.PREVIEW;
module.exports = `
... on RecommendedProducts {
    title
    subtitle
    gtin
    productsCollection(limit: 10,preview: ${preview}) {
      items {
        name
        gtin
        page {
          seoHead {
            url
          }
        }
        imagesCollection(limit: 1,preview: ${preview}) {
          items {
            ... on ProductImage {
              imageThumbnail {
                title
                description
                cloudinaryId
                asset{url}
              }
            }
          }
        }
      }
    }
  }
`;
