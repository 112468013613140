const { countArticles } = require('./ArticleList.helper');

test('Count articles correctly', () => {
    const articles = [
        {
            'articles': {
                'items': [
                    {
                        'title': '尿もれについて知られていない',
                        'title1': '５つのこと',
                        'nameEn': '5 things you don\'t know about incontinence',
                        'page': {
                            'seoHead': {
                                'url': '/article-top/incontinence-among-women/ignorance-of-urinary-leakage/',
                            },
                        },
                        'imageDt': {
                            'url': 'https://images.ctfassets.net/8g0fievzqg8k/46wIG6SqJLsM2wn7RPu17z/acacce3799c4c359342f96761f3c587c/whisper_article02.image.5things_you_don_t_know_about_incontinence.jpg',
                            'description': null,
                        },
                        'imageSp': {
                            'url': 'https://images.ctfassets.net/8g0fievzqg8k/5Xb5vocfyzt0tJ48c6XLUc/019673ea90905e8a4cac68f4de869ab2/whisper_article_relevance_2.5things_you_don_t_know_about_incontinence_sp.jpg',
                            'description': null,
                        },
                    },
                    {
                        'title': 'あなたの尿もれはどのタイプ?',
                        'title1': null,
                        'nameEn': 'What type of incontinence do you have?',
                        'page': {
                            'seoHead': {
                                'url': '/article-top/incontinence-among-women/type/',
                            },
                        },
                        'imageDt': {
                            'url': 'https://images.ctfassets.net/8g0fievzqg8k/3LUg53SKMImLIkSN1yF4gu/9ca474c4d19937005e5b4b29a0dc335d/whisper_article04.image.what_type_of_incontinence_do_you_have.jpg',
                            'description': null,
                        },
                        'imageSp': {
                            'url': 'https://images.ctfassets.net/8g0fievzqg8k/4egwX5RY4BqYU7QUodtbRv/bcce87b20bfad85051118075a26c87c6/whisper_article_relevance_4.what_type_of_incontinence_do_you_have.jjpg_sp.jpg',
                            'description': null,
                        },
                    },
                ],
            },
        },
    ];

    expect(countArticles(articles)).toBe(2);
});
