import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";

.Japan-Id-content {
    width: 100%;
    float: left;
    position: relative;
    background: #f5f5f5;
    padding: 40px;
    h2{
        color: #592c82;
        font-family: "M PLUS Rounded 1c",sans-serif;
        font-size: 24px;
    }
    p{
        color: #592c82;
        font-family: "M PLUS Rounded 1c",sans-serif;
        font-size: 16px;
        line-height: 24px;
    }

    @media (max-width: $breakpoint-max){
        // padding:20px;
        // border: 3px solid #ccc;
        // height: 150px;
        // overflow: auto;
        // width: auto;
        // margin: 0 20px;
        width: auto;
        background: #f5f5f5;
        padding:0 10px 0 0;
        margin:20px 20px 10px 20px;
        h2{
            font-size: 18px;
            text-align:center;
            width:50%;
            margin:0 auto 20px;
        }
        p{
            font-size: 14px;
            line-height: 20px;
        }

        .scrollbar {
            background-color: #F5F5F5;
            float: left;
            height: 250px;
            margin-bottom: 0;
            margin-left: 0;
            margin-top: 0;
            width: 100%;
            overflow-y: scroll;
            padding: 20px;
            .force-overflow {
                min-height:0px;
            }
        }
        #style-1::-webkit-scrollbar {
            width: 10px;
            background-color: #F5F5F5;
            
        } 
        #style-1::-webkit-scrollbar-thumb {
            background-color: #8347ad;
            border-radius: 5px;
            
        }
        #style-1::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: #e5d3ef;
            margin-top: 10px;
            margin-bottom:10px;
            
           
            
        }
    }

    @media only screen and (max-width: 320px){
        h2{
            width:75%;
        }
    }
}

.id-binding-login{
    margin:60px 0 50px;
    .submit_button{
        background-color: #E3007E;
        width: 393px;
        height: 60px;
        color: #fff;
        margin: 20px auto;
        font-size: 24px;
        border: none;
        box-shadow: none;
        font-weight: 700;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        margin:20px;
        border-radius: 7px;
        }
        @media (max-width: $breakpoint-max){
            margin: 30px auto;
            text-align: center;
            .submit_button{
                width: 270px;
                font-size: 14px;
                height: 45px;
                margin: 10px;
                }

        }
}
.id-binding-continue{
    .submit_next{
        background: url(https://images.ctfassets.net/8g0fievzqg8k/24U2sssoNWFk0SXy5I3u2B/e6fb88d0a72ec791e7f43bfd71ce4cc3/next-btn.png) no-repeat;
        width: 393px;
        height: 60px;
        color: #fff;
        margin: 20px auto;
        font-size: 24px;
        border: none;
        box-shadow: none;
        font-weight: 700;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        margin-left: 20px;
        *background: none;
        border-radius: 5px;
        @media (max-width: $breakpoint-max){
            width:270px;
            font-size:18px;
            margin:20px;
        }

    }
    
}

.remember_me_checkbox{
    float: left;
    width: 100%;
    font-size: 24px;
    margin-bottom: 20px;
    margin: 41px auto 0 0;

    .frm-customchkbox {
            display: block;
        position: relative;
        padding-left: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 18px;
            width: 18px;
            left: 0;
            top: 4px;
            z-index: 100;
        }
        input[type="checkbox"] {
            width: 17px;
            height: 17px;
          }
        .checkmark{
            position: absolute;
            top: 3px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 1px solid #8347ad;
            float:left;
        }
        span{
            cursor: pointer;
            font-size: 20px;
            user-select: none;
            font-family: "M PLUS Rounded 1c",sans-serif;
            color: #8347ad;
            float:left;
        }
        }
        .frm-customchkbox input:checked ~ .checkmark {
            background-color: #2196f3;
          }
          .frm-customchkbox input:checked ~ .checkmark:after {
            display: block;
          }
          .frm-customchkbox .checkmark:after {
            left: 6px;
            top: 0px;
            width: 7px;
            height: 15px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            @media (max-width: $breakpoint-max){
                left: 5px;
                top: 0;
                width: 6px;
                height: 14px;
                border-width: 0 2px 2px 0;
            }
          }
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
    .error {
        text-align: left;
        font-size: 14px;
        color: red;
        margin: 8px 0;
        width: 100%;
        display: block;
        clear: both;
        font-family: 'M PLUS Rounded 1c',sans-serif;
        padding: 5px 0;
        @media (max-width: $breakpoint-max){
            font-size: 12px;
                    }
    }
    @media (max-width: $breakpoint-max){
        width: auto;
        margin:20px;
        font-size:18px;
        .frm-customchkbox{
            padding-left:25px;
        .checkmark{
            height: 18px;
            width: 18px;
            top: 0;
        }
        span{
            font-size: 14px;
        }
    }
    }
}`;

export default styles;