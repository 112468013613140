module.exports = `...on IdBindingRetailers
{
  logo
  {
     cloudinaryId
     description
  }
  title
  termsAndConditions
}`;
