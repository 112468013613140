import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.cardPromo {
    align-self: center;
    margin-bottom: 160px;
    width: 940px;
    position: relative;
    .title {
        text-align: center;
        font-size: 22px;
        font-family: "SourceSansPro-Regular";
        color: $purple;
        margin-bottom: 115px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            height: 4px;
            bottom: -16px;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: 60px;
            background: $purple;
        }
    }
    .imageSection-wrapper-dt {
        widows: 100%;
        position: relative;
        display: flex;
        .imageSection-text {
            font-size: 12px;
            line-height: 18px;
            width: 90%;
            margin: -2% 5% 0 5%;
            .conditionText-disc {
                font-size: 12px;
                line-height: 18px;
            }
        }
        .imageSection-wrapper-dt-left {
            width: 50%;
            float: left;
            text-align: left;
            color: $purple;
            font-size: 24px;
            line-height: 35px;
            font-family: "Noto Serif JP";
            font-weight: normal;
            .imageSection-cta {
                float: left;
                width: 100%;
                margin: 30px 0;
                display: flex;
                .cta {
                    align-self: center;
                    background-color: #592c82;
                    border: 0;
                    border-radius: 20px;
                    bottom: 1.6rem;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    display: block;
                    font-family: "SourceSansPro-Regular";
                    font-size: 16.8px;
                    font-weight: 500;
                    outline: none;
                    padding: 3px;
                    text-align: center;
                    width: 210px;
                    letter-spacing: 4px;
                    font-weight: normal;
                    &::after {
                        border-bottom: 7px solid transparent;
                        border-left: 12px solid white;
                        border-right: 7px solid transparent;
                        border-top: 7px solid transparent;
                        content: "";
                        font-size: 0;
                        position: absolute;
                        right: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                    }                   
                }
                @media (max-width: $breakpoint-max){
                    .cta {
                        &::after {
                            border-bottom: 7px solid transparent;
                            border-left: 12px solid white;
                            border-right: 7px solid transparent;
                            border-top: 7px solid transparent;
                            content: "";
                            font-size: 0;
                            position: absolute;
                            right: 4px;
                            top: 50%;
                            transform: translateY(-50%);
                        }                   
                    }

                }
                
            }
        }

        .imageSection-img {
            width: 50%;
            float: left;
        }
    }

    @media (max-width: $breakpoint-max) {
        align-self: center;
        margin-bottom: 50px;
        width: 100%;
        float: left;
        margin-top: 70px;
        .title {
            margin-bottom: 60px;
        }
    }
}
`;

export default styles;