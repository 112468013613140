import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import useScreenSize from '../Hooks/useScreenSize';
import style from './IdBindLohacoJapanId.scss';
import { storageGet, storageKeyRemove, GetEmailVerified } from '../../helpers/StorageEvents';

const IdBindLohacoJapanId = (props) => {
    const { headerText, description } = props;
    const [userloggedInstatus, setUserloggedInstatus] = useState(false);
    const [unbindTC, setUnbindTC] = useState(false);
    // const [checkBoxStatus, setCheckBoxStatus] = useState(false);
    // const [errorStatus, setErrorStatus] = useState(false);
    const resolution = useScreenSize();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (storageGet('isUserLoggedIn') && GetEmailVerified()) {
                setUserloggedInstatus(true);
            } else {
                setUserloggedInstatus(false);
            }

            if (storageGet('UnbindTC')) { setUnbindTC(true); } else { setUnbindTC(false); }
        }
        return () => {
            storageKeyRemove('UnbindTC');
        };
    }, []);

    // const GADataLayer = (eventAction, eventLabel) => {
    //     window.dataLayer.push({
    //         'event': 'customEvent',
    //         'GAeventCategory': 'event_crm_action',
    //         'GAeventAction': eventAction,
    //         'GAeventLabel': eventLabel,
    //         'GAeventValue': 0,
    //         'GAeventNonInteraction': false,
    //     });
    // };

    // const routerRedirection = (status) => {
    //     storageSet('IDBindingVia', true);
    //    (status === 'login') ? Router.push('/login') : (status === 'register') ? Router.push('/registration') : Router.push('/id-bind-lohaco-next');
    // };

    // const handleLoginOrRegister = (status) => {
    //     if (checkBoxStatus) {
    //         setErrorStatus(false);
    //         routerRedirection(status);
    //     } else {
    //         setErrorStatus(true);
    //     }
    // };

    // const loginPageFunc = async () => {
    //     await GADataLayer('event_profile_lohaco_login', 'whisper_login_start_idbinding');
    //     await handleLoginOrRegister('login');
    // };

    // const registrationPageFunc = async () => {
    //     await GADataLayer('event_profile_lohaco_idbinding_register', 'whisper_register_start_idbinding');
    //     await handleLoginOrRegister('register');
    // };

    // const nextPageFunc = async () => {
    //     await GADataLayer('event_profile_lohaco_idbinding_continue', 'whisper_continue_start_idbinding');
    //     await handleLoginOrRegister('continue');
    // };

    const IdbindingLand = () => {
        storageKeyRemove('UnbindTC');
        Router.push('/id-bind');
    };

    // const handleCheckboxStatus = () => {
    //     setCheckBoxStatus(prevState => !prevState);
    //     setErrorStatus(false);
    // };

    if (!resolution) {
        return null;
    }

    return (
        <>
            <div className="Japan-Id-content">
                <div className="scrollbar" id="style-1">
                    <h2>{headerText}</h2>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                    <div className="force-overflow" />
                </div>

                {/* <h2>{headerText}</h2>
                <p dangerouslySetInnerHTML={{ __html: description }} /> */}

            </div>

            {!unbindTC && (
                <div align="center" className="remember_me_checkbox">
                    {/* <div className="frm-customchkbox">
                        <input
                            type="checkbox"
                            name="rememberme"
                            onChange={handleCheckboxStatus}
                        />
                        <span className="checkmark" />
                        <span>
                            {"上記内容を確認の上、同意します。"}
                        </span>
                    </div>
                    {errorStatus && <p className="error">{"記載事項をご確認の上チェックを入れてください"}</p>} */}
                </div>
            )}

            {!userloggedInstatus ? (
                <div className="id-binding-login">
                    {/* <button className="submit_button login" type="button" onClick={loginPageFunc}> ウィスパー会員の方のID連携</button> */}
                    {/* <button className="submit_button register" type="button" onClick={registrationPageFunc}> ウィスパー会員になってID連携</button> */}
                </div>
            )
                : unbindTC ? (
                    <div className=" id-binding-login id-binding-continue">
                        <button className="submit_next next" type="button" onClick={IdbindingLand}> 戻る</button>
                    </div>
                ) : (
                    <div className=" id-binding-login id-binding-continue">
                        {/* <button
                                className="submit_next next event_profile_lohaco_idbinding_continue"
                                type="button"
                                data-action-detail="whisper_continue_start_idbinding"
                                onClick={nextPageFunc}
                            > 次へ進む
                        </button> */}
                    </div>
                )}
            <style jsx>{style}</style>
        </>
    );
};

IdBindLohacoJapanId.propTypes = {
    headerText: PropTypes.string,
    description: PropTypes.string,
};
export default IdBindLohacoJapanId;
