const preview = process.env.PREVIEW;
module.exports = `
... on TabsContainer{
    tabsListCollection(limit:3,preview: ${preview}){
      items{
        menuTitle
        tabListItemsCollection(limit: 4,preview: ${preview}) {
          items {
            imageDt {
              __typename
              description
              cloudinaryId
              asset{url}
            }
            imageSp {
              __typename
              description
              cloudinaryId
              asset{url}
            }
            tabSectionTextCollection(limit: 4,preview: ${preview}) {
              items {
                __typename
                ... on ContentLt {
                  content
                  contentMobile
                  styles
                }
                ... on ContentTypeRichText {
                  styles
                  body
                }
              }
            }
          }
        }
        styles
      }
    }
    styles
  }
`;
