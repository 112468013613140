import css from 'styled-jsx/css';

const styles = css`@import "./styles/global-variables";
.fiftyfive-lp{
    box-shadow: 7px 9px 5px -3px rgba(155,155,155,1);
    position: relative;
    width: 100%;
    float: left;
    background-image: url('/static/images/renewal-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    color: #592C82;
    font-family: 'M PLUS Rounded 1c',sans-serif;
    font-size: 30px;
    
    h1{
        font-size: 40px;
        margin: 70px auto 0;
        width: 100%;
        text-align: center;
        padding: 0 50px;
        
    }
    h2{
        font-size: 26px;
        float: left;
        width:100%;
        margin: 0 0 25px 0;
    }

    

    .promoBanners{
        .promo-banner{
            padding:0 50px;
        }
        div{
            float: left;
            width: 100%;
            position: relative;
            img{
                width:100%;
                cursor: pointer;
                margin-bottom: 75px;
            }
        }
        #promoBanner2{
            .img1{
                margin-bottom:28px;                
            }
        }
        
        .promo-banner5{
            .header-text-promo{
            background-image: url('/static/images/renewal-head.png');
            background-repeat: no-repeat;
            background-size: contain;
            height:100px;
                h3{
                    color:#fff;
                    position: absolute;
                    top: 33%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .promo-banner5-cta{
                position: relative;
                float: left;
                width:100%;
                a{
                    position: absolute;
                    top: 140px;
                    left: 25px;
                    background-color: #592C82;
                    border: 0;
                    border-radius: 25px;
                    color:#fff;
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: 500;
                    padding: 4px 40px;
                    transition: opacity 300ms;
                    &::after{
                        border-bottom: 7px solid transparent;
                        border-left: 12px solid white;
                        border-right: 7px solid transparent;
                        border-top: 7px solid transparent;
                        content: "";
                        font-size: 0;
                        position: absolute;
                        right: 4px;
                        top: 10px;
                    }
                }
            }

        }

    }

    footer{
        p{
            width: 100%;
            font-size: 13px;
            margin: 0 auto 30px;
            text-align: center;
            font-family: 'M PLUS Rounded 1c',sans-serif;
            color: #000;
            font-weight: 700;            
        }
    }

    .header-carousel {
        position: relative;
        width: 100%;
        margin:0 0 65px 0;
        img {
            width: 100%;
        }
        .slick-prev,
        .slick-next {
            border: 2px solid $purple;
            border-radius: 50%;
            color: $purple;
            cursor: pointer;
            height: 70px;
            padding: 5px 10px;
            position: absolute;
            top: 55%;
            width: 70px;
            z-index: 1;
    
            &::before {
                border-bottom: 2px solid $purple-dark;
                border-left: 2px solid $purple-dark;
                content: '';
                font-size: 0;
                height: 20px;
                left: 55%;
                position: absolute;
                top: 45%;
                transform: translate(-50%, -50%);
                width: 20px;                 
            }      
        }
        .slick-next {
            border-width: 2px;
            right: 1%;
            transform: rotate(225deg) scale(.5);           
        }
    
        .slick-prev {
            left: 1%;
            transform: rotate(45deg) scale(.5);
        }
    }





    .dropbtn {
        background-color: #fff;
        padding: 20px 50px 20px 20px;
        font-size: 30px;
        border: none;
        cursor: pointer;
        width: 100%;
        border: 1px solid $purple-dark;
        text-align: left;
        background-image: url('/static/images/renewal-down.png');
        background-image: url(/static/images/renewal-down.png);
        background-repeat: no-repeat;
        background-position: 95%;
        background-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
      
      .dropbtn:hover, .dropbtn:focus {
        background-color: #fff;
      }
      
      .dropdown {
        position: relative;
        display: inline-block;
        width: 100%;
        float: left;
        padding: 0 50px;
        margin:55px 0;
       

      }
      
      .dropdown-content {
        height: 455px;
        display: none;
        position: absolute;
        background-color: #fff;
        min-width: 160px;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        width: 428px;
        right: 50px;
        border-radius: 5px;
        padding: 25px 10px 25px 25px;
       }
      
      .dropdown-content div {
        color: black;
        padding: 10px 16px;
        text-decoration: none;
        display: block;
        cursor: pointer;
        font-size: 30px;
        font-weight: 500;
      }
      
      .dropdown a:hover {background-color: #ddd;}
      
      .show {display: block;}
      @media (max-width: 320px){
        .promo-banner5{
            .header-text-promo{
                height:70px !important;
                h3{
                    top: 24% !important;
                    font-size: 18px !important;
                }
            }
            .promo-banner5-cta{
                a{
                     top: 70px !important;
                    left: 5px !important;
                   }
            }
        }
    
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px){
          h1{
            font-size: 24px;
            margin: 35px auto 0;
            width: 100%;
            text-align: center;
            padding: 0 20px;
          }
          h2{
            font-size: 21px;
        }
          .dropdown {
            padding: 0 20px;
            margin:45px 0;         
    
          }
          .promoBanners{
            .promo-banner{
                padding:0 20px;
            }
            div{            
                img{
                    margin-bottom: 55px;
                }
            }
            #promoBanner2{
                .img1{
                    margin-bottom:28px;                
                }
            }
            
            .promo-banner5{
                .header-text-promo{
                    h3{
                        top: 23%;
                        font-size: 24px;
                    }
                }
                .promo-banner5-cta{
                    a{
                         top: 92px;
                        left: 10px;
                        padding: 2px 35px;
                    }
                }
            }
    
        }

        .header-carousel{
            margin:0 0 35px 0;
        }

        .dropdown-content {
            height: 300px;
            width: 300px;
            right: 0px;
          }
          .dropdown-content div {
            padding: 5px 8px;
            font-size: 20px;
          }  
      }


}

`;

export default styles;