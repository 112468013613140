import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import useScreenSize from '../Hooks/useScreenSize';
import style from './UserVideoBannerstyle.scss';
import { buildContentfullImageUrl } from '../ReusableComponents/imageHelper';

const UserVideoBanner = (props) => {
    const resolution = useScreenSize();
    if (!resolution) {
        return null;
    }

    const img = (resolution.isMobile || props.bannerStatus === 'small') ? 'https://images.ctfassets.net/8g0fievzqg8k/2twpQp1NBbBQofjzK35WJT/6c8b58a5cc54c0d80c815bce20d5d3bb/movie-banner-SP.png' : 'https://images.ctfassets.net/8g0fievzqg8k/70mrnT067j45uSm4GvMd1A/f2cff8908471fec1eab6b10fa0eecedd/movie-banner-DT.png';

    return (

        <div className="VF-Banner-container">
            {resolution && resolution.isMobile
                ? (
                    <div className="VF-Banner-header-container-sp">
                        {props.descStatus && <h3 className="VF-Banner-header">新規会員登録ならお得な<br />▼キャンペーン実施中▼</h3>}
                    </div>
                )
                : (
                    <div className="VF-Banner-header-container">
                        {props.descStatus && <h3 className="VF-Banner-header">▼新規会員登録ならお得なキャンペーン実施中▼</h3>}
                    </div>
                )}

            <div className={props.descStatus ? 'VF-Banner' : ' VF-Banner VC-Banner'}>
                <div className="VF-Banner-img">
                    <Link href={{ pathname: '/campaign/whisper-request-sampling2020' }}><img src={buildContentfullImageUrl(img)} alt="現品プレゼントキャンペーン " /></Link>
                </div>
                <div className="VF-Banner-content">
                    <p>お一人様1回・1個限り</p>
                    <h3>全員<span className="hyper-text">に</span>現品<br />プレゼント</h3>
                </div>
            </div>
            <style jsx> {style} </style>
        </div>
    );
};

UserVideoBanner.propTypes = {
    bannerStatus: PropTypes.any,
    descStatus: PropTypes.any,
};

export default UserVideoBanner;
