import css from 'styled-jsx/css';

const styles = css`@import './styles/global-variables';

.customTable{
    background:$pink-light-2;
    width:100%;
    // @media only screen and (min-width: $breakpoint-max) {
    @media only screen and (min-width:1024px) {
        padding-top:50px;
    }
    :global(h2 .table-style), :global(h2 .table-style-SP1), :global(h2 .table-style-SP2){
        margin: 0 auto;
        :global(tr){
            background: #E9DCF2 0% 0% no-repeat padding-box;
            :global(th,td){
                font-size: 20px;
                text-align: center;
                padding: 17px 12px;
                border-right: 1px solid;                
            }
            :global(th:nth-of-type(4), td:nth-of-type(4)){
               // border-right: 0;
            }
        }
        :global(tr:nth-of-type(2)){
            border-top: 1px solid;
            background-color: #ffff;
            :global(td:nth-of-type(1)){
                background: #E9DCF2 0% 0% no-repeat padding-box;
            }
        }
    }
    :global(h2 .table-style:nth-of-type(2)), :global(h2 .table-style-SP1:nth-of-type(2)), :global(h2 .table-style-SP2:nth-of-type(2)){
        margin-top: 20px;
    }
    +div{
        background:$pink-light-2;
        width:100%;
        text-align: center;
        padding-top:100px;
        // @media only screen and (min-width: $breakpoint-max) {
        @media only screen and (min-width:1024px) {
            position:relative;
        }
    }
   
    
}
.customTable +div:global(h2.conditionText){
    position:relative;
    max-width:800px;
    margin: 0 auto;
}


.conditionTextSectionTwo, .conditionTextSectionThree{
    background:$pink-light-2;
    width:100%;
    // @media only screen and (min-width: $breakpoint-max) {
    @media only screen and (min-width: 1024px) {
        padding-top:120px;
    }
}
.conditionTextSectionTwo{
    @media only screen and (max-width: 767px) {
        background:$pink-light-2 url('https://images.ctfassets.net/8g0fievzqg8k/6iTA54Uutef8CI94G5Np0v/236f975f8193221f45175ef61815f454/flower1_PC.png') no-repeat -88px -28px;
        background-size: 50%;
        padding-top:60px;
    }

}
.customTable +div p >img{
    position:absolute;
}
.conditionTextSectionThree{
    background:$pink-light-2 url('https://images.ctfassets.net/8g0fievzqg8k/6iTA54Uutef8CI94G5Np0v/236f975f8193221f45175ef61815f454/flower1_PC.png') no-repeat top left;
    padding:100px 0;
    margin-bottom:-5rem;
    // @media only screen and (min-width: $breakpoint-max) {
    //     background:$pink-light-2 url('https://images.ctfassets.net/8g0fievzqg8k/6iTA54Uutef8CI94G5Np0v/236f975f8193221f45175ef61815f454/flower1_PC.png') no-repeat top left;
    // }
    @media only screen and (min-width:1280px) {
        background-position: -135px 0;
    }
}
.conditionTextSectionTwo :global(h2.conditionText){
    display:flex;
    justify-content: center;
    margin:0 auto;
    width:80%;
    position:relative;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        flex-direction:column;
        width:90%;
    }
}
.conditionTextSectionTwo :global(h2.conditionText div){
    display:flex;
    flex-direction:column;
    color:$purple-dark;
    font-size:28px;
    border-right:1px solid $purple-dark;
    padding:10px;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        border-bottom:1px solid $purple-dark;
        border-right:0;
        padding:10px 30px 25px;
        font-size:32px;
    }
}
.conditionTextSectionTwo :global(h2.conditionText div:nth-child(4)){
    border-right:0;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        border-bottom:0;
    }
}
.conditionTextSectionTwo :global(h2.conditionText div:nth-child(4) br){
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        display:none;
    }
}
.conditionTextSectionTwo :global(h2.conditionText div img){
    width:232px;
    height:232px;
    margin:30px auto;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        margin:15px auto;
        z-index:1;
    }
}
.conditionTextSectionTwo :global(h2.conditionText p){
    position:absolute;
    right:9%;
    bottom:-90px;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        right:-19px;
        bottom:-182px;
        z-index:1;
    }
    @media only screen and (min-width: 1260px) and (max-width:1600px) {
        right:-8%;
    }
}
.conditionTextSectionTwo :global(h2.conditionText p img){
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
            width:161px;
        }
        @media only screen and (max-width: 390px) {
            width:130px;
        }
}
.conditionTextSectionThree :global(h2.conditionText){
    // max-width:806px;
    margin:0 auto;
    // @media only screen and (min-width: $breakpoint-max) {
    @media only screen and (min-width:1024px) {
        background:url("https://images.ctfassets.net/8g0fievzqg8k/528gf3IjOb8ACYYxY3mrWm/3591604bee851b0610d978de173c40cc/flower3_PC.png") no-repeat bottom right;
    }
    
    @media only screen and (min-width:1280px) and (max-width: 1600px) {
        background-position: 130% 100%;
    }
    @media only screen and (min-width:1601px){
        background-position: 108% 100%;
    }
}
.conditionTextSectionTwo :global(h2.conditionText span){
    font-size:12px;
    display:inline-block;
    margin-top:30px;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        margin-top:5px;
    }
}
 .conditionTextSectionThree :global(h2.conditionText >div:nth-child(1)){
// :global(div.washingMachine){
    display:flex;
    flex-direction:column;
    background:#fff;
    margin-bottom:100px;
    position:relative;
}
:global(img.laundryImg){
    position:absolute;
    right:-50px;
    top:-40px;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        display:none;
    }
}
:global(img.laundryImgMob){
    margin:0 !important;
    position:absolute;
    top:-52px;
    right:0;
    max-width: 300px !important;
    width: 58% !important;

    @media only screen and (max-width: 374px) {
        top:-48px;
        width:62% !important;
    }
    @media only screen and (min-width: 1024px) {
        display:none;
    }
}
:global(div.washingMachineTitle),:global(div.innerWashingMachine){
// .conditionTextSectionThree :global(h2.conditionText >div >div){
    display:flex;
    color:$purple-dark;
    font-size:18px;
    align-items:center;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        flex-direction: column;
        align-items: unset;
    }
}

.conditionTextSectionThree :global(h3){
    background:$purple-light-3;
    color:$pink-light-3;
    font-size:24px;
    font-weight: bold;
    width:100%;
    padding:10px 50px;
}
.conditionTextSectionThree :global(h2.conditionText >div >div img){
    width:140px;
    margin:3px 53px 30px 50px;
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        margin:5px 0 30px 10px; 
    }
}
.conditionTextSectionThree :global(h2.conditionText >div >div p){
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        padding: 0 30px;
    }
}
.conditionTextSectionThree :global(h2.conditionText >div >div p br){
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
            // display:none !important;
    }
}
.conditionTextSectionThree :global(div.innerWashingMachine1Div > p br){
    // @media only screen and (max-width: $breakpoint-max) {
        @media only screen and (max-width: 1023px) {
        display:block !important;
    }
}
.conditionTextSectionThree :global(h2.conditionText >div:nth-child(2)){
    color:$purple-light-2;
    font-size:24px;
    font-weight:bold;
}
.conditionTextSectionThree :global(h2.conditionText >div:nth-child(2) >div){
    display:flex;
    border-top:1px solid $purple-dark;
    padding:20px 0;
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        width:85%;
        margin:auto;
        flex-direction:column;
    }
}
.conditionTextSectionThree :global(h2.conditionText >div:nth-child(2) >div p){ 
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        padding-left:0;
    }
}
.conditionTextSectionThree :global(h2.conditionText >div:nth-child(2) span){
    display:inline-block;
    width:30%;
    margin-bottom:10px;
}
.conditionTextSectionThree :global(h2.conditionText >div:nth-child(2) p){
    color:$purple-dark;
    font-size:18px;
    font-weight:normal;
    width:70%;
    text-align: left;
}

.conditionTextSectionThree :global(h2.conditionText >p){
    border-top:1px solid $purple-dark;
    padding:100px 0 50px 0;
    text-align: center;
    max-width:806px; 
    margin:auto;
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        width:85%;
        
    }
}
.conditionTextSectionThree :global(h2.conditionText >p img){
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        width:80%;
    }
}
:global(h2 p a img){
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        width:80%;
    }
}
:global(img.LpImage){
    // width: 21%;
    width: 280px !important;
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        width:80%;
    }
}
:global(img.amazonLogo){
    width: 280px !important;
    // @media only screen and (max-width: $breakpoint-max) {
    // @media only screen and (max-width: 1023px) {
    //     width:80%;
    // }
}
:global(img.tableImage1){
   position:absolute;
   right:5%;
   bottom:25%;
//@media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: $breakpoint-max) {
        bottom:39%;
        left:-66px;
        right:unset;
        z-index:1;
   }
   @media only screen and (min-width: 390px) and (max-width: 411px) {
        bottom:39.5%;
   }
   @media only screen and (min-width: 375px) and (max-width: 389px) {
        bottom:40%;
   }
   @media only screen and (min-width: 360px) and (max-width: 374px) {
        bottom:40.5%;
    }
}
:global(img.tableImage2){
    position:absolute;
    left:5%;
    bottom:-160px;
    // @media only screen and (max-width: $breakpoint-max) {
    @media only screen and (max-width: 1023px) {
        right:0;
        margin-top:231px;
        width:205px;
        left:unset;
        bottom:unset;
    }
}
:global(div.washingMachine), :global(div.innerWashingMachine1){
       max-width:806px; 
       margin:auto;
}
:global(div.innerWashingMachine1Div){
    @media only screen and (max-width: 700px) {
        display: block;
    }
}
:global(table.table-style){
    @media only screen and (max-width: 700px) {
        display: none;
    }
}
:global(table.table-style-SP1), :global(table.table-style-SP2){
    display: none;
    @media only screen and (max-width: 700px) {
        display: block;
    }
}
:global(img.washingMachineImg1){
    @media only screen and (max-width: 1023px) {
        display:none;
    }
}
:global(img.washingMachineImg1SP){
    width:188px !important;
    @media only screen and (min-width: 1024px) {
        display:none;
    }
}
:global(div.innerWashingMachine1Div p){
    @media only screen and (max-width: 1023px) {
        width:100% !important;
    }
}
`;

export default styles;