import React from 'react';
import PropTypes from 'prop-types';

import { excludeUnwantedPages } from './Sitemap.helper';

import Style from './Sitemap.scss';

const SPLIT_CHAR = '+';

const SitemapArticles = ({ categories }) => {
    const filteredCategories = excludeUnwantedPages(categories);

    return (
        <div className="articles">
            <ul>
                {filteredCategories.map((category) => {
                    const categoryUrl = category && category.page && category.page.seoHead.url;
                    const categoryName = category && category.page && category.name.replace(SPLIT_CHAR, '');

                    return (
                        <li key={category.name}>
                            <h2><a href={categoryUrl} title={categoryName}>{categoryName}</a></h2>
                            <ul>
                                {category && category.articles && category?.articles?.items && category?.articles?.items.map((article, i) => {
                                    const  url  = article?.page?.seoHead?.url;
                                    return (
                                        <li key={i}>
                                            <a href={url} title={article?.title}>
                                                {article?.title} {article?.title1 || ''}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
            <style jsx>{Style}</style>
        </div>
    );
};

SitemapArticles.propTypes = {
    categories: PropTypes.object,
};

export default SitemapArticles;
