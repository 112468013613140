import React from 'react';
import Style from '../LotteryCampaign/OpenCampaignlpcontent.scss';

const OpenCampaignLPContent = () => (
    <div className="lottery-campaign-contentsection">
        <div className="lottery-campaign-content">
            <h2 className="lottery-campaign-header">【応募方法】</h2>

            <p className="lottery-campaign-text">
                ご注意を必ずお読みいただき、ウィスパー会員にご登録の上ご応募ください。<br />
            </p>
            <h2 className="lottery-campaign-header">【ご注意】</h2>
            <p className="lottery-campaign-text">
                ● ご応募はお一人様1回限り、プレゼントは、1製品限りとさせていただきます。<br />
                ● 「ウィスパー現品プレゼントキャンペーン」にご応募された方は、ご応募いただけません。<br />
                ● 必要事項に不備や虚偽がある場合は無効となります。またご応募内容の訂正は承れません。<br />
                ● ご希望の製品が不明の場合、事務局で選定した製品をお届けさせていただく場合がございます。<br />
                ● ご応募、製品の発送は日本国内に在住でかつ連絡先が日本国内の方に限らせていただきます。<br />
                ● 製品の交換・換金、及び受領の権利の譲渡はできません。<br />
                ● 製品の写真はイメージです。デザインや色・柄は写真と異なる場合がございます。又、製品は諸事情により変更になる場合がございます。<br />
                ● 住所不明や連絡不能等の理由により製品のお届けができない場合は、ご応募は無効となります。<br />
                ● ご応募にはマイレピ会員/ウィスパー会員の登録（無料）が必要です。<br />
                ● ご応募はこちらのページからのみとなります。メールやはがき、お電話、FAXでのご応募は受け付けておりません。<br />
                ● ご応募の際、キャンペーン事務局に送られた質問やご意見等については返答いたしかねます。<br />
                ● ご応募の際にかかる通信料は応募者負担とさせていただきます。<br />
                ● P&Gジャパンの社員とその家族、及び関係者の応募はできません。<br />
                ● 応募者に予告することなく、本キャンペーンの内容の一部または全部を変更し、もしくは本サービスの提供を中止することがあります。P&Gジャパンは、これによって応募者に生じた損害について一切の責任を負いません。<br />
            </p>
            <h2 className="lottery-campaign-header">【個人情報の取り扱いについて】</h2>
            <p className="lottery-campaign-text">
                お客様からいただいた個人情報は、プレゼント製品の発送やご連絡、アンケートへのご協力等、当キャンペーン遂行のために、必要な範囲で弊社とP&Gグループ企業（日本国外を含む）と共同で利用いたします*1。
                ご本人の同意なく外部機関に対してそれら情報の交換・売買・共有を行うことはありません。ただし、弊社またはP&Gグループ企業は、業務代行者（日本国外を含む）へ個人情報取扱い業務を委託する場合があり、
                業務代行者は個人情報を委託内容の範囲において適切に取り扱います。 P&Gグループのプライバシーポリシーは
                <a href="https://privacypolicy.pg.com/ja/"> こちら</a> <br />
                *1 共同利用の管理責任者：P&Gジャパン合同会社
            </p>
            <h2 className="lottery-campaign-header">【キャンペーンに関するお問い合わせ先】</h2>
            <p className="lottery-campaign-text">
                キャンペーン事務局までメールでお問い合わせください。<br />
                メールアドレス：info@whisper-cpn.jp<br />
                受付期間：2021年11月22日（月）まで<br />
                ※ご返信は数日かかる場合がございます。<br />
                ※キャンペーン事務局では製品に関するご質問にはお答えできません。
            </p>
            <h2 className="lottery-campaign-header">【製品に関するお問い合わせ先】</h2>
            <p className="lottery-campaign-text">
                電話：0120-021329<br />
                受付時間：祝日・年末年始を除く月～金 9:15～17:00<br />
                メールでのお問い合わせはこちらからお進みください。
            </p>

        </div>
        <style jsx>{Style}</style>
    </div>
);
export default OpenCampaignLPContent;
